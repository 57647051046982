import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TextTemplateModalComponent} from 'src/app/components/text-template/text-template-modal/text-template-modal.component';
import {TextTemplate} from 'submodules/baumaster-v2-common';

@Injectable({
  providedIn: 'root',
})
export class TextTemplateModalService {
  constructor(private modalController: ModalController) {}

  async getTextTemplate(category: string, prefillTextTemplate?: Partial<TextTemplate>): Promise<string | null> {
    const modal = await this.modalController.create({
      component: TextTemplateModalComponent,
      componentProps: {category, prefillTextTemplate},
      cssClass: 'omg-modal omg-boundary omg-in-modal-list pdf-workflow-modal',
    });

    await modal.present();

    const {data} = await modal.onWillDismiss();

    if (data && data.textTemplate && typeof data.textTemplate === 'string') {
      return data.textTemplate;
    }

    return null;
  }
}
