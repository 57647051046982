import {Injectable} from '@angular/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {Share} from '@capacitor/share';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {convertBlobToBase64} from 'src/app/utils/attachment-utils';
import {sanitizeForFilename} from 'submodules/baumaster-v2-common';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private translateService: TranslateService,
    private platform: Platform
  ) {}

  async downloadBlob(blob: Blob, filename: string, event?: Event) {
    if (!this.isDownloadNativePlatform()) {
      // Fallback to browser behavior
      return;
    }

    // Override browser behavior
    event?.preventDefault();
    event?.stopPropagation();
    event?.stopImmediatePropagation();

    await this.downloadNativePlatform(blob, filename);
  }

  public isDownloadNativePlatform(): boolean {
    return this.platform.is('capacitor');
  }

  public async downloadNativePlatform(blob: Blob, filename: string) {
    const result = await Filesystem.writeFile({
      path: sanitizeForFilename(filename, {replacement: '_', includesFileExt: true}),
      data: await convertBlobToBase64(blob),
      directory: Directory.Cache,
    });

    await Share.share({
      title: this.translateService.instant('download'),
      text: this.translateService.instant('download'),
      url: result.uri,
      dialogTitle: this.translateService.instant('download'),
    });
  }
}
