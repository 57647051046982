import {Injectable} from '@angular/core';
import {IdType, ProtocolEntryChat} from 'submodules/baumaster-v2-common';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {observableToPromise} from '../../utils/async-utils';

@Injectable({
  providedIn: 'root',
})
export class ProtocolEntryChatService {
  constructor(
    private protocolEntryChatDataService: ProtocolEntryChatDataService,
    private attachmentChatDataService: AttachmentChatDataService
  ) {}

  public async deleteProtocolEntryChat(chat: ProtocolEntryChat, projectId: IdType, protocolEntryId: IdType): Promise<void> {
    const attachmentChats = await observableToPromise(this.attachmentChatDataService.getByProtocolEntryAndChatAcrossProjects(protocolEntryId, chat.id));
    await this.attachmentChatDataService.delete(attachmentChats, projectId);
    await this.protocolEntryChatDataService.delete(chat, projectId);
  }
}
