import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {Address, IdType, LicenseType, Profile, Unit, UnitForBreadcrumbs, UnitLevel, UnitProfile} from 'submodules/baumaster-v2-common';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {AddressDataService} from '../../../services/data/address-data.service';
import _ from 'lodash';
import {EMAIL_REG_EXP_PATTERN, PHONE_NUMBER_PATTERN} from '../../../shared/constants';
import {UserService} from 'src/app/services/user/user.service';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {UnitDataService} from 'src/app/services/data/unit-data.service';
import {UnitProfileDataService} from 'src/app/services/data/unit-profile-data.service';
import {getAddressFormConfig} from 'src/app/utils/form-utils';
import {UnitService} from 'src/app/services/unit/unit.service';
import {UnitLevelDataService} from 'src/app/services/data/unit-level-data.service';
import {isPopoverDismissed, PopoverService} from 'src/app/services/ui/popover.service';
import {UnitActionsService} from 'src/app/services/units/unit-actions.service';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AddressFormComponent} from '../../common/address-form/address-form.component';
import {UnitSelectorDirective} from 'src/app/directives/common/unit-selector.directive';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AlertService} from 'src/app/services/ui/alert.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';

const LOG_SOURCE = 'UnitContactFormComponent';

interface UnitForBreadcrumbsWithAddress extends UnitForBreadcrumbs {
  unitProfile: UnitProfile;
  addressString: string;
}

@Component({
  selector: 'app-unit-contact-form',
  templateUrl: './unit-contact-form.component.html',
  styleUrls: ['./unit-contact-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IonicModule, TranslateModule, AddressFormComponent, UnitSelectorDirective, FormsModule, FontAwesomeModule],
})
export class UnitContactFormComponent implements OnChanges, OnDestroy, OnInit {
  @Input() readonly = false;
  @Input() addressId: string;
  @Input() prefillLastName: string | undefined;
  @Input() hideUnitsSelector = false;
  @Input() isEditMode: boolean;

  @Output() dirtyChange = new EventEmitter<boolean>();

  protected readonly EMAIL_REG_EXP_PATTERN = EMAIL_REG_EXP_PATTERN;
  protected readonly PHONE_NUMBER_PATTERN = PHONE_NUMBER_PATTERN;

  public units$: Observable<Array<Unit> | undefined> = this.unitDataService.data;
  public unitContactForm = this.formBuilder.group({
    salutation: [''],
    legalEntity: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: [''],
    phone: [''],
    externalId: [''],
    note: [''],
    address: this.formBuilder.group(getAddressFormConfig()),
    selectedUnitIds: this.formBuilder.control([]),
    dsgvoShowTel: [false],
    dsgvoShowEmail: [false],
  });

  private formDataSubscription: Subscription | undefined;
  private readonly destroy$ = new Subject<void>();
  private unitAdded = new Subject<void>();
  private unitAddedObservable = this.unitAdded.asObservable();

  allUnits$: Observable<Array<UnitForBreadcrumbs>>;
  unitLevels$: Observable<Array<UnitLevel>>;
  unitsWithAddressOrdered: Array<UnitForBreadcrumbsWithAddress> | undefined;
  isFeatureEnabled = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);

  constructor(
    private formBuilder: FormBuilder,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private userService: UserService,
    private unitDataService: UnitDataService,
    private unitProfileDataService: UnitProfileDataService,
    private unitService: UnitService,
    private unitLevelDataService: UnitLevelDataService,
    private popoverService: PopoverService,
    private unitActionsService: UnitActionsService,
    private alertService: AlertService,
    private featureEnabledService: FeatureEnabledService
  ) {}

  ngOnInit(): void {
    this.unitContactForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dirtyChange.emit(this.isDirty());
    });
    if (this.prefillLastName && !this.unitContactForm.get('lastName').dirty) {
      this.unitContactForm.get('lastName').setValue(this.prefillLastName);
      this.unitContactForm.get('lastName').markAsDirty();
    }
    this.allUnits$ = this.unitService.unitsForBreadcrumbs$;
    this.unitLevels$ = this.unitLevelDataService.data;

    combineLatestAsync([
      this.profileDataService.dataForOwnClient$,
      this.addressDataService.dataForOwnClientGroupedById,
      this.unitProfileDataService.data,
      this.unitService.unitsForBreadcrumbs$,
      this.unitAddedObservable.pipe(startWith(undefined)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([profiles, addressesById, unitProfiles, unitsForBreadcrumbs, unitAdded]) => {
        const address = addressesById[this.addressId];
        const profile = profiles.find((profileData) => profileData.addressId === address?.id);
        const unitIds = unitProfiles.filter((unitProfile) => unitProfile.profileId === profile?.id).map((unitProfile) => unitProfile.unitId);
        const unitsWithAddress = unitsForBreadcrumbs
          .filter((unit) => (this.unitContactForm.get('selectedUnitIds').value.length > 0 ? this.unitContactForm.get('selectedUnitIds').value.includes(unit.id) : unitIds.includes(unit.id)))
          .map((unit) => {
            const address = addressesById[unit.addressId];
            const unitProfile = unitProfiles.filter((unitProfile) => unitProfile.unitId === unit.id && unitProfile.profileId === profile?.id)?.[0];
            const addressString = address ? _.compact([address.street1, address.street2, _.compact([address.zipCode, address.city]).join(' '), address.country]).join(', ') : '';
            return {
              ...unit,
              addressString,
              unitProfile,
            } as UnitForBreadcrumbsWithAddress;
          });
        this.unitsWithAddressOrdered = _.orderBy(unitsWithAddress, [(unit) => unit.unitProfile?.isActive === false, 'orderNumber'], ['asc', 'asc']);
      });
  }

  markAsPristine() {
    this.unitContactForm.markAsPristine();
  }

  isDirty() {
    return this.unitContactForm.dirty;
  }

  getRawValue() {
    const rawData = this.unitContactForm.getRawValue();

    return {
      ...rawData,
      street1: rawData.address.street1,
      street2: rawData.address.street2,
      city: rawData.address.city,
      zipCode: rawData.address.zipCode,
      country: rawData.address.country,
    };
  }

  isValid() {
    return this.unitContactForm.valid;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.addressId && !_.isEmpty(this.addressId)) {
      this.initFormContactData();
    }
  }

  private formDataUnsubscribe() {
    this.formDataSubscription?.unsubscribe();
    this.formDataSubscription = undefined;
  }

  ngOnDestroy(): void {
    this.formDataUnsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initFormContactData() {
    this.unitContactForm.markAsPristine();
    this.formDataSubscription = combineLatestAsync([
      this.profileDataService.dataForOwnClient$,
      this.addressDataService.dataForOwnClient$,
      this.userService.currentUser$,
      this.unitProfileDataService.data,
    ])
      .pipe(
        map(([profiles, addresses, currentUser, unitProfiles]) => {
          const address = addresses.find((addressData) => addressData.id === this.addressId);
          const profile = profiles.find((profileData) => profileData.addressId === address.id);
          const unitIds = unitProfiles.filter((unitProfile) => unitProfile.profileId === profile.id).map((unitProfile) => unitProfile.unitId);
          return {
            address,
            profile,
            currentUser,
            unitIds,
          };
        })
      )
      .subscribe((data) => {
        const address = data.address;
        const profile = data.profile;
        const unitIds = data.unitIds;
        this.applyContactData(address, profile, unitIds);
      });
  }

  private applyIfPristine<T>(control: AbstractControl<T>, value: T) {
    if (control.pristine) {
      control.setValue(value, {onlySelf: true});
    }
  }

  private applyContactData(address: Address, profile: Profile, unitIds: Array<string>) {
    this.applyIfPristine(this.unitContactForm.get('salutation'), address.salutation);
    this.applyIfPristine(this.unitContactForm.get('legalEntity'), profile.legalEntity);
    this.applyIfPristine(this.unitContactForm.get('firstName'), address.firstName);
    this.applyIfPristine(this.unitContactForm.get('lastName'), address.lastName);
    this.applyIfPristine(this.unitContactForm.get('phone'), address.phone);
    this.applyIfPristine(this.unitContactForm.get('email'), address.email);
    this.applyIfPristine(this.unitContactForm.get('externalId'), profile.externalId);
    this.applyIfPristine(this.unitContactForm.get('note'), profile.note);
    this.applyIfPristine(this.unitContactForm.get('address').get('street1'), address.street1);
    this.applyIfPristine(this.unitContactForm.get('address').get('street2'), address.street2);
    this.applyIfPristine(this.unitContactForm.get('address').get('city'), address.city);
    this.applyIfPristine(this.unitContactForm.get('address').get('zipCode'), address.zipCode);
    this.applyIfPristine(this.unitContactForm.get('address').get('country'), address.country);
    this.applyIfPristine(this.unitContactForm.get('selectedUnitIds'), unitIds);
    this.applyIfPristine(this.unitContactForm.get('dsgvoShowTel'), profile.dsgvoShowTel);
    this.applyIfPristine(this.unitContactForm.get('dsgvoShowEmail'), profile.dsgvoShowEmail);
    this.unitContactForm.updateValueAndValidity();
  }

  addUnitIdToForm(unitId: IdType) {
    if (unitId && !this.unitContactForm.get('selectedUnitIds').value?.includes(unitId)) {
      this.unitContactForm.get('selectedUnitIds').setValue(this.unitContactForm.get('selectedUnitIds').value.concat([unitId]));
      this.unitContactForm.get('selectedUnitIds').markAsDirty();
      this.dirtyChange.emit(this.isDirty());
      this.unitAdded.next();
    }
  }

  removeUnit(unitId: IdType) {
    this.unitsWithAddressOrdered = this.unitsWithAddressOrdered.filter((unit) => unit.id !== unitId);
    this.unitContactForm.get('selectedUnitIds').setValue(this.unitContactForm.get('selectedUnitIds').value.filter((formUnitId) => formUnitId !== unitId));
    this.unitContactForm.get('selectedUnitIds').markAsDirty();
    this.dirtyChange.emit(this.isDirty());
  }

  async openPopover(event: {event: MouseEvent; unit: UnitForBreadcrumbsWithAddress}) {
    const isEnabled = await observableToPromise(this.isFeatureEnabled);
    const result = await this.popoverService.openActions(
      event.event,
      isEnabled
        ? [
            {
              label: 'unitContacts.units.openDetails',
              role: 'open_unit_modal',
              icon: ['fal', 'pen'],
            },
            {
              label: 'unitContacts.units.removeConnection',
              role: 'delete',
              icon: ['fal', 'trash-alt'],
            },
          ]
        : [
            {
              label: 'unitContacts.units.openDetails',
              role: 'open_unit_modal',
              icon: ['fal', 'pen'],
            },
          ]
    );

    if (isPopoverDismissed(result)) {
      return;
    }

    switch (result) {
      case 'open_unit_modal':
        this.unitActionsService.editUnit(event.unit);
        return;
      case 'delete':
        const confirm = await this.alertService.confirm({
          header: 'unitContacts.units.alertHeader',
          message: 'unitContacts.units.alertMessage',
          cancelLabel: 'cancel',
          confirmLabel: 'unitContacts.units.removeConnection',
          confirmButton: {
            color: 'danger',
            fill: 'solid',
          },
        });
        if (confirm) {
          this.removeUnit(event.unit.id);
        }
        return;
    }
  }
}
