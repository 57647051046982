import {IdType, ProtocolEntry, ProtocolEntryStatus, ProtocolEntryType, ProtocolOpenEntry} from 'submodules/baumaster-v2-common';
import {EntryCardModel} from '../model/entry-card-model';
import {ProtocolEntryOrOpen} from '../model/protocol';
import _ from 'lodash';

export function findPreviousTask(tasks: EntryCardModel[], previouslyCurrentTaskId: IdType, oldTasks: EntryCardModel[]): EntryCardModel | undefined {
  if (!tasks.length) {
    return undefined;
  }
  let index = oldTasks.findIndex((task) => task.id === previouslyCurrentTaskId);
  for (; index >= 0; index--) {
    const oldTask = oldTasks[index];
    const task = tasks.find((t) => t.id === oldTask.id);
    if (task) {
      return task;
    }
  }
  if (index === -1) {
    return tasks[0]; // should not happen but previousTaskId is not in previousTasks. Just return the first new one.
  }
}

export function filterParentEntry(protocolEntry: ProtocolEntry) {
  return protocolEntry.parentId === null || protocolEntry.parentId === undefined;
}

export function filterChildEntry(protocolEntry: ProtocolEntry) {
  return !!protocolEntry.parentId;
}

export function toProtocolEntryOrOpen(protocolEntry: ProtocolEntry, isOpenEntry: boolean, newProtocolId?: IdType): ProtocolEntryOrOpen {
  const protocolEntryOrOpen = {isOpenEntry, ...protocolEntry} as ProtocolEntryOrOpen;

  if (newProtocolId) {
    protocolEntryOrOpen.originalProtocolId = protocolEntryOrOpen.protocolId;
    protocolEntryOrOpen.protocolId = newProtocolId;
  }

  return protocolEntryOrOpen;
}

// Use this method only if you want to convert all protocolEntries of a "project".
export function convertAllToProtocolEntriesOrOpen(
  allProtocolEntries: Array<ProtocolEntry>,
  protocolOpenEntries: Array<ProtocolOpenEntry>,
  filteredProtocolEntries?: Array<ProtocolEntry>
): Array<ProtocolEntryOrOpen> {
  const protocolEntriesById = _.keyBy(allProtocolEntries, 'id');
  const result = (filteredProtocolEntries || allProtocolEntries).map((protocolEntry) => toProtocolEntryOrOpen(protocolEntry, false));
  for (const protocolOpenEntry of protocolOpenEntries) {
    const protocolEntry = protocolEntriesById[protocolOpenEntry.protocolEntryId];
    if (protocolEntry) {
      // it is possible, that the protocolEntry cannot be found temporarily if the data for all projects is not yet loaded
      result.push(toProtocolEntryOrOpen(protocolEntry, true, protocolOpenEntry.protocolId));
    }
  }
  return result;
}

export function filterByUnfinishedOrParentOfUnfinished(
  isProtocolLayoutShort: boolean,
  protocolEntryTypes: Array<ProtocolEntryType>,
  protocolEntriesWithUnfinishedChildren?: IdType[]
): (protocolEntry: ProtocolEntry) => boolean {
  return (protocolEntry: ProtocolEntry): boolean => {
    const isStatusFieldActive: boolean = !!protocolEntry.typeId && protocolEntryTypes.some((protocolEntryType) => protocolEntryType.id === protocolEntry.typeId && protocolEntryType.statusFieldActive);
    return (
      ((isProtocolLayoutShort || isStatusFieldActive) && protocolEntry.status !== ProtocolEntryStatus.DONE) ||
      (!isProtocolLayoutShort && !isStatusFieldActive && !!protocolEntry.isContinuousInfo) ||
      (!!protocolEntriesWithUnfinishedChildren?.length && protocolEntriesWithUnfinishedChildren.includes(protocolEntry.id))
    );
  };
}

export function getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(
  protocolEntries: Array<ProtocolEntryOrOpen>,
  protocolEntryTypes: Array<ProtocolEntryType>,
  isProtocolLayoutShort: boolean,
  protocolEntriesMovedToNewProtocol?: Array<ProtocolEntry>
): Array<ProtocolEntryOrOpen> {
  let protocolEntriesWithUnfinishedChildren: IdType[] = protocolEntries
    .filter(filterChildEntry)
    .filter(filterByUnfinishedOrParentOfUnfinished(isProtocolLayoutShort, protocolEntryTypes, undefined))
    .map((protocolEntry) => protocolEntry.parentId);
  if (protocolEntriesMovedToNewProtocol?.length) {
    const protocolEntriesWithMovedChildren = protocolEntriesMovedToNewProtocol.filter(filterChildEntry).map((protocolEntry) => protocolEntry.parentId);
    protocolEntriesWithUnfinishedChildren = _.uniq([...protocolEntriesWithUnfinishedChildren, ...protocolEntriesWithMovedChildren]);
  }

  return protocolEntries.filter(filterByUnfinishedOrParentOfUnfinished(!!isProtocolLayoutShort, protocolEntryTypes, protocolEntriesWithUnfinishedChildren));
}
