import {Component, OnInit} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticateRes} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss'],
})
export class LoginAsComponent implements OnInit {
  private modal: HTMLIonModalElement;

  email = '';

  lastError = '';

  loginCallback?: (username: string) => Promise<AuthenticateRes>;

  constructor(
    private translateService: TranslateService,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.loadingController.dismiss();
  }

  dismiss() {
    this.modal.dismiss(undefined, 'cancel');
  }

  async submitForm() {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('LOGIN.login_done'),
    });
    try {
      await loading.present();
      const auth = await this.loginCallback?.(this.email);
      if (auth) {
        this.modal.dismiss(auth);
      }
    } catch (e) {
      this.lastError = 'LOGIN.superuser.user_not_found';
    } finally {
      await loading.dismiss();
    }
  }
}
