import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {SelectableService} from 'src/app/services/common/selectable.service';
import {NameableDropdownDataService} from 'src/app/services/data/nameable-dropdown-data.service';
import {NameableDropdownItemDataService} from 'src/app/services/data/nameable-dropdown-item-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {combineLatestAsync, filterByObservableObjectProperty} from 'src/app/utils/async-utils';
import {Client, IdType, LicenseType, NameableDropdown, Project, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {ClientService} from '../../../services/client/client.service';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {SelectableChangeType} from '../../common/selectable/selectable.component';

@Component({
  selector: 'app-protocol-additional-fields',
  templateUrl: './protocol-additional-fields.component.html',
  styleUrls: ['./protocol-additional-fields.component.scss'],
})
export class ProtocolAdditionalFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() acrossProjects = true;
  @Input() disabled = false;
  @Input() protocolEntry: ProtocolEntry;
  @Input() initNameableDropdownId: IdType;
  @Output() additionalFieldsChange = new EventEmitter<string | null>();

  public dropdownSubscription: Subscription | undefined;
  public dropdownList: Array<NameableDropdown> | undefined = [];
  public disabledDropdownList: Array<NameableDropdown> | undefined = [];
  public allNameableDropdowns: Array<NameableDropdown> | undefined = [];
  public selectedItem: NameableDropdown | null = null;
  public clientData: Observable<Client | undefined>;
  private protocolEntrySubject = new ReplaySubject<ProtocolEntry | undefined>(1);
  public dropdownListAll: Array<NameableDropdown> | undefined = [];
  public allowCreatedProjectSettings$: Observable<boolean>;
  private pendingNameableDropdownId: IdType | undefined = undefined;

  private protocolEntryIdSubject = new ReplaySubject<IdType | undefined>(1);

  project$: Observable<Project | undefined>;

  createNewAdditionalFieldFunction = this.createNewAdditionalFieldFunctionFn();
  assignAdditionalFieldToProjectFunction = this.assignAdditionalFieldToProjectFunctionFn();

  constructor(
    private nameableDropdownDataService: NameableDropdownDataService,
    private nameableDropdownItemDataService: NameableDropdownItemDataService,
    private clientService: ClientService,
    private projectDataService: ProjectDataService,
    private protocolService: ProtocolService,
    private selectableService: SelectableService,
    private featureEnabledService: FeatureEnabledService
  ) {}

  ngOnInit() {
    this.project$ = this.acrossProjects
      ? this.protocolEntryIdSubject.pipe(
          switchMap((id) => this.protocolService.getProjectByEntryId(id)),
          switchMap((project) => (!project ? this.projectDataService.currentProjectObservable : of(project)))
        )
      : this.projectDataService.currentProjectObservable;
    this.clientData = this.clientService.currentClient$;
    this.dropdownSubscription = combineLatestAsync([
      this.nameableDropdownDataService.dataAcrossClientsWithDeletedSuffix$.pipe(filterByObservableObjectProperty(this.project$, 'clientId', 'clientId')),
      (this.acrossProjects ? this.nameableDropdownItemDataService.dataAcrossProjects$ : this.nameableDropdownItemDataService.data).pipe(
        filterByObservableObjectProperty(this.project$, 'projectId', 'id')
      ),
      this.protocolEntrySubject,
    ]).subscribe(([dropdowns, dropdownItems, protocolEntry]) => {
      this.allNameableDropdowns = dropdowns;
      this.dropdownList = dropdowns.filter(
        (dropdown) => (dropdownItems.find((dropdownItem) => dropdownItem.nameabledropdownId === dropdown.id) && dropdown.isActive !== false) || protocolEntry?.nameableDropdownId === dropdown.id
      );
      this.disabledDropdownList = this.dropdownList.filter((dropdown) => dropdown.isActive === false);
      this.dropdownListAll = dropdowns.filter((dropdown) => dropdown.isActive === undefined || dropdown.isActive === true);
      if (this.pendingNameableDropdownId) {
        this.setSelectedItem(this.pendingNameableDropdownId);
        this.pendingNameableDropdownId = undefined;
      } else {
        this.setSelectedItem();
      }
    });
    this.allowCreatedProjectSettings$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.protocolEntry) {
      this.protocolEntryIdSubject.next(this.protocolEntry?.id);
      this.protocolEntrySubject.next(this.protocolEntry);
      this.setSelectedItem();
    }
    if (changes.initNameableDropdownId) {
      if (this.allNameableDropdowns?.length) {
        this.setSelectedItem(this.initNameableDropdownId);
      } else {
        this.pendingNameableDropdownId = this.initNameableDropdownId;
      }
    }
  }

  setSelectedItem(nameableDropdownId?: IdType) {
    if (this.protocolEntry && this.dropdownList) {
      this.selectedItem = this.allNameableDropdowns.find((dropdownItem) => dropdownItem.id === this.protocolEntry?.nameableDropdownId);
    } else if (!this.protocolEntry && nameableDropdownId) {
      this.selectedItem = this.allNameableDropdowns.find((dropdownItem) => dropdownItem.id === nameableDropdownId);
    } else {
      this.selectedItem = null;
    }
  }

  clearAdditionalField() {
    this.additionalFieldsChange.emit(null);
  }

  onAdditionalFieldsChange(event: SelectableChangeType<NameableDropdown>) {
    const value = event.value;
    const nameableDropdownId = value ? value.id : null;
    this.additionalFieldsChange.emit(nameableDropdownId);
  }

  ngOnDestroy() {
    if (this.dropdownSubscription) {
      this.dropdownSubscription.unsubscribe();
      this.dropdownSubscription = undefined;
    }
  }

  private createNewAdditionalFieldFunctionFn(): (text?: string) => Promise<NameableDropdown | undefined> {
    return (text?: string) => this.selectableService.createNameableDropdown(text);
  }

  private assignAdditionalFieldToProjectFunctionFn(): (item: NameableDropdown, assign: boolean) => Promise<boolean> {
    return (item: NameableDropdown, assign: boolean) => this.selectableService.assignNameableDropdownToProject(item, assign);
  }
}
