import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {Country, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {map} from 'rxjs';

const REST_ENDPOINT_URI = 'api/data/countries/';

@Injectable({
  providedIn: 'root',
})
export class CountryDataService extends AbstractClientAwareDataService<Country> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(StorageKeyEnum.COUNTRY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, integrityResolverService);
  }

  readonly dataSorted$ = this.data.pipe(
    map((items) =>
      items.sort((a: {name: string}, b: {name: string}) => {
        const prioritizedNames = ['Österreich', 'Deutschland', 'Schweiz'];
        const indexA = prioritizedNames.indexOf(a.name);
        const indexB = prioritizedNames.indexOf(b.name);

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        if (indexA !== -1) {
          return -1;
        }

        if (indexB !== -1) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      })
    )
  );

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
