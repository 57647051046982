import {Injectable} from '@angular/core';
import {generateFilePathsForPdfPlanAttachmentOrPage, IdType, MIME_TYPE_PDF, MIME_TYPES_CAD, PdfPlanAttachment, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import * as _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanAttachments';

@Injectable({
  providedIn: 'root',
})
export class PdfPlanAttachmentDataService extends AbstractProjectAwareAttachmentDataService<PdfPlanAttachment> {
  dataGroupedByPdfPlanVersionId$: Observable<Record<IdType, PdfPlanAttachment>> = this.data.pipe(
    map((attachments) =>
      _.keyBy(
        attachments.filter((attachment) => attachment.mimeType === MIME_TYPE_PDF),
        'pdfPlanVersionId'
      )
    ),
    shareReplay(1)
  );

  dataCadFilesGroupedByPdfPlanVersionId$: Observable<Record<IdType, Array<PdfPlanAttachment>>> = this.data.pipe(
    map((attachments) =>
      _.groupBy(
        attachments.filter((attachment) => MIME_TYPES_CAD.includes(attachment.mimeType)),
        'pdfPlanVersionId'
      )
    ),
    shareReplay(1)
  );

  constructor(
    http: HttpClient,
    storage: StorageService,
    private toastController: ToastController,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    loggingService: LoggingService,
    systemEventService: SystemEventService,
    protected devModeService: DevModeService,
    integrityResolverService: IntegrityResolverService,
    protected translateService: TranslateService,
    protected attachmentSettingService: AttachmentSettingService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService
  ) {
    super(
      StorageKeyEnum.PDF_PLAN_ATTACHMENT,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      systemEventService,
      devModeService,
      integrityResolverService,
      translateService,
      attachmentSettingService,
      projectAvailabilityExpirationService,
      VERSION_INTRODUCED_DEFAULT,
      ATTACHMENT_DEFAULT_SORT_COLUMNS,
      ATTACHMENT_DEFAULT_SORT_ORDER
    );
  }

  protected setGeneratedFilePath(attachment: PdfPlanAttachment) {
    attachment.filePath = generateFilePathsForPdfPlanAttachmentOrPage(attachment).filePath;
  }

  public getByPdfPlanVersionId$(pdfPlanVersionId: IdType): Observable<PdfPlanAttachment | undefined> {
    return this.data.pipe(
      map((pdfPlanAttachments) => pdfPlanAttachments.find((pdfPlanAttachment) => pdfPlanAttachment.pdfPlanVersionId === pdfPlanVersionId && pdfPlanAttachment.mimeType === MIME_TYPE_PDF))
    );
  }

  public getCadFilesByPdfPlanVersionId$(pdfPlanVersionId: IdType): Observable<Array<PdfPlanAttachment>> {
    return this.data.pipe(
      map((pdfPlanAttachments) => pdfPlanAttachments.filter((pdfPlanAttachment) => pdfPlanAttachment.pdfPlanVersionId === pdfPlanVersionId && MIME_TYPES_CAD.includes(pdfPlanAttachment.mimeType)))
    );
  }

  public getByPdfPlanVersionIdAcrossProjects$(pdfPlanVersionId: IdType): Observable<PdfPlanAttachment | undefined> {
    return this.dataAcrossProjects$.pipe(map((pdfPlanAttachments) => pdfPlanAttachments.find((pdfPlanAttachment) => pdfPlanAttachment.pdfPlanVersionId === pdfPlanVersionId)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
