import {ColumnConfig} from '../ColumnConfig';

export const AttachmentColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, pk: true},
  {name: 'hash'},
  {name: 'file_ext', prop: 'fileExt'},
  {
    name: 'markings',
    def: null,
    cast: 'Jsonb',
    castOptions: {
      stringifyObject: true,
    },
  },
  {name: 'mime_type', prop: 'mimeType'},
  {name: 'file_name', prop: 'fileName', def: null},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cnd: true, cast: 'Timestamp'},
  {name: 'width', prop: 'width', def: null},
  {name: 'height', prop: 'height', def: null},
  {name: 'original_creation_date', prop: 'originalCreationDate', def: null, cast: 'Date'},
  {name: 'latitude', prop: 'latitude', def: null},
  {name: 'longitude', prop: 'longitude', def: null},
];

export const HistColumnSet: Array<ColumnConfig> = [
  {name: 'hist_timestamp', prop: 'histTimestamp', cnd: true, cast: 'Timestamp'},
  {name: 'hist_operation_type', prop: 'histOperationType', cnd: true},
];

export const AttachmentHistColumnSet: Array<ColumnConfig> = [...AttachmentColumnSet, ...HistColumnSet];
