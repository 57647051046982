import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';

type ParentIdAware = {parentId?: Nullish<IdType>};
type SimplifiedProtocolEntry = IdAware & ParentIdAware;

const isProtocolEntriesEqual = (a: SimplifiedProtocolEntry) => (b: SimplifiedProtocolEntry) => a && b && a.id === b.id;
const isProtocolEntriesDifferent = (a: SimplifiedProtocolEntry) => (b: SimplifiedProtocolEntry) => !a || !b || a.id !== b.id;

@Injectable()
export class ProtocolEntrySelectionService<T extends SimplifiedProtocolEntry = SimplifiedProtocolEntry> {
  private selectedEntriesSubject = new BehaviorSubject<T[]>([]);
  selectedEntries$ = this.selectedEntriesSubject.asObservable();
  get selectedEntries() {
    return this.selectedEntriesSubject.getValue();
  }

  private isSelectModeSubject = new BehaviorSubject<boolean>(false);
  isSelectMode$ = this.isSelectModeSubject.asObservable();
  get isSelectMode() {
    return this.isSelectModeSubject.getValue();
  }

  private includingSubentriesSubject = new BehaviorSubject<boolean>(false);
  includingSubentries$ = this.includingSubentriesSubject.asObservable();
  get includingSubentries() {
    return this.includingSubentriesSubject.getValue();
  }

  private allEntriesSubject = new BehaviorSubject<T[]>([]);
  allEntries$ = this.allEntriesSubject.asObservable();
  get allEntries() {
    return this.allEntriesSubject.getValue();
  }

  hasAllSelected$ = this.allEntries$.pipe(
    switchMap((allEntries) => this.selectedEntries$.pipe(map((selectedEntries) => allEntries.every((entry) => selectedEntries.some(isProtocolEntriesEqual(entry))))))
  );

  get hasAllSelected() {
    return this.allEntries.every((entry) => this.selectedEntries.some(isProtocolEntriesEqual(entry)));
  }

  hasAnySelected$ = this.selectedEntries$.pipe(map((entries) => entries.length > 0));

  get hasAnySelected() {
    return this.selectedEntries.length > 0;
  }

  leaveSelectModeOnAllUnselected = true;

  constructor() {}

  setAllEntries(entries: T[]) {
    const {selectedEntries} = this;
    const newSelectedEntries = selectedEntries.filter((entry) => entries.some(isProtocolEntriesEqual(entry)));
    if (newSelectedEntries.length !== selectedEntries.length) {
      this.selectedEntriesSubject.next(newSelectedEntries);
    }
    this.allEntriesSubject.next(entries);
  }

  selectAll() {
    this.selectedEntriesSubject.next([...this.allEntries]);
    this.enterSelectMode();
  }

  unselectAll() {
    this.selectedEntriesSubject.next([]);
    if (this.leaveSelectModeOnAllUnselected && this.isSelectMode) {
      this.leaveSelectMode();
    }
  }

  select(protocolEntry: T) {
    if (!this.selectedEntries.some(isProtocolEntriesEqual(protocolEntry))) {
      this.selectedEntriesSubject.next([...this.selectedEntries, protocolEntry]);
    }
  }

  unselect(protocolEntry: T) {
    if (!protocolEntry) {
      return;
    }
    this.selectedEntriesSubject.next(this.selectedEntries.filter(isProtocolEntriesDifferent(protocolEntry)));
    if (this.leaveSelectModeOnAllUnselected && this.selectedEntries.length === 0 && this.isSelectMode) {
      this.leaveSelectMode();
    }
  }

  private doForEveryChildren(protocolEntry: T, fn: (entry: T) => void) {
    this.allEntries.filter((entry) => entry.parentId === protocolEntry.id).forEach(fn);
  }

  selectChildren(protocolEntry: T) {
    this.doForEveryChildren(protocolEntry, (entry) => this.select(entry));
  }

  unselectChildren(protocolEntry: T) {
    this.doForEveryChildren(protocolEntry, (entry) => this.unselect(entry));
  }

  toggle(protocolEntry: T) {
    if (this.selectedEntries.some(isProtocolEntriesEqual(protocolEntry))) {
      this.unselect(protocolEntry);

      if (this.includingSubentries) {
        this.unselectChildren(protocolEntry);
      }

      return;
    }

    this.select(protocolEntry);

    if (this.includingSubentries) {
      this.selectChildren(protocolEntry);
    }
  }

  enterSelectMode() {
    this.isSelectModeSubject.next(true);
  }

  leaveSelectMode() {
    this.isSelectModeSubject.next(false);
    this.unselectAll();
    this.setIncludingSubentries(false);
  }

  toggleSelectMode() {
    if (this.isSelectMode) {
      this.leaveSelectMode();
    } else {
      this.enterSelectMode();
    }
  }

  setIncludingSubentries(includingSubentries: boolean) {
    this.includingSubentriesSubject.next(includingSubentries);
  }

  isSelected$(protocolEntry: T) {
    return this.selectedEntries$.pipe(
      map((entries) => entries.some(isProtocolEntriesEqual(protocolEntry))),
      distinctUntilChanged()
    );
  }
}
