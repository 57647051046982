import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractDataService, DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {ProjectDataService} from './project-data.service';
import {combineLatest, interval, Observable, ReplaySubject, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IdAware, IdType, Project, User} from 'submodules/baumaster-v2-common';
import {debounceTime, delayWhen, distinctUntilChanged, map, shareReplay, take} from 'rxjs/operators';
import _ from 'lodash';
import {LoggingService} from '../common/logging.service';
import {INIT_AFTER_APP_START_DELAY_IN_MS, STORAGE_KEY_PROJECT_SEPARATOR, StorageKeyEnum} from '../../shared/constants';
import {LocalChanges} from '../../model/local-changes';
import {flattenMap} from 'src/app/utils/data-utils';
import {UserService} from '../user/user.service';
import {StorageMutationOptions, StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {combineLatestAsync} from '../../utils/async-utils';

@Injectable()
export abstract class AbstractProjectAwareDataService<T extends IdAware> extends AbstractDataService<T> implements OnDestroy {
  protected readonly idColumn = 'id';
  protected readonly restEndpointUrl = environment.serverUrl + this.restEndpointUri;
  public readonly dataGroupedById: Observable<Record<IdType, T>> = this.data.pipe(map((entities) => (entities === null ? {} : _.keyBy(entities, 'id'))));
  private readonly authSubscription: Subscription;
  private projectsSubscription: Subscription;
  private currentProjectAuthSubscription: Subscription;
  private projects: Array<Project> = [];
  protected currentProjectId: IdType | undefined;
  public readonly dataByProjectId = new Map<IdType, Array<T>>();
  private readonly dataByProjectIdSubject = new ReplaySubject<Map<IdType, Array<T>>>(1);
  public readonly dataByProjectId$: Observable<Map<IdType, Array<T>>> = this.dataByProjectIdSubject.asObservable();
  private allProjectsSubscription: Subscription | undefined;
  public readonly dataAcrossProjects$ = this.dataByProjectId$.pipe(map(flattenMap));
  public readonly dataAcrossProjectsGroupedById: Observable<Record<IdType, T>> = this.dataAcrossProjects$.pipe(map((entities) => (entities === null ? {} : _.keyBy(entities, 'id'))));
  private firstAllProjectsDelay$ = interval(INIT_AFTER_APP_START_DELAY_IN_MS).pipe(take(1), shareReplay(1));
  private readonly availableProjectIdsAndCurrent$: Observable<Array<IdType>>;

  constructor(
    @Inject('StorageKeyEnum') storageKey: StorageKeyEnum,
    @Inject('String') protected readonly restEndpointUri: string,
    @Inject('Array') protected readonly defaultValue: Array<T>,
    protected http: HttpClient,
    protected storage: StorageService,
    protected authenticationService: AuthenticationService,
    protected userService: UserService,
    protected projectDataService: ProjectDataService,
    protected loggingService: LoggingService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    protected integrityResolverService: IntegrityResolverService,
    public versionIntroduced = VERSION_INTRODUCED_DEFAULT,
    @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
    @Inject('Array') protected sortColumnOrders?: Array<'asc' | 'desc'>
  ) {
    super(false, true, storageKey, restEndpointUri, defaultValue, http, storage, authenticationService, loggingService, integrityResolverService, versionIntroduced, sortColumns, sortColumnOrders);
    this.currentProjectAuthSubscription = combineLatest([projectDataService.currentProjectObservable, authenticationService.isAuthenticated$])
      .pipe(debounceTime(0))
      .subscribe(async ([currentProject, isAuthenticated]) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          const currentProjectId = currentProject ? currentProject.id : undefined;
          this.currentProjectId = currentProjectId;
          if (currentProjectId) {
            let data = this.dataByProjectId.get(currentProjectId);
            if (!data) {
              data = await this.getDataFromStorageOrServer(currentProjectId);
              data = data ? this.ensureDataSorted(data) : this.defaultValue;
            }
            this.loggingService.debug(this.logSource, `dataSubject.next(${data?.length}) in combineLatest`);
            this.setNextData(data, currentProjectId);
            await this.initLocalChangesSubject(currentProjectId);
          }
        } else {
          await this.removeAllStorageData();
          this.loggingService.debug(this.logSource, `dataSubject.next(null) in combineLatest`);
          this.dataSubject.next(null);
          this.clearDataSubjectByProjectId();
          this.localChangesSubject.next(new LocalChanges<T>());
        }
      });

    this.availableProjectIdsAndCurrent$ = combineLatestAsync([projectAvailabilityExpirationService.availableProjectIds$, this.projectDataService.currentProjectObservable])
      .pipe(
        map(([availableProjectIds, currentProject]) => {
          if (!currentProject || availableProjectIds.includes(currentProject?.id)) {
            return availableProjectIds;
          }
          return [...availableProjectIds, currentProject.id];
        })
      )
      .pipe(distinctUntilChanged((a, b) => _.xor(a, b).length === 0));

    this.allProjectsSubscription = combineLatest([authenticationService.isAuthenticated$, projectDataService.dataAcrossClientsActive$, this.availableProjectIdsAndCurrent$])
      .pipe(debounceTime(0))
      .pipe(delayWhen(() => this.firstAllProjectsDelay$))
      .subscribe(async ([isAuthenticated, projects, availableProjectIds]) => {
        if (isAuthenticated) {
          const dataByProjectIdToAdd = new Map<IdType, Array<T>>();
          const availableProjects = projects.filter((project) => availableProjectIds.includes(project.id));
          for (const project of availableProjects) {
            const projectId = project.id;
            if (!this.dataByProjectId.has(projectId)) {
              let data = await this.getDataFromStorageOrServer(projectId);
              if (data) {
                data = data ? this.ensureDataSorted(data) : this.defaultValue;
                dataByProjectIdToAdd.set(projectId, data);
              }
            }
          }
          let added = false;
          let deleted = false;

          for (const projectId of dataByProjectIdToAdd.keys()) {
            if (!this.dataByProjectId.has(projectId)) {
              this.dataByProjectId.set(projectId, dataByProjectIdToAdd.get(projectId));
              added = true;
            }
          }

          for (const projectId of this.dataByProjectId.keys()) {
            const project = availableProjects.find((value) => value.id === projectId);
            if (!project) {
              const deletedOne = this.dataByProjectId.delete(projectId);
              this.unsetStorageInitialized(projectId);
              deleted = deleted || deletedOne;
            }
          }
          if (added || deleted) {
            this.dataByProjectIdSubject.next(this.dataByProjectId);
          }
          await this.initLocalChangesByClientOrProjectSubject();
        } else {
          this.localChangesByClientOrProjectSubject.next(new Map());
        }
      });

    this.userService.currentUser$.subscribe((currentUser) => {
      if (!currentUser) {
        this.hasCurrentUserPermission = undefined;
      } else {
        this.hasCurrentUserPermission = this.checkHasCurrentUserPermission(currentUser);
      }
    });

    this.projectsSubscription = projectDataService.dataAcrossClientsActive$.subscribe(async (projects) => {
      this.projects = projects;
    });
  }

  private setNextData(values: Array<T>, projectId: IdType) {
    this.dataSubject.next(values);
    this.dataByProjectId.set(projectId, values);
    this.dataByProjectIdSubject.next(this.dataByProjectId);
  }

  private setNextDataOtherProject(values: Array<T>, projectId: IdType) {
    this.dataByProjectId.set(projectId, values);
    this.dataByProjectIdSubject.next(this.dataByProjectId);
  }

  private clearDataSubjectByProjectId() {
    this.dataByProjectId.clear();
    this.dataByProjectIdSubject.next(this.dataByProjectId);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentProjectId = undefined;
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.projectsSubscription) {
      this.projectsSubscription.unsubscribe();
      this.projectsSubscription = null;
    }
    if (this.currentProjectAuthSubscription) {
      this.currentProjectAuthSubscription.unsubscribe();
      this.currentProjectAuthSubscription = null;
    }
    if (this.allProjectsSubscription) {
      this.allProjectsSubscription.unsubscribe();
      this.allProjectsSubscription = undefined;
    }
  }

  public async insertUpdateDelete(
    changes: {
      inserts?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      insertOptions?: DataServiceInsertOptions;
      updates?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      deletes?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      deleteOptions?: DataServiceDeleteOptions;
    },
    projectId: IdType
  ): Promise<Array<T>> {
    return await super.insertUpdateDeleteInternal(changes, projectId);
  }

  public async insertOrUpdate(valueOrArray: T | Array<T>, projectId: IdType): Promise<Array<T>> {
    return await super.insertOrUpdate(valueOrArray, projectId);
  }

  public async insert(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), projectId: IdType, options?: DataServiceInsertOptions): Promise<Array<T>> {
    return await super.insertInternal(valueArrayOrFunction, projectId, options);
  }

  public async update(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), projectId: IdType): Promise<Array<T>> {
    return await super.updateInternal(valueArrayOrFunction, projectId);
  }

  public async delete(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), projectId: IdType, options?: DataServiceDeleteOptions): Promise<void> {
    await super.deleteInternal(valueArrayOrFunction, projectId, options);
  }

  protected async storageDataChanged(data: Array<T>, projectId: IdType): Promise<void> {
    const values = this.ensureDataSorted(data);
    if (this.currentProjectId === projectId) {
      this.loggingService.debug(this.logSource, `storageDataChanged(${data?.length} entries).`);
      this.setNextData(values, projectId);
    } else {
      this.loggingService.debug(this.logSource, `storageDataChanged(${data?.length} for project ${projectId} entries).`);
      this.setNextDataOtherProject(values, projectId);
    }
  }

  protected async removeAllStorageData(): Promise<void> {
    const storageKeysToDelete = (await this.storage.keys()).filter((key) => this.isStorageKey(key) || this.isStorageKeyLocalChanges(key));
    for (const storageKey of storageKeysToDelete) {
      this.loggingService.debug(this.logSource, `removeAllStorageData - before storage.remove(${storageKey})`);
      await this.storage.remove(storageKey);
      await this.storage.remove(this.getStorageKeyLocalChanges());
      this.loggingService.debug(this.logSource, `removeAllStorageData - before storage.remove(${storageKey})`);
    }
    this.clearStorageInitialized();
  }

  public async removeAllStorageDataButForProjects(
    keepProjectIds: Array<IdType>,
    mutationOptions?: Partial<StorageMutationOptions>
  ): Promise<{deletedElements: Array<T>; deletedProjectIds: Array<IdType>}> {
    const storageKeysToKeep = keepProjectIds.map((projectId) => this.getStorageKey(projectId)).concat(keepProjectIds.map((projectId) => this.getStorageKeyLocalChanges(projectId)));
    const deleteStorageKeys = (await this.storage.keys()).filter(
      (key) => (this.isStorageKey(key) || this.isStorageKeyLocalChanges(key)) && (storageKeysToKeep === undefined || !storageKeysToKeep.find((storageKeyToKeep) => storageKeyToKeep === key))
    );
    let deletedElements = new Array<T>();
    for (const storageKey of deleteStorageKeys) {
      const elementToDelete: Array<T> | null = await this.storage.get(storageKey);
      if (elementToDelete && elementToDelete.length) {
        deletedElements = deletedElements.concat(elementToDelete);
      }
      await this.storage.remove(storageKey, mutationOptions);
      this.loggingService.info(this.logSource, `Removed data with key ${storageKey}.`);
    }
    const deletedProjectIds = _.compact(deleteStorageKeys.map((storageKey) => storageKey.substring((this.storageKey + STORAGE_KEY_PROJECT_SEPARATOR).length)));
    deletedProjectIds.forEach((projectId) => this.unsetStorageInitialized(projectId));
    return {deletedElements, deletedProjectIds};
  }

  public async removeStorageDataForProject(projectId: IdType): Promise<Array<T>> {
    const storageKey = this.getStorageKey(projectId);
    const deletedElements: Array<T> | null = await this.storage.get(storageKey);
    await this.storage.remove(storageKey);
    await this.storage.remove(this.getStorageKeyLocalChanges(projectId));
    this.unsetStorageInitialized(projectId);
    return deletedElements || [];
  }

  public getByIdAcrossProjects(id: IdType): Observable<T | undefined> {
    return this.dataByProjectId$.pipe(
      map((dataByProjectId) => {
        if (!dataByProjectId) {
          return undefined;
        }
        return _.flatten(Array.from(dataByProjectId.values())).find((data) => data.id === id);
      })
    );
  }

  public getByIdsAcrossProjects(ids: IdType[]): Observable<T[] | undefined> {
    return this.dataByProjectId$.pipe(
      map((dataByProjectId) => {
        if (!dataByProjectId) {
          return undefined;
        }
        return _.flatten(Array.from(dataByProjectId.values())).filter((data) => ids.includes(data.id));
      })
    );
  }

  public getByIdAcrossProjectsWithProjectId(id: IdType): Observable<{projectId: IdType; object: T} | undefined> {
    return this.dataByProjectId$.pipe(
      map((dataByProjectId) => {
        if (!dataByProjectId) {
          return undefined;
        }
        let object: T | undefined;
        for (const projectId of dataByProjectId.keys()) {
          object = dataByProjectId.get(projectId).find((data) => data.id === id);
          if (object) {
            return {projectId, object};
          }
        }
        return undefined;
      })
    );
  }

  public getDataForProject$(projectId: IdType): Observable<Array<T> | undefined> {
    return this.dataByProjectId$.pipe(map((dataByProjectId) => dataByProjectId.get(projectId)));
  }

  public getDataForProject(projectId: IdType): Array<T> | undefined {
    return this.dataByProjectId.get(projectId);
  }

  public getDataForProjects$(projectIds: IdType[]): Observable<Array<T> | undefined> {
    return this.dataByProjectId$.pipe(
      map((dataByProjectId) =>
        projectIds.reduce((acc: T[], id) => {
          const data = dataByProjectId.get(id);
          if (!data) {
            return acc;
          }

          return acc.concat(data);
        }, [])
      )
    );
  }

  public getDataFromMemory(clientOrProjectId?: IdType): Array<T> | undefined {
    return this.getDataForProject(clientOrProjectId);
  }

  protected abstract checkHasCurrentUserPermission(currentUser: User): boolean;
}
