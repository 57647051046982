import {Component, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {Observable, Subscription} from 'rxjs';
import {AttachmentProtocolEntry, IdType, ProtocolEntry, ProtocolLayout} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {AlertController, NavParams} from '@ionic/angular';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ProjectService} from '../../../services/project/project.service';
import {ProjectWithOffline} from '../../../model/project-with-offline';
import {TranslateService} from '@ngx-translate/core';
import {PROTOCOL_LAYOUT_NAME_SHORT, ToastDurationInMs} from '../../../shared/constants';
import {CopyProtocolEntryService} from '../../../services/copy/copy-protocol-entry.service';
import {Router} from '@angular/router';
import {LoggingService} from '../../../services/common/logging.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {convertErrorToMessage, ErrorWithUserMessage} from '../../../shared/errors';
import {DomSanitizer} from '@angular/platform-browser';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {observableToPromise} from '../../../utils/async-utils';
import {AttachmentEntryDataService} from '../../../services/data/attachment-entry-data.service';
import {PhotoService} from '../../../services/photo/photo.service';
import {ImageOffline} from '../../../model/attachments';
import {CopyProtocolEntrySetting, ProtocolEntryCopyResult} from '../../../model/copy';
import {SelectedProtocolService} from 'src/app/services/protocol/selected-protocol.service';
import {isQuotaExceededError} from '../../../utils/attachment-utils';
import {AttachmentService} from '../../../services/attachment/attachment.service';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {ProtocolWithTypeAndLayout} from '../../../model/protocol';
import {ProtocolEntryCompanyDataService} from 'src/app/services/data/protocol-entry-company-data.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {PdfPlanMarkerProtocolEntryDataService} from 'src/app/services/data/pdf-plan-marker-protocol-entry-data.service';

const LOG_SOURCE = 'CopyProtocolEntryComponent';

@Component({
  selector: 'app-copy-protocol-entry',
  templateUrl: './copy-protocol-entry.component.html',
  styleUrls: ['./copy-protocol-entry.component.scss'],
})
export class CopyProtocolEntryComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  private currentProtocolLayout: ProtocolLayout | undefined;
  public currentProjectWithOffline: ProjectWithOffline | undefined;
  public destinationProtocols: Array<ProtocolWithTypeAndLayout> = [];
  public sourceProtocol: ProtocolWithTypeAndLayout | undefined;

  public copyMultiple: boolean;
  public protocolEntry: ProtocolEntry;
  public protocolEntries: Array<ProtocolEntry>;
  public protocols$: Observable<ProtocolWithTypeAndLayout[]>;
  public networkConnected$: Observable<boolean>;
  public subEntries: ProtocolEntry[] | undefined;
  public allAttachmentsWithOffline: Array<AttachmentProtocolEntry & ImageOffline>;
  public isConnected: boolean;
  private copyInProgress: HTMLIonAlertElement;
  public allAttachmentsOfflineAvailable: boolean | undefined;
  private projectWithOfflineSubscription: Subscription | undefined;
  private networkConnectedSubscription: Subscription | undefined;
  private abortController = new AbortController();
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  public copyEntryForm: UntypedFormGroup = new UntypedFormGroup({
    protocol: new UntypedFormControl([]),
    copyAttachments: new UntypedFormControl(true),
    copyParentEntry: new UntypedFormControl(false),
    copySubEntries: new UntypedFormControl(false),
    copyDetails: new UntypedFormControl(true),
    copyCreationDate: new UntypedFormControl(true),
    copyMarkers: new UntypedFormControl(false),
    ignoreMissingAttachments: new UntypedFormControl(false),
  });
  public copyMultipleAnalyze: {parentWithoutAnyChild: boolean; childWithoutParent: boolean} | undefined;

  constructor(
    private navParams: NavParams,
    private networkStatusService: NetworkStatusService,
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private copyProtocolEntryService: CopyProtocolEntryService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private protocolEntryDataService: ProtocolEntryDataService,
    private alertController: AlertController,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private photoService: PhotoService,
    private protocolService: ProtocolService,
    private selectedProtocolService: SelectedProtocolService,
    private attachmentService: AttachmentService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private posthogService: PosthogService,
    private selectableUtilService: SelectableUtilService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService
  ) {}

  protocolsOnChange(event: {component: IonicSelectableComponent; value: Array<ProtocolWithTypeAndLayout>}) {
    this.currentProtocolLayout = null;
    this.destinationProtocols = event.value;
  }

  async ngOnInit() {
    this.currentProtocolLayout = _.cloneDeep(this.navParams.data.currentProtocolLayout);
    if (!this.navParams.data.protocolEntry && !this.navParams.data.protocolEntries) {
      throw new Error(`Neither protocolEntry nor protocolEntries was provided in data.`);
    }
    if (this.navParams.data.protocolEntries) {
      this.copyMultiple = true;
      this.protocolEntries = _.cloneDeep(this.navParams.data.protocolEntries);
      if (!this.protocolEntries.length) {
        this.loggingService.warn(LOG_SOURCE, 'Empty protocolEntries was provided.');
        await this.modal.dismiss({success: false});
        return;
      }
      this.protocolEntry = this.protocolEntries[0];
      const protocolEntryIds = this.protocolEntries
        .map((protocolEntry) => protocolEntry.id)
        .concat(this.protocolEntries.filter((protocolEntry) => protocolEntry.parentId).map((protocolEntry) => protocolEntry.parentId));
      this.subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryIds(protocolEntryIds));
      this.copyMultipleAnalyze = await this.copyProtocolEntryService.analyzeCopyMultipleEntries(this.protocolEntries);
      this.copyEntryForm.get('copyParentEntry').setValue(false);
      this.copyEntryForm.get('copySubEntries').setValue(false);
    } else {
      this.copyMultiple = false;
      this.protocolEntry = this.navParams.data.protocolEntry;
      this.protocolEntries = [this.protocolEntry];
      if (this.protocolEntry.parentId) {
        this.copyEntryForm.get('copyParentEntry').setValue(true);
      } else {
        this.subEntries = await observableToPromise(this.protocolEntryDataService.getSubEntriesByParentEntryId(this.protocolEntry.id));
        if (this.subEntries.length > 0) {
          this.copyEntryForm.get('copySubEntries').setValue(true);
        }
      }
    }

    const currentProject = await this.projectDataService.getCurrentProject();
    this.projectWithOfflineSubscription = this.projectService.getById(currentProject.id).subscribe((project) => {
      this.currentProjectWithOffline = project;
    });
    this.protocols$ = this.protocolService.openProtocolsWithTypeAndLayout$;
    this.sourceProtocol = _.cloneDeep(await observableToPromise(this.selectedProtocolService.getCurrentProtocolWithTypeAndLayout()));
    this.allAttachmentsOfflineAvailable = await this.checkAttachmentsAvailability();

    this.networkConnected$ = this.networkStatusService.onlineOrUnknown$;
    this.networkConnectedSubscription = this.networkConnected$.subscribe(async (connected) => {
      this.isConnected = connected;
      this.updateForm();
    });
  }

  private updateForm() {
    if (this.currentProjectWithOffline.isOfflineAvailable && !this.allAttachmentsWithOffline.length) {
      this.copyEntryForm.get('copyAttachments').setValue(false);
      this.copyEntryForm.get('copyAttachments').disable();
    }
    if (this.currentProjectWithOffline.isOfflineAvailable && this.allAttachmentsWithOffline.length && !this.allAttachmentsOfflineAvailable) {
      if (this.isConnected) {
        this.copyEntryForm.get('copyAttachments').enable();
      } else {
        this.copyEntryForm.get('copyAttachments').setValue(false);
        this.copyEntryForm.get('copyAttachments').disable();
      }
    }
    if (!this.currentProjectWithOffline.isOfflineAvailable && this.allAttachmentsWithOffline.length) {
      if (this.isConnected) {
        this.copyEntryForm.get('copyAttachments').enable();
      } else {
        this.copyEntryForm.get('copyAttachments').setValue(false);
        this.copyEntryForm.get('copyAttachments').disable();
      }
    }
  }

  private async checkAttachmentsAvailability(): Promise<boolean> {
    const allProtocolEntryIds = new Array<IdType>();
    allProtocolEntryIds.push(..._.map(this.subEntries, 'id'));
    allProtocolEntryIds.push(..._.map(this.protocolEntries, 'id'));
    const allAttachments = await observableToPromise(this.attachmentEntryDataService.getByProtocolEntries(allProtocolEntryIds));
    this.allAttachmentsWithOffline = await this.photoService.toAttachmentsWithOffline(allAttachments);
    return _.some(this.allAttachmentsWithOffline, (attachment) => attachment.imageOffline);
  }

  async copyEntry() {
    this.copyInProgress = await this.alertController.create({
      message: this.translateService.instant(this.copyMultiple ? 'copyWorkflow.protocolEntries.copyInProgress' : 'copyWorkflow.protocolEntry.copyInProgress', {count: this.protocolEntries.length}),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translateService.instant('cancel'),
          role: 'cancel',
          handler: async () => {
            this.abortController.abort();
            await this.toastService.infoWithMessage('copyWorkflow.cancelled');
            await this.modal.dismiss({success: false});
          },
        },
      ],
    });
    await this.copyInProgress.present();
    const logInstance = this.systemEventService.logAction(
      LOG_SOURCE,
      () =>
        `Copy entr${this.copyMultiple ? 'ies' : 'y'} (ids=${this.protocolEntries
          .map((entry) => entry.id)
          .join(',')}, destinationProtocols=${this.destinationProtocols.map((protocol) => protocol.id).join(',')})`
    );
    try {
      const rawData = this.copyEntryForm.getRawValue();
      for (const destinationProtocol of this.destinationProtocols) {
        const copyProtocolEntrySetting = {
          protocol: rawData.protocol,
          copyAttachments: rawData.copyAttachments,
          copyParentEntry: this.isDestinationProtocolTypeShort(destinationProtocol) ? false : rawData.copyParentEntry,
          copySubEntries: this.isDestinationProtocolTypeShort(destinationProtocol) ? false : rawData.copySubEntries,
          copyDetails: rawData.copyDetails,
          copyCreationDate: rawData.copyCreationDate,
          copyContacts: true,
          copyMarkers: rawData.copyMarkers,
          ignoreMissingAttachments: rawData.ignoreMissingAttachments,
        } as CopyProtocolEntrySetting;

        const {protocol, ...settingsForLog} = copyProtocolEntrySetting;

        logInstance.logCheckpoint(() => `(settings=${JSON.stringify(settingsForLog)})`);

        let copyResult: ProtocolEntryCopyResult;
        if (this.copyMultiple) {
          copyResult = await this.copyProtocolEntryService.startCopyMultiple(
            this.protocolEntries,
            destinationProtocol,
            copyProtocolEntrySetting,
            this.abortController,
            this.copyUpdateProgressCallback
          );
        } else {
          copyResult = await this.copyProtocolEntryService.startCopy(this.protocolEntry, destinationProtocol, copyProtocolEntrySetting, this.abortController, this.copyUpdateProgressCallback);
        }
        if (!this.abortController.signal.aborted) {
          this.copyInProgress.querySelector('button').hidden = true;
          await this.protocolEntryDataService.insert(copyResult.copiedEntries, destinationProtocol.projectId);
          await this.protocolEntryCompanyDataService.insert(copyResult.copiedEntryCompanies, destinationProtocol.projectId);
          for (const protocolEntryAttachmentsCopy of copyResult.copiedAttachments) {
            if (protocolEntryAttachmentsCopy.attachments.length) {
              await this.attachmentEntryDataService.insert(protocolEntryAttachmentsCopy.attachments, destinationProtocol.projectId, {}, protocolEntryAttachmentsCopy.blobs);
            }
          }
          if (copyResult.copiedMarkers.length) {
            for (const markerCopy of copyResult.copiedMarkers) {
              await this.pdfPlanMarkerProtocolEntryDataService.insert(markerCopy, destinationProtocol.projectId);
            }
          }
          logInstance.success(() => `newIds=${copyResult.copiedEntries.map((entry) => entry.id)}`);
          await this.toastService.toastWithTranslateParamsAndButton(
            this.copyMultiple ? 'copyWorkflow.protocolEntries.success' : 'copyWorkflow.protocolEntry.success',
            {count: this.protocolEntries.length},
            ToastDurationInMs.INFO_WITH_MESSAGE,
            [
              {
                side: 'end',
                text: this.translateService.instant('modal.goToEntry'),
                handler: async () => {
                  await this.router.navigate(getProtocolEntryPagePath(destinationProtocol.id, copyResult.newParentEntry.id), {
                    state: {
                      protocolListShowActive: true,
                      newProtocolEntry: true,
                    },
                  });
                },
              },
            ]
          );
          try {
            const isNetworkConnected = (await this.networkStatusService.getNetworkStatus()).connected;
            this.posthogService.captureEvent('[Protocols][Entry] Copy', {
              type: this.router.url.includes('tasks') ? 'task' : 'entry',
              sameProtocol: this.sourceProtocol === destinationProtocol,
              copyMultiple: this.copyMultiple,
              editedOffline: !isNetworkConnected,
            });
          } catch (error) {
            this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
          }
        } else {
          logInstance.success('aborted');
        }
      }
      await this.modal.dismiss({success: true});
    } catch (error) {
      logInstance.failure(error);
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage || convertErrorToMessage(error);
      } else {
        message = convertErrorToMessage(error);
      }
      this.loggingService.error(LOG_SOURCE, `Error copying protocolEntry. "${message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - copyDismiss', message);
      if (!this.abortController.signal.aborted && message.indexOf('abort') === -1) {
        if (isQuotaExceededError(message)) {
          await this.attachmentService.showToastQuotaExceeded();
        } else {
          await this.toastService.errorWithMessageAndHeader('copyWorkflow.errorCopyingMessage', message);
        }
      }
    } finally {
      await this.copyInProgress.dismiss();
    }
  }

  copyUpdateProgressCallback = (protocolEntry: ProtocolEntry, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => {
    if (text) {
      this.copyInProgress.message = text;
      return;
    }
    let message = this.translateService.instant('copyWorkflow.progressCopyEntry', {name: protocolEntry.title});
    if (numberCopiedAttachments !== undefined) {
      message =
        message +
        this.translateService.instant('copyWorkflow.progressCopyAttachment', {
          numberCopiedAttachments,
          numberOfAttachments,
        });
    }
    this.copyInProgress.message = this.sanitizer.sanitize(SecurityContext.HTML, message);
  };

  ngOnDestroy(): void {
    this.projectWithOfflineSubscription?.unsubscribe();
    this.projectWithOfflineSubscription = undefined;
  }

  async cancel() {
    await this.modal.dismiss({success: false});
  }

  public isDestinationProtocolTypeShort(destinationProtocol: ProtocolWithTypeAndLayout): boolean {
    return destinationProtocol ? destinationProtocol.protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT : false;
  }

  public isSourceProtocolTypeShort(): boolean {
    return this.sourceProtocol ? this.sourceProtocol.protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT : false;
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }

  public destinationProtocolsHasClosed() {
    if (!this.destinationProtocols) {
      return false;
    }
    return this.destinationProtocols.some((protocol) => protocol?.closedAt);
  }

  public destinationProtocolsIncludesShort() {
    if (!this.destinationProtocols) {
      return false;
    }
    return this.destinationProtocols.some((protocol) => protocol?.protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT);
  }
}
