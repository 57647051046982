import {ColumnConfig} from '../ColumnConfig';

export const EmployeeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'status_editor', prop: 'statusEditor', def: null},
  {name: 'status_employee', prop: 'statusEmployee', def: null},
  {name: 'is_deleted', prop: 'isDeleted'},
  {name: 'personal_number', prop: 'personalNumber', def: null},
  {name: 'display_group', prop: 'displayGroup'},
  {name: 'display_employer_name', prop: 'displayEmployerName', def: null},
  {name: 'hours', prop: 'hours', def: null},
  {name: 'working_from', prop: 'workingFrom', cast: 'Time', def: null},
  {name: 'working_until', prop: 'workingUntil', cast: 'Time', def: null},
  {name: 'break_hours', prop: 'breakHours', def: null},
  {name: 'extra_pay', prop: 'extraPay', def: null},
  {name: 'employer_id', prop: 'employerId', def: null},
  {name: 'group_id', prop: 'groupId', def: null},
  {name: 'modified_by_id', prop: 'modifiedById', def: null},
  {name: 'parent_id', prop: 'parentId', def: null},
  {name: 'report_id', prop: 'reportId'},
  {name: 'additional_pay_type_id', prop: 'additionalPayTypeId', def: null},
];

EmployeeColumnSet.push({name: 'employees', fk: 'parentId', mappedBy: EmployeeColumnSet, transient: true});
