import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PluginListenerHandle} from '@capacitor/core';
import {Channel, PermissionStatus, PushNotifications, Token} from '@capacitor/push-notifications';
import {IonicSafeString, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, debounceTime, distinctUntilChanged, EMPTY, filter, fromEventPattern, map, retry, Subscription, switchMap, tap, timeout, timer} from 'rxjs';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../auth/authentication.service';
import {StorageService} from '../storage.service';
import {AlertService} from '../ui/alert.service';
import {PosthogService} from '../posthog/posthog.service';

const STORAGE_KEY = StorageKeyEnum.NOTIFICATION_ALERT;

@Injectable({
  providedIn: 'root',
})
export class FcmPushTokenManagerService {
  private subscription: Subscription | undefined;
  private permissionRequestResultSubject = new BehaviorSubject<PermissionStatus | undefined>(undefined);
  permissionRequestResult$ = this.permissionRequestResultSubject.asObservable();

  get permissionRequestResult(): PermissionStatus | undefined {
    return this.permissionRequestResultSubject.value;
  }
  set permissionRequestResult(permissionRequestResult: PermissionStatus | undefined) {
    this.permissionRequestResultSubject.next(permissionRequestResult);
  }

  private fcmTokenSubject = new BehaviorSubject<string | null>(null);

  get fcmToken(): string | null {
    return this.fcmTokenSubject.value;
  }
  set fcmToken(fcmToken: string | null) {
    this.fcmTokenSubject.next(fcmToken);
  }

  constructor(
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private translateService: TranslateService,
    private storage: StorageService,
    private posthogService: PosthogService
  ) {}

  isPushSupported() {
    return this.platform.is('capacitor');
  }

  private getToken$() {
    if (!this.isPushSupported()) {
      return EMPTY;
    }

    return fromEventPattern(
      (handler) => {
        return PushNotifications.addListener('registration', handler);
      },
      (_handler, signalPromise: Promise<PluginListenerHandle>) => {
        signalPromise.then((handle) => handle.remove());
      }
    ).pipe(
      tap((token: Token) => (this.fcmToken = token.value)),
      switchMap((token: Token) =>
        this.authenticationService.data.pipe(
          filter((data) => data && data.impersonated !== true), // Never send FCM token when impersonated
          distinctUntilChanged((a, b) => a.userId === b.userId),
          debounceTime(0),
          switchMap(() => this.httpClient.post(`${environment.serverUrl}fcmPushTokens/`, {token: token.value})),
          retry({
            delay: (_error, retryCount) => {
              return timer(2 ** Math.min(retryCount, 10) * 50 * (Math.random() + 1));
            },
            resetOnSuccess: true,
          })
        )
      )
    );
  }

  async unregisterToken(timeoutMs?: number) {
    if (!this.isPushSupported()) {
      return;
    }
    const isAuthenticated = await observableToPromise(this.authenticationService.isAuthenticated$);
    if (!isAuthenticated) {
      throw new Error('Unable to deactivateUserDevice as no user is authenticated');
    }

    const token = this.fcmToken;

    if (!token) {
      return;
    }

    await observableToPromise(this.httpClient.delete(`${environment.serverUrl}fcmPushTokens/`, {body: {token}}).pipe(timeoutMs ? timeout(timeoutMs) : map((v) => v)));
  }

  async cachedPermissionsOrRequest() {
    if (!this.isPushSupported()) {
      return;
    }
    if (!this.permissionRequestResult) {
      this.permissionRequestResult = await PushNotifications.requestPermissions();
    }

    return this.permissionRequestResult;
  }

  async startTokenManagement(comesFromOptIn = false) {
    if (!this.isPushSupported()) {
      return;
    }
    if (this.subscription) {
      return;
    }
    let permResult = await PushNotifications.checkPermissions();
    if (permResult.receive === 'denied') {
      return;
    }
    if (permResult.receive !== 'granted' && comesFromOptIn) {
      permResult = await this.cachedPermissionsOrRequest();
    }
    if (permResult.receive === 'granted') {
      if (this.platform.is('android')) {
        const channel: Channel = {
          id: 'android_channel',
          name: 'BauMaster',
          importance: 5,
        };
        PushNotifications.createChannel(channel);
      }
      PushNotifications.register();
      this.subscription = this.getToken$().subscribe();
    }
  }

  stopTokenManagement() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  async openPushNotificationsOptIn(force = false) {
    if (!this.isPushSupported()) {
      return;
    }
    const permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'granted' || permStatus.receive === 'denied') {
      return;
    }
    const lastShown = await this.storage.get(STORAGE_KEY);

    if (!lastShown || force) {
      const image_src = '/assets/images/brian-notifications.png';
      const alert = await this.alertService.createAlert({
        header: this.translateService.instant('pushNotificationsOptIn.title'),
        message: new IonicSafeString(`${this.translateService.instant('pushNotificationsOptIn.text')}\n<img src="${image_src}" alt="photo" />`),
        buttons: [
          {
            text: this.translateService.instant('cancel'),
            role: 'cancel',
            cssClass: this.alertService.getButtonCssClass({color: 'text-primary', fill: 'clear'}),
          },
          {
            text: this.translateService.instant('pushNotificationsOptIn.button'),
            role: 'confirm',
            cssClass: this.alertService.getButtonCssClass({color: 'text-primary', fill: 'solid'}),
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();

      if ((await alert.onWillDismiss()).role === 'confirm') {
        this.posthogService.captureEvent('[Notification] Opt in shown', {confirmed: true});
        this.stopTokenManagement();
        this.startTokenManagement(true);
      } else {
        this.posthogService.captureEvent('[Notification] Opt in shown', {confirmed: false});
        await this.storage.set(STORAGE_KEY, new Date().toISOString());
      }
    }
  }
}
