import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationsCenterProjectMode} from 'src/app/services/notifications/notifications-center.service';
import {TooltipModule} from '../../../shared/module/tooltip/tooltip.module';

@Component({
  selector: 'app-notifications-toolbar',
  templateUrl: './notifications-toolbar.component.html',
  styleUrls: ['./notifications-toolbar.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, FontAwesomeModule, FormsModule, NgIf, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsToolbarComponent {
  @Input()
  projectMode: NotificationsCenterProjectMode;

  @Input()
  unseenCountByMode: Record<NotificationsCenterProjectMode, number>;

  @Input()
  showSeen: boolean;

  @Output()
  projectModeChange = new EventEmitter<NotificationsCenterProjectMode>();

  @Output()
  markAllAsSeenClick = new EventEmitter<MouseEvent>();

  @Output()
  settingsClick = new EventEmitter<MouseEvent>();

  @Output()
  showSeenClick = new EventEmitter<MouseEvent>();

  constructor() {}
}
