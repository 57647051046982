import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {observableToPromise} from '../../utils/async-utils';
import {StatusReportLocalChangeItems, StatusReportLocalChanges, StatusReportReq, StatusReportRes, StatusReportSystemInfo, SystemEvent, SystemEventCategoryEnum} from 'submodules/baumaster-v2-common';
import {environment} from '../../../environments/environment';
import {Platform} from '@ionic/angular';
import {StorageQuota, SystemInfoService} from '../common/system-info.service';
import {Device} from '@capacitor/device';
import {App} from '@capacitor/app';
import {SyncStatusService} from '../sync/sync-status.service';
import {DataServiceFactoryService} from '../data/data-service-factory.service';
import {AbstractClientAwareDataService} from '../data/abstract-client-aware-data.service';
import {AbstractProjectAwareDataService} from '../data/abstract-project-aware-data.service';
import {SystemEventService} from '../event/system-event.service';
import {AbstractNonClientAwareDataService} from '../data/abstract-non-client-aware-data.service';
import {StorageService} from '../storage.service';
import {LoggingService} from '../common/logging.service';
import {UserService} from '../user/user.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {convertErrorToMessage} from 'src/app/shared/errors';

const LOG_SOURCE = 'StatusReportService';

@Injectable({
  providedIn: 'root',
})
export class StatusReportService {
  private readonly url = environment.serverUrl + `api/advanced/statusReport`;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    public platform: Platform,
    private systemInfoService: SystemInfoService,
    private loggingService: LoggingService,
    private syncStatusService: SyncStatusService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private systemEventService: SystemEventService,
    private userService: UserService,
    private attachmentSettingService: AttachmentSettingService
  ) {}

  private async collectSystemInfo(): Promise<StatusReportSystemInfo> {
    const deviceInfo = await Device.getInfo();
    const deviceId = await Device.getId();
    const appInfo = deviceInfo.platform !== 'web' ? await App.getInfo() : undefined;
    let storageQuota: StorageQuota | undefined;
    try {
      storageQuota = await observableToPromise(this.systemInfoService.storageQuota$);
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Unable to getStorageQuota. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to getStorageQuota. ${error?.message}`);
    }
    const webWorkerSupported = typeof Worker !== 'undefined';
    const cacheStorageSupported = typeof caches !== 'undefined';
    const currentUser = await observableToPromise(this.userService.currentUser$);
    let userAgent: string | undefined;
    try {
      userAgent = navigator.userAgent;
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Unable to get userAgent from navigator. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to get userAgent from navigator. ${error?.message}`);
    }
    const fileAccessUtilWithDefault = await this.attachmentSettingService.getFileAccessUtilWithDefault();
    const fileAccessUtilClassName = fileAccessUtilWithDefault.fileAccessUtil.className + (fileAccessUtilWithDefault.usedDefault ? ' (Default)' : '');

    const ret: StatusReportSystemInfo = {
      environment: {
        version: environment.version,
        production: environment.production,
        serverUrl: environment.serverUrl,
        logLevel: environment.logLevel,
      },
      storageDriver: this.storage.driver,
      webWorkerSupported,
      cacheStorageSupported,
      fileAccessUtilClassName,
      device: {
        model: deviceInfo.model,
        platform: deviceInfo.platform,
        operatingSystem: deviceInfo.operatingSystem,
        osVersion: deviceInfo.osVersion,
        appVersion: appInfo?.version,
        appBuild: appInfo?.build,
        webViewVersion: deviceInfo.webViewVersion,
        manufacturer: deviceInfo.manufacturer,
        memUsed: deviceInfo.memUsed,
        userAgent,
        diskTotal: deviceInfo.realDiskTotal,
        diskFree: deviceInfo.realDiskFree,
        deviceId: deviceId.identifier,
      },
      platform: {
        width: this.platform.width(),
        height: this.platform.height(),
        orientation: this.platform.isLandscape() ? 'landscape' : 'portrait',
        platforms: this.platform.platforms(),
      },
      storageQuota: {
        quota: storageQuota?.quota,
        usage: storageQuota?.usage,
        fallbackImplementation: storageQuota?.fallbackImplementation,
        usageDetails: {
          caches: storageQuota?.usageDetails?.caches,
          indexedDB: storageQuota?.usageDetails?.indexedDB,
          serviceWorkerRegistrations: storageQuota?.usageDetails?.serviceWorkerRegistrations,
        },
        persistent: navigator?.storage?.persisted ? await navigator?.storage?.persisted() : undefined,
      },
    };
    if (currentUser) {
      ret.user = {
        id: currentUser.id,
        license: currentUser.role,
        reportPlugin: currentUser.assignedReportRights,
      };
    }
    return ret;
  }

  private async collectNonClientAwareLocalChanges(): Promise<{[key: string]: StatusReportLocalChangeItems<unknown>}> {
    const ret: {[key: string]: StatusReportLocalChangeItems<unknown>} = {};
    try {
      for (const key of Object.keys(this.dataServiceFactoryService.nonClientAwareServices)) {
        const service: AbstractNonClientAwareDataService<any> = this.dataServiceFactoryService.nonClientAwareServices[key];
        const localChanges = await observableToPromise(service.localChanges);
        const changes = localChanges?.inserted?.length || localChanges?.updated?.length || localChanges?.deleted?.length;
        if (!changes) {
          continue;
        }
        ret[key] = localChanges;
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Unable to collectNonClientAwareLocalChanges. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to collectNonClientAwareLocalChanges. ${error?.message}`);
    }
    return ret;
  }

  private async collectClientAwareLocalChanges(): Promise<{[key: string]: StatusReportLocalChangeItems<unknown>}> {
    const ret: {[key: string]: StatusReportLocalChangeItems<unknown>} = {};
    try {
      for (const key of Object.keys(this.dataServiceFactoryService.clientAwareServices)) {
        const service: AbstractClientAwareDataService<any> = this.dataServiceFactoryService.clientAwareServices[key];
        const localChanges = await observableToPromise(service.localChanges);
        const changes = localChanges?.inserted?.length || localChanges?.updated?.length || localChanges?.deleted?.length;
        if (!changes) {
          continue;
        }
        ret[key] = localChanges;
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Unable to collectClientAwareLocalChanges. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to collectClientAwareLocalChanges. ${error?.message}`);
    }
    return ret;
  }

  private async collectProjectAwareLocalChanges(): Promise<{[key: string]: {[key: string]: StatusReportLocalChangeItems<unknown>}}> {
    const ret: {[key: string]: {[key: string]: StatusReportLocalChangeItems<unknown>}} = {};

    try {
      for (const key of Object.keys(this.dataServiceFactoryService.projectAwareServices)) {
        const service: AbstractProjectAwareDataService<any> = this.dataServiceFactoryService.projectAwareServices[key];
        const localChangesByProject = await observableToPromise(service.localChangesByClientOrProject);
        for (const projectId of localChangesByProject.keys()) {
          const localChanges = localChangesByProject.get(projectId);
          const changes = localChanges?.inserted?.length || localChanges?.updated?.length || localChanges?.deleted?.length;
          if (!changes) {
            continue;
          }
          if (!ret[projectId]) {
            ret[projectId] = {};
          }
          ret[projectId][key] = localChangesByProject.get(projectId);
        }
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Unable to collectProjectAwareLocalChanges. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to collectProjectAwareLocalChanges. ${error?.message}`);
    }
    return ret;
  }

  private async collectLocalChanges(): Promise<StatusReportLocalChanges> {
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    let numberAttachmentsInCacheCache: number | undefined;
    let numberAttachmentsInUploadQueue: number | undefined;
    let numberAttachmentsInErrorQueue: number | undefined;
    try {
      numberAttachmentsInCacheCache = await fileAccessUtil.numberOfFiles('media');
      numberAttachmentsInUploadQueue = await fileAccessUtil.numberOfFiles('upload');
      numberAttachmentsInErrorQueue = await fileAccessUtil.numberOfFiles('uploadError');
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Unable to determine numberAttachmentsInCache. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to determine numberAttachmentsInCache. ${error?.message}`);
    }

    const ret: StatusReportLocalChanges = {
      nonClientAware: await this.collectNonClientAwareLocalChanges(),
      clientAware: await this.collectClientAwareLocalChanges(),
      projectAware: await this.collectProjectAwareLocalChanges(),
      numberAttachmentsInCacheCache,
      numberAttachmentsInUploadQueue,
      numberAttachmentsInErrorQueue,
    };

    if (ret.numberAttachmentsInUploadQueue) {
      try {
        ret.attachmentsInUploadQueue = await fileAccessUtil.files('upload');
      } catch (error) {
        this.loggingService.warn(LOG_SOURCE, `Unable to determine getUrlsOfMediaFilesScheduledForUpload. ${error?.message}`);
        await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to determine getUrlsOfMediaFilesScheduledForUpload. ${error?.message}`);
      }
    }
    if (ret.numberAttachmentsInErrorQueue) {
      try {
        ret.attachmentsInErrorQueue = await fileAccessUtil.files('uploadError');
      } catch (error) {
        this.loggingService.warn(LOG_SOURCE, `Unable to determine getUrlsOfMediaFilesInUploadErrorQueue. ${error?.message}`);
        await this.systemEventService.logErrorEvent(LOG_SOURCE, `Unable to determine getUrlsOfMediaFilesInUploadErrorQueue. ${error?.message}`);
      }
    }

    return ret;
  }

  private async collectEvents(): Promise<Array<SystemEvent>> {
    try {
      return await this.systemEventService.getClonedSystemEventsWithIsoDate();
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Error collecting systemEvents`);
      return [
        {
          category: SystemEventCategoryEnum.ERROR,
          source: LOG_SOURCE,
          timestamp: Date.now(),
          message: `Failed to collect systemEvents: ${convertErrorToMessage(error)}`,
          timestampIsoDate: new Date().toISOString(),
        },
      ];
    }
  }

  public async collectStatusReportData(): Promise<StatusReportReq> {
    return {
      localChanges: await this.collectLocalChanges(),
      systemInfo: await this.collectSystemInfo(),
      events: await this.collectEvents(),
    };
  }

  public async sendStatusReport(): Promise<number> {
    try {
      this.systemEventService.logEvent('StatusReportService', 'sendStatusReport started');
      const statusReportReq = await this.collectStatusReportData();
      const statusReportRes = await observableToPromise(this.http.post<StatusReportRes>(this.url, statusReportReq));
      const statusReportId = statusReportRes.statusReportId;
      this.systemEventService.logEvent('StatusReportService', `sendStatusReport successfully sent (statusReportId=${statusReportId})`);
      return statusReportRes.statusReportId;
    } catch (error) {
      this.systemEventService.logErrorEvent('StatusReportService', `Failed sending status report. ${error.message}`);
      throw error;
    }
  }
}
