import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, race, timer} from 'rxjs';
import {mapTo, tap} from 'rxjs/operators';
import {AppDataPageService} from 'src/app/services/data/app-data-page.service';
import {ALLOW_INTERACTION_DEADLINE_IN_MS} from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AllowInteractionDeadlineGuard implements CanActivate {
  constructor(
    private appDataPageService: AppDataPageService,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const stateUrl = state.url;
    return race([
      this.appDataPageService.allowInteraction$,
      timer(ALLOW_INTERACTION_DEADLINE_IN_MS).pipe(
        mapTo(false),
        tap(() => {
          this.router.navigate(['/loading'], {
            queryParams: {
              redirectTo: stateUrl,
            },
          });
        })
      ),
    ]);
  }
}
