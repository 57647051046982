import {ColumnConfig} from '../ColumnConfig';
import {ConstructionScheduleLinkColumnSet} from './ConstructionScheduleLinkColumnSet';

export const ConstructionScheduleTaskColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'project_id', prop: 'projectId', cnd: true, def: null},
  {name: 'parent_id', prop: 'parentId', def: null},
  {name: 'text', def: null},
  {name: 'custom_type', prop: 'customType', def: null},
  {name: 'sort_order', prop: 'sortOrder', def: null},
  {name: 'status', prop: 'status'},
  {name: 'start_date', prop: 'startDate', cast: 'Date'},
  {name: 'end_date', prop: 'endDate', cast: 'Date', def: null},
  {name: 'duration', def: null},
  {name: 'craft_id', prop: 'craftId', def: null},
  {name: 'constraint_type', prop: 'constraintType', def: null},
  {name: 'constraint_date', prop: 'constraintDate', cast: 'Timestamp', def: null},
  {name: 'group_inline', prop: 'groupInline', def: null},
  {name: 'created_at', prop: 'createdAt', cnd: true, cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'constructionScheduleLinks', fk: 'source', mappedBy: ConstructionScheduleLinkColumnSet, transient: true},
  {name: 'constructionScheduleLinks', fk: 'target', mappedBy: ConstructionScheduleLinkColumnSet, transient: true},
];

ConstructionScheduleTaskColumnSet.push({name: 'constructionScheduleTasks', fk: 'parentId', mappedBy: ConstructionScheduleTaskColumnSet, transient: true});
