import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ViewDidEnter} from '@ionic/angular';
import {Nullish} from 'src/app/model/nullish';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';

@Component({
  selector: 'app-pdf-plan-comment-modal',
  templateUrl: './pdf-plan-comment-modal.component.html',
  styleUrls: ['./pdf-plan-comment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanCommentModalComponent implements OnInit, ViewDidEnter {
  modal: HTMLIonModalElement;
  @Input()
  pdfPlan: PdfPlanWithDeletable;

  @Input()
  form: UntypedFormGroup | undefined;

  private previousValue: Nullish<string>;

  showContent = false;

  @ViewChild('textarea', {
    read: ElementRef,
    static: false,
  })
  textarea: ElementRef<HTMLIonTextareaElement>;

  constructor(
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    if (!this.form) {
      this.form = this.fb.group({
        description: [''],
      });
      this.form.reset({
        description: this.pdfPlan.latestPdfPlanVersion.description ?? '',
      });
      this.previousValue = this.pdfPlan.latestPdfPlanVersion.description;
    } else {
      this.previousValue = this.form.get('description').value;
    }

    const modal = this.modal;
    modal.onWillDismiss().then(({role}) => {
      if (role !== 'save') {
        this.form.get('description').setValue(this.previousValue);
      }
    });
  }

  ionViewDidEnter() {
    this.showContent = true;
    this.cdRef.markForCheck();
    setTimeout(() => {
      this.textarea.nativeElement.setFocus();
    }, 50);
  }

  save = () => {
    const updatedPdfPlan: PdfPlanWithDeletable = {
      ...this.pdfPlan,
      latestPdfPlanVersion: {
        ...this.pdfPlan.latestPdfPlanVersion,
        description: this.form.value.description,
      },
    };
    this.modal.dismiss(updatedPdfPlan, 'save');
  };

  cancel() {
    this.modal.dismiss('cancel');
  }
}
