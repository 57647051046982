import {animate, style, transition, trigger} from '@angular/animations';
import {NgForOf, NgIf} from '@angular/common';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IonicModule, ItemReorderCustomEvent} from '@ionic/angular';
import {Company, ProjectCompany} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {sortByCompanyOrder} from 'src/app/utils/company-utils';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-company-order',
  templateUrl: './company-order.component.html',
  styleUrls: ['./company-order.component.scss'],
  standalone: true,
  imports: [IonicModule, NgForOf, PipesModule, FontAwesomeModule, TranslateModule, NgIf],
  animations: [
    trigger('pinned', [
      transition(':enter', [
        style({opacity: 0, transform: 'translate3d(0,-200%,0)', zIndex: 1, height: 0}),
        animate('160ms ease', style({opacity: 0, transform: 'translate3d(0,-200%,0)'})),
        animate('160ms ease', style({opacity: 1, transform: 'translate3d(0,0,0)', height: '50px', zIndex: 1})),
      ]),
      transition(':leave', [style({opacity: 1, transform: 'translate3d(0,0,0)', zIndex: 1}), animate('160ms ease', style({opacity: 0, transform: 'translate3d(0,-200%,0)', height: 0}))]),
    ]),
    trigger('emptyTop', [
      transition(':enter', [style({opacity: 0, zIndex: 1, height: 0}), animate('160ms ease', style({opacity: 1, height: '19px', zIndex: 1}))]),
      transition(':leave', [style({opacity: 1, zIndex: 1}), animate('160ms ease', style({opacity: 1, zIndex: 1})), animate('160ms ease', style({opacity: 0, height: 0}))]),
    ]),
    trigger('pinnedTop', [
      transition(':enter', [
        style({opacity: 0, transform: 'translate3d(0,200%,0)', zIndex: 1, height: 0}),
        animate('160ms ease', style({opacity: 0, transform: 'translate3d(0,200%,0)'})),
        animate('160ms ease', style({opacity: 1, transform: 'translate3d(0,0,0)', height: '48px', zIndex: 1})),
      ]),
      transition(':leave', [style({opacity: 1, transform: 'translate3d(0,0,0)', zIndex: 1}), animate('160ms ease', style({opacity: 0, transform: 'translate3d(0,200%,0)', height: 0}))]),
    ]),
  ],
})
export class CompanyOrderComponent implements OnChanges {
  @Input()
  companies: Company[] | undefined;

  @Input()
  projectCompanyByCompanyId: Record<string, ProjectCompany> | undefined;

  @Output()
  projectCompaniesChange = new EventEmitter<ProjectCompany[]>();

  protected pinnedCompanies: Company[];
  protected unpinnedCompanies: Company[];

  constructor() {}

  private updateCompaniesOrder() {
    if (!this.companies || !this.projectCompanyByCompanyId) {
      return;
    }

    this.pinnedCompanies = sortByCompanyOrder(
      this.companies.filter((company) => typeof this.projectCompanyByCompanyId[company.id]?.sortOrder === 'number'),
      this.projectCompanyByCompanyId
    );
    this.unpinnedCompanies = this.companies.filter((company) => typeof this.projectCompanyByCompanyId[company.id]?.sortOrder !== 'number');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companies || changes.projectCompanies) {
      this.updateCompaniesOrder();
    }
  }

  doReorder(event: ItemReorderCustomEvent) {
    this.pinnedCompanies = event.detail.complete(this.pinnedCompanies);
    const projectCompanyById = _.mapValues(this.projectCompanyByCompanyId, (v: ProjectCompany): ProjectCompany => ({...v}));
    this.pinnedCompanies.forEach((company, index) => {
      const projectCompany = projectCompanyById[company.id];
      if (projectCompany) {
        projectCompany.sortOrder = index;
      }
    });
    this.projectCompaniesChange.emit(Object.values(projectCompanyById));
  }

  pinCompany(company: Company) {
    const projectCompanyById = _.mapValues(this.projectCompanyByCompanyId, (v: ProjectCompany): ProjectCompany => ({...v}));
    const projectCompany = projectCompanyById[company.id];
    if (projectCompany) {
      projectCompany.sortOrder = this.pinnedCompanies.length;
      this.projectCompaniesChange.emit(Object.values(projectCompanyById));
    }
  }

  unpinCompany(company: Company) {
    const projectCompanyById = _.mapValues(this.projectCompanyByCompanyId, (v: ProjectCompany): ProjectCompany => ({...v}));
    const projectCompany = projectCompanyById[company.id];
    if (projectCompany) {
      projectCompany.sortOrder = null;
      this.projectCompaniesChange.emit(Object.values(projectCompanyById));
    }
  }

  unpinAll() {
    this.projectCompaniesChange.emit(Object.values(_.mapValues(this.projectCompanyByCompanyId, (v: ProjectCompany): ProjectCompany => ({...v, sortOrder: null}))));
  }
}
