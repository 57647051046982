import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import _ from 'lodash';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {AdditionalPayType, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/additionalPayTypes';

@Injectable({
  providedIn: 'root',
})
export class AdditionalPayTypeDataService extends AbstractClientAwareDataService<AdditionalPayType> {
  public readonly dataActive$ = this.data.pipe(map((additionalPayTypes) => additionalPayTypes.filter((additionalPayType) => additionalPayType.isActive === undefined || additionalPayType.isActive)));
  public readonly dataActiveGroupedById: Observable<Record<IdType, AdditionalPayType>> = this.dataActive$.pipe(map((entities) => (entities === null ? {} : _.keyBy(entities, 'id'))));

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.ADDITIONAL_PAY_TYPE,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      clientService,
      loggingService,
      integrityResolverService,
      VERSION_INTRODUCED_DEFAULT,
      [(dataItem: AdditionalPayType) => dataItem.name?.toLowerCase()]
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
