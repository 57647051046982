import {Component, ContentChild, Directive, Input, OnDestroy, TemplateRef} from '@angular/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {Subscription} from 'rxjs';
import {LoggingService} from '../../../../services/common/logging.service';
import {convertErrorToMessage} from '../../../../shared/errors';
import {IonButton} from '@ionic/angular';

const LOG_SOURCE = 'SelectableFooterComponent';

@Directive({selector: '[appProjectSelectorFooterStartTemplate]'})
export class ProjectSelectorFooterStartTemplateDirective {}

@Directive({selector: '[appProjectSelectorFooterEndTemplate]'})
export class ProjectSelectorFooterEndTemplateDirective {}

@Component({
  selector: 'app-selectable-footer',
  templateUrl: './selectable-footer.component.html',
  styleUrls: ['./selectable-footer.component.scss'],
})
export class SelectableFooterComponent implements OnDestroy {
  private sub: Subscription;

  @ContentChild(ProjectSelectorFooterStartTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  protected startTemplate?: TemplateRef<IonButton>;

  @ContentChild(ProjectSelectorFooterEndTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  protected endTemplate?: TemplateRef<IonButton>;

  @Input()
  canClear = true;

  @Input()
  autoCloseOnSelect = true;

  constructor(
    public selectable: IonicSelectableComponent,
    private loggingService: LoggingService
  ) {
    this.sub = this.selectable.onSelect.subscribe(({isSelected}) => {
      if (!this.selectable.isMultiple && (this.canClear || isSelected)) {
        // When footer template is defined, IonicSelectable does not automatically confirm the selection
        try {
          if (this.autoCloseOnSelect) {
            this.selectable._confirm();
          }
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `selectable.onSelect. ` + convertErrorToMessage(error));
        }
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
