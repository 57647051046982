import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BimMarker, IdType, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/pdfPLanMarkerProtocolEntries';

@Injectable({
  providedIn: 'root',
})
export class BimMarkerDataService extends AbstractProjectAwareDataService<BimMarker> {
  readonly dataByProtocolEntryId$: Observable<Record<IdType, BimMarker[]>> = this.data.pipe(map((markers) => _.groupBy(markers ?? [], 'protocolEntryId')));

  readonly dataByProtocolEntryIdAcrossProjects$: Observable<Record<IdType, BimMarker[]>> = this.dataAcrossProjects$.pipe(map((markers) => _.groupBy(markers ?? [], 'protocolEntryId')));

  readonly dataByBimVersionId$: Observable<Record<IdType, BimMarker[]>> = this.data.pipe(map((markers) => _.groupBy(markers ?? [], 'bimVersionId')));

  readonly dataByBimVersionIdAcrossProjects$: Observable<Record<IdType, BimMarker[]>> = this.dataAcrossProjects$.pipe(map((markers) => _.groupBy(markers ?? [], 'bimVersionId')));

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    loggingService: LoggingService,
    projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.BIM_MARKER,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  getByEntryIds$(entryIds: IdType[], accrossProjects = false): Observable<BimMarker[]> {
    return (accrossProjects ? this.dataByProtocolEntryIdAcrossProjects$ : this.dataByProtocolEntryId$).pipe(map((byEntryId) => _.flatten(entryIds.map((entryId) => byEntryId[entryId] ?? []))));
  }

  getByEntryId$(entryId: IdType, acrossProjects = false): Observable<BimMarker[]> {
    return (acrossProjects ? this.dataByProtocolEntryIdAcrossProjects$ : this.dataByProtocolEntryId$).pipe(map((byEntryId) => byEntryId[entryId] ?? []));
  }

  getByBimId$(bimId: IdType, acrossProjects = false): Observable<BimMarker[]> {
    return (acrossProjects ? this.dataByBimVersionId$ : this.dataByBimVersionIdAcrossProjects$).pipe(map((byBimId) => byBimId[bimId] ?? []));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
