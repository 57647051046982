import {Injectable} from '@angular/core';
import {StorageKeyEnum} from '../../shared/constants';
import {ReplaySubject} from 'rxjs';
import {StorageService} from '../storage.service';

const STORAGE_KEY = StorageKeyEnum.PROTOCOL_SORT_ORDER;
const SORT_ASC = 'asc';
const SORT_DESC = 'desc';
type SortOrder = 'asc' | 'desc';

@Injectable({
  providedIn: 'root',
})
export class ProtocolSortingService {
  protected sortingSubject = new ReplaySubject<SortOrder>(1);
  public readonly selectedSorting$ = this.sortingSubject.asObservable();

  constructor(private storage: StorageService) {
    this.initializeSortingSubject();
  }

  public async initializeSortingSubject() {
    const sortOrder = await this.getSortOrder();
    if (sortOrder === null) {
      await this.setSortOrder(SORT_ASC);
    } else {
      this.sortingSubject.next(sortOrder);
    }
  }

  async getSortOrder(): Promise<SortOrder | null> {
    return await this.storage.get(STORAGE_KEY);
  }

  async setSortOrder(sortOrder: SortOrder): Promise<void> {
    this.sortingSubject.next(sortOrder);
    await this.storage.set(STORAGE_KEY, sortOrder, {
      ensureStored: false,
      immediate: false,
    });
  }

  async toggleSortOrder(): Promise<void> {
    const sortOrder = await this.getSortOrder();
    await this.setSortOrder(sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC);
  }
}
