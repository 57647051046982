import {ColumnConfig} from '../ColumnConfig';

export const IndividualNextMeetingColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'time_start', prop: 'timeStart', cast: 'Time'},
  {name: 'time_end', prop: 'timeEnd', cast: 'Time'},
  {name: 'profile_id', prop: 'profileId'},
  {name: 'next_meeting_id', prop: 'nextMeetingId'},
  {name: 'created_by_id', prop: 'createdById'},
];
