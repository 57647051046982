import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Injectable()
export class SplitPanePageService {
  private isVisibleSubject = new ReplaySubject<boolean>(1);
  isVisible$ = this.isVisibleSubject.asObservable().pipe(debounceTime(0));

  constructor() {}

  handleSplitVisible(event: CustomEvent<{visible: boolean}>) {
    this.isVisibleSubject.next(event.detail.visible);
  }
}
