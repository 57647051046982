import {ConvertHtmlToPdfmakeOptions, fixUnderLineColorIssue, fixStrikeThroughColorIssue, PdfHelperFunctions} from 'submodules/baumaster-v2-common';
import htmlToPdfmake from 'html-to-pdfmake';
import {Content, StyleDictionary} from 'pdfmake/interfaces';

const DEFAULT_STYLES: StyleDictionary = {
  b: {bold: true},
  strong: {bold: true},
  u: {decoration: 'underline'},
  s: {decoration: 'lineThrough'},
  em: {italics: true},
  i: {italics: true},
  h1: {fontSize: 20, bold: false, margin: [0, 6, 0, 6]},
  h2: {fontSize: 18, bold: false, margin: [0, 4, 0, 4]},
  h3: {fontSize: 16, bold: false, margin: [0, 4, 0, 4]},
  h4: {fontSize: 14, bold: false, margin: [0, 4, 0, 4]},
  h5: {fontSize: 12, bold: false, margin: [0, 4, 0, 4]},
  h6: {fontSize: 10, bold: false, margin: [0, 4, 0, 4]},
  a: {color: 'blue', decoration: 'underline'},
  strike: {decoration: 'lineThrough'},
  p: {margin: [0, 1, 0, 1]},
  ol: {margin: [0, 0, 0, 1]},
  ul: {margin: [0, 0, 0, 1]},
  li: {margin: [0, 0, 0, 1]},
  table: {margin: [0, 0, 0, 1]},
  th: {bold: true, fillColor: '#EEEEEE'},
};

export const PdfHelperFunctionsImpl: PdfHelperFunctions = {
  convertHtmlToPdfmake(text: string | undefined | null, options?: ConvertHtmlToPdfmakeOptions): Content {
    let defaultStyles: StyleDictionary;
    if (options?.fontSize) {
      const fontSize = options.fontSize;
      defaultStyles = {...DEFAULT_STYLES, ...{p: {...DEFAULT_STYLES.p, fontSize}, ol: {...DEFAULT_STYLES.ol, fontSize}, ul: {...DEFAULT_STYLES.ul, fontSize}, li: {...DEFAULT_STYLES.li, fontSize}}};
    } else {
      defaultStyles = DEFAULT_STYLES;
    }
    return htmlToPdfmake(!text ? '' : fixStrikeThroughColorIssue(fixUnderLineColorIssue(text)), {defaultStyles});
  },
};

export async function getImageDimension(imgSrc: string): Promise<{width: number; height: number} | undefined> {
  const img = new Image();
  img.src = imgSrc;
  await img.decode();
  if (img.width && img.height) {
    return {width: img.width, height: img.height};
  } else {
    return {width: 1, height: 1};
  }
}

export const tinymceContentStyle = 'body { font-family:Inter,Muli,sans-serif; font-size:16px; } p { margin-block-start: 0.1em; margin-block-end: 0.1em; } h3 { margin-block-end: 0.5em; }';
