import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {PdfPlanFilterModalComponent} from 'src/app/components/project-room/pdf-plan-filters/pdf-plan-filter-modal/pdf-plan-filter-modal.component';
import {PdfPlansFilterService} from './pdf-plans-filter.service';

@Injectable({
  providedIn: 'root',
})
export class PdfPlansFilterModalService {
  constructor(
    private pdfPlansFilterService: PdfPlansFilterService,
    private modalController: ModalController
  ) {}

  async openFilters() {
    const modal = await this.modalController.create({
      component: PdfPlanFilterModalComponent,
      cssClass: 'omg-modal',
    });

    await modal.present();

    const {data} = await modal.onWillDismiss();
    if (data) {
      this.pdfPlansFilterService.filter = data;
    }
  }
}
