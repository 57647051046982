import {ColumnConfig} from '../ColumnConfig';
import {NotificationEventColumnSet} from './NotificationEventColumnSet';

const UniqueConfigInProject = ['project_id'];

export const NotificationConfigColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'project_id', prop: 'projectId', uniqueWith: [UniqueConfigInProject]},
  {name: 'push_notification', prop: 'pushNotification'},
  {name: 'protocol_entry_new', prop: 'protocolEntryNew'},
  {name: 'protocol_entry_new_sub', prop: 'protocolEntryNewSub'},
  {name: 'protocol_entry_chat_new', prop: 'protocolEntryChatNew'},
  {name: 'protocol_entry_update_status', prop: 'protocolEntryUpdateStatus'},
  {name: 'protocol_entry_update_todo_until', prop: 'protocolEntryUpdateTodoUntil'},
  {name: 'protocol_entry_update_assignee', prop: 'protocolEntryUpdateAssignee'},
  {name: 'protocol_entry_to_responsible', prop: 'protocolEntryToResponsible'},
  {name: 'protocol_entry_to_creator', prop: 'protocolEntryToCreator'},
  {name: 'document_pdf_plan_new', prop: 'documentPdfPlanNew'},
  {name: 'document_bim_plan_new', prop: 'documentBimPlanNew'},
  {name: 'document_to_project_team', prop: 'documentToProjectTeam'},
  {name: 'document_to_own_company_project_team', prop: 'documentToOwnCompanyProjectTeam'},
  {name: 'project_user_added', prop: 'projectUserAdded'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'notificationEvents', fk: 'notificationConfigId', mappedBy: NotificationEventColumnSet, transient: true},
];
