import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {Platform} from '@ionic/angular';

@Directive({
  selector: '[appDragDropFileUpload]',
})
export class DragDropFileUploadDirective {
  @Input() appDragDropFileUpload: boolean | '' = '';
  @Output() fileDropped = new EventEmitter<FileList>();
  @HostBinding('class') private class = '';
  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event: DragEvent) {
    if (!this.isDragDropSupported()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.class = 'dragover';
  }
  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: DragEvent) {
    if (!this.isDragDropSupported()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.class = '';
  }
  // Drop Event
  @HostListener('drop', ['$event']) public drop(event: DragEvent) {
    if (!this.isDragDropSupported()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.class = '';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  private isDragDropSupported(): boolean {
    return this.platform.is('desktop') || (this.platform.is('mobileweb') && !this.platform.is('android') && !this.platform.is('ios'));
  }

  constructor(private platform: Platform) {}
}
