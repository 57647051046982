import {Injectable} from '@angular/core';
import _ from 'lodash';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {IdAware, Protocol} from 'submodules/baumaster-v2-common';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {ProtocolFilterService} from './protocol-filter.service';
import {ProtocolSortingService} from './protocol-sorting.service';

const protocolTypeComparer = <T extends IdAware>(lookupData: Array<T>, sortField: keyof T, protocolField: 'typeId'): ((value: any) => any) => {
  const lookupDataById = _.groupBy(lookupData, 'id');
  return (protocol: Protocol) => {
    if (lookupDataById[protocol[protocolField]]) {
      const value = lookupDataById[protocol[protocolField]][0][sortField];
      if (typeof value === 'string') {
        return (value as string).toLowerCase();
      }
      return value;
    }
    return undefined;
  };
};

@Injectable({
  providedIn: 'root',
})
export class ProtocolListService {
  protocols$: Observable<Protocol[]> = combineLatestAsync([
    this.protocolDataService.dataWithoutHidden$,
    this.protocolTypeDataService.dataWithoutHidden$,
    this.protocolFilterService.selectedProtocolType$,
    this.protocolSortingService.selectedSorting$,
  ]).pipe(
    map(([protocolList, protocolTypeList, protocolType, sortOrder]) => {
      const protocols = protocolType ? protocolList.filter((protocol: Protocol) => protocol.typeId === protocolType.id) : protocolList;
      return _.orderBy(protocols, [protocolTypeComparer(protocolTypeList, 'code', 'typeId'), 'number'], [sortOrder, sortOrder]);
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  protocolsWithClosedCount$: Observable<{
    protocols: Protocol[];
    closedCount: Nullish<number>;
  }> = combineLatestAsync([this.protocols$, this.protocolFilterService.showClosedProtocols$]).pipe(
    map(([allProtocols, showClosedProtocols]) => {
      if (showClosedProtocols) {
        return {
          protocols: allProtocols,
          closedCount: undefined,
        };
      }

      const protocols = allProtocols.filter((protocol: Protocol) => _.isEmpty(protocol.closedAt));
      const closedCount = allProtocols.length - protocols.length;

      return {
        protocols,
        closedCount,
      };
    })
  );

  filteredProtocols$: Observable<Protocol[]> = this.protocolsWithClosedCount$.pipe(map(({protocols}) => protocols));

  firstProtocol$: Observable<Nullish<Protocol>> = this.filteredProtocols$.pipe(map((protocols) => protocols?.[0]));

  constructor(
    private protocolDataService: ProtocolDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolFilterService: ProtocolFilterService,
    private protocolSortingService: ProtocolSortingService
  ) {}
}
