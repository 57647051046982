import {ColumnConfig} from '../ColumnConfig';
import {skipColumn} from './skipColumn';

const UniqueProtocolTypesInProjectUniq = ['project_id', 'protocoltype_id'];

export const ProjectProtocolTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueProtocolTypesInProjectUniq]},
  {name: 'protocoltype_id', prop: 'protocoltypeId', compositeKey: true, uniqueWith: [UniqueProtocolTypesInProjectUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
