import {Injectable} from '@angular/core';
import {IonMenu} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class IonMenuService {
  private _ionMenu: IonMenu | undefined;

  get ionMenu() {
    return this._ionMenu;
  }

  /**
   * This function is meant to be called only by AppComponent, when IonMenu reference is first available
   */
  setIonMenu(ionMenu: IonMenu) {
    this._ionMenu = ionMenu;
  }
}
