import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {PdfPlanFolderService} from 'src/app/services/project-room/pdf-plan-folder.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {ICON_SPLIT_CHARACTER} from '../pdf-plan-folder-icon-picker/pdf-plan-folder-icon-picker.component';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';

@Component({
  selector: 'app-pdf-plan-folder-picker',
  templateUrl: './pdf-plan-folder-picker.component.html',
  styleUrls: ['./pdf-plan-folder-picker.component.scss'],
  exportAs: 'appPdfPlanFolderPickerComponent',
})
export class PdfPlanFolderPickerComponent implements OnInit {
  @ViewChild('selectable', {
    read: IonicSelectableComponent,
    static: true,
  })
  selectable?: IonicSelectableComponent;

  folders$: Observable<{id: string; name: string; icon?: Nullish<string>}[]> = this.pdfPlanFolderService.getPdfPlanFolderWithDeletable$().pipe(
    map((folders) => [
      {
        id: 'all',
        name: this.translateService.instant('project_room.pdf_plan_folder.all_plans'),
        icon: `fal${ICON_SPLIT_CHARACTER}folder`,
      },
      ...folders,
    ]),
    startWith([] as {id: string; name: string; icon?: Nullish<string>}[]),
    filter((v) => Boolean(v))
  );
  folderId$ = this.pdfPlanFolderService.routeFolderId$;

  folder$ = combineLatestAsync([this.folders$, this.folderId$]).pipe(map(([folders, folderId]) => folders.find((folder) => folder.id === folderId)));

  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(
    private pdfPlanFolderService: PdfPlanFolderService,
    private translateService: TranslateService,
    private selectableUtilService: SelectableUtilService
  ) {}

  ngOnInit() {}

  navigateToFolder(folderId: string) {
    this.pdfPlanFolderService.navigateToFolder(folderId);
  }

  open() {
    this.selectable?._click();
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
