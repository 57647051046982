import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContactsFilter} from 'src/app/model/contacts-filter';

export const DEFAULT_CONTACTS_FILTER: ContactsFilter = {
  craftIds: [],
};

@Injectable({
  providedIn: 'root',
})
export class ContactsFilterService implements OnDestroy {
  private filterSubject = new BehaviorSubject<ContactsFilter>(DEFAULT_CONTACTS_FILTER);
  filter$ = this.filterSubject.asObservable();
  get filter() {
    return this.filterSubject.value;
  }

  active$ = this.filter$.pipe(map(() => this.active));
  get active() {
    return this.filter.craftIds.length > 0;
  }

  constructor() {}

  setFilter(filter: ContactsFilter) {
    this.filterSubject.next(filter);
  }

  clear() {
    this.filterSubject.next(DEFAULT_CONTACTS_FILTER);
  }

  ngOnDestroy() {
    this.filterSubject.complete();
  }
}
