import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TagsPopoverService} from 'src/app/services/tags/tags-popover.service';
import {TagBase} from 'submodules/baumaster-v2-common';
import {AccordionComponent} from '../../ui/accordion/accordion.component';

@Component({
  selector: 'app-object-tag-list-popover',
  templateUrl: './object-tag-list-popover.component.html',
  styleUrls: ['./object-tag-list-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectTagListPopoverComponent {
  readonly newIdPrefix = this.tagsPopoverService.newIdPrefix;

  @Input()
  tags: TagBase[];
  @Input()
  filteredTags: TagBase[];
  @Output()
  tagRemove = new EventEmitter<TagBase>();
  @Output()
  tagAdd = new EventEmitter<TagBase>();
  @Output()
  editClick = new EventEmitter<MouseEvent>();

  @ViewChild(AccordionComponent, {
    read: AccordionComponent,
    static: true,
  })
  appliedTagsAccordion: AccordionComponent;

  constructor(private tagsPopoverService: TagsPopoverService) {}

  onSearchChange(search: string) {
    this.appliedTagsAccordion?.setOpen(!search);
  }
}
