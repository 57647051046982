import {ColumnConfig} from '../ColumnConfig';

const SinglePdfConfigPerClientUniq = ['client_id'];

export const CustomPdfConfigurationColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'oem_pdfs', prop: 'oemPdfs'},
  {name: 'hide_internal_assignment', prop: 'hideInternalAssignment'},
  {name: 'hide_company_from_protocol', prop: 'hideCompanyFromProtocol'},
  {name: 'client_id', prop: 'clientId', cnd: true, uniqueWith: [SinglePdfConfigPerClientUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
