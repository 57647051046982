import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {AttachmentProjectBanner, generateFilePathsForAttachmentProjectBanner, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/attachmentProjectBanner/';

@Injectable({
  providedIn: 'root',
})
export class AttachmentProjectBannerDataService extends AbstractProjectAwareAttachmentDataService<AttachmentProjectBanner> {
  constructor(
    projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    loggingService: LoggingService,
    systemEventService: SystemEventService,
    protected devModeService: DevModeService,
    integrityResolverService: IntegrityResolverService,
    protected translateService: TranslateService,
    protected attachmentSettingService: AttachmentSettingService
  ) {
    super(
      StorageKeyEnum.ATTACHMENT_PROJECT_BANNER,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      systemEventService,
      devModeService,
      integrityResolverService,
      translateService,
      attachmentSettingService,
      projectAvailabilityExpirationService,
      VERSION_INTRODUCED_DEFAULT,
      ATTACHMENT_DEFAULT_SORT_COLUMNS,
      ATTACHMENT_DEFAULT_SORT_ORDER
    );
  }

  protected setGeneratedFilePath(attachment: AttachmentProjectBanner) {
    attachment.filePath = generateFilePathsForAttachmentProjectBanner(attachment).filePath;
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
