import {Injectable} from '@angular/core';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {
  Address,
  Attachment,
  AttachmentBimMarkerScreenshotWithProtocolEntry,
  AttachmentProtocolEntry,
  AttachmentWithContent,
  Client,
  CommonEntryMailService,
  Company,
  Craft,
  ENTRY_MAIL_REQ_DEFAULT,
  EntryMailData,
  EntryMailReq,
  IdAware,
  IdType,
  NameableDropdown,
  PdfPlanAttachmentWithContent,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  Profile,
  Project,
  Protocol,
  ProtocolEntry,
  ProtocolEntryCompany,
  ProtocolEntryLocation,
  ProtocolEntryType,
  ProtocolType,
} from 'submodules/baumaster-v2-common';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {LoggingService} from '../common/logging.service';
import {PhotoService} from '../photo/photo.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {Observable} from 'rxjs';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, switchMap} from 'rxjs/operators';
import _ from 'lodash';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {ClientService} from '../client/client.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {AttachmentBimMarkerScreenshotDataService} from '../data/attachment-bim-marker-screenshot-data.service';
import {PdfPlanService} from '../pdf/pdf-plan.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';
import {PdfProtocolService} from '../pdf/pdf-protocol.service';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {BimPlanService} from '../project-room/bim-plan.service';
import {ProtocolEntryOrOpen} from '../../model/protocol';
import {isImage} from '../../utils/attachment-utils';
import {CustomPdfConfigurationDataService} from '../data/custom-pdf-configuration-data.service';

const LOG_SOURCE = 'EntryMailService';

interface PdfPlanMarkerContent {
  pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>;
  pdfPlanPageMarkings: Array<PdfPlanPageMarking>;
  pdfPlanPages: Array<PdfPlanPage>;
  pdfPlanVersions: Array<PdfPlanVersion>;
}

@Injectable({
  providedIn: 'root',
})
export class EntryMailService {
  private commonEntryMailService = new CommonEntryMailService();

  constructor(
    private protocolEntryDataService: ProtocolEntryDataService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private photoService: PhotoService,
    private projectDataService: ProjectDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private companyDataService: CompanyDataService,
    private craftDataService: CraftDataService,
    private addressDataService: AddressDataService,
    private profileDataService: ProfileDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private loggingService: LoggingService,
    private http: HttpClient,
    private clientService: ClientService,
    private nameableDropdownDataService: NameableDropdownDataService,
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private pdfPlanService: PdfPlanService,
    private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
    private pdfProtocolService: PdfProtocolService,
    private bimPlanService: BimPlanService,
    private customPdfConfigurationDataService: CustomPdfConfigurationDataService
  ) {}

  private getPdfPlanMarkerContent$(entryIds: Array<IdType>): Observable<PdfPlanMarkerContent> {
    return combineLatestAsync([this.pdfPlanService.getLatestMarkers(entryIds), this.pdfPlanPageDataService.dataGroupedById, this.pdfPlanVersionDataService.dataGroupedById]).pipe(
      map(([{pdfPlanMarkerProtocolEntries, pdfPlanPageMarkings}, pdfPlanPagesById, pdfPlanVersionsById]) => {
        const pdfPlanPageIds = _.compact(_.uniq(pdfPlanMarkerProtocolEntries.map((v) => v.pdfPlanPageId)));
        const pdfPlanPages = _.compact(pdfPlanPageIds.map((id) => pdfPlanPagesById[id]));
        const pdfPlanVersionIds = _.compact(_.uniq(pdfPlanPages.map((v) => v.pdfPlanVersionId)));
        const pdfPlanVersions = _.compact(pdfPlanVersionIds.map((id) => pdfPlanVersionsById[id]));
        return {
          pdfPlanMarkerProtocolEntries,
          pdfPlanPageMarkings,
          pdfPlanPages,
          pdfPlanVersions,
        };
      })
    );
  }

  private getBimMarkerContent$(entryIds: Array<IdType>): Observable<Array<AttachmentBimMarkerScreenshotWithProtocolEntry>> {
    return combineLatestAsync([this.bimPlanService.getLatestMarkers$(entryIds), this.attachmentBimMarkerScreenshotDataService.data]).pipe(
      map(([bimMarkers, allAttachmentBimMarkerScreenshots]) => {
        const bimMarkersById = _.keyBy(bimMarkers, 'id');
        return allAttachmentBimMarkerScreenshots
          .map((attachment) => {
            return {...attachment, protocolEntryId: bimMarkersById[attachment.bimMarkerId]?.protocolEntryId};
          })
          .filter((v) => v.protocolEntryId);
      })
    );
  }

  public generateHtmlContent$(entryIds: Array<IdType>): Observable<{html: string; attachmentsMissingContent: Array<Attachment>}> {
    this.loggingService.debug(LOG_SOURCE, `generateHtmlContent$ called with ${entryIds?.length} entryIds.`);
    return combineLatestAsync([
      combineLatestAsync([
        this.projectDataService.currentProjectObservable,
        this.protocolDataService.data,
        this.protocolEntryDataService.getByIds(entryIds),
        this.attachmentEntryDataService.getByProtocolEntries(entryIds),
        this.getPdfPlanMarkerContent$(entryIds),
        this.getBimMarkerContent$(entryIds),
        this.protocolEntryCompanyDataService.data,
        this.clientService.currentClient$,
      ]),
      combineLatestAsync([
        this.companyDataService.dataAcrossClients$,
        this.craftDataService.dataAcrossClients$,
        this.addressDataService.dataAcrossClients$,
        this.profileDataService.dataAcrossClientsWithDefaultType$,
        this.protocolTypeDataService.dataAcrossClients$,
        this.protocolEntryTypeDataService.dataAcrossClients$,
        this.nameableDropdownDataService.dataAcrossClients$,
        this.protocolEntryLocationDataService.data,
      ]),
    ]).pipe(
      switchMap(
        ([
          [currentProject, protocols, entries, attachments, pdfPlanMarkerContent, bimMarkerContent, observerCompanies, client],
          [companies, crafts, addresses, profiles, protocolTypes, protocolEntryTypes, nameableDropdowns, locations],
        ]) => {
          const protocolIds = _.compact(_.uniq(entries.map((entry) => entry.protocolId)));
          const usedProtocols = protocols.filter((protocol) => protocolIds.includes(protocol.id));
          return this.generateHtmlContentInternal(
            entryIds,
            currentProject,
            client,
            usedProtocols,
            entries,
            attachments,
            pdfPlanMarkerContent,
            bimMarkerContent,
            observerCompanies,
            companies,
            crafts,
            addresses,
            profiles,
            protocolTypes,
            protocolEntryTypes,
            nameableDropdowns,
            locations
          );
        }
      )
    );
  }

  private convertEntriesToProtocolEntryOrOpen(entries: Array<ProtocolEntry>): Array<ProtocolEntryOrOpen> {
    return entries.map((entry) => {
      return {...entry, isOpenEntry: false};
    });
  }

  private async generateHtmlContentInternal(
    entryIds: Array<IdType>,
    project: Project,
    client: Client,
    protocols: Array<Protocol>,
    entries: Array<ProtocolEntry>,
    attachments: Array<AttachmentProtocolEntry>,
    pdfPlanMarkerContent: PdfPlanMarkerContent,
    attachmentBimMarkers: Array<AttachmentBimMarkerScreenshotWithProtocolEntry>,
    observerCompanies: Array<ProtocolEntryCompany>,
    companies: Array<Company>,
    crafts: Array<Craft>,
    addresses: Array<Address>,
    profiles: Array<Profile>,
    protocolTypes: Array<ProtocolType>,
    protocolEntryTypes: Array<ProtocolEntryType>,
    nameableDropdowns: Array<NameableDropdown>,
    locations: Array<ProtocolEntryLocation>
  ): Promise<{html: string; attachmentsMissingContent: Array<Attachment>}> {
    const attachmentsMissingContent = new Array<Attachment>();
    const protocolEntryTypesMap = this.toMap(protocolEntryTypes);
    const attachmentsWithContent: Array<AttachmentWithContent<AttachmentProtocolEntry>> = await this.photoService.toAttachmentWithContent(attachments, 'thumbnail', attachmentsMissingContent, {
      orderByDate: true,
    });
    const pdfPlanAttachmentsWithContent: Array<PdfPlanAttachmentWithContent> = !ENTRY_MAIL_REQ_DEFAULT.includePdfPlanMarkers
      ? []
      : await this.pdfProtocolService.toPdfPlanAttachmentWithContent(
          pdfPlanMarkerContent.pdfPlanVersions,
          pdfPlanMarkerContent.pdfPlanPages,
          pdfPlanMarkerContent.pdfPlanMarkerProtocolEntries,
          pdfPlanMarkerContent.pdfPlanPageMarkings,
          this.convertEntriesToProtocolEntryOrOpen(entries),
          protocolEntryTypesMap,
          attachmentsMissingContent
        );
    const bimMarkerScreenshotsWithContent: Array<AttachmentWithContent<AttachmentBimMarkerScreenshotWithProtocolEntry>> = !ENTRY_MAIL_REQ_DEFAULT.includeBimMarkers
      ? []
      : await this.photoService.toAttachmentWithContent(attachmentBimMarkers, 'thumbnail', attachmentsMissingContent, {orderByDate: true});

    const req: EntryMailReq = {
      entryIds,
      recipientProfileIds: [],
    };
    const data: EntryMailData = {
      project,
      protocols,
      protocolEntryTypes,
      entries,
      imageAttachments: attachments.filter((attachment) => isImage(attachment)),
      attachmentsWithContent,
      pdfPlanAttachmentsWithContent,
      bimMarkerScreenshotsWithContent,
      observerCompanies,
      nameableDropdownName: client.nameableDropdownName,
      lookup: {
        companies: this.toMap(companies),
        crafts: this.toMap(crafts),
        addresses: this.toMap(addresses),
        profiles: this.toMap(profiles),
        protocolTypes: this.toMap(protocolTypes),
        protocolEntryTypes: this.toMap(protocolEntryTypes),
        nameableDropdowns: this.toMap(nameableDropdowns),
        locations: this.toMap(locations),
      },
    };
    const html = await this.commonEntryMailService.generateHtml(data, req, false);
    return {html, attachmentsMissingContent};
  }

  public toMap<T extends IdAware>(values: Array<T>): Map<IdType, T> {
    const valueMap = new Map<IdType, T>();
    for (const value of values) {
      valueMap.set(value.id, value);
    }
    return valueMap;
  }

  public async sendMail(req: EntryMailReq): Promise<void> {
    const url = await this.getSendPdfUrl();
    await observableToPromise(this.http.post<void>(url, req));
    return;
  }

  private async getSendPdfUrl(): Promise<string> {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    return environment.serverUrl + `api/entryMail/send?projectId=${currentProject.id}`;
  }
}
