import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {PdfPlanVersion, ProtocolEntryLocation, TagBase} from 'submodules/baumaster-v2-common';
import {AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {MbscDatepickerOptions} from '@mobiscroll/angular-ivy';
import {TranslateService} from '@ngx-translate/core';
import {ProjectProtocolLocationDataService} from '../../../services/data/project-protocol-location-data.service';
import {LoggingService} from '../../../services/common/logging.service';
import {convertDateTimeToISOString, convertISOStringToDate} from '../../../utils/date-utils';
import {takeUntil} from 'rxjs/operators';
import {PdfPlanVersionDataService} from '../../../services/data/pdf-plan-version-data.service';
import {MobiscrollService} from '../../../services/common/mobiscroll.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';

const LOG_SOURCE = 'PdfPlanVersionFormComponent';

@Component({
  selector: 'app-pdf-plan-version-form',
  templateUrl: './pdf-plan-version-form.component.html',
  styleUrls: ['./pdf-plan-version-form.component.scss'],
})
export class PdfPlanVersionFormComponent implements OnInit, OnChanges, OnDestroy {
  public readonly datePickerSettings: MbscDatepickerOptions = {
    dateFormat: this.mobiscrollService.DATE_FORMAT,
    returnFormat: 'iso8601',
    min: new Date(),
  };

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  @Input()
  public pdfPlanVersion: PdfPlanVersion;
  @Input()
  public tags: TagBase[];
  @Output()
  private pdfPlanVersionChange = new EventEmitter<PdfPlanVersion>();
  @Output()
  private tagsChange = new EventEmitter<TagBase[]>();
  @Output()
  private validChange = new EventEmitter<boolean>();
  private destroy$ = new Subject<void>();
  public locations$: Observable<ProtocolEntryLocation[]>;
  public locations: ProtocolEntryLocation[] | undefined;

  public form = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(60)]],
    index: ['', [Validators.maxLength(4), this.uniqueIndexValidator()]],
    description: [''],
    date: ['', [Validators.required]],
    scale: ['', [Validators.maxLength(10)]],
    tags: [],
    locationId: [''],
  });
  private pdfPlanVersions$: Observable<Array<PdfPlanVersion> | undefined>;
  private pdfPlanVersions: Array<PdfPlanVersion> | undefined;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    public translateService: TranslateService,
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
    private loggingService: LoggingService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private mobiscrollService: MobiscrollService,
    private selectableUtilService: SelectableUtilService
  ) {}

  ngOnInit() {
    this.locations$ = this.projectProtocolLocationDataService.getProjectProtocolLocations();
    this.pdfPlanVersions$ = this.pdfPlanVersionDataService.findByPdfPlan$(this.pdfPlanVersion.pdfPlanId);
    this.pdfPlanVersions$.pipe(takeUntil(this.destroy$)).subscribe((pdfPlanVersions) => {
      this.pdfPlanVersions = pdfPlanVersions;
      this.form.get('index').updateValueAndValidity();
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formValues) => {
      if (this.form.valid) {
        const pdfPlanVersion = this.formValuesToPdfPlanVersion(formValues);
        this.pdfPlanVersion = pdfPlanVersion;
        this.loggingService.debug(LOG_SOURCE, `Emitting new values.`);
        this.pdfPlanVersionChange.emit(pdfPlanVersion);
      }
      this.loggingService.debug(LOG_SOURCE, `Emitting value for valid=${this.form.valid}.`);
      this.validChange.emit(this.form.valid);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pdfPlanVersion && changes.pdfPlanVersion.currentValue) {
      this.loggingService.debug(LOG_SOURCE, 'ngOnChanges - new value for pdfPlanVersion');
      this.patchFormWithPdfPlanVersion(this.pdfPlanVersion);
      this.validChange.emit(this.form.valid);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private patchFormWithPdfPlanVersion(pdfPlanVersion: PdfPlanVersion) {
    this.form.patchValue(
      {
        name: pdfPlanVersion.name,
        index: pdfPlanVersion.index,
        date: convertISOStringToDate(pdfPlanVersion.date),
        scale: pdfPlanVersion.scale,
        description: pdfPlanVersion.description,
        locationId: pdfPlanVersion.locationId,
      },
      {emitEvent: false}
    );
  }

  private formValuesToPdfPlanVersion(formValues: any): PdfPlanVersion {
    return {
      ...this.pdfPlanVersion,
      ...{
        name: formValues.name,
        index: formValues.index,
        date: convertDateTimeToISOString(formValues.date),
        scale: formValues.scale,
        description: formValues.description,
        locationId: formValues.locationId ?? null,
      },
    };
  }

  private uniqueIndexValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const index: string | null | undefined = control.value;
      if (!index) {
        return null;
      }
      if (!this.pdfPlanVersions) {
        return null;
      }

      const recordExist = this.pdfPlanVersions.some((pdfPlanVersion) => pdfPlanVersion.id !== this.pdfPlanVersion.id && pdfPlanVersion.index && pdfPlanVersion.index === index);
      if (recordExist) {
        return {indexExists: true};
      }
      return null;
    };
  }

  handleTagsChange(tags: TagBase[]) {
    this.tags = tags;
    this.tagsChange.emit(tags);
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
