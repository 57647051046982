import {ProtocolEntry} from 'submodules/baumaster-v2-common';
import {DashboardFilter, DashboardFilterDate, ValuedDashboardFilterDate} from '../model/dashboard-filter';
import {ProtocolEntryCompareFilter, ProtocolEntryInFilter} from '../services/protocol/protocol-entry-filter.service';
import {getWeekRange, trimTimeFromDate} from './date-utils';

export const compareIdArrays = (a: (string | number)[] | null, b: (string | number)[] | null) => (a === null && b === null) || (a?.length === b?.length && a.every((v) => b.includes(v)));

export const TODO_UNTIL_FIELD: keyof ProtocolEntry = 'todoUntil';

export const isValuedDashboardFilterDate = (theFilter: DashboardFilterDate): theFilter is ValuedDashboardFilterDate => ['next_days', 'calendar_week'].includes(theFilter.type);

export const compareDashboardFilters = (thisFilter: DashboardFilter, otherFilter: DashboardFilter): boolean => {
  if (
    !compareIdArrays(thisFilter.companies, otherFilter.companies) ||
    !compareIdArrays(thisFilter.priorities, otherFilter.priorities) ||
    !compareIdArrays(thisFilter.projects, otherFilter.projects) ||
    !compareIdArrays(thisFilter.protocolTypes, otherFilter.protocolTypes) ||
    !compareIdArrays(thisFilter.protocols, otherFilter.protocols) ||
    !compareIdArrays(thisFilter.statuses, otherFilter.statuses) ||
    thisFilter.projectTeam !== otherFilter.projectTeam
  ) {
    return false;
  }

  if (thisFilter.date.type !== otherFilter.date.type) {
    return false;
  }

  if (isValuedDashboardFilterDate(thisFilter.date) && isValuedDashboardFilterDate(otherFilter.date) && thisFilter.date.value !== otherFilter.date.value) {
    return false;
  }

  return true;
};

export const dashboardFiltersToProtocolEntryFilters = (filters: Partial<DashboardFilter>): (ProtocolEntryInFilter | ProtocolEntryCompareFilter)[] => {
  const params: (ProtocolEntryInFilter | ProtocolEntryCompareFilter)[] = [];

  if (filters.companies || filters.projectTeam) {
    params.push({
      mode: 'in',
      fieldName: 'companyId',
      value: filters.companies ?? [],
    });
  }
  if (filters.projectTeam) {
    params.push({
      mode: 'in',
      fieldName: 'allCompanies',
      value: [filters.projectTeam],
    });
  }
  if (filters.statuses) {
    params.push({
      mode: 'in',
      fieldName: 'status',
      value: filters.statuses,
    });
  }
  if (filters.priorities) {
    params.push({
      mode: 'in',
      fieldName: 'priority',
      value: filters.priorities,
    });
  }
  if (filters.protocols) {
    params.push({
      mode: 'in',
      fieldName: 'protocolId',
      value: filters.protocols,
    });
  }
  if (filters.date) {
    params.push(...dateFilterToProtocolEntryParameterFilters(filters.date));
  }

  return params;
};

export const dateFilterToProtocolEntryParameterFilters = (dateFilter: DashboardFilterDate): (ProtocolEntryInFilter | ProtocolEntryCompareFilter)[] => {
  if (dateFilter.type === 'overdue') {
    const date = trimTimeFromDate(new Date());

    return [
      {
        mode: 'compare',
        direction: 'lt',
        value: date.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
    ];
  }

  if (dateFilter.type === 'future') {
    const date = trimTimeFromDate(new Date());
    date.setDate(date.getDate() + 1);
    return [
      {
        mode: 'compare',
        direction: 'gt',
        value: date.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
    ];
  }

  if (dateFilter.type === 'today') {
    const begin = new Date();
    begin.setHours(0, 0, 0, 0);
    const end = new Date(begin);
    end.setDate(end.getDate() + 1);
    return [
      {
        mode: 'compare',
        direction: 'gte',
        value: begin.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
      {
        mode: 'compare',
        direction: 'lt',
        value: end.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
    ];
  }

  if (dateFilter.type === 'next_days') {
    const begin = new Date();
    begin.setHours(0, 0, 0, 0);
    const end = new Date(begin);
    end.setDate(end.getDate() + dateFilter.value);
    return [
      {
        mode: 'compare',
        direction: 'gte',
        value: begin.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
      {
        mode: 'compare',
        direction: 'lt',
        value: end.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
    ];
  }

  if (dateFilter.type === 'calendar_week') {
    const [begin, end] = getWeekRange(new Date(dateFilter.value));

    return [
      {
        mode: 'compare',
        direction: 'gte',
        value: begin.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
      {
        mode: 'compare',
        direction: 'lt',
        value: end.toISOString(),
        fieldName: TODO_UNTIL_FIELD,
      },
    ];
  }

  if (dateFilter.type === 'no_date') {
    return [
      {
        mode: 'in',
        value: [null, undefined],
        fieldName: TODO_UNTIL_FIELD,
      },
    ];
  }

  return [];
};
