import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {RxLet} from '@rx-angular/template/let';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TextTemplateEditModalService} from 'src/app/services/text-template/text-template-edit-modal.service';
import {TextTemplateService} from 'src/app/services/text-template/text-template.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {isClientTextTemplate} from 'src/app/utils/text-template-utils';
import {IdType, TextTemplate} from 'submodules/baumaster-v2-common';
import {NoFilteredItemsComponent} from '../../common/no-filtered-items/no-filtered-items.component';
import {NoItemsComponent} from '../../common/no-items/no-items.component';
import {TextTemplateListComponent} from '../text-template-list/text-template-list.component';
import {PosthogService} from 'src/app/services/posthog/posthog.service';

type TextTemplateModalData = {
  clientTextTemplates: TextTemplate[];
  textTemplates: TextTemplate[];
  hasGlobalTemplates: boolean;
  hasSearch: boolean;
};

@Component({
  selector: 'app-text-template-modal',
  templateUrl: './text-template-modal.component.html',
  styleUrls: ['./text-template-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, RxLet, UiModule, IonicModule, TranslateModule, FormsModule, TextTemplateListComponent, NoItemsComponent, FontAwesomeModule, NoFilteredItemsComponent],
})
export class TextTemplateModalComponent implements OnInit {
  private modal: HTMLIonModalElement;
  private searchSubject = new BehaviorSubject('');

  @Input()
  category: string;

  @Input()
  prefillTextTemplate?: Partial<TextTemplate>;

  data$: Observable<TextTemplateModalData>;

  templateType: 'client' | 'global' = 'client';

  selectedTemplateId: IdType | undefined;

  get search() {
    return this.searchSubject.value;
  }
  set search(str: string) {
    this.searchSubject.next(str);
  }

  constructor(
    private textTemplateService: TextTemplateService,
    private textTemplateEditModalService: TextTemplateEditModalService,
    private posthogService: PosthogService
  ) {}

  ngOnInit() {
    this.data$ = this.getDataObservable();
  }

  private getDataObservable(): Observable<TextTemplateModalData> {
    return combineLatestAsync([this.textTemplateService.getClientTemplates$(this.category), this.textTemplateService.getGlobalTemplates$(this.category), this.searchSubject]).pipe(
      map(([clientTextTemplates, textTemplates, search]) => ({
        clientTextTemplates: clientTextTemplates.filter((template) => template.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())),
        textTemplates: textTemplates.filter((template) => template.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())),
        hasGlobalTemplates: textTemplates.length > 0,
        hasSearch: !!search,
      }))
    );
  }

  dismiss() {
    this.modal.dismiss();
  }

  async getTemplateForCurrentType(templateId: IdType) {
    const data = await observableToPromise(this.data$);
    return (this.templateType === 'client' ? data.clientTextTemplates : data.textTemplates).find((template) => template.id === templateId);
  }

  getSelectedTemplate() {
    return this.getTemplateForCurrentType(this.selectedTemplateId);
  }

  handleTemplateTypeChange() {
    this.selectedTemplateId = undefined;
    this.search = '';
  }

  async apply() {
    if (this.selectedTemplateId) {
      const selectedTemplate = await this.getSelectedTemplate();
      if (!selectedTemplate) {
        return;
      }
      if (this.templateType === 'global') {
        this.posthogService.captureEvent('[TextTemplate] Used Baumaster Template', {templateName: selectedTemplate.name});
      }
      this.modal.dismiss({
        textTemplate: selectedTemplate.textTemplate,
      });
    }
  }

  async create() {
    const textTemplate = await this.textTemplateEditModalService.createClientTextTemplate(this.category, this.prefillTextTemplate);
    if (textTemplate) {
      this.modal.dismiss({textTemplate: textTemplate.textTemplate});
    }
  }

  async edit(templateId: IdType = this.selectedTemplateId) {
    const selectedTemplate = await this.getTemplateForCurrentType(templateId);
    if (selectedTemplate && isClientTextTemplate(selectedTemplate)) {
      const textTemplate = await this.textTemplateEditModalService.editClientTextTemplate(selectedTemplate);
      if (textTemplate) {
        this.modal.dismiss({textTemplate: textTemplate.textTemplate});
      }
    }
  }

  async copy(templateId: IdType = this.selectedTemplateId) {
    const selectedTemplate = await this.getTemplateForCurrentType(templateId);
    if (selectedTemplate && !isClientTextTemplate(selectedTemplate)) {
      const textTemplate = await this.textTemplateEditModalService.createClientTextTemplate(this.category, {
        name: selectedTemplate.name,
        textTemplate: selectedTemplate.textTemplate,
      });
      if (textTemplate) {
        if (this.templateType === 'global') {
          this.posthogService.captureEvent('[TextTemplate] Copied Baumaster Template', {templateName: selectedTemplate.name});
        }
        this.modal.dismiss({textTemplate: textTemplate.textTemplate});
      }
    }
  }
}
