import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-baumaster-logo-svg',
  templateUrl: './baumaster-logo-svg.component.html',
  styleUrls: ['./baumaster-logo-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaumasterLogoSvgComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
