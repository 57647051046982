import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {Nullish} from 'src/app/model/nullish';
import {BauColorsKeys} from 'src/app/shared/colors';

@Component({
  selector: 'app-chip-with-label',
  templateUrl: './chip-with-label.component.html',
  styleUrls: ['./chip-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipWithLabelComponent {
  @Input()
  size: Nullish<'medium' | 'small'>;
  @Input()
  chip: string;
  @Input()
  color: BauColorsKeys = 'text-primary';

  @HostBinding('class.medium')
  get mediumClass() {
    return this.size === 'medium';
  }

  @HostBinding('class.small')
  get smallClass() {
    return this.size === 'small';
  }

  @HostBinding('style.--background')
  get backgroundStyle() {
    return `var(--ion-color-${this.color})`;
  }

  @HostBinding('style.--color')
  get colorStyle() {
    return `var(--ion-color-${this.color}-contrast)`;
  }
}
