import {Injectable} from '@angular/core';
import {UserDataService} from '../data/user-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IdType} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../utils/async-utils';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  private readonly termsAndConditionsVersions = [1]; // new versions (must be incremental) should be added here and in the translation files under the key "termsAndConditions.versions"
  public readonly latestVersion = this.termsAndConditionsVersions.length ? this.termsAndConditionsVersions[this.termsAndConditionsVersions.length - 1] : undefined;
  public readonly latestVersionTermsOfUseTranslationKey = this.latestVersion !== undefined ? `termsAndConditions.versions.${this.latestVersion}.termsOfUse` : undefined;
  public readonly latestVersionTermsAndConditionsTranslationKey = this.latestVersion !== undefined ? `termsAndConditions.versions.${this.latestVersion}.termsAndConditions` : undefined;
  public readonly currentUserAccepted$: Observable<{userId: IdType; accepted: boolean; acceptedVersion?: number} | undefined>;

  constructor(private userDataService: UserDataService) {
    this.currentUserAccepted$ = this.userDataService.currentUser$.pipe(
      map((user) => {
        if (!user) {
          return undefined;
        }
        const accepted = this.latestVersion === undefined || user.termsAndConditionsVersion >= this.latestVersion;
        const acceptedVersion = accepted ? user.termsAndConditionsVersion : undefined;
        return {userId: user.id, accepted, acceptedVersion};
      })
    );
  }

  public async accept(userId: IdType, termsAndConditionsVersion: number) {
    const user = await observableToPromise(this.userDataService.getById(userId));
    if (!user) {
      throw new Error(`User with id ${userId} does not exist.`);
    }
    if (user.termsAndConditionsVersion === undefined || user.termsAndConditionsVersion < termsAndConditionsVersion) {
      user.termsAndConditionsVersion = termsAndConditionsVersion;
      await this.userDataService.update(user);
    }
  }
}
