import {Content, TDocumentDefinitions} from 'pdfmake/interfaces';
import {PdfPreview} from '../../models';
import {PdfProtocolSendReq} from '../../requestResponse';
import {ProjectContent} from './content/globalSearch/project.content';
import {ProtocolEntriesContent} from './content/globalSearch/protocolEntries.content';
import {GlobalSearchPdfProtocolGenerateData, HeaderFooterData} from './pdfProtocol.model';
import {AbstractPdfCommon} from './abstractPdfCommon.service';
import {PdfHelperFunctions} from '../common-report-utils';

export abstract class AbstractPdfGlobalSearchCommonService extends AbstractPdfCommon {
  getDocDefinition(
    config: PdfProtocolSendReq,
    data: GlobalSearchPdfProtocolGenerateData,
    pdfHelperFunctions: PdfHelperFunctions,
    bannerDimensions?: number[] | undefined,
    indexNumber?: number,
    pdfPreview?: PdfPreview
  ): TDocumentDefinitions {
    try {
      const content: Content = [];
      const projectContent = new ProjectContent(config, data, pdfHelperFunctions, pdfPreview);
      projectContent.writeProject(content);
      const protocolEntriesContent = new ProtocolEntriesContent(config, data, pdfHelperFunctions, pdfPreview);
      protocolEntriesContent.writeProtocolEntries(content);
      content.push({text: '.', style: ['lastPage'], fontSize: 1}); // padding for pdf footer image
      return this.getGeneratedDocDefinitions(
        data.language,
        content,
        config,
        {
          attachmentClients: data.attachmentClients,
          pdfProjectBanners: data.pdfProjectBanners,
        } as HeaderFooterData,
        bannerDimensions
      );
    } catch (err) {
      throw new Error(`Error generating pdf. ${err}`);
    }
  }

  protected getGeneratedDocDefinitions(language: string, content: Content[], config: PdfProtocolSendReq, data: HeaderFooterData, bannerDimensions?: number[] | undefined): TDocumentDefinitions {
    const docDefinition: TDocumentDefinitions = {
      pageSize: 'A4',
      content,
      styles: this.getPdfStyle(config, bannerDimensions),
      images: this.getPdfImage(data),
      footer: (currentPage: number, pageCount: number) => {
        return this.getPdfFooter(currentPage, pageCount, language, config, data);
      },
      defaultStyle: {
        font: 'Inter',
      },
      pageMargins: [40, 50, 40, 40],
      pageBreakBefore: (currentNode) => {
        return currentNode.id === 'imageRow' && currentNode.pageNumbers.length > 1;
      },
    };
    return docDefinition;
  }
}
