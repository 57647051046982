import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Subscription} from 'rxjs';
import {getFormattedNumber, ProjectCurrencyEnum, ProjectCurrencySymbolEnum} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {LoggingService} from '../services/common/logging.service';
import {LanguageService} from '../services/i18n/language.service';

@Pipe({
  name: 'numberFormat',
  pure: false,
})
export class NumberFormatPipe implements PipeTransform, OnDestroy {
  private currentLanguage: Nullish<string>;
  private currentLanguageSubscription: Nullish<Subscription>;

  constructor(
    private languageService: LanguageService,
    private loggingService: LoggingService
  ) {
    // Can't place it in ngOnInit
    // https://github.com/angular/angular/issues/10279
    this.subscribe();
  }

  private subscribe() {
    this.currentLanguageSubscription = this.languageService.selectedLanguage.subscribe((language) => (this.currentLanguage = language));
  }

  private unsubscribe() {
    this.currentLanguageSubscription?.unsubscribe();
  }

  transform(value: Nullish<number | string>, currencyCode?: keyof typeof ProjectCurrencyEnum): string {
    // null/undef is checked by getFormattedNumber
    if (!this.currentLanguage || value === '') {
      return '';
    }

    try {
      return `${getFormattedNumber(this.currentLanguage, parseFloat(`${value}`))}${currencyCode ? ' ' + ProjectCurrencySymbolEnum[currencyCode] : ''}`;
    } catch (e) {
      this.loggingService.error('NumberFormatPipe', `Failed to format value ${value}. Exception message: ${e?.message}`);
    }

    return '';
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
