import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {DEFAULT_PROTOCOL_COLOR} from 'src/app/shared/constants';
import {observableToPromise} from 'src/app/utils/async-utils';
import {convertRichTextToPlainText, convertToRichText, IdType, isRichText, PdfPrintEntryDetails, PdfProtocolLayout, PdfProtocolSetting, ShowPicturesEnum} from 'submodules/baumaster-v2-common';
import {PdfProtocolSettingDataService} from '../data/pdf-protocol-setting-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {WorkflowType} from '../../model/send-protocol';
import {TranslateService} from '@ngx-translate/core';
import {EmptyUserEmailSignature} from 'src/app/model/email-signature-form-model';

@Injectable({
  providedIn: 'root',
})
export class PdfProtocolSettingService {
  readonly protocolConfigurationFormDefaultSetting = {
    reportName: '',
    startingText: '',
    endingText: '',
    additionalText: '',
    imagesSize: ShowPicturesEnum.LARGE,
    showComments: true,
    showCosts: true,
    showClientAddress: true,
    showOpenEntriesOnly: false,
    showAttachmentDownloadLink: false,
    showStatusbar: true,
    showPhotosOnComments: true,
    showPlanmarker: true,
    showAllContactsInProject: false,
    placeEntriesOnNewPage: false,
    projectColor: DEFAULT_PROTOCOL_COLOR,
    showAdvancedSettings: false,
    printEntryDetails: [
      PdfPrintEntryDetails.TYPE,
      PdfPrintEntryDetails.PRIORITY,
      PdfPrintEntryDetails.DATES,
      PdfPrintEntryDetails.COMPANY,
      PdfPrintEntryDetails.CRAFT,
      PdfPrintEntryDetails.OBSERVER_COMPANIES,
      PdfPrintEntryDetails.RESPONSIBLE,
      PdfPrintEntryDetails.ADDITIONAL_FIELD,
      PdfPrintEntryDetails.LOCATION,
      PdfPrintEntryDetails.COSTS,
      PdfPrintEntryDetails.UNITS,
    ],
    showStatusAsCheckbox: false,
    highlightEntryTitles: false,
    moreSpaceBetweenEntries: false,
    appendCarriedOverEntries: false,
    hideMainEntry: true,
    printFilteredEntriesOnly: false,
    individualProtocol: false,
    compactContactList: false,
    showParticipantCol: true,
    showLetterhead: false,
    letterheadText: '',
    hideDescription: false,
    everyEntryOnNewPage: false,
    noSplitEntryInfo: true,
    showWeather: false,
    showBimMarker: true,
    useNewLayout: false,
    showHeaderBannerOnEveryPage: false,
    showFooterBannerOnEveryPage: false,
  };
  readonly protocolConfigurationFormDefaultSettingGlobalSearch = {
    ...this.protocolConfigurationFormDefaultSetting,
    reportName: '',
    layout: PdfProtocolLayout.COMPACT,
  };

  constructor(
    private pdfProtocolSettingDataService: PdfProtocolSettingDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private translateService: TranslateService
  ) {}

  fillConfigurationFormGroupFromProtocolSetting(workflowType: WorkflowType, pdfProtocolSetting: PdfProtocolSetting, form: UntypedFormGroup) {
    const defaultSettings = workflowType === WorkflowType.Protocol ? this.protocolConfigurationFormDefaultSetting : this.protocolConfigurationFormDefaultSettingGlobalSearch;

    form.get('startingText').setValue(convertToRichText(pdfProtocolSetting.pdfStartText), {onlySelf: true});
    form.get('endingText').setValue(convertToRichText(pdfProtocolSetting.pdfEndText), {onlySelf: true});
    form.get('additionalText').setValue(pdfProtocolSetting.additionalText, {onlySelf: true});
    form.get('imagesSize').setValue(pdfProtocolSetting.showPictures, {onlySelf: true});
    form.get('showComments').setValue(pdfProtocolSetting.showEntryComments, {onlySelf: true});
    form.get('showCosts').setValue(pdfProtocolSetting.showEntryCost, {onlySelf: true});
    form.get('showClientAddress').setValue(pdfProtocolSetting.clientShowAddress, {onlySelf: true});
    form.get('showOpenEntriesOnly').setValue(pdfProtocolSetting.showOnlyOpenEntries, {onlySelf: true});
    form.get('showAttachmentDownloadLink').setValue(pdfProtocolSetting.showAttachmentDlLink, {onlySelf: true});
    form.get('showStatusbar').setValue(pdfProtocolSetting.showStatusBar, {onlySelf: true});
    form.get('showPhotosOnComments').setValue(pdfProtocolSetting.showEntryCommentPictures, {onlySelf: true});
    form.get('showPlanmarker').setValue(pdfProtocolSetting.showPdfPlanMarker, {onlySelf: true});
    form.get('showAllContactsInProject').setValue(pdfProtocolSetting.showAllContactsInProject, {onlySelf: true});
    form.get('placeEntriesOnNewPage').setValue(pdfProtocolSetting.startNewPageForEntry, {onlySelf: true});
    form.get('projectColor').setValue(pdfProtocolSetting.protocolColor || DEFAULT_PROTOCOL_COLOR, {onlySelf: true});
    form.get('compactContactList').setValue(pdfProtocolSetting.compactContactList, {onlySelf: true});
    form.get('showParticipantCol').setValue(pdfProtocolSetting.showParticipantCol ?? defaultSettings.showParticipantCol, {onlySelf: true});
    form.get('printEntryDetails').setValue(pdfProtocolSetting.printEntryDetails ?? defaultSettings.printEntryDetails, {onlySelf: true});
    form.get('showStatusAsCheckbox').setValue(pdfProtocolSetting.showStatusAsCheckbox ?? defaultSettings.showStatusAsCheckbox, {onlySelf: true});
    form.get('highlightEntryTitles').setValue(pdfProtocolSetting.highlightEntryTitles ?? defaultSettings.highlightEntryTitles, {onlySelf: true});
    form.get('moreSpaceBetweenEntries').setValue(pdfProtocolSetting.moreSpaceBetweenEntries ?? defaultSettings.moreSpaceBetweenEntries, {onlySelf: true});
    form.get('appendCarriedOverEntries').setValue(pdfProtocolSetting.appendCarriedOverEntries ?? defaultSettings.appendCarriedOverEntries, {onlySelf: true});
    form.get('hideMainEntry').setValue(pdfProtocolSetting.hideMainEntry ?? defaultSettings.hideMainEntry, {onlySelf: true});
    form.get('printFilteredEntriesOnly').setValue(pdfProtocolSetting.printFilteredEntriesOnly ?? defaultSettings.printFilteredEntriesOnly, {onlySelf: true});
    form.get('individualProtocol').setValue(pdfProtocolSetting.individualProtocol ?? defaultSettings.individualProtocol, {onlySelf: true});
    form.get('reportName').setValue(pdfProtocolSetting.reportName ?? defaultSettings.reportName, {onlySelf: true});
    form.get('showLetterhead').setValue(pdfProtocolSetting.showLetterhead ?? defaultSettings.showLetterhead, {onlySelf: true});
    form.get('letterheadText').setValue(pdfProtocolSetting.letterheadText ?? defaultSettings.letterheadText, {onlySelf: true});
    form.get('hideDescription').setValue(pdfProtocolSetting.hideDescription ?? defaultSettings.hideDescription, {onlySelf: true});
    form.get('everyEntryOnNewPage').setValue(pdfProtocolSetting.everyEntryOnNewPage ?? defaultSettings.everyEntryOnNewPage, {onlySelf: true});
    form.get('noSplitEntryInfo').setValue(pdfProtocolSetting.noSplitEntryInfo ?? defaultSettings.noSplitEntryInfo, {onlySelf: true});
    form.get('showWeather').setValue(pdfProtocolSetting.showWeather ?? defaultSettings.showWeather, {onlySelf: true});
    form.get('showBimMarker').setValue(pdfProtocolSetting.showBimMarker ?? defaultSettings.showBimMarker, {onlySelf: true});
    form.get('useNewLayout').setValue(pdfProtocolSetting.useNewLayout ?? defaultSettings.useNewLayout, {onlySelf: true});
    form.get('showHeaderBannerOnEveryPage').setValue(pdfProtocolSetting.showHeaderBannerOnEveryPage ?? defaultSettings.showHeaderBannerOnEveryPage, {onlySelf: true});
    form.get('showFooterBannerOnEveryPage').setValue(pdfProtocolSetting.showFooterBannerOnEveryPage ?? defaultSettings.showFooterBannerOnEveryPage, {onlySelf: true});
  }

  getEmptyConfigurationFormGroup(workflowType: WorkflowType) {
    const defaultSettings = workflowType === WorkflowType.Protocol ? this.protocolConfigurationFormDefaultSetting : this.protocolConfigurationFormDefaultSettingGlobalSearch;
    const group = new UntypedFormGroup({
      reportName: new UntypedFormControl(defaultSettings.reportName, workflowType === WorkflowType.GlobalSearch ? Validators.required : null),
      startingText: new UntypedFormControl(defaultSettings.startingText),
      endingText: new UntypedFormControl(defaultSettings.endingText),
      additionalText: new UntypedFormControl(defaultSettings.additionalText),
      imagesSize: new UntypedFormControl(defaultSettings.imagesSize),
      showComments: new UntypedFormControl(defaultSettings.showComments),
      showCosts: new UntypedFormControl(defaultSettings.showCosts),
      showClientAddress: new UntypedFormControl(defaultSettings.showClientAddress),
      showOpenEntriesOnly: new UntypedFormControl(defaultSettings.showOpenEntriesOnly),
      showAttachmentDownloadLink: new UntypedFormControl(defaultSettings.showAttachmentDownloadLink),
      showStatusbar: new UntypedFormControl(defaultSettings.showStatusbar),
      showPhotosOnComments: new UntypedFormControl(defaultSettings.showPhotosOnComments),
      showPlanmarker: new UntypedFormControl(defaultSettings.showPlanmarker),
      showAllContactsInProject: new UntypedFormControl(defaultSettings.showAllContactsInProject),
      placeEntriesOnNewPage: new UntypedFormControl(defaultSettings.placeEntriesOnNewPage),
      projectColor: new UntypedFormControl(defaultSettings.projectColor),
      showAdvancedSettings: new UntypedFormControl(defaultSettings.showAdvancedSettings),
      printEntryDetails: new UntypedFormControl(defaultSettings.printEntryDetails),
      showStatusAsCheckbox: new UntypedFormControl(defaultSettings.showStatusAsCheckbox),
      highlightEntryTitles: new UntypedFormControl(defaultSettings.highlightEntryTitles),
      moreSpaceBetweenEntries: new UntypedFormControl(defaultSettings.moreSpaceBetweenEntries),
      appendCarriedOverEntries: new UntypedFormControl(defaultSettings.appendCarriedOverEntries),
      hideMainEntry: new UntypedFormControl(defaultSettings.hideMainEntry),
      printFilteredEntriesOnly: new UntypedFormControl(defaultSettings.printFilteredEntriesOnly),
      individualProtocol: new UntypedFormControl(defaultSettings.individualProtocol),
      compactContactList: new UntypedFormControl(defaultSettings.compactContactList),
      showParticipantCol: new UntypedFormControl(defaultSettings.showParticipantCol),
      showLetterhead: new UntypedFormControl(defaultSettings.showLetterhead),
      letterheadText: new UntypedFormControl(defaultSettings.letterheadText),
      hideDescription: new UntypedFormControl(defaultSettings.hideDescription),
      everyEntryOnNewPage: new UntypedFormControl(defaultSettings.everyEntryOnNewPage),
      noSplitEntryInfo: new UntypedFormControl(defaultSettings.noSplitEntryInfo),
      showWeather: new UntypedFormControl(defaultSettings.showWeather),
      showBimMarker: new UntypedFormControl(defaultSettings.showBimMarker),
      useNewLayout: new UntypedFormControl(defaultSettings.useNewLayout),
      showHeaderBannerOnEveryPage: new UntypedFormControl(defaultSettings.showHeaderBannerOnEveryPage),
      showFooterBannerOnEveryPage: new UntypedFormControl(defaultSettings.showFooterBannerOnEveryPage),
    });

    return group;
  }

  getEmptyEmailSettingsFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      subject: new UntypedFormControl(''),
      text: new UntypedFormControl(''),
      arrangeNewAppointment: new UntypedFormControl(false),
      individualProtocol: new UntypedFormControl(false),
      addSignature: new UntypedFormControl(false),
      emailSignature: new UntypedFormControl({...EmptyUserEmailSignature}),
      mailTextUnderDownloadLink: new UntypedFormControl(''),
    });
  }

  configurationFormGroupFromSetting(pdfProtocolSetting: PdfProtocolSetting) {
    return new UntypedFormGroup({
      reportName: new UntypedFormControl(pdfProtocolSetting.reportName),
      startingText: new UntypedFormControl(convertToRichText(pdfProtocolSetting.pdfStartText)),
      endingText: new UntypedFormControl(convertToRichText(pdfProtocolSetting.pdfEndText)),
      additionalText: new UntypedFormControl(pdfProtocolSetting.additionalText),
      imagesSize: new UntypedFormControl(pdfProtocolSetting.showPictures),
      showComments: new UntypedFormControl(pdfProtocolSetting.showEntryComments),
      showCosts: new UntypedFormControl(pdfProtocolSetting.showEntryCost),
      showClientAddress: new UntypedFormControl(pdfProtocolSetting.clientShowAddress),
      showOpenEntriesOnly: new UntypedFormControl(pdfProtocolSetting.showOnlyOpenEntries),
      showAttachmentDownloadLink: new UntypedFormControl(pdfProtocolSetting.showAttachmentDlLink),
      showStatusbar: new UntypedFormControl(pdfProtocolSetting.showStatusBar),
      showPhotosOnComments: new UntypedFormControl(pdfProtocolSetting.showEntryCommentPictures),
      showPlanmarker: new UntypedFormControl(pdfProtocolSetting.showPdfPlanMarker),
      showAllContactsInProject: new UntypedFormControl(pdfProtocolSetting.showAllContactsInProject),
      placeEntriesOnNewPage: new UntypedFormControl(pdfProtocolSetting.startNewPageForEntry),
      projectColor: new UntypedFormControl(pdfProtocolSetting.protocolColor),
      showAdvancedSettings: new UntypedFormControl(false),
      printEntryDetails: new UntypedFormControl(pdfProtocolSetting.printEntryDetails),
      showStatusAsCheckbox: new UntypedFormControl(pdfProtocolSetting.showStatusAsCheckbox),
      highlightEntryTitles: new UntypedFormControl(pdfProtocolSetting.highlightEntryTitles),
      moreSpaceBetweenEntries: new UntypedFormControl(pdfProtocolSetting.moreSpaceBetweenEntries),
      appendCarriedOverEntries: new UntypedFormControl(pdfProtocolSetting.appendCarriedOverEntries),
      hideMainEntry: new UntypedFormControl(pdfProtocolSetting.hideMainEntry),
      printFilteredEntriesOnly: new UntypedFormControl(pdfProtocolSetting.printFilteredEntriesOnly),
      compactContactList: new UntypedFormControl(pdfProtocolSetting.compactContactList),
      showParticipantCol: new UntypedFormControl(pdfProtocolSetting.showParticipantCol),
      showLetterhead: new UntypedFormControl(pdfProtocolSetting.showLetterhead),
      letterheadText: new UntypedFormControl(pdfProtocolSetting.letterheadText),
      hideDescription: new UntypedFormControl(pdfProtocolSetting.hideDescription),
      everyEntryOnNewPage: new UntypedFormControl(pdfProtocolSetting.everyEntryOnNewPage),
      noSplitEntryInfo: new UntypedFormControl(pdfProtocolSetting.noSplitEntryInfo),
      showWeather: new UntypedFormControl(pdfProtocolSetting.showWeather),
      showBimMarker: new UntypedFormControl(pdfProtocolSetting.showBimMarker),
      useNewLayout: new UntypedFormControl(pdfProtocolSetting.useNewLayout),
      showHeaderBannerOnEveryPage: new UntypedFormControl(pdfProtocolSetting.showHeaderBannerOnEveryPage),
      showFooterBannerOnEveryPage: new UntypedFormControl(pdfProtocolSetting.showFooterBannerOnEveryPage),
    });
  }

  emailSettingsFormGroupFromSetting(pdfProtocolSetting: PdfProtocolSetting) {
    return new UntypedFormGroup({
      subject: new UntypedFormControl(pdfProtocolSetting.mailSubject),
      text: new UntypedFormControl(isRichText(pdfProtocolSetting.mailText) ? convertRichTextToPlainText(pdfProtocolSetting.mailText) : pdfProtocolSetting.mailText),
      arrangeNewAppointment: new UntypedFormControl(false),
      individualProtocol: new UntypedFormControl(pdfProtocolSetting.individualProtocol ?? false),
      addSignature: new UntypedFormControl(pdfProtocolSetting.addSignature ?? false),
      emailSignature: new UntypedFormControl({...EmptyUserEmailSignature}),
      mailTextUnderDownloadLink: new UntypedFormControl(pdfProtocolSetting.mailTextUnderDownloadLink),
    });
  }

  formGroupsToPdfProtocolSetting(configurationForm: UntypedFormGroup, emailSettingsForm: UntypedFormGroup, pdfProtocolSetting?: PdfProtocolSetting): PdfProtocolSetting {
    if (!pdfProtocolSetting) {
      pdfProtocolSetting = {
        id: null,
        hideTransferredEntries: false,
        isSearchPdf: false,
      } as PdfProtocolSetting; // Ignore that not all fields are populated; they will be updated later
    }
    const protocolSettings = configurationForm.getRawValue();
    const emailSettings = emailSettingsForm.getRawValue();

    pdfProtocolSetting.layoutType = PdfProtocolLayout.COMPACT;
    pdfProtocolSetting.pdfStartText = protocolSettings.startingText;
    pdfProtocolSetting.pdfEndText = protocolSettings.endingText;
    pdfProtocolSetting.mailText = emailSettings.text;
    pdfProtocolSetting.mailSubject = emailSettings.subject;
    pdfProtocolSetting.individualProtocol = emailSettings.individualProtocol;
    pdfProtocolSetting.addSignature = emailSettings.addSignature;
    pdfProtocolSetting.mailTextUnderDownloadLink = emailSettings.mailTextUnderDownloadLink;
    pdfProtocolSetting.additionalText = protocolSettings.additionalText;
    pdfProtocolSetting.clientShowAddress = protocolSettings.showClientAddress;
    pdfProtocolSetting.protocolColor = protocolSettings.projectColor;
    pdfProtocolSetting.showAllContactsInProject = protocolSettings.showAllContactsInProject;
    pdfProtocolSetting.showEntryComments = protocolSettings.showComments;
    pdfProtocolSetting.showEntryCost = protocolSettings.showCosts;
    pdfProtocolSetting.showPdfPlanMarker = protocolSettings.showPlanmarker;
    pdfProtocolSetting.showPictures = protocolSettings.imagesSize;
    pdfProtocolSetting.showStatusBar = protocolSettings.showStatusbar;
    pdfProtocolSetting.startNewPageForEntry = protocolSettings.placeEntriesOnNewPage;
    pdfProtocolSetting.showAttachmentDlLink = protocolSettings.showAttachmentDownloadLink;
    pdfProtocolSetting.showEntryCommentPictures = protocolSettings.showPhotosOnComments;
    pdfProtocolSetting.showOnlyOpenEntries = protocolSettings.showOpenEntriesOnly;
    pdfProtocolSetting.printEntryDetails = protocolSettings.printEntryDetails;
    pdfProtocolSetting.showStatusAsCheckbox = protocolSettings.showStatusAsCheckbox;
    pdfProtocolSetting.highlightEntryTitles = protocolSettings.highlightEntryTitles;
    pdfProtocolSetting.moreSpaceBetweenEntries = protocolSettings.moreSpaceBetweenEntries;
    pdfProtocolSetting.appendCarriedOverEntries = protocolSettings.appendCarriedOverEntries;
    pdfProtocolSetting.hideMainEntry = protocolSettings.hideMainEntry;
    pdfProtocolSetting.printFilteredEntriesOnly = protocolSettings.printFilteredEntriesOnly;
    pdfProtocolSetting.compactContactList = protocolSettings.compactContactList;
    pdfProtocolSetting.reportName = protocolSettings.reportName;
    pdfProtocolSetting.showParticipantCol = protocolSettings.showParticipantCol;
    pdfProtocolSetting.showLetterhead = protocolSettings.showLetterhead;
    pdfProtocolSetting.letterheadText = protocolSettings.letterheadText;
    pdfProtocolSetting.hideDescription = protocolSettings.hideDescription;
    pdfProtocolSetting.everyEntryOnNewPage = protocolSettings.everyEntryOnNewPage;
    pdfProtocolSetting.noSplitEntryInfo = protocolSettings.noSplitEntryInfo;
    pdfProtocolSetting.showWeather = protocolSettings.showWeather;
    pdfProtocolSetting.showBimMarker = protocolSettings.showBimMarker;
    pdfProtocolSetting.useNewLayout = protocolSettings.useNewLayout;
    pdfProtocolSetting.showHeaderBannerOnEveryPage = protocolSettings.showHeaderBannerOnEveryPage;
    pdfProtocolSetting.showFooterBannerOnEveryPage = protocolSettings.showFooterBannerOnEveryPage;

    return pdfProtocolSetting;
  }

  async saveForAllProtocolTypes(pdfProtocolSetting: PdfProtocolSetting, projectId: IdType) {
    const protocolTypes = await observableToPromise(this.protocolTypeDataService.dataWithoutHidden$.pipe(take(1)));

    const pdfProtocolSettings = await Promise.all(
      protocolTypes.map(async (protocolType) => {
        const existingPdfProtocolSetting = await observableToPromise(this.pdfProtocolSettingDataService.getByProtocolTypeId(protocolType.id).pipe(take(1)));

        return {
          ...pdfProtocolSetting,
          protocolTypeId: protocolType.id,
          projectId,
          id: existingPdfProtocolSetting?.id || null,
          changedAt: existingPdfProtocolSetting?.changedAt || new Date().toISOString(),
        };
      })
    );

    return this.pdfProtocolSettingDataService.insertOrUpdate(pdfProtocolSettings, projectId);
  }
}
