import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProtocolTypeDataService} from '../../../services/data/protocol-type-data.service';
import {Protocol, ProtocolType} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-protocol-short-name-number',
  templateUrl: './protocol-short-name-number.component.html',
  styleUrls: ['./protocol-short-name-number.component.scss'],
})
export class ProtocolShortNameNumberComponent implements OnInit, OnDestroy {
  @Input() protocol: Protocol;
  public protocolTypesById: Record<string, ProtocolType> | null | undefined;
  private subscription: Subscription;

  constructor(private protocolTypeDataService: ProtocolTypeDataService) {}

  ngOnInit(): void {
    this.subscription = this.protocolTypeDataService.dataWithoutHiddenAcrossClientsGroupedById$.subscribe((protocolTypesById) => (this.protocolTypesById = protocolTypesById));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
