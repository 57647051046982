import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, Observable} from 'rxjs';
import {Nullish} from 'src/app/model/nullish';
import {Translatable} from 'src/app/model/translatable';

@Injectable({
  providedIn: 'root',
})
export class WithParamsTranslateService {
  constructor(private translateService: TranslateService) {}

  translate(str: Nullish<Translatable>): string | undefined {
    if (str === undefined || str === null) {
      return undefined;
    }
    if (typeof str === 'string') {
      return this.translateService.instant(str);
    }
    if ('key' in str) {
      return this.translateService.instant(str.key, str.params);
    }

    return str.doNotTranslate;
  }

  translateInLanguage(str: Nullish<Translatable>, language: string): Observable<string> {
    if (str === undefined || str === null) {
      return undefined;
    }
    if (typeof str === 'object' && 'key' in str) {
      return this.translateService.getTranslation(language).pipe(
        map((translations) => {
          const translatedText = this.translateService.getParsedResult(translations, str.key, str.params);
          return translatedText || str.key;
        })
      );
    }
  }
}
