import {ColumnConfig} from '../ColumnConfig';
import {AttachmentReportCompanyColumnSet} from './AttachmentReportCompanyColumnSet';
import {ReportCompanyActivityColumnSet} from './ReportCompanyActivityColumnSet';

export const ReportCompanyColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'report_id', prop: 'reportId'},
  {name: 'company_id', prop: 'companyId'},
  {name: 'number_of_persons', prop: 'numberOfPersons', def: null},
  {name: 'working_hours', prop: 'workingHours', def: null},
  {name: 'reportCompanyActivities', fk: 'reportCompanyId', mappedBy: ReportCompanyActivityColumnSet, transient: true},
  {name: 'attachmentReportCompanies', fk: 'reportCompanyId', mappedBy: AttachmentReportCompanyColumnSet, transient: true},
];
