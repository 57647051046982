import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {race, Subscription, timer} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {convertErrorToMessage, NetworkError} from 'src/app/shared/errors';
import {LoggingService} from '../common/logging.service';
import {NetworkStatusService} from '../common/network-status.service';
import {SystemEventService} from '../event/system-event.service';
import {AuthenticationService} from './authentication.service';
import {TokenManagerService} from './token-manager.service';

// Refresh token when it will expire in 5 minutes
const REFRESH_TOKEN_BEFORE_IN_MS = 1000 * 60 * 5;

const BACKOFF_BASE_IN_MS = 1000 * 2;
const BACKOFF_BASE_WHEN_OFFLINE_IN_MS = 1000 * 10;

const BACKOFF_MAX_IN_MS = 1000 * 60;
const BACKOFF_MAX_WHEN_OFFLINE_IN_MS = 1000 * 60 * 5;

const LOG_SOURCE = 'TokenRefresherService';

@Injectable({
  providedIn: 'root',
})
export class TokenRefresherService implements OnDestroy {
  private refresherSubscription: Subscription | undefined;
  private retryCounter = 0;

  constructor(
    private tokenManagerService: TokenManagerService,
    private authService: AuthenticationService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private networkStatusService: NetworkStatusService
  ) {
    this.startRefresher();
  }

  startRefresher() {
    this.refresherSubscription?.unsubscribe();

    this.refresherSubscription = this.authService.data
      .pipe(
        filter((auth) => !!auth),
        switchMap((auth) => {
          if ('refreshToken' in auth) {
            const refreshAt = new Date(auth.tokenExpiresAt - REFRESH_TOKEN_BEFORE_IN_MS);
            this.loggingService.debug(LOG_SOURCE, `startRefresher - Will attempt to refresh token at ${refreshAt.toISOString()}`);
            return timer(refreshAt);
          } else {
            this.loggingService.warn(LOG_SOURCE, 'startRefresher - legacy token; will attempt to refresh token now.');
            this.systemEventService.logEvent(LOG_SOURCE, 'startRefresher - legacy token; will attempt to refresh token now.');
            return timer(0); // Legacy token detected; schedule a new token retrieval, so we can replace it with a jwt token
          }
        }),
        switchMap(() => this.tokenManagerService.getNewToken$())
      )
      .subscribe({
        next: () => {
          this.retryCounter = 0;
        },
        error: async (error) => {
          this.retryCounter++;

          let networkStatus: Awaited<ReturnType<typeof this.networkStatusService.getNetworkStatus>> = undefined;
          try {
            networkStatus = await this.networkStatusService.getNetworkStatus();
          } catch (e) {
            const networkStatusMessage = `Failed to get network status; using undefined... (${convertErrorToMessage(e)})`;
            this.loggingService.warn(LOG_SOURCE, networkStatusMessage);
            this.systemEventService.logErrorEvent(LOG_SOURCE, networkStatusMessage);
          }

          const isNetworkAvailable = !networkStatus || networkStatus.connected;
          const networkRelatedError = isNetworkRelatedError(error);
          const isOfflineish = !isNetworkAvailable || networkRelatedError;

          const restartAfter = this.getBackoffDelay(isOfflineish);

          const message = `startRefresher error; restarting refresher after ${restartAfter} ms${isOfflineish ? ', or when server will be reachable' : ''} - ${convertErrorToMessage(
            error
          )} (isNetworkAvailable:${isNetworkAvailable},networkRelatedError:${networkRelatedError})`;
          this.loggingService.error(LOG_SOURCE, message);
          this.systemEventService.logErrorEvent(LOG_SOURCE, message);

          race(timer(restartAfter), this.networkStatusService.networkConnectedObservable.pipe(filter((connected) => !isNetworkAvailable && connected)))
            .pipe(take(1))
            .subscribe(() => {
              this.stopRefresher();
              this.startRefresher();
            });
        },
      });
  }

  private getBackoffDelay(isOfflineish: boolean) {
    const counter = Math.min(10, this.retryCounter);
    const base = isOfflineish ? BACKOFF_BASE_WHEN_OFFLINE_IN_MS : BACKOFF_BASE_IN_MS;
    const cap = isOfflineish ? BACKOFF_MAX_WHEN_OFFLINE_IN_MS : BACKOFF_MAX_IN_MS;
    const calculatedBackoff = Math.round(Math.max(0, base * Math.pow(2, counter) + (Math.random() - 0.5) * base));
    return Math.min(calculatedBackoff, cap);
  }

  stopRefresher() {
    this.refresherSubscription?.unsubscribe();
    this.refresherSubscription = undefined;
  }

  ngOnDestroy() {
    this.stopRefresher();
  }
}

function isNetworkRelatedError(error: any) {
  if (error instanceof NetworkError) {
    return true;
  }

  if (error instanceof HttpErrorResponse && (error.status === 504 || error.status === 503 || error.status === 502)) {
    return true;
  }

  if (error instanceof Error) {
    const errorName = error.name.toLowerCase();
    if (errorName.includes('network')) {
      return true;
    }
    if (errorName.includes('timeout')) {
      return true;
    }
  }

  return false;
}
