import {Directive} from '@angular/core';
import {LicenseType} from 'submodules/baumaster-v2-common';

@Directive({
  selector: '[appLicenseType]',
  exportAs: 'appLicenseType',
})
export class LicenseTypeDirective {
  readonly LicenseType = LicenseType;

  constructor() {}
}
