import {AbstractPdfContent, formatDate, MaybeMinimizeProtocolEntry, ProtocolEntryWithSubEntries} from './abstractPdf.content';
import _ from 'lodash';
import {PdfProtocolSendReq} from '../../../requestResponse';
import {
  AttachmentProtocolEntry,
  IdType,
  PdfPreview,
  ProjectCurrencyEnum,
  PROTOCOL_LAYOUT_NAME_CONTINUOUS,
  PROTOCOL_LAYOUT_NAME_SHORT,
  ProtocolEntry,
  ProtocolEntryChat,
  ProtocolEntryIconStatus,
  ProtocolEntryPriorityLevel,
  ProtocolEntryPriorityType,
  ShowPicturesEnum,
  BimMarker,
  AttachmentBimMarkerScreenshot,
  UnitForBreadcrumbs,
} from '../../../models';
import {AttachmentWithContent, PdfPlanAttachmentWithContent, PdfProtocolGenerateData} from '../pdfProtocol.model';
import {Column, ColumnProperties, Content, ContentColumns, ContentImage, ContentStack, ContentSvg, Table, TableCell, TableLayout, TableOfContent} from 'pdfmake/interfaces';
import {SvgIcons} from '../pdfSvgIcons';
import {getProtocolEntryIconStatus, getProtocolEntryStatus} from '../../../planMarker/planMarkerCanvasUtils';
import {createCanvas} from 'canvas';
import {PdfPrintEntryDetails} from '../pdfProtocolEnums';
import {convertToRichText, isRichText, PdfHelperFunctions} from '../../common-report-utils';
import {addZeroWidthSpaces} from '../pdfutils';
import {PDF_NODE_ID_DESC_SEARCH_STRING, PDF_NODE_ID_HEADER_SEARCH_STRING} from '../../../constants';
import {isUnitFeatureEnabledForClient} from '../../../unitUtils';

const TABLE_WIDTH = 507;
const LINK_BLUE = '#2691C8';

export abstract class AbstractProtocolEntriesContent extends AbstractPdfContent {
  protected isGroupBy = false;

  protected tableLayout: TableLayout = {
    hLineWidth(i, node) {
      return 0.5;
    },
    vLineWidth(i, node) {
      return 0.5;
    },
    hLineColor(i, node) {
      return '#B3B3B3';
    },
    vLineColor(i, node) {
      return '#B3B3B3';
    },
  };

  protected constructor(
    language: string,
    config: PdfProtocolSendReq,
    data: PdfProtocolGenerateData,
    protected pdfHelperFunctions: PdfHelperFunctions,
    pdfPreview?: PdfPreview
  ) {
    super(language, config, data, pdfPreview);

    this.isGroupBy = this.isGroupByCriteriaMet(this.data.protocol.sortEntriesBy);
  }

  protected getProtocolEntries(): MaybeMinimizeProtocolEntry[] {
    if (this.data.filteredProtocolEntries !== undefined && this.data.filteredProtocolEntries?.length > 0) {
      // We only want to use a subset of the protocol entries
      return this.getFilteredProtocolEntries(this.data.filteredProtocolEntries);
    }
    return this.data.protocolEntries;
  }

  protected getOwnProtocolEntries(): MaybeMinimizeProtocolEntry[] {
    const protocolEntries = this.getProtocolEntries();
    const carriedSubEntries = protocolEntries.filter((entry) => entry.createdInProtocolId === this.data.protocol.id);
    const carriedSubEntryParentIds = new Set(carriedSubEntries.map(({parentId}) => parentId).filter((v): v is IdType => Boolean(v)));

    return this.getProtocolEntries().filter((entry) => carriedSubEntryParentIds.has(entry.id) || !this.data.protocolOpenEntries.some((openEntry) => openEntry.protocolEntryId === entry.id));
  }

  protected getCarriedOverProtocolEntriesNotGroup(): MaybeMinimizeProtocolEntry[] {
    const protocolEntries = this.getProtocolEntries();
    const carriedSubEntries = protocolEntries.filter((entry) => entry.createdInProtocolId === this.data.protocol.id);
    const carriedSubEntryParentIds = new Set(carriedSubEntries.map(({parentId}) => parentId).filter((v): v is IdType => Boolean(v)));

    return this.getProtocolEntries().filter((entry) => !carriedSubEntryParentIds.has(entry.id) && this.data.protocolOpenEntries.some((openEntry) => openEntry.protocolEntryId === entry.id));
  }

  protected getCarriedOverProtocolEntriesGroupBy(): MaybeMinimizeProtocolEntry[] {
    return this.getProtocolEntries().filter((entry) => this.data.protocolOpenEntries.some((openEntry) => openEntry.protocolEntryId === entry.id));
  }

  private getFilteredProtocolEntries(filteredProtocolEntries: ProtocolEntry[]): MaybeMinimizeProtocolEntry[] {
    const newProtocolEntries: MaybeMinimizeProtocolEntry[] = [];
    for (const filteredProtocolEntry of filteredProtocolEntries) {
      if (!_.isEmpty(filteredProtocolEntry.parentId)) {
        const parentEntry = this.data.protocolEntries.find((entry) => entry.id === filteredProtocolEntry.parentId);
        if (parentEntry && !newProtocolEntries.some((newEntry) => newEntry.id === parentEntry.id)) {
          newProtocolEntries.push({
            ...parentEntry,
            minimize: !filteredProtocolEntries.some((entry) => entry.id === parentEntry.id),
          });
        }
      }

      if (!newProtocolEntries.some((newEntry) => newEntry.id === filteredProtocolEntry.id)) {
        newProtocolEntries.push(filteredProtocolEntry);
      }
    }
    return newProtocolEntries;
  }

  protected writeEndText(content: Content[]) {
    if (!_.isEmpty(this.config.pdfProtocolSetting?.pdfEndText)) {
      this.writeEndTextHeader(content);
      const pdfEndText = this.config.pdfProtocolSetting?.pdfEndText;
      if (isRichText(pdfEndText)) {
        content.push(this.pdfHelperFunctions.convertHtmlToPdfmake(convertToRichText(pdfEndText), {fontSize: 9}));
      } else {
        content.push({
          text: `${pdfEndText}`,
          style: ['font9', 'marginTop10'],
        });
      }
    }
  }

  protected writeTextHeader(content: Content[], label: string, icon?: SvgIcons) {
    const columns: Column[] = [];
    if (icon) {
      columns.push({
        columns: [
          {
            svg: this.changeSvgIconFill(icon, this.getProtocolColor()),
            fit: [12, 12],
          },
        ],
        width: 'auto',
      });
    }
    columns.push({
      text: label,
      width: 'auto',
      style: ['font10', 'protocolFontColor', ...(icon ? ['marginLeft10'] : [])],
    });
    content.push({
      style: ['marginTop10'],
      columns,
    });
    this.writeLine(content);
  }

  protected writeEndTextHeader(content: Content[]) {
    this.writeTextHeader(content, `${this.i18n?.get('end_text')}`, SvgIcons.paper);
  }

  protected writeGroupByProtocolEntries(content: Content[], protocolEntries: MaybeMinimizeProtocolEntry[], isTask: boolean, isOwnEntriesAndAppendCarried = false) {
    const groupProtocolEntries = this.groupProtocolEntries(protocolEntries, isOwnEntriesAndAppendCarried);
    for (const groupProtocolEntry of groupProtocolEntries) {
      const currentGroupName = groupProtocolEntry.groupName.replace(/.+__/, '');
      if (this.isForSpecificParticipantCompany(currentGroupName)) {
        continue;
      }
      content.push({
        text: currentGroupName,
        style: ['font12', 'groupName', 'textBold'],
      });
      const protocolNumberComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => this.getProtocolNumber(protocolEntry);
      const protocolEntryNumberComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => protocolEntry.number;
      const sortedProtocolEntries = _.orderBy(groupProtocolEntry.protocolEntries, [protocolNumberComparer, protocolEntryNumberComparer], ['asc', 'asc']);
      let count = sortedProtocolEntries.length;
      for (const protocolEntry of sortedProtocolEntries) {
        this.writeProtocolEntry(content, protocolEntry, isTask);
        if (this.config.pdfProtocolSetting?.everyEntryOnNewPage && count > 1) {
          content.push({
            text: '',
            pageBreak: 'after',
          });
        }
        count--;
      }
    }
  }

  protected isMinimizeDetails(protocolEntry: ProtocolEntry): boolean {
    if (_.isEmpty(protocolEntry.parentId)) {
      const protocolEntryWithSubEntries = protocolEntry as ProtocolEntryWithSubEntries;
      return protocolEntryWithSubEntries.minimize;
    }
    return false;
  }

  protected writeProtocolEntry(content: Content[], protocolEntry: ProtocolEntry, isTask: boolean) {
    const entryInfoTableCell: TableCell[][] = [];
    const isHightLight = this.config.pdfProtocolSetting?.highlightEntryTitles;
    const isMinimizeMainEntry = this.isMinimizeDetails(protocolEntry);

    content.push({
      text: ' ',
      id: _.uniqueId(PDF_NODE_ID_HEADER_SEARCH_STRING),
    });

    entryInfoTableCell.push([
      {
        fillColor: this.config.pdfProtocolSetting?.highlightEntryTitles ? '#F4F4F4' : '#FFFFFF',
        columns: this.getProtocolEntryHeader(protocolEntry, isTask),
        border: [!!isHightLight, !!isHightLight, !!isHightLight, true],
        style: ['marginTop3Bottom3'],
      },
    ]);

    const protocolEntryInformation = this.getProtocolEntryInformation(protocolEntry);
    entryInfoTableCell.push([
      {
        columns: protocolEntryInformation.columns,
        border: [true, false, false, false],
      },
    ]);

    const tableConfig: Table = {
      headerRows: 2,
      dontBreakRows: true,
      widths: [this.getTableWidth(protocolEntry)],
      body: entryInfoTableCell,
    };

    content.push({
      style: [_.isEmpty(protocolEntry.parentId) ? 'marginTop10' : ''],
      table: tableConfig,
      layout: this.tableLayout,
    });

    if (protocolEntry.text?.length || (protocolEntryInformation.protocolEntryDetailRows && protocolEntryInformation.protocolEntryDetailRows > 1)) {
      const descriptionContent = this.pdfHelperFunctions.convertHtmlToPdfmake(convertToRichText(protocolEntry.text), {fontSize: 9});
      const descriptionColumns: Column[] = [];
      descriptionColumns.push({
        text: '',
        width: 60,
        style: [],
      });

      if (isMinimizeMainEntry) {
        descriptionColumns.push({
          text: '',
          style: ['font9'],
        });
      } else {
        if (!this.config.pdfProtocolSetting?.hideDescription) {
          descriptionColumns.push(addZeroWidthSpaces(descriptionContent, this.lng));
        } else {
          descriptionColumns.push({
            text: '',
          });
        }
      }

      if (!isMinimizeMainEntry) {
        const protocolEntryDetails = this.getProtocolEntryDetails(protocolEntry, {showEntriesAfter: 0});
        descriptionColumns.push({
          width: 180,
          columns: [protocolEntryDetails.columns],
          style: ['marginLeft10'],
        });
      }

      content.push({
        table: {
          widths: [this.getTableWidth(protocolEntry)],
          body: [
            [
              {
                columns: descriptionColumns,
                border: [true, false, false, false],
                id: _.uniqueId(PDF_NODE_ID_DESC_SEARCH_STRING),
              },
            ],
          ],
        },
        layout: {
          hLineWidth(i, node) {
            return 0.5;
          },
          vLineWidth(i, node) {
            return 0.5;
          },
          hLineColor(i, node) {
            return '#B3B3B3';
          },
          vLineColor(i, node) {
            return '#B3B3B3';
          },
          paddingTop(i, node) {
            return 0;
          },
          paddingBottom(i, node) {
            return 0;
          },
        },
      });
    }

    this.writeProtocolEntryTableDetails(content, protocolEntry, isTask);
  }

  private writeProtocolEntryTableDetails(content: Content[], protocolEntry: ProtocolEntry, isTask: boolean) {
    const isMinimizeMainEntry = this.isMinimizeDetails(protocolEntry);
    let hasOtherInfo = false;

    const tableCell: TableCell[][] = [];
    if (!isMinimizeMainEntry) {
      const pdfPlanPage = this.getPdfPlanPage(protocolEntry.id);
      if (pdfPlanPage !== undefined) {
        hasOtherInfo = true;
        tableCell.push([
          {
            columns: [this.getPlanMarker(protocolEntry)],
            style: ['marginLeft10'],
            border: [true, false, false, false],
          },
        ]);
      }

      const bimMarkers = this.getBimMarkers(protocolEntry.id);
      if (bimMarkers.length > 0) {
        hasOtherInfo = true;
        tableCell.push([
          {
            columns: [this.getBimMarker(protocolEntry, bimMarkers)],
            style: ['marginLeft10'],
            border: [true, false, false, false],
          },
        ]);
      }

      const protocolEntryPhotos = this.getProtocolEntryAttachmentsData(protocolEntry.id);
      if (protocolEntryPhotos !== undefined) {
        hasOtherInfo = true;
        tableCell.push([
          {
            columns: [this.getProtocolEntryAttachments(protocolEntry)],
            style: ['marginLeft10'],
            border: [true, false, false, false],
          },
        ]);
      }

      const comments = this.getProtocolEntryComments(protocolEntry.id);
      if (comments !== undefined) {
        hasOtherInfo = true;
        tableCell.push([
          {
            columns: [this.getComments(protocolEntry)],
            style: ['marginLeft10'],
            border: [true, false, false, false],
          },
        ]);
      }
    }

    const sortedSubProtocolEntries = this.getProtocolEntrySubEntries(protocolEntry);
    if (sortedSubProtocolEntries.length > 0) {
      hasOtherInfo = true;
      tableCell.push([
        {
          columns: [this.getSubEntries(protocolEntry, isTask)],
          border: [true, false, false, false],
        },
      ]);
    }

    tableCell.push([
      {
        columns: [],
        style: [hasOtherInfo ? 'marginTop5' : ''],
        border: [true, false, false, true],
      },
    ]);

    content.push({
      table: {
        widths: [this.getTableWidth(protocolEntry)],
        body: tableCell,
      },
      layout: this.tableLayout,
    });
  }

  getBimMarker(protocolEntry: ProtocolEntry, bimMarkers: BimMarker[]): Column[] {
    const columns: Column[] = [];
    const bimVersion = this.data.bimVersions?.find((bim) => bimMarkers.some((marker) => marker.bimVersionId === bim.id));
    const bimVersionInfo = bimVersion ? (bimVersion.name ? `${bimVersion.name} | ` : '') + formatDate(bimVersion.createdAt) : '';
    columns.push({
      style: ['marginTop10'],
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.marker, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          // todo: translations
          text: `${this.i18n?.get('bimMarker')}`,
          width: 'auto',
          style: ['font10', 'protocolFontColor', 'marginLeft10'],
        },
        {
          text: bimVersion ? `${bimVersionInfo}` : '',
          width: 'auto',
          // todo: bim public link(?)
          link: '',
          // todo: bim public link color
          style: ['font8', 'fontColorGray', 'marginLeft5Top2'],
        },
        {
          // todo: download info(?)
          text: '',
          width: 'auto',
          style: ['font8', 'fontColorGray', 'marginLeft5Top2'],
        },
      ],
    });

    this.writeLine(columns, this.getLineWidth(protocolEntry));

    type RequiredBase64Screenshot = AttachmentWithContent<AttachmentBimMarkerScreenshot> & Required<Pick<AttachmentWithContent<AttachmentBimMarkerScreenshot>, 'contentBase64'>>;

    const markers = bimMarkers
      .map((marker) => (marker.id ? this.data.attachmentBimMarkerScreenshots?.get(marker.id) : undefined))
      .filter((marker: AttachmentWithContent<AttachmentBimMarkerScreenshot> | undefined): marker is RequiredBase64Screenshot => !!marker?.contentBase64);

    for (const chunk of _.chunk(markers, 2)) {
      columns.push({
        columns: chunk.map((marker) => ({
          image: this.sanitizeBase64Image(marker.contentBase64),
          fit: [161, 124],
          style: ['alignCenter', 'marginTop10'],
          // todo: bim marker public link(?)
          link: '',
        })),
      });
    }

    return columns;
  }

  protected getTableWidth(protocolEntry: ProtocolEntry): number {
    return _.isEmpty(protocolEntry.parentId) ? TABLE_WIDTH : TABLE_WIDTH - 13;
  }

  protected getProtocolEntryInformation(protocolEntry: ProtocolEntry): {columns: Column[]; protocolEntryDetailRows: number | undefined} {
    const columns: Column[] = [];
    const isMinimizeMainEntry = this.isMinimizeDetails(protocolEntry);

    columns.push({
      columns: [
        [
          {
            text: `${this.getDateValueNotNull(protocolEntry.createdAt)}`,
            style: ['font9'],
          },
        ],
      ],
      width: 60,
      style: [],
    });

    columns.push({
      columns: [
        [
          {
            text: `${this.sanitizeValue(protocolEntry.title)}`,
            style: ['textBold', 'font9'],
          },
        ],
      ],
      width: '*',
      style: ['font9'],
    });

    let protocolEntryDetailRows: number | undefined;
    if (!isMinimizeMainEntry) {
      const protocolEntryDetails = this.getProtocolEntryDetails(protocolEntry, {showEntriesUpTo: 0});
      protocolEntryDetailRows = protocolEntryDetails.protocolEntryDetailRows;
      columns.push({
        width: 180,
        columns: [protocolEntryDetails.columns],
        style: ['marginLeft10'],
      });
    }

    return {columns, protocolEntryDetailRows};
  }

  protected getProtocolEntryDetails(protocolEntry: ProtocolEntry, options?: {showEntriesUpTo?: number; showEntriesAfter?: number}): {columns: Column[]; protocolEntryDetailRows: number} {
    const columns: Column[] = [];
    const labelWidth = 50;
    const lineWidth = 175;
    const showEntryDetails: PdfPrintEntryDetails[] = this.config.pdfProtocolSetting?.printEntryDetails || [];
    let rowNumber = -1;

    const showByOptions = (): boolean => {
      return (options?.showEntriesUpTo === undefined || rowNumber <= options.showEntriesUpTo) && (options?.showEntriesAfter === undefined || rowNumber > options.showEntriesAfter);
    };

    const isFirstRow = (): boolean => {
      return columns.length === 0 && options?.showEntriesAfter === undefined;
    };

    const showLineSeparator = (): boolean => {
      return columns.length > 0 || options?.showEntriesAfter !== undefined;
    };

    const protocolEntryCompany = this.getProtocolEntryCompany(protocolEntry);
    if (!_.isEmpty(protocolEntryCompany) && _.includes(showEntryDetails, PdfPrintEntryDetails.COMPANY)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('company')}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${this.replaceSpecialCharToSpace(protocolEntryCompany)}`,
              width: '*',
              style: ['font9', 'textBold', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    const companyProfile = this.data.lookup.profiles.get(protocolEntry?.internalAssignmentId + '');
    const companyAddresses = this.data.lookup.addresses.get(companyProfile?.addressId + '');
    if (!_.isEmpty(companyAddresses?.firstName) && !_.isEmpty(companyAddresses?.lastName) && _.includes(showEntryDetails, PdfPrintEntryDetails.RESPONSIBLE)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('responsible')}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${companyAddresses?.firstName} ${companyAddresses?.lastName}`,
              width: '*',
              style: ['font9', 'textBold', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    const protocolEntryCompanies = this.groupedObserverCompanies[protocolEntry.id] ?? [];
    if (!_.isEmpty(protocolEntryCompanies) && _.includes(showEntryDetails, PdfPrintEntryDetails.OBSERVER_COMPANIES)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('observerCompanies')}`,
              style: ['font7', 'marginTop5'],
            },
            {
              text: `${protocolEntryCompanies
                .map(({companyId}) => this.data.lookup.companies.get(companyId)?.name)
                .filter(Boolean)
                .join(', ')}`,
              width: '*',
              style: ['font9', 'textBold', 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    const protocolEntryType = this.sanitizeValue(this.data.lookup.protocolEntryTypes.get(protocolEntry?.typeId + '')?.name);
    if (!_.isEmpty(protocolEntryType) && _.includes(showEntryDetails, PdfPrintEntryDetails.TYPE)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('type')}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${this.replaceSpecialCharToSpace(protocolEntryType)}`,
              width: '*',
              style: ['font9', 'textBold', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    const craft = this.sanitizeValue(this.data.lookup.crafts.get(protocolEntry?.craftId + '')?.name);
    if (!_.isEmpty(craft) && _.includes(showEntryDetails, PdfPrintEntryDetails.CRAFT)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('craft')}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${this.replaceSpecialCharToSpace(craft)}`,
              width: '*',
              style: ['font9', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    const location = this.sanitizeValue(this.data.lookup.protocolEntryLocations.get(protocolEntry?.locationId + '')?.location);
    if (!_.isEmpty(location) && _.includes(showEntryDetails, PdfPrintEntryDetails.LOCATION)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('location')}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${this.replaceSpecialCharToSpace(location)}`,
              width: '*',
              style: ['font9', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }
    const nameableItem = this.data.lookup.nameableDropdownItems.get(`${this.data.project.id}${protocolEntry?.nameableDropdownId}`);
    const nameableDropdown = this.data.lookup.nameableDropdowns.get(nameableItem?.nameabledropdownId + '')?.name;
    const additionalField = this.sanitizeValue(nameableDropdown);
    if (!_.isEmpty(additionalField) && _.includes(showEntryDetails, PdfPrintEntryDetails.ADDITIONAL_FIELD)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.replaceSpecialCharToSpace(this.data.client.nameableDropdownName)}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${this.replaceSpecialCharToSpace(additionalField)}`,
              width: '*',
              style: ['font9', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    const cost = protocolEntry?.cost;
    const costParsed = parseFloat(`${cost}`);
    if (cost !== undefined && cost !== null && costParsed !== 0 && _.includes(showEntryDetails, PdfPrintEntryDetails.COSTS)) {
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n
                ?.get(this.data.protocol.includesVat ? 'costWithTax' : 'costWithoutTax')
                ?.replace(/{{\s*tax_rate\s*}}/g, this.getFormattedNumber(parseFloat(`${this.data.project.taxRate}`)))}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${ProjectCurrencyEnum[this.data.project.currency]} ${this.getFormattedNumber(costParsed)}`,
              width: '*',
              style: ['font9', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    if (isUnitFeatureEnabledForClient(this.data.client.id) && _.includes(showEntryDetails, PdfPrintEntryDetails.UNITS) && protocolEntry.unitId) {
      const unitForBreadcrumb = this.getProtocolEntryUnitForBreadcrumbs(protocolEntry);
      if (!unitForBreadcrumb) {
        throw new Error(`getProtocolEntryUnitForBreadcrumbs did not find the unit ${protocolEntry.unitId} of protocolEntry ${protocolEntry.id}`);
      }
      rowNumber++;
      if (showByOptions()) {
        if (showLineSeparator()) {
          this.writeLine(columns, lineWidth);
        }
        columns.push({
          columns: [
            {
              width: labelWidth,
              text: `${this.i18n?.get('unit')}`,
              style: ['font7', isFirstRow() ? 'marginTop2' : 'marginTop6'],
            },
            {
              text: `${unitForBreadcrumb.breadcrumbsName}`,
              width: '*',
              style: ['font9', 'textBold', isFirstRow() ? 'marginLeft10Top1' : 'marginLeft10Top5'],
            },
          ],
        });
      }
    }

    return {columns, protocolEntryDetailRows: rowNumber + 1};
  }

  protected getProtocolEntryCompany(protocolEntry: ProtocolEntry): string {
    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId);
    const protocolLayout = this.data.lookup?.protocolLayouts.get(protocolType?.layoutId + '');
    if (protocolEntry.allCompanies) {
      return this.sanitizeValue(this.i18n?.get('project_team'));
    }
    let companyName = this.data.lookup?.companies.get(protocolEntry?.companyId + '')?.name;
    if (protocolType !== undefined && protocolLayout !== undefined && protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT) {
      const userPublic = this.data.lookup.userPublicData.get(this.data.userId);
      const userProfile = this.data.lookup.profiles.get(userPublic?.profileId + '');
      const userCompany = this.data.lookup.companies.get(userProfile?.companyId + '');
      if (userCompany !== undefined) {
        companyName = userCompany.name;
      }
    }
    return this.sanitizeValue(companyName);
  }

  protected getProtocolEntryUnitForBreadcrumbs(protocolEntry: ProtocolEntry): UnitForBreadcrumbs | undefined {
    if (!protocolEntry.unitId) {
      return undefined;
    }
    return this.data.unitForBreadcrumbs?.find((v) => v.id === protocolEntry.unitId);
  }

  private isCarriedEntry(protocolEntry: ProtocolEntry): boolean {
    return this.data.protocolOpenEntries.some((entry) => entry.protocolEntryId === protocolEntry.id);
  }

  private isContinuousProtocol(): boolean {
    const layoutId = this.data.lookup.protocolTypes.get(this.data.protocol.typeId)?.layoutId;
    if (layoutId) {
      return this.data.lookup.protocolLayouts.get(layoutId)?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
    }
    return false;
  }

  protected getProtocolEntryHeader(protocolEntry: ProtocolEntry, isTask: boolean): Column[] {
    const protocolEntryHeaderColumns: Column[] = [];
    const showEntryDetails: PdfPrintEntryDetails[] = this.config.pdfProtocolSetting?.printEntryDetails || [];

    const displayNewFlag = (this.isContinuousProtocol() && !this.isCarriedEntry(protocolEntry)) || protocolEntry.createdInProtocolId === this.data.protocol.id;

    protocolEntryHeaderColumns.push({
      columns: [
        {
          text: displayNewFlag ? `${this.i18n?.get('new_flag')}` : '',
          style: ['font8', 'fontColorRed'],
          width: 'auto',
          margin: displayNewFlag ? [protocolEntry.parentId ? 0 : -25, 0, protocolEntry.parentId ? 0 : 5, 0] : [0, 0, 0, 0],
        },
        {
          svg: isTask ? this.changeSvgIconFill(SvgIcons.recordCheck, this.getProtocolColor()) : this.changeSvgIconFill(SvgIcons.record, this.getProtocolColor()),
          fit: [12, 12],
        },
      ],
      width: 'auto',
    });

    protocolEntryHeaderColumns.push({
      width: 95,
      text: `${isTask ? this.getTaskShortId(protocolEntry) : this.getProtocolEntryShortId(protocolEntry)}`,
      style: ['font8', 'protocolFontColor', 'marginLeft10'],
    });

    const protocolPriorityLevel = this.getProtocolEntryPriorityLevel(protocolEntry.priority);
    if (protocolEntry.createdInProtocolId) {
      const createdInProtocol = this.data.lookup.protocols.get(protocolEntry.createdInProtocolId);
      if (createdInProtocol && this.data.protocol.number < createdInProtocol.number) {
        protocolEntryHeaderColumns.push({
          text: ` ${this.sanitizeValue(this.i18n?.get('entry_added_later'))} `,
          width: 'auto',
          style: ['font7', 'marginLeft10', 'addedLater'],
        });
      }
    }
    if (!_.isEmpty(protocolPriorityLevel) && _.includes(showEntryDetails, PdfPrintEntryDetails.PRIORITY)) {
      protocolEntryHeaderColumns.push({
        text: `${protocolPriorityLevel}`,
        width: 'auto',
        style: ['font8', 'marginLeft10'],
      });

      protocolEntryHeaderColumns.push({
        columns: [
          {
            svg: `${this.getSvgPriorityLevel(protocolEntry.priority)}`,
            fit: [12, 12],
          },
        ],
        width: 'auto',
        style: ['marginLeft10'],
      });
    }

    if ((!this.isEmptyDate(protocolEntry.startDate) || !this.isEmptyDate(protocolEntry.todoUntil)) && _.includes(showEntryDetails, PdfPrintEntryDetails.DATES)) {
      protocolEntryHeaderColumns.push({
        columns: this.getProtocolStartEndDate(protocolEntry),
        style: ['marginLeft10'],
      });
    }

    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId);
    const protocolLayout = this.data.lookup?.protocolLayouts.get(protocolType?.layoutId + '');

    let protocolEntryStatus;

    if (protocolType !== undefined && protocolLayout !== undefined && protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT) {
      protocolEntryStatus = getProtocolEntryStatus(protocolEntry);
    } else {
      protocolEntryStatus = getProtocolEntryIconStatus(protocolEntry, this.data.lookup.protocolEntryTypes.get(protocolEntry.typeId + ''));
    }

    let svgProtocolStatus = '',
      protocolStatusText = '';
    if (protocolEntryStatus === ProtocolEntryIconStatus.OPEN) {
      protocolStatusText = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? this.sanitizeValue(this.i18n?.get('checkbox_open_status')) : this.sanitizeValue(this.i18n?.get('open_status'));
      svgProtocolStatus = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? SvgIcons.blackCheckboxUncheck : SvgIcons.redProtocolStatus;
    } else if (protocolEntryStatus === ProtocolEntryIconStatus.ON_HOLD) {
      protocolStatusText = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? this.sanitizeValue(this.i18n?.get('checkbox_waiting_status')) : this.sanitizeValue(this.i18n?.get('waiting_status'));
      svgProtocolStatus = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? SvgIcons.blackCheckboxUncheck : SvgIcons.yellowProtocolStatus;
    } else if (protocolEntryStatus === ProtocolEntryIconStatus.DONE) {
      protocolStatusText = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? this.sanitizeValue(this.i18n?.get('checkbox_done_status')) : this.sanitizeValue(this.i18n?.get('done_status'));
      svgProtocolStatus = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? SvgIcons.blackCheckboxCheck : SvgIcons.greenProtocolStatus;
    }

    if (!_.isEmpty(svgProtocolStatus) && !_.isEmpty(protocolStatusText)) {
      protocolEntryHeaderColumns.push({
        text: protocolStatusText,
        width: '*',
        style: ['font8', 'alignRight'],
      });

      protocolEntryHeaderColumns.push({
        columns: [
          {
            svg: svgProtocolStatus,
            fit: [12, 12],
          },
        ],
        width: 'auto',
        style: ['marginLeft10'],
      });
    }

    return protocolEntryHeaderColumns;
  }

  protected getProtocolStartEndDate(protocolEntry: ProtocolEntry): Column[] {
    const startEndDateColumn: Column[] = [];
    if (!this.isEmptyDate(protocolEntry.startDate) || !this.isEmptyDate(protocolEntry.todoUntil)) {
      if (!this.isEmptyDate(protocolEntry.startDate) && this.isEmptyDate(protocolEntry.todoUntil)) {
        startEndDateColumn.push({
          text: `${this.i18n?.get('starts')}`,
          width: 'auto',
          style: ['font9', 'fontColorRed'],
        });
        startEndDateColumn.push({
          text: `${this.getDateValueNotNull(protocolEntry.startDate)}`,
          width: 'auto',
          style: ['font9', 'fontColorRed', 'textBold', 'marginLeft5'],
        });
      } else {
        startEndDateColumn.push({
          text: `${this.i18n?.get('due')}`,
          width: 'auto',
          style: ['font9', 'fontColorRed'],
        });

        if (!this.isEmptyDate(protocolEntry.startDate) && !this.isEmptyDate(protocolEntry.todoUntil)) {
          startEndDateColumn.push({
            text: `${this.getDateValueNotNull(protocolEntry.startDate)}`,
            width: 'auto',
            style: ['font9', 'fontColorRed', 'textBold', 'marginLeft5'],
          });

          startEndDateColumn.push({
            text: `${this.sanitizeValue(this.i18n?.get('until'))}`,
            width: 'auto',
            style: ['font9', 'fontColorRed', 'marginLeft5'],
          });

          startEndDateColumn.push({
            text: ` ${this.getDateValueNotNull(protocolEntry.todoUntil)}`,
            width: 'auto',
            style: ['font9', 'fontColorRed', 'textBold', 'marginLeft5'],
          });
        } else if (this.isEmptyDate(protocolEntry.startDate) && !this.isEmptyDate(protocolEntry.todoUntil)) {
          startEndDateColumn.push({
            text: ` ${this.getDateValueNotNull(protocolEntry.todoUntil)}`,
            width: 'auto',
            style: ['font9', 'fontColorRed', 'textBold', 'marginLeft5'],
          });
        }
      }
    }

    return startEndDateColumn;
  }

  protected getPdfPlanPage(protocolEntryId: IdType): PdfPlanAttachmentWithContent | undefined {
    const pdfPlanPageMarkerGeneral = this.data.pdfPlanPageMarkings.find((pdfPlanPageMarking) => !pdfPlanPageMarking.protocolEntryId && pdfPlanPageMarking);
    const pdfPlanPageMarker = this.data.pdfPlanPageMarkings.find((pdfPlanPageMarking) => pdfPlanPageMarking.protocolEntryId === protocolEntryId);
    const pdfPlanPage = this.data.pdfPlanPages?.find((pdfProtocolEntryPlanPage) => pdfProtocolEntryPlanPage.protocolEntryId === protocolEntryId);
    if (!this.config.pdfProtocolSetting?.showPdfPlanMarker || _.isEmpty(pdfPlanPage?.contentBase64)) {
      return undefined;
    }
    if (pdfPlanPage && (pdfPlanPageMarkerGeneral || pdfPlanPageMarker)) {
      pdfPlanPage.markings = [];
      if (pdfPlanPageMarkerGeneral) {
        pdfPlanPage.markings.push(pdfPlanPageMarkerGeneral.markings);
      }
      if (pdfPlanPageMarker) {
        pdfPlanPage.markings.push(pdfPlanPageMarker.markings);
      }
    }
    return pdfPlanPage;
  }

  protected getBimMarkers(protocolEntryId: IdType): Array<BimMarker> {
    if (!(this.config.pdfProtocolSetting?.showBimMarker ?? true)) {
      return [];
    }

    const bimMarkers = this.data.bimMarkers?.filter((marker) => marker.protocolEntryId === protocolEntryId) ?? [];

    return bimMarkers;
  }

  protected getPlanMarker(protocolEntry: ProtocolEntry): Column[] {
    const columns: Column[] = [];
    const pdfPlanPage = this.getPdfPlanPage(protocolEntry.id);
    const downloadInfo = pdfPlanPage?.publicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? this.i18n?.get('downloadClick') : '';
    const planVersion = this.data.pdfPlanVersions ? this.data.pdfPlanVersions.find((plan) => plan.id === pdfPlanPage?.attachment.pdfPlanVersionId) : undefined;
    const planVersionInfo = planVersion ? planVersion.name + ' | ' + (planVersion.index ? planVersion.index + ' | ' : '') + formatDate(planVersion.date) : '';
    const planPublicLink = this.data.pdfPlanVersionsWithContent?.find((pdfPlan) => pdfPlan.attachment.pdfPlanVersionId === pdfPlanPage?.attachment.pdfPlanVersionId)?.publicLink;
    const fontColor = planPublicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'fontColorBlue' : 'fontColorGray';
    const linkUnderline = planPublicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'linkUnderline' : '';
    columns.push({
      style: ['marginTop10'],
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.marker, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          text: 'Planmarker',
          width: 'auto',
          style: ['font10', 'protocolFontColor', 'marginLeft10'],
        },
        {
          text: `${planVersionInfo}`,
          width: 'auto',
          link: `${planPublicLink ? planPublicLink : ''}`,
          style: ['font8', `${fontColor}`, `${linkUnderline}`, 'marginLeft5Top2'],
        },
        {
          text: `${downloadInfo}`,
          width: 'auto',
          style: ['font8', 'fontColorGray', 'marginLeft5Top2'],
        },
      ],
    });

    this.writeLine(columns, this.getLineWidth(protocolEntry));

    if (pdfPlanPage?.contentBase64 !== undefined && pdfPlanPage?.secondContentBase64 !== undefined) {
      columns.push({
        columns: [
          {
            image: this.sanitizeBase64Image(pdfPlanPage?.contentBase64),
            fit: [161, 124],
            style: ['alignCenter', 'marginTop10'],
            link: pdfPlanPage.publicLink ? `${pdfPlanPage.publicLink}` : '',
          },
          {
            image: this.sanitizeBase64Image(pdfPlanPage?.secondContentBase64),
            fit: [161, 124],
            style: ['alignCenter', 'marginTop10'],
            link: pdfPlanPage.secondPublicLink ? `${pdfPlanPage.secondPublicLink}` : '',
          },
        ],
      });
    }

    return columns;
  }

  protected getProtocolEntryAttachmentsData(protocolEntryId: IdType): AttachmentWithContent<AttachmentProtocolEntry>[] | undefined {
    const protocolEntryPhotos = this.data.attachmentProtocolEntries?.filter((protocolEntryAttachment) => protocolEntryAttachment.attachment.protocolEntryId === protocolEntryId);
    if (_.isEmpty(protocolEntryPhotos) || protocolEntryPhotos === undefined || this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.NONE) {
      return undefined;
    }
    return _.orderBy(protocolEntryPhotos, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);
  }

  protected getProtocolEntryAttachments(protocolEntry: ProtocolEntry): Column[] {
    const columns: Column[] = [];
    const protocolEntryPhotos = this.getProtocolEntryAttachmentsData(protocolEntry.id);
    const downloadInfo = protocolEntryPhotos?.some((photo) => photo.publicLink) && this.config.pdfProtocolSetting?.showAttachmentDlLink ? this.i18n?.get('downloadClick') : '';
    columns.push({
      style: ['marginTop5'],
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.attachment, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          text: `${this.i18n?.get('photos_attachments')}`,
          width: 'auto',
          style: ['font10', 'protocolFontColor', 'marginLeft10'],
        },
        {
          text: `${downloadInfo}`,
          width: 'auto',
          style: ['font8', 'fontColorGray', 'marginLeft5Top2'],
        },
      ],
    });
    this.writeLine(columns, this.getLineWidth(protocolEntry));
    const attachmentsOrderedByNewest = _.orderBy(protocolEntryPhotos, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);
    if (this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.SMALL) {
      this.addChunkPhotos(columns, attachmentsOrderedByNewest, [152, 100], 3);
    } else if (this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.MEDIUM) {
      this.addChunkPhotos(columns, attachmentsOrderedByNewest, [232, 145], 2);
    } else if (this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.LARGE) {
      this.addLargePhotosIntoColumns(columns, attachmentsOrderedByNewest);
    }
    return columns;
  }

  protected addChunkPhotos(columns: Column[], protocolEntryPhotos: AttachmentWithContent<AttachmentProtocolEntry>[], imageFit: [number, number], chunkImage: number) {
    const protocolEntryImages = protocolEntryPhotos.filter((protocolEntryPhoto) => this.isImage(protocolEntryPhoto.attachment.mimeType));
    const chunkProtocolEntryPhotos = _.chunk(protocolEntryImages, chunkImage);
    const chunkAttachmentsColumn = new Array<ContentStack & TableOfContent>();
    let index = 1;
    for (const attachments of chunkProtocolEntryPhotos) {
      const attachmentColumns = new Array<ContentImage | ContentSvg>();
      const indexColumns: Column[] = [];
      for (const attachment of attachments) {
        if (this.isImage(attachment.attachment.mimeType)) {
          if (attachment.contentBase64) {
            attachmentColumns.push({
              image: this.sanitizeBase64Image(attachment.contentBase64),
              fit: imageFit,
              style: ['alignCenter'],
              link: attachment.publicLink ? `${attachment.publicLink}` : '',
            });
          } else {
            attachmentColumns.push({
              svg: SvgIcons.noImagePlaceholder,
              fit: imageFit,
              style: ['alignCenter'],
            });
          }

          indexColumns.push({
            text: index.toString(),
            style: ['alignCenter', 'font9'],
          });
          index++;
        }
      }
      chunkAttachmentsColumn.push({
        stack: [
          {
            columns: indexColumns,
            style: ['marginTop10'],
          },
          {
            columns: attachmentColumns,
            style: ['marginTop2'],
          },
        ],
        id: 'imageRow',
      });
    }
    columns.push({
      columns: [chunkAttachmentsColumn],
      style: ['marginTop5'],
    });

    this.writeNonImageAttachment(columns, protocolEntryPhotos);
  }

  protected addLargePhotosIntoColumns(columns: Column[], protocolEntryPhotos: AttachmentWithContent<AttachmentProtocolEntry>[]) {
    const attachmentColumns = new Array<(ContentStack & TableOfContent & ColumnProperties) | ContentColumns>();
    const protocolEntryImages = protocolEntryPhotos.filter((protocolEntryPhoto) => this.isImage(protocolEntryPhoto.attachment.mimeType));
    let index = 1;

    for (const protocolEntryPhoto of protocolEntryImages) {
      attachmentColumns.push({
        width: '*',
        style: ['alignCenter'],
        stack: [
          {
            text: index.toString(),
            style: ['alignCenter', 'font9', 'marginTop10'],
          },
          {
            columns: [
              protocolEntryPhoto.contentBase64
                ? {
                    image: this.sanitizeBase64Image(protocolEntryPhoto.contentBase64),
                    fit: [470, 270],
                    style: ['marginTop2'],
                    link: protocolEntryPhoto.publicLink ? `${protocolEntryPhoto.publicLink}` : '',
                  }
                : {
                    svg: SvgIcons.noImagePlaceholder,
                    fit: [470, 270],
                    style: ['marginTop2'],
                  },
            ],
          },
        ],
        id: 'imageRow',
      });
      index++;
    }

    columns.push({
      columns: [attachmentColumns],
      style: ['marginTop5'],
    });

    this.writeNonImageAttachment(columns, protocolEntryPhotos);
  }

  protected getProtocolEntryComments(protocolEntryId: IdType): ProtocolEntryChat[] | undefined {
    const comments = _.sortBy(
      this.data.protocolEntryChats.filter((protocolEntryChat) => protocolEntryChat.protocolEntryId === protocolEntryId),
      ['createdAt']
    );
    if (!this.config.pdfProtocolSetting?.showEntryComments || comments.length === 0) {
      return undefined;
    }
    return comments;
  }

  protected getComments(protocolEntry: ProtocolEntry): Column[] {
    const columns: Column[] = [];
    const comments = this.getProtocolEntryComments(protocolEntry.id);
    const downloadInfo = this.data.attachmentChats?.some((attachmentChat) => attachmentChat.publicLink) && this.config.pdfProtocolSetting?.showAttachmentDlLink ? this.i18n?.get('downloadClick') : '';

    if (comments === undefined) {
      return columns;
    }

    columns.push({
      style: ['marginTop5'],
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.comments, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          text: `${this.i18n?.get('comments')}`,
          width: 'auto',
          style: ['font10', 'protocolFontColor', 'marginLeft10'],
        },
        {
          text: `${downloadInfo}`,
          width: 'auto',
          style: ['font8', 'fontColorGray', 'marginLeft5Top2'],
        },
      ],
    });
    this.writeLine(columns, this.getLineWidth(protocolEntry));

    const commentsLength = comments.length;
    let counter = 0;
    for (const comment of comments) {
      const commentsColumns: Column[] = [];
      const userData = this.data.lookup.userPublicData.get(comment?.createdById + '');
      const commenterProfile = this.data.lookup.profiles.get(userData?.profileId + '') ?? this.data.lookup.profilesByAttachedToUserId.get(comment?.createdById + '');
      const commenterAddress = this.data.lookup.addresses.get(commenterProfile?.addressId + '');
      const displayNewFlag = this.isContinuousProtocol() && this.isCarriedEntry(protocolEntry) && comment.changedAt > this.data.protocol.createdAt;
      commentsColumns.push({
        width: 40,
        style: ['alignCenter'],
        columns: [
          {
            style: ['font8', 'fontColorRed'],
            text: displayNewFlag ? `${this.i18n?.get('new_flag')}` : '',
          },
        ],
      });

      commentsColumns.push({
        width: 'auto',
        columns: [
          [
            {
              style: ['font9', 'textBold'],
              text: `${this.sanitizeValue(commenterAddress?.firstName)} ${this.sanitizeValue(commenterAddress?.lastName)}`,
            },
            {
              style: ['font8'],
              text: `${this.getDateValueNotNull(comment.changedAt, 'DD.MM.YYYY HH:mm')}`,
            },
          ],
        ],
      });

      commentsColumns.push({
        width: '*',
        style: ['marginLeft10'],
        columns: [
          {
            style: ['font8'],
            text: `${this.sanitizeValue(comment?.message)}`,
          },
        ],
      });

      columns.push({
        columns: commentsColumns,
        width: '*',
        style: 'marginTop5',
      });

      if (this.config.pdfProtocolSetting?.showEntryCommentPictures) {
        const chatAttachments = this.data.attachmentChats?.filter((attachmentChat) => attachmentChat.attachment.chatId === comment.id);
        if (!_.isEmpty(chatAttachments) && chatAttachments !== undefined) {
          const sortedChatAttachments = _.orderBy(chatAttachments, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);
          const nonImageAttachments = sortedChatAttachments.filter((attachment) => !this.isImage(attachment.attachment.mimeType));
          const imageAttachments = sortedChatAttachments.filter((attachment) => this.isImage(attachment.attachment.mimeType));
          const chunkChatImages = _.chunk(imageAttachments, 2);
          const chunkAttachmentsColumn = new Array<ContentStack & TableOfContent & ColumnProperties>();
          let index = 1;
          for (const attachments of chunkChatImages) {
            const attachmentColumns = new Array<ContentImage | ContentSvg>();
            const indexColumns: Column[] = [];
            for (const chatAttachment of attachments) {
              if (chatAttachment.contentBase64) {
                attachmentColumns.push({
                  image: this.sanitizeBase64Image(chatAttachment.contentBase64),
                  fit: [150, 150],
                  style: ['alignCenter'],
                  margin: [20, 0, 0, 0],
                  link: chatAttachment.publicLink ? `${chatAttachment.publicLink}` : '',
                });
              } else {
                attachmentColumns.push({
                  svg: SvgIcons.noImagePlaceholder,
                  fit: [150, 150],
                  style: ['alignCenter'],
                  margin: [20, 0, 0, 0],
                });
              }

              indexColumns.push({
                text: index.toString(),
                style: ['font9', 'alignCenter'],
              });
              index++;
            }
            chunkAttachmentsColumn.push({
              stack: [
                {
                  columns: indexColumns,
                  style: ['marginTop10'],
                },
                {
                  columns: attachmentColumns,
                  style: ['marginTop2'],
                },
              ],
              id: 'imageRow',
              width: 'auto',
            });
          }

          if (!_.isEmpty(chunkAttachmentsColumn)) {
            columns.push({
              width: 'auto',
              margin: index === 2 ? [340, 0, 0, 0] : [170, 0, 0, 0],
              columns: [chunkAttachmentsColumn],
              style: ['alignRight'],
            });
          }

          for (const attachment of nonImageAttachments) {
            const nonImageAttachmentColumn: Column[] = [];
            this.addToNonPhotosColumnComment(attachment, nonImageAttachmentColumn);
            columns.push({
              columns: [nonImageAttachmentColumn],
              margin: [0, 10, 10, 0],
            });
          }
        }
      }
      counter++;

      if (counter < commentsLength) {
        this.writeLine(columns, this.getLineWidth(protocolEntry));
      }
    }

    return columns;
  }

  protected writeNonImageAttachment(columns: Column[], protocolEntryPhotos: AttachmentWithContent<AttachmentProtocolEntry>[]) {
    let splitedNonPhotos: Column[] = [];
    let charLength = 0;
    const maxCharLengthPerRow = 90;
    for (const attachment of protocolEntryPhotos) {
      if (!this.isImage(attachment.attachment.mimeType)) {
        charLength += (attachment.attachment?.fileName + '').length;
        if (charLength > maxCharLengthPerRow) {
          columns.push({
            columns: splitedNonPhotos,
            style: ['marginTop10'],
          });
          splitedNonPhotos = [];
          charLength = (attachment.attachment?.fileName + '').length;
          this.addToNonPhotosColumn(attachment, splitedNonPhotos);
        } else {
          this.addToNonPhotosColumn(attachment, splitedNonPhotos);
        }
      }
    }
    if (splitedNonPhotos.length > 0) {
      columns.push({
        columns: splitedNonPhotos,
        style: ['marginTop10'],
      });
    }
  }

  protected addToNonPhotosColumn(attachment: AttachmentWithContent<AttachmentProtocolEntry>, columns: Column[]) {
    const fontColorBlue = attachment.publicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'fontColorBlue' : '';
    const linkUnderline = attachment.publicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'linkUnderline' : '';
    columns.push({
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.attachment, this.config.pdfProtocolSetting?.showAttachmentDlLink ? LINK_BLUE : this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          text: `${attachment.attachment?.fileName + ''}`,
          style: ['font9', 'alignLeft'],
          width: 'auto',
          link: attachment.publicLink ? `${attachment.publicLink}` : '',
        },
      ],
      style: ['marginLeft10', `${fontColorBlue}`, `${linkUnderline}`],
      width: 'auto',
    });
  }

  protected addToNonPhotosColumnComment(attachment: AttachmentWithContent<AttachmentProtocolEntry>, columns: Column[]) {
    const fontColorBlue = attachment.publicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'fontColorBlue' : '';
    const linkUnderline = attachment.publicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'linkUnderline' : '';
    columns.push({
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.attachment, this.config.pdfProtocolSetting?.showAttachmentDlLink ? LINK_BLUE : this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
        },
        {
          text: attachment.attachment.fileName ? `${attachment.attachment.fileName}` : `${this.i18n?.get('no_filename')}`,
          style: ['font9'],
          width: 'auto',
          link: attachment.publicLink ? `${attachment.publicLink}` : '',
        },
      ],
      style: ['alignRight', `${fontColorBlue}`, `${linkUnderline}`],
    });
  }

  protected getRotatedText(text: string): string {
    const canvas = createCanvas(30, 270);
    canvas.width = 30;
    canvas.height = 270;
    const ctx = canvas.getContext('2d');
    if (ctx !== null) {
      ctx.font = '30pt Arial';
      ctx.save();
      ctx.translate(30, 270);
      ctx.rotate(-0.5 * Math.PI);
      ctx.fillStyle = '#000';
      ctx.fillText(text, 0, 0);
      ctx.restore();
    }
    return canvas.toDataURL();
  }

  protected getProtocolEntrySubEntries(protocolEntry: ProtocolEntry): ProtocolEntry[] {
    let sortedProtocolEntries: ProtocolEntry[];
    if (this.isGroupBy) {
      const protocolEntryWithSubEntries = protocolEntry as ProtocolEntryWithSubEntries;
      sortedProtocolEntries = this.sortProtocolEntries(protocolEntryWithSubEntries.subEntries);
    } else {
      const subProtocolEntries = _.filter(this.data.protocolEntries, (entry) => entry.parentId === protocolEntry.id);
      sortedProtocolEntries = this.sortProtocolEntries(subProtocolEntries);
    }
    return this.filteredSubEntries(sortedProtocolEntries);
  }

  protected filteredSubEntries(sortedProtocolEntries: ProtocolEntry[]): ProtocolEntry[] {
    if (this.data.filteredProtocolEntries !== undefined && this.data.filteredProtocolEntries?.length > 0) {
      const filteredSubProtocolEntries: ProtocolEntry[] = [];
      for (const sortedProtocolEntry of sortedProtocolEntries) {
        const subEntry = this.data.filteredProtocolEntries.find((filteredEntry) => filteredEntry.id === sortedProtocolEntry.id);
        if (subEntry !== undefined && !_.isEmpty(subEntry) && !filteredSubProtocolEntries.some((filteredSubEntry) => filteredSubEntry.id === subEntry?.id)) {
          filteredSubProtocolEntries.push(subEntry);
        }
      }
      return filteredSubProtocolEntries;
    }
    return sortedProtocolEntries;
  }

  protected getSubEntries(protocolEntry: ProtocolEntry, isTask: boolean): Column[] {
    const columns: Column[] = [];
    const subEntriesColumn: Column[] = [];
    const sortedSubProtocolEntries = this.getProtocolEntrySubEntries(protocolEntry);

    for (const subProtocolEntry of sortedSubProtocolEntries) {
      this.writeProtocolEntry(subEntriesColumn, subProtocolEntry, isTask);
    }
    columns.push({
      columns: [
        {
          image: this.config.pdfProtocolSetting?.noSplitEntryInfo ? `${this.getRotatedText('')}` : `${this.getRotatedText(this.i18n?.get('sub_entries') + '')}`,
          width: 6,
        },
        {
          columns: [subEntriesColumn],
          width: '*',
          style: ['marginLeft2'],
        },
      ],
    });

    return [columns];
  }

  protected getLineWidth(protocolEntry: ProtocolEntry): number {
    return _.isEmpty(protocolEntry.parentId) ? TABLE_WIDTH - 5 : TABLE_WIDTH - 18;
  }

  protected getProtocolEntryPriorityLevel(priorityLevel: ProtocolEntryPriorityType | undefined): string | null {
    switch (priorityLevel) {
      case ProtocolEntryPriorityLevel.LOW:
        return this.sanitizeValue(this.i18n?.get('low'));
      case ProtocolEntryPriorityLevel.HIGH:
        return this.sanitizeValue(this.i18n?.get('high'));
      case ProtocolEntryPriorityLevel.MEDIUM:
        return this.sanitizeValue(this.i18n?.get('medium'));
      default:
        return null;
    }
  }

  protected getSvgPriorityLevel(priorityLevel: ProtocolEntryPriorityType | undefined): string | null {
    switch (priorityLevel) {
      case ProtocolEntryPriorityLevel.LOW:
        return SvgIcons.blackFlag;
      case ProtocolEntryPriorityLevel.HIGH:
        return SvgIcons.redFlag;
      case ProtocolEntryPriorityLevel.MEDIUM:
        return SvgIcons.yellowFlag;
      default:
        return null;
    }
  }
}
