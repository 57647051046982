import {Directive, OnDestroy, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {map, pairwise, startWith, takeUntil} from 'rxjs/operators';
import {convertDateTimeToISOString} from 'src/app/utils/date-utils';

@Directive({
  selector: '[appMbscCalendar]',
})
export class MbscCalendarDirective implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(@Optional() private ngControl: NgControl) {}

  ngOnInit() {
    if (this.ngControl?.control) {
      this.listenToChangesAndMarkAsPristineIfValueNotChanged();
    }
  }
  private listenToChangesAndMarkAsPristineIfValueNotChanged() {
    this.ngControl.control.valueChanges
      .pipe(
        startWith(this.ngControl.control.value),
        map((value) => [value, this.ngControl.control?.dirty ?? false]),
        pairwise(),
        takeUntil(this.destroy$)
      )
      .subscribe(([[oldValue, oldIsDirty], [newValue, newIsDirty]]) => {
        if ((oldValue === undefined || oldValue === null) && (newValue === undefined || newValue === null)) {
          return;
        }

        const vDate = convertDateTimeToISOString(oldValue);
        const oDate = convertDateTimeToISOString(newValue);
        if (vDate && oDate && vDate === oDate) {
          // New value has been emitted, but underlying value did not change;
          // if previously the control was not dirty, and now it is dirty,
          // we mark it as pristine.
          // _Mobiscroll re-emits value when init data changes, that's why it's needed._
          if (newIsDirty && !oldIsDirty) {
            this.ngControl.control?.markAsPristine();
          }
          return;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
