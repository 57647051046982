import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Attachment, AttachmentChat, AttachmentProtocolEntry, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {Observable} from 'rxjs';
import {AttachmentBlob} from '../../../model/attachments';
import {ProtocolEntryService} from '../../../services/protocol/protocol-entry.service';

@Component({
  selector: 'app-protocol-entry-attachments',
  templateUrl: './protocol-entry-attachments.component.html',
  styleUrls: ['./protocol-entry-attachments.component.scss'],
})
export class ProtocolEntryAttachmentsComponent implements OnChanges {
  @Input() acrossProjects = true;
  @Input() protocolEntry: ProtocolEntry;
  @Input() public allowDeleting: boolean;
  @Input() public allowEditMarkings: boolean;
  @Output() public markingsChanged = new EventEmitter<{attachment: Attachment | AttachmentBlob; markings: string}>();
  @Output() public attachmentDeleted = new EventEmitter<{attachment: Attachment | AttachmentBlob}>();
  public attachments: Observable<Array<AttachmentProtocolEntry | AttachmentChat>>;

  constructor(private protocolEntryService: ProtocolEntryService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.protocolEntry) {
      this.attachments = this.acrossProjects
        ? this.protocolEntryService.getEntryAndChatAttachmentsAcrossProjects(this.protocolEntry.id)
        : this.protocolEntryService.getEntryAndChatAttachments(this.protocolEntry.id);
    }
  }

  public onMarkingsChanged(attachment: Attachment | AttachmentBlob, markings: string) {
    this.markingsChanged.emit({attachment, markings});
  }

  public onAttachmentDeleted(attachment: Attachment | AttachmentBlob) {
    this.attachmentDeleted.emit({attachment});
  }
}
