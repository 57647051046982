import {transition, trigger, useAnimation} from '@angular/animations';
import {Component, HostBinding, Input} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {concealAnimation, revealAnimation} from 'src/app/animations/reveal.animation';

@Component({
  selector: 'app-text-tooltip',
  templateUrl: './text-tooltip.component.html',
  styleUrls: ['./text-tooltip.component.scss'],
  standalone: true,
  imports: [IonicModule],
  animations: [trigger('reveal', [transition(':enter', useAnimation(revealAnimation)), transition(':leave', useAnimation(concealAnimation))])],
})
export class TextTooltipComponent {
  @HostBinding('@reveal')
  protected readonly revealAnimation = true;

  @Input() text: string;
}
