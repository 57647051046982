import {PdfPlanVersionWithAttachment, PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {PdfPlanFolderWithDeletable} from 'src/app/services/project-room/pdf-plan-folder.service';
import {DeviceService, QUERY} from 'src/app/services/ui/device.service';
import {getPlanItemExpandedExtraHeight, PLAN_ITEM_HEIGHT, PLAN_ITEM_HEIGHT_MD} from 'src/app/utils/plan-item-height-calculator';
import {trackById} from 'src/app/utils/track-by-id';
import {IdType} from 'submodules/baumaster-v2-common';
import {PdfPlanHolderItemDirective, PdfPlanViewMode} from '../pdf-plan-holder-list-item-row/pdf-plan-holder-item.directive';
import _ from 'lodash';

const HEADER_HEIGHT = 48;

export interface HolderMouseEvent {
  event: MouseEvent;
  holder: PdfPlanWithDeletable;
  version?: PdfPlanVersionWithAttachment | undefined;
}

export interface HolderActiveChangeEvent {
  active: boolean;
  holder: PdfPlanWithDeletable;
}

export interface HolderSelectedChangeEvent {
  selected: boolean;
  holder: PdfPlanWithDeletable;
}

@Component({
  selector: 'app-pdf-plan-holder-list',
  templateUrl: './pdf-plan-holder-list.component.html',
  styleUrls: ['./pdf-plan-holder-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanHolderListComponent extends PdfPlanHolderItemDirective implements OnChanges, OnInit, OnDestroy {
  readonly trackById = trackById;
  readonly trackByIdAndHeight = (index: number, item: PdfPlanWithDeletable) => `${item.id}_${this.getItemHeight(item)}`;
  private PLAN_ITEM_HEIGHTS = new Map<IdType, number>();
  private isBelowBreakpoint = false;
  private isAboveMediaQuerySubscription: Subscription;

  @Input()
  viewMode: PdfPlanViewMode = 'read';

  @Input()
  pdfPlanHolders: Array<PdfPlanWithDeletable>;

  @Input()
  pdfPlanFolders: Array<PdfPlanFolderWithDeletable>;

  @Input()
  selected: IdType[] | undefined;

  @Output()
  holderClick = new EventEmitter<HolderMouseEvent>();

  @Output()
  actionsClick = new EventEmitter<HolderMouseEvent>();

  @Output()
  activeChange = new EventEmitter<HolderActiveChangeEvent>();

  @Output()
  selectedChange = new EventEmitter<HolderSelectedChangeEvent>();

  @Output()
  uploadVersionClick = new EventEmitter<HolderMouseEvent>();

  expandSet = new Set<IdType>();

  // rx-Angular VirtualScroll in combination with dynamic height only works properly with Observable (otherwise it calls getItemHeight forever)
  private pdfPlanHoldersSubject = new ReplaySubject<Array<PdfPlanWithDeletable>>(1);
  public pdfPlanHolders$ = this.pdfPlanHoldersSubject.asObservable();

  constructor(
    private cdRef: ChangeDetectorRef,
    private deviceService: DeviceService
  ) {
    super();
  }

  ngOnInit() {
    this.isAboveMediaQuerySubscription = this.deviceService.isAboveMediaQuery(QUERY.md).subscribe((isAbove) => {
      if (isAbove) {
        this.isBelowBreakpoint = false;
      } else {
        this.isBelowBreakpoint = true;
        this.PLAN_ITEM_HEIGHTS.clear();
        this.expandSet.clear();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pdfPlanHolders) {
      this.pdfPlanHoldersSubject.next(this.pdfPlanHolders);
    }
    if (changes.pdfPlanFolders && !changes.pdfPlanFolders.firstChange) {
      this.forceRefreshAfterHeightChanged();
    }
  }

  ngOnDestroy() {
    this.isAboveMediaQuerySubscription.unsubscribe();
  }

  toggleExpanded(planId: IdType, item: PdfPlanWithDeletable) {
    if (this.expandSet.has(planId)) {
      this.expandSet.delete(planId);
    } else {
      this.expandSet.add(planId);
    }
    this.planItemHeightChanged(item);
    this.cdRef.markForCheck();
  }

  isExpanded(planId: IdType) {
    return this.expandSet.has(planId);
  }

  planItemHeightChanged(item: PdfPlanWithDeletable) {
    this.setPlanItemHeight(item);
    this.forceRefreshAfterHeightChanged();
  }

  private setPlanItemHeight(item: PdfPlanWithDeletable) {
    if (this.isExpanded(item.id)) {
      const planVersionsTotalHeight = this.getPlanExpandedHeight(item);
      const index = this.pdfPlanHolders.findIndex((i) => i.id === item.id);
      let groupHeaderHeight: number = this.pdfPlanFolders?.length && index >= 0 && this.firstInGroup(item, index) ? HEADER_HEIGHT : 0;
      this.PLAN_ITEM_HEIGHTS.set(item.id, PLAN_ITEM_HEIGHT + groupHeaderHeight + planVersionsTotalHeight);
    } else {
      this.PLAN_ITEM_HEIGHTS.delete(item.id);
    }
  }

  private getPlanExpandedHeight(item: PdfPlanWithDeletable) {
    return getPlanItemExpandedExtraHeight(item.pdfPlanVersions.length);
  }

  private forceRefreshAfterHeightChanged() {
    // rx-Angular VirtualScroll will only apply changes in height if new objects are being provided.
    this.pdfPlanHoldersSubject.next(this.pdfPlanHolders.map((c) => _.clone(c)));
  }

  getItemHeight = (item: PdfPlanWithDeletable) => {
    const index = this.pdfPlanHolders.findIndex((i) => i.id === item.id);
    if (this.PLAN_ITEM_HEIGHTS.has(item?.id)) {
      return this.PLAN_ITEM_HEIGHTS.get(item?.id);
    }
    const groupHeaderHeight = this.pdfPlanFolders?.length && index >= 0 && this.firstInGroup(item, index) ? HEADER_HEIGHT : 0;
    if (this.isBelowBreakpoint) {
      return PLAN_ITEM_HEIGHT_MD + groupHeaderHeight;
    }
    return PLAN_ITEM_HEIGHT + groupHeaderHeight;
  };

  getHeader = (item: PdfPlanWithDeletable, index: number, items: Array<PdfPlanWithDeletable>): null | {name: string | undefined; icon: string | null | undefined} => {
    if (this.pdfPlanFolders && items?.length) {
      if (index === 0) {
        const folder = this.pdfPlanFolders.find((planFolder) => planFolder.id === item.folderId);
        return {name: folder?.name, icon: folder?.icon};
      } else {
        if (items[index].folderId !== items[index - 1].folderId) {
          const folder = this.pdfPlanFolders.find((planFolder) => planFolder.id === item.folderId);
          return {name: folder?.name, icon: folder?.icon};
        }
      }
    }
    return null;
  };

  firstInGroup = (item: PdfPlanWithDeletable | null | undefined, index: number): boolean | undefined => {
    if (!item || !this.pdfPlanHolders || index > this.pdfPlanHolders.length - 1) {
      return undefined;
    }
    if (index <= 0) {
      return true;
    }
    if (!this.pdfPlanFolders?.length) {
      return index === 0;
    }
    return item.folderId !== this.pdfPlanHolders[index - 1]?.folderId;
  };
}
