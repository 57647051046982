import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ReportGroup} from '../model/report-group';
import {isReportMonthGroup, isReportWeekGroup, isReportYearGroup} from '../services/report/report-filter.service';

@Pipe({
  name: 'reportGroupName',
})
export class ReportGroupNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: ReportGroup): unknown {
    if (isReportWeekGroup(value)) {
      return `${this.translateService.instant('reports.calendarWeekPrefix')}${value.weekNumber} - ${value.year} (${value.reports.length})`;
    }
    if (isReportMonthGroup(value)) {
      return `${this.translateService.instant(`months.${value.month}`)} - ${value.year} (${value.reports.length})`;
    }
    if (isReportYearGroup(value)) {
      return `${value.year} (${value.reports.length})`;
    }
    return null;
  }
}
