<ion-list class="ion-no-padding omg-hoverable">
  <ion-item [routerLink]="['/project-room/pdf-plan-folders']" (click)="capturePosthogEvent('PDF plans')" detail routerLinkActive="active-item">
    <ion-label>{{ "project_room.pdf_plan_folders" | translate }}</ion-label>
  </ion-item>
  <ion-item *ngIf="!hideBimSection" (click)="navigateToBIM(); capturePosthogEvent('Bim Files');" [class.active-item]="isActive" detail class="cursor-fix">
    <ion-label>{{ "project_room.bim_files" | translate }}</ion-label>
  </ion-item>
  <ion-item [routerLink]="['/project-room/attachments']" (click)="capturePosthogEvent('attachments')" detail routerLinkActive="active-item">
    <ion-label>{{ "project_room.attachments" | translate }}</ion-label>
  </ion-item>
  <ion-item [routerLink]="['/project-room/pdf-protocols']" (click)="capturePosthogEvent('PDF protocols')" detail routerLinkActive="active-item">
    <ion-label>{{ "project_room.pdf_protocols" | translate }}</ion-label>
  </ion-item>
  <ion-item [routerLink]="['/project-room/pdf-reports']" (click)="capturePosthogEvent('PDF reports')" detail routerLinkActive="active-item" *ngIf="hasReportRights$ | async">
    <ion-label>{{ "project_room.pdf_reports" | translate }}</ion-label>
  </ion-item>
  <ion-item [routerLink]="['/project-room/global-search']" (click)="capturePosthogEvent('PDF search')" detail routerLinkActive="active-item">
    <ion-label>{{ "project_room.global_search" | translate }}</ion-label>
  </ion-item>
</ion-list>
