import {Inject, Injectable, OnDestroy} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {IdAware, IdType, syncKeyColumnSetMap} from 'submodules/baumaster-v2-common';
import {filter, map} from 'rxjs/operators';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {LoggingService} from '../common/logging.service';
import {LocalChange, LocalChanges, LocalChangesData} from '../../model/local-changes';
import {IS_AWARE, STORAGE_KEY_PROJECT_SEPARATOR, StorageKeyEnum} from '../../shared/constants';
import async, {AsyncResultCallback, QueueObject} from 'async';
import {StorageMutationOptions, StorageService} from '../storage.service';
import {DataDependency} from 'src/app/model/data-dependency';
import {DATA_DEPENDENCIES, STORAGE_KEY_TO_RAW_SYNC_KEYS} from 'src/app/shared/data-dependencies';
import {doesObjectHasAnyDataDependencyReference, getAllObjectDataDependencyReferences} from '../sync/sync-utils';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {checkObjectsUniqueConstraint} from '../sync/utils/unique-constraint-utils';
import semver from 'semver';

export const STORAGE_KEY_LOCAL_CHANGES_SUFFIX = '_localChanges';
export const VERSION_INTRODUCED_DEFAULT = '2.0.0';
const DEFAULT_SORT_ORDER = 'asc';

export interface DataServiceOptions {
  localChangesMappingMode?: boolean;
}

export interface DataServiceInsertOptions extends DataServiceOptions {
  dismissDuplicateKeys?: boolean;
}

export interface DataServiceDeleteOptions extends DataServiceOptions {
  dismissNotExistingValue?: boolean;
}

export const defaultInsertOptionsMappingDataServices: DataServiceInsertOptions = {
  localChangesMappingMode: true,
  dismissDuplicateKeys: true,
};

export const defaultDeleteOptionsMappingDataServices: DataServiceDeleteOptions = {
  localChangesMappingMode: true,
  dismissNotExistingValue: true,
};

@Injectable()
export abstract class AbstractDataService<T extends IdAware> implements OnDestroy {
  private static readonly synchronizedStorageAccessQueue: QueueObject<() => Promise<any>> = async.queue<any>(async (task: () => Promise<any>, callback: AsyncResultCallback<any>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);

  public readonly clientOrProjectAware: boolean;
  protected readonly restEndpointUrl;
  protected dataSubjectInitialized = false;
  protected readonly dataSubject = new BehaviorSubject<Array<T> | null>(null);
  public readonly dataReally: Observable<Array<T>> = this.dataSubject.asObservable().pipe(filter((value) => value !== null));
  public readonly data: Observable<Array<T>> = this.dataSubject.asObservable().pipe(map((valueOrNull) => (valueOrNull !== null ? valueOrNull : this.defaultValue)));
  protected readonly localChangesSubject = new BehaviorSubject<LocalChanges<T>>(new LocalChanges<T>());
  public readonly localChanges: Observable<LocalChanges<T>> = this.localChangesSubject.asObservable();
  protected readonly localChangesByClientOrProjectSubject = new BehaviorSubject<Map<IdType | undefined, LocalChanges<T>>>(new Map());
  public readonly localChangesByClientOrProject: Observable<Map<IdType | undefined, LocalChanges<T>>> = this.localChangesByClientOrProjectSubject.asObservable();
  protected readonly logSource!: string;
  protected isAuthenticated: boolean | undefined;
  protected readonly storageInitializedSubject = new BehaviorSubject<Set<IdType | null>>(new Set());
  public readonly storageInitializedObservable = this.storageInitializedSubject.asObservable();
  public storageInitializedWithOptional$: Observable<{storageKey: string; optional: boolean; initializedMap: Set<IdType | null>}>;
  public hasCurrentUserPermission: boolean | undefined;
  protected readonly readDataFromStorageOnceSubject = new BehaviorSubject(false);
  public readonly readDataFromStorageOnce$ = this.readDataFromStorageOnceSubject.asObservable();
  public get readDataFromStorageOnce() {
    return this.readDataFromStorageOnceSubject.getValue();
  }
  public readonly isStorageInitializedOptional: boolean;

  public static async runInSynchronizedStorageAccess<R>(functionToCall: () => Promise<R>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      AbstractDataService.synchronizedStorageAccessQueue.push<R>(functionToCall, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  }

  constructor(
    @Inject('Boolean') public readonly clientAware: boolean,
    @Inject('Boolean') public readonly projectAware: boolean,
    @Inject('StorageKeyEnum') public readonly storageKey: StorageKeyEnum,
    @Inject('String') restEndpointUri: string,
    @Inject('Array') protected readonly defaultValue: Array<T>,
    protected http: HttpClient,
    protected storage: StorageService,
    protected authenticationService: AuthenticationService,
    protected loggingService: LoggingService,
    protected integrityResolverService: IntegrityResolverService,
    public versionIntroduced: string,
    @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
    @Inject('Array') protected sortColumnOrders?: Array<'asc' | 'desc'>
  ) {
    this.clientOrProjectAware = this.clientAware || this.projectAware;
    this.restEndpointUrl = environment.serverUrl + restEndpointUri;
    this.logSource = `AbstractDataService<${storageKey}>`;
    if (!semver.valid(this.versionIntroduced)) {
      throw new Error(`Value versionIntroduced "${this.versionIntroduced}" is not a valid semantic version`);
    }
    if (!semver.valid(environment.version)) {
      throw new Error(`Value environment version (from package.json) "${environment.version}" is not a valid semantic version`);
    }
    this.isStorageInitializedOptional = semver.lte(environment.version, this.versionIntroduced);
    this.storageInitializedWithOptional$ = this.storageInitializedSubject.asObservable().pipe(
      map((initializedMap) => {
        return {
          storageKey: this.storageKey,
          optional: this.isStorageInitializedOptional,
          initializedMap,
        };
      })
    );
    if (this.sortColumns) {
      if (!this.sortColumnOrders) {
        this.sortColumnOrders = _.fill(Array(sortColumns.length), DEFAULT_SORT_ORDER);
      } else if (this.sortColumnOrders.length !== this.sortColumns.length) {
        throw new Error(`For DataService with storageKey "${this.storageKey}" the field sortColumnOrders was provided but it's length does not match sortColumns`);
      }
    }
  }

  protected didReadDataFromStorageOnce() {
    if (!this.readDataFromStorageOnce) {
      this.readDataFromStorageOnceSubject.next(true);
    }
  }

  ngOnDestroy(): void {}

  protected async getDataFromStorageOrServer(clientOrProjectId?: IdType): Promise<Array<T> | null> {
    if (this.clientOrProjectAware && clientOrProjectId === undefined) {
      return this.defaultValue;
    }
    if (!this.hasCurrentUserPermission) {
      return this.defaultValue;
    }
    const data = await this.getStorageDataOrNull(clientOrProjectId);
    if (data) {
      this.setStorageInitialized(clientOrProjectId);
      return data;
    }
    this.unsetStorageInitialized(clientOrProjectId);
    if (!this.projectAware) {
      // nonClientAware and clientAware data will still be loaded from the server if not initialized locally, otherwise the sync would not start at all.
      return await this.loadFromServer(clientOrProjectId);
    }
    this.loggingService.warn(this.logSource, `Data for client or project ${clientOrProjectId} not on device's storage.`);

    return data;
  }

  protected async initLocalChangesSubject(clientOrProjectId?: IdType): Promise<LocalChanges<T>> {
    if (this.clientOrProjectAware && clientOrProjectId === undefined) {
      const emptyLocalChanges = new LocalChanges<T>();
      this.localChangesSubject.next(emptyLocalChanges);
      return emptyLocalChanges;
    }
    const localChangesOrNull = this.localChangesByClientOrProjectSubject.value.get(clientOrProjectId) || (await this.getLocalChangesFromStore(clientOrProjectId));
    const localChanges = localChangesOrNull || new LocalChanges<T>();
    this.localChangesSubject.next(localChanges);
    return localChanges;
  }

  protected async initLocalChangesByClientOrProjectSubject(): Promise<void> {
    const localChangesByClientOrProject = await this.getLocalChangesByClientOrProjectFromStore();
    this.localChangesByClientOrProjectSubject.next(localChangesByClientOrProject);
  }

  public getById(id: IdType): Observable<T | undefined> {
    return this.data.pipe(map((data) => data.find((dataEntry) => dataEntry.id === id)));
  }

  public getByIds(ids: Array<IdType>): Observable<Array<T>> {
    return this.data.pipe(map((entries) => entries.filter((dataEntry) => ids.some((id) => dataEntry.id === id))));
  }

  protected ensureDataSorted(data: Array<T>): Array<T> {
    if (!this.sortColumns || this.sortColumns.length === 0) {
      return data;
    }
    return _.orderBy(data, this.sortColumns, this.sortColumnOrders);
  }

  protected async loadFromServer(clientOrProjectId?: IdType): Promise<Array<T>> {
    const httpOptions = {
      headers: {
        Accept: 'Application/json; version=6',
      },
    };

    this.assertAuthenticated();
    const result = await new Promise<Array<T>>((resolve, reject) => {
      this.http
        .get<T>(this.getRestEndpointUrl(clientOrProjectId), httpOptions)
        .pipe(map(this.processServerData, this))
        .subscribe(
          (data) => {
            // @ts-ignore
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
    this.assertAuthenticated();

    return result;
  }

  private assertProjectIdProvidedForProjectAware(clientOrProjectId?: IdType) {
    if (!this.clientOrProjectAware && clientOrProjectId !== undefined) {
      throw new Error(`This service ${this.constructor.name} is NOT "clientAware" and not "projectAware" but argument "clientOrProjectId" with value ${clientOrProjectId} was provided.`);
    }
    if (this.clientOrProjectAware && clientOrProjectId === undefined) {
      throw new Error(`This service ${this.constructor.name} is "clientAware" or "projectAware" but argument "clientOrProjectId" was NOT provided.`);
    }
  }

  private assertStorageInitialized(clientOrProjectId?: IdType) {
    if (!this.isStorageInitialized(clientOrProjectId)) {
      throw new Error(
        `Local storage for data service with storageKey "${this.storageKey}" and clientId or projectId ${clientOrProjectId} is not yet initialized. Initial sync is probably not yet ready.`
      );
    }
  }

  public isStorageInitialized(clientOrProjectId?: IdType): boolean {
    const clientOrProjectIdOrNull = clientOrProjectId || null;
    return this.storageInitializedSubject.getValue().has(clientOrProjectIdOrNull);
  }

  public isStorageInitialized$(clientOrProjectId?: IdType): Observable<boolean> {
    return this.storageInitializedObservable.pipe(map((storageInitialize) => storageInitialize.has(clientOrProjectId)));
  }

  protected processServerData(data: any): Array<T> {
    return data as Array<T>;
  }

  protected async insertOrUpdate(valueOrArray: T | Array<T>, clientOrProjectId?: IdType): Promise<Array<T>> {
    const values: Array<T> = _.isArray(valueOrArray) ? (valueOrArray as Array<T>) : new Array<T>(valueOrArray as T);
    const newValues: Array<T> = values.filter((value) => value.id === null || value.id === undefined);
    const updateValues: Array<T> = values.filter((value) => value.id !== null && value.id !== undefined);
    return (await this.insertInternal(newValues, clientOrProjectId)).concat(await this.updateInternal(updateValues, clientOrProjectId));
  }

  private insertOne(value: T, storageData: Array<T>, options?: DataServiceInsertOptions): T | undefined {
    if (!value.id) {
      value.id = uuidv4();
    }
    const index = _.findIndex(storageData, (data) => data.id === value.id);
    if (index !== -1) {
      if (options?.dismissDuplicateKeys) {
        return;
      }
      throw new Error(`Error inserting value with id ${value.id} as it already exists in the storage.`);
    }
    storageData.push(value);
    return value;
  }

  private async runInSynchronizedStorageAccess<R>(functionToCall: () => Promise<R>): Promise<R> {
    return await AbstractDataService.runInSynchronizedStorageAccess(functionToCall);
  }

  protected valueArrayOrFunctionAsArray(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), storageData: Array<T>): Array<T> {
    const valueOrArray = typeof valueArrayOrFunction === 'function' ? valueArrayOrFunction(storageData) || [] : valueArrayOrFunction;
    const values: Array<T> = _.isArray(valueOrArray) ? (valueOrArray as Array<T>) : new Array<T>(valueOrArray as T);
    return values.filter((v) => v !== undefined && v !== null);
  }

  protected valueOrArrayAsIdString(valueOrArray: T | Array<T>): string {
    const values: Array<T> = _.isArray(valueOrArray) ? (valueOrArray as Array<T>) : [valueOrArray as T];
    return values.map((value) => value?.id).join();
  }

  private getEnsureStoredOptionsOrDefault(ensureDataStored: boolean): Partial<StorageMutationOptions> {
    if (ensureDataStored) {
      return {
        ensureStored: true,
        immediate: true,
      };
    }

    return {
      ensureStored: false,
      immediate: false,
    };
  }

  protected async assertInsertUpdateDeleteInternalInSynchronized(
    storageData: Array<T>,
    changes: {
      inserts?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      insertOptions?: DataServiceInsertOptions;
      updates?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      deletes?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      deleteOptions?: DataServiceDeleteOptions;
    },
    clientOrProjectId?: IdType
  ): Promise<void> {
    const inserts = this.valueArrayOrFunctionAsArray(changes.inserts ?? [], storageData);
    const updates = this.valueArrayOrFunctionAsArray(changes.updates ?? [], storageData);
    const deletes = this.valueArrayOrFunctionAsArray(changes.deletes ?? [], storageData);

    await Promise.all([
      this.assertInsertInternalInSynchronized(storageData, inserts, clientOrProjectId),
      this.assertUpdateInternalInSynchronized(storageData, updates, clientOrProjectId),
      this.assertDeleteInternalInSynchronized(storageData, deletes, clientOrProjectId),
    ]);
  }

  protected async assertInsertInternalInSynchronized(
    storageData: Array<T>,
    valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined),
    clientOrProjectId?: IdType,
    options?: DataServiceInsertOptions
  ): Promise<void> {
    const valueArray = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);

    await Promise.all(valueArray.map((obj) => this.assertInsertedOrUpdatedObjectIntegrity(obj, valueArray)));
    await this.assertInsertedOrUpdatedObjectsUniqueness(valueArray, storageData);
    await this.assertIsValid(valueArray);
  }

  protected async assertUpdateInternalInSynchronized(
    storageData: Array<T>,
    valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined),
    clientOrProjectId?: IdType
  ): Promise<void> {
    const valueArray = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);

    await Promise.all(valueArray.map((obj) => this.assertInsertedOrUpdatedObjectIntegrity(obj, valueArray)));
    await this.assertInsertedOrUpdatedObjectsUniqueness(valueArray, storageData);
    await this.assertIsValid(valueArray);
  }

  protected async assertDeleteInternalInSynchronized(
    storageData: Array<T>,
    valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined),
    clientOrProjectId?: IdType,
    options?: DataServiceDeleteOptions
  ): Promise<void> {
    const valueArray = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);
    const valuesById = _.keyBy(valueArray, 'id');

    await Promise.all(valueArray.map((obj) => this.assertDeletedObjectIntegrity(obj, valueArray, valuesById)));
  }

  protected async insertUpdateDeleteInternal(
    changes: {
      inserts?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      insertOptions?: DataServiceInsertOptions;
      updates?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      deletes?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
      deleteOptions?: DataServiceDeleteOptions;
    },
    clientOrProjectId?: IdType
  ): Promise<Array<T>> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    this.assertStorageInitialized(clientOrProjectId);

    this.loggingService.debug(this.logSource, `insertUpdateDeleteInternal value(s).`);
    return await this.runInSynchronizedStorageAccess(async () => {
      const storageData = await this.getStorageData(clientOrProjectId);
      await this.assertInsertUpdateDeleteInternalInSynchronized(storageData, changes, clientOrProjectId);

      let valuesInserted: Array<T>;
      let valuesOldValuesInsert: Array<{value: T; oldValue: T}>;
      let valuesOldValuesDelete: Array<{value: T; oldValue: T}>;
      if (changes.inserts) {
        valuesInserted = this.insertInternalPart1(changes.inserts, storageData, changes.insertOptions);
      }
      if (changes.updates) {
        valuesOldValuesInsert = this.updateInternalPart1(changes.updates, storageData);
      }
      if (changes.deletes) {
        valuesOldValuesDelete = this.deleteInternalPart1(changes.deletes, storageData, changes.deleteOptions);
      }

      await this.setStorageData(storageData, clientOrProjectId);

      const changedAt = new Date().toISOString();
      await this.updateLocalChanges((localChanges) => {
        if (changes.inserts && valuesInserted) {
          this.updateLocalChangesAfterInsert(changedAt, localChanges, valuesInserted, changes.insertOptions);
        }
        if (changes.updates && valuesOldValuesInsert) {
          this.updateLocalChangesAfterUpdate(changedAt, localChanges, valuesOldValuesInsert);
        }
        if (changes.deletes && valuesOldValuesDelete) {
          this.updateLocalChangesAfterDelete(changedAt, localChanges, valuesOldValuesDelete, changes.deleteOptions);
        }
      }, clientOrProjectId);

      return valuesInserted;
    });
  }

  private insertInternalPart1(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), storageData: Array<T>, options?: DataServiceInsertOptions): Array<T> {
    const values: Array<T> = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);
    const valuesInserted = new Array<T>();
    values.forEach((value) => {
      const valueInserted = this.insertOne(value, storageData, options);
      if (valueInserted) {
        valuesInserted.push(valueInserted);
      }
    });
    return valuesInserted;
  }

  protected async insertInternal(
    valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined),
    clientOrProjectId?: IdType,
    options?: DataServiceInsertOptions
  ): Promise<Array<T>> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    this.assertStorageInitialized(clientOrProjectId);

    this.loggingService.debug(this.logSource, `Inserting value(s).`);
    return await this.runInSynchronizedStorageAccess(async () => {
      const storageData = await this.getStorageData(clientOrProjectId);
      await this.assertInsertInternalInSynchronized(storageData, valueArrayOrFunction, clientOrProjectId, options);
      const valuesInserted = this.insertInternalPart1(valueArrayOrFunction, storageData, options);
      if (!valuesInserted.length) {
        return [];
      }
      await this.setStorageData(storageData, clientOrProjectId);

      const changedAt = new Date().toISOString();
      await this.updateLocalChanges((localChanges) => this.updateLocalChangesAfterInsert(changedAt, localChanges, valuesInserted, options), clientOrProjectId);
      this.loggingService.debug(this.logSource, `Inserted ${valuesInserted.length} values, the first one with id ${_.head(valuesInserted)?.id} and changedAt "${changedAt}"`);

      return valuesInserted;
    });
  }

  private updateLocalChangesAfterInsert(changedAt: string, localChanges: LocalChanges<T>, valuesInserted: Array<T>, options?: DataServiceInsertOptions) {
    valuesInserted.forEach((value) => {
      if (options?.localChangesMappingMode) {
        _.remove(localChanges.inserted, (localChange) => localChange.value.id === value.id);
        _.remove(localChanges.updated, (localChange) => localChange.value.id === value.id);
        const localChangesDeleted = localChanges.deleted.filter((localChange) => localChange.value.id === value.id);
        if (localChangesDeleted.length) {
          _.remove(localChanges.deleted, (localChange) => localChangesDeleted.some((localChangeDeleted) => localChange.value.id === localChangeDeleted.value.id));
          // if the value was deleted before, and is now being inserted, the original value has bee restored and there should be no change.
        } else {
          localChanges.inserted.push({changedAt, value});
        }
      } else {
        localChanges.inserted.push({changedAt, value});
      }
    });
  }

  private updateLocalChangesAfterUpdate(changedAt: string, localChanges: LocalChanges<T>, valueOldValues: Array<{value: T; oldValue: T}>): void {
    valueOldValues.forEach((valueOldValue) =>
      localChanges.updated.push({
        changedAt,
        value: valueOldValue.value,
        oldValue: valueOldValue.oldValue,
        originalValue: this.findOriginalValueFromLocalChanges(localChanges, valueOldValue.value, valueOldValue.oldValue),
      })
    );
  }

  private updateLocalChangesAfterDelete(changedAt: string, localChanges: LocalChanges<T>, valueOldValues: Array<{value: T; oldValue: T}>, options?: DataServiceDeleteOptions): void {
    valueOldValues.forEach((valueOldValue) => {
      const originalValue = this.findOriginalValueFromLocalChanges(localChanges, valueOldValue.value, valueOldValue.oldValue);
      if (options?.localChangesMappingMode) {
        _.remove(localChanges.updated, (localChange) => localChange.value.id === valueOldValue.value.id);
        _.remove(localChanges.deleted, (localChange) => localChange.value.id === valueOldValue.value.id);
        const localChangesInserted = localChanges.inserted.filter((localChange) => localChange.value.id === valueOldValue.value.id);
        if (localChangesInserted.length) {
          _.remove(localChanges.inserted, (localChange) => localChangesInserted.some((localChangeInserted) => localChange.value.id === localChangeInserted.value.id));
          // if the value was inserted before, and is now being deleted, the original value has bee restored and there should be no change.
        } else {
          localChanges.deleted.push({
            changedAt,
            value: valueOldValue.value,
            oldValue: valueOldValue.oldValue,
            originalValue,
          });
        }
      } else {
        localChanges.deleted.push({
          changedAt,
          value: valueOldValue.value,
          oldValue: valueOldValue.oldValue,
          originalValue,
        });
      }
    });
  }

  private updateOne(value: T, storageData: Array<T>): {value: T; oldValue: T} {
    if (!value.id) {
      throw new Error(`Error updating as value does not have an id provided.`);
    }
    const index = _.findIndex(storageData, (data) => data.id === value.id);
    if (index === -1) {
      throw new Error(`Error updating value with id ${value.id} as it does not exist in the storage.`);
    }
    const oldValue = storageData[index];
    storageData[index] = value;
    return {value, oldValue};
  }

  private updateInternalPart1(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), storageData: Array<T>): Array<{value: T; oldValue: T}> {
    const values: Array<T> = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);
    const valueOldValues = new Array<{value: T; oldValue: T}>();
    values.forEach((value) => valueOldValues.push(this.updateOne(value, storageData)));
    return valueOldValues;
  }

  private deleteInternalPart1(
    valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined),
    storageData: Array<T>,
    options?: DataServiceDeleteOptions
  ): Array<{value: T; oldValue: T}> {
    const values: Array<T> = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);
    const valueOldValues = new Array<{value: T; oldValue: T}>();
    values.forEach((value) => {
      const valueOldValue = this.deleteOne(value, storageData, options);
      if (valueOldValue) {
        valueOldValues.push(valueOldValue);
      }
    });
    return valueOldValues;
  }

  protected async updateInternal(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), clientOrProjectId?: IdType): Promise<Array<T>> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    this.assertStorageInitialized(clientOrProjectId);

    return await this.runInSynchronizedStorageAccess(async () => {
      const storageData = await this.getStorageData(clientOrProjectId);
      await this.assertUpdateInternalInSynchronized(storageData, valueArrayOrFunction, clientOrProjectId);
      const values: Array<T> = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);
      const valueOldValues = this.updateInternalPart1(valueArrayOrFunction, storageData);
      await this.setStorageData(storageData, clientOrProjectId);

      const changedAt = new Date().toISOString();
      await this.updateLocalChanges((localChanges) => this.updateLocalChangesAfterUpdate(changedAt, localChanges, valueOldValues), clientOrProjectId);

      this.loggingService.debug(this.logSource, `Updated ${values.length} values, the first one with id ${_.head(values)?.id} and changedAt "${changedAt}"`);

      return values;
    });
  }

  private deleteOne(value: T, storageData: Array<T>, options?: DataServiceDeleteOptions): {value: T; oldValue: T} | undefined {
    if (!value.id) {
      throw new Error(`Error deleting as value does not have an id provided.`);
    }
    const index = _.findIndex(storageData, (data) => data.id === value.id);
    if (index === -1) {
      if (options?.dismissNotExistingValue) {
        return;
      }
      throw new Error(`Error deleting value with id ${value.id} as it does not exist in the storage.`);
    }
    const oldValue = storageData[index];
    storageData.splice(index, 1);
    this.loggingService.info(this.logSource, `Data object with id ${value.id} of storageKey ${this.storageKey} deleted.`);

    return {value, oldValue};
  }

  protected async deleteInternal(
    valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined),
    clientOrProjectId?: IdType,
    options?: DataServiceDeleteOptions
  ): Promise<void> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    this.assertStorageInitialized(clientOrProjectId);

    return await this.runInSynchronizedStorageAccess(async () => {
      const storageData = await this.getStorageData(clientOrProjectId);
      await this.assertDeleteInternalInSynchronized(storageData, valueArrayOrFunction, clientOrProjectId, options);
      const values: Array<T> = this.valueArrayOrFunctionAsArray(valueArrayOrFunction, storageData);
      const valueOldValues = this.deleteInternalPart1(valueArrayOrFunction, storageData, options);
      if (!valueOldValues.length) {
        return;
      }
      await this.setStorageData(storageData, clientOrProjectId);

      const changedAt = new Date().toISOString();
      await this.updateLocalChanges((localChanges) => {
        this.updateLocalChangesAfterDelete(changedAt, localChanges, valueOldValues);
      }, clientOrProjectId);

      this.loggingService.debug(this.logSource, `Deleted ${values.length} values, the first one with id ${_.head(values)?.id} and changedAt "${changedAt}"`);
    });
  }

  private createLocalChangesFilteredById(localChanges: LocalChanges<T>, id: IdType): LocalChanges<T> {
    if (!localChanges.hasChanges()) {
      return localChanges;
    }
    const newLocalChanges = new LocalChanges<T>();
    const filterLocalChangeById = (value) => value.value.id === id;

    newLocalChanges.inserted = localChanges.inserted.filter(filterLocalChangeById);
    newLocalChanges.updated = localChanges.updated.filter(filterLocalChangeById);
    newLocalChanges.deleted = localChanges.deleted.filter(filterLocalChangeById);

    return newLocalChanges;
  }

  public getLocalChangesById(id: IdType): Observable<LocalChanges<T>> {
    return this.localChanges.pipe(map((localChanges) => this.createLocalChangesFilteredById(localChanges, id)));
  }

  protected getStorageKey(clientOrProjectId?: IdType): string {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    if (this.clientOrProjectAware) {
      return this.storageKey + STORAGE_KEY_PROJECT_SEPARATOR + clientOrProjectId;
    } else {
      return this.storageKey;
    }
  }

  protected getStorageKeyLocalChanges(clientOrProjectId?: IdType): string {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    if (this.clientOrProjectAware) {
      return this.storageKey + STORAGE_KEY_LOCAL_CHANGES_SUFFIX + STORAGE_KEY_PROJECT_SEPARATOR + clientOrProjectId;
    } else {
      return this.storageKey + STORAGE_KEY_LOCAL_CHANGES_SUFFIX;
    }
  }

  protected isStorageKey(storageKey: string): boolean {
    return this.clientOrProjectAware ? storageKey.startsWith(this.storageKey + STORAGE_KEY_PROJECT_SEPARATOR) : storageKey === this.storageKey;
  }

  protected isStorageKeyLocalChanges(storageKey: string): boolean {
    return this.clientOrProjectAware
      ? storageKey.startsWith(this.storageKey + STORAGE_KEY_LOCAL_CHANGES_SUFFIX + STORAGE_KEY_PROJECT_SEPARATOR)
      : storageKey === this.storageKey + STORAGE_KEY_LOCAL_CHANGES_SUFFIX;
  }

  private getRestEndpointUrl(clientOrProjectId?: IdType) {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    if (this.projectAware && clientOrProjectId) {
      return `${this.restEndpointUrl}?projectId=${clientOrProjectId}`;
    }
    if (this.clientAware && clientOrProjectId) {
      return `${this.restEndpointUrl}?clientId=${clientOrProjectId}`;
    }
    return this.restEndpointUrl;
  }

  private async getStorageDataOrNull(clientOrProjectId?: IdType): Promise<Array<T> | null> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    const data: Array<T> | null = await this.storage.get(this.getStorageKey(clientOrProjectId));
    this.didReadDataFromStorageOnce();
    return data;
  }

  public async getStorageData(clientOrProjectId?: IdType): Promise<Array<T>> {
    return (await this.getStorageDataOrNull(clientOrProjectId)) || [];
  }

  public async getDataFromMemoryOrStorageOrNull(clientOrProjectId?: IdType): Promise<Array<T> | null> {
    if (this.isStorageInitialized(clientOrProjectId)) {
      const dataInMemory = this.getDataFromMemory(clientOrProjectId);
      if (dataInMemory) {
        return dataInMemory;
      }
    }
    return this.getStorageDataOrNull(clientOrProjectId);
  }

  public async getDataFromMemoryOrStorage(clientOrProjectId?: IdType): Promise<Array<T>> {
    return (await this.getDataFromMemoryOrStorageOrNull(clientOrProjectId)) || [];
  }

  public abstract getDataFromMemory(clientOrProjectId?: IdType): Array<T> | undefined;

  private async setStorageData(dataToStore: Array<T>, clientOrProjectId?: IdType, ensureDataStored: boolean = true): Promise<Array<T>> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    const startTime = new Date().getTime();
    this.assertAuthenticated();
    const value: Array<T> = await this.storage.set(this.getStorageKey(clientOrProjectId), dataToStore, this.getEnsureStoredOptionsOrDefault(ensureDataStored));
    if (this.loggingService.isDebugEnabled() && value?.length) {
      const idsShortened = value.map((item) => (item?.id && typeof item.id === 'string' && item.id.length > 5 ? item.id.substring(item.id.length - 5) : item));
      this.loggingService.debug(this.logSource, `setStorageData (only the storage part) took ${new Date().getTime() - startTime} ms. IDs(short)=${idsShortened.join(',')}`);
    }
    await this.storageDataChanged(dataToStore, clientOrProjectId);
    return value;
  }

  public async setStorageDataPublic(dataToStore: Array<T>, clientOrProjectId?: IdType, ensureDataStored: boolean = false): Promise<Array<T>> {
    if (dataToStore.length === 0) {
      const storageData = await this.getStorageDataOrNull(clientOrProjectId);
      if (storageData === null || storageData.length > 0) {
        const ret = await this.setStorageData(dataToStore, clientOrProjectId, ensureDataStored);
        this.setStorageInitialized(clientOrProjectId);
        return ret;
      } else {
        return storageData;
      }
    } else {
      const ret = await this.setStorageData(dataToStore, clientOrProjectId, ensureDataStored);
      this.setStorageInitialized(clientOrProjectId);
      return ret;
    }
  }

  private setStorageInitialized(clientOrProjectId?: IdType): boolean {
    this.loggingService.debug(this.logSource, `setStorageInitialized(${clientOrProjectId}) called`);
    const clientOrProjectIdOrNull = clientOrProjectId || null;
    const value = this.storageInitializedSubject.getValue();
    if (!value.has(clientOrProjectIdOrNull)) {
      value.add(clientOrProjectIdOrNull);
      this.storageInitializedSubject.next(value);
      return true;
    }
    return false;
  }

  protected unsetStorageInitialized(clientOrProjectId?: IdType): boolean {
    const clientOrProjectIdOrNull = clientOrProjectId || null;
    const value = this.storageInitializedSubject.getValue();
    if (value.has(clientOrProjectIdOrNull)) {
      value.delete(clientOrProjectIdOrNull);
      this.storageInitializedSubject.next(value);
      return true;
    }
    return false;
  }

  protected clearStorageInitialized(): boolean {
    const value = this.storageInitializedSubject.getValue();
    if (value.size) {
      value.clear();
      this.storageInitializedSubject.next(value);
      return true;
    }
    return false;
  }

  protected abstract storageDataChanged(data: Array<T>, clientOrProjectId?: IdType): Promise<void>;

  public async updateLocalChangesInSynchronizedStorageAccess(changeFunction: (localChanges: LocalChanges<T>) => void, clientOrProjectId?: IdType): Promise<void> {
    return await this.runInSynchronizedStorageAccess(async () => {
      return this.updateLocalChanges(changeFunction, clientOrProjectId);
    });
  }

  public async updateLocalChanges(localChangesOrChangeFunction: ((localChanges: LocalChanges<T>) => void) | LocalChanges<T>, clientOrProjectId?: IdType): Promise<void> {
    let localChanges: LocalChanges<T>;
    if (typeof localChangesOrChangeFunction === 'function') {
      localChanges = (await this.getLocalChangesFromStore(clientOrProjectId)) || new LocalChanges<T>();
      localChangesOrChangeFunction(localChanges);
    } else {
      localChanges = localChangesOrChangeFunction as LocalChanges<T>;
    }
    await this.setLocalChangesToStore(localChanges, clientOrProjectId);
  }

  private async getLocalChangesFromStore(clientOrProjectId?: IdType): Promise<LocalChanges<T> | null> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    return await this.storage.get(this.getStorageKeyLocalChanges(clientOrProjectId));
  }

  private async setLocalChangesToStore(localChanges: LocalChanges<T>, clientOrProjectId?: IdType, ensureDataStored: boolean = true): Promise<void> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    this.assertAuthenticated();
    await this.storage.set(this.getStorageKeyLocalChanges(clientOrProjectId), localChanges, this.getEnsureStoredOptionsOrDefault(ensureDataStored));
    this.localChangesSubject.next(localChanges);
    const localChangesByClientOrProject = this.localChangesByClientOrProjectSubject.getValue();
    localChangesByClientOrProject.set(clientOrProjectId, localChanges);
    this.localChangesByClientOrProjectSubject.next(localChangesByClientOrProject);
  }

  private extractClientOrProjectIdFromStorageKey(storageKey: string): IdType | undefined {
    if (!this.clientOrProjectAware) {
      return undefined;
    }
    const index = storageKey.lastIndexOf('_');
    if (index === -1) {
      throw new Error(`StorageKey "${storageKey}" is supposed to have a suffix with a projectId but no "_" was found.`);
    }
    return storageKey.substring(index + 1);
  }

  private async getLocalChangesByClientOrProjectFromStore(): Promise<Map<IdType | undefined, LocalChanges<T>>> {
    const keys = _.filter(await this.storage.keys(), (key) => this.isStorageKeyLocalChanges(key));
    const localChangesByProjectId = new Map<IdType | undefined, LocalChanges<T>>();
    for (const key of keys) {
      const clientOrProjectId = this.extractClientOrProjectIdFromStorageKey(key);
      const localChanges: LocalChanges<T> = (await this.storage.get(key)) || new LocalChanges<T>();
      localChangesByProjectId.set(clientOrProjectId, localChanges);
    }

    return localChangesByProjectId;
  }

  public findOriginalValueFromLocalChanges(localChanges: LocalChanges<T>, value: T, defaultValue?: T): T | undefined {
    let oldestLocalChange = localChanges.updated.find((firstLocalChange) => firstLocalChange.value.id === value.id);
    if (!oldestLocalChange) {
      oldestLocalChange = localChanges.deleted.find((firstLocalChange) => firstLocalChange.value.id === value.id);
    }
    return oldestLocalChange?.originalValue || defaultValue || undefined;
  }

  public async deleteLocalChangesBeforeAndUpdateChangedAtInSynchronizedStorageAccess(before: Date, newChangedAt: Date | string, clientOrProjectId?: IdType): Promise<Array<T>> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    const newChangedAtAsString = _.isString(newChangedAt) ? newChangedAt : (newChangedAt as Date).toISOString();
    return await this.runInSynchronizedStorageAccess(async () => {
      const localChanges = await this.getLocalChangesFromStore(clientOrProjectId);
      if (!localChanges?.inserted?.length && !localChanges?.updated?.length && !localChanges?.deleted?.length) {
        return [];
      }
      const filterLocalChangesBefore = (localChange) => new Date(localChange.changedAt).getTime() <= before.getTime();
      const filterLocalChangesAfter = (localChange) => new Date(localChange.changedAt).getTime() > before.getTime();

      const idsLocalChangesBefore = localChanges.inserted
        .filter(filterLocalChangesBefore)
        .concat(localChanges.updated.filter(filterLocalChangesBefore))
        .map((localChange) => localChange.value.id);
      const itemsChanged = await this.updatedChangedAt(idsLocalChangesBefore, newChangedAt, clientOrProjectId);

      const filterChangedLocalChanges = (localChange: LocalChange<T>) => idsLocalChangesBefore.find((id) => id === localChange.value.id);
      const updateValueWithChangedAt = (localChange: LocalChange<T>) => {
        if ('changedAt' in localChange.value) {
          // eslint-disable-next-line  @typescript-eslint/dot-notation
          localChange.value['changedAt'] = newChangedAtAsString;
        }
      };

      if (this.loggingService.isDebugEnabled()) {
        localChanges.inserted
          .filter((localChange) => !filterLocalChangesAfter(localChange))
          .forEach((localChange) => this.loggingService.debug(this.logSource, `delete local change (inserted) with id ${localChange.value.id} and changedAt ${localChange.changedAt}`));
        localChanges.updated
          .filter((localChange) => !filterLocalChangesAfter(localChange))
          .forEach((localChange) => this.loggingService.debug(this.logSource, `delete local change (updated) with id ${localChange.value.id} and changedAt ${localChange.changedAt}`));
        localChanges.deleted
          .filter((localChange) => !filterLocalChangesAfter(localChange))
          .forEach((localChange) => this.loggingService.debug(this.logSource, `delete local change (deleted) with id ${localChange.value.id} and changedAt ${localChange.changedAt}`));
      }

      localChanges.inserted = localChanges.inserted.filter(filterLocalChangesAfter);
      localChanges.updated = localChanges.updated.filter(filterLocalChangesAfter);
      localChanges.deleted = localChanges.deleted.filter(filterLocalChangesAfter);
      localChanges.inserted.filter(filterChangedLocalChanges).forEach(updateValueWithChangedAt);
      localChanges.updated.filter(filterChangedLocalChanges).forEach(updateValueWithChangedAt);
      localChanges.deleted.filter(filterChangedLocalChanges).forEach(updateValueWithChangedAt);
      await this.setLocalChangesToStore(localChanges, clientOrProjectId);
      return itemsChanged;
    });
  }

  public async deleteLocalChangesWithIdsInSynchronizedStorageAccess(ids: Array<IdType>, clientOrProjectId?: IdType): Promise<boolean> {
    this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
    return await this.runInSynchronizedStorageAccess(async () => {
      const localChanges = await this.getLocalChangesFromStore(clientOrProjectId);
      if (!localChanges) {
        return false;
      }
      this.loggingService.debug(this.logSource, `Deleting localChanges with ids ${ids}`);
      const insertedCount = localChanges.inserted.length;
      const updatedCount = localChanges.updated.length;
      const deletedCount = localChanges.deleted.length;
      localChanges.inserted = localChanges.inserted.filter((localChange) => !ids.find((id) => id === localChange.value.id));
      localChanges.updated = localChanges.updated.filter((localChange) => !ids.find((id) => id === localChange.value.id));
      localChanges.deleted = localChanges.deleted.filter((localChange) => !ids.find((id) => id === localChange.value.id));
      await this.setLocalChangesToStore(localChanges, clientOrProjectId);

      return insertedCount !== localChanges.inserted.length || updatedCount !== localChanges.updated.length || deletedCount !== localChanges.deleted.length;
    });
  }

  protected async updatedChangedAt(
    ids: Array<IdType>,
    newChangedAt: Date | string,
    clientOrProjectId?: IdType,
    changedCallback?: (changedItem: T, oldChangedAt: Date | string) => Promise<void>
  ): Promise<Array<T>> {
    if (ids.length === 0) {
      return [];
    }
    const items = await this.getStorageData(clientOrProjectId);
    const newChangedAtAsString = _.isString(newChangedAt) ? newChangedAt : (newChangedAt as Date).toISOString();
    let changed = false;
    const itemsChanged = new Array<T>();
    for (const id of ids) {
      const changedItem = items.find((item) => item.id === id);
      if (changedItem) {
        if ('changedAt' in changedItem) {
          const oldChangedAt = _.get(changedItem, 'changedAt') as Date | string;
          _.set(changedItem, 'changedAt', newChangedAtAsString);
          itemsChanged.push(changedItem);
          if (changedCallback !== undefined && changedCallback !== null) {
            await changedCallback(changedItem, oldChangedAt);
          }
          changed = true;
        }
      } else {
        this.loggingService.warn(this.logSource, `Object with id ${id} not found in storage "${this.getStorageKey(clientOrProjectId)}".`);
      }
    }
    if (changed) {
      await this.setStorageData(items, clientOrProjectId);
    }
    return itemsChanged;
  }

  private toLatestLocalChangeById(localChanges: Array<LocalChange<T>>): Map<IdType, LocalChange<T>> {
    const valuesById = new Map<IdType, LocalChange<T>>();
    localChanges.forEach((localChange) => valuesById.set(localChange.value.id, localChange));
    return valuesById;
  }

  private localChangesFilteredAndOrdered(data: Array<T>, localChanges: Map<IdType, LocalChange<T>>): Array<T> {
    const filtered = data.filter((value) => !!localChanges.get(value.id));
    return _.sortBy(filtered, (value) => localChanges.get(value.id).changedAt);
  }

  private localDeleteChangesOrdered(localChanges: Map<IdType, LocalChange<T>>): Array<T> {
    const values = new Array<T>();

    for (const localChange of localChanges.values()) {
      values.push(localChange.value);
    }
    return _.sortBy(values, (value) => localChanges.get(value.id).changedAt);
  }

  public async getLatestLocalChanges(clientOrProjectId?: IdType): Promise<LocalChangesData<T>> {
    const localChanges = this.localChangesByClientOrProjectSubject.value.get(clientOrProjectId) || (await this.getLocalChangesFromStore(clientOrProjectId));
    if (!localChanges) {
      return {insert: [], update: [], delete: [], localChangesInsertById: new Map(), localChangesUpdateById: new Map(), localChangesDeleteById: new Map()};
    }
    const data = await this.getDataFromMemoryOrStorage(clientOrProjectId);

    const insertedById = this.toLatestLocalChangeById(localChanges.inserted);
    const updatedById = this.toLatestLocalChangeById(localChanges.updated);
    const deletedById = this.toLatestLocalChangeById(localChanges.deleted);

    deletedById.forEach((localChange, id) => {
      insertedById.delete(id);
      updatedById.delete(id);
    });
    insertedById.forEach((localChange, id) => updatedById.delete(id));

    return {
      insert: this.localChangesFilteredAndOrdered(data, insertedById),
      update: this.localChangesFilteredAndOrdered(data, updatedById),
      delete: this.localDeleteChangesOrdered(deletedById),
      localChangesInsertById: this.toLatestLocalChangeById(localChanges.inserted),
      localChangesUpdateById: this.toLatestLocalChangeById(localChanges.updated),
      localChangesDeleteById: this.toLatestLocalChangeById(localChanges.deleted),
    };
  }

  protected async removeStorageData(clientOrProjectId?: IdType, ensureDataStored: boolean = true): Promise<any> {
    try {
      this.assertProjectIdProvidedForProjectAware(clientOrProjectId);
      this.loggingService.debug(this.logSource, `removeStorageData - before storage.remove(${this.storageKey})`);
      await this.storage.remove(this.getStorageKey(clientOrProjectId), this.getEnsureStoredOptionsOrDefault(ensureDataStored));
      await this.storage.remove(this.getStorageKeyLocalChanges(clientOrProjectId), this.getEnsureStoredOptionsOrDefault(ensureDataStored));
      this.unsetStorageInitialized(clientOrProjectId);
      this.loggingService.debug(this.logSource, `removeStorageData - after storage.remove(${this.storageKey})`);
    } catch (err) {
      this.loggingService.error(this.logSource, `Error in removeStorageData. ${err.message}`);
      throw err;
    }
  }

  protected assertAuthenticated() {
    if (!this.isAuthenticated) {
      throw new Error('User not authenticated (isAuthenticated is false or undefined)');
    }
  }

  private async getDestinationData(dataDependency: DataDependency): Promise<IdAware[][]> {
    const awareness = IS_AWARE.get(dataDependency.destinationStorageKey) ?? 'NON_AWARE';

    if (awareness !== 'NON_AWARE') {
      // Client/project aware objects are grouped by client/project id; current implementation gathers **all** groups and checks if the source<->destination
      // exists in at least one group.
      return Object.entries(await this.storage.getAllBeginsWith(`${dataDependency.destinationStorageKey}${STORAGE_KEY_PROJECT_SEPARATOR}`))
        .map(([key, value]: [string, any[]]) => (key.includes(STORAGE_KEY_LOCAL_CHANGES_SUFFIX) ? null : value))
        .filter((v) => v);
    }

    // Destination object is not aware, therefore no grouping occurs; single key is gathered.
    return [(await this.storage.get(dataDependency.destinationStorageKey)) ?? []];
  }
  private async assertNoDestinationToSourceObjectIntegrityInDependency(dataDependency: DataDependency, objectToCheck: T, allObjects: T[], allObjectsById: Record<IdType, T>): Promise<void> {
    const data = await this.getDestinationData(dataDependency);

    let objectsToCheck = data;
    if (dataDependency.destinationStorageKey === this.storageKey) {
      objectsToCheck = data.map((bucket) => bucket.filter((item) => !allObjectsById[item.id]));
    }

    if (objectsToCheck.some((items) => doesObjectHasAnyDataDependencyReference(dataDependency, objectToCheck, items))) {
      const boundTo = _.flattenDeep(objectsToCheck.map((items) => getAllObjectDataDependencyReferences(dataDependency, objectToCheck, items)).filter((items) => items.length > 0)) as IdAware[];

      await this.integrityResolverService.handleDependencyStillBound(this.storageKey, objectToCheck, allObjects, dataDependency, boundTo);
    }
  }

  private async assertSourceToDestinationObjectIntegrityInDependency(dataDependency: DataDependency, objectToCheck: T, allObjects: T[]): Promise<void> {
    if (dataDependency.optional && (objectToCheck[dataDependency.sourceKeyPath] === null || objectToCheck[dataDependency.sourceKeyPath] === undefined)) {
      return;
    }

    const data = await this.getDestinationData(dataDependency);

    if (!data.some((items) => doesObjectHasAnyDataDependencyReference(dataDependency, objectToCheck, items))) {
      if (dataDependency.destinationStorageKey === this.storageKey) {
        // Self reference; might contain dependency in current insert batch
        if (doesObjectHasAnyDataDependencyReference(dataDependency, objectToCheck, allObjects)) {
          return;
        }
      }

      // The id value from sourceKeyPath of object to check was not found in destinationKeyPath, in none of the objects in destinationStorageKey
      await this.integrityResolverService.handleMissingDependency(this.storageKey, objectToCheck, allObjects, dataDependency);
    }
  }

  private async assertInsertedOrUpdatedObjectsUniqueness(objectsToCheck: T[], allObjects: T[]): Promise<void> {
    const duplicated = checkObjectsUniqueConstraint(objectsToCheck, allObjects, syncKeyColumnSetMap[STORAGE_KEY_TO_RAW_SYNC_KEYS[this.storageKey]]);
    if (duplicated) {
      await this.integrityResolverService.handleUniqueConstraint(this.storageKey, objectsToCheck, allObjects, duplicated);
    }
  }

  private async assertInsertedOrUpdatedObjectIntegrity(objectToCheck: T, allObjects: T[]): Promise<void> {
    const dataDependencies = DATA_DEPENDENCIES.get(this.storageKey);
    if (!dataDependencies || dataDependencies.length === 0) {
      return;
    }

    await Promise.all(
      dataDependencies
        .filter(({direction}) => direction === 'SOURCE_TO_DESTINATION')
        .map((dataDependency) => this.assertSourceToDestinationObjectIntegrityInDependency(dataDependency, objectToCheck, allObjects))
    );
  }

  private async assertDeletedObjectIntegrity(obj: T, allObjects: T[], allObjectsById: Record<IdType, T>): Promise<void> {
    const dataDependencies = DATA_DEPENDENCIES.get(this.storageKey);
    if (!dataDependencies || dataDependencies.length === 0) {
      return;
    }

    await Promise.all(
      dataDependencies
        .filter(({direction}) => direction === 'DESTINATION_TO_SOURCE')
        .map((dataDependency) => this.assertNoDestinationToSourceObjectIntegrityInDependency(dataDependency, obj, allObjects, allObjectsById))
    );
  }

  private async assertIsValid(values: Array<T>) {
    for (const value of values) {
      const {valid, message} = await this.isValid(value);
      if (!valid) {
        throw new Error(message);
      }
    }
  }

  /**
   * This method can be overridden in sub-class to implement custom validation.
   */
  protected async isValid(obj: T): Promise<{valid: true; message?: string} | {valid: false; message: string}> {
    return {valid: true};
  }

  protected validateMandatoryFields(obj: T, ...mandatoryFields: Array<keyof T>): Array<keyof T> {
    if (!obj) {
      return mandatoryFields;
    }
    const missingKeys = new Array<keyof T>();
    for (const key of mandatoryFields) {
      if (obj[key] === undefined || obj[key] === null) {
        missingKeys.push(key);
      }
    }
    return missingKeys;
  }
}
