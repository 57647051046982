import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UserDevicesModalComponent} from 'src/app/components/common/user-devices/user-devices-modal/user-devices-modal.component';
import {UserDeviceService} from './user-device.service';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';
import {PosthogService} from '../posthog/posthog.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceLimitService {
  private deviceModalOpen = false;

  constructor(
    private modalController: ModalController,
    private userDeviceService: UserDeviceService,
    private syncService: SyncService,
    private posthogService: PosthogService
  ) {}

  async handleDeviceLimitReached(): Promise<void> {
    if (this.deviceModalOpen) {
      return;
    }
    try {
      this.deviceModalOpen = true;

      this.posthogService.captureEvent('[Settings][DeviceSettings][DeviceLimit]: Limit Exceeded Modal displayed', {});

      const modal = await this.modalController.create({
        component: UserDevicesModalComponent,
        backdropDismiss: false,
        cssClass: 'pdf-workflow-modal omg-modal',
      });

      await modal.present();
      await modal.onDidDismiss();
    } finally {
      this.deviceModalOpen = false;
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, {showInfoToastMessages: true});
    }
  }
}
