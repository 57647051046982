import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {IdAware, IdType, Project} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {combineLatestAsync} from './async-utils';

export function haveObjectsEqualProperties<T>(object1: T, object2: T, properties: Array<keyof T>) {
  for (const property of properties) {
    if ((object1[property] === null || object1[property] === undefined) && (object2[property] === null || object2[property] === undefined)) {
      continue;
    }
    if (object1[property] !== object2[property]) {
      return false;
    }
  }
  return true;
}

export const sortExistingLastFn =
  <T, U extends keyof T>(existingList: T[U][], existingBy: U) =>
  (a: T, b: T) => {
    const aSelected = existingList.includes(a[existingBy]);
    const bSelected = existingList.includes(b[existingBy]);

    if (aSelected === bSelected) {
      return 0;
    }
    if (aSelected) {
      return 1;
    }
    return -1;
  };

export const sortByTextField = <T extends {isActive?: boolean} & Record<TName, string>, TName extends keyof T>(values: T[], textField: TName, withActive = false): T[] => {
  const sortsBy: ((value: T) => string | boolean)[] = [(value: T) => value[textField]?.toLowerCase()];
  if (withActive) {
    sortsBy.unshift((value) => !value.isActive);
  }
  return _.sortBy(values, sortsBy);
};

export const sortStreamByTextField = <T extends {isActive?: boolean} & Partial<Record<TName, string>>, TName extends keyof T>(field: TName, withActive?: boolean) =>
  map<T[], T[]>((values) => sortByTextField(values, field, withActive));

export const filterStreamByProjects = <T extends {projectId: IdType}>(projects$: Observable<IdAware[]>) =>
  switchMap<T[], Observable<T[]>>((objects) => projects$.pipe(map<Project[], T[]>((projects) => objects.filter(({projectId}) => projects.some(({id}) => id === projectId)))));

export const filterStreamByActiveOrUsedInProjects = <
  TSource extends IdAware,
  TActive extends {projectId: IdType} & Record<TName, IdType>,
  TObject extends IdAware & Partial<Record<TObjectName, Nullish<IdType>>>,
  TName extends keyof TActive,
  TObjectName extends keyof TObject,
>(
  active$: Observable<TActive[]>,
  projects$: Observable<IdAware[]>,
  activeField: TName,
  objects$: Observable<TObject[]> = of([]),
  objectField?: TObjectName
) =>
  switchMap<TSource[], Observable<TSource[]>>((sourceData) =>
    combineLatestAsync([active$.pipe(filterStreamByProjects(projects$)), objects$]).pipe(
      map(([activeData, objects]) => {
        const activeByField = new Set<IdType>(activeData.map((value) => value[activeField]));
        const objectsByField = new Set<IdType>(objects.map((value) => value[objectField]));
        const result = sourceData.filter(({id}) => activeByField.has(id) || objectsByField.has(id));

        return result;
      })
    )
  );

export const isRecord = (v: unknown): v is Record<string | number | symbol, unknown> => v && typeof v === 'object';
