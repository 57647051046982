import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractDataService, DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {map} from 'rxjs/operators';
import _ from 'lodash';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {LocalChanges} from '../../model/local-changes';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

@Injectable()
export abstract class AbstractNonClientAwareDataService<T extends IdAware> extends AbstractDataService<T> implements OnDestroy {
  protected readonly idColumn = 'id';
  protected readonly restEndpointUrl = environment.serverUrl + this.restEndpointUri;
  public readonly dataGroupedById: Observable<Record<IdType, T>> = this.data.pipe(map((entities) => (entities === null ? {} : _.keyBy(entities, 'id'))));
  private authSubscription: Subscription | undefined;

  constructor(
    @Inject('StorageKeyEnum') storageKey: StorageKeyEnum,
    @Inject('String') protected readonly restEndpointUri: string,
    @Inject('Array') protected readonly defaultValue: Array<T>,
    protected http: HttpClient,
    protected storage: StorageService,
    protected authenticationService: AuthenticationService,
    protected loggingService: LoggingService,
    protected integrityResolverService: IntegrityResolverService,
    public versionIntroduced = VERSION_INTRODUCED_DEFAULT,
    @Inject('Array') protected sortColumns?: Array<keyof T | ((item: T) => any)>,
    @Inject('Array') protected sortColumnOrders?: Array<'asc' | 'desc'>
  ) {
    super(false, false, storageKey, restEndpointUri, defaultValue, http, storage, authenticationService, loggingService, integrityResolverService, versionIntroduced, sortColumns, sortColumnOrders);
    this.authSubscription = authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      if (isAuthenticated) {
        this.hasCurrentUserPermission = true;
        const data = await this.getDataFromStorageOrServer();
        this.dataSubject.next(data ? this.ensureDataSorted(data) : this.defaultValue);
        await this.initLocalChangesSubject();
        await this.initLocalChangesByClientOrProjectSubject();
      } else {
        this.hasCurrentUserPermission = undefined;
        await this.removeStorageData();
        this.dataSubject.next(null);
        this.localChangesSubject.next(new LocalChanges<T>());
        this.localChangesByClientOrProjectSubject.next(new Map());
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = undefined;
    }
  }

  protected async storageDataChanged(data: Array<T>): Promise<void> {
    this.dataSubject.next(this.ensureDataSorted(data));
  }

  public async insertUpdateDelete(changes: {
    inserts?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
    insertOptions?: DataServiceInsertOptions;
    updates?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
    deletes?: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined);
    deleteOptions?: DataServiceDeleteOptions;
  }): Promise<Array<T>> {
    return await super.insertUpdateDeleteInternal(changes);
  }

  public async insertOrUpdate(valueOrArray: T | Array<T>): Promise<Array<T>> {
    return await super.insertOrUpdate(valueOrArray);
  }

  public async insert(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), options?: DataServiceInsertOptions): Promise<Array<T>> {
    return await super.insertInternal(valueArrayOrFunction, undefined, options);
  }

  public async update(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined)): Promise<Array<T>> {
    return await super.updateInternal(valueArrayOrFunction);
  }

  public async delete(valueArrayOrFunction: T | Array<T> | ((storageData: Array<T>) => T | Array<T> | undefined), options?: DataServiceDeleteOptions): Promise<void> {
    await super.deleteInternal(valueArrayOrFunction, undefined, options);
  }

  protected checkHasCurrentUserPermission(): boolean | undefined {
    return true;
  }

  public getDataFromMemory(clientOrProjectId?: IdType): Array<T> | undefined {
    return this.dataSubject.value || undefined;
  }
}
