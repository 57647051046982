import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {IonicModule, ViewDidEnter} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {CodeInputModule} from 'angular-code-input';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {isHttpApplicationError} from 'src/app/utils/error-utils';
import {getEmptyTwoFactorAuthenticate} from 'src/app/utils/two-factor-utils';
import {TwoFactorAuthenticate, TwoFactorDeviceMethod} from 'submodules/baumaster-v2-common';
import {GatherTwoFactorRecoveryComponent} from '../gather-two-factor-recovery/gather-two-factor-recovery.component';
import {GatherTwoFactorTotpComponent} from '../gather-two-factor-totp/gather-two-factor-totp.component';

@Component({
  selector: 'app-gather-two-factor-modal',
  templateUrl: './gather-two-factor-modal.component.html',
  styleUrls: ['./gather-two-factor-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, CodeInputModule, UiModule, IonicModule, GatherTwoFactorTotpComponent, GatherTwoFactorRecoveryComponent, TranslateModule, ReactiveFormsModule],
  providers: [PageDidEnterLifecycleService],
})
export class GatherTwoFactorModalComponent implements OnInit, ViewDidEnter {
  protected modal: HTMLIonModalElement;

  @Input()
  backLabel = 'twoFactor.login.backToLogin';
  @Input()
  method: TwoFactorDeviceMethod;
  @Input()
  action: (twoFactor: TwoFactorAuthenticate) => void | Promise<void>;

  loading = false;

  twoFactorDetails: TwoFactorAuthenticate | undefined;

  twoFactorDetailsControl = new FormControl<TwoFactorAuthenticate | null>(null);

  constructor(
    private alertService: AlertService,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService
  ) {}

  ionViewDidEnter() {
    this.pageDidEnterLifecycleService.pageDidEnter();
  }

  ngOnInit() {
    this.changeMethod(this.method);
  }

  changeMethod(method: TwoFactorDeviceMethod) {
    if (this.loading) {
      return;
    }
    this.method = method;
    this.twoFactorDetails = getEmptyTwoFactorAuthenticate(this.method);
    this.twoFactorDetailsControl.reset(this.twoFactorDetails);
  }

  dismiss() {
    this.modal.dismiss();
  }

  async send2FA() {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      this.modal.canDismiss = false;
      const result = await this.action(this.twoFactorDetailsControl.value);
      this.modal.dismiss(result, 'performed');
    } catch (error) {
      if (isHttpApplicationError(error, 'AUTHENTICATION_TIMED_BLOCK')) {
        this.alertService.ok({message: 'LOGIN.authentication_timed_block'});
        return;
      }
      if (isHttpApplicationError(error, 'AUTHENTICATION_TWO_FACTOR_INVALID')) {
        this.twoFactorDetailsControl.setErrors({invalidServer: true});
        return;
      }
      throw error;
    } finally {
      this.loading = false;
      this.modal.canDismiss = true;
    }
  }
}
