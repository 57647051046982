import {Pipe, PipeTransform} from '@angular/core';
import {ProjectCurrencyEnum} from 'submodules/baumaster-v2-common';

@Pipe({
  name: 'currencyCode',
  pure: true,
})
export class CurrencyCodePipe implements PipeTransform {
  transform(value: ProjectCurrencyEnum | keyof typeof ProjectCurrencyEnum): string | number {
    return ProjectCurrencyEnum[value];
  }
}
