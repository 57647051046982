import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {Nullish} from 'src/app/model/nullish';
import {LoggingService} from 'src/app/services/common/logging.service';
import {tinymceContentStyle} from 'src/app/services/pdf/pdf-client-util';
import {DeviceService} from 'src/app/services/ui/device.service';
import {ThemeService} from 'src/app/services/ui/theme.service';
import {convertToRichText, isRichText, parseRichTextInput, sanitizeRichTextInput} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';

function pastePreProcessorConvertToPlainText(plugin: any, args: any) {
  if (args.content && typeof args.content === 'string') {
    args.content = sanitizeRichTextInput(args.content);
    return;
  }
  args?.stopImmediatePropagation?.();
  args?.stopPropagation?.();
  args?.preventDefault?.();
}

const LOG_SOURCE = 'RichTextEditorComponent';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('animation', [
      transition('in => out', [
        animate(
          '1s ease',
          keyframes([
            style({height: '*', opacity: 1, transform: 'scale(1)', offset: 0}),
            style({height: '*', opacity: 1, transform: 'scale(1)', offset: 0.9}),
            style({height: 0, opacity: 0, transform: 'scale(0.97)', offset: 1}),
          ])
        ),
      ]),
      transition('out => in', [animate('300ms ease')]),
      state('out', style({height: 0, opacity: 0, transform: 'scale(0.97)'})),
      state('in', style({height: '*', opacity: 1, transform: 'scale(1)'})),
    ]),
  ],
})
export class RichTextEditorComponent implements ControlValueAccessor {
  instanceId = v4();
  readonly tinymceContentStyle = tinymceContentStyle;
  readonly pastePreProcessorConvertToPlainText = pastePreProcessorConvertToPlainText;
  toolbarMode: 'scrolling' | 'floating' | null = null;

  @ViewChild(EditorComponent) editorComponent: EditorComponent;
  @Input() label: string;

  @Input()
  disabled = false;

  @Input()
  labelColor: Nullish<string> = 'text-secondary';

  @Input()
  required?: boolean;

  value: Nullish<string>;
  mceValue: Nullish<string>;

  showToolbar: boolean;
  isNativeDevice: boolean;
  private propagateOnChange = (_: any) => {};
  private propagateOnTouched = () => {};

  get currentLang() {
    return this.translateService.currentLang;
  }

  get isDark() {
    return this.themeService.isDark;
  }

  constructor(
    private translateService: TranslateService,
    private themeService: ThemeService,
    private loggingService: LoggingService,
    private deviceService: DeviceService
  ) {
    const isNativeApp = this.deviceService.isNativeApp();
    this.toolbarMode = isNativeApp ? 'scrolling' : 'floating';
    this.showToolbar = !isNativeApp;
    this.isNativeDevice = isNativeApp;
  }

  writeValue(value: any): void {
    const wasRichText = typeof value === 'string' && isRichText(value);
    this.value = typeof value === 'string' ? sanitizeRichTextInput(isRichText(value) ? value : convertToRichText(value)) : value;
    if (value === null || value === undefined) {
      this.loggingService.info('ProtocolEntryFormComponent - ' + LOG_SOURCE, `writeValue to "${value}"`);
      this.mceValue = this.value;
    } else if (typeof value === 'string') {
      const parsedUnsanitizedValue = parseRichTextInput(this.mceValue);

      if (this.value !== parsedUnsanitizedValue) {
        this.mceValue = this.value;
      } else {
        if (this.value && !wasRichText) {
          // There is an issue when text was not richtext and value was switched between entries of idential value, the formatting of the richtext did not take into account the line breaks
          this.mceValue = this.value + String.fromCharCode(0);
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  valueChangeHandle(value: string) {
    this.mceValue = value;
    const sanitized = sanitizeRichTextInput(value);
    const shouldPropagate = sanitized !== this.value;
    this.value = sanitized;
    const parsedDirtyValue = parseRichTextInput(value);
    if (parsedDirtyValue !== this.value) {
      this.mceValue = this.value;
      this.loggingService.warn(LOG_SOURCE, 'Value from TinyMCE editor had to be sanitized and updated; pointer flicker most probably occurred!');
    }
    if (shouldPropagate) {
      this.propagateOnChange(this.value);
    }
  }

  handleBlur() {
    this.propagateOnTouched();
  }

  handleInit() {
    if (this.editorComponent.editor.mode.isReadOnly() !== Boolean(this.disabled)) {
      if (typeof this.editorComponent.editor.mode?.set === 'function') {
        this.editorComponent.editor.mode.set(this.disabled ? 'readonly' : 'design');
      } else {
        this.editorComponent.editor.setMode(this.disabled ? 'readonly' : 'design');
      }
    }
  }

  toggleToolbar() {
    this.showToolbar = !this.showToolbar;
  }

  resetContent(value: string) {
    this.editorComponent?.editor?.resetContent(value ?? '');
  }
}
