import {ColumnConfig, ColumnUniqueConfig, IdAware} from 'submodules/baumaster-v2-common';

export const checkObjectsUniqueConstraint = <T extends IdAware>(
  objs: T[],
  allObjects: T[],
  columnSets: ColumnConfig[]
): {
  firstDuplicate: T;
  uniqueConstraint: ColumnUniqueConfig;
  objectToCheck: T;
} | null => {
  if (!objs?.length) {
    return;
  }

  // First check uniqueness inside batch
  for (const obj of objs) {
    const result = checkObjectUniqueConstraint(obj, objs, columnSets);

    if (result !== null) {
      return {...result, objectToCheck: obj};
    }
  }

  // Then check uniqueness across whole storage
  for (const obj of objs) {
    const result = checkObjectUniqueConstraint(obj, allObjects, columnSets);

    if (result !== null) {
      return {...result, objectToCheck: obj};
    }
  }

  return null;
};

const checkObjectUniqueConstraint = <T extends IdAware>(obj: T, allObjects: T[], columnSets: ColumnConfig[]): {firstDuplicate: T; uniqueConstraint: ColumnUniqueConfig} | null => {
  const uniqueConstraints = columnSets
    .filter((column) => column.uniqueWith)
    .map((column) => column.uniqueWith)
    .reduce((acc, uniques) => {
      uniques.forEach((unique) => {
        if (!acc.includes(unique)) {
          acc.push(unique);
        }
      });

      return acc;
    }, []);

  if (uniqueConstraints.length === 0) {
    return null;
  }

  const nameToProp = columnSets.reduce((acc, column) => acc.set(column.name, column.prop || column.name), new Map<string, string>());

  for (const uniqueConstraint of uniqueConstraints) {
    const props = uniqueConstraint.map((name) => nameToProp.get(name));

    const firstDuplicate = allObjects.find((theObject) => props.every((prop) => obj[prop] != null && theObject[prop] != null && obj[prop] === theObject[prop] && obj.id !== theObject.id));

    if (firstDuplicate) {
      return {firstDuplicate, uniqueConstraint};
    }
  }

  return null;
};
