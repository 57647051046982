import {ColumnConfig} from '../ColumnConfig';

const UniqueTagInObjectUniq = ['tag_id', 'object_id'];

export const TagClientObjectColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'tag_id', prop: 'tagId', uniqueWith: [UniqueTagInObjectUniq]},
  {name: 'object_id', prop: 'objectId', uniqueWith: [UniqueTagInObjectUniq]},
  {name: 'object_type', prop: 'objectType'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
