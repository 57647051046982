import {Injectable} from '@angular/core';
import {AttachmentClient, generateFilePathsForAttachmentClient, IdType} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {TranslateService} from '@ngx-translate/core';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractNonClientAwareAttachmentDataService} from './abstract-non-client-aware-attachment-data.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/attachmentClients/';

@Injectable({
  providedIn: 'root',
})
export class AttachmentClientDataService extends AbstractNonClientAwareAttachmentDataService<AttachmentClient> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    loggingService: LoggingService,
    systemEventService: SystemEventService,
    protected devModeService: DevModeService,
    integrityResolverService: IntegrityResolverService,
    protected translateService: TranslateService,
    protected attachmentSettingService: AttachmentSettingService
  ) {
    super(
      StorageKeyEnum.ATTACHMENT_CLIENT,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      loggingService,
      systemEventService,
      devModeService,
      integrityResolverService,
      translateService,
      attachmentSettingService,
      VERSION_INTRODUCED_DEFAULT,
      ATTACHMENT_DEFAULT_SORT_COLUMNS,
      ATTACHMENT_DEFAULT_SORT_ORDER
    );
  }

  protected setGeneratedFilePath(attachment: AttachmentClient) {
    attachment.filePath = generateFilePathsForAttachmentClient(attachment).filePath;
  }

  public getDataMappedById(): Observable<Map<IdType, AttachmentClient>> {
    return this.data.pipe(
      map((attachments) => {
        const attachmentMap = new Map<IdType, AttachmentClient>();
        attachments.forEach((attachment) => attachmentMap.set(attachment.id, attachment));
        return attachmentMap;
      })
    );
  }
}
