import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {convertOptionalProjectNumberToString, IdType, Project, ProjectStatusEnum, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {observableToPromise} from '../../utils/async-utils';
import {StorageKeyEnum} from '../../shared/constants';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {ClientService, ProjectNotFoundError} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {observableToPromiseWithTimeout} from '../../utils/observable-to-promise';
import {TimeoutError} from '../../shared/errors';

const REST_ENDPOINT_URI = 'api/data/projects/';

export type BeforeProjectChangeCallback = (event: {newProject: Project}) => Promise<unknown> | unknown;

@Injectable({
  providedIn: 'root',
})
export class ProjectDataService extends AbstractClientAwareDataService<Project> implements OnDestroy {
  private currentProjectSubject = new ReplaySubject<Project | undefined>(1);
  public readonly currentProjectObservable = this.currentProjectSubject.asObservable();
  public readonly dataActive$ = this.data.pipe(map((projects) => projects.filter((project) => project.status === null || project.status === undefined || project.status === ProjectStatusEnum.ACTIVE)));
  public readonly dataActiveAndArchived$ = this.data.pipe(
    map((projects) =>
      projects.filter((project) => project.status === null || project.status === undefined || project.status === ProjectStatusEnum.ACTIVE || project.status === ProjectStatusEnum.ARCHIVED)
    )
  );
  public readonly dataAcrossClientsActive$ = this.dataAcrossClients$.pipe(
    map((projects) => projects.filter((project) => project.status === null || project.status === undefined || project.status === ProjectStatusEnum.ACTIVE))
  );
  private projectAuthSubscription: Subscription;

  private beforeProjectChangeCallbacks: BeforeProjectChangeCallback[] = [];

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService
  ) {
    super(StorageKeyEnum.PROJECT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, integrityResolverService, VERSION_INTRODUCED_DEFAULT, [
      'number',
      'name',
    ]);
    this.projectAuthSubscription = this.authenticationService.isAuthenticated$
      .pipe(
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            return of(isAuthenticated);
          }

          // Make sure the isAuthenticated is emitted **after** the clients list is emitted
          return this.clientService.clients$.pipe(
            filter((clients) => clients.length > 0),
            map(() => isAuthenticated),
            distinctUntilChanged()
          );
        })
      )
      .subscribe(async (isAuthenticated) => {
        this.loggingService.debug(this.logSource, 'ProjectDataService - authenticationService.subscribe called', isAuthenticated);
        if (!isAuthenticated) {
          await this.storage.remove(StorageKeyEnum.CURRENT_PROJECT);
          this.currentProjectSubject.next(undefined);
        } else {
          await this.initCurrentProject();
        }
      });
  }

  addBeforeProjectChangeEventHandler(fn: BeforeProjectChangeCallback) {
    this.beforeProjectChangeCallbacks.push(fn);
  }

  removeBeforeProjectChangeEventHandler(fnToRemove: BeforeProjectChangeCallback) {
    const fnIndexToRemove = this.beforeProjectChangeCallbacks.findIndex((fn) => fn === fnToRemove);
    if (fnIndexToRemove >= 0) {
      this.beforeProjectChangeCallbacks.splice(fnIndexToRemove, 1);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.projectAuthSubscription.unsubscribe();
  }

  async initCurrentProject(): Promise<void> {
    let currentProject: Project | null = await this.storage.get(StorageKeyEnum.CURRENT_PROJECT);
    let didSetCurrentClient = false;
    if (currentProject) {
      try {
        await this.clientService.setCurrentClientId(currentProject.clientId);
        didSetCurrentClient = true;
      } catch (e) {
        if (!(e instanceof ProjectNotFoundError)) {
          throw e;
        }
        this.loggingService.warn(this.logSource, 'initCurrentProject: ProjectNotFoundError has been thrown, meaning current project state is corrupted; fallback to default client.');
        currentProject = null;
      }
    }

    if (!didSetCurrentClient) {
      this.clientService.setDefaultClient();
      try {
        const projects = await observableToPromiseWithTimeout(this.dataActive$.pipe(filter((theProjects) => !!theProjects?.length)));
        if (projects?.length) {
          await this.setCurrentProject(projects[0]);
          return;
        }
      } catch (error) {
        if (!(error instanceof TimeoutError)) {
          throw error;
        }
        this.loggingService.warn(this.logSource, 'initCurrentProject failed to get project data within timeout.');
      }
    }
    this.currentProjectSubject.next(currentProject || undefined);
  }

  public async getCurrentProject(): Promise<Project | undefined> {
    return observableToPromise(this.currentProjectSubject);
  }

  public async getCurrentProjectWithTimeout(timeout = 1000): Promise<Project | undefined> {
    return await Promise.race([this.getCurrentProject(), new Promise<undefined>((resolve) => setTimeout(() => resolve(undefined), timeout))]);
  }

  public async getMandatoryCurrentProject(): Promise<Project> {
    const currentProject = await this.getCurrentProject();
    if (!currentProject) {
      throw new Error('No currentProject set.');
    }
    return currentProject;
  }

  public async setCurrentProjectId(projectId: IdType): Promise<void> {
    const project = await observableToPromise(this.getByIdAcrossClients(projectId));
    if (!project) {
      throw new Error(`Project with id ${projectId} not found.`);
    }
    await this.setCurrentProject(project);
  }

  public async setCurrentProject(project: Project): Promise<void> {
    this.loggingService.debug(this.logSource, 'ProjectDataService - setCurrentProject called', project);
    this.assertAuthenticated();
    await this.beforeProjectChangeCallbacks.reduce((chain, fn) => chain.then(() => fn({newProject: project})), Promise.resolve());
    this.storage.set(StorageKeyEnum.CURRENT_PROJECT, project, {
      ensureStored: false,
      immediate: false,
    }); // do not wait for the storage.set. I slows switching projects down in come cases and is not essential anyway
    if (project) {
      await this.clientService.setCurrentClientId(project.clientId);
      const activeProjects = await observableToPromise(this.dataAcrossClientsActive$);
      await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(
        project.id,
        activeProjects.map((p) => p.id),
        undefined,
        undefined,
        {
          ensureStored: false,
          immediate: false,
        }
      );
    } else {
      this.clientService.setDefaultClient();
    }
    this.currentProjectSubject.next(project);
  }

  public findByName(name: string): Observable<Array<Project>> {
    return this.data.pipe(map((projects) => projects.filter((protocol) => protocol.name === name)));
  }

  public findByNumber(projectNumber: string): Observable<Array<Project>> {
    return this.data.pipe(map((projects) => projects.filter((project) => convertOptionalProjectNumberToString(project.number) === projectNumber)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
