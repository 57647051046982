import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-project-selector-col',
  templateUrl: './project-selector-col.component.html',
  styleUrls: ['./project-selector-col.component.scss'],
})
export class ProjectSelectorColComponent {
  @Input()
  class: string | undefined;
}
