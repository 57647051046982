<form class="omg-form omg-boundary" [formGroup]="unitContactForm" action="#">
  <ion-grid class="grid-gap-4">
    <ion-row [class.ion-hide-lg-up]="isEditMode">
      <ion-col size="12">
        <ng-container *ngTemplateOutlet="unitSelectorTemplate"></ng-container>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-hide-lg-down unit-selector-container" [class.ion-hide]="!isEditMode">
        <ng-container *ngTemplateOutlet="unitSelectorTemplate"></ng-container>
      </ion-col>
      <ion-col>
        <ion-row>
          <ion-col size="12">
            <div class="omg-modal-header">
              {{ "contactForm.contactData" | translate }}
            </div>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item [disabled]="readonly">
              <ion-input label="{{ 'unitContacts.form.salutation' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="salutation" maxlength="50" type="text"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item [disabled]="readonly">
              <ion-input label="{{ 'unitContacts.form.legalEntity' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="legalEntity" maxlength="200" type="text"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item [disabled]="readonly">
              <ion-input label="{{ 'contactForm.firstName' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="firstName" maxlength="50" type="text" required class="omg-required"></ion-input>
            </ion-item>
            <span *ngIf="unitContactForm.controls.firstName.errors && unitContactForm.controls.firstName.dirty"
                  class="error-message">
              {{ 'formValidation.emptyField' | translate:{field: 'contactForm.firstName' | translate} }}
            </span>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item [disabled]="readonly">
              <ion-input label="{{ 'contactForm.lastName' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="lastName" maxlength="50" type="text" required class="omg-required"></ion-input>
            </ion-item>
            <span *ngIf="unitContactForm.controls.lastName.errors && unitContactForm.controls.lastName.dirty"
                  class="error-message">
              {{ 'formValidation.emptyField' | translate:{field: 'contactForm.lastName' | translate} }}
            </span>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item [disabled]="readonly">
              <ion-input label="{{ 'contactForm.email' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="email" maxlength="200" type="email" [pattern]="EMAIL_REG_EXP_PATTERN"></ion-input>
            </ion-item>
            <span *ngIf="unitContactForm.controls.email.errors && unitContactForm.dirty" class="error-message">
              {{ 'formValidation.invalidEmail' | translate }}
            </span>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item [disabled]="readonly">
              <ion-input label="{{ 'unitContacts.table.phone' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="phone" maxlength="30" type="text" [pattern]="PHONE_NUMBER_PATTERN"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col *ngIf="unitContactForm.get('externalId')?.value" size="12">
            <ion-item [disabled]="true">
              <ion-input label="{{ 'unitContacts.form.importId' | translate }}" label-placement="floating" [readonly]="true" formControlName="externalId" maxlength="50" type="text"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <app-address-form formGroupName="address" [readonly]="readonly"></app-address-form>

        <ion-row>
          <ion-col size="12">
            <div class="omg-modal-header">
              {{ "units_settings.unit_form.other" | translate }}
            </div>
          </ion-col>
          <ion-col size="12">
            <ion-item [disabled]="readonly">
              <ion-textarea label="{{ 'contactForm.notes' | translate }}" label-placement="floating" [readonly]="readonly" auto-grow="true" formControlName="note" rows="1"
                            type="text"></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="omg-modal-header">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col>
                    <ion-label class="ion-no-margin">{{ "contactForm.public_data" | translate }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-note>{{ "contactForm.public_data_description" | translate }}</ion-note>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item class="omg omg-form-checkbox" [disabled]="readonly">
            <ion-checkbox label-placement="end" justify="start" [disabled]="readonly" formControlName="dsgvoShowEmail">
              {{ 'contactForm.email' | translate }}
            </ion-checkbox>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-sm="6">
            <ion-item class="omg omg-form-checkbox" [disabled]="readonly">
            <ion-checkbox label-placement="end" justify="start" [disabled]="readonly" formControlName="dsgvoShowTel">
              {{ 'contactForm.mobileNumber' | translate }}
            </ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>

<ng-template #unitSelectorTemplate>
  <ion-accordion-group [multiple]="true" [value]="['units']">
    <ion-accordion toggleIconSlot="start" [value]="'units'">
      <ion-item class="omg-modal-header" slot="header">
        {{ 'unitContacts.units.title' | translate }}
      </ion-item>
      <div slot="content">
        @for (unitWithAddress of unitsWithAddressOrdered; track unitWithAddress.id;) {
        <div [class.mt-2]="0" class="d-flex flex-column gap-2 pb-2" [class.bottom-border]="unitWithAddress?.unitProfile && unitWithAddress?.unitProfile?.isActive" [class.with-historic-heading]="!unitWithAddress.unitProfile?.isActive">
          @if (unitWithAddress?.unitProfile && !unitWithAddress?.unitProfile?.isActive) {
            <div class="omg-t-medium-body-bold pt-2 previous-unit-header">
              <fa-icon slot="icon-only" [icon]="['fal', 'archive']"></fa-icon>
              <span class="pl-2">{{ 'unitContacts.units.historicContact' | translate }}</span>
            </div>
          }
          <div class="omg-t-medium-body d-flex ion-align-items-center ion-justify-content-between">
            <span>{{unitWithAddress.breadcrumbsName}}</span>
            <ion-button class="omg-btn-text" (click)="openPopover({event: $event, unit: unitWithAddress})">
              <fa-icon slot="icon-only" [icon]="['far', 'ellipsis-h']"></fa-icon>
            </ion-button>
          </div>
          @if (unitWithAddress?.unitProfile && unitWithAddress?.unitProfile?.isActive) {
            <div class="omg-t-medium-body grey-text">
              <span>{{unitWithAddress.addressString}}</span>
            </div>
          }
        </div>
        }
        <ion-item class="ion-hide">
          <div class="ionic-selectable-label-floating">
            <div
              appUnitSelector
              #us="appUnitSelector"
              [selectorUnits]="allUnits$ | async"
              [selectorUnitLevels]="unitLevels$ | async"
              [ngModel]="us.idValue()"
              (ngModelChange)="addUnitIdToForm(us.idValue())"
              [ngModelOptions]="{standalone: true}"
              [idOnly]="true"
              [preSelectEnabled]="false"
            >
              <bdi>{{us.value()?.breadcrumbsName}}</bdi>
            </div>
            <div class="ionic-selectable-icon">
              <div class="ionic-selectable-icon-inner"></div>
            </div>
          </div>
        </ion-item>
        <ion-button *ngIf="!readonly" class="omg-btn-secondary mt-4 full-width" (click)="us.chooseUnit()">
          {{ 'unitContacts.units.button' | translate }}
        </ion-button>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</ng-template>