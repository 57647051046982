import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {UiModule} from 'src/app/shared/module/ui/ui.module';

@Component({
  selector: 'app-payment-overdue-modal',
  templateUrl: './payment-overdue-modal.component.html',
  styleUrls: ['./payment-overdue-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, UiModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOverdueModalComponent {}
