import {Content} from 'pdfmake/interfaces';
import {PdfPreview, ReportTypeCode} from '../../../models';
import {PdfReportSendReq} from '../../../requestResponse';
import {PdfReportGenerateData} from '../pdfReport.model';
import {AbstractReportContent} from './abstractReport.content';

export class ReportContent extends AbstractReportContent {
  constructor(config: PdfReportSendReq, data: PdfReportGenerateData, pdfPreview?: PdfPreview) {
    super(data.project.language, config, data, pdfPreview);
  }

  private writeDiaryConstructionReport(content: Content[]) {
    this.writePdfStartBanner(content);
    this.writeProject(content);
    this.writeInvolved(content);
    this.writeWeather(content);
    this.writeParticipantsHeader(content);
    this.writeParticipants(content);
    this.writeCompaniesHeader(content);
    this.writeCompanies(content);
    this.writeOthersHeader(content);
    this.writeOthers(content);
  }

  private writeConstructionReport(content: Content[]) {
    this.writePdfStartBanner(content);
    this.writeProject(content);
    this.writeInvolved(content, {
      projectLeaderLabel: this.i18n?.get('contractor'),
    });
    this.writeWeather(content);
    this.writeParticipantsHeader(content);
    this.writeParticipants(content);

    this.writeStaffsHeader(content);
    this.writeStaffs(content);

    this.writeActivitiesHeader(content);
    this.writeActivities(content);

    this.writeOthersHeader(content);
    this.writeOthers(content);

    this.writeMaterialsHeader(content);
    this.writeMaterials(content);

    this.writeEquipmentsHeader(content);
    this.writeEquipments(content);

    this.writeSignatures(content);
  }

  private writeDirectedReport(content: Content[]) {
    this.writePdfStartBanner(content);
    this.writeProjectWithDate(content);
    this.writeInvolved(content, {
      projectLeaderLabel: this.i18n?.get('contractor'),
    });

    this.writeWeatherDirectedReport(content);
    this.writeDirectedReportSummary(content);

    this.writeParticipantsHeader(content);
    this.writeParticipants(content);

    this.writeEmployeesHeader(content);
    this.writeEmployees(content);

    this.writeActivitiesHeader(content);
    this.writeActivities(content);

    this.writeOthersHeader(content);
    this.writeOthers(content);

    this.writeMaterialsHeader(content);
    this.writeMaterials(content);

    this.writeEquipmentsHeader(content);
    this.writeEquipments(content);

    this.writeSignatures(content);
  }

  writeReport(content: Content[]) {
    const reportType = this.data.lookup.reportTypes.get(this.data.reportWeek.typeId);
    if (!reportType) {
      throw new Error(`Unable to find reportType with id ${this.data.reportWeek.typeId}.`);
    }
    const reportTypeCode = reportType.name;
    switch (reportTypeCode) {
      case ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY:
        return this.writeDiaryConstructionReport(content);
      case ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT:
        return this.writeConstructionReport(content);
      case ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT:
        return this.writeDirectedReport(content);
      default:
        throw new Error(`Unknown report type ${reportTypeCode}!`);
    }
  }

  writeAttachmentsAfterReport(content: Content[]) {
    this.writeBigAttachments(content);
  }
}
