<ion-content>
  <ion-router-outlet
    *ngIf="(protocolEntry$ | async) else ((filters$ | async)?.length === 0) ? noProtocolEntry : noFilterResults"
  ></ion-router-outlet>
  <ng-template #noProtocolEntry>
    <ion-grid class="app-add-entry">
      <ion-row>
        <app-no-content-svg [messageString]="'protocol.add_entry' | translate"></app-no-content-svg>
      </ion-row>
    </ion-grid>
  </ng-template>
  <ng-template #noFilterResults>
    <ion-grid class="app-add-entry">
      <ion-row>
        <app-no-content-svg [messageString]="'protocol.no_entries_with_filter' | translate"></app-no-content-svg>
      </ion-row>
    </ion-grid>
  </ng-template>
  <ng-container *ngIf="(protocol$ | async) as protocol">
    <ion-fab class="margin-right-10 omg-btn-split" slot="fixed" *ngIf="(protocol.closedAt | isEmpty) && (isEditEnabled$ | async) && (entryCreationModeRemembered$ | async)" vertical="bottom" horizontal="end">
      <ion-button (click)="addNewProtocolEntry(protocol.id)" class="omg-btn-primary">
        <fa-icon slot="start" [icon]="entryCreationModeIcon$ | async"></fa-icon>
        <span>{{ 'protocolEntry.create' | translate }}</span>
      </ion-button>
      <ion-button  (click)="openButtonActions($event, protocol.id)" class="omg-btn-primary">
        <fa-icon slot="start" [icon]="['fal', 'chevron-up']"></fa-icon>
      </ion-button>
    </ion-fab>
    <ion-fab class="margin-right-10" slot="fixed" *ngIf="(protocol.closedAt | isEmpty) && (isEditEnabled$ | async) && !(entryCreationModeRemembered$ | async)" vertical="bottom" horizontal="end">
      <ion-button (click)="openButtonActions($event, protocol.id)" class="omg-btn-primary">
        <span>{{ 'protocolEntry.create' | translate }}</span>
      </ion-button>
    </ion-fab>
  </ng-container>
</ion-content>
