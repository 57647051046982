import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IdType, Unit, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const REST_ENDPOINT_URI = 'api/data/unitLevels';

@Injectable({
  providedIn: 'root',
})
export class UnitDataService extends AbstractProjectAwareDataService<Unit> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    loggingService: LoggingService,
    projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.UNIT,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService,
      '2.30.0',
      ['unitLevelId', (v) => v.parentId ?? undefined, 'index', 'name', 'id']
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  public getByUnitLevel(unitLevelId: IdType): Observable<Array<Unit>> {
    return this.data.pipe(map((units) => units.filter((unit) => unit.unitLevelId === unitLevelId)));
  }

  public getAllSubUnits(unitId: IdType): Observable<Unit[]> {
    return this.dataAcrossProjects$.pipe(map((units) => this.getSubUnits(unitId, units)));
  }

  private getSubUnits(unitId: IdType, allUnits: Unit[]): Unit[] {
    const children = allUnits.filter((unit) => unit.parentId === unitId);
    return children.reduce((acc, child) => {
      return acc.concat(child, this.getSubUnits(child.id, allUnits));
    }, [] as Unit[]);
  }
}
