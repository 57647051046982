import {Directive, HostBinding, HostListener, Input} from '@angular/core';
import {InfoPopoverComponent} from 'src/app/components/common/info-popover/info-popover.component';
import {Translatable} from 'src/app/model/translatable';
import {WithParamsTranslateService} from 'src/app/services/common/with-params-translate.service';
import {PopoverService} from 'src/app/services/ui/popover.service';

@Directive({
  selector: '[appInfoPopoverClick]',
  standalone: true,
})
export class InfoPopoverClickDirective {
  @Input()
  infoTitle: Translatable | undefined;

  @Input()
  infoText: Translatable | undefined;

  @Input()
  infoHintText: Translatable | undefined;

  @Input()
  lottieFilename: string | undefined;

  @Input()
  stopPropagation = false;

  @Input()
  linkText: Translatable | undefined;

  @Input()
  pageLink: string | undefined;

  @HostBinding('class.cursor-pointer')
  protected readonly cursorPointer = true;

  constructor(
    private popoverService: PopoverService,
    private withParamsTranslateService: WithParamsTranslateService
  ) {}

  @HostListener('click', ['$event'])
  async showInfo(event: Event) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    this.popoverService.openAndClose(event, {
      component: InfoPopoverComponent,
      cssClass: 'omg-popover-info omg-popover-info-border',
      componentProps: {
        title: this.withParamsTranslateService.translate(this.infoTitle),
        text: this.withParamsTranslateService.translate(this.infoText),
        hintText: this.withParamsTranslateService.translate(this.infoHintText),
        lottieFilename: this.lottieFilename,
        linkText: this.withParamsTranslateService.translate(this.linkText),
        pageLink: this.pageLink,
      },
    });
  }
}
