import {NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, ModalController} from '@ionic/angular';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {AnimationItem} from 'lottie-web';
import {AnimationOptions, LottieComponent} from 'ngx-lottie';
import {Router} from '@angular/router';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
  standalone: true,
  imports: [IonicModule, FontAwesomeModule, PipesModule, NgIf, LottieComponent],
})
export class InfoPopoverComponent implements OnInit {
  popover: HTMLIonPopoverElement;

  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  hintText?: string;

  @Input()
  lottieFilename?: string;

  @Input()
  linkText?: string;

  @Input()
  pageLink?: string;

  private animationItem: AnimationItem | undefined;

  options: AnimationOptions | undefined;

  constructor(
    private router: Router,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    if (this.lottieFilename) {
      this.options = {
        path: '../../../../assets/lottie/' + this.lottieFilename,
        autoplay: true,
        loop: true,
      };
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  dismiss() {
    this.popover.dismiss();
  }

  async navigateToPage() {
    this.dismiss();
    if ((await this.modalController.getTop())?.dismiss()) {
      await this.router.navigate([this.pageLink], {replaceUrl: true});
    }
  }
}
