<ng-template #itemTemplate let-item="item">
  <ion-label
    [class.text-secondary-imp]="itemGreyedOutField && item[itemGreyedOutField] === true"
    [class.omg-w-bold]="itemBoldField && item[itemBoldField] === true"
  >
    <ng-container *ngTemplateOutlet="itemLabelIconTemplate; context: { item }"></ng-container>
    {{selectable.ionicSelectable?._formatItem(item)}}
  </ion-label>
</ng-template>

<app-selectable
  #selectable
  [suppressAssignToProject]="true"
  [suppressMarkRecentlyUsed]="true"
  [recentlyUsedKey]="recentlyUsedKey"
  [modalTitle]="modalTitle"
  [itemTemplate]="itemTemplate"
  [canSearch]="true"
  [isEnabled]="true"
  [items]="activeItems"
  [allItems]="items"
  itemValueField="id"
  [itemTextField]="itemTextField"
  [shouldStoreItemValue]="true"
  class="text-primary"
  [isMultiple]="isMultiple"
  [ngModel]="value"
  (ngModelChange)="valueChangeHandle($event)"
  (onClose)="closeHandle()">
</app-selectable>

<div class="select-box">
  <ion-button
    mode="md"
    class="omg-btn-secondary open-button"
    [class.active]="value !== null"
    (click)="isUnitFilter ? us.chooseUnit() : selectable.ionicSelectable?._click()"
    appUnitSelector
    #us="appUnitSelector"
    [selectorUnits]="items"
    [selectorUnitLevels]="unitLevels"
    [ngModel]="value"
    (ngModelChange)="valueChangeHandle($event)"
    [ngModelOptions]="{standalone: true}"
    [idOnly]="false">
    <div class="inner-button omg-t-body">
      <span class="text-ellipsis">
        <strong *ngIf="(value && isArray(value) && value.length > 0)">({{ value.length }})</strong>
        {{ label }}
      </span>
      <fa-icon (click)="$event.stopPropagation(); selectAll()" class="cursor-pointer fake-button" *ngIf="value && isArray(value) && value.length > 0 || (value && !isArray(value))" slot="end" [icon]="['fal', 'times']"></fa-icon>
      <fa-icon class="cursor-pointer fake-button" *ngIf="!value || (isArray(value) && value.length === 0)" slot="end" [icon]="['fas', 'caret-down']"></fa-icon>
    </div>
  </ion-button>
  <div class="select-box-content omg-t-medium-body" [@boxContent]="hasData ? 'open' : 'close'">
    <div class="select-box-chips" @pinned *ngIf="showSelectedItems">
      <div class="selected-item-chip cursor-pointer" [class.selectable-item-text-wrap-one-line-rtl]="isUnitFilter" (click)="removeItem(item)" *ngFor="let item of selectedItems">
        <span class="selected-item-chip-text">{{ item[itemTextField] }}</span>
        <fa-icon [icon]="['fal', 'times']" class="text-secondary"></fa-icon>
      </div>
    </div>
    <div class="expand-collapse-row">
      <span class="expand-encourage" [@smooth]="!showSelectedItems ? 'visible' : 'hidden'">{{'showFilter' | translate}}</span>
      <fa-icon (click)="toggleSelectedItems()" class="cursor-pointer fake-button" [icon]="['fal', showSelectedItems ? 'chevron-up' : 'chevron-down']"></fa-icon>
    </div>
  </div>
</div>
