import {Injectable} from '@angular/core';
import {orderBy} from 'lodash';
import moment from 'moment';
import {BehaviorSubject, Observable, distinctUntilChanged, map, of, switchMap} from 'rxjs';
import {TranslatedNotificationEvent, TranslatedNotificationEventWithGroup} from 'src/app/model/notifications';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {NotificationEvent} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../data/project-data.service';
import {LanguageService} from '../i18n/language.service';
import {NotificationsService} from './notifications.service';
import {FcmPushTokenManagerService} from './fcm-push-token-manager.service';
import {PosthogService} from '../posthog/posthog.service';

export type NotificationsCenterProjectMode = 'currentProject' | 'allProjects';
const DEFAULT_NOTIFICATIONS_CENTER_PROJECT_MODE: NotificationsCenterProjectMode = 'currentProject';

const DEFAULT_SHOW_SEEN = false;

function getGroupId(event: TranslatedNotificationEvent) {
  const createdAt = new Date(event.createdAt);
  return moment(createdAt).calendar({
    sameDay: '[today]',
    lastWeek: '[last_week]',
    lastDay: '[yesterday]',
    sameElse: '[older]',
  }) as TranslatedNotificationEventWithGroup['groupId'];
}

function getIcon(event: NotificationEvent): [string, string] {
  switch (event.notificationEventType) {
    case 'DocumentBimPlanNew':
      return ['bau', 'bim'];
    case 'DocumentPdfPlanNew':
      return ['fal', 'map'];
    case 'ProtocolEntryNew':
      return ['fal', 'clipboard-check'];
    case 'ProtocolEntryNewSub':
      return ['fal6', 'diagram-subtask'];
    case 'ProtocolEntryChatNew':
      return ['fal', 'comment-alt-lines'];
    case 'ProjectUserAdded':
      return ['fal', 'list'];
    case 'ProtocolEntryUpdateAssignee':
      return ['fal', 'user-alt'];
    case 'ProtocolEntryUpdateStatus':
      return ['bau', 'status-open'];
    case 'ProtocolEntryUpdateTodoUntil':
      return ['fal', 'calendar-alt'];
  }
}

function getIconClasses(event: NotificationEvent) {
  switch (event.notificationEventType) {
    case 'DocumentBimPlanNew':
    case 'DocumentPdfPlanNew':
    case 'ProtocolEntryNew':
    case 'ProtocolEntryNewSub':
    case 'ProtocolEntryChatNew':
    case 'ProjectUserAdded':
    case 'ProtocolEntryUpdateAssignee':
    case 'ProtocolEntryUpdateTodoUntil':
      return [];
    case 'ProtocolEntryUpdateStatus':
      return 'text-secondary';
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsCenterService {
  private isCenterOpenSubject = new BehaviorSubject<boolean>(false);
  isCenterOpen$ = this.isCenterOpenSubject.asObservable();

  get isCenterOpen(): boolean {
    return this.isCenterOpenSubject.value;
  }
  private set isCenterOpen(isCenterOpen: boolean) {
    this.isCenterOpenSubject.next(isCenterOpen);
  }

  private projectModeSubject = new BehaviorSubject<NotificationsCenterProjectMode>(DEFAULT_NOTIFICATIONS_CENTER_PROJECT_MODE);
  projectMode$ = this.projectModeSubject.asObservable();

  get projectMode(): NotificationsCenterProjectMode {
    return this.projectModeSubject.value;
  }
  set projectMode(projectMode: NotificationsCenterProjectMode) {
    this.projectModeSubject.next(projectMode);
  }

  private showSeenSubject = new BehaviorSubject<boolean>(DEFAULT_SHOW_SEEN);
  showSeen$ = this.showSeenSubject.asObservable();

  get showSeen(): boolean {
    return this.showSeenSubject.value;
  }
  set showSeen(showSeen: boolean) {
    this.showSeenSubject.next(showSeen);
  }

  notifications$: Observable<TranslatedNotificationEventWithGroup[]> = combineLatestAsync([
    this.projectMode$.pipe(
      distinctUntilChanged(),
      switchMap((mode) =>
        mode === 'currentProject'
          ? this.projectDataService.currentProjectObservable.pipe(
              map((v) => v?.id),
              distinctUntilChanged()
            )
          : of(null)
      )
    ),
    this.notificationsService.allNotifications$,
    this.languageService.selectedLanguage.pipe(distinctUntilChanged()),
    this.showSeen$,
  ]).pipe(
    map(([projectIdOrNullish, notifications, lang, showSeen]) => {
      const translatedNotifications: TranslatedNotificationEvent[] = notifications
        .filter(({config, recipient}) => (!projectIdOrNullish || config.projectId === projectIdOrNullish) && (showSeen || !recipient.seenAt))
        .map(({config, event, recipient}) => ({
          ...event,
          projectId: config.projectId,
          seenAt: recipient.seenAt,
          recipientId: recipient.id,
          notificationIcon: getIcon(event),
          notificationIconClasses: getIconClasses(event),
          notificationText: event.notificationText[lang] ?? event.notificationText['de'],
        }));

      return orderBy(
        translatedNotifications.map((n) => ({...n, groupId: getGroupId(n)})),
        ['createdAt'],
        ['desc']
      ).map((n, i, arr) => ({...n, firstInGroup: i === 0 || arr[i - 1].groupId !== n.groupId}));
    })
  );
  unseenCountByMode$: Observable<Record<NotificationsCenterProjectMode, number>> = combineLatestAsync([
    this.notificationsService.allNotifications$,
    this.projectDataService.currentProjectObservable.pipe(
      map((v) => v?.id),
      distinctUntilChanged()
    ),
  ]).pipe(
    map(([notifications, currentProjectId]) => ({
      currentProject: notifications.filter(({recipient, config}) => config.projectId === currentProjectId && !recipient.seenAt).length,
      allProjects: notifications.filter(({recipient}) => !recipient.seenAt).length,
    }))
  );

  constructor(
    private languageService: LanguageService,
    private projectDataService: ProjectDataService,
    private notificationsService: NotificationsService,
    private fcmPushTokenManagerService: FcmPushTokenManagerService,
    private posthogService: PosthogService
  ) {}

  openCenter() {
    this.fcmPushTokenManagerService.openPushNotificationsOptIn();
    this.isCenterOpen = true;
    this.posthogService.captureEvent('[Notification] Center opened', {});
  }

  closeCenter() {
    this.isCenterOpen = false;
  }
}
