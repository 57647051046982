import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Nullish} from 'src/app/model/nullish';

@Component({
  selector: 'app-cancel-apply-modal-frame',
  templateUrl: './cancel-apply-modal-frame.component.html',
  styleUrls: ['./cancel-apply-modal-frame.component.scss'],
})
export class CancelApplyModalFrameComponent {
  @HostBinding('class.omg-boundary')
  readonly omgUi = true;

  @Input()
  title: string;

  @Input()
  cancelLabel: Nullish<string>;

  @Input()
  applyLabel: Nullish<string>;

  @Input()
  applyProps: Nullish<
    Partial<{
      disabled: boolean;
      color: string;
    }>
  >;

  @Input()
  cancelProps: Nullish<
    Partial<{
      disabled: boolean;
      color: string;
    }>
  >;

  @Output()
  applyClick = new EventEmitter<MouseEvent>();

  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss(undefined, 'cancel');
  }
}
