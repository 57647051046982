<form [formGroup]="form">
  <mbsc-datepicker
    #range
    select="range"
    formControlName="timeStartEnd"
    [controls]="['time']"
    hidden
    [options]="rangePickerSettings"
    display="center"
    [setText]="'button.apply' | translate"
    [cancelText]="'cancel' | translate"
    [buttons]="[ 'cancel', 'set' ]"
    [locale]="mbscLocale$ | async"
    [themeVariant]="mbscThemeVariant$ | async"
    theme="windows"
    [rangeStartLabel]="'sendProtocol.startDate' | translate"
    [rangeEndLabel]="'sendProtocol.endDate' | translate"
  >
  </mbsc-datepicker>
  <ion-grid>
    <!-- TODO: Uncomment once separate mail sending is supported -->
    <!-- <ion-row>
      <ion-col>
        <ion-item>
          <ion-label color="text-primary" position="floating">{{ 'sendProtocol.protocolConfig.nextMeeting.subject' | translate }}</ion-label>
          <ion-textarea auto-grow="true" color="text-primary" formControlName="subject" rows="1" type="text"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label color="text-primary" position="floating">{{ 'sendProtocol.protocolConfig.nextMeeting.body' | translate }}</ion-label>
          <ion-textarea auto-grow="true" color="text-primary" formControlName="body" rows="1" type="text"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row> -->

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-input label="{{ 'sendProtocol.date' | translate }}"
            label-placement="floating"
            formControlName="date"
            mbsc-datepicker [controls]="['calendar']"
            [mbscOptions]="datePickerSettings"
            [showWeekNumbers]="true"
            [firstSelectDay]="1"
            [firstDay]="1"
            [setText]="'button.apply' | translate"
            [cancelText]="'cancel' | translate"
            [buttons]="[ 'cancel', 'set' ]"
            [locale]="mbscLocale$ | async"
            [themeVariant]="mbscThemeVariant$ | async"
            theme="windows"
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item>
          <ion-input label="{{ 'sendProtocol.startEnd' | translate }}"
            label-placement="floating"
            [value]="(nextMeeting.timeStart ? nextMeeting.timeStart + ' - ' + nextMeeting.timeEnd : '')"
            readonly
            (click)="range.open()"
            [disabled]="!nextMeeting.date"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="d-flex ion-align-items-center justify-between">
    <ion-text class="text-primary padding-left-10">
      {{ "sendProtocol.protocolConfig.nextMeeting.invitationFor" | translate }}
    </ion-text>
    <ion-item lines="none">
      <ion-toggle slot="end" formControlName="groupMeeting" appSlimToggle></ion-toggle>
      <ion-label class="ion-text-right">{{ "sendProtocol.protocolConfig.nextMeeting.groupMeeting" | translate }}</ion-label>
    </ion-item>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-text class="text-secondary size-sm">
          {{ "sendProtocol.protocolConfig.nextMeeting.recipientsWillReceiveInvite" | translate }}
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
