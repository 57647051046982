import {ColumnConfig} from '../ColumnConfig';
import {ClientColumnSet} from './AddressAndClientColumnSet';

export const CountryColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'name', def: null},
  {name: 'tax_rate', prop: 'taxRate'},
  {name: 'no_tax_if_uid', prop: 'noTaxIfUid'},
  {name: 'clients', fk: 'countryId', mappedBy: ClientColumnSet, transient: true},
];
