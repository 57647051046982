import {Injectable} from '@angular/core';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {IdType, PdfPlanPage, PdfPlanPageMarking, PdfPlanPageMarkingBase, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {v4 as uuid4} from 'uuid';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/pdfPlanPageMarkings';

@Injectable({
  providedIn: 'root',
})
export class PdfPlanPageMarkingDataService extends AbstractProjectAwareDataService<PdfPlanPageMarking> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.PDF_PLAN_PAGE_MARKING,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  public dataByPdfPlanPage(pdfPlanPageId: IdType): Observable<Array<PdfPlanPageMarking>> {
    return this.data.pipe(map((pdfPlanPageMarkings) => pdfPlanPageMarkings.filter((pdfPlanPageMarking) => pdfPlanPageMarking.pdfPlanPageId === pdfPlanPageId)));
  }

  public getByProtocolEntry(protocolEntryId: IdType): Observable<Array<PdfPlanPageMarking>> {
    return this.data.pipe(map((entries) => entries.filter((pdfPlanPageMarking) => pdfPlanPageMarking.protocolEntryId === protocolEntryId)));
  }

  public toPdfPlanPageMarking(pdfPlanPageMarking: PdfPlanPageMarking | PdfPlanPageMarkingBase, protocolEntryId: IdType): PdfPlanPageMarking {
    if ('id' in pdfPlanPageMarking && pdfPlanPageMarking.id) {
      return pdfPlanPageMarking as PdfPlanPageMarking;
    }
    return {
      id: uuid4(),
      markings: pdfPlanPageMarking.markings,
      pdfPlanPageId: pdfPlanPageMarking.pdfPlanPageId,
      protocolEntryId,
      name: pdfPlanPageMarking.name,
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    };
  }

  public async upsertPdfPlanPageMarking(
    projectId: IdType,
    protocolEntryId: IdType | undefined,
    ...pdfPlanPageMarkings: Array<PdfPlanPageMarking | PdfPlanPageMarkingBase>
  ): Promise<Array<PdfPlanPageMarking>> {
    const upserted = Array<PdfPlanPageMarking>();
    for (const pdfPlanPageMarking of pdfPlanPageMarkings) {
      if ('id' in pdfPlanPageMarking && pdfPlanPageMarking.id) {
        await this.update(pdfPlanPageMarking, projectId);
        upserted.push(pdfPlanPageMarking);
      } else {
        const newPdfPlanPageMarking: PdfPlanPageMarking = {
          id: uuid4(),
          markings: pdfPlanPageMarking.markings,
          pdfPlanPageId: pdfPlanPageMarking.pdfPlanPageId,
          protocolEntryId,
          name: pdfPlanPageMarking.name,
          changedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
        };
        await this.insert(newPdfPlanPageMarking, projectId);
        upserted.push(newPdfPlanPageMarking);
      }
    }
    return upserted;
  }

  public getByPlanPages(pdfPlanPages: Array<PdfPlanPage> | undefined): Observable<Array<PdfPlanPageMarking>> {
    if (!pdfPlanPages?.length) {
      return of([]);
    }
    return this.getByPlanPagesId(pdfPlanPages.map((v) => v.id));
  }

  public getByPlanPagesId(planPageIds: Array<IdType>): Observable<Array<PdfPlanPageMarking>> {
    return this.data.pipe(map((entries) => entries.filter((pdfPlanMarkerProtocolEntry) => planPageIds.includes(pdfPlanMarkerProtocolEntry.pdfPlanPageId))));
  }
}
