import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {AppTokenPermission, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const REST_ENDPOINT_URI = 'api/data/appTokenPermissions/';

@Injectable({
  providedIn: 'root',
})
export class AppTokenPermissionDataService extends AbstractClientAwareDataService<AppTokenPermission> implements OnDestroy {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(StorageKeyEnum.APP_TOKEN_PERMISSION, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.isClientAdmin;
  }

  public getByAppTokenId(appTokenId: IdType): Observable<Array<AppTokenPermission>> {
    return this.data.pipe(map((appTokenPermissions) => appTokenPermissions.filter((appTokenPermission) => appTokenPermission.appTokenId === appTokenId)));
  }
}
