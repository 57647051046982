import {HttpErrorResponse} from '@angular/common/http';
import {ErrorCodeAuthenticationType} from 'submodules/baumaster-v2-common';

export function isHttpError(e: unknown, errorStatus?: number): e is HttpErrorResponse {
  return e instanceof HttpErrorResponse && (!errorStatus || e.status === errorStatus);
}

export const isHttpApplicationError = (e: unknown, errorCode?: ErrorCodeAuthenticationType): e is HttpErrorResponse => {
  const httpApplicationError = isHttpError(e, 400);

  return errorCode ? httpApplicationError && e.error?.errorCode === errorCode : httpApplicationError;
};
