import {Type} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {IonicRouteStrategy} from '@ionic/angular';
import {ProjectRoomPdfPlanFolderPage} from '../pages/project-room/project-room-pdf-plan-folder/project-room-pdf-plan-folder.page';
import {ProjectRoomPdfPlanFoldersPage} from '../pages/project-room/project-room-pdf-plan-folders/project-room-pdf-plan-folders.page';
import {ProjectRoomPage} from '../pages/project-room/project-room.page';
import {ProtocolEntriesListPage} from '../pages/protocol-entries-list/protocol-entries-list.page';
import {ProtocolEntriesPage} from '../pages/protocol-entries/protocol-entries.page';
import {ProtocolEntryPage} from '../pages/protocol-entries/protocol-entry/protocol-entry.page';
import {ProtocolEntryEditPage} from '../pages/protocol-entry-edit/protocol-entry-edit.page';
import {ProtocolListPage} from '../pages/protocol-list/protocol-list.page';
import {ProtocolsPage} from '../pages/protocols/protocols.page';
import {TaskCardListPage} from '../pages/task-card-list/task-card-list.page';
import {TasksCardViewPage} from '../pages/tasks-card-view/tasks-card-view.page';
import {TasksListViewPage} from '../pages/tasks-list-view/tasks-list-view.page';
import {TasksPage} from '../pages/tasks/tasks.page';

export class BauRouteReuseStrategy extends IonicRouteStrategy {
  private readonly UseAngularReuseStrategyForComponents: (string | Type<any>)[] = [
    ProtocolsPage,
    ProtocolListPage,
    ProtocolEntriesPage,
    ProtocolEntriesListPage,
    ProtocolEntryPage,
    ProtocolEntryEditPage,

    ProjectRoomPdfPlanFolderPage,
    ProjectRoomPdfPlanFoldersPage,
    ProjectRoomPage,

    TasksPage,
    TasksCardViewPage,
    TasksListViewPage,
    TaskCardListPage,
  ];

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.routeConfig !== curr.routeConfig) {
      return false;
    }

    if (this.UseAngularReuseStrategyForComponents.includes(future.component) || this.UseAngularReuseStrategyForComponents.includes(future.parent?.component)) {
      return true;
    }

    return super.shouldReuseRoute(future, curr);
  }
}
