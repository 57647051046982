import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {asyncScheduler, Observable, ObservedValueOf, of} from 'rxjs';
import {distinctUntilChanged, map, observeOn, shareReplay, switchMap} from 'rxjs/operators';
import {EntryCardModel, EntryLayout} from 'src/app/model/entry-card-model';
import {Nullish} from 'src/app/model/nullish';
import {DEFAULT_ENTRY_PRIORITY, PROTOCOL_LAYOUT_NAME_CONTINUOUS, PROTOCOL_LAYOUT_NAME_SHORT, PROTOCOL_LAYOUT_NAME_STANDARD} from 'src/app/shared/constants';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {
  compareObjectsWithObjectWithComparator,
  compareObjectsWithObjectWithPrimitiveValues,
  compareObjectsWithPrimitiveArrays,
  compareObjectsWithPrimitiveValues,
  comparePrimitiveArrays,
  comparePrimitiveSets,
  compareUnsortedArraysByObjectKeys,
  distinctUntilUnsortedArraysByObjectKeysChanged,
  Primitive,
} from 'src/app/utils/compare-utils';
import {convertISOStringToDate} from 'src/app/utils/date-utils';
import {entryShortIdWithDepsAndValuesAndProtocolShortId, isEntryTypeTask, protocolShortIdWithDepsAndValues} from 'src/app/utils/protocol-entry-utils';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';
import {getObjectNameWithDeletedSuffix, memoizedConvertRichTextToPlainText} from 'src/app/utils/text-utils';
import {IdType, Protocol, ProtocolEntry, ProtocolEntryIconStatus, ProtocolLayout, ProtocolSortEntriesByEnum} from 'submodules/baumaster-v2-common';
import {getProtocolEntryStatus} from 'submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {ProtocolLayoutDataService} from '../data/protocol-layout-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';

// 24 hours from created at date is considered as new
const IS_NEW_PERIOD_IN_MS = 24 * 60 * 60 * 1000;
// Maximum 200 characters is taken into account for text display
const MAX_CHARACTERS_IN_TEXT_PREVIEW = 200;

const LAYOUT_NAME_TO_ENTRY_LAYOUT: Record<typeof PROTOCOL_LAYOUT_NAME_STANDARD | typeof PROTOCOL_LAYOUT_NAME_CONTINUOUS | typeof PROTOCOL_LAYOUT_NAME_SHORT, EntryLayout> = {
  [PROTOCOL_LAYOUT_NAME_STANDARD]: 'STANDARD',
  [PROTOCOL_LAYOUT_NAME_CONTINUOUS]: 'CONTINUOUS',
  [PROTOCOL_LAYOUT_NAME_SHORT]: 'SHORT',
};

@Injectable({
  providedIn: 'root',
})
export class EntryService {
  private getObjectNameWithDeletedSuffix = <T extends {isActive: boolean; name: string}>(obj: T) => getObjectNameWithDeletedSuffix(obj, this.translateService).name;

  private protocolNumberById$: Observable<Record<IdType, Protocol['number']>> = this.protocolDataService.dataGroupedById.pipe(
    distinctUntilChanged(compareObjectsWithObjectWithPrimitiveValues(['number'])),
    map((v) => _.mapValues(v, ({number}) => number))
  );

  private protocolCreatedAtById$: Observable<Record<IdType, string | undefined>> = this.protocolDataService.dataGroupedById.pipe(
    distinctUntilChanged(compareObjectsWithObjectWithComparator((a, b) => convertISOStringToDate(a?.createdAt)?.toISOString() === convertISOStringToDate(b?.createdAt)?.toISOString())),
    map((v) => _.mapValues(v, ({createdAt}) => (createdAt ? new Date(createdAt).toISOString() : undefined)))
  );

  private layoutByTypeId$: Observable<Record<IdType, EntryLayout>> = combineLatestAsync([
    this.protocolLayoutDataService.dataGroupedById.pipe(
      distinctUntilChanged(compareObjectsWithObjectWithPrimitiveValues(['name'])),
      map((layoutById) => _.mapValues<Record<IdType, ProtocolLayout>, EntryLayout>(layoutById, ({name}) => LAYOUT_NAME_TO_ENTRY_LAYOUT[name] ?? 'STANDARD'))
    ),
    this.protocolTypeDataService.data.pipe(distinctUntilChanged(compareUnsortedArraysByObjectKeys(['layoutId']))),
  ]).pipe(
    map(([layoutById, protocolTypes]) => _.mapValues(_.keyBy(protocolTypes, 'id'), ({layoutId}) => layoutById[layoutId] ?? 'STANDARD')),
    distinctUntilChanged(compareObjectsWithPrimitiveValues)
  );

  private layoutByProtocolId$: Observable<Record<IdType, EntryLayout>> = combineLatestAsync([
    this.protocolDataService.dataGroupedById.pipe(
      distinctUntilChanged(compareObjectsWithObjectWithPrimitiveValues(['typeId'])),
      map((v) => _.mapValues(v, ({typeId}) => typeId))
    ),
    this.layoutByTypeId$,
  ]).pipe(map(([protocolByTypeId, layoutByTypeId]) => _.mapValues(protocolByTypeId, (typeId) => layoutByTypeId[typeId])));

  private protocolDisplayNumberById$ = combineLatestAsync([
    this.protocolDataService.dataGroupedById.pipe(distinctUntilChanged(compareObjectsWithObjectWithPrimitiveValues(['number', 'typeId']))),
    this.protocolTypeDataService.dataGroupedById.pipe(
      map((protocolTypeById) => _.mapValues(protocolTypeById, 'code')),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    ),
  ]).pipe(
    map(([protocolById, protocolCodeById]) => _.mapValues(protocolById, ({number, typeId}) => protocolShortIdWithDepsAndValues(this.locale, protocolCodeById[typeId], number))),
    distinctUntilChanged(compareObjectsWithPrimitiveValues)
  );
  private isProtocolClosedById$: Observable<Record<IdType, boolean>> = this.protocolDataService.dataGroupedById.pipe(
    map((protocolById) => _.mapValues(protocolById, ({closedAt}) => Boolean(closedAt))),
    distinctUntilChanged(compareObjectsWithPrimitiveValues)
  );

  readonly taskProtocol$: Observable<Protocol | undefined> = this.protocolDataService.data.pipe(
    map((protocols) => protocols.find(isTaskProtocol)),
    distinctUntilChanged<Protocol | undefined>(_.isEqual)
  );

  readonly taskTypeDefault$ = this.protocolEntryTypeDataService.data.pipe(map((types) => types.find(isEntryTypeTask) ?? types.find((type) => type.statusFieldActive)));

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private attachmentChatDataService: AttachmentChatDataService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
    private protocolEntryChatDataService: ProtocolEntryChatDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private nameableDropdownDataService: NameableDropdownDataService,
    private protocolLayoutDataService: ProtocolLayoutDataService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private companyDataService: CompanyDataService,
    private craftDataService: CraftDataService,
    private protocolDataService: ProtocolDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService
  ) {}

  getEntriesForProtocolEntries$(
    protocolEntries$: Observable<ProtocolEntry[]>,
    {
      taskProtocolIds,
      protocolIds,
      carriedOverIds$ = of([]),
      entryIdToOriginalProtocolId$,
      includeOrphanSubentries = false,
      sortBy$,
    }: {
      taskProtocolIds?: Nullish<IdType[]>;
      protocolIds?: Nullish<IdType[]>;
      carriedOverIds$?: Nullish<Observable<IdType[]>>;
      /**
       * Only necessary if entry's `protocolId` is not equal to the source (original) protocol id
       */
      entryIdToOriginalProtocolId$?: Nullish<Observable<Record<IdType, Nullish<IdType>>>>;
      /**
       * If true, orphan subentries will be included in the result.
       *
       * Note: orphan subentries will have isSubtask set to true
       */
      includeOrphanSubentries?: boolean;
      sortBy$?: Observable<Nullish<ProtocolSortEntriesByEnum>>;
    } = {}
  ): Observable<EntryCardModel[]> {
    const theSortBy$ = sortBy$ ? sortBy$.pipe(distinctUntilChanged()) : of(null);
    const createdInProtocolIdsSet$ = protocolEntries$.pipe(
      distinctUntilUnsortedArraysByObjectKeysChanged(['createdInProtocolId']),
      map((entries) => new Set(entries.map(({createdInProtocolId}) => createdInProtocolId))),
      distinctUntilChanged(comparePrimitiveSets)
    );
    const theEntryIdToOriginalProtocolId$ = entryIdToOriginalProtocolId$ ?? of({} as Record<IdType, Nullish<IdType>>);
    const additionalProtocolIds$ =
      protocolIds || entryIdToOriginalProtocolId$
        ? combineLatestAsync([createdInProtocolIdsSet$, theEntryIdToOriginalProtocolId$]).pipe(
            map(([createdInProtocolIdsSet, theEntryIdToOriginalProtocolId]) => [...createdInProtocolIdsSet.values(), ...Object.values(theEntryIdToOriginalProtocolId)]),
            distinctUntilChanged(comparePrimitiveArrays),
            shareReplay({refCount: true, bufferSize: 1})
          )
        : undefined;

    const protocolNumberById$ = this.getDistinctObservableForProtocolIds$(protocolIds, additionalProtocolIds$, this.protocolNumberById$);
    const entries$ = protocolEntries$
      .pipe(
        distinctUntilUnsortedArraysByObjectKeysChanged([
          'companyId',
          'allCompanies',
          'craftId',
          'parentId',
          'priority',
          'status',
          'title',
          'text',
          'typeId',
          'todoUntil',
          'number',
          'internalAssignmentId',
          'locationId',
          'createdAt',
          'isContinuousInfo',
          'unitId',
        ])
      )
      .pipe(
        map((unsortedEntries) => {
          const entries = _.orderBy(unsortedEntries, ['number'], ['asc', 'asc']);

          const childrenByParentId = _.groupBy(
            entries.filter((entry) => entry.parentId),
            'parentId'
          );

          const entriesIds = new Set<IdType>();

          if (includeOrphanSubentries) {
            entries.forEach(({id}) => entriesIds.add(id));
          }

          return entries.reduce<(ProtocolEntry & {subEntriesCount: number; parentEntryNumber?: number})[]>((acc, entry) => {
            if (entry.parentId) {
              if (includeOrphanSubentries && !entriesIds.has(entry.parentId)) {
                return [...acc, {...entry, subEntriesCount: 0}];
              }
              return acc;
            }

            const children = childrenByParentId[entry.id];

            if (children) {
              return [...acc, {...entry, subEntriesCount: children.length}, ...children.map((e) => ({...e, subEntriesCount: 0, parentEntryNumber: entry.number}))];
            }
            return [...acc, {...entry, subEntriesCount: 0}];
          }, []);
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );

    const entriesIds$ = entries$.pipe(
      map((entries) => new Set(entries.map((entry) => entry.id))),
      distinctUntilChanged(comparePrimitiveSets)
    );

    const hasEntryAttachment$ = combineLatestAsync([this.attachmentEntryDataService.dataByProtocolEntryId$, this.attachmentChatDataService.dataByProtocolEntryId$, entriesIds$]).pipe(
      map(
        ([attachmentsByEntryId, chatAttachmentsByEntryId, entriesSet]) =>
          new Set(
            Object.keys(attachmentsByEntryId)
              .concat(Object.keys(chatAttachmentsByEntryId))
              .filter((id) => entriesSet.has(id))
          )
      ),
      distinctUntilChanged(comparePrimitiveSets)
    );

    const hasMarkers$ = combineLatestAsync([this.pdfPlanMarkerProtocolEntryDataService.dataByProtocolEntryId$, entriesIds$]).pipe(
      map(([markersByEntryId, entriesSet]) => new Set(Object.keys(markersByEntryId).filter((id) => entriesSet.has(id)))),
      distinctUntilChanged(comparePrimitiveSets)
    );

    const hasChats$ = combineLatestAsync([this.protocolEntryChatDataService.dataByProtocolEntryId$, entriesIds$]).pipe(
      map(([chatsByEntryId, entriesSet]) => new Set(Object.keys(chatsByEntryId).filter((id) => entriesSet.has(id)))),
      distinctUntilChanged(comparePrimitiveSets)
    );

    const typeIdsSet$ = entries$.pipe(
      map((entries) => new Set(entries.map(({typeId}) => typeId))),
      distinctUntilChanged(comparePrimitiveSets)
    );

    const typeName$ = typeIdsSet$.pipe(
      switchMap((typeIds) =>
        this.protocolEntryTypeDataService.dataGroupedById.pipe(map((typeById) => _.mapValues(_.pick(typeById, Array.from(typeIds.values())), this.getObjectNameWithDeletedSuffix)))
      ),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );

    const typeActionable$ = typeIdsSet$.pipe(
      switchMap((typeIds) => this.protocolEntryTypeDataService.dataGroupedById.pipe(map((typeById) => _.mapValues(_.pick(typeById, Array.from(typeIds.values())), 'statusFieldActive')))),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );

    const companyName$: Observable<Record<string | 'true', string>> = entries$.pipe(
      map((entries) => new Set(entries.map(({companyId}) => companyId ?? 'true'))),
      distinctUntilChanged(comparePrimitiveSets),
      switchMap((companyIds) =>
        this.companyDataService.dataGroupedById.pipe(
          map((companyById) => ({
            ..._.mapValues(_.pick(companyById, Array.from(companyIds.values())), this.getObjectNameWithDeletedSuffix),
            true: this.translateService.instant('project_team'),
          }))
        )
      ),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );

    const projectCompanySortOrder$: Observable<Record<IdType, Nullish<number>>> = theSortBy$.pipe(
      switchMap((sortBy) => (sortBy === ProtocolSortEntriesByEnum.COMPANY ? this.projectCompanyDataService.dataByCompanyId$ : of({}))),
      map((projectCompaniesByCompanyId) => _.mapValues(projectCompaniesByCompanyId, ({sortOrder}) => sortOrder)),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );

    const nameableDropdownName$: Observable<Record<IdType, string>> = theSortBy$.pipe(
      switchMap((sortBy) => (sortBy === ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN ? this.nameableDropdownDataService.dataGroupedById : of({}))),
      map((nameableDropdownById) => _.mapValues(nameableDropdownById, ({name}) => name)),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );

    const observerCompanyIdsByEntryId$ = combineLatestAsync([this.protocolEntryCompanyDataService.dataGroupedByEntryId, entriesIds$]).pipe(
      map(([observerCompaniesByEntryId, entriesIds]) => {
        return _.mapValues(_.pick(observerCompaniesByEntryId, [...entriesIds]), (companies) => companies.map(({companyId}) => companyId));
      }),
      distinctUntilChanged(compareObjectsWithPrimitiveArrays)
    );

    const craftName$ = entries$.pipe(
      map((entries) => new Set(entries.map(({craftId}) => craftId))),
      distinctUntilChanged(comparePrimitiveSets),
      switchMap((craftIds) => this.craftDataService.dataGroupedById.pipe(map((craftById) => _.mapValues(_.pick(craftById, Array.from(craftIds.values())), this.getObjectNameWithDeletedSuffix)))),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );

    const protocolDisplayNumberById$ = this.getDistinctObservableForProtocolIds$(protocolIds, additionalProtocolIds$, this.protocolDisplayNumberById$);
    const isProtocolClosedById$ = this.getDistinctObservableForProtocolIds$(protocolIds, additionalProtocolIds$, this.isProtocolClosedById$);
    const layoutByProtocolId$ = this.getDistinctObservableForProtocolIds$(protocolIds, additionalProtocolIds$, this.layoutByProtocolId$);
    const protocolCreatedAtById$ = this.getDistinctObservableForProtocolIds$(protocolIds, additionalProtocolIds$, this.protocolCreatedAtById$);

    const tasks$ = combineLatestAsync([
      entries$,
      hasEntryAttachment$,
      hasMarkers$,
      hasChats$,
      typeName$,
      companyName$,
      projectCompanySortOrder$,
      craftName$,
      nameableDropdownName$,
      protocolDisplayNumberById$,
      protocolNumberById$,
      typeActionable$,
      observerCompanyIdsByEntryId$,
      isProtocolClosedById$,
      carriedOverIds$,
      theEntryIdToOriginalProtocolId$,
      layoutByProtocolId$,
      protocolCreatedAtById$,
      theSortBy$,
    ]).pipe(
      map(
        ([
          entries,
          hasEntryAttachment,
          hasMarkers,
          hasChats,
          typeName,
          companyName,
          projectCompanySortOrder,
          craftName,
          nameableDropdownName,
          protocolDisplayNumberById,
          protocolNumberById,
          typeActionable,
          observerCompanyIdsByEntryId,
          isProtocolClosedById,
          carriedOverIds,
          theEntryIdToOriginalProtocolId,
          layoutByProtocolId,
          protocolCreatedAtById,
          sortBy,
        ]: [
          ObservedValueOf<typeof entries$>,
          ObservedValueOf<typeof hasEntryAttachment$>,
          ObservedValueOf<typeof hasMarkers$>,
          ObservedValueOf<typeof hasChats$>,
          ObservedValueOf<typeof typeName$>,
          ObservedValueOf<typeof companyName$>,
          ObservedValueOf<typeof projectCompanySortOrder$>,
          ObservedValueOf<typeof craftName$>,
          ObservedValueOf<typeof nameableDropdownName$>,
          ObservedValueOf<typeof protocolDisplayNumberById$>,
          ObservedValueOf<typeof protocolNumberById$>,
          ObservedValueOf<typeof typeActionable$>,
          ObservedValueOf<typeof observerCompanyIdsByEntryId$>,
          ObservedValueOf<typeof isProtocolClosedById$>,
          ObservedValueOf<typeof carriedOverIds$>,
          ObservedValueOf<typeof theEntryIdToOriginalProtocolId$>,
          ObservedValueOf<typeof layoutByProtocolId$>,
          ObservedValueOf<typeof protocolCreatedAtById$>,
          ObservedValueOf<typeof theSortBy$>,
        ]) => {
          if (!entries) {
            return [];
          }

          const result = entries.map<EntryCardModel>((entry, index) => ({
            id: entry.id,
            // Order will be filled in the next step
            mainEntryOrder: 0,
            subEntryOrder: undefined,
            layout: layoutByProtocolId[entry.protocolId],
            createdAt: entry.createdAt,
            entryNumber: entry.number,
            mainEntryNumber: entry.parentEntryNumber ?? entry.number,
            parentId: entry.parentId,
            internalAssignmentId: entry.internalAssignmentId,
            company: companyName[entry.companyId ?? `${entry.allCompanies}`],
            craft: craftName[entry.craftId],
            hasAttachments: hasEntryAttachment.has(entry.id),
            hasChat: hasChats.has(entry.id),
            hasMarker: hasMarkers.has(entry.id),
            isSubtask: Boolean(entry.parentId),
            priority: entry.priority ?? DEFAULT_ENTRY_PRIORITY,
            status: typeActionable[entry.typeId] || layoutByProtocolId[entry.protocolId] === 'SHORT' ? getProtocolEntryStatus(entry) : ProtocolEntryIconStatus.INFO,
            subEntriesCount: entry.parentId ? undefined : entry.subEntriesCount,
            titleText: entry.title,
            text: memoizedConvertRichTextToPlainText(entry.text ?? '').substring(0, MAX_CHARACTERS_IN_TEXT_PREVIEW),
            type: typeName[entry.typeId] ?? '',
            todoUntil: convertISOStringToDate(entry.todoUntil),
            isNew: Date.now() - convertISOStringToDate(entry.createdAt).getTime() < IS_NEW_PERIOD_IN_MS,
            ...(taskProtocolIds?.includes(entry.protocolId)
              ? {taskNumber: entry.number}
              : {
                  protocolEntryNumber: entryShortIdWithDepsAndValuesAndProtocolShortId(
                    this.locale,
                    protocolDisplayNumberById[theEntryIdToOriginalProtocolId[entry.id] ?? entry.protocolId],
                    entry.number,
                    entry.parentEntryNumber
                  ),
                  isCarriedOver: layoutByProtocolId[entry.protocolId] === 'CONTINUOUS' && !!carriedOverIds?.includes(entry.id),
                  createdInProtocol: layoutByProtocolId[entry.protocolId] === 'CONTINUOUS' && protocolDisplayNumberById[entry.createdInProtocolId],
                  originalProtocolId: theEntryIdToOriginalProtocolId[entry.id],
                  showCreatedInProtocol:
                    layoutByProtocolId[entry.protocolId] === 'CONTINUOUS' &&
                    protocolIds?.length === 1 &&
                    !!entry.createdInProtocolId &&
                    entry.createdInProtocolId !== entry.protocolId &&
                    protocolNumberById[entry.createdInProtocolId] > protocolNumberById[protocolIds[0]],
                }),
            protocolId: entry.protocolId,
            createdInProtocolId: entry.createdInProtocolId,
            companyId: entry.companyId,
            craftId: entry.craftId,
            locationId: entry.locationId,
            typeId: entry.typeId,
            allCompanies: entry.allCompanies,
            nameableDropdownId: entry.nameableDropdownId,
            createdById: entry.createdById,
            observerCompanies: observerCompanyIdsByEntryId[entry.id],
            isProtocolClosed: isProtocolClosedById[entry.createdInProtocolId ?? theEntryIdToOriginalProtocolId[entry.id] ?? entry.protocolId] ?? false,
            isContinuousInfo: layoutByProtocolId[entry.protocolId] === 'CONTINUOUS' && entry.isContinuousInfo,
            unitId: entry.unitId,
          }));

          let mainEntriesIteratees: (keyof EntryCardModel | ((v: EntryCardModel) => Nullish<boolean | number | string>))[];
          let mainEntriesOrders: ('asc' | 'desc')[];
          let inGroupEntriesIteratees: (keyof EntryCardModel | ((v: EntryCardModel) => Nullish<boolean | number | string>))[];
          let inGroupEntriesOrders: ('asc' | 'desc')[];

          const isOpenEntryComparer = (entry: EntryCardModel) => Boolean(entry.isCarriedOver || entry.createdInProtocolId);
          const projectCompanyComparer = (entry: EntryCardModel) => {
            if (entry.allCompanies) {
              return Infinity;
            }

            return projectCompanySortOrder[entry.companyId];
          };
          const protocolNumberComparer = (entry: EntryCardModel) => protocolNumberById[theEntryIdToOriginalProtocolId[entry.id] ?? entry.protocolId];
          const protocolCreatedAtComparer = (entry: EntryCardModel) => protocolCreatedAtById[theEntryIdToOriginalProtocolId[entry.id] ?? entry.protocolId];
          const entryCreatedAtComparer = (entry: EntryCardModel) => convertISOStringToDate(entry.createdAt)?.toISOString();
          const nameableDropdownComparer = (entry: EntryCardModel) => nameableDropdownName[entry.nameableDropdownId];

          switch (sortBy) {
            case ProtocolSortEntriesByEnum.COMPANY:
              mainEntriesIteratees = [isOpenEntryComparer, projectCompanyComparer, 'company', protocolNumberComparer, 'mainEntryNumber'];
              mainEntriesOrders = ['desc', 'asc', 'asc', 'asc', 'asc'];
              inGroupEntriesIteratees = [projectCompanyComparer, 'company', 'entryNumber'];
              inGroupEntriesOrders = ['asc', 'asc', 'asc'];
              break;
            case ProtocolSortEntriesByEnum.CRAFT:
              mainEntriesIteratees = [isOpenEntryComparer, 'craft', protocolNumberComparer, 'mainEntryNumber'];
              mainEntriesOrders = ['desc', 'asc', 'asc', 'asc'];
              inGroupEntriesIteratees = ['craft', 'entryNumber'];
              inGroupEntriesOrders = ['asc', 'asc'];
              break;
            case ProtocolSortEntriesByEnum.NAMEABLE_DROPDOWN:
              mainEntriesIteratees = [isOpenEntryComparer, nameableDropdownComparer, protocolNumberComparer, 'mainEntryNumber'];
              mainEntriesOrders = ['desc', 'asc', 'asc', 'asc'];
              inGroupEntriesIteratees = [nameableDropdownComparer, 'entryNumber'];
              inGroupEntriesOrders = ['asc', 'asc'];
              break;
            case ProtocolSortEntriesByEnum.CREATED_AT_DATE:
              mainEntriesIteratees = [isOpenEntryComparer, protocolCreatedAtComparer, protocolNumberComparer, entryCreatedAtComparer, 'mainEntryNumber'];
              mainEntriesOrders = ['desc', 'asc', 'asc', 'asc', 'asc'];
              inGroupEntriesIteratees = [entryCreatedAtComparer, 'entryNumber'];
              inGroupEntriesOrders = ['asc', 'asc'];
              break;
            default:
              mainEntriesIteratees = [isOpenEntryComparer, protocolNumberComparer, 'mainEntryNumber'];
              mainEntriesOrders = ['desc', 'asc', 'asc'];
              inGroupEntriesIteratees = ['entryNumber'];
              inGroupEntriesOrders = ['asc'];
          }

          type GroupIterateeReturnType = `1.0.${keyof EntryCardModel}` | ((group: [string, EntryCardModel[]]) => Nullish<boolean | number>);
          const groupIteratee = (iteratee: ((entry: EntryCardModel) => Nullish<boolean | number>) | keyof EntryCardModel): GroupIterateeReturnType =>
            typeof iteratee === 'string' ? `1.0.${iteratee}` : (group: [string, EntryCardModel[]]) => iteratee(group[1][0]);

          const groupedByProtocolAndMainEntry = _.chain(result)
            .groupBy((entry) => `${theEntryIdToOriginalProtocolId[entry.id] ?? entry.protocolId}/${entry.mainEntryNumber}`)
            .mapValues((group) => _.orderBy<(typeof group)[0]>(group, ['isSubtask', ...inGroupEntriesIteratees], ['asc', ...inGroupEntriesOrders]))
            .entries()
            .orderBy(mainEntriesIteratees.map(groupIteratee), mainEntriesOrders)
            .map(([, group], mainEntryOrder) => group.map((v, subEntryOrder) => ({...v, subEntryOrder, mainEntryOrder})))
            .flatten()
            .value();
          return groupedByProtocolAndMainEntry;
        }
      ),
      observeOn(asyncScheduler),
      shareReplay({refCount: true, bufferSize: 1})
    );

    return tasks$;
  }

  private getDistinctObservableForProtocolIds$<T extends Primitive>(
    protocolIds: Nullish<IdType[]>,
    additionalProtocolIds$: Nullish<Observable<IdType[]>>,
    recordsById$: Observable<Record<IdType, T>>
  ): Observable<Record<IdType, T>> {
    if (!protocolIds) {
      return recordsById$;
    }

    if (!additionalProtocolIds$) {
      return recordsById$.pipe(distinctUntilChanged((a, b) => compareObjectsWithPrimitiveValues(_.pick(a, protocolIds), _.pick(b, protocolIds))));
    }

    return combineLatestAsync([recordsById$, additionalProtocolIds$]).pipe(
      distinctUntilChanged(([a, aOriginalProtocolIds], [b, bOriginalProtocolIds]) => {
        const allProtocolIdsA = [...(protocolIds ?? []), ...(aOriginalProtocolIds ?? [])];
        const allProtocolIdsB = [...(protocolIds ?? []), ...(bOriginalProtocolIds ?? [])];
        return compareObjectsWithPrimitiveValues(_.pick(a, allProtocolIdsA), _.pick(b, allProtocolIdsB));
      }),
      map(([recordsById]) => recordsById)
    );
  }

  /**
   * **Caution!** This method is **not** intended to use with continuous protocols!
   *
   * @param protocolIds If not provided, method will return all entries in the project
   */
  getEntriesForProtocols$(protocolIds?: Nullish<IdType[]>, taskProtocolIds?: Nullish<IdType[]>): Observable<EntryCardModel[]> {
    return this.getEntriesForProtocolEntries$(this.protocolEntryDataService.getByProtocolIds(protocolIds), {protocolIds, taskProtocolIds});
  }

  getEntriesForProtocolWithAnyLayout$(protocolId: IdType): Observable<EntryCardModel[]> {
    const protocolIds = [protocolId];
    const protocolEntriesOrOpen$ = this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId).pipe(
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
    const carriedOverIds$ = protocolEntriesOrOpen$.pipe(
      map((entries) => entries.filter((entry) => entry.isOpenEntry).map(({id}) => id)),
      distinctUntilChanged(comparePrimitiveArrays)
    );
    const entryIdToOriginalProtocolId$ = protocolEntriesOrOpen$.pipe(
      map((entries) =>
        _.mapValues(
          _.keyBy(
            entries.filter((entry) => entry.originalProtocolId),
            'id'
          ),
          'originalProtocolId'
        )
      ),
      distinctUntilChanged(compareObjectsWithPrimitiveValues)
    );
    const sortBy$ = this.protocolDataService.getById(protocolId).pipe(
      map((protocol) => protocol?.sortEntriesBy as ProtocolSortEntriesByEnum),
      distinctUntilChanged()
    );
    return this.getEntriesForProtocolEntries$(protocolEntriesOrOpen$, {protocolIds, carriedOverIds$, entryIdToOriginalProtocolId$, sortBy$});
  }
}
