import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ProjectDataService} from '../services/data/project-data.service';
import {debounceTime} from 'rxjs/operators';
import {ProjectOmissionMessage, ProjectOmissionService} from 'src/app/services/project/project-omission.service';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProjectOmitterGuard {
  private projectOmitterSubscription: Subscription | undefined;

  constructor(
    private projectDataService: ProjectDataService,
    private projectOmissionService: ProjectOmissionService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.projectOmitterSubscription?.unsubscribe();
    this.projectOmitterSubscription = undefined;
    this.projectOmitterSubscription = combineLatest([this.projectDataService.dataAcrossClients$, this.projectDataService.currentProjectObservable])
      .pipe(debounceTime(500))
      .subscribe(async ([projects, currentProject]) => {
        if (currentProject !== undefined) {
          const selectedProject = projects.find((project) => project.id === currentProject.id);
          if (selectedProject && !this.projectOmissionService.isProjectActive(selectedProject)) {
            await this.projectOmissionService.setNextActiveProject();
            await this.projectOmissionService.showMessageAndNavigate(this.projectOmissionService.getProjectOmissionMessage(selectedProject.name, ProjectOmissionMessage.ARCHIVED));
          } else if (currentProject && _.isEmpty(selectedProject)) {
            await this.projectOmissionService.setNextActiveProject();
            if (projects.length > 0) {
              await this.projectOmissionService.showMessageAndNavigate(this.projectOmissionService.getProjectOmissionMessage(currentProject.name, ProjectOmissionMessage.DELETED));
            }
          }
        }
      });
    return true;
  }
}
