import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {PdfPlanHolderItemDirective} from '../pdf-plan-holder-list-item-row/pdf-plan-holder-item.directive';

@Component({
  selector: 'app-pdf-plan-holder-list-item',
  templateUrl: './pdf-plan-holder-list-item.component.html',
  styleUrls: ['./pdf-plan-holder-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanHolderListItemComponent extends PdfPlanHolderItemDirective {
  @Input()
  pdfPlanHolder: PdfPlanWithDeletable;

  @Input()
  activeToggleDisabled = false;

  @Input()
  disabled = false;

  @HostBinding('class.plan-versions-expanded')
  @Input()
  expanded = false;

  @Input()
  selected = false;

  @Output()
  holderClick = new EventEmitter<MouseEvent>();

  @Output()
  actionsClick = new EventEmitter<MouseEvent>();

  @Output()
  expandClick = new EventEmitter<MouseEvent>();

  @Output()
  activeChange = new EventEmitter<boolean>();

  @Output()
  selectedChange = new EventEmitter<boolean>();
}
