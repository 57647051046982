import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {observableToPromise} from 'src/app/utils/async-utils';
import {isViewerOnlyClient} from 'src/app/utils/client-status-utils';
import {LicenseType} from 'submodules/baumaster-v2-common';
import {LicenseService} from '../auth/license.service';
import {ClientService} from '../client/client.service';
import {OmgToastService} from '../ui/omg-toast.service';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureEnabledService {
  constructor(
    private licenseService: LicenseService,
    private userService: UserService,
    private toastService: OmgToastService,
    private clientService: ClientService
  ) {}

  async assertFeatureEnabled(
    featureEnabled: boolean,
    forConnected: boolean | null,
    forLicenses: LicenseType[] | null,
    forAdmin: boolean | null,
    forReportRights: boolean | null,
    featureName?: string
  ): Promise<void> {
    if (!(await this.isFeatureEnabled(featureEnabled, forConnected, forLicenses, forAdmin, forReportRights))) {
      throw new Error(`Feature '${featureName || '_name not provided_'}' is disabled for current user/client!`);
    }
  }

  private async enabledForConnected(featureEnabled: boolean, forConnected: boolean | null): Promise<boolean> {
    return typeof forConnected === 'boolean' ? this.isFeatureEnabled(featureEnabled, forConnected, null) : featureEnabled;
  }

  private async enabledForLicense(featureEnabled: boolean, forLicenses: LicenseType[] | null): Promise<boolean> {
    return forLicenses && Array.isArray(forLicenses) ? this.isFeatureEnabled(featureEnabled, null, forLicenses) : featureEnabled;
  }

  async isFeatureEnabledOrToast(featureEnabled: boolean, forConnected: boolean | null, forLicenses: LicenseType[] | null): Promise<boolean> {
    if (!(await this.enabledForConnected(featureEnabled, forConnected))) {
      this.toastService.infoWithMessageAndHeader('toast.connectedDisabled.header', 'toast.connectedDisabled.message');
      return false;
    }
    if (!(await this.enabledForLicense(featureEnabled, forLicenses))) {
      this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
      return false;
    }

    return true;
  }

  async isFeatureEnabled(
    featureEnabled: boolean,
    forConnected: boolean | null,
    forLicenses: LicenseType[] | null,
    forAdmin: boolean | null = null,
    forReportRights: boolean | null = null,
    isCurrentUserConnected$ = this.userService.isCurrentUserConnected$,
    currentUserLicense$ = this.licenseService.currentUserLicense$,
    isCurrentUserAdmin$ = this.userService.isCurrentUserAdmin$,
    hasCurrentUserReportRights$ = this.userService.hasCurrentUserReportRights$,
    currentClient$ = this.clientService.currentClient$
  ): Promise<boolean> {
    return observableToPromise(
      this.isFeatureEnabled$(
        featureEnabled,
        forConnected,
        forLicenses,
        forAdmin,
        forReportRights,
        isCurrentUserConnected$,
        currentUserLicense$,
        isCurrentUserAdmin$,
        hasCurrentUserReportRights$,
        currentClient$
      )
    );
  }

  isFeatureEnabled$(
    featureEnabled: boolean,
    forConnected: boolean | null,
    forLicenses: LicenseType[] | null,
    forAdmin: boolean | null = null,
    forReportRights: boolean | null = null,
    isCurrentUserConnected$ = this.userService.isCurrentUserConnected$,
    currentUserLicense$ = this.licenseService.currentUserLicense$,
    isCurrentUserAdmin$ = this.userService.isCurrentUserAdmin$,
    hasCurrentUserReportRights$ = this.userService.hasCurrentUserReportRights$,
    currentClient$ = this.clientService.currentClient$
  ): Observable<boolean> {
    return combineLatest([isCurrentUserConnected$, currentUserLicense$, isCurrentUserAdmin$, hasCurrentUserReportRights$, currentClient$]).pipe(
      map(([isCurrentUserConnected, currentUserLicense, isCurrentUserAdmin, hasCurrentUserReportRights, currentClient]) => {
        if (isViewerOnlyClient(currentClient)) {
          currentUserLicense = LicenseType.VIEWER;
        }

        const hasForConnected = forConnected !== null;
        const hasForLicenses = forLicenses !== null;
        const hasForAdmin = forAdmin !== null;
        const hasForReportRights = forReportRights !== null;
        const conditions = [];
        if (hasForConnected) {
          conditions.push(forConnected === isCurrentUserConnected);
        }
        if (hasForLicenses) {
          conditions.push(forLicenses.includes(currentUserLicense));
        }
        if (hasForAdmin) {
          conditions.push(forAdmin === isCurrentUserAdmin);
        }
        if (hasForReportRights) {
          conditions.push(forReportRights === hasCurrentUserReportRights);
        }
        const allConditionsMet = !conditions.includes(false);
        const anyConditionMet = conditions.includes(true);

        if (conditions.length === 0) {
          return featureEnabled;
        }

        if (featureEnabled) {
          return allConditionsMet;
        } else {
          return !anyConditionMet;
        }
      })
    );
  }
}
