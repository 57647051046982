import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PdfPlanFolderService, PdfPlanFolderWithDeletable} from 'src/app/services/project-room/pdf-plan-folder.service';
import {trackById} from 'src/app/utils/track-by-id';
import {IdType} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-pdf-plan-folder-list-picker',
  templateUrl: './pdf-plan-folder-list-picker.component.html',
  styleUrls: ['./pdf-plan-folder-list-picker.component.scss'],
})
export class PdfPlanFolderListPickerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  readonly trackById = trackById;

  @Input()
  folderId?: IdType;

  @Output()
  folderIdChange = new EventEmitter<IdType | undefined>();

  folders$ = this.pdfPlanFolderService.getPdfPlanFolderWithDeletable$();

  constructor(private pdfPlanFolderService: PdfPlanFolderService) {}

  ngOnInit() {
    this.folders$.pipe(takeUntil(this.destroy$)).subscribe((folders) => {
      if (this.folderId && !folders.some((folder) => folder.id === this.folderId)) {
        this.clearSelection();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  chooseFolder(folder: PdfPlanFolderWithDeletable) {
    this.folderIdChange.emit(folder.id);
  }

  private clearSelection() {
    this.folderIdChange.emit(undefined);
  }
}
