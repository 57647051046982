import {ColumnConfig} from '../ColumnConfig';
import {ReportColumnSet} from './ReportColumnSet';

export const ReportWeekColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'year', prop: 'year'},
  {name: 'calender_week', prop: 'calenderWeek'},
  {name: 'additional_description', prop: 'additionalDescription', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'project_id', prop: 'projectId'},
  {name: 'type_id', prop: 'typeId', cnd: true},
  {name: 'custom_report_type_id', prop: 'customReportTypeId', def: null},
  {name: 'reports', fk: 'reportWeekId', mappedBy: ReportColumnSet, transient: true},
];
