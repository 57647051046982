import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeWhile, tap} from 'rxjs/operators';
import {EnsureStorageInitializedGuard, skipWhileNotInitialized} from 'src/app/guards/ensure-storage-initialized.guard';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {LoggingService} from '../common/logging.service';
import {DataServiceFactoryService} from './data-service-factory.service';
import {ProjectDataService} from './project-data.service';

const LOG_SOURCE = 'AppDataPageService';

@Injectable({
  providedIn: 'root',
})
export class AppDataPageService {
  private newAuthenticated$ = this.authService.isAuthenticated$.pipe(
    distinctUntilChanged(),
    filter((isAuthenticated) => isAuthenticated),
    shareReplay({
      refCount: false,
      bufferSize: 1,
    })
  );
  hasClientDataInitialized$ = this.resetOnNewAuth$(() =>
    combineLatestAsync([this.ensureStorageInitializedGuard.clientAwareDataServicesInitialized(), this.ensureStorageInitializedGuard.nonClientAwareDataServicesInitialized()]).pipe(
      skipWhileNotInitialized(),
      shareReplay({
        refCount: false,
        bufferSize: 1,
      })
    )
  );
  allowInteraction$ = this.resetOnNewAuth$(() =>
    this.hasClientDataInitialized$.pipe(
      takeWhile((allowInteraction) => !allowInteraction, true),
      distinctUntilChanged(),
      switchMap((allowInteraction) => {
        if (allowInteraction) {
          return this.hasProjects$.pipe(switchMap((hasProjects) => (hasProjects ? this.ensureStorageInitializedGuard.projectAwareDataServicesInitialized() : of(true))));
        }

        return of(allowInteraction);
      }),
      shareReplay({
        refCount: false,
        bufferSize: 1,
      })
    )
  );

  initialSync$ = this.resetOnNewAuth$(() =>
    this.ensureStorageInitializedGuard.nonClientAwareDataServicesRead().pipe(
      debounceTime(0),
      tap(() => this.loggingService.debug(LOG_SOURCE, 'initialSync$: nonClientAwareDataServicesRead')),
      switchMap(() => {
        // Storage is read for non-client aware data. If they don't have data yet, it means it has no data locally = initial sync.
        return this.dataServiceFactoryService.nonClientStorageInitialized$.pipe(take(1));
      }),
      tap((nonClientStorageInitialized) => this.loggingService.debug(LOG_SOURCE, `initialSync$: nonClientStorageInitialized: ${nonClientStorageInitialized}`)),
      switchMap((nonClientStorageInitialized) => {
        if (!nonClientStorageInitialized) {
          return of(false); // If non-client aware storage is uninitialized, then client aware storage is not as well
        }

        // Non-client aware data has the data already in place. Perhaps client aware data hasn't initialized yet?
        return this.clientService.currentClient$.pipe(
          tap((currentClient) => this.loggingService.debug(LOG_SOURCE, `initialSync$: currentClientId: ${currentClient?.id}`)),
          switchMap((currentClient) => {
            if (!currentClient) {
              return of(false);
            }

            return this.dataServiceFactoryService.isClientAwareStorageDataInitialized$(currentClient.id).pipe(take(1));
          })
        );
      }),
      tap((clientStorageInitialized) => this.loggingService.debug(LOG_SOURCE, `initialSync$: clientStorageInitialized: ${clientStorageInitialized}`)),
      switchMap((clientStorageInitialized) => {
        if (!clientStorageInitialized) {
          return of(true); // It's an initial sync for sure
        }

        // Client aware data is already in place. Last thing is to check currently selected project.
        return this.projectDataService.currentProjectObservable.pipe(take(1));
      }),
      tap((projectOrIsInitialSync) =>
        this.loggingService.debug(LOG_SOURCE, `initialSync$: projectOrIsInitialSync: ${typeof projectOrIsInitialSync === 'boolean' ? projectOrIsInitialSync : projectOrIsInitialSync?.id}`)
      ),
      switchMap((projectOrIsInitialSync) => {
        if (typeof projectOrIsInitialSync === 'boolean') {
          return of(projectOrIsInitialSync);
        }

        if (!projectOrIsInitialSync) {
          return of(true); // It's an initial sync, current project is not set (should not happen?)
        }

        return this.dataServiceFactoryService.isProjectAwareStorageDataInitialized$(projectOrIsInitialSync?.id).pipe(map((v) => !v));
      }),
      tap((isInitialSync) => this.loggingService.debug(LOG_SOURCE, `initialSync$: isInitialSync: ${isInitialSync}`))
    )
  );

  hasProjects$ = this.resetOnNewAuth$(() =>
    this.projectDataService.dataAcrossClientsActive$.pipe(
      map((projects) => projects.length > 0),
      distinctUntilChanged()
    )
  );

  constructor(
    private loggingService: LoggingService,
    private ensureStorageInitializedGuard: EnsureStorageInitializedGuard,
    private projectDataService: ProjectDataService,
    private authService: AuthenticationService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private clientService: ClientService
  ) {}

  private resetOnNewAuth$<T>(obFactory: () => Observable<T>): Observable<T> {
    return this.newAuthenticated$.pipe(switchMap(() => obFactory()));
  }
}
