import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Platform} from '@ionic/angular';
import {ClientAwareKey, ProjectAwareKey} from 'submodules/baumaster-v2-common';
import {IdAware, IdType} from 'submodules/baumaster-v2-common';
import {LocalChanges} from '../../model/local-changes';
import {LoggingService} from '../common/logging.service';
import {DataServiceFactoryService} from '../data/data-service-factory.service';
import {SyncStrategy} from './sync-utils';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {AbstractDataService} from '../data/abstract-data.service';
import {map} from 'rxjs/operators';
import {combineLatestAsync} from 'src/app/utils/async-utils';

const LOG_SOURCE = 'SyncStatusService';

export interface DataSyncStatus {
  inProgress: boolean;
  syncStrategy: SyncStrategy | undefined;
}

export interface AttachmentSyncStatus {
  inProgress: boolean;
  syncStrategy: SyncStrategy | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class SyncStatusService implements OnDestroy {
  protected readonly dataSyncInProgressSubject = new BehaviorSubject<DataSyncStatus>({inProgress: false, syncStrategy: undefined});
  public readonly dataSyncInProgressObservable = this.dataSyncInProgressSubject.asObservable();
  protected readonly attachmentSyncInProgressSubject = new BehaviorSubject<AttachmentSyncStatus>({inProgress: false, syncStrategy: undefined});
  public readonly attachmentSyncInProgressObservable = this.attachmentSyncInProgressSubject.asObservable();
  protected readonly attachmentUploadInProgressSubject = new BehaviorSubject<boolean>(false);
  public readonly attachmentUploadInProgressObservable = this.attachmentUploadInProgressSubject.asObservable();
  protected readonly clientOrProjectsWithLocalChangesSubject = new BehaviorSubject<Set<IdType | undefined>>(new Set());
  public readonly clientOrProjectsWithLocalChanges$ = this.clientOrProjectsWithLocalChangesSubject.asObservable();
  protected readonly numberOfAttachmentsInUploadQueueSubject = new BehaviorSubject<number | undefined>(undefined);
  public readonly numberOfAttachmentsInUploadQueueObservable = this.numberOfAttachmentsInUploadQueueSubject.asObservable();
  protected readonly numberOfAttachmentsInErrorUploadQueueSubject = new BehaviorSubject<number | undefined>(undefined);
  public readonly numberOfAttachmentsInErrorUploadQueueObservable = this.numberOfAttachmentsInErrorUploadQueueSubject.asObservable();
  public readonly hasUnsynchronizedChanges$ = combineLatestAsync([this.clientOrProjectsWithLocalChanges$, this.numberOfAttachmentsInUploadQueueObservable]).pipe(
    map(([clientOrProjectsWithLocalChanges, numberOfAttachmentsInUploadQueue]) => clientOrProjectsWithLocalChanges.size > 0 || numberOfAttachmentsInUploadQueue > 0)
  );
  private allServiceSubscriptions: {[key in ProjectAwareKey | ClientAwareKey]: Subscription | undefined};
  private allServicesWithChanges: {[key in ProjectAwareKey | ClientAwareKey]: Set<IdType | undefined>};

  constructor(
    private platform: Platform,
    private loggingService: LoggingService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private attachmentSettingService: AttachmentSettingService
  ) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called.');

    this.allServicesWithChanges = {} as {[key in ProjectAwareKey | ClientAwareKey]: Set<IdType | undefined>};
    for (const key of Object.keys(this.dataServiceFactoryService.allServices)) {
      this.allServicesWithChanges[key] = new Set();
    }

    this.allServiceSubscriptions = {} as {[key in ProjectAwareKey | ClientAwareKey]: Subscription | undefined};
    for (const key of Object.keys(this.dataServiceFactoryService.allServices)) {
      const service: AbstractDataService<any> = this.dataServiceFactoryService.allServices[key];
      this.allServiceSubscriptions[key] = service.localChangesByClientOrProject.subscribe((localChangesByClientOrProject) => {
        const clientOrProjectIds = this.extractClientOrProjectIdsWithChanges(localChangesByClientOrProject);
        this.allServicesWithChanges[key] = clientOrProjectIds;
        this.allServicesWithChangesUpdated();
      });
    }

    this.updateNumberOfMediaFilesScheduledForUpload();
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    for (const key of Object.keys(this.allServiceSubscriptions)) {
      const subscription: Subscription = this.allServiceSubscriptions[key];
      if (subscription) {
        subscription.unsubscribe();
        this.allServiceSubscriptions[key] = undefined;
      }
    }
  }

  private extractClientOrProjectIdsWithChanges<T extends IdAware>(localChangesByClientOrProject: Map<IdType | undefined, LocalChanges<T>>): Set<IdType | undefined> {
    const clientOrProjectIdsWithChanges = new Set<IdType | undefined>();
    for (const clientOrProjectId of localChangesByClientOrProject.keys()) {
      const localChanges: LocalChanges<any> = localChangesByClientOrProject.get(clientOrProjectId);
      if (localChanges.inserted.length || localChanges.updated.length || localChanges.deleted.length) {
        clientOrProjectIdsWithChanges.add(clientOrProjectId);
      }
    }
    return clientOrProjectIdsWithChanges;
  }

  private allServicesWithChangesUpdated() {
    const clientOrProjectIdsWithChanges = new Set<IdType | undefined>();
    for (const key of Object.keys(this.allServicesWithChanges)) {
      const idsWithChangesForService: Set<IdType | undefined> = this.allServicesWithChanges[key];
      idsWithChangesForService.forEach((clientOrProjectId) => clientOrProjectIdsWithChanges.add(clientOrProjectId));
    }
    this.clientOrProjectsWithLocalChangesSubject.next(clientOrProjectIdsWithChanges);
  }

  public async updateNumberOfMediaFilesScheduledForUpload(): Promise<number> {
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    const count = await fileAccessUtil.numberOfFiles('upload');
    this.numberOfAttachmentsInUploadQueueSubject.next(count);
    this.numberOfAttachmentsInErrorUploadQueueSubject.next(await fileAccessUtil.numberOfFiles('uploadError'));
    return count;
  }

  get dataSyncInProgress(): DataSyncStatus {
    return this.dataSyncInProgressSubject.value;
  }

  set dataSyncInProgress(dataSyncStatus: DataSyncStatus) {
    this.dataSyncInProgressSubject.next(dataSyncStatus);
  }

  get attachmentSyncInProgress(): AttachmentSyncStatus {
    return this.attachmentSyncInProgressSubject.value;
  }

  set attachmentSyncInProgress(attachmentSyncStatus: AttachmentSyncStatus) {
    this.attachmentSyncInProgressSubject.next(attachmentSyncStatus);
  }

  get attachmentUploadInProgress(): boolean {
    return this.attachmentUploadInProgressSubject.value;
  }

  set attachmentUploadInProgress(inProgress: boolean) {
    this.attachmentUploadInProgressSubject.next(inProgress);
  }

  get numberOfAttachmentsInUploadQueue(): number | undefined {
    return this.numberOfAttachmentsInUploadQueueSubject.value;
  }

  get numberOfAttachmentsInErrorQueue(): number | undefined {
    return this.numberOfAttachmentsInErrorUploadQueueSubject.value;
  }
}
