import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {map} from 'rxjs/operators';
import {IdType, ProtocolEntry, ProtocolEntryStatus, ProtocolEntryType, User} from 'submodules/baumaster-v2-common';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import _ from 'lodash';
import {StorageKeyEnum} from '../../shared/constants';
import {ProtocolOpenEntryDataService} from './protocol-open-entry-data.service';
import {ProtocolEntryOrOpen} from '../../model/protocol';
import {ProtocolEntryTypeDataService} from './protocol-entry-type-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {DataServiceDeleteOptions, DataServiceInsertOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {Nullish} from 'src/app/model/nullish';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {convertAllToProtocolEntriesOrOpen, filterParentEntry, getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId, toProtocolEntryOrOpen} from '../../utils/entry-utils';

const REST_ENDPOINT_URI = 'api/data/protocolEntries';

export interface ActiveProtocolEntry {
  protocolEntry: ProtocolEntry;
  protocolListShowActive?: boolean;
  newProtocolEntry?: boolean;
}

function filterByNotDone(isProtocolLayoutShort: boolean, protocolEntryTypes: Array<ProtocolEntryType>): (protocolEntry: ProtocolEntry) => boolean {
  return (protocolEntry: ProtocolEntry): boolean => {
    const isStatusFieldActive: boolean = !!protocolEntry.typeId && protocolEntryTypes.some((protocolEntryType) => protocolEntryType.id === protocolEntry.typeId && protocolEntryType.statusFieldActive);
    return (isProtocolLayoutShort || isStatusFieldActive) && protocolEntry.status !== ProtocolEntryStatus.DONE;
  };
}

type ProtocolEntryOrOptionallyOpen = ProtocolEntry | ProtocolEntryOrOpen;

@Injectable({
  providedIn: 'root',
})
export class ProtocolEntryDataService extends AbstractProjectAwareDataService<ProtocolEntry> {
  protected readonly currentProtocolEntry = new BehaviorSubject<ActiveProtocolEntry | null>(null);
  public readonly currentProtocolEntryObservable = this.currentProtocolEntry.asObservable();

  public protocolEntriesAssignedToCurrentUser: Observable<ProtocolEntry[]> = combineLatestAsync([this.userService.currentUser$, this.data]).pipe(
    map(([user, protocolEntries]) => {
      const myEntries = protocolEntries.filter((entry) => entry.internalAssignmentId === user.profileId);
      const parentEntries = protocolEntries.filter((entry) => myEntries.some((theEntry) => theEntry.parentId === entry.id));
      const myEntriesAndParents = [...myEntries, ...parentEntries];

      return myEntriesAndParents;
    })
  );

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    protected projectDataService: ProjectDataService,
    private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    protected userService: UserService
  ) {
    super(
      StorageKeyEnum.PROTOCOL_ENTRY,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService,
      VERSION_INTRODUCED_DEFAULT,
      ['number']
    );
  }

  private normalizeProtocolEntryBeforeStorage(value: ProtocolEntryOrOptionallyOpen | ProtocolEntryOrOptionallyOpen[]): ProtocolEntry[] {
    const entries = Array.isArray(value) ? (value as ProtocolEntryOrOptionallyOpen[]) : [value as ProtocolEntryOrOptionallyOpen];
    return entries.map((entry) => this.protocolEntryOrOptionallyOpenToProtocolEntry(entry));
  }

  private isFalsyOrFunction(value: any): value is undefined | null | Function {
    return !value || typeof value === 'function';
  }

  public async insertUpdateDelete(
    changes: {
      inserts?:
        | ProtocolEntryOrOptionallyOpen
        | Array<ProtocolEntryOrOptionallyOpen>
        | ((storageData: Array<ProtocolEntryOrOptionallyOpen>) => ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen> | undefined);
      insertOptions?: DataServiceInsertOptions;
      updates?:
        | ProtocolEntryOrOptionallyOpen
        | Array<ProtocolEntryOrOptionallyOpen>
        | ((storageData: Array<ProtocolEntryOrOptionallyOpen>) => ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen> | undefined);
      deletes?:
        | ProtocolEntryOrOptionallyOpen
        | Array<ProtocolEntryOrOptionallyOpen>
        | ((storageData: Array<ProtocolEntryOrOptionallyOpen>) => ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen> | undefined);
      deleteOptions?: DataServiceDeleteOptions;
    },
    projectId: IdType
  ): Promise<Array<ProtocolEntryOrOptionallyOpen>> {
    if (!this.isFalsyOrFunction(changes.inserts)) {
      changes.inserts = this.normalizeProtocolEntryBeforeStorage(changes.inserts);
    }
    if (!this.isFalsyOrFunction(changes.updates)) {
      changes.updates = this.normalizeProtocolEntryBeforeStorage(changes.updates);
    }
    if (!this.isFalsyOrFunction(changes.deletes)) {
      changes.deletes = this.normalizeProtocolEntryBeforeStorage(changes.deletes);
    }
    return await super.insertUpdateDelete(changes, projectId);
  }

  public async insertOrUpdate(valueOrArray: ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen>, projectId: IdType): Promise<Array<ProtocolEntryOrOptionallyOpen>> {
    if (this.isFalsyOrFunction(valueOrArray)) {
      return await super.insertOrUpdate(valueOrArray, projectId);
    }
    return await super.insertOrUpdate(this.normalizeProtocolEntryBeforeStorage(valueOrArray), projectId);
  }

  public async insert(
    valueArrayOrFunction:
      | ProtocolEntryOrOptionallyOpen
      | Array<ProtocolEntryOrOptionallyOpen>
      | ((storageData: Array<ProtocolEntryOrOptionallyOpen>) => ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen> | undefined),
    projectId: IdType,
    options?: DataServiceInsertOptions
  ): Promise<Array<ProtocolEntryOrOptionallyOpen>> {
    if (this.isFalsyOrFunction(valueArrayOrFunction)) {
      return await super.insert(valueArrayOrFunction, projectId, options);
    }
    return await super.insert(this.normalizeProtocolEntryBeforeStorage(valueArrayOrFunction), projectId, options);
  }

  public async update(
    valueArrayOrFunction:
      | ProtocolEntryOrOptionallyOpen
      | Array<ProtocolEntryOrOptionallyOpen>
      | ((storageData: Array<ProtocolEntryOrOptionallyOpen>) => ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen> | undefined),
    projectId: IdType
  ): Promise<Array<ProtocolEntryOrOptionallyOpen>> {
    if (this.isFalsyOrFunction(valueArrayOrFunction)) {
      return await super.update(valueArrayOrFunction, projectId);
    }
    return await super.update(this.normalizeProtocolEntryBeforeStorage(valueArrayOrFunction), projectId);
  }

  public async delete(
    valueArrayOrFunction:
      | ProtocolEntryOrOptionallyOpen
      | Array<ProtocolEntryOrOptionallyOpen>
      | ((storageData: Array<ProtocolEntryOrOptionallyOpen>) => ProtocolEntryOrOptionallyOpen | Array<ProtocolEntryOrOptionallyOpen> | undefined),
    projectId: IdType,
    options?: DataServiceDeleteOptions
  ): Promise<void> {
    if (this.isFalsyOrFunction(valueArrayOrFunction)) {
      return await super.delete(valueArrayOrFunction, projectId, options);
    }
    return await super.delete(this.normalizeProtocolEntryBeforeStorage(valueArrayOrFunction), projectId, options);
  }

  public getByProtocolId(protocolId: IdType): Observable<Array<ProtocolEntry>> {
    return this.data.pipe(map((protocolEntries) => protocolEntries.filter((protocolEntry) => protocolEntry.protocolId === protocolId)));
  }

  public getByProtocolIds(protocolIds?: Nullish<IdType[]>): Observable<Array<ProtocolEntry>> {
    if (!protocolIds?.length) {
      return this.data;
    }
    return this.data.pipe(map((protocolEntries) => protocolEntries.filter((protocolEntry) => protocolIds.includes(protocolEntry.protocolId))));
  }

  private toProtocolEntryOrOpen(protocolEntry: ProtocolEntry, isOpenEntry: boolean, newProtocolId?: IdType): ProtocolEntryOrOpen {
    return toProtocolEntryOrOpen(protocolEntry, isOpenEntry, newProtocolId);
  }

  private protocolEntryOrOptionallyOpenToProtocolEntry(entry: ProtocolEntryOrOptionallyOpen): ProtocolEntry {
    if (!entry.hasOwnProperty('isOpenEntry')) {
      return entry as ProtocolEntry;
    }
    const protocolEntryOrOpen = entry as ProtocolEntryOrOpen;
    const protocolEntry: ProtocolEntry = _.omit(protocolEntryOrOpen, ['isOpenEntry', 'originalProtocolId']);
    if (protocolEntryOrOpen.isOpenEntry) {
      protocolEntry.protocolId = protocolEntryOrOpen.originalProtocolId;
    }
    return protocolEntry;
  }

  public getProtocolEntryOrOpenById(id: IdType): Observable<ProtocolEntryOrOpen> {
    return combineLatest([this.getById(id), this.protocolOpenEntryDataService.getByProtocolEntryId(id)]).pipe(
      map(([protocolEntry, protocolOpenEntry]) => {
        return this.toProtocolEntryOrOpen(protocolEntry, Boolean(protocolOpenEntry), protocolOpenEntry?.protocolId);
      })
    );
  }

  public getProtocolEntryOrOpenByIdAcrossProjects(id: IdType): Observable<ProtocolEntryOrOpen> {
    return combineLatest([this.getByIdAcrossProjects(id), this.protocolOpenEntryDataService.getByProtocolEntryIdAcrossProjects(id)]).pipe(
      map(([protocolEntry, protocolOpenEntry]) => {
        return this.toProtocolEntryOrOpen(protocolEntry, Boolean(protocolOpenEntry), protocolOpenEntry?.protocolId);
      })
    );
  }

  public getProtocolEntryOrOpenByProtocolId(protocolId: IdType): Observable<Array<ProtocolEntryOrOpen>> {
    return combineLatest([this.data, this.protocolOpenEntryDataService.getByProtocolId(protocolId)]).pipe(
      map(([protocolEntries, protocolOpenEntries]) => {
        const protocolEntriesForProtocol = protocolEntries.filter((protocolEntry) => protocolEntry.protocolId === protocolId || protocolEntry.createdInProtocolId === protocolId);
        return convertAllToProtocolEntriesOrOpen(protocolEntries, protocolOpenEntries, protocolEntriesForProtocol);
      })
    );
  }

  public getProtocolEntryOrOpenByProtocolAndEntryId(protocolId: IdType, protocolEntryId: IdType): Observable<ProtocolEntryOrOpen | undefined> {
    return this.getProtocolEntryOrOpenByProtocolId(protocolId).pipe(map((protocolEntries) => protocolEntries.find((protocolEntry) => protocolEntry.id === protocolEntryId)));
  }

  private findByIds(ids: Array<IdType>): Observable<Array<ProtocolEntry>> {
    return this.data.pipe(map((protocolEntries) => protocolEntries.filter((protocolEntry) => ids.find((id) => id === protocolEntry.id))));
  }

  private findByIdsAndFilterAcrossProjects(fn: (entry: ProtocolEntry) => boolean): Observable<Array<ProtocolEntry>> {
    return this.dataAcrossProjects$.pipe(map((protocolEntries) => protocolEntries.filter(fn)));
  }

  public getByProtocolIdAcrossProjects(protocolId: IdType): Observable<Array<ProtocolEntry>> {
    return this.findByIdsAndFilterAcrossProjects((protocolEntry) => protocolEntry.protocolId === protocolId);
  }

  private findByIdsAcrossProjects(ids: Array<IdType>): Observable<Array<ProtocolEntry>> {
    return this.findByIdsAndFilterAcrossProjects((protocolEntry) => ids.some((id) => id === protocolEntry.id));
  }

  public getParentEntriesOrOpenByProtocolId(protocolId: IdType): Observable<Array<ProtocolEntryOrOpen>> {
    return this.getProtocolEntryOrOpenByProtocolId(protocolId).pipe(map((protocolEntries) => _.orderBy(protocolEntries.filter(filterParentEntry), 'number')));
  }

  public getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId: IdType): Observable<Array<ProtocolEntryOrOpen>> {
    return combineLatestAsync([this.dataAcrossProjects$, this.protocolOpenEntryDataService.getByProtocolIdAcrossProjects(protocolId)]).pipe(
      map(([protocolEntries, protocolOpenEntries]) => {
        const protocolEntriesForProtocol = protocolEntries.filter((protocolEntry) => protocolEntry.protocolId === protocolId || protocolEntry.createdInProtocolId === protocolId);
        return convertAllToProtocolEntriesOrOpen(protocolEntries, protocolOpenEntries, protocolEntriesForProtocol);
      })
    );
  }

  public getParentEntriesOrOpenByProtocolIdAcrossProjects(protocolId: IdType): Observable<Array<ProtocolEntryOrOpen>> {
    return this.getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId).pipe(map((protocolEntries) => protocolEntries.filter(filterParentEntry)));
  }

  public getSubEntriesByParentEntryId(protocolEntryId: IdType, acrossProjects = true): Observable<Array<ProtocolEntry>> {
    return (acrossProjects ? this.dataAcrossProjects$ : this.data).pipe(map((protocolEntries) => protocolEntries.filter((protocolEntry) => protocolEntry.parentId === protocolEntryId)));
  }

  public getSubEntriesByParentEntryIds(protocolEntryIds: Array<IdType>): Observable<Array<ProtocolEntry>> {
    return this.dataAcrossProjects$.pipe(map((protocolEntries) => protocolEntries.filter((protocolEntry) => protocolEntry.parentId && protocolEntryIds.includes(protocolEntry.parentId))));
  }

  public getSubEntriesOrOpenByParentEntryId(protocolId: IdType, protocolEntryId: IdType): Observable<Array<ProtocolEntryOrOpen>> {
    return this.getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId).pipe(
      map((protocolEntries) => {
        const parentProtocolEntry = protocolEntries.find((protocolEntry) => protocolEntry.id === protocolEntryId);
        return protocolEntries.filter(
          (protocolEntry) => protocolEntry.parentId === protocolEntryId && (!parentProtocolEntry.isOpenEntry || protocolEntry.isOpenEntry || protocolEntry.createdInProtocolId === protocolId)
        );
      })
    );
  }

  public getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(protocolId: IdType, isProtocolLayoutShort$: Observable<boolean | undefined>): Observable<Array<ProtocolEntryOrOpen>> {
    const filteredProtocolEntries = this.getProtocolEntryOrOpenByProtocolId(protocolId);
    return combineLatestAsync([filteredProtocolEntries, this.protocolEntryTypeDataService.data, isProtocolLayoutShort$]).pipe(
      map(([protocolEntries, protocolEntryTypes, isProtocolLayoutShort]) => {
        return getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(protocolEntries, protocolEntryTypes, isProtocolLayoutShort);
      })
    );
  }

  public getNotDoneEntriesOrOpenByProtocolId(protocolId: IdType, isProtocolLayoutShort$: Observable<boolean | undefined>): Observable<Array<ProtocolEntryOrOpen>> {
    const filteredProtocolEntries = this.getProtocolEntryOrOpenByProtocolId(protocolId);
    return combineLatestAsync([filteredProtocolEntries, this.protocolEntryTypeDataService.data, isProtocolLayoutShort$]).pipe(
      map(([protocolEntries, protocolEntryTypes, isProtocolLayoutShort]) => {
        return protocolEntries.filter(filterByNotDone(!!isProtocolLayoutShort, protocolEntryTypes));
      })
    );
  }

  public setCurrentProtocolEntry(activeProtocolEntry: ActiveProtocolEntry | null): void {
    if (typeof activeProtocolEntry?.protocolEntry !== 'undefined' || activeProtocolEntry === null) {
      this.loggingService.debug(this.logSource, `setCurrentProtocolEntry called for "${activeProtocolEntry?.protocolEntry?.title}" (${activeProtocolEntry?.protocolEntry?.id}) `);
      if ((activeProtocolEntry === null && this.currentProtocolEntry.value === null) || activeProtocolEntry?.protocolEntry?.id === this.currentProtocolEntry.value?.protocolEntry?.id) {
        this.loggingService.debug(
          this.logSource,
          `setCurrentProtocolEntry called for "${activeProtocolEntry?.protocolEntry?.title}" (${activeProtocolEntry?.protocolEntry?.id}) but value has not changed.`
        );
      } else {
        this.currentProtocolEntry.next(activeProtocolEntry);
      }
    }
  }

  public getCurrentProtocolEntry(): Observable<ActiveProtocolEntry | null> {
    return this.currentProtocolEntryObservable;
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  protected async isValid(obj: ProtocolEntry): Promise<{valid: true; message?: string} | {valid: false; message: string}> {
    if (!obj) {
      return {valid: false, message: `ProtocolEntry is falsy.`};
    }
    const missingFields = this.validateMandatoryFields(obj, 'protocolId', 'allCompanies', 'changedAt', 'createdAt', 'number');
    if (missingFields.length) {
      return {valid: false, message: `Mandatory fields "${missingFields}" missing for ProtocolEntry with id ${obj.id}`};
    }
    return {valid: true};
  }
}
