<ion-header appLicenseType #lt="appLicenseType" [class.ion-no-border]="withoutHeaderShadow">
  <ion-toolbar color="menu">
    <ion-buttons slot="start">
      <app-menu-button></app-menu-button>
      <ion-back-button *ngIf="showBackButton" [defaultHref]="defaultPage" [text]="''"></ion-back-button>
      <ion-button *ngIf="isNetworkConnected && (isTester || isExpiredTester) && !isNativeApp" [disabled]="!isClientAdmin" class="omg-btn-primary omg-btn-color-brand" (click)="openBuyingModal()">
        <span class="medium-bold">{{('buyingWorkflow.buyNow' | translate) }}</span>
      </ion-button>
      <div *ngIf="isNetworkConnected && (isTester || isExpiredTester) && !isNativeApp" class="ion-hide-lg-down">
        <span class="medium-bold ion-align-self-center ml-2" *ngIf="isTester && isNetworkConnected">{{('buyingWorkflow.freeTrial' | translate) + ' ' + ('buyingWorkflow.daysLeft' | translate:{daysLeft})}}</span>
        <span class="medium-bold ion-align-self-center ml-2" *ngIf="isExpiredTester && isNetworkConnected">{{'buyingWorkflow.expired' | translate}}</span>
      </div>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ title }}</ion-title>
    <div class="action-buttons d-flex gap-2" slot="end">
      <app-sync-status inverseColor="true" class="ion-align-self-center"></app-sync-status>
      <app-storage-quota-notification></app-storage-quota-notification>
      <ion-button *ngIf="isTakingPictureEnabled$ | async" class="omg-btn-primary omg-btn-color-neutral single-photo cursor-pointer" [disabled]="!takingPhotosSupported">
        <fa-icon slot="icon-only" size="lg" (click)="takePicture()" [icon]="['fal', 'camera']"></fa-icon>
      </ion-button>
      <ion-button *ngIf="isTakingPictureEnabled$ | async" class="omg-btn-primary omg-btn-color-neutral cursor-pointer ion-hide-sm-down" [disabled]="!takingPhotosSupported">
        <fa-icon slot="icon-only" size="lg" (click)="takePictures()" [icon]="['bau', 'foto-series']"></fa-icon>
      </ion-button>
      <ion-button class="omg-btn-primary omg-btn-color-neutral notifications-center-button ion-hide-sm-down" (click)="openNotificationsCenter()">
        <fa-icon slot="icon-only" size="lg" [icon]="['fal', 'bell']"></fa-icon>
        <span *ngIf="(totalUnseenCount$ | async) as totalUnseenCount" class="omg-notification-badge">{{totalUnseenCount > 99 ? '99+' : totalUnseenCount}}</span>
      </ion-button>
      <ion-button class="omg-btn-primary omg-btn-color-neutral cursor-pointer ion-hide-sm-up" (click)="showUserMenu($event)">
        <fa-icon slot="icon-only" size="lg" [icon]="['fal', 'ellipsis-h']"></fa-icon>
      </ion-button>
      <app-avatar class="ion-hide-sm-down" (click)="showUserMenu($event)" color="header-avatar" [initials]="(initials$ | async) || ''" size="medium"></app-avatar>
    </div>
  </ion-toolbar>
  <app-storage-quota-notification-header></app-storage-quota-notification-header>
  <div class="subheader">
    <ng-content></ng-content>
  </div>
</ion-header>
