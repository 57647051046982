<div *ngIf="workflowType === WorkflowTypeEnum.Protocol">
  <ion-item lines="none" class="individual-protocol">
    <!-- [checked]="form.get('individualProtocol').value" (ionChange)="toggleIndividualProtocol()" -->
    <ion-toggle slot="start" [formControl]="form.get('individualProtocol')" class="ion-no-padding" appSlimToggle></ion-toggle>
    <ion-label>
      <div>{{ 'sendProtocol.addIndividualProtocol' | translate }}</div>
      <ion-note>{{ 'sendProtocol.addIndividualProtocolDescription' | translate }} </ion-note>
    </ion-label>
  </ion-item>
</div>

<ng-container *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Protocol">
  <app-next-meeting
    [participants]="participants"
    [nextMeeting]="nextMeeting"
    (nextMeetingChange)="nextMeetingChange.emit($event)"
    [individualNextMeetings]="individualNextMeetings"
    (individualNextMeetingsChange)="individualNextMeetingsChange.emit($event)"></app-next-meeting>
</ng-container>

<ng-container *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Protocol && protocol && !protocol.closedAt && pdfProtocolSignatures">
  <app-pdf-protocol-signatures
    [showSignatures]="showSignatures"
    (showSignaturesChange)="showSignaturesChange.emit($event)"
    [pdfProtocolSignatures]="pdfProtocolSignatures"
    (pdfProtocolSignaturesChange)="pdfProtocolSignaturesChange.emit($event)"></app-pdf-protocol-signatures>
</ng-container>

<ion-grid class="ion-no-padding omg-form">
  <ion-accordion-group *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report" [multiple]="true" [value]="['general', 'layout']" class="mt-2">
    <ion-accordion toggleIconSlot="start" [value]="'general'">
      <ion-item slot="header" lines="none">
        <ion-label>{{ "sendProtocol.protocolConfig.sections.content_general" | translate }}</ion-label>
      </ion-item>
      <ion-row slot="content" class="hide-overflow-x">
        <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report && !report.closedAt">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="showReportSignatures"
                (ionChange)="showReportSignaturesChange.emit($event.detail.checked)"
                [disabled]="!(isSignatureEnabled$ | async)"
            >
              <div>{{ "sendProtocol.advancedSettings.sign" | translate }}</div>
              <div class="signatureNoPermission" *ngIf="!(isSignatureEnabled$ | async)">
                {{ "sendProtocol.protocolConfig.signature.noPermission" | translate }}
              </div>
            </ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report && (isUsingTimeBlocks$ | async)">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="showStaffTimeBlocks"
                (ionChange)="showStaffTimeBlocksChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.showTimeBlocks" | translate }}</ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-accordion>
    <ion-accordion toggleIconSlot="start" [value]="'layout'" class="mt-3">
      <ion-item slot="header" lines="none">
        <ion-label>{{ 'sendProtocol.advancedSettings.layout' | translate }}</ion-label>
      </ion-item>
      <ion-row slot="content" class="hide-overflow-x">
        <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="appendActivityAttachments"
                (ionChange)="appendActivityAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.activity" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="appendOtherAttachments"
                (ionChange)="appendOtherAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.other" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox 
              label-placement="end" justify="start"
              [(ngModel)]="appendMaterialAttachments"
              (ionChange)="appendMaterialAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.material" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox
              label-placement="end" justify="start"
              [(ngModel)]="appendEquipmentAttachments"
              (ionChange)="appendEquipmentAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.equipment" | translate }}</ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-accordion>
  </ion-accordion-group>
</ion-grid>
