import {Directive, Input, forwardRef, signal} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {UnitSelectorModalComponent} from 'src/app/components/common/unit-selector-modal/unit-selector-modal.component';
import {Nullish} from 'src/app/model/nullish';
import {SelectorUnit, SelectorUnitLevel} from 'src/app/model/selector-unit';
import {IdType} from 'submodules/baumaster-v2-common';

@Directive({
  selector: '[appUnitSelector]',
  standalone: true,
  exportAs: 'appUnitSelector',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitSelectorDirective),
      multi: true,
    },
  ],
})
export class UnitSelectorDirective<T extends SelectorUnit> implements ControlValueAccessor {
  @Input()
  selectorUnits: T[] = [];

  @Input()
  selectorUnitLevels: SelectorUnitLevel[] = [];

  value = signal<Nullish<T>>(undefined);
  idValue = signal<Nullish<IdType>>(undefined);

  @Input()
  idOnly = false;

  @Input()
  preselectUnitId: IdType | undefined;

  @Input()
  preSelectEnabled = true;

  @Input()
  applyLabel = 'button.apply';

  @Input()
  applyIcon: [string, string] = ['fal', 'check'];

  private modal: HTMLIonModalElement | undefined;

  private propagateOnChange = (_: any) => {};
  private propagateOnTouched = () => {};

  constructor(private modalController: ModalController) {}

  writeValue(value: IdType | T): void {
    if (value === undefined || value === null) {
      this.value.set(undefined);
      this.idValue.set(undefined);
    } else if (this.idOnly && typeof value === 'string') {
      this.idValue.set(value);
      this.value.set(this.selectorUnits.find((u) => u.id === value));
    } else if (!this.idOnly && typeof value === 'object') {
      this.value.set(value);
      this.idValue.set(value.id);
    } else {
      throw new Error(`Cannot write value, as idOnly is ${this.idOnly} (type of value: ${typeof value})`);
    }
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  async chooseUnit() {
    const unit = await this.openSelector();
    this.propagateOnTouched();
    if (!unit) {
      return;
    }
    if (unit !== 'reset') {
      this.idValue.set(unit.id);
      this.value.set(unit);
      this.propagateOnChange(this.idOnly ? this.idValue() : this.value());
    } else {
      this.idValue.set(undefined);
      this.value.set(undefined);
      this.propagateOnChange(undefined);
    }
  }

  async openSelector(): Promise<T | 'reset' | undefined> {
    if (this.modal) {
      return;
    }

    const {selectorUnits, selectorUnitLevels, applyLabel, applyIcon} = this;
    const preselectUnit = this.getPreselectUnit();
    const modal = await this.modalController.create({
      component: UnitSelectorModalComponent,
      cssClass: 'omg-modal omg-boundary omg-in-modal-list',
      componentProps: {
        selectorUnits,
        selectorUnitLevels,
        preselectUnit,
        applyLabel,
        applyIcon,
        canInitiallyReset: !!this.value(),
      },
    });

    if (this.modal) {
      modal.remove();
      return;
    }

    this.modal = modal;

    await this.modal.present();

    const {role, data} = await this.modal.onWillDismiss();

    this.modal = undefined;

    if (role === 'save') {
      const unit: T = data;

      return unit;
    }

    if (role === 'reset') {
      return 'reset';
    }

    return undefined;
  }

  private getPreselectUnit(): T | undefined {
    const hasFormValue = (this.idOnly && this.idValue()) || (!this.idOnly && this.value());
    if ((!hasFormValue && !this.preselectUnitId) || !this.preSelectEnabled) {
      return;
    }
    const id = hasFormValue ? (this.idOnly ? this.idValue() : this.value().id) : this.preselectUnitId;
    const {selectorUnits} = this;
    return selectorUnits.find((u) => u.id === id);
  }
}
