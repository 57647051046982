import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MIME_TYPE_EXTENSION_WHITELIST} from 'submodules/baumaster-v2-common';
import {ToastService} from '../../../services/common/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@ionic/angular';
import mime from 'mime';
import _ from 'lodash';
import {convertFileViaBinaryStringToFile, ensureMimeTypeSet} from '../../../utils/attachment-utils';
import {AttachmentKind} from '../../../shared/errors';
import {PhotoService} from '../../../services/photo/photo.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  private readonly convertForExternalSourcesNeeded = this.platform.is('android');
  @Input()
  maxFiles = 99;
  @Input()
  acceptedMimeTypes: string[] = MIME_TYPE_EXTENSION_WHITELIST;
  @Input()
  title: string | undefined;
  @Input()
  processingFiles = false;
  @Input()
  files = new Array<File>();
  @Output()
  filesChange = new EventEmitter<File[]>();
  @Input()
  showFilesList = true;
  @Input()
  fileColumnTemplate?: TemplateRef<{file: File; index: number}>;
  @Input()
  showAbortProcessFile = false;
  @Input()
  attachmentKind?: AttachmentKind | undefined;
  @Output()
  abortProcessingFile = new EventEmitter<void>();

  acceptedExtensions = this.convertForExternalSourcesNeeded ? this.acceptedMimeTypes : this.mimeTypesToExtensions(this.acceptedMimeTypes);

  @ViewChild('fileField') private fileField: ElementRef<HTMLInputElement>;
  dragAndDropSupported: boolean;

  form = this.fb.group({
    formFiles: [[]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private platform: Platform,
    private photoService: PhotoService
  ) {
    this.dragAndDropSupported = this.platform.is('desktop') || (this.platform.is('mobileweb') && !this.platform.is('android') && !this.platform.is('ios'));
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.files) {
      this.form.patchValue({
        formFiles: this.files,
      });
      this.form.get('formFiles').updateValueAndValidity();
    }
    if (changes.maxFiles && changes.maxFiles.currentValue) {
      if (typeof this.maxFiles === 'string') {
        const maxFilesAsString: string = this.maxFiles;
        this.maxFiles = +maxFilesAsString;
      }
    }
    if (changes.acceptedMimeTypes) {
      if (this.convertForExternalSourcesNeeded) {
        this.acceptedExtensions = this.acceptedMimeTypes;
      } else {
        this.acceptedExtensions = this.mimeTypesToExtensions(this.acceptedMimeTypes);
      }
    }
  }

  async uploadFileEvent(files: FileList) {
    const fileListAsArray: Array<File> = Array.from(files);
    await this.processUploadedFiles(fileListAsArray);
    this.fileField.nativeElement.value = null;
  }

  private async processUploadedFiles(files: Array<File>) {
    const filesLengthBefore = this.files.length;
    const filesSkippedDueToMimeType = new Array<File>();
    let filesUpdated = false;
    const updatedFiles = [...this.files];
    for (let file of files) {
      if (updatedFiles.length >= this.maxFiles) {
        const skipped = filesLengthBefore + files.length - this.maxFiles;
        await this.toastService.infoWithMessage(this.translateService.instant('fileUpload.skipped_maximum_files_exceeded', {skipped, max: this.maxFiles}));
        break;
      }
      if (this.convertForExternalSourcesNeeded) {
        file = await convertFileViaBinaryStringToFile(file);
      }
      file = ensureMimeTypeSet(file, file.name);
      const type = file.type ? file.type : mime.getType(file.name);
      if (!this.acceptedMimeTypes.some((acceptedMimeType) => acceptedMimeType === type)) {
        filesSkippedDueToMimeType.push(file);
        continue;
      }
      if (!(await this.photoService.ensureContentIsJpegOrPng(file, this.attachmentKind))) {
        break;
      }
      filesUpdated = true;
      updatedFiles.push(file);
    }
    if (filesSkippedDueToMimeType.length === 1) {
      const type = filesSkippedDueToMimeType[0].type;
      this.toastService.infoWithMessage(this.translateService.instant('fileUpload.file_skipped_mimeType_not_allowed', {files: files.length, type}));
    } else if (filesSkippedDueToMimeType.length > 1) {
      const types = _.compact(filesSkippedDueToMimeType.map((file) => file.type)).join(',');
      this.toastService.infoWithMessage(this.translateService.instant('fileUpload.files_skipped_mimeType_not_allowed', {files: files.length, types}));
    }
    if (filesUpdated) {
      this.files = updatedFiles;
      this.filesUpdated();
    }
  }

  private filesUpdated() {
    this.form.patchValue({
      formFiles: this.files,
    });
    this.form.get('formFiles').updateValueAndValidity();
    this.filesChange.emit(this.files);
  }

  removeFileByIndex(index: number) {
    if (index < 0 || index > this.files.length - 1) {
      return;
    }
    this.files = this.files.filter((file, fileIndex) => fileIndex !== index);
    this.filesUpdated();
  }

  private mimeTypesToExtensions(mimeTypes: Array<string>): Array<string> {
    const extensions = _.uniq(mimeTypes.map((mimeType) => mime.getExtension(mimeType)).filter((extension) => extension && extension !== 'null'));
    return extensions.map((extension) => '.' + extension);
  }

  onAbortClicked() {
    if (this.showAbortProcessFile) {
      this.abortProcessingFile.emit();
    }
  }
}
