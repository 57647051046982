import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

export enum LogLevel {
  'NONE',
  'DEBUG',
  'INFO',
  'WARN',
  'ERROR',
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private readonly logLevel: LogLevel;

  constructor() {
    const envLogLevel = LogLevel[environment.logLevel];
    if (envLogLevel === undefined) {
      throw new Error(`Unable to convert environment logLevel "${environment.logLevel}" to enum LogLevel. Looks like an invalid value.`);
    }
    this.logLevel = envLogLevel;
  }

  public isDebugEnabled(): boolean {
    return this.logLevel <= LogLevel.DEBUG;
  }

  public isInfoEnabled(): boolean {
    return this.logLevel <= LogLevel.INFO;
  }

  public isWarningEnabled(): boolean {
    return this.logLevel <= LogLevel.WARN;
  }

  public isErrorEnabled(): boolean {
    return this.logLevel <= LogLevel.ERROR;
  }

  public debug(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isDebugEnabled()) {
      // eslint-disable-next-line no-console
      console.debug(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  public info(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isInfoEnabled()) {
      // eslint-disable-next-line no-console
      console.info(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  public warn(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isWarningEnabled()) {
      // eslint-disable-next-line no-console
      console.warn(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  public error(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isErrorEnabled()) {
      // eslint-disable-next-line no-console
      console.error(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }
}
