import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {filter, map, skipWhile, switchMap, take} from 'rxjs/operators';
import {IdAware} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';
import {ClientService} from '../services/client/client.service';
import {AbstractDataService} from '../services/data/abstract-data.service';
import {DataServiceFactoryService} from '../services/data/data-service-factory.service';
import {ProjectDataService} from '../services/data/project-data.service';
import {combineLatestAsync} from '../utils/async-utils';

export const skipWhileNotInitialized =
  () =>
  (source: Observable<boolean[]>): Observable<true> =>
    source.pipe(
      skipWhile((isInitializedArray) => isInitializedArray.some((isInitialized) => !isInitialized)),
      map(() => true)
    );

const clientOrProjectAwareDataServicesInitialized = (dataServices: AbstractDataService<any>[], currentClientOrProject$: Observable<Nullish<IdAware>>): Observable<true> =>
  currentClientOrProject$.pipe(
    filter((v) => Boolean(v)),
    take(1),
    switchMap((clientOrProject) =>
      !clientOrProject
        ? of([])
        : combineLatestAsync(
            dataServices
              .filter((d) => d.hasCurrentUserPermission === true || d.hasCurrentUserPermission === undefined)
              .map((dataService) => dataService.storageInitializedWithOptional$.pipe(map((initialized) => initialized.optional || initialized.initializedMap.has(clientOrProject.id))))
          )
    ),
    skipWhileNotInitialized()
  );

const dataServicesRead = (dataServices: AbstractDataService<any>[]): Observable<true> =>
  combineLatestAsync(dataServices.map((dataService) => dataService.readDataFromStorageOnce$)).pipe(skipWhileNotInitialized());

@Injectable({
  providedIn: 'root',
})
export class EnsureStorageInitializedGuard {
  constructor(
    private projectDataService: ProjectDataService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private clientService: ClientService
  ) {}

  projectAwareDataServicesInitialized(): Observable<true> {
    return clientOrProjectAwareDataServicesInitialized(Object.values(this.dataServiceFactoryService.projectAwareServices), this.projectDataService.currentProjectObservable);
  }

  clientAwareDataServicesInitialized(): Observable<true> {
    return clientOrProjectAwareDataServicesInitialized(Object.values(this.dataServiceFactoryService.clientAwareServices), this.clientService.currentClient$);
  }

  nonClientAwareDataServicesInitialized(): Observable<true> {
    const dataServices = Object.values(this.dataServiceFactoryService.nonClientAwareServices);

    return combineLatestAsync(
      dataServices.map((dataService) => dataService.storageInitializedWithOptional$.pipe(map((initialized) => initialized.optional || initialized.initializedMap.has(null))))
    ).pipe(skipWhileNotInitialized());
  }

  projectAwareDataServicesRead(): Observable<true> {
    return dataServicesRead(Object.values(this.dataServiceFactoryService.projectAwareServices));
  }

  clientAwareDataServicesRead(): Observable<true> {
    return dataServicesRead(Object.values(this.dataServiceFactoryService.clientAwareServices));
  }

  nonClientAwareDataServicesRead(): Observable<true> {
    return dataServicesRead(Object.values(this.dataServiceFactoryService.nonClientAwareServices));
  }

  canActivate(): Observable<boolean> {
    return combineLatestAsync([this.projectAwareDataServicesInitialized(), this.clientAwareDataServicesInitialized(), this.nonClientAwareDataServicesInitialized()]).pipe(skipWhileNotInitialized());
  }
}
