import {Injectable} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectWithOffline} from 'src/app/model/project-with-offline';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {Attachment, AttachmentTypeEnum, IdType} from 'submodules/baumaster-v2-common';
import {QuotaExceededComponent} from '../../components/common/quota-exceeded/quota-exceeded.component';
import {ProjectRoomAttachmentsSelectorComponent} from '../../components/project-room/project-room-attachments-selector/project-room-attachments-selector.component';
import {LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY} from '../../shared/constants';
import {NetworkStatusService} from '../common/network-status.service';
import {ToastService} from '../common/toast.service';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {AttachmentClientDataService} from '../data/attachment-client-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {AttachmentProjectDataService} from '../data/attachment-project-data.service';
import {AttachmentProjectImageDataService} from '../data/attachment-project-image-data.service';
import {AttachmentReportActivityDataService} from '../data/attachment-report-activity-data.service';
import {AttachmentReportCompanyDataService} from '../data/attachment-report-company-data.service';
import {AttachmentReportEquipmentDataService} from '../data/attachment-report-equipment-data.service';
import {AttachmentReportMaterialDataService} from '../data/attachment-report-material-data.service';
import {PdfPlanAttachmentDataService} from '../data/pdf-plan-attachment-data.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(
    private alertController: AlertController,
    private translateService: TranslateService,
    private modalController: ModalController,
    private toastService: ToastService,
    private attachmentChatDataService: AttachmentChatDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private attachmentReportActivityDataService: AttachmentReportActivityDataService,
    private attachmentReportCompanyDataService: AttachmentReportCompanyDataService,
    private attachmentReportEquipmentDataService: AttachmentReportEquipmentDataService,
    private attachmentReportMaterialDataService: AttachmentReportMaterialDataService,
    private attachmentProjectImageDataService: AttachmentProjectImageDataService,
    private attachmentClientDataService: AttachmentClientDataService,
    private networkStatusService: NetworkStatusService
  ) {}

  public async showProjectRoomAttachmentsSelector(maxSelectionLimit = LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY, projectId: IdType | null = null): Promise<Array<Attachment> | undefined> {
    const modal = await this.modalController.create({
      component: ProjectRoomAttachmentsSelectorComponent,
      cssClass: 'full-screen-modal',
      componentProps: {
        maxSelectionLimit,
        projectId,
      },
    });
    await modal.present();

    const result = await modal.onDidDismiss();
    if (!result.data?.attachments) {
      return undefined;
    }
    return result.data.attachments as Array<Attachment>;
  }

  public async showAlertIfOnlineOnlyProjectAndNoNetwork(project: ProjectWithOffline) {
    if (!project?.isOfflineAvailable && this.networkStatusService.offline) {
      await this.showAlertOfflineProject();
      return true;
    }

    return false;
  }

  private async showAlertOfflineProject() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('alert.projectRoomAttachmentProjectOffline.header'),
      message: this.translateService.instant('alert.projectRoomAttachmentProjectOffline.message'),
      buttons: [this.translateService.instant('close')],
    });
    await alert.present();
  }

  public getAttachmentById(id: IdType, attachmentType: AttachmentTypeEnum): Observable<Attachment | undefined> {
    switch (attachmentType) {
      case AttachmentTypeEnum.AttachmentChat:
        return this.attachmentChatDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.PdfPlanPage:
        return this.pdfPlanPageDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.PdfPlanAttachment:
        return this.pdfPlanAttachmentDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentProtocolEntry:
        return this.attachmentEntryDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentReportCompany:
        return this.attachmentReportCompanyDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentReportActivity:
        return this.attachmentReportActivityDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentReportEquipment:
        return this.attachmentReportEquipmentDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentReportMaterial:
        return this.attachmentReportMaterialDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentProjectImage:
        return this.attachmentProjectImageDataService.getByIdAcrossClients(id);
      case AttachmentTypeEnum.AttachmentProject:
        return this.attachmentProjectDataService.getByIdAcrossProjects(id);
      case AttachmentTypeEnum.AttachmentClient:
        return this.attachmentClientDataService.getById(id);
      default:
        throw new Error(`Unsupported attachment type ${attachmentType}:`);
    }
  }

  public getUnknownAttachmentById(id: IdType): Observable<Attachment | undefined> {
    return combineLatestAsync([
      this.attachmentChatDataService.getByIdAcrossProjects(id),
      this.pdfPlanPageDataService.getByIdAcrossProjects(id),
      this.pdfPlanAttachmentDataService.getByIdAcrossProjects(id),
      this.attachmentEntryDataService.getByIdAcrossProjects(id),
      this.attachmentReportCompanyDataService.getByIdAcrossProjects(id),
      this.attachmentReportActivityDataService.getByIdAcrossProjects(id),
      this.attachmentReportEquipmentDataService.getByIdAcrossProjects(id),
      this.attachmentReportMaterialDataService.getByIdAcrossProjects(id),
      this.attachmentProjectImageDataService.getByIdAcrossClients(id),
      this.attachmentProjectDataService.getByIdAcrossProjects(id),
      this.attachmentClientDataService.getById(id),
    ]).pipe(map((attachments) => attachments.find((attachment) => Boolean(attachment))));
  }

  private async showQuotaExceededComponent() {
    const modal = await this.modalController.create({
      component: QuotaExceededComponent,
      backdropDismiss: true,
    });

    await modal.present();
  }

  public async showToastQuotaExceeded() {
    await this.toastService.infoWithMessageAndButtons('quotaExceeded.title', [
      {
        side: 'end',
        text: this.translateService.instant('details'),
        handler: () => {
          this.showQuotaExceededComponent();
        },
      },
    ]);
  }
}
