import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NotificationSyncModalComponent} from 'src/app/components/common/project-loading/notification-sync-modal.component';
import {Router} from '@angular/router';
import {ProtocolService} from '../protocol/protocol.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {ProjectDataService} from '../data/project-data.service';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';
import {DashboardFilterService} from '../dashboard/dashboard-filter.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {AlertService} from '../ui/alert.service';
import {NotificationAction} from 'src/app/model/notification';
import {PushNotifications} from '@capacitor/push-notifications';
import {LoggingService} from '../common/logging.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {PosthogService} from '../posthog/posthog.service';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {PdfPlanDataService} from '../data/pdf-plan-data.service';
import {ProtocolEntryModalService} from '../protocol/protocol-entry-modal.service';
import {Nullish} from 'src/app/model/nullish';
import {SyncHistoryService} from '../sync/sync-history.service';
import {BimVersionDataService} from '../data/bim-version-data.service';
import {NotificationsCenterService} from './notifications-center.service';
import {FcmPushTokenManagerService} from './fcm-push-token-manager.service';
import {NavigateHelperService} from '../common/navigate-helper.service';
import {Protocol, ProtocolEntry} from 'submodules/baumaster-v2-common';

const LOG_SOURCE = 'NotificationManagerService';

interface HandleNotificationClickOptions {
  skipSyncModalIfProjectPresent: boolean;
  skipCloseModal: boolean;
  skipChangingProject: boolean;
  openEntryModalInsteadOfNavigation: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService {
  private handlingNotification = false;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private protocolService: ProtocolService,
    private protocolEntryService: ProtocolEntryService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private projectDataService: ProjectDataService,
    private dashboardFilterService: DashboardFilterService,
    private alertService: AlertService,
    private loggingService: LoggingService,
    private posthogService: PosthogService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private pdfPlanDataService: PdfPlanDataService,
    private protocolEntryModalService: ProtocolEntryModalService,
    private syncHistoryService: SyncHistoryService,
    private bimVersionDataService: BimVersionDataService,
    private notificationsCenterService: NotificationsCenterService,
    private fcmPushTokenManagerService: FcmPushTokenManagerService,
    private navigateHelperService: NavigateHelperService
  ) {}

  public async addListener() {
    if (this.fcmPushTokenManagerService.isPushSupported()) {
      PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
        const notificationAction: NotificationAction = {
          id: notification.notification.data.id,
          notificationEventType: notification.notification.data.notificationEventType,
          protocolEntryId: notification.notification.data.protocolEntryId,
          pdfPlanVersionId: notification.notification.data.pdfPlanVersionId,
          bimVersionId: notification.notification.data.bimVersionId,
          projectId: notification.notification.data.projectId,
        };

        await this.handleNotificationClick(notificationAction);
      });
    }
  }

  private async closeAllModals(): Promise<boolean> {
    let topModal = await this.modalController.getTop();
    while (topModal !== undefined) {
      const dismissed = await topModal.dismiss();
      if (dismissed) {
        topModal = await this.modalController.getTop();
      } else {
        topModal = undefined;
        await this.alertService.ok({
          header: 'notificationSync.alertHeader',
          message: 'notificationSync.alertMessage',
          confirmLabel: 'close',
        });
        return false;
      }
    }
    return true;
  }

  async showAlertNoObject() {
    await this.alertService.ok({
      header: 'notificationSync.noObjectHeader',
      message: 'notificationSync.noObjectMessage',
      confirmLabel: 'close',
    });
  }

  public async handleNotificationClick(
    notificationAction: NotificationAction,
    {skipSyncModalIfProjectPresent = false, openEntryModalInsteadOfNavigation = false, skipCloseModal = false, skipChangingProject = false}: Partial<HandleNotificationClickOptions> = {}
  ) {
    if (this.handlingNotification) {
      return;
    }
    this.handlingNotification = true;
    this.posthogService.captureEvent('[Notification][Push] Tapped on Notification', {});
    try {
      if (!skipCloseModal && !(await this.closeAllModals())) {
        return;
      }

      const ensureProjectPresent = async (skipSyncModalIfProjectExists = skipSyncModalIfProjectPresent) => {
        if (skipSyncModalIfProjectExists) {
          const project = await observableToPromise(this.projectDataService.getByIdAcrossClients(notificationAction.projectId));
          if (project) {
            const storageInitialized = await observableToPromise(this.protocolEntryDataService.storageInitializedWithOptional$);
            if (storageInitialized.optional || storageInitialized.initializedMap.has(notificationAction.projectId)) {
              return 'exists';
            }
          }
        }

        const modal = await this.modalController.create({
          component: NotificationSyncModalComponent,
          backdropDismiss: false,
          componentProps: {
            projectId: notificationAction.projectId,
          },
        });
        await modal.present();

        return (await modal.onDidDismiss()).role === 'success' ? 'synced' : 'cancelled';
      };

      const ensureResult = await ensureProjectPresent();

      if (ensureResult !== 'cancelled') {
        const syncIfNecessary = async <T>(object: Nullish<T>) => {
          if (ensureResult === 'synced') {
            return 'already-synced';
          }

          const syncHistory = await this.syncHistoryService.getSyncHistory(notificationAction.projectId);
          if (!object || !notificationAction.createdAt || !syncHistory || new Date(syncHistory.endServerTime).getTime() < new Date(notificationAction.createdAt).getTime()) {
            return await ensureProjectPresent(false);
          }

          return 'already-fresh';
        };
        const eventType = notificationAction.notificationEventType;
        const currentProject = await this.projectDataService.getMandatoryCurrentProject();
        let protocolEntry: ProtocolEntry | undefined;
        let protocol: Protocol | undefined;
        if (this.isEntryEvent(eventType, notificationAction)) {
          protocolEntry = await this.protocolEntryService.getProtocolEntry(notificationAction.protocolEntryId);
          if ((await syncIfNecessary(protocolEntry)) === 'synced') {
            protocolEntry = await this.protocolEntryService.getProtocolEntry(notificationAction.protocolEntryId);
          }
          if (!protocolEntry) {
            await this.showAlertNoObject();
            return;
          }
          protocol = await this.protocolService.getProtocolById(protocolEntry.protocolId);
        }
        if (!skipChangingProject && currentProject.id !== notificationAction.projectId) {
          const project = await observableToPromise(this.projectDataService.getByIdAcrossClients(notificationAction.projectId));
          if (!project) {
            await this.showAlertNoObject();
            return;
          }
          await this.projectDataService.setCurrentProject(project);
          await this.navigateHelperService.resetPagesBeforeEntryNavigation(project.id, protocol?.id);
        }
        if (this.isEntryEvent(eventType, notificationAction)) {
          const protocolId = protocol.id;
          if (openEntryModalInsteadOfNavigation) {
            this.protocolEntryModalService.openModal(protocolEntry, undefined, {awaitDismiss: true}).then((result) => {
              if (result && result?.role === 'goToEntry') {
                this.notificationsCenterService.closeCenter();
              }
            });
          } else if (isTaskProtocol(protocol)) {
            await this.router.navigate([`/tasks/card/entry/${notificationAction.protocolEntryId}`]);
          } else {
            await this.router.navigate(['/protocols/view/' + protocolId + '/entry/' + protocolId + '/' + notificationAction.protocolEntryId]);
          }
        }

        if (eventType === 'ProjectUserAdded' && notificationAction.projectId) {
          await this.router.navigate(['/dashboard']);
        }
        if (eventType === 'DocumentPdfPlanNew' && notificationAction.pdfPlanVersionId) {
          let planVersion = await observableToPromise(this.pdfPlanVersionDataService.getByIdAcrossProjects(notificationAction.pdfPlanVersionId));
          if ((await syncIfNecessary(planVersion)) === 'synced') {
            planVersion = await observableToPromise(this.pdfPlanVersionDataService.getByIdAcrossProjects(notificationAction.pdfPlanVersionId));
          }
          const plan = await observableToPromise(this.pdfPlanDataService.getByIdAcrossProjects(planVersion?.pdfPlanId));
          if (plan) {
            await this.router.navigate([`/project-room/pdf-plan-folders/${plan.folderId}`]);
          } else {
            await this.showAlertNoObject();
            return;
          }
        }

        if (eventType === 'DocumentBimPlanNew' && notificationAction.bimVersionId) {
          let bimVersion = await observableToPromise(this.bimVersionDataService.getByIdAcrossProjects(notificationAction.bimVersionId));
          if ((await syncIfNecessary(bimVersion)) === 'synced') {
            bimVersion = await observableToPromise(this.bimVersionDataService.getByIdAcrossProjects(notificationAction.bimVersionId));
          }
          if (bimVersion) {
            await this.router.navigate(['/project-room/bim']);
          } else {
            await this.showAlertNoObject();
            return;
          }
        }
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `handleNotificationClick failed with error ${convertErrorToMessage(error)}`);
    } finally {
      this.handlingNotification = false;
    }
  }

  isEntryEvent(eventType: string, notificationAction: NotificationAction) {
    return (
      (eventType === 'ProtocolEntryUpdateAssignee' ||
        eventType === 'ProtocolEntryNew' ||
        eventType === 'ProtocolEntryNewSub' ||
        eventType === 'ProtocolEntryChatNew' ||
        eventType === 'ProtocolEntryUpdateStatus' ||
        eventType === 'ProtocolEntryUpdateTodoUntil') &&
      notificationAction.protocolEntryId
    );
  }
}
