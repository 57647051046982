<div class="d-flex">
  <div class="omg-modal-header title" *ngIf="title">
    {{ title }} 
  </div>
  <ion-button class="omg-btn-text close-button" (click)="dismiss()">
    <fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" slot="icon-only"></fa-icon>
  </ion-button>
</div>

<div class="d-flex mt-2">
  <div class="text">
    <span [innerHTML]="text | safeHtml"></span>
  </div>
</div>

<ion-grid class="ion-no-padding mt-4" *ngIf="hintText">
  <ion-row class="hint-box ion-no-padding ion-no-margin">
    <ion-col size="1" class="d-flex ion-align-items-center ion-justify-content-center ion-no-padding ion-no-margin">
      <fa-icon class="hint-icon" [icon]="['fal', 'lightbulb-on']"></fa-icon>
    </ion-col>
    <ion-col size="11" class="text ion-no-padding ion-no-margin">
      <span class="hint-text" [innerHTML]="hintText | safeHtml"></span>
    </ion-col>
  </ion-row>
</ion-grid>

<div class="d-flex ion-align-items-center pt-1 pb-2" *ngIf="linkText && pageLink">
  <a class="clickable-link" [innerHtml]="linkText | safeHtml" (click)="navigateToPage()" ></a>
</div>

<div *ngIf="lottieFilename && options">
  <ng-lottie
    [options]="options"
    (animationCreated)="animationCreated($event)">
  </ng-lottie>
</div>
