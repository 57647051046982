import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AlertService} from '../ui/alert.service';

type LeaveEditModeResult = 'not-in-edit-mode' | 'left' | 'stayed';

export type EditMode = 'multiselect' | 'single';

@Injectable()
export abstract class AbstractEditModeService {
  private editModeSubject = new BehaviorSubject<EditMode | false>(false);
  editMode$ = this.editModeSubject.asObservable();

  get editMode() {
    return this.editModeSubject.value;
  }

  private leaveEditModePromise: Promise<LeaveEditModeResult> | undefined;

  constructor(private alertService: AlertService) {}

  enterEditMode(mode: EditMode = 'multiselect') {
    if (this.editMode) {
      return;
    }

    this.editModeSubject.next(mode);
  }

  private async askAndPerformLeaveEditMode(): Promise<LeaveEditModeResult> {
    if (!this.editMode) {
      return 'not-in-edit-mode';
    }

    const wantToLeave = await this.alertService.confirm({
      header: 'project_room.pdf_plan_folder.confirm_edit_mode_leave.header',
      message: 'project_room.pdf_plan_folder.confirm_edit_mode_leave.message',
      cancelLabel: 'cancel',
      confirmLabel: 'button.quit',
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
    });

    if (wantToLeave) {
      this.editModeSubject.next(false);

      return 'left';
    }

    return 'stayed';
  }

  unsafeLeaveEditMode() {
    if (this.editMode) {
      this.editModeSubject.next(false);
    }
  }

  safeLeaveEditMode(): Promise<LeaveEditModeResult> {
    if (!this.leaveEditModePromise) {
      this.leaveEditModePromise = this.askAndPerformLeaveEditMode().then((value) => {
        this.leaveEditModePromise = undefined;

        return value;
      });
    }

    return this.leaveEditModePromise;
  }
}
