const WebAnalyticsConfig = {};

try {
  /*
   * web-analytics-config contents:
    {
      "apiKey": "API_KEY",
      "authDomain": "PROJECT_ID.firebaseapp.com",
      "databaseURL": "https://PROJECT_ID.firebaseio.com",
      "projectId": "PROJECT_ID",
      "storageBucket": "PROJECT_ID.appspot.com",
      "messagingSenderId": "SENDER_ID",
      "appId": "APP_ID",
      "measurementId": "G-MEASUREMENT_ID",
    }
   * To obtain this config, refer to this help article: https://support.google.com/firebase/answer/7015592#web
   *
   * If this file won't be provided, web analytics won't work, but the app won't crash neither
   */
  Object.assign(WebAnalyticsConfig, require('../../web-analytics-config.json'));
} catch (e) {}

const hasWebAnalyticsConfig = Object.keys(WebAnalyticsConfig).length > 0;

export {hasWebAnalyticsConfig, WebAnalyticsConfig};
