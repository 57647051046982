import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {orderBy} from 'lodash';
import {Observable} from 'rxjs';
import {filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {EntryCardListModel} from 'src/app/model/entry-card-model';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {IdType} from 'submodules/baumaster-v2-common';
import {AbstractEntryListService} from '../entry/abstract-entry-list.service';
import {EntryService} from '../entry/entry.service';

@Injectable()
export class ProtocolEntryListService extends AbstractEntryListService {
  readonly currentProtocolId$: Observable<IdType>;
  readonly lastEntryId$: Observable<string>;
  readonly currentEntryId$: Observable<string | undefined>;

  constructor(entryService: EntryService, router: Router) {
    const currentUrl$ = router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith(router.url),
      map(() => router.url)
    );
    const lastEntryId$ = currentUrl$.pipe(
      filter((url) => url.startsWith('/protocols/view') && url.includes('/entry')),
      shareReplay({refCount: true, bufferSize: 1})
    );
    const currentEntryId$ = currentUrl$.pipe(
      startWith(router.url),
      map((url) => (url.startsWith('/protocols/view') && url.includes('/entry') ? url.slice(url.indexOf('/entry') + 7).split('/')[1] : undefined)),
      shareReplay({refCount: true, bufferSize: 1})
    );
    const currentProtocolId$: Observable<string | undefined> = currentUrl$.pipe(
      map((url) => (url.startsWith('/protocols/view/') ? url.substring(0, url.indexOf('/entry/') === -1 ? undefined : url.indexOf('/entry/')).replace('/protocols/view/', '') : undefined))
    );
    super(currentProtocolId$.pipe(switchMap((protocolId) => entryService.getEntriesForProtocolWithAnyLayout$(protocolId))));
    this.lastEntryId$ = lastEntryId$;
    this.currentEntryId$ = currentEntryId$;
    this.currentProtocolId$ = currentProtocolId$;
    this.sortOrderAsc = true;
  }

  protected sortAndMapEntries(entries: EntryCardListModel[], sortOrderAsc: boolean = true): EntryCardListModel[] {
    const subEntryOrderForMainEntry = sortOrderAsc ? -Infinity : Infinity;
    return orderBy(entries, ['mainEntryOrder', (e) => (e.subEntryOrder === 0 ? subEntryOrderForMainEntry : e.subEntryOrder)], [sortOrderAsc ? 'asc' : 'desc', sortOrderAsc ? 'asc' : 'desc']);
  }

  protected sortAndMapEntriesAfterFilter(entries: EntryCardListModel[], filters: ProtocolEntrySearchFilter, search: string): EntryCardListModel[] {
    const companyIdFilter = filters.entry.companyId.in;
    const observerCompaniesFilter = filters.entry.observerCompanies.in;
    const shouldGroup = companyIdFilter?.length === 1 && observerCompaniesFilter?.length === 1 && companyIdFilter[0] === observerCompaniesFilter[0];

    if (!shouldGroup) {
      return entries;
    }

    const groupByFn = (entry: EntryCardListModel) => entry.companyId === companyIdFilter[0];
    const hasAnyChildGroupFnById: Record<IdType, boolean> = {};
    for (const entry of entries) {
      if (entry.parentId) {
        hasAnyChildGroupFnById[entry.parentId] = hasAnyChildGroupFnById[entry.parentId] || groupByFn(entry);
      }
    }

    const extendedGroupByFn = (entry: EntryCardListModel) => hasAnyChildGroupFnById[entry.id] || groupByFn(entry);

    const groupedEntries = [
      ...entries.filter((entry) => entry.companyId === companyIdFilter[0] || hasAnyChildGroupFnById[entry.id]),
      ...entries.filter((entry) => entry.companyId !== companyIdFilter[0] && !hasAnyChildGroupFnById[entry.id]),
    ].map((entry, index, arr) => ({
      ...entry,
      groupId: extendedGroupByFn(entry) ? companyIdFilter[0] : entry.companyId,
      firstInGroup: index === 0 || extendedGroupByFn(arr[index - 1]) !== extendedGroupByFn(entry),
    }));

    return groupedEntries;
  }
}
