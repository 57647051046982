import {ColumnConfig} from '../ColumnConfig';
import {PdfPlanColumnSet} from './PdfPlanColumnSet';

export const PdfPlanFolderColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'project_id', prop: 'projectId'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'icon', def: null},
  {name: 'pdfPlans', fk: 'folderId', mappedBy: PdfPlanColumnSet, transient: true},
];
