import {ToastDurationInMs} from './../../../shared/constants';
import {LoggingService} from './../../../services/common/logging.service';
import {MediaService} from './../../../services/media/media.service';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {AlertController, Platform} from '@ionic/angular';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {FileEntry} from '@awesome-cordova-plugins/file/ngx';
import {AndroidPermissionResponse, AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {waitAndResolve} from 'src/app/utils/async-utils';

const LOG_SOURCE = 'AudioRecordingComponent';

@Component({
  selector: 'app-audio-recording',
  templateUrl: './audio-recording.component.html',
  styleUrls: ['./audio-recording.component.scss'],
})
export class AudioRecordingComponent implements OnInit, OnDestroy {
  constructor(
    private mediaService: MediaService,
    private translateService: TranslateService,
    private toastService: ToastService,
    public platform: Platform,
    private alertCtrl: AlertController,
    private androidPermissions: AndroidPermissions,
    private loggingService: LoggingService
  ) {}

  private timerSubject = new Subject<number>();
  private timer: Observable<number>;
  private stopped = false;
  private canceled = false;
  private audioBlob: Blob;
  private audioFile: FileEntry;
  private audioRecording = false;
  private isPermitted: boolean;
  public timeElapsed: number;
  private backButtonSubscription: Subscription | undefined;

  private modal: HTMLIonModalElement;

  async ngOnInit() {
    this.platform.backButton.subscribe(async () => {
      await this.onCancel();
      this.loggingService.debug(LOG_SOURCE, 'Recording stopped after back button press.');
    });
    // Start recording immedately when entering this component.
    await this.startAudioRecording();
    this.setCanDismiss();
  }

  private canDismiss = async () => {
    if (!this.audioRecording || this.canceled) {
      return true;
    }

    return this.dismissWithConfirmation();
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  private async dismissWithConfirmation() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss',
          handler: () => {
            this.cancel();
          },
        },
      ],
    });
    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  }

  prepareSubject() {
    this.timer.pipe(takeUntil(this.timerSubject)).subscribe(async (value) => {
      this.timeElapsed = value;
      if (this.stopped || this.canceled) {
        this.timerSubject.next(0);
        this.timerSubject.complete();
        if (this.stopped && !this.canceled) {
          this.loggingService.debug(LOG_SOURCE, 'Recording was finished.');
          if (this.audioBlob && this.audioFile) {
            await this.dismissModal({blobObject: this.audioBlob, fileEntry: this.audioFile});
          } else {
            this.loggingService.warn(LOG_SOURCE, 'Recording response from modal is empty.');
            await this.dismissModal();
          }
        }
        if (this.canceled && !this.stopped) {
          this.loggingService.debug(LOG_SOURCE, 'Recording was canceled.');
          await this.toastService.info('attachment.audioRecordingCanceled');
          await this.dismissModal();
        }
      }
    });
  }

  async startAudioRecording() {
    if (this.audioRecording) {
      this.loggingService.warn(LOG_SOURCE, 'Audio recording already running.');
      return;
    }
    try {
      if (this.platform.is('android')) {
        const microphonePerm: AndroidPermissionResponse = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO);
        const storagePerm: AndroidPermissionResponse = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        if (microphonePerm.hasPermission && storagePerm.hasPermission) {
          this.isPermitted = true;
        } else {
          const response: AndroidPermissionResponse = await this.androidPermissions.requestPermissions([
            this.androidPermissions.PERMISSION.RECORD_AUDIO,
            this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
          ]);
          if (response.hasPermission) {
            this.isPermitted = true;
          }
        }
      }
      if (this.platform.is('ios')) {
        await this.mediaService.startAudioRecording();
        this.isPermitted = true;
      }
      if (!this.isPermitted) {
        await this.toastService.info('attachment.error.audioRecordingPermission');
        await this.onCancel();
        await this.dismissModal();
        return;
      } else {
        if (this.platform.is('android')) {
          await this.mediaService.startAudioRecording();
        }
        this.timer = timer(0, 1000);
        this.prepareSubject();
        this.audioRecording = true;
      }
    } catch (e) {
      await this.toastService.toastWithTranslateParams('attachment.error.startAudioRecordingFailed', {message: convertErrorToMessage(e)}, ToastDurationInMs.ERROR);
    }
  }

  async onStopClick() {
    if (!this.audioRecording) {
      this.loggingService.warn(LOG_SOURCE, 'Audio recording not running (already stopped).');
      return;
    }
    try {
      const audioFileEntry = await this.mediaService.stopAudioRecording();
      this.audioRecording = false;
      this.loggingService.debug(LOG_SOURCE, `stopAudioRecording finished with audioFileEntry.nativeURL "${audioFileEntry?.nativeURL}".`);
      const handleBlob = async (audioBlob: Blob, retry = true) => {
        this.loggingService.debug(LOG_SOURCE, `converting audioFileEntry.nativeURL "${audioFileEntry?.nativeURL}" to blob of type ${audioBlob?.type} resulted in blob size ${audioBlob?.size}.`);
        if (audioBlob.size > 0 && audioBlob.type && audioBlob.type !== '') {
          this.audioBlob = audioBlob;
          this.audioFile = audioFileEntry;
        } else if (this.platform.is('ios') && audioBlob.size > 0 && !audioBlob.type) {
          this.audioBlob = new Blob([audioBlob.slice()], {type: 'audio/mp4'});
          this.audioFile = audioFileEntry;
        } else {
          // For any reason, Android sometimes returns an empty file; we just need to give a little bit of time for the OS to save the file into the filesystem
          if (retry) {
            await handleBlob(await waitAndResolve(() => this.mediaService.getBlob(audioFileEntry.nativeURL), 100), false);
          } else {
            await this.toastService.toast('attachment.error.audioRecordingFileIsEmpty', ToastDurationInMs.ERROR, {header: this.translateService.instant('attachment.error.header')});
          }
        }
      };

      await handleBlob(await this.mediaService.getBlob(audioFileEntry.nativeURL));

      this.stopped = !this.stopped;
    } catch (e) {
      await this.toastService.toastWithTranslateParams('attachment.error.audioRecordingFailed', {message: convertErrorToMessage(e)}, ToastDurationInMs.ERROR);
    }
  }

  async onCancel() {
    await this.dismissModal();
  }

  async cancel() {
    await this.mediaService.stopAudioRecording();
    this.canceled = true;
    await this.dismissModal();
  }

  dismissModal(data?: {blobObject: Blob; fileEntry: FileEntry}) {
    return this.modal.dismiss(data);
  }

  async ionViewDidLeave() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidLeave called.');
    await this.onCancel();
  }

  ngOnDestroy(): void {
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
      this.backButtonSubscription = undefined;
    }
  }
}
