import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'monthName',
})
export class MonthNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number): string {
    return this.translateService.instant('months.' + value);
  }
}
