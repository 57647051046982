import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Nullish} from 'src/app/model/nullish';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @Input()
  summary: string;

  private _open = true;
  @Input()
  set open(open: Nullish<boolean>) {
    if (open === Boolean(open)) {
      this._open = open;
    }
  }

  get open() {
    return this._open;
  }

  @Output()
  openChange = new EventEmitter<boolean>();

  constructor(private cdRef: ChangeDetectorRef) {}

  toggle() {
    this._open = !this.open;
    this.openChange.emit(this.open);
  }

  setOpen(open: boolean) {
    this.open = open;
    this.cdRef.markForCheck();
  }
}
