import {Column, Content, StyleDictionary, TDocumentDefinitions} from 'pdfmake/interfaces';
import {PdfReportSendReq} from '../../requestResponse';
import {PdfReportGenerateData} from './pdfReport.model';
import _ from 'lodash';
import {PdfPreview} from '../../models';
import {ReportContent} from './content/report.content';
import Translation from '../../i18n/translation';
import {formatDate} from '../protocol/content/abstractPdf.content';
import {formatProjectNumberOptional} from '../../commonUtils';

const EMPTY_IMAGE_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';

export abstract class AbstractPdfReportCommonService {
  getDocDefinition(config: PdfReportSendReq, data: PdfReportGenerateData, pdfPreview?: PdfPreview): TDocumentDefinitions {
    const content: Content = [];
    const reportContent = new ReportContent(config, data, pdfPreview);

    reportContent.writeReport(content);

    if (config.appendActivityAttachments || config.appendOtherAttachments || config.appendMaterialAttachments || config.appendEquipmentAttachments) {
      reportContent.writeAttachmentsAfterReport(content);
    }

    const language = data.project.language;
    return this.getGeneratedDocDefinitions(language, content, config, data);
  }

  protected getGeneratedDocDefinitions(language: string, content: Content[], config: PdfReportSendReq, data: PdfReportGenerateData): TDocumentDefinitions {
    const docDefinition: TDocumentDefinitions = {
      pageSize: 'A4',
      content,
      styles: this.getPdfStyle(config),

      images: this.getPdfImage(data),
      header: (currentPage: number, pageCount: number, pageSize) => {
        if (currentPage === 1) {
          return;
        }
        return this.getPdfHeader(data, language);
      },
      footer: (currentPage: number, pageCount: number) => {
        return this.getPdfFooter(currentPage, pageCount, language, config);
      },
      defaultStyle: {
        font: 'Inter',
      },
      pageMargins: [40, 50, 40, 40],
    };
    return docDefinition;
  }

  protected getPdfHeader(data: PdfReportGenerateData, lng: string): Content[] {
    const i18n = this.getI18n(lng);
    const content: Content[] = [];
    const outerColumn: Column[] = [];
    const innerColumns: Column[] = [];
    const reportType = data.lookup.reportTypes.get(data.reportWeek.typeId);
    if (!reportType) {
      throw new Error(`Unable to find reportType with id ${data.reportWeek.typeId}.`);
    }

    innerColumns.push({
      margin: [40, 20, 40, 0],
      columns: [
        {
          width: '*',
          text: [
            {
              text: `${formatProjectNumberOptional(data.project.number)} - ${data.project.name} / `,
              style: ['font8'],
            },
            {
              text: `${i18n?.get(`reportTypeCode.${reportType.name}`)} | Nr. ${i18n?.get(`reportTypeCodeAbbreviation.${reportType.name}`)} ${data.report.reportNumber}`,
              style: ['font8', 'textBold'],
            },
          ],
        },
        {
          width: 'auto',
          text: formatDate(data.report.date),
          style: ['font8', 'textBold', 'alignLeft'],
        },
      ],
    });

    innerColumns.push({
      margin: [40, 3, 0, 0],
      columns: [
        {
          canvas: [{type: 'line', x1: 0, y1: 2, x2: 515, y2: 2, lineWidth: 0.5, lineColor: '#B3B3B3'}],
        },
      ],
    });

    outerColumn.push(innerColumns);

    content.push(outerColumn);
    return content;
  }

  protected getPdfFooter(currentPage: number, pageCount: number, lng: string, config: PdfReportSendReq): Content {
    const i18n = this.getI18n(lng);
    const columns: Column[] = [];

    if (currentPage === pageCount) {
      const footerColumns: Column[] = [];

      if (config.customPdfConfig?.oemPdfs !== true) {
        footerColumns.push({
          fontSize: 8,
          text: `${i18n?.get('slogan')}`,
          width: 'auto',
        });

        footerColumns.push({
          fontSize: 8,
          text: '-',
          style: ['marginLeft2'],
          width: 'auto',
        });

        footerColumns.push({
          fontSize: 8,
          link: 'https://bau-master.com/?utm_source=baumaster&utm_medium=protokoll_protokoll&utm_campaign=protokoll_footer',
          text: 'www.bau-master.com',
          style: ['marginLeft2'],
        });
      }

      footerColumns.push({
        width: '*',
        fontSize: 8,
        text: `${i18n?.get('page')} ${currentPage.toString()}/${pageCount}`,
        alignment: 'right',
      });

      columns.push({
        columns: footerColumns,
      });
    } else {
      columns.push({
        columns: [
          {
            width: '*',
            fontSize: 8,
            text: `${i18n?.get('page')} ${currentPage.toString()}/${pageCount}`,
            alignment: 'right',
          },
        ],
      });
    }

    return {
      style: ['footer'],
      columns: [columns],
    };
  }

  protected getI18n(lng: string): Map<string, string> | undefined {
    const translation = new Translation();
    return translation.getTranslation(lng);
  }

  // From baumaster-v2-client/submodules/baumaster-v2-common/src/pdf/protocol/abstractPdfCommon.service.ts
  protected getPdfStyle(config: PdfReportSendReq): StyleDictionary {
    return {
      font7: {fontSize: 7},
      font8: {fontSize: 8},
      font9: {fontSize: 9},
      font10: {fontSize: 10},
      font12: {fontSize: 12},
      font15: {fontSize: 15},
      font18: {fontSize: 18},
      margin5: {margin: 5},
      marginBottom10: {margin: [0, 0, 0, 10]},
      marginTop1: {margin: [0, 1, 0, 0]},
      marginTop5: {margin: [0, 5, 0, 0]},
      marginTop10: {margin: [0, 10, 0, 0]},
      marginTop20: {margin: [0, 20, 0, 0]},
      groupName: {margin: [0, 10, 0, 0]},
      marginLeft10Top5: {margin: [10, 5, 0, 0]},
      marginLeft10: {margin: [10, 0, 0, 0]},
      marginLeft5: {margin: [5, 0, 0, 0]},
      marginLeft2: {margin: [2, 0, 0, 0]},
      fontColorGray: {color: '#5D5D5D'},
      fontColorRed: {color: '#AD003E'},
      fontColorBlue: {color: '#2691C8'},
      colorGreen: {color: '#78C832'},
      participantCompany: {margin: [0, 2, 0, 0]},
      participant: {margin: [10, 2, 0, 0]},
      textBold: {bold: true},
      alignRight: {alignment: 'right'},
      alignLeft: {alignment: 'left'},
      alignCenter: {alignment: 'center'},
      companyInfo: {margin: [0, 5, 0, 0]},
      projectInfo: {fontSize: 16},
      moreInfoPage: {margin: [0, 3, 0, 0]},
      header: {margin: [40, 30]},
      footer: {margin: [40, 10]},
      lastFooterWithSignatures: {margin: [40, -106, 40, 0]},
      lastFooter: {margin: [40, -111, 40, 0]},
      lastPageWithSignatures: {margin: [40, 106]},
      lastPage: {margin: [40, 106]},
      marginTop10Bottom30: {margin: [0, 5, 0, 30]},
      backgroundGray: {fillColor: '#EEEEEE'},
    };
  }

  // From baumaster-v2-client/submodules/baumaster-v2-common/src/pdf/protocol/abstractPdfCommon.service.ts
  protected getImageBase64(contentBase64: string | undefined): string {
    if (_.isEmpty(contentBase64)) {
      return EMPTY_IMAGE_BASE64;
    }
    return contentBase64 + '';
  }

  // From baumaster-v2-client/submodules/baumaster-v2-common/src/pdf/protocol/abstractPdfCommon.service.ts
  protected getPdfImage(data: PdfReportGenerateData): {[key: string]: string} {
    return {
      pdfStartBanner: this.getImageBase64(data.pdfProjectBanners?.get('pdf_start_banner')?.contentBase64 ?? data.attachmentClients?.get('pdfStartBanner')?.contentBase64),
      pdfEndBanner: this.getImageBase64(data.pdfProjectBanners?.get('pdf_end_banner')?.contentBase64 ?? data.attachmentClients?.get('pdfEndBanner')?.contentBase64),
      clientSign: this.getImageBase64(data.attachmentReportSignatures?.find((sign) => sign.attachment.code === 'client')?.contentBase64),
      contractorSign: this.getImageBase64(data.attachmentReportSignatures?.find((sign) => sign.attachment.code === 'contractor')?.contentBase64),
    };
  }
}
