import {Injectable} from '@angular/core';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {StorageService} from '../storage.service';
import {PictureQualitySetting} from 'src/app/model/picture-quality-setting';

const LOG_SOURCE = 'PictureQualityService';
const STORAGE_KEY = StorageKeyEnum.PICTURE_QUALITY;

const DEFAULT_PICTURE_QUALITY: PictureQualitySetting = 'STANDARD';

@Injectable({
  providedIn: 'root',
})
export class PictureQualityService {
  qualitySetting: PictureQualitySetting = DEFAULT_PICTURE_QUALITY;

  constructor(
    private storage: StorageService,
    private loggingService: LoggingService
  ) {}

  private async persistQualitySetting() {
    this.loggingService.debug(LOG_SOURCE, `Persisting quality setting ${this.qualitySetting} to the storage`);
    await this.storage.set(STORAGE_KEY, this.qualitySetting, {
      ensureStored: false,
      immediate: false,
    });
  }

  private async applyPictureQualitySetting(writeToStorage = true) {
    if (writeToStorage) {
      await this.persistQualitySetting();
    }
  }

  public async initializePictureQuality() {
    this.loggingService.debug(LOG_SOURCE, 'initializePictureQuality called.');

    const storedPictureQuality = await this.storage.get(STORAGE_KEY);
    let writeToStorage: boolean;
    if (typeof storedPictureQuality === 'boolean' || !storedPictureQuality) {
      this.qualitySetting = DEFAULT_PICTURE_QUALITY;
      writeToStorage = true;
    } else {
      this.qualitySetting = storedPictureQuality as PictureQualitySetting;
      writeToStorage = false;
    }

    this.applyPictureQualitySetting(writeToStorage);
  }

  public setCurrentPictureQuality(quality: PictureQualitySetting) {
    this.qualitySetting = quality;
    this.applyPictureQualitySetting();
  }
}
