import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CreateUnitInLevelEvent} from 'src/app/components/units/unit-levels-grid/unit-levels.grid.model';
import {UnitModalComponent} from 'src/app/components/units/unit-modal/unit-modal.component';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {IdType, Unit, UnitLevel} from 'submodules/baumaster-v2-common';
import {UnitLevelDataService} from '../data/unit-level-data.service';

@Injectable({
  providedIn: 'root',
})
export class UnitActionsService {
  constructor(
    private modalController: ModalController,
    private unitLevelDataService: UnitLevelDataService
  ) {}

  async createUnit(unitLevel: UnitLevel, insertPosition?: CreateUnitInLevelEvent['insertPosition'], parentUnitId?: IdType) {
    const modal = await this.modalController.create({
      component: UnitModalComponent,
      componentProps: {
        unitLevel,
        insertPosition,
        parentUnitId,
      },
      cssClass: 'omg-modal omg-boundary pdf-workflow-modal',
    });

    await modal.present();

    return await modal.onWillDismiss();
  }

  async editUnit(unit: Unit) {
    const unitLevel = await observableToPromise(this.unitLevelDataService.getById(unit.unitLevelId));
    if (!unitLevel) {
      throw new Error(`Unit level ${unit.unitLevelId} not found for unit ${unit.id}`);
    }
    const modal = await this.modalController.create({
      component: UnitModalComponent,
      componentProps: {
        unit,
        unitLevel,
        index: unit.index,
        parentUnitId: unit.parentId,
      },
      cssClass: 'omg-modal omg-boundary pdf-workflow-modal',
    });

    await modal.present();

    return modal.onWillDismiss();
  }
}
