import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {v4 as uuid4} from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, of} from 'rxjs';
import {debounceTime, map, startWith, switchMap, take} from 'rxjs/operators';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {UserNameString} from 'src/app/utils/user-name.pipe';
import {
  Activity,
  ActivityTypeEnum,
  Address,
  Attachment,
  AttachmentReportActivity,
  AttachmentReportCompany,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  CustomReportType,
  Employee,
  Equipment,
  IdAware,
  IdType,
  isAttachmentReportActivity,
  isAttachmentReportCompany,
  isAttachmentReportEquipment,
  isAttachmentReportMaterial,
  LicenseType,
  Material,
  Participant,
  PdfPreview,
  Profile,
  Project,
  ProjectProfile,
  Report,
  ReportCompany,
  ReportCompanyActivity,
  ReportType,
  ReportTypeCode,
  ReportWeek,
  Staff,
  User,
} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import moment from 'moment';
import {LoggingService} from '../common/logging.service';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ReportDataService} from '../data/report-data.service';
import {ReportTypeDataService} from '../data/report-type-data.service';
import {ReportWeekDataService} from '../data/report-week-data.service';
import {UserService} from '../user/user.service';
import {SystemEventService} from '../event/system-event.service';
import {UserProfileService} from '../user/user-profile.service';
import {ActivityDataService} from '../data/activity-data.service';
import {PdfPreviewDataService} from '../data/pdf-preview-data.service';
import {CustomReportTypeDataService} from '../data/custom-report-type-data.service';
import {ReportCompanyDataService} from '../data/report-company-data.service';
import {AttachmentReportActivityDataService} from '../data/attachment-report-activity-data.service';
import {AttachmentReportCompanyDataService} from '../data/attachment-report-company-data.service';
import {ReportCompanyActivityDataService} from '../data/report-company-activity-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {ContactService} from '../contact/contact.service';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ParticipantDataService} from '../data/participant-data.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {AttachmentBlob} from 'src/app/model/attachments';
import {extractCommonAttachmentProperties, isAttachmentBlob} from 'src/app/utils/attachment-utils';
import {AttachmentReportMaterialDataService} from '../data/attachment-report-material-data.service';
import {MaterialDataService} from '../data/material-data.service';
import {EquipmentDataService} from '../data/equipment-data.service';
import {AttachmentReportEquipmentDataService} from '../data/attachment-report-equipment-data.service';
import {ProjectCustomReportTypeDataService} from '../data/project-custom-report-type-data.service';
import {StaffDataService} from '../data/staff-data.service';
import {AbstractProjectAwareDataService} from '../data/abstract-project-aware-data.service';
import {AbstractProjectAwareAttachmentDataService} from '../data/abstract-project-aware-attachment-data.service';
import {
  ActivitySource,
  EquipmentSource,
  MaterialSource,
  ReportAttachmentCreatedEvent,
  ReportAttachmentDeletedEvent,
  ReportAttachmentEventBase,
  ReportAttachmentUpdatedEvent,
  ReportCompanySource,
  ReportDiffObjectsEvent,
  ReportsPageTypeEnum,
  ReportTypeOrCustomReportType,
  ReportTypeWithHasReports,
  ReportWithPreviews,
  WithAttachmentsSource,
} from 'src/app/model/reports';
import {EmployeeDataService} from '../data/employee-data.service';
import {Nullish} from 'src/app/model/nullish';
import {reportTypeCodeToPageType} from 'src/app/utils/report-utils';
import {convertDateTimeToISOString} from 'src/app/utils/date-utils';
import {PosthogService} from '../posthog/posthog.service';

interface AttachmentBlobBundle<T extends Attachment> {
  attachments: T[];
  blobs: Blob[];
}

const LOG_SOURCE = 'ReportService';

const REPORT_REST_ENDPOINT_URI = 'api/data/reports';
const removeReportUrl = (projectId: IdType, reportId: IdType) => environment.serverUrl + `${REPORT_REST_ENDPOINT_URI}/${projectId}/${reportId}/delete`;

type IdAwareWithAttachmentsSource = IdAware & WithAttachmentsSource;

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private reportDataService: ReportDataService,
    private reportWeekDataService: ReportWeekDataService,
    private reportTypeDataService: ReportTypeDataService,
    private userService: UserService,
    private userProfileService: UserProfileService,
    private profileDataService: ProfileDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private addressDataService: AddressDataService,
    private userNameString: UserNameString,
    private translateService: TranslateService,
    private projectDataService: ProjectDataService,
    private activityDataService: ActivityDataService,
    private systemEventService: SystemEventService,
    private reportCompanyDataService: ReportCompanyDataService,
    private attachmentReportCompanyDataService: AttachmentReportCompanyDataService,
    private reportCompanyActivityDataService: ReportCompanyActivityDataService,
    private contactService: ContactService,
    private locationDataService: ProtocolEntryLocationDataService,
    private pdfPreviewDataService: PdfPreviewDataService,
    private customReportTypeDataService: CustomReportTypeDataService,
    private projectCustomReportTypeDataService: ProjectCustomReportTypeDataService,
    private participantDataService: ParticipantDataService,
    private featureEnabledService: FeatureEnabledService,
    private attachmentReportActivityDataService: AttachmentReportActivityDataService,
    private attachmentReportMaterialDataService: AttachmentReportMaterialDataService,
    private attachmentReportEquipmentDataService: AttachmentReportEquipmentDataService,
    private materialDataService: MaterialDataService,
    private equipmentDataService: EquipmentDataService,
    private staffDataService: StaffDataService,
    private employeeDataService: EmployeeDataService,
    private posthogService: PosthogService
  ) {}

  public readonly reportTypes$ = this.reportTypesByTypeCodes([
    ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT,
    ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT,
    ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY,
  ]);

  getMyCompanyProjectProfilesWithName(): Observable<{id: IdType; name: string}[]> {
    return combineLatestAsync([this.projectProfileDataService.getProjectProfiles(), this.addressDataService.dataGroupedById, this.userProfileService.currentUserOwnProfile$]).pipe(
      map(([profiles, addresses, userProfile]) => [profiles.filter((profile) => profile.companyId === userProfile.companyId && profile.isActive), addresses]),
      map(this.mapProfileAndAddressToIdName),
      startWith([])
    );
  }

  mapProfileAndAddressToIdNameWithProjectProfile = ([profiles, addresses]: [{profile: Profile; projectProfile?: ProjectProfile}[], Record<string, Address>]): {id: IdType; name: string}[] => {
    return _.sortBy(
      profiles
        .map(({profile, projectProfile}) => {
          const address: Nullish<Address> = addresses[profile.addressId];
          if (!address) {
            return null;
          }
          return {
            id: profile.id,
            name: this.userNameString.transform(address, profile.isActive, !projectProfile),
          };
        })
        .filter((v) => Boolean(v)),
      [({name}) => name.toLocaleLowerCase()]
    );
  };

  mapProfileAndAddressToIdName = ([profiles, addresses]: [Profile[], Record<string, Address>]): {id: IdType; name: string}[] => {
    return _.sortBy(
      profiles
        .map((profile) => {
          const address: Nullish<Address> = addresses[profile.addressId];
          if (!address) {
            return null;
          }
          return {
            id: profile.id,
            name: this.userNameString.transform(address, profile.isActive),
          };
        })
        .filter((v) => Boolean(v)),
      [({name}) => name.toLocaleLowerCase()]
    );
  };

  getDisabledProjectProfilesWithName(includedProfileIds: IdType[] = []): Observable<{id: IdType; name: string}[]> {
    return combineLatestAsync([this.profileDataService.dataWithDefaultType$, this.addressDataService.dataGroupedById]).pipe(
      map(([profiles, addresses]) => [profiles.filter((profile) => includedProfileIds.includes(profile.id)), addresses]),
      map(this.mapProfileAndAddressToIdName),
      startWith([])
    );
  }

  getProjectProfilesWithName(includedProfileIds: IdType[] = []): Observable<{id: IdType; name: string}[]> {
    return combineLatestAsync([this.projectProfileDataService.getProjectProfilesWithMapping(includedProfileIds), this.addressDataService.dataGroupedById]).pipe(
      map(([projectProfiles, addresses]) => [projectProfiles.filter(({profile}) => profile.isActive), addresses]),
      map(this.mapProfileAndAddressToIdNameWithProjectProfile),
      startWith([])
    );
  }

  getReportWeek(report: Report): Observable<ReportWeek | undefined> {
    if (!report) {
      this.loggingService.warn(LOG_SOURCE, 'getReportWeek called but without report.');
      return of(undefined);
    }
    return this.reportWeekDataService.data.pipe(map((weeks) => weeks.find(({id}) => id === report.reportWeekId)));
  }

  getReportTypeCode(report: Report): Observable<ReportTypeCode> {
    return this.getReportWeek(report).pipe(switchMap((week) => this.reportTypeDataService.getReportTypeCodeById(week.typeId)));
  }

  getReportPageType(report: Report): Observable<ReportsPageTypeEnum> {
    return this.getReportTypeCode(report).pipe(map(reportTypeCodeToPageType));
  }

  getReportsByTypeCode(reportTypeCode: ReportTypeCode): Observable<Report[]> {
    return this.reportTypeDataService.data.pipe(
      switchMap((types) => {
        const type = types.find((theType) => theType.name === reportTypeCode);
        if (!type) {
          this.loggingService.warn(LOG_SOURCE, `Unable to find report type ${reportTypeCode}!`);
          return of([]);
        }

        return this.getReportsByType(type);
      })
    );
  }

  getReportsByType(reportTypeOrId: ReportType | IdType): Observable<Report[]> {
    const reportTypeId = typeof reportTypeOrId === 'string' ? reportTypeOrId : reportTypeOrId.id;

    return combineLatest([this.reportWeekDataService.data.pipe(map((weeks) => weeks.filter((week) => week.typeId === reportTypeId).map(({id}) => id))), this.reportDataService.data]).pipe(
      debounceTime(0),
      map(([reportWeeks, reports]) => reports.filter((report) => reportWeeks.includes(report.reportWeekId)))
    );
  }

  private toReportTypeWithHasReports(
    reportType: ReportTypeOrCustomReportType,
    reportWeeks: Array<ReportWeek>,
    reports: Array<Report>,
    participants: Array<Participant>,
    pdfPreviews: Array<PdfPreview>,
    isViewer: boolean,
    isCurrentUserConnected: boolean,
    hasCurrentUserReportRights: boolean
  ): ReportTypeWithHasReports {
    const reportWeeksWithType = reportWeeks.filter((reportWeek) => this.isReportWeekOfReportTypeOrCustomType(reportWeek, reportType));
    const reportsOfWeeks = reports.filter((report) => reportWeeksWithType.some((reportWeek) => report.reportWeekId === reportWeek.id));
    const hasPdfPreviews = reportsOfWeeks.some((report) => this.hasReportPdfPreviews(report, pdfPreviews, participants, isViewer, isCurrentUserConnected, hasCurrentUserReportRights));
    const hasReports = reportsOfWeeks.length > 0;
    const hasClosedReports = reportsOfWeeks.some((report) => report.closedAt);
    return {...reportType, hasReports, hasClosedReports, hasPdfPreviews};
  }

  public isReportWeekOfReportTypeOrCustomType(reportWeek: ReportWeek, reportType: ReportTypeOrCustomReportType): boolean {
    return (
      (reportType.customReportTypeId && reportWeek.customReportTypeId && reportWeek.customReportTypeId === reportType.customReportTypeId) ||
      (!reportType.customReportTypeId && !reportWeek.customReportTypeId && reportWeek.typeId === reportType.reportTypeId)
    );
  }

  private toReportWithPreviews(
    report: Report,
    pdfPreviews: Array<PdfPreview>,
    participants: Array<Participant>,
    isViewer: boolean,
    isCurrentUserConnected: boolean,
    hasCurrentUserReportRights: boolean
  ): ReportWithPreviews {
    const hasPdfPreviews = this.hasReportPdfPreviews(report, pdfPreviews, participants, isViewer, isCurrentUserConnected, hasCurrentUserReportRights);
    return {
      ...report,
      hasPdfPreviews,
    };
  }

  private hasReportPdfPreviews(
    report: Report,
    pdfPreviews: Array<PdfPreview>,
    participants: Array<Participant>,
    isViewer: boolean,
    isCurrentUserConnected: boolean,
    hasCurrentUserReportRights: boolean
  ): boolean {
    let hasPdfPreviews: boolean;
    if ((isViewer && !hasCurrentUserReportRights) || isCurrentUserConnected) {
      const pdfPreviewIds = participants.map((participant) => participant.pdfpreviewId);
      hasPdfPreviews = pdfPreviews.some((pdfPreview) => pdfPreview.reportId === report.id && pdfPreviewIds.includes(pdfPreview.id));
    } else {
      hasPdfPreviews = pdfPreviews.some((pdfPreview) => pdfPreview.reportId === report.id);
    }
    return hasPdfPreviews;
  }

  getReportTypesWithHasReports(): Observable<ReportTypeWithHasReports[]> {
    return combineLatestAsync([
      this.reportTypes$,
      this.reportWeekDataService.data,
      this.reportDataService.data,
      this.participantDataService.data,
      this.pdfPreviewDataService.data,
      this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.VIEWER]),
      this.userService.isCurrentUserConnected$,
      this.userService.hasCurrentUserReportRights$,
    ]).pipe(
      debounceTime(0),
      map(([reportTypes, reportWeeks, reports, participants, pdfPreviews, isViewer, isCurrentUserConnected, hasCurrentUserReportRights]) =>
        reportTypes.map((reportType) => this.toReportTypeWithHasReports(reportType, reportWeeks, reports, participants, pdfPreviews, isViewer, isCurrentUserConnected, hasCurrentUserReportRights))
      )
    );
  }

  getReportWithPdfPreviews(reportTypeOrCustomTypeId: IdType): Observable<ReportWithPreviews[]> {
    return combineLatestAsync([
      this.reportDataService.data,
      this.reportWeekDataService.data.pipe(
        map((weeks) =>
          weeks
            .filter((week) => (week.customReportTypeId && week.customReportTypeId === reportTypeOrCustomTypeId) || (!week.customReportTypeId && week.typeId === reportTypeOrCustomTypeId))
            .map(({id}) => id)
        )
      ),
      this.pdfPreviewDataService.data,
      this.userService.currentUser$.pipe(switchMap((user) => this.participantDataService.getOwnParticipants(user.profileId))),
      this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.VIEWER]),
      this.userService.isCurrentUserConnected$,
      this.userService.hasCurrentUserReportRights$,
    ]).pipe(
      debounceTime(0),
      map(([reports, reportWeeks, pdfPreviews, participants, isViewer, isCurrentUserConnected, hasCurrentUserReportRights]) =>
        reports
          .filter((report) => reportWeeks.includes(report.reportWeekId))
          .map((report) => this.toReportWithPreviews(report, pdfPreviews, participants, isViewer, isCurrentUserConnected, hasCurrentUserReportRights))
      )
    );
  }

  getReportsByCustomType(customReportTypeOrId: CustomReportType | IdType): Observable<Report[]> {
    const customReportType = typeof customReportTypeOrId === 'string' ? customReportTypeOrId : customReportTypeOrId.id;

    return combineLatest([
      this.reportWeekDataService.data.pipe(map((weeks) => weeks.filter((week) => week.customReportTypeId === customReportType).map(({id}) => id))),
      this.reportDataService.data,
    ]).pipe(
      debounceTime(0),
      map(([reportWeeks, reports]) => reports.filter((report) => reportWeeks.includes(report.reportWeekId)))
    );
  }

  getReportsByTypeAndCustomType(reportTypeOrId: ReportType | IdType, customReportTypeOrId: CustomReportType | IdType): Observable<Report[]> {
    const reportTypeId = typeof reportTypeOrId === 'string' ? reportTypeOrId : reportTypeOrId.id;
    const customReportTypeId = typeof customReportTypeOrId === 'string' ? customReportTypeOrId : (customReportTypeOrId?.id ?? null);

    return combineLatest([
      this.reportWeekDataService.data.pipe(
        map((weeks) => weeks.filter((week) => week.typeId === reportTypeId && ((!week.customReportTypeId && !customReportTypeId) || week.customReportTypeId === customReportTypeId)).map(({id}) => id))
      ),
      this.reportDataService.data,
    ]).pipe(
      debounceTime(0),
      map(([reportWeeks, reports]) => reports.filter((report) => reportWeeks.includes(report.reportWeekId)))
    );
  }

  getLastSentPdfPreview(currentReport: Report): Observable<PdfPreview | null> {
    return combineLatest([this.reportWeekDataService.data, this.pdfPreviewDataService.data, this.reportTypeDataService.data, this.customReportTypeDataService.data]).pipe(
      debounceTime(0),
      switchMap(async ([reportWeeks, pdfPreviews, reportTypes, customReportTypes]) => {
        const currentReportWeek = reportWeeks.find((reportWeek) => reportWeek.id === currentReport.reportWeekId);
        if (!currentReportWeek) {
          throw new Error(`Reportweek with id ${currentReport.reportWeekId} does not exist`);
        }
        const currentReportType = reportTypes.find((reportType) => reportType.id === currentReportWeek.typeId);
        if (!currentReportType) {
          throw new Error(`ReportType with id ${currentReportWeek.typeId} does not exist`);
        }
        let currentCustomReportType;
        if (currentReportWeek.customReportTypeId) {
          currentCustomReportType = customReportTypes.find((customReportType) => customReportType.id === currentReportWeek.customReportTypeId);
        }
        const allReportsFromSameType = await observableToPromise(this.getReportsByTypeAndCustomType(currentReportType, currentCustomReportType));

        const sortedPdfPreviews: PdfPreview[] = _.sortBy(pdfPreviews, 'changedAt').reverse();
        for (const pdfPreview of sortedPdfPreviews) {
          if (allReportsFromSameType.some((report) => report.id === pdfPreview.reportId)) {
            return pdfPreview;
          }
        }
        return null;
      })
    );
  }

  getAuthors(includeProfileIds: IdType[] = []): Observable<Array<{id: IdType; name: string}>> {
    return combineLatestAsync([
      this.userProfileService.currentUserOwnProfile$,
      this.projectProfileDataService.getProjectProfilesWithMapping(includeProfileIds),
      this.addressDataService.dataGroupedById,
    ]).pipe(
      map(([ownProfile, projectProfiles, addressesById]) => {
        const profilesByOwnCompany = projectProfiles.filter(
          ({profile}) => (profile.companyId === ownProfile?.companyId && (profile.isActive === undefined || profile.isActive === true)) || includeProfileIds.includes(profile.id)
        );
        return profilesByOwnCompany.map(({profile, projectProfile}) => {
          const address = addressesById[profile.addressId];
          return {
            id: profile.id,
            name: this.userNameString.transform(address, profile.isActive, !projectProfile),
          };
        });
      })
    );
  }

  getDisabledAuthors(includeProfileIds: IdType[] = []): Observable<Array<{id: IdType; name: string}>> {
    return combineLatestAsync([this.projectProfileDataService.getProjectProfilesWithMapping(includeProfileIds), this.addressDataService.dataGroupedById]).pipe(
      map(([profiles, addressesById]) => {
        const profilesInactiveButAssigned = profiles.filter(({profile, projectProfile}) => includeProfileIds.includes(profile.id) && (profile.isActive === false || !projectProfile));
        return profilesInactiveButAssigned.map(({profile, projectProfile}) => {
          const address = addressesById[profile.addressId];
          return {
            id: profile.id,
            name: this.userNameString.transform(address, profile.isActive, !projectProfile),
          };
        });
      })
    );
  }

  async getReportsSortedByReportNumber(partialReportWeek: Pick<ReportWeek, 'typeId' | 'customReportTypeId'>): Promise<Report[]> {
    const reports = await observableToPromise(this.getReportsByTypeAndCustomType(partialReportWeek.typeId, partialReportWeek.customReportTypeId));
    const sortedReports: Array<Report> = _.sortBy(reports, ['reportNumber']);

    return sortedReports;
  }

  async getReportNumbersInUse(partialReportWeek: Pick<ReportWeek, 'typeId' | 'customReportTypeId'>): Promise<number[]> {
    return (await this.getReportsSortedByReportNumber(partialReportWeek)).map((report) => report.reportNumber);
  }

  async getNextReportNumber(partialReportWeek: Pick<ReportWeek, 'typeId' | 'customReportTypeId'>): Promise<number> {
    const sortedNumbers = await this.getReportNumbersInUse(partialReportWeek);
    return sortedNumbers.length === 0 ? 1 : sortedNumbers[sortedNumbers.length - 1] + 1;
  }

  async saveReport(reportTypeCode: ReportTypeCode, report: Report, form: UntypedFormGroup): Promise<void> {
    try {
      const reportType = await observableToPromise(this.reportTypeDataService.getByName(reportTypeCode));
      if (!reportType) {
        throw new Error('Report type with the name ' + reportTypeCode + ' does not exist');
      }
      const formValues = form.getRawValue();
      const momentDate = moment(formValues.date);
      let reportWeek = await observableToPromise(this.reportWeekDataService.getByYearWeekAndTypeId(momentDate.year(), momentDate.isoWeek(), reportType.id));
      if (!reportWeek) {
        reportWeek = {
          id: uuid4(),
          changedAt: new Date().toISOString(),
          year: momentDate.year(),
          calenderWeek: momentDate.isoWeek(),
          additionalDescription: null,
          createdAt: new Date().toISOString(),
          createdById: (await observableToPromise(this.userService.currentUser$)).id,
          projectId: (await this.projectDataService.getMandatoryCurrentProject()).id,
          typeId: reportType.id,
          customReportTypeId: null,
        } as ReportWeek;
        await this.reportWeekDataService.insert(reportWeek, reportWeek.projectId);
      }

      if (!report.id) {
        report.closedAt = null;
        report.additionalInfo = null;
        report.refusalNote = null;
        report.inReview = false;
        report.internalNotice = null;
        report.reportNumber = await this.getNextReportNumber(reportWeek);
        report.changedAt = new Date().toISOString();
      }
      if (!report.id) {
        report.id = formValues.id;
      }
      report.dependencyLink = formValues.dependencyLink || null;
      report.employerId = formValues.employerId || null;
      report.craftId = formValues.craftId || null;
      report.internalNumber = formValues.internalNumber || null;
      report.hasDamage = formValues.hasDamage ?? false;
      report.title = formValues.title;
      report.weather = formValues.weather;
      report.date = convertDateTimeToISOString(formValues.date);
      report.minTemp = formValues.temperatureMin;
      report.maxTemp = formValues.temperatureMax;
      report.humidity = formValues.humidity;
      report.windspeed = formValues.windspeed;
      report.weatherFromApi = formValues.weatherFromApi;
      report.workingTime1From = formValues.workingTime1FromTo?.length && formValues.workingTime1FromTo?.length >= 2 ? formValues.workingTime1FromTo[0] : null;
      report.workingTime1To = formValues.workingTime1FromTo?.length && formValues.workingTime1FromTo?.length >= 2 ? formValues.workingTime1FromTo[1] : null;
      report.workingTime2From = formValues.workingTime2FromTo?.length && formValues.workingTime2FromTo?.length >= 2 ? formValues.workingTime2FromTo[0] : null;
      report.workingTime2To = formValues.workingTime2FromTo?.length && formValues.workingTime2FromTo?.length >= 2 ? formValues.workingTime2FromTo[1] : null;
      report.badWeatherTime1From = formValues.badWeatherTime1FromTo?.length && formValues.badWeatherTime1FromTo?.length >= 2 ? formValues.badWeatherTime1FromTo[0] : null;
      report.badWeatherTime1To = formValues.badWeatherTime1FromTo?.length && formValues.badWeatherTime1FromTo?.length >= 2 ? formValues.badWeatherTime1FromTo[1] : null;
      report.badWeatherTime2From = formValues.badWeatherTime2FromTo?.length && formValues.badWeatherTime2FromTo?.length >= 2 ? formValues.badWeatherTime2FromTo[0] : null;
      report.badWeatherTime2To = formValues.badWeatherTime2FromTo?.length && formValues.badWeatherTime2FromTo?.length >= 2 ? formValues.badWeatherTime2FromTo[1] : null;
      report.editorId = formValues.author;
      report.reportWeekId = reportWeek.id;

      await this.reportDataService.insertOrUpdate(report, reportWeek.projectId);
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error inserting report. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - saveReport', error?.userMessage + '-' + error?.message);
      throw error;
    }
  }

  getReportCompanySources(report: Report): Observable<ReportCompanySource[]> {
    return combineLatestAsync([
      this.reportCompanyDataService.getByReport(report.id),
      this.contactService.sortedCompanies$,
      this.attachmentReportCompanyDataService.data,
      this.activityDataService.getByReport(report.id).pipe(map((activities) => activities.filter((activity) => activity.type === ActivityTypeEnum.ACTIVITY))),
      this.locationDataService.data,
      this.reportCompanyActivityDataService.data,
      this.projectDataService.currentProjectObservable,
    ]).pipe(
      map(([reportCompanies, companies, attachments, activities, locations, reportCompanyActivites, project]) => {
        const result = reportCompanies.map((reportCompany) => {
          const reportCompanyAttachments = attachments.filter(({reportCompanyId}) => reportCompanyId === reportCompany.id);

          const company = companies.find(({id}) => reportCompany.companyId === id);
          return {
            reportCompany,
            isCompanyRemoved: company ? !company.projectCompanies.some(({projectId}) => projectId === project.id) : false,
            company,
            reportCompanyAttachments,
            reportCompanyAttachments$: of(reportCompanyAttachments),
            activities: _.orderBy(
              activities
                .filter(({id}) => reportCompanyActivites.some((rca) => rca.reportCompanyId === reportCompany.id && rca.activityId === id))
                .map((activity) => ({
                  ...activity,
                  location: locations.find(({id}) => id === activity.locationId),
                })),
              ['position', 'changedAt']
            ),
          } as ReportCompanySource;
        });

        return result;
      })
    );
  }

  hasPermissionForReport(): Observable<boolean> {
    return this.userService.currentUser$.pipe(map((user) => user?.role === LicenseType.BASIC || user?.role === LicenseType.PROFESSIONAL || !!user?.assignedReportRights));
  }

  async deleteReportOnline(reportId: IdType): Promise<void> {
    await observableToPromise(this.http.post(removeReportUrl((await this.projectDataService.getMandatoryCurrentProject()).id, reportId), undefined));
  }

  isReportDeletable(reportId: IdType): Promise<boolean> {
    return observableToPromise(this.isReportDeletableStream(reportId).pipe(take(1)));
  }

  isReportDeletableStream(reportId: IdType): Observable<boolean> {
    return this.reportDataService.getById(reportId).pipe(
      map((report) => {
        if (!report) {
          return false;
        }
        if (report.closedAt !== null) {
          return false;
        }
        return true;
      })
    );
  }

  private async createAttachments<T extends Attachment>(attachmentBlobs: Array<AttachmentBlob>, otherData: Omit<T, keyof Attachment>): Promise<Array<T>> {
    const attachments = new Array<T>();
    for (const attachmentBlob of attachmentBlobs) {
      const attachmentT = {
        ...otherData,
        id: attachmentBlob.id || uuid4(),
        hash: attachmentBlob.hash || uuid4(),
        fileExt: attachmentBlob.fileExt,
        fileName: attachmentBlob.fileName,
        mimeType: attachmentBlob.mimeType,
        markings: attachmentBlob.markings,
        createdById: attachmentBlob.createdById,
        createdAt: attachmentBlob.createdAt,
        changedAt: attachmentBlob.changedAt,
        ...(await extractCommonAttachmentProperties(attachmentBlob.blob)),
      } as T;
      attachments.push(attachmentT);
    }
    return attachments;
  }

  private async addReportWeek(reportTypeOrCustomReportType: ReportTypeOrCustomReportType, momentDate: moment.Moment, currentProject: Project, currentUser: User): Promise<ReportWeek> {
    const reportWeek = {
      id: uuid4(),
      changedAt: new Date().toISOString(),
      year: momentDate.year(),
      calenderWeek: momentDate.isoWeek(),
      additionalDescription: null,
      createdAt: new Date().toISOString(),
      createdById: currentUser.id,
      projectId: currentProject.id,
      typeId: reportTypeOrCustomReportType.reportTypeId,
      customReportTypeId: reportTypeOrCustomReportType.customReportTypeId,
    } as ReportWeek;
    await this.reportWeekDataService.insert(reportWeek, currentProject.id);
    return reportWeek;
  }

  private async createReportObject(formValues: any, reportWeek: ReportWeek): Promise<Report> {
    return {
      id: formValues.id || uuid4(),
      title: formValues.title,
      closedAt: null,
      weather: formValues.weather,
      reportNumber: await this.getNextReportNumber(reportWeek),
      date: convertDateTimeToISOString(formValues.date),
      minTemp: formValues.temperatureMin,
      maxTemp: formValues.temperatureMax,
      humidity: formValues.humidity,
      windspeed: formValues.windspeed,
      weatherFromApi: formValues.weatherFromApi,
      hasDamage: formValues.hasDamage ?? false,
      additionalInfo: null,
      internalNumber: formValues.internalNumber || null,
      dependencyLink: formValues.dependencyLink || null,
      refusalNote: null,
      inReview: false,
      internalNotice: null,
      workingTime1From: formValues.workingTime1FromTo?.length && formValues.workingTime1FromTo?.length >= 2 ? formValues.workingTime1FromTo[0] : null,
      workingTime1To: formValues.workingTime1FromTo?.length && formValues.workingTime1FromTo?.length >= 2 ? formValues.workingTime1FromTo[1] : null,
      workingTime2From: formValues.workingTime2FromTo?.length && formValues.workingTime2FromTo?.length >= 2 ? formValues.workingTime2FromTo[0] : null,
      workingTime2To: formValues.workingTime2FromTo?.length && formValues.workingTime2FromTo?.length >= 2 ? formValues.workingTime2FromTo[1] : null,
      badWeatherTime1From: formValues.badWeatherTime1FromTo?.length && formValues.badWeatherTime1FromTo?.length >= 2 ? formValues.badWeatherTime1FromTo[0] : null,
      badWeatherTime1To: formValues.badWeatherTime1FromTo?.length && formValues.badWeatherTime1FromTo?.length >= 2 ? formValues.badWeatherTime1FromTo[1] : null,
      badWeatherTime2From: formValues.badWeatherTime2FromTo?.length && formValues.badWeatherTime2FromTo?.length >= 2 ? formValues.badWeatherTime2FromTo[0] : null,
      badWeatherTime2To: formValues.badWeatherTime2FromTo?.length && formValues.badWeatherTime2FromTo?.length >= 2 ? formValues.badWeatherTime2FromTo[1] : null,
      craftId: formValues.craftId || null,
      editorId: formValues.author,
      employerId: formValues.employerId || null,
      reportWeekId: reportWeek.id,
      changedAt: new Date().toISOString(),
      createdById: (await observableToPromise(this.userService.currentUser$)).id,
      createdAt: new Date().toISOString(),
    } as Report;
  }

  private copyReportObjects<T extends IdAware>(objects: T[], reportId: IdType): T[] {
    return objects.map((obj) => ({
      ...obj,
      id: uuid4(),
      reportId,
      changedAt: new Date().toISOString(),
    }));
  }

  private copyReportCompanies(reportId: IdType, reportCompanySources: ReportCompanySource[]): Array<ReportCompanySource> {
    const newReportCompanySources = new Array<ReportCompanySource>();
    for (const reportCompanySource of reportCompanySources) {
      reportCompanySource.reportCompany = {...reportCompanySource.reportCompany, ...{id: uuid4(), reportId, changedAt: new Date().toISOString()}};
      reportCompanySource.activities = this.copyReportObjects(reportCompanySource.activities, reportId);
      newReportCompanySources.push(reportCompanySource);
    }
    return newReportCompanySources;
  }

  async createReport(
    formValues: any,
    reportTypeOrCustomReportType: ReportTypeOrCustomReportType,
    children: {
      reportCompanySources: ReportCompanySource[];
      activitySources: ActivitySource[];
      materialSources: MaterialSource[];
      equipmentSources: EquipmentSource[];
      staffSources: Staff[];
      employeeSources: Employee[];
    },
    options: {
      isCopyFrom: boolean;
    }
  ) {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    const currentUser = await observableToPromise(this.userService.currentUser$);

    if (!currentUser) {
      throw new Error('Current user not set');
    }

    const momentDate = moment(formValues.date);

    let reportWeek = await observableToPromise(
      this.reportWeekDataService.getByYearWeekAndTypeIdAndCustomTypeId(
        momentDate.year(),
        momentDate.isoWeek(),
        reportTypeOrCustomReportType.reportTypeId,
        reportTypeOrCustomReportType.customReportTypeId
      )
    );
    if (!reportWeek) {
      reportWeek = await this.addReportWeek(reportTypeOrCustomReportType, momentDate, currentProject, currentUser);
    }
    const report = await this.createReportObject(formValues, reportWeek);

    const reportCompanySources = options.isCopyFrom ? this.copyReportCompanies(report.id, children.reportCompanySources) : children.reportCompanySources;
    if (options.isCopyFrom) {
      children.activitySources = this.copyReportObjects(children.activitySources, report.id);
      children.materialSources = this.copyReportObjects(children.materialSources, report.id);
      children.equipmentSources = this.copyReportObjects(children.equipmentSources, report.id);
      children.staffSources = this.copyReportObjects(children.staffSources, report.id);
      children.employeeSources = this.copyReportObjects(children.employeeSources, report.id);
    }

    const reportCompanyAttachments = await this.createReportCompanyAttachments(reportCompanySources, report, currentProject);
    const reportActivityAttachments = await this.createReportActivityAttachments(children.activitySources, report, currentProject);
    const reportMaterialAttachments = await this.createReportMaterialAttachments(children.materialSources, report, currentProject);
    const reportEquipmentAttachments = await this.createReportEquipmentAttachments(children.equipmentSources, report, currentProject);
    const reportCompanies: Array<ReportCompany> = _(reportCompanySources).map('reportCompany').flatten().value();
    const activities: Array<Activity> = _(reportCompanySources)
      .map('activities')
      .flatten()
      .value()
      .concat(children.activitySources)
      .map(({attachments, location, ...activity}: ActivitySource) => ({
        ...activity,
        reportId: report.id,
        id: activity.id ?? uuid4(),
      }));
    const materials: Material[] = children.materialSources.map(({attachments, ...material}) => ({
      ...material,
      reportId: report.id,
      id: material.id ?? uuid4(),
    }));
    const equipments: Equipment[] = children.equipmentSources.map(({attachments, ...equipment}) => ({
      ...equipment,
      reportId: report.id,
      id: equipment.id ?? uuid4(),
    }));
    const staffs: Staff[] = children.staffSources.map((staff) => ({
      ...staff,
      reportId: report.id,
      id: staff.id ?? uuid4(),
    }));
    const employees: Employee[] = children.employeeSources.map((employee) => ({
      ...employee,
      reportId: report.id,
      id: employee.id ?? uuid4(),
    }));

    const reportCompanyActivities: Array<ReportCompanyActivity> = _.flatten(
      children.reportCompanySources.map((reportCompanySource) =>
        reportCompanySource.activities.map((activity) => {
          return {
            id: uuid4(),
            changedAt: new Date().toISOString(),
            reportCompanyId: reportCompanySource.reportCompany.id,
            activityId: activity.id,
          } as ReportCompanyActivity;
        })
      )
    );
    await this.reportDataService.insert(report, currentProject.id);
    await this.reportCompanyDataService.insert(reportCompanies, currentProject.id);
    if (activities?.length) {
      await this.fillActivityPositions(activities);
    }
    await this.activityDataService.insert(activities, currentProject.id);
    await this.reportCompanyActivityDataService.insert(reportCompanyActivities, currentProject.id);
    await this.materialDataService.insert(materials, currentProject.id);
    await this.equipmentDataService.insert(equipments, currentProject.id);
    await this.staffDataService.insert(staffs, currentProject.id);
    await this.employeeDataService.insert(employees, currentProject.id);
    for (const newAttachment of reportCompanyAttachments) {
      await this.attachmentReportCompanyDataService.insert(newAttachment.attachments, currentProject.id, {}, newAttachment.blobs);
    }
    for (const newAttachment of reportActivityAttachments) {
      await this.attachmentReportActivityDataService.insert(newAttachment.attachments, currentProject.id, {}, newAttachment.blobs);
    }
    for (const newAttachment of reportMaterialAttachments) {
      await this.attachmentReportMaterialDataService.insert(newAttachment.attachments, currentProject.id, {}, newAttachment.blobs);
    }
    for (const newAttachment of reportEquipmentAttachments) {
      await this.attachmentReportEquipmentDataService.insert(newAttachment.attachments, currentProject.id, {}, newAttachment.blobs);
    }

    if (reportTypeOrCustomReportType.reportTypeCode === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT) {
      this.posthogService.captureEvent('[Reports] Construction report created', {usedWeatherApi: report.weatherFromApi ?? false});
    } else if (reportTypeOrCustomReportType.reportTypeCode === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT) {
      this.posthogService.captureEvent('[Reports] Directed report created', {usedWeatherApi: report.weatherFromApi ?? false});
    } else {
      this.posthogService.captureEvent('[Reports] Construction dairy created', {usedWeatherApi: report.weatherFromApi ?? false});
    }

    return report;
  }

  private async fillActivityPositions(activities: Activity[]) {
    const activitiesByType: Record<string, Activity[]> = _.groupBy(activities, 'type');
    for (const theActivities of Object.values(activitiesByType)) {
      let nextPosition = await observableToPromise(this.activityDataService.getNextPosition(theActivities[0].reportId, theActivities[0].type));
      theActivities.forEach((activity) => {
        if (activity.position === null || activity.position === undefined) {
          activity.position = nextPosition++;
        }
      });
    }
  }

  private async createReportAttachments<T extends WithAttachmentsSource, U extends Attachment>(
    sources: T[],
    sourceToPartial: (source: T) => Omit<U, keyof Attachment>
  ): Promise<AttachmentBlobBundle<U>[]> {
    const newAttachments = new Array<AttachmentBlobBundle<U>>();
    for (const source of sources) {
      if (source.attachments?.length > 0) {
        const attachmentBlobs = source.attachments.filter(isAttachmentBlob);
        const attachments = await this.createAttachments<U>(attachmentBlobs, sourceToPartial(source));
        const blobs = attachmentBlobs.map((attachment) => attachment.blob);
        newAttachments.push({attachments, blobs});
      }
    }

    return newAttachments;
  }

  private async createReportEquipmentAttachments(equipmentSources: EquipmentSource[], report: Report, currentProject: Project): Promise<AttachmentBlobBundle<AttachmentReportEquipment>[]> {
    return await this.createReportAttachments(equipmentSources, (source) => ({
      equipmentId: source.id,
      projectId: currentProject.id,
    }));
  }

  private async createReportMaterialAttachments(materialSources: MaterialSource[], report: Report, currentProject: Project): Promise<AttachmentBlobBundle<AttachmentReportMaterial>[]> {
    return await this.createReportAttachments(materialSources, (source) => ({
      materialId: source.id,
      projectId: currentProject.id,
    }));
  }

  private async createReportActivityAttachments(activitySources: ActivitySource[], report: Report, currentProject: Project): Promise<AttachmentBlobBundle<AttachmentReportActivity>[]> {
    return await this.createReportAttachments(activitySources, (source) => ({
      activityId: source.id,
      projectId: currentProject.id,
    }));
  }

  private async createReportCompanyAttachments(reportCompanySources: ReportCompanySource[], report: Report, currentProject: Project): Promise<AttachmentBlobBundle<AttachmentReportCompany>[]> {
    const newAttachments = new Array<AttachmentBlobBundle<AttachmentReportCompany>>();
    for (const reportCompanySource of reportCompanySources) {
      reportCompanySource.reportCompany.reportId = report.id;
      reportCompanySource.activities.forEach((activity) => (activity.reportId = report.id));
      if (reportCompanySource.reportCompanyAttachments.length > 0) {
        const attachments = await this.createAttachments<AttachmentReportCompany>(
          reportCompanySource.reportCompanyAttachments.filter((attachment): attachment is AttachmentBlob => isAttachmentBlob(attachment)),
          {
            reportCompanyId: reportCompanySource.reportCompany.id,
            projectId: currentProject.id,
          }
        );
        const blobs = reportCompanySource.reportCompanyAttachments.filter((attachment): attachment is AttachmentBlob => isAttachmentBlob(attachment)).map((attachment) => attachment.blob);
        newAttachments.push({attachments, blobs});
      }
    }

    return newAttachments;
  }

  private getReportAttachmentService(event: ReportAttachmentEventBase): AbstractProjectAwareAttachmentDataService<any> {
    if (event.activity) {
      return this.attachmentReportActivityDataService;
    }
    if (event.equipment) {
      return this.attachmentReportEquipmentDataService;
    }
    if (event.material) {
      return this.attachmentReportMaterialDataService;
    }
    if (event.reportCompany) {
      return this.attachmentReportCompanyDataService;
    }
    throw new Error(`Cannot determine attachment service for event with keys (${Object.keys(event).join(', ')})`);
  }

  async createReportAttachment(event: ReportAttachmentCreatedEvent) {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    if (event.activity) {
      return this.createReportAttachmentInternal(event.attachment, this.attachmentReportActivityDataService, {
        activityId: event.activity.id,
        projectId: currentProject.id,
      });
    }
    if (event.equipment) {
      return this.createReportAttachmentInternal(event.attachment, this.attachmentReportEquipmentDataService, {
        equipmentId: event.equipment.id,
        projectId: currentProject.id,
      });
    }
    if (event.material) {
      return this.createReportAttachmentInternal(event.attachment, this.attachmentReportMaterialDataService, {
        materialId: event.material.id,
        projectId: currentProject.id,
      });
    }
    if (event.reportCompany) {
      return this.createReportAttachmentInternal(event.attachment, this.attachmentReportCompanyDataService, {
        reportCompanyId: event.reportCompany.id,
        projectId: currentProject.id,
      });
    }
  }

  async updateReportAttachment(event: ReportAttachmentUpdatedEvent) {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    const attachmentService = this.getReportAttachmentService(event);

    await this.updateReportAttachmentInternal(event.attachment, attachmentService, currentProject.id);
  }

  async deleteReportAttachment(event: ReportAttachmentDeletedEvent) {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    const attachmentService = this.getReportAttachmentService(event);

    await this.deleteReportAttachmentsInternal([event.attachment], attachmentService, currentProject.id);
  }

  public getReportTypeCodesByReportsPageType(reportsPageType: ReportsPageTypeEnum): Array<ReportTypeCode> {
    switch (reportsPageType) {
      case ReportsPageTypeEnum.REPORTS:
        return [ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY];
      case ReportsPageTypeEnum.CONSTRUCTION_REPORTS:
        return [ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT, ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT];
      default:
        throw new Error(`Unsupported reportsPageType ${reportsPageType}`);
    }
  }

  private customReportTypeToReportTypeOrCustomReportType(customReportType: CustomReportType): ReportTypeOrCustomReportType {
    return {
      id: customReportType.id,
      reportTypeCode: customReportType.name,
      reportTypeId: customReportType.reportTypeId,
      customReportTypeId: customReportType.id,
      name: customReportType.name,
    } as ReportTypeOrCustomReportType;
  }

  private reportTypeToReportTypeOrCustomReportType(reportType: ReportType): ReportTypeOrCustomReportType {
    return {
      id: reportType.id,
      reportTypeCode: reportType.name,
      reportTypeId: reportType.id,
      name: this.translateService.instant(`reportTypeCode.${reportType.name}`),
    } as ReportTypeOrCustomReportType;
  }

  private customReportTypesByTypeCodes(reportTypeCodes: ReportTypeCode[]): Observable<CustomReportType[]> {
    return this.reportTypeDataService
      .getByReportTypeCode(reportTypeCodes)
      .pipe(switchMap((reportTypes) => this.customReportTypeDataService.dataByReportTypes(reportTypes)))
      .pipe(switchMap((customReportTypes) => this.projectCustomReportTypeDataService.filterCustomReportTypes(customReportTypes)));
  }

  public reportTypesByTypeCodes(reportTypeCodes: ReportTypeCode[]): Observable<Array<ReportTypeOrCustomReportType>> {
    return combineLatest([this.customReportTypesByTypeCodes(reportTypeCodes), this.reportTypeDataService.getByReportTypeCode(reportTypeCodes)])
      .pipe(debounceTime(0))
      .pipe(
        map(([customReportTypes, reportTypes]) =>
          _.orderBy(
            reportTypes.map((reportType) => this.reportTypeToReportTypeOrCustomReportType(reportType)),
            'name'
          ).concat(customReportTypes.map((customReportType) => this.customReportTypeToReportTypeOrCustomReportType(customReportType)))
        )
      );
  }

  private async saveDiffObjects<T extends IdAware>(
    objects: ReportDiffObjectsEvent<T>,
    dataService: AbstractProjectAwareDataService<T>,
    modifyCreatedFn?: (objects: T[]) => Promise<void>
  ): Promise<void> {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;

    if (objects.created?.length) {
      await modifyCreatedFn?.(objects.created);
    }

    await dataService.insertUpdateDelete(
      {
        inserts: objects.created,
        updates: objects.updated,
        deletes: [],
      },
      projectId
    );
  }

  async saveDiffActivities(activities: ReportDiffObjectsEvent<Activity>) {
    await this.saveDiffObjects(activities, this.activityDataService, async (created) => {
      await this.fillActivityPositions(created);
    });
  }

  async saveDiffMaterials(materials: ReportDiffObjectsEvent<Material>) {
    await this.saveDiffObjects(materials, this.materialDataService);
  }

  async saveDiffEquipments(equipments: ReportDiffObjectsEvent<Equipment>) {
    await this.saveDiffObjects(equipments, this.equipmentDataService);
  }

  async saveDiffStaffs(staffs: ReportDiffObjectsEvent<Staff>) {
    await this.saveDiffObjects(staffs, this.staffDataService);
  }

  async saveDiffEmployees(employees: ReportDiffObjectsEvent<Employee>) {
    await this.saveDiffObjects(employees, this.employeeDataService);
  }

  async deleteActivityWithAttachments(activity: ActivitySource) {
    return await this.deleteObjectWithAttachments(activity, this.activityDataService, this.attachmentReportActivityDataService);
  }

  async deleteMaterialWithAttachments(material: MaterialSource) {
    return await this.deleteObjectWithAttachments(material, this.materialDataService, this.attachmentReportMaterialDataService);
  }

  async deleteEquipmentWithAttachments(equipment: EquipmentSource) {
    return await this.deleteObjectWithAttachments(equipment, this.equipmentDataService, this.attachmentReportEquipmentDataService);
  }

  async deleteStaff(staff: Staff) {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    await this.staffDataService.delete(staff, projectId);
  }

  async deleteEmployee(employee: Employee) {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    await this.employeeDataService.delete(employee, projectId);
  }

  private async deleteObjectWithAttachments<T extends IdAwareWithAttachmentsSource, U extends Attachment>(
    obj: T,
    dataService: AbstractProjectAwareDataService<T>,
    attachmentService: AbstractProjectAwareAttachmentDataService<U>
  ) {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    await this.deleteReportAttachmentsInternal(obj.attachments ?? [], attachmentService, projectId);
    await dataService.delete(obj, projectId);
  }

  private async createReportAttachmentInternal<T extends Attachment>(
    attachment: AttachmentBlob,
    attachmentService: AbstractProjectAwareAttachmentDataService<T>,
    additional: Omit<T, keyof Attachment>
  ) {
    const [{attachments, blobs}] = await this.createReportAttachments([{attachments: [attachment]}], () => ({
      ...additional,
    }));
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    await attachmentService.insert(attachments, currentProject.id, {}, blobs);
  }

  private async updateReportAttachmentInternal<T extends Attachment>(
    attachment: ReportAttachmentUpdatedEvent['attachment'],
    attachmentService: AbstractProjectAwareAttachmentDataService<T>,
    projectId: IdType
  ) {
    if ('blob' in attachment) {
      if (attachment.id) {
        const attachmentUpdated = await observableToPromise(attachmentService.getById(attachment.id));
        if (attachmentUpdated) {
          attachmentUpdated.markings = attachment.markings;
          await attachmentService.update(attachmentUpdated, projectId);
        }
      }
    } else {
      await attachmentService.update(attachment as T, projectId);
    }
  }

  private async deleteReportAttachmentsInternal<T extends Attachment>(
    attachments: ReportAttachmentDeletedEvent['attachment'][],
    attachmentService: AbstractProjectAwareAttachmentDataService<T>,
    projectId: IdType
  ) {
    const theAttachments = await observableToPromise(attachmentService.getByIds(attachments.map(({id}) => id)));
    await attachmentService.delete(theAttachments, projectId);
  }

  getReportTypeOrCustomTypeById(reportTypeId: IdType): Observable<ReportTypeOrCustomReportType | undefined> {
    return this.reportTypes$.pipe(map((reportTypes) => reportTypes.find((reportType) => reportType.id === reportTypeId)));
  }

  private findReportByFieldInService<T extends Attachment, U extends IdAware & {reportId: IdType}>(
    field: string,
    attachment: T,
    dataService: AbstractProjectAwareDataService<U>
  ): Promise<Nullish<Report>> {
    return observableToPromise(
      dataService.data.pipe(
        map((data) => data.find((item) => item.id === attachment[field])),
        switchMap((item) => (item ? this.reportDataService.getById(item.reportId) : of(null)))
      )
    );
  }

  async getReportIdAndTypeByAttachment(attachment: Attachment): Promise<[IdType, ReportsPageTypeEnum] | [false]> {
    if (isAttachmentReportActivity(attachment)) {
      const report = await this.findReportByFieldInService('activityId', attachment, this.activityDataService);

      return [report?.id, ReportsPageTypeEnum.CONSTRUCTION_REPORTS];
    }

    if (isAttachmentReportCompany(attachment)) {
      const report = await this.findReportByFieldInService('reportCompanyId', attachment, this.reportCompanyDataService);

      return [report?.id, ReportsPageTypeEnum.REPORTS];
    }
    if (isAttachmentReportEquipment(attachment)) {
      const report = await this.findReportByFieldInService('equipmentId', attachment, this.equipmentDataService);

      return [report?.id, ReportsPageTypeEnum.CONSTRUCTION_REPORTS];
    }
    if (isAttachmentReportMaterial(attachment)) {
      const report = await this.findReportByFieldInService('materialId', attachment, this.materialDataService);

      return [report?.id, ReportsPageTypeEnum.CONSTRUCTION_REPORTS];
    }

    return [false];
  }
}
