import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {
  Attachment,
  AttachmentChat,
  AttachmentProject,
  AttachmentProjectImage,
  AttachmentProtocolEntry,
  AttachmentReportCompany,
  AttachmentTypeEnum,
  Client,
  ClientAttachmentTypeEnum,
  getAttachmentType,
  IdType,
  isTaskProtocol,
} from 'submodules/baumaster-v2-common';
import {combineLatest, Subject} from 'rxjs';
import {ClientDataService} from '../../../services/data/client-data.service';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {UserDataService} from '../../../services/data/user-data.service';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {AddressDataService} from '../../../services/data/address-data.service';
import _ from 'lodash';
import {ProtocolEntryService} from '../../../services/protocol/protocol-entry.service';
import {observableToPromise} from '../../../utils/async-utils';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ReportWeekDataService} from '../../../services/data/report-week-data.service';
import {ReportDataService} from '../../../services/data/report-data.service';
import {ReportCompanyDataService} from '../../../services/data/report-company-data.service';
import {CustomReportTypeDataService} from '../../../services/data/custom-report-type-data.service';
import {AttachmentService} from '../../../services/attachment/attachment.service';

type MissingReason = 'missingLocally' | 'missingServer' | 'missingBoth';

interface MissingAttachment {
  fileName: string;
  type: MissingAttachmentType;
  text?: string;
  author?: string;
  missingReason?: MissingReason;
}

type MissingAttachmentType = AttachmentTypeEnum | ClientAttachmentTypeEnum;

@Component({
  selector: 'app-missing-attachments',
  templateUrl: './missing-attachments.component.html',
  styleUrls: ['./missing-attachments.component.scss'],
})
export class MissingAttachmentsComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  public attachmentsOrigin: 'client' | 'server';
  private attachments: Array<Attachment>;
  public missingAttachments: Array<MissingAttachment> | undefined;
  private destroy$ = new Subject<void>();

  constructor(
    private navParams: NavParams,
    private clientDataService: ClientDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private userDataService: UserDataService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private protocolEntryService: ProtocolEntryService,
    private projectDataService: ProjectDataService,
    private reportWeekDataService: ReportWeekDataService,
    private reportDataService: ReportDataService,
    private reportCompanyDataService: ReportCompanyDataService,
    private customReportTypeDataService: CustomReportTypeDataService,
    private attachmentService: AttachmentService
  ) {}

  ngOnInit() {
    this.attachmentsOrigin = this.navParams.data.attachmentsOrigin;
    this.attachments = this.navParams.data.attachments;
    combineLatest([
      this.clientDataService.getOwnClient(),
      this.userDataService.dataGroupedById,
      this.profileDataService.dataAcrossClientsGroupedById,
      this.addressDataService.dataAcrossClientsGroupedById,
    ])
      .pipe(debounceTime(0))
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([client, users, profiles, addresses]) => {
        const missingAttachments = new Array<MissingAttachment>();
        for (const attachment of this.attachments) {
          let author: string | undefined;
          if (attachment.createdById) {
            const authorAddress = addresses[profiles[users[attachment.createdById]?.profileId]?.addressId];
            if (authorAddress) {
              author = `${authorAddress.firstName} ${authorAddress.lastName}`;
            }
          }
          const attachmentType = this.getMissingAttachmentType(attachment, client);
          const missingReason = await this.getMissingReason(attachment);
          const text = await this.getText(attachment, attachmentType);

          missingAttachments.push({
            fileName: attachment.fileName,
            author,
            type: attachmentType,
            missingReason,
            text,
          } as MissingAttachment);
        }
        this.missingAttachments = _.orderBy(missingAttachments, ['type', 'text', 'changedAt'], ['asc', 'asc', 'asc']);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async dismiss() {
    await this.modal.dismiss();
  }

  private getMissingAttachmentType(attachment: Attachment, client: Client | undefined): MissingAttachmentType {
    const attachmentType = getAttachmentType(attachment);
    if (attachmentType === AttachmentTypeEnum.AttachmentClient) {
      if (attachment.id === client?.pdfstartbannerId) {
        return ClientAttachmentTypeEnum.AttachmentClientPdfStartBanner;
      } else if (attachment.id === client?.pdfendbannerId) {
        return ClientAttachmentTypeEnum.AttachmentClientPdfEndBanner;
      } else if (attachment.id === client?.logoId) {
        return ClientAttachmentTypeEnum.AttachmentClientLogo;
      }
    }
    return attachmentType;
  }

  private async getMissingReason(attachment: Attachment): Promise<MissingReason | undefined> {
    if (this.attachmentsOrigin === 'client') {
      if (attachment.filePath) {
        return 'missingLocally';
      } else {
        return 'missingBoth';
      }
    } else if (this.attachmentsOrigin === 'server') {
      const attachmentType = getAttachmentType(attachment);
      const localAttachment = await observableToPromise(this.attachmentService.getAttachmentById(attachment.id, attachmentType));
      if (localAttachment && localAttachment.filePath) {
        return 'missingServer';
      } else {
        return 'missingBoth';
      }
    } else {
      throw new Error(`Unsupported attachmentsOrigin "${this.attachmentsOrigin}".`);
    }
  }

  private async getText(attachment: Attachment, attachmentType: MissingAttachmentType): Promise<string | undefined> {
    switch (attachmentType) {
      case 'AttachmentProtocolEntry':
      case 'AttachmentChat':
        return await this.getProtocolEntryText(attachment as AttachmentProtocolEntry | AttachmentChat);
      case 'AttachmentProject':
      case 'AttachmentProjectImage':
        return await this.getProjectName(attachment as AttachmentProject | AttachmentProjectImage);
      case 'AttachmentReportCompany':
        return await this.getReportNameByReportCompanyId((attachment as AttachmentReportCompany).reportCompanyId);
      default:
        return undefined;
    }
  }

  private async getProtocolEntryText(attachment: AttachmentProtocolEntry | AttachmentChat): Promise<string | undefined> {
    const protocolEntryId = attachment.protocolEntryId;
    const protocolEntry = await observableToPromise(this.protocolEntryDataService.getByIdAcrossProjects(protocolEntryId));
    if (!protocolEntry) {
      return undefined;
    }
    const protocol = await observableToPromise(this.protocolDataService.getByIdAcrossProjects(protocolEntry.protocolId));
    if (protocol) {
      if (isTaskProtocol(protocol)) {
        return protocolEntry.number.toString().padStart(3, '0');
      }
    }
    return await this.protocolEntryService.getShortIdAcrossProjects(protocolEntry);
  }

  private async getProjectName(attachment: AttachmentProject | AttachmentProjectImage): Promise<string | undefined> {
    const projectId = attachment.projectId;
    const project = await observableToPromise(this.projectDataService.getByIdAcrossClients(projectId));
    return project?.name || undefined;
  }

  private async getReportName(reportId: IdType | null | undefined): Promise<string | undefined> {
    if (!reportId) {
      return undefined;
    }
    const report = await observableToPromise(this.reportDataService.getByIdAcrossProjects(reportId));
    if (!report) {
      return undefined;
    }

    const reportWeek = await observableToPromise(this.reportWeekDataService.getByIdAcrossProjects(report.reportWeekId));
    if (!reportWeek) {
      return undefined;
    }
    if (reportWeek.customReportTypeId) {
      const customReportType = await observableToPromise(this.customReportTypeDataService.getByIdAcrossClients(reportWeek.customReportTypeId));
      if (customReportType?.name) {
        return customReportType?.name + ' ' + report.reportNumber;
      }
    }
    return 'BB ' + report.reportNumber;
  }

  private async getReportNameByReportCompanyId(reportCompanyId: IdType | null | undefined): Promise<string | undefined> {
    const reportCompany = await observableToPromise(this.reportCompanyDataService.getByIdAcrossProjects(reportCompanyId));
    if (!reportCompany) {
      return undefined;
    }
    return await this.getReportName(reportCompany.reportId);
  }
}
