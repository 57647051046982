import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {PdfPlansFilterService} from 'src/app/services/project-room/pdf-plans-filter.service';
import {PdfPlanFiltersComponent} from '../pdf-plan-filters.component';

@Component({
  selector: 'app-pdf-plan-filter-modal',
  templateUrl: './pdf-plan-filter-modal.component.html',
  styleUrls: ['./pdf-plan-filter-modal.component.scss'],
})
export class PdfPlanFilterModalComponent implements OnInit {
  private modal: HTMLIonModalElement;

  @HostBinding('class.omg-boundary')
  readonly omgUi = true;

  @ViewChild(PdfPlanFiltersComponent)
  private filtersComponent: PdfPlanFiltersComponent;

  constructor(private pdfPlansFilterService: PdfPlansFilterService) {}

  ngOnInit() {}

  clearAndClose() {
    this.pdfPlansFilterService.clear();
    this.close();
  }

  close() {
    this.modal.dismiss(undefined, 'cancel');
  }

  save() {
    this.modal.dismiss(this.filtersComponent.getValue(), 'save');
  }
}
