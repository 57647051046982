import {ColumnConfig} from '../ColumnConfig';
import {skipColumn} from './skipColumn';

const UniqueNameableDropdownItemsInProjectUniq = ['project_id', 'nameabledropdown_id'];

export const NameableDropdownItemColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true, skip: skipColumn},
  {name: 'project_id', prop: 'projectId', compositeKey: true, uniqueWith: [UniqueNameableDropdownItemsInProjectUniq]},
  {name: 'nameabledropdown_id', prop: 'nameabledropdownId', compositeKey: true, uniqueWith: [UniqueNameableDropdownItemsInProjectUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
