import {ColumnConfig} from '../ColumnConfig';

export const BimVersionAccessColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'bim_version_id', prop: 'bimVersionId', cnd: true},
  {name: 'user_id', prop: 'userId', cnd: true},
  {name: 'type', prop: 'type', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
