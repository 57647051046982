<ion-accordion-group class="flex-grow-1" [multiple]="true" [value]="['unit-profiles']">
  <ion-accordion toggleIconSlot="start" [value]="'unit-profiles'">
    <ion-item class="omg-modal-header" slot="header">
      {{ 'units_settings.unit_contacts.title' | translate }}
    </ion-item>
    <div slot="content">
      @for (unitContact of unitContacts; track unitContact.profile.id) {
      <div [class.mt-2]="0" class="d-flex flex-column gap-2 pb-4 bottom-border" [class.with-previous-owner-header]="!unitContact.unitProfile.isActive">
        @if (!unitContact.unitProfile.isActive) {
        <div class="previous-owner-header omg-t-body-bold pt-2 d-flex ion-align-items-center gap-2">
          <fa-icon [icon]="['fal', 'archive']"></fa-icon>
          {{ 'units_settings.unit_contacts.inactive_contact' | translate }}
        </div>
        } @else if (unitContact.unitProfile.isDefault) {
        <div class="omg-t-medium-body-bold text-grey pt-2">
          {{ 'units_settings.unit_contacts.main_contact' | translate }}
        </div>
        }
        <div class="d-flex ion-align-items-center ion-justify-content-between text-grey">
          <span class="wrap-text">{{unitContact.address.firstName}} {{unitContact.address.lastName}}</span>
          <ion-button class="omg-btn-text" (click)="contactMenuClick.emit({event: $event, unitContact})">
            <fa-icon slot="icon-only" [icon]="['far', 'ellipsis-h']"></fa-icon>
          </ion-button>
        </div>
        @if (unitContact.address.mobile || unitContact.address.phone) {
        <div class="mobile-email-row d-flex ion-align-items-center gap-3">
          <ion-button [href]="('tel:' + (unitContact.address.mobile || unitContact.address.phone))" class="omg-btn-secondary">
            <fa-icon slot="icon-only" [icon]="['fal', 'phone']"></fa-icon>
          </ion-button>
          <span class="flex-grow-1 wrap-text">{{ unitContact.address.mobile || unitContact.address.phone }}</span>
        </div>
        }
        @if (unitContact.address.email) {
        <div class="mobile-email-row d-flex ion-align-items-center gap-3">
          <ion-button [href]="('mailto:' + unitContact.address.email)" class="omg-btn-secondary">
            <fa-icon slot="icon-only" [icon]="['fal', 'envelope']"></fa-icon>
          </ion-button>
          <span class="flex-grow-1 wrap-text">{{ unitContact.address.email }}</span>
        </div>
        }
      </div>
      }

      <app-selectable
        #selectable
        [modalTitle]="'units_settings.unit_contacts.connect_contact' | translate"
        [recentlyUsedKey]="undefined"
        [items]="unselectedUnitContacts"
        [allItems]="unselectedUnitContacts"
        [disabledItems]="[]"
        itemTextField="name"
        itemValueField="id"
        [isEnabled]="true"
        [allowCreateNew]="true"
        [createNewFunction]="createNewContactFunction"
        [suppressAssignToProject]="true"
        (onChange)="newContactSelected($event)"
      >
      </app-selectable>
      @if (!readonly) {
      <ion-button class="omg-btn-secondary mt-4 full-width" (click)="selectable.ionicSelectable.open()">
        {{ 'units_settings.unit_contacts.connect_contact' | translate }}
      </ion-button>
      }
    </div>
  </ion-accordion>
</ion-accordion-group>
