import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';
import {switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/users/currentUsers';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends AbstractNonClientAwareDataService<User> {
  public readonly currentUser$: Observable<User | undefined> = this.authenticationService.authenticatedUserId$.pipe(
    switchMap((authenticatedUserId) => (authenticatedUserId ? this.getById(authenticatedUserId) : of(undefined)))
  );

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.USER, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService, integrityResolverService);
  }
}
