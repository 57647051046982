import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, of} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {ContactService} from 'src/app/services/contact/contact.service';
import _ from 'lodash';
import {PdfPlanVersionDataService} from 'src/app/services/data/pdf-plan-version-data.service';
import {Address, IdType, PdfPlanVersion, sanitizeRichTextInputForMailDisplay} from 'submodules/baumaster-v2-common/';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {PdfPlanVersionDistributionAccessDataService} from 'src/app/services/data/pdf-plan-version-distribution-access-data.service';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserDataService} from 'src/app/services/data/user-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {combineLatestAsync, switchMapOrDefault} from 'src/app/utils/async-utils';
import {getUserNameFromAddress} from 'src/app/utils/user-name-utils';
import {TranslateService} from '@ngx-translate/core';
import {LoggingService} from 'src/app/services/common/logging.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {comparePrimitiveArrays} from 'src/app/utils/compare-utils';

const LOG_SOURCE = 'PdfPlanHistoryEmailComponent';

interface Recipient {
  companyName: string;
  personName: string;
  email: string;
}

interface MailHistoryResponse {
  body: string;
  subject: string;
}

type SortType = 'companyName' | 'personName' | 'email';

@Component({
  selector: 'app-pdf-plan-history-email',
  templateUrl: './pdf-plan-history-email.component.html',
  styleUrls: ['./pdf-plan-history-email.component.scss'],
})
export class PdfPlanHistoryEmailComponent implements OnInit, OnDestroy {
  readonly senderEmailAddress = environment.senderEmailAddress;

  @Input()
  distributionId: IdType;

  mode: 'EMAIL' | 'RECIPIENT_LIST' | 'PLANS' = 'EMAIL';
  modal: HTMLIonModalElement;
  searchTextRecipient: string | null | undefined;
  collapsedRecipients = true;
  isNetworkConnected: boolean | undefined;
  addressCreator$: Observable<Address | undefined> | undefined;
  mailHistory$: Observable<{body: string | undefined; subject: string | undefined; error: false} | {body: undefined; subject: undefined; error: true}> | undefined;
  loading: boolean = false;
  sortType: SortType = 'companyName';
  sortMode: 'asc' | 'desc' = 'asc';

  private combineSubscription: Subscription | undefined;
  private recipients: Array<Recipient> = [];
  private planVersions$: Observable<Array<PdfPlanVersion>> | undefined;
  private profile$ = this.userProfileService.currentUserOwnProfile$;
  private company$ = this.companyDataService.dataForOwnClient$.pipe(
    switchMap((companies) => this.profile$.pipe(map((profile) => (profile ? companies?.find((company) => company.id === profile.companyId) : null))))
  );
  private searchTextPlanSubject = new BehaviorSubject('');
  searchTextPlan$ = this.searchTextPlanSubject.asObservable();
  get searchTextPlan() {
    return this.searchTextPlanSubject.value;
  }
  set searchTextPlan(searchTextPlan: string) {
    this.searchTextPlanSubject.next(searchTextPlan);
  }

  public filteredRecipients: Array<Recipient> = [];
  public filteredPlanVersions$: Observable<Array<PdfPlanVersion>> | undefined;

  constructor(
    private contactService: ContactService,
    private companyDataService: CompanyDataService,
    private userProfileService: UserProfileService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private pdfPlanVersionDistributionAccessDataService: PdfPlanVersionDistributionAccessDataService,
    private httpClient: HttpClient,
    private userDataService: UserDataService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private networkStatusService: NetworkStatusService,
    private translateService: TranslateService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    const versionDistAccesses$ = this.pdfPlanVersionDistributionAccessDataService.getAllByPlanVersionDistId$(this.distributionId).pipe(shareReplay({bufferSize: 1, refCount: true}));

    this.planVersions$ = versionDistAccesses$.pipe(
      map((versionDistAccesses) => _.uniq(versionDistAccesses.map((distAccess) => distAccess.pdfPlanVersionId))),
      distinctUntilChanged(comparePrimitiveArrays),
      switchMap((ids) => this.pdfPlanVersionDataService.findByVersionIds$(ids))
    );

    this.addressCreator$ = versionDistAccesses$.pipe(
      distinctUntilChanged((a, b) => a?.[0]?.createdById === b?.[0]?.createdById),
      switchMapOrDefault((versionDistAccesses) => this.userDataService.getById(versionDistAccesses?.[0]?.createdById)),
      switchMapOrDefault((user) => this.profileDataService.getById(user.profileId)),
      switchMapOrDefault((profile) => this.addressDataService.getById(profile.addressId))
    );

    this.mailHistory$ = versionDistAccesses$.pipe(
      distinctUntilChanged((a, b) => a?.[0]?.distributionId === b?.[0]?.distributionId),
      map((array) => array?.[0]?.distributionId),
      filter((distributionId) => !!distributionId),
      switchMap((distributionId) => {
        const params = new HttpParams().set('distributionId', distributionId);
        const mailHistoryReq$ = this.httpClient.get<MailHistoryResponse>(environment.serverUrl + 'api/data/mailHistory', {params}).pipe(
          tap(() => (this.loading = true)),
          shareReplay({
            refCount: true,
            bufferSize: 1,
          }),
          tap(() => (this.loading = false))
        );
        return mailHistoryReq$.pipe(
          map((v) => ({
            body: v?.body ? sanitizeRichTextInputForMailDisplay(v.body).replace(/(<a )(href="[^"]*")/, '$1id="download-btn-tooltip" onclick="return false" $2') : undefined,
            subject: v?.subject,
            error: false as const,
          })),
          catchError((error) => {
            this.loggingService.error(LOG_SOURCE, convertErrorToMessage(error));
            this.loading = false;
            return of({body: undefined, subject: undefined, error: true as const});
          })
        );
      })
    );

    this.filteredPlanVersions$ = this.planVersions$.pipe(
      switchMap((array) =>
        this.searchTextPlan$.pipe(map((text) => array.filter((version) => version.name.toLowerCase().includes(text.toLowerCase()) || version?.index.toLowerCase().includes(text.toLowerCase()))))
      )
    );

    this.combineSubscription = combineLatestAsync([this.contactService.getSortedCompaniesActiveOnly(), this.company$, versionDistAccesses$, this.networkStatusService.online$]).subscribe(
      async ([companies, userCompany, versionDistAccesses, connectionStatus]) => {
        companies = companies.map((company) => ({...company, isUserCompany: company.id === userCompany?.id}));
        this.isNetworkConnected = connectionStatus;
        const filteredCompanies = companies
          .filter((company) => !!company.projectCompany)
          .map((companySource) => ({
            ...companySource,
            employees: companySource.employees.filter((employee) => !!employee.projectProfile),
          }));
        this.recipients = [];
        for (const company of filteredCompanies) {
          for (const employee of company.employees) {
            if (versionDistAccesses.some((distAccess) => distAccess.profileId === employee.profile.id)) {
              this.recipients.push({
                companyName: company.name,
                personName: getUserNameFromAddress(this.translateService, employee, employee.profile?.isActive),
                email: employee?.email ?? '',
              });
            }
          }
        }

        this.filteredRecipients = [...this.recipients];
      }
    );
  }

  searchTextRecipientChanged() {
    if (_.isEmpty(this.searchTextRecipient)) {
      this.filteredRecipients = this.recipients;
      return;
    }
    this.filteredRecipients = this.recipients.filter((recipient) => this.searchableColumnsIncludeSearchText(recipient));
  }

  changeSortOrder(sortType: SortType) {
    if (this.sortType === sortType) {
      this.sortType = sortType;
      this.sortMode = this.sortMode === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortType = sortType;
      this.sortMode = 'asc';
    }
    this.filteredRecipients = _.orderBy(this.filteredRecipients, [sortType], [this.sortMode]);
  }

  private searchableColumnsIncludeSearchText(recipient: Recipient): boolean {
    return (
      recipient.companyName.toLowerCase().includes(this.searchTextRecipient.toLowerCase()) ||
      recipient.personName.toLowerCase().includes(this.searchTextRecipient.toLowerCase()) ||
      recipient.email.toLowerCase().includes(this.searchTextRecipient.toLowerCase())
    );
  }

  ngOnDestroy() {
    this.combineSubscription?.unsubscribe();
    this.combineSubscription = undefined;
  }

  async dismiss() {
    await this.modal.dismiss();
  }
}
