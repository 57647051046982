import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, ModalController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import {UnitContact} from 'src/app/services/units/unit-contacts.service';
import {SelectableInputModule} from 'src/app/shared/module/selectable-input/selectable-input.module';
import {CommonSelectableModule} from 'src/app/shared/module/selectable/selectable.module';
import {Profile, UnitProfile} from 'submodules/baumaster-v2-common';
import {UnitContactCreateComponent} from '../../contacts/unit-contact-create/unit-contact-create.component';

export interface ContactMenuClickEvent {
  event: MouseEvent;
  unitContact: UnitContactWithSimplifiedUnitProfile;
}

export type SimplifiedUnitProfile = Pick<UnitProfile, 'profileId' | 'isActive' | 'isDefault'>;

export interface UnitContactWithSimplifiedUnitProfile extends UnitContact {
  unitProfile: SimplifiedUnitProfile;
}

@Component({
  selector: 'app-unit-profiles-in-unit',
  templateUrl: './unit-profiles-in-unit.component.html',
  styleUrls: ['./unit-profiles-in-unit.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, FontAwesomeModule, TranslateModule, SelectableInputModule, CommonSelectableModule],
})
export class UnitProfilesInUnitComponent {
  @Input()
  unitContacts: UnitContactWithSimplifiedUnitProfile[];

  @Input()
  unselectedUnitContacts: UnitContact[];

  @Input()
  readonly = false;

  @Output()
  contactMenuClick = new EventEmitter<ContactMenuClickEvent>();

  @Output()
  contactAdded = new EventEmitter<UnitContact>();

  constructor(private modalController: ModalController) {}

  protected newContactSelected({component, value}: {component: IonicSelectableComponent; value: UnitContact | null}) {
    if (!value) {
      return;
    }
    component.clear();
    this.contactAdded.emit(value);
  }

  protected createNewContactFunction = async (text?: string): Promise<UnitContact | undefined> => {
    const modal = await this.modalController.create({
      component: UnitContactCreateComponent,
      cssClass: 'basic-modal',
      keyboardClose: false,
      backdropDismiss: false,
      componentProps: {
        hideUnitsSelector: true,
        prefillLastName: text,
      },
    });
    await modal.present();
    const {role, data} = await modal.onWillDismiss();

    if (role !== 'ok') {
      return undefined;
    }

    const profile: Profile = data;
    const unitContact = this.unselectedUnitContacts.find(({id}) => profile.id === id);
    return unitContact;
  };
}
