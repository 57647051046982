import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IdType, PdfPlanVersion} from 'submodules/baumaster-v2-common/';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';

export type LastUsedPlanVersionContext = 'tasks' | 'protocols';
@Injectable({
  providedIn: 'root',
})
export class LastUsedPlanVersionService implements OnDestroy {
  private rememberedPlanId: IdType | undefined;
  private rememberedContext: LastUsedPlanVersionContext | undefined;
  private context: LastUsedPlanVersionContext | undefined;
  private showAllMarker = false;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private pdfPlanVersionDataService: PdfPlanVersionDataService
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url;
        let contextSet = false;
        if (currentUrl.includes('tasks')) {
          this.context = 'tasks';
          contextSet = true;
        } else if (currentUrl.includes('protocols')) {
          this.context = 'protocols';
          contextSet = true;
        }
        if (!contextSet) {
          this.clearRememberedData();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setRememberedPlanVersion(planId: IdType | undefined, context: LastUsedPlanVersionContext | undefined) {
    this.rememberedPlanId = planId;
    this.rememberedContext = context ?? this.context;
  }

  setRememberedShowAllMarkers(showAllMarkers: boolean) {
    this.showAllMarker = showAllMarkers;
  }

  async getRememberedPlanVersion(): Promise<PdfPlanVersion | undefined> {
    if (!this.rememberedPlanId || !this.rememberedContext) {
      return undefined;
    }
    const pdfPlanVersion = await observableToPromise(this.pdfPlanVersionDataService.getLatestByPdfPlan$(this.rememberedPlanId));
    if (!pdfPlanVersion) {
      // PdfPlanVersion is not valid anymore (project changed or plan was deleted)
      this.clearRememberedData();
      return undefined;
    }
    return pdfPlanVersion;
  }

  getRememberedShowAllMarkers() {
    return this.showAllMarker;
  }

  private clearRememberedData() {
    this.setRememberedPlanVersion(undefined, undefined);
    this.setRememberedShowAllMarkers(false);
  }
}
