import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SyncStatusService} from '../../../services/sync/sync-status.service';
import {SyncService} from '../../../services/sync/sync.service';
import {SyncStrategy} from '../../../services/sync/sync-utils';
import {AsyncPipe, NgIf} from '@angular/common';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {Subject, takeUntil, takeWhile} from 'rxjs';

@Component({
  selector: 'app-notification-sync-modal',
  templateUrl: './notification-sync-modal.component.html',
  styleUrls: ['./notification-sync-modal.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule, IonicModule, NgIf, AsyncPipe, UiModule],
})
export class NotificationSyncModalComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  private destroy$ = new Subject<void>();
  hasInternet: boolean;
  dataSyncInProgress$ = this.syncStatusService.dataSyncInProgressObservable;

  @Input()
  projectId: string;

  @Output()
  logout = new EventEmitter<void>();

  constructor(
    private syncStatusService: SyncStatusService,
    private syncService: SyncService,
    private networkStatusService: NetworkStatusService
  ) {}

  async ngOnInit() {
    await this.networkStatusService.pingServer();
    this.networkStatusService.online$
      .pipe(
        takeUntil(this.destroy$),
        takeWhile((isOnline) => !isOnline, true)
      )
      .subscribe(async (isOnline) => {
        this.hasInternet = isOnline;
        if (this.hasInternet) {
          await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, {additionalProjectIdsToSync: [this.projectId]});
          this.modal.dismiss({}, 'success');
        }
      });
  }

  async retryConnection() {
    await this.networkStatusService.isOnline();
  }

  dismissModal() {
    this.modal.dismiss({}, 'cancel');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
