import {ColumnConfig} from '../ColumnConfig';
import {PdfPlanMarkerProtocolEntryColumnSet} from './PdfPlanMarkerProtocolEntryColumnSet';
import {PdfPlanPageMarkingColumnSet} from './PdfPlanPageMarkingColumnSet';

export const PdfPlanPageColumnSet: Array<ColumnConfig> = [
  {name: 'attachment_ptr_id', prop: 'id', cnd: true},
  {name: 'pagenumber', prop: 'pageNumber'},
  {name: 'pdfplan_version_id', prop: 'pdfPlanVersionId'},
  {name: 'pdfPlanMarkerProtocolEntries', fk: 'pdfPlanPageId', mappedBy: PdfPlanMarkerProtocolEntryColumnSet, transient: true},
  {name: 'pdfPlanPageMarkings', fk: 'pdfPlanPageId', mappedBy: PdfPlanPageMarkingColumnSet, transient: true},
];
