import {AsyncPipe, NgIf} from '@angular/common';
import {Component} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContactService} from 'src/app/services/contact/contact.service';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {ProjectCompany} from 'submodules/baumaster-v2-common';
import {CompanyOrderComponent} from '../company-order/company-order.component';
import _ from 'lodash';
import {LoadingService} from 'src/app/services/common/loading.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {OmgToastService} from 'src/app/services/ui/omg-toast.service';
import {LoggingService} from 'src/app/services/common/logging.service';
import {TranslateModule} from '@ngx-translate/core';
import {UiModule} from 'src/app/shared/module/ui/ui.module';

const LOG_SOURCE = 'ManageCompanyOrderComponent';

@Component({
  selector: 'app-manage-company-order',
  templateUrl: './manage-company-order.component.html',
  styleUrls: ['./manage-company-order.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgIf, IonicModule, CompanyOrderComponent, TranslateModule, UiModule],
})
export class ManageCompanyOrderComponent {
  protected pendingProjectCompaniesSubject = new BehaviorSubject<ProjectCompany[] | undefined>(undefined);

  private modal: HTMLIonModalElement;

  companiesWithProject$ = combineLatestAsync([this.contactService.sortedCompanies$, this.projectCompanyDataService.data, this.pendingProjectCompaniesSubject]).pipe(
    map(([companies, projectCompanies, pendingProjectCompanies]) => {
      const combinedProjectCompanies = pendingProjectCompanies?.length ? _.unionBy(pendingProjectCompanies, projectCompanies, 'id') : projectCompanies;
      const projectCompanyByCompanyId = _.keyBy(combinedProjectCompanies, 'companyId');
      return {
        companies: companies.filter((company) => projectCompanyByCompanyId[company.id] && company.isActive !== false),
        projectCompanyByCompanyId,
      };
    })
  );

  constructor(
    private contactService: ContactService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private loadingService: LoadingService,
    private systemEventService: SystemEventService,
    private toastService: OmgToastService,
    private loggingService: LoggingService
  ) {}

  orderChanged(projectCompanies: ProjectCompany[]) {
    this.pendingProjectCompaniesSubject.next(projectCompanies);
  }

  async save() {
    await this.loadingService.withLoading(async () => {
      const {value: projectCompaniesToUpdate} = this.pendingProjectCompaniesSubject;

      if (!projectCompaniesToUpdate?.length) {
        this.modal.dismiss(undefined, 'saved');
        return;
      }

      const projectIds = _.uniq(projectCompaniesToUpdate.map(({projectId}) => projectId));

      if (projectIds.length > 1) {
        throw new Error('Assertion failed: more than one project is in the project companies array');
      }

      try {
        await this.projectCompanyDataService.update(projectCompaniesToUpdate, projectIds[0]);
        this.modal.dismiss(undefined, 'saved');
        this.pendingProjectCompaniesSubject.next(undefined);
      } catch (e) {
        this.systemEventService.logErrorEvent(LOG_SOURCE, `Failed to update project companies for project ${projectIds[0]}; reason: ${convertErrorToMessage(e)}`);
        this.loggingService.error(LOG_SOURCE, `Failed to update project companies for project ${projectIds[0]}; reason: ${convertErrorToMessage(e)}`);
        this.toastService.savingError();
        throw e;
      }
    });
  }

  cancel() {
    this.modal.dismiss(undefined, 'cancel');
  }
}
