import {NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-no-projects-placeholder',
  templateUrl: './no-projects-placeholder.component.html',
  styleUrls: ['./no-projects-placeholder.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, NgIf, TranslateModule, FontAwesomeModule],
})
export class NoProjectsPlaceholderComponent implements OnInit {
  @Input()
  isClientAdmin = false;

  constructor() {}

  ngOnInit() {}
}
