import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
  IdType,
  LicenseType,
  MIME_TYPE_PDF,
  MIME_TYPES_CAD,
  PdfPlan,
  PdfPlanAttachment,
  PdfPlanFolder,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  PdfPlanVersionQualityEnum,
  TagBase,
  TagClient,
} from 'submodules/baumaster-v2-common';
import {TranslateService} from '@ngx-translate/core';
import {BlobFilenameBundle, MIME_TYPES_PDF_PLAN} from '../../../utils/attachment-utils';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {Observable, of, Subject} from 'rxjs';
import {PdfPlanFolderDataService} from '../../../services/data/pdf-plan-folder-data.service';
import {observableToPromise} from '../../../utils/async-utils';
import {PdfPlanVersionDataService} from '../../../services/data/pdf-plan-version-data.service';
import {PdfPlanPageDataService} from '../../../services/data/pdf-plan-page-data.service';
import {PdfPlanDataService} from '../../../services/data/pdf-plan-data.service';
import {ToastService} from '../../../services/common/toast.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {PdfPlanAttachmentDataService} from '../../../services/data/pdf-plan-attachment-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {AbortError, convertErrorToMessage} from '../../../shared/errors';
import {LoggingService} from '../../../services/common/logging.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../../../services/data/pdf-plan-marker-protocol-entry-data.service';
import {PdfPlanPageMarkingDataService} from '../../../services/data/pdf-plan-page-marking-data.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import _ from 'lodash';
import {TagService} from '../../../services/tags/tag.service';
import {PdfPlanMarkerMigrationModalComponent} from '../pdf-plan-marker-migration-modal/pdf-plan-marker-migration-modal.component';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {AlertService} from '../../../services/ui/alert.service';

const LOG_SOURCE = 'UploadPdfPlanVersionWorkflowComponent';

interface UploadPdfPlanVersionWorkflowStep {
  key: 'FILE_SELECT' | 'DETAILS' | 'MARKER' | 'SUMMARY';
  titleTranslationKey: string;
}

const WORKFLOW_STEPS: UploadPdfPlanVersionWorkflowStep[] = [
  {key: 'FILE_SELECT', titleTranslationKey: 'project_room.upload_pdf_plan_version.step.FILE_SELECT.title'},
  {key: 'DETAILS', titleTranslationKey: 'project_room.upload_pdf_plan_version.step.DETAILS.title'},
  {key: 'MARKER', titleTranslationKey: 'project_room.upload_pdf_plan_version.step.MARKER.title'},
  {key: 'SUMMARY', titleTranslationKey: 'project_room.upload_pdf_plan_version.step.SUMMARY.title'},
];

@Component({
  selector: 'app-upload-pdf-plan-version-workflow',
  templateUrl: './upload-pdf-plan-version-workflow.component.html',
  styleUrls: ['./upload-pdf-plan-version-workflow.component.scss'],
})
export class UploadPdfPlanVersionWorkflowComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  workflowSteps = WORKFLOW_STEPS;
  readonly pdfPlanVersionQualities = _.orderBy(Object.values(PdfPlanVersionQualityEnum).filter(_.isNumber));
  acceptedMimeTypes = MIME_TYPES_PDF_PLAN;
  cadFilesEnabled$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.PROFESSIONAL]);
  pdfPlanVersionQuality = PdfPlanVersionQualityEnum.DEFAULT;
  private modal: HTMLIonModalElement;
  @Input()
  pdfPlanFolderId: IdType;
  @Input()
  pdfPlanId: IdType | undefined;
  currentStepIndex = 0;
  currentStep: UploadPdfPlanVersionWorkflowStep = this.workflowSteps[0];
  processingUploadedFile = false;
  files = new Array<File>();
  pdfPlanFolder$: Observable<PdfPlanFolder>;
  latestPdfPlanVersion$: Observable<PdfPlanVersion | undefined>;
  latestPdfPlanVersionPages$: Observable<Array<PdfPlanPage> | undefined>;
  tags$: Observable<Array<TagClient> | undefined>;
  latestPdfPlanVersionAfterFileUpload: PdfPlanVersion | undefined;
  tagsAfterFileUpload: Array<TagClient> | undefined;
  processing = false;
  private processFileAbortController: AbortController | undefined;
  printWarningReducedQualityUsed: boolean | undefined;

  pdfPlan: PdfPlan | undefined;
  pdfPlanAttachment: PdfPlanAttachment | undefined;
  pdfPlanCadAttachments: Array<PdfPlanAttachment> | undefined;
  pdfPlanVersion: PdfPlanVersion | undefined;
  pdfPlanPages: Array<PdfPlanPage> | undefined;
  pdfBlob: Blob | undefined;
  imageBlobs: Array<Blob> | undefined;
  cadBlobs: Blob[] | undefined;
  tags: Array<TagBase> | undefined;
  private pdfPlanVersionValid: boolean | undefined;
  pdfPlanPageMarkings: Array<PdfPlanPageMarking> | undefined;
  pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry> | undefined;
  isNetworkConnected: boolean | undefined;
  latestPdfPlanPageMarkings$: Observable<Array<PdfPlanPageMarking>>;
  latestPdfPlanMarkerProtocolEntries$: Observable<Array<PdfPlanMarkerProtocolEntry>>;

  migratePdfPlanPageMarkingGeneral: boolean | undefined = true;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private pdfPlanService: PdfPlanService,
    private toastService: ToastService,
    private pdfPlanFolderDataService: PdfPlanFolderDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private pdfPlanDataService: PdfPlanDataService,
    private projectDataService: ProjectDataService,
    private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService,
    private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private networkStatusService: NetworkStatusService,
    private alertService: AlertService,
    private tagService: TagService,
    private featureEnabledService: FeatureEnabledService
  ) {}

  ngOnInit() {
    this.currentStepIndex = 0;
    this.currentStep = this.workflowSteps[0];
    this.pdfPlanFolder$ = this.pdfPlanFolderDataService.getById(this.pdfPlanFolderId);
    this.latestPdfPlanVersion$ = this.pdfPlanId ? this.pdfPlanVersionDataService.getLatestByPdfPlan$(this.pdfPlanId) : of(undefined);
    this.latestPdfPlanVersion$.pipe(takeUntil(this.destroy$)).subscribe((pdfPlanVersion) => {
      if (!this.files.length && pdfPlanVersion.quality) {
        this.pdfPlanVersionQuality = pdfPlanVersion.quality;
      }
    });
    this.latestPdfPlanVersionPages$ = this.pdfPlanId ? this.pdfPlanService.latestPdfPlanVersionPages$(this.pdfPlanId) : of(undefined);
    this.tags$ = this.latestPdfPlanVersion$.pipe(switchMap((pdfPlanVersion) => (pdfPlanVersion ? this.tagService.getTagsForObject$(pdfPlanVersion.id, 'pdfPlanVersions') : of(undefined))));
    this.latestPdfPlanPageMarkings$ = this.latestPdfPlanVersionPages$.pipe(switchMap((pdfPlanVersionPages) => this.pdfPlanPageMarkingDataService.getByPlanPages(pdfPlanVersionPages)));
    this.latestPdfPlanMarkerProtocolEntries$ = this.latestPdfPlanVersionPages$.pipe(switchMap((pdfPlanVersionPages) => this.pdfPlanMarkerProtocolEntryDataService.getByPlanPages(pdfPlanVersionPages)));
    this.networkStatusService.onlineOrUnknown$.pipe(takeUntil(this.destroy$)).subscribe((connectionStatus) => (this.isNetworkConnected = connectionStatus));
    this.cadFilesEnabled$.pipe(takeUntil(this.destroy$)).subscribe((cadFilesEnabled) => {
      this.acceptedMimeTypes = cadFilesEnabled ? MIME_TYPES_PDF_PLAN.concat(MIME_TYPES_CAD) : MIME_TYPES_PDF_PLAN;
    });
    this.initWorkflowSteps();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async dismissModal(role?: string) {
    await this.modal.dismiss(undefined, role);
  }

  private async initWorkflowSteps() {
    const workflowSteps = new Array<UploadPdfPlanVersionWorkflowStep>();
    for (const workflowStep of WORKFLOW_STEPS) {
      if (await this.showStep(workflowStep)) {
        workflowSteps.push(workflowStep);
      }
    }
    this.workflowSteps = workflowSteps;
  }

  async back() {
    for (let nextStepIndex = this.currentStepIndex - 1; nextStepIndex >= 0; nextStepIndex--) {
      const nextStep = this.workflowSteps[nextStepIndex];
      if (await this.showStep(nextStep)) {
        this.currentStepIndex = nextStepIndex;
        this.currentStep = nextStep;

        await this.preProcessStep(this.currentStep);
        break;
      }
    }
  }

  async next() {
    await this.initWorkflowSteps();
    if (!(await this.postProcessStep(this.currentStep))) {
      return;
    }
    await this.initWorkflowSteps();
    for (let nextStepIndex = this.currentStepIndex + 1; nextStepIndex < this.workflowSteps.length; nextStepIndex++) {
      const nextStep = this.workflowSteps[nextStepIndex];
      if (await this.showStep(nextStep)) {
        this.currentStepIndex = nextStepIndex;
        this.currentStep = nextStep;

        await this.preProcessStep(this.currentStep);
        break;
      }
    }
  }

  async finish() {
    try {
      this.processing = true;
      await this.pdfPlanService.finishUploadingPdfPlanVersion(
        this.latestPdfPlanVersion$,
        this.latestPdfPlanVersionAfterFileUpload,
        this.pdfPlanId,
        this.pdfPlan,
        this.pdfPlanAttachment,
        this.pdfPlanCadAttachments,
        this.pdfPlanVersion,
        this.pdfPlanPages,
        this.pdfBlob,
        this.imageBlobs,
        this.cadBlobs,
        this.pdfPlanPageMarkings,
        this.pdfPlanMarkerProtocolEntries,
        this.tagsAfterFileUpload,
        this.tags
      );
      await this.dismissModal('finished');
    } catch (error) {
      const errorMessage = `finish - inserting files failed with error ${convertErrorToMessage(error)}`;
      this.systemEventService.logErrorEvent(LOG_SOURCE, errorMessage);
      this.loggingService.error(LOG_SOURCE, errorMessage);
      this.toastService.error(this.translateService.instant('project_room.upload_pdf_plan_version.step.SUMMARY.errorProcessingFile', {errorMessage: convertErrorToMessage(error)}));
    } finally {
      this.processing = false;
    }
  }

  public filesChange(files: Array<File>) {
    this.files = this.validateFiles(files).files;
    this.handleFilesChange();
  }

  private validateFiles(files: Array<File>): {valid: boolean; files: Array<File>} {
    const filesToRemove = new Array<File>();

    let errorMessageKey: string | undefined;
    let pdfCount = 0;
    for (const file of files) {
      const isPdfFile = file.type === MIME_TYPE_PDF;
      const isCadFile = MIME_TYPES_CAD.includes(file.type);
      const isOtherFile = !isPdfFile && !isCadFile;
      if (isOtherFile) {
        errorMessageKey = 'project_room.upload_pdf_plan_version.step.FILE_SELECT.invalidFilesRemoved';
        filesToRemove.push(file);
      } else if (isPdfFile) {
        if (pdfCount > 0) {
          errorMessageKey = 'project_room.upload_pdf_plan_version.step.FILE_SELECT.secondPdfRemoved';
          filesToRemove.push(file);
        } else {
          pdfCount++;
        }
      }
    }

    if (!filesToRemove.length) {
      return {valid: true, files};
    }
    const fileNamesToRemove = filesToRemove.map((file) => file.name).join(',');
    this.toastService.error(this.translateService.instant(errorMessageKey, {fileNames: fileNamesToRemove}));
    return {valid: false, files: files.filter((file) => !filesToRemove.includes(file))};
  }

  private async handleFilesChange() {
    const pdfFiles = this.files.filter((file) => file.type === MIME_TYPE_PDF);
    const cadFiles = this.files.filter((file) => file.type !== MIME_TYPE_PDF);
    const cadFilesBundle: Array<BlobFilenameBundle> = cadFiles.map((file) => {
      return {blob: file, fileName: file.name};
    });
    if (pdfFiles.length) {
      try {
        this.processingUploadedFile = true;
        this.processing = true;
        this.printWarningReducedQualityUsed = undefined;
        this.processFileAbortController = new AbortController();

        const file = pdfFiles[0];

        const qualities = this.pdfPlanVersionQualities.reverse().filter((q) => q <= this.pdfPlanVersionQuality);
        const lowestQuality = qualities[qualities.length - 1];
        for (const pdfPlanVersionQuality of qualities) {
          try {
            if (this.processFileAbortController?.signal?.aborted) {
              throw new AbortError();
            }
            await this.processPdfFile(file, cadFilesBundle, pdfPlanVersionQuality);
            if (pdfPlanVersionQuality !== this.pdfPlanVersionQuality) {
              this.printWarningReducedQualityUsed = true;
              this.pdfPlanVersionQuality = pdfPlanVersionQuality;
            } else {
              this.printWarningReducedQualityUsed = false;
            }
            break;
          } catch (error) {
            if (pdfPlanVersionQuality === lowestQuality) {
              throw error;
            }
            if (this.processFileAbortController?.signal?.aborted) {
              throw new AbortError();
            }
          }
        }
      } catch (error) {
        const errorMessage = `Processing file(s) failed with error ${convertErrorToMessage(error)}`;
        this.systemEventService.logErrorEvent(LOG_SOURCE, errorMessage);
        this.loggingService.error(LOG_SOURCE, errorMessage);
        this.toastService.error(this.translateService.instant('project_room.upload_pdf_plan_version.step.FILE_SELECT.errorProcessingFile', {errorMessage: convertErrorToMessage(error)}));
        this.files = cadFiles;
      } finally {
        this.processingUploadedFile = false;
        this.processing = false;
        this.processFileAbortController = undefined;
      }
    } else {
      this.clearUploadedPdfPlanObjects();
    }
  }

  private async processPdfFile(file: File, cadFilesBundle: Array<BlobFilenameBundle>, pdfPlanVersionQuality: PdfPlanVersionQualityEnum): Promise<{pdfPlanVersionQuality: PdfPlanVersionQualityEnum}> {
    const pdfPlanFolder = await observableToPromise(this.pdfPlanFolder$);
    const latestPdfPlanVersionPages = await observableToPromise(this.latestPdfPlanVersionPages$);
    const pdfPlanFromId = this.pdfPlanId ? await observableToPromise(this.pdfPlanDataService.getById(this.pdfPlanId)) : undefined;
    this.latestPdfPlanVersionAfterFileUpload = _.cloneDeep(await observableToPromise(this.latestPdfPlanVersion$));
    this.tagsAfterFileUpload = _.cloneDeep(await observableToPromise(this.tags$));
    const {pdfPlanAttachment, pdfPlanCadAttachments, pdfPlan, pdfPlanVersion, pdfPlanPages, pdfBlob, imageBlobs, cadBlobs, tags} = await this.pdfPlanService.upload(
      pdfPlanFolder,
      {blob: file, fileName: file.name},
      cadFilesBundle,
      new Date(),
      pdfPlanFromId,
      false,
      pdfPlanVersionQuality,
      this.processFileAbortController?.signal
    );
    if (this.pdfPlanId && latestPdfPlanVersionPages !== undefined && pdfPlanPages.length !== latestPdfPlanVersionPages.length) {
      await this.alertService.ok({
        header: this.translateService.instant('project_room.upload_pdf_plan_version.step.FILE_SELECT.errorPagesDoNotMatchTitle'),
        message: this.translateService.instant('project_room.upload_pdf_plan_version.step.FILE_SELECT.errorPagesDoNotMatchText', {
          newPages: pdfPlanPages.length,
          oldPages: latestPdfPlanVersionPages.length,
        }),
        confirmLabel: 'understood',
      });
      this.clearUploadedPdfPlanObjects();
      this.files = [];
      return;
    }
    this.pdfPlan = pdfPlan;
    this.pdfPlanAttachment = pdfPlanAttachment;
    this.pdfPlanCadAttachments = pdfPlanCadAttachments;
    this.pdfPlanVersion = pdfPlanVersion;
    this.pdfPlanPages = pdfPlanPages;
    this.pdfBlob = pdfBlob;
    this.imageBlobs = imageBlobs;
    this.cadBlobs = cadBlobs;
    this.tags = tags;
  }

  private clearUploadedPdfPlanObjects() {
    this.pdfPlanAttachment = undefined;
    this.pdfPlanVersion = undefined;
    this.pdfPlanPages = undefined;
    this.pdfBlob = undefined;
    this.imageBlobs = undefined;

    this.pdfPlanVersionQuality = PdfPlanVersionQualityEnum.DEFAULT;
    this.printWarningReducedQualityUsed = undefined;
  }

  pdfPlanVersionValidChanged(valid: boolean) {
    this.pdfPlanVersionValid = valid;
  }

  private async copyMarkers() {
    try {
      this.processing = true;
      const latestPdfPlanVersion = await observableToPromise(this.latestPdfPlanVersion$);
      const latestPdfPlanVersionPages = await observableToPromise(this.latestPdfPlanVersionPages$);
      if (!latestPdfPlanVersionPages?.length) {
        this.pdfPlanPageMarkings = [];
        this.pdfPlanMarkerProtocolEntries = [];
      } else {
        const sourceQuality = latestPdfPlanVersion.quality ?? PdfPlanVersionQualityEnum.LOW;
        const {pdfPlanPageMarkings, pdfPlanMarkerProtocolEntries} = await this.pdfPlanService.copyMarkers(
          latestPdfPlanVersionPages.map((value) => value.id),
          sourceQuality,
          this.pdfPlanPages,
          this.pdfPlanVersionQuality,
          false,
          this.migratePdfPlanPageMarkingGeneral
        );
        if (this.pdfPlanPageMarkings === undefined) {
          this.pdfPlanPageMarkings = pdfPlanPageMarkings;
        }
        if (this.pdfPlanMarkerProtocolEntries === undefined) {
          this.pdfPlanMarkerProtocolEntries = pdfPlanMarkerProtocolEntries;
        }
      }
    } catch (error) {
      const errorMessage = `Copying markers failed with error ${convertErrorToMessage(error)}`;
      this.systemEventService.logErrorEvent(LOG_SOURCE, errorMessage);
      this.loggingService.error(LOG_SOURCE, errorMessage);
      this.toastService.error(this.translateService.instant('project_room.upload_pdf_plan_version.step.MARKER.errorCopying', {errorMessage: convertErrorToMessage(error)}));
    } finally {
      this.processing = false;
    }
  }

  public isCurrentStepValid(): boolean {
    return this.isStepValid(this.currentStep);
  }

  public isAllStepsValid(): boolean {
    for (const step of this.workflowSteps) {
      if (!this.isStepValid(step)) {
        return false;
      }
    }
    return true;
  }

  private async preProcessStep(step: UploadPdfPlanVersionWorkflowStep): Promise<boolean> {
    switch (step.key) {
      case 'FILE_SELECT':
        return true;
      case 'DETAILS':
        return true;
      case 'MARKER': {
        await this.copyMarkers();
        return true;
      }
      case 'SUMMARY':
        return true;
      default:
        throw new Error(`processStep - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  private async showStep(step: UploadPdfPlanVersionWorkflowStep): Promise<boolean> {
    switch (step.key) {
      case 'FILE_SELECT':
        return true;
      case 'DETAILS':
        return true;
      case 'MARKER':
        return (await observableToPromise(this.latestPdfPlanPageMarkings$))?.length > 0 || (await observableToPromise(this.latestPdfPlanMarkerProtocolEntries$))?.length > 0;
      case 'SUMMARY':
        return true;
      default:
        throw new Error(`processStep - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  private async postProcessStep(step: UploadPdfPlanVersionWorkflowStep): Promise<boolean> {
    switch (step.key) {
      case 'FILE_SELECT':
        return true;
      case 'DETAILS':
        return true;
      case 'MARKER': {
        return await this.openPdfPlanMarkerMigrationModal();
      }
      case 'SUMMARY': {
        await this.finish();
        return true;
      }
      default:
        throw new Error(`processStep - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  private isStepValid(step: UploadPdfPlanVersionWorkflowStep): boolean {
    switch (step.key) {
      case 'FILE_SELECT':
        return this.isNetworkConnected !== false && this.files.filter((file) => file.type === MIME_TYPE_PDF).length && !this.processingUploadedFile;
      case 'DETAILS':
        return this.isNetworkConnected !== false && this.pdfPlanVersion && this.pdfPlanVersionValid;
      case 'MARKER':
        return this.isNetworkConnected !== false && this.pdfPlanPageMarkings !== undefined && this.pdfPlanMarkerProtocolEntries !== undefined;
      case 'SUMMARY':
        return this.isNetworkConnected !== false;
      default:
        throw new Error(`isCurrentStepValid - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  private async openPdfPlanMarkerMigrationModal(): Promise<boolean> {
    const latestPdfPlanVersion = await observableToPromise(this.latestPdfPlanVersion$);
    const modal = await this.modalController.create({
      component: PdfPlanMarkerMigrationModalComponent,
      backdropDismiss: false,
      cssClass: 'full-screen-modal-xxl',
      componentProps: {
        latestPdfPlanVersion,
        pdfPlanVersion: this.pdfPlanVersion,
        pdfPlanPages: this.pdfPlanPages,
        imageBlobs: this.imageBlobs,
        migratePdfPlanPageMarkingGeneral: this.migratePdfPlanPageMarkingGeneral,
        pdfPlanPageMarkings: this.pdfPlanPageMarkings,
        pdfPlanMarkerProtocolEntries: this.pdfPlanMarkerProtocolEntries,
      },
    });

    await modal.present();

    const {data, role} = await modal.onDidDismiss();
    const success = role === 'ok';
    if (success) {
      this.pdfPlanPageMarkings = data.pdfPlanPageMarkings;
      this.pdfPlanMarkerProtocolEntries = data.pdfPlanMarkerProtocolEntries;
    }
    return success;
  }

  async migratePdfPlanPageMarkingGeneralChanged(migratePdfPlanPageMarkingGeneral: boolean) {
    this.migratePdfPlanPageMarkingGeneral = migratePdfPlanPageMarkingGeneral;
    this.pdfPlanPageMarkings = undefined;
    await this.copyMarkers();
  }

  public abortProcessingFile() {
    this.processFileAbortController?.abort();
  }

  changeQuality(event: PdfPlanVersionQualityEnum) {
    this.pdfPlanVersionQuality = event;
  }
}
