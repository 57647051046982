import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthenticateRes, IdType, UserConnectionInvite, UserConnectionInviteConfirmReq, UserConnectionInviteReq, UserConnectionInviteRes} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {observableToPromise} from '../../utils/async-utils';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';
import {UserConnectionInviteDataService} from '../data/user-connection-invite-data.service';

const LOG_SOURCE = 'UserConnectionInviteService';

@Injectable({
  providedIn: 'root',
})
export class UserConnectionInviteService {
  public static readonly USER_INVITE_INVITE_URL = environment.serverUrl + 'userConnectionInvite/invite';
  public static readonly USER_INVITE_CONFIRM_URL = environment.serverUrl + 'userConnectionInvite/confirmInvite';
  public static readonly USER_BASE_URL = environment.serverUrl + 'user';
  private readonly httpOptions = {
    headers: {
      Accept: 'application/json; version=6',
    },
  };

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private userConnectionInviteDataService: UserConnectionInviteDataService,
    private syncService: SyncService
  ) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called');
  }

  public async sendUserConnectionInvite(profileId: IdType, language: string, clientId: IdType, projectId: IdType | undefined): Promise<UserConnectionInviteRes> {
    const payload: UserConnectionInviteReq = {
      profileId,
      language,
      projectId,
    };
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
    const result = await observableToPromise(this.http.post<UserConnectionInviteRes>(UserConnectionInviteService.USER_INVITE_INVITE_URL + '/' + clientId, payload));
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    return result;
  }

  public async confirmUserConnectionInvite(userConnectionInviteConfirmReq: UserConnectionInviteConfirmReq): Promise<AuthenticateRes> {
    return await observableToPromise(this.http.post<AuthenticateRes>(UserConnectionInviteService.USER_INVITE_CONFIRM_URL, userConnectionInviteConfirmReq, this.httpOptions));
  }

  public async cancelInvite(userConnectionInvite: UserConnectionInvite) {
    await this.userConnectionInviteDataService.delete(userConnectionInvite);
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
  }

  public async resendInvite(userConnectionInvite: UserConnectionInvite) {
    await this.sendUserConnectionInvite(userConnectionInvite.profileId, userConnectionInvite.language, userConnectionInvite.clientId, userConnectionInvite.projectId);
  }
}
