import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {IonicModule, IonInput} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {Nullish} from 'src/app/model/nullish';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {Protocol, ProtocolEntry, ProtocolType} from 'submodules/baumaster-v2-common';

const LOG_SOURCE = 'EntryEditNumberModal';
const ONLY_POSITIVE_NUMBER = /^[0-9]\d*$/;

@Component({
  selector: 'app-entry-edit-number-modal',
  templateUrl: './entry-edit-number-modal.component.html',
  styleUrls: ['./entry-edit-number-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, UiModule, IonicModule, TranslateModule, ReactiveFormsModule, FormsModule],
})
export class EntryEditNumberModalComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;

  entryNumberForm: UntypedFormGroup = this.formBuilder.group({
    number: [null, Validators.compose([Validators.required, Validators.pattern(ONLY_POSITIVE_NUMBER), Validators.max(999), this.numberValidator()])],
  });

  @Input()
  entryId?: Nullish<string>;

  @Input()
  isCarriedOver?: Nullish<boolean>;

  @Input()
  isProtocolClosed: boolean;

  public maxNumber: number;
  public selectedProtocolEntry: ProtocolEntry | undefined;
  public protocol$: Observable<Protocol> | undefined;
  public protocolType$: Observable<ProtocolType> | undefined;
  private selectedProtocolEntrySubscription: Subscription | undefined;
  private protocolEntriesSubscription: Subscription | undefined;
  private protocolSubEntriesSubscription: Subscription | undefined;
  private allProtocolEntriesSubscription: Subscription | undefined;
  private parentProtocolEntriesData: Array<ProtocolEntry>;
  private protocolSubEntriesData: Array<ProtocolEntry> | undefined;
  public allProtocolEntriesData: Array<ProtocolEntryOrOpen> | undefined;
  public changeInProgress = false;

  @ViewChild('inputNumber') inputNumber: IonInput;
  constructor(
    private formBuilder: FormBuilder,
    private protocolEntryDataService: ProtocolEntryDataService,
    private loggingService: LoggingService,
    private projectDataService: ProjectDataService,
    private toastService: ToastService,
    private protocolDataService: ProtocolDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryService: ProtocolEntryService
  ) {}

  async ngOnInit() {
    this.selectedProtocolEntrySubscription = this.protocolEntryDataService.getById(this.entryId).subscribe((protocolEntry) => {
      this.selectedProtocolEntry = protocolEntry;
      this.protocol$ = this.protocolDataService.getById(protocolEntry.protocolId);
    });
    this.protocolType$ = this.protocolTypeDataService.getById((await observableToPromise(this.protocol$))?.typeId);
    this.allProtocolEntriesSubscription = this.protocolEntryDataService
      .getProtocolEntryOrOpenByProtocolId(this.selectedProtocolEntry.protocolId)
      .subscribe((entries) => (this.allProtocolEntriesData = entries));
    this.protocolEntriesSubscription = this.protocolEntryDataService
      .getByProtocolId(this.selectedProtocolEntry.protocolId)
      .subscribe((protocolEntries) => (this.parentProtocolEntriesData = protocolEntries.filter((entry) => !entry.parentId)));
    if (this.selectedProtocolEntry.parentId) {
      this.protocolSubEntriesSubscription = this.protocolEntryDataService
        .getSubEntriesByParentEntryId(this.selectedProtocolEntry.parentId)
        .subscribe((subEntries) => (this.protocolSubEntriesData = subEntries));
    }
    this.maxNumber = this.getNextNumber();
    this.entryNumberForm.get('number').setValue(this.selectedProtocolEntry.number);
  }

  private getNextNumber() {
    const entries = this.selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
    if (!entries?.length) {
      return 1;
    }
    const numbers = _.sortBy(entries.map((protocolEntry) => protocolEntry.number));
    return numbers[numbers.length - 1] + 1;
  }

  private numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number | null = control.value;
      if (value === null) {
        return null;
      }
      if (!this.selectedProtocolEntry) {
        return null;
      }
      const entryNumber: number = control.value;
      if (entryNumber > this.maxNumber) {
        return {numberTooHigh: true};
      }
      if (this.selectedProtocolEntry && this.selectedProtocolEntry.number === entryNumber) {
        return null;
      }
      const protocolEntries = this.selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
      if (protocolEntries.some((protocol) => protocol.number === entryNumber) && entryNumber < this.maxNumber) {
        return {numbersWillChange: true};
      }
    };
  }

  public async changeEntryNumber() {
    const entryNumber: number = +this.inputNumber.value;
    const protocolEntries = this.selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
    if (!protocolEntries.length) {
      return;
    }
    const numberAlreadyUsed = () => {
      if (protocolEntries.some((protocol) => protocol.number === entryNumber)) {
        return true;
      }
      return false;
    };
    const currentProject = await this.projectDataService.getCurrentProject();
    try {
      this.changeInProgress = true;
      if (entryNumber < this.maxNumber && numberAlreadyUsed()) {
        const filteredProtocolEntries = protocolEntries.filter((entry) => entry.number >= entryNumber || (entry.number > entryNumber && entry.number < this.selectedProtocolEntry.number));
        for (const filteredEntry of filteredProtocolEntries) {
          const newNumber = ++filteredEntry.number;
          const entryToUpdate: ProtocolEntry = {...filteredEntry, number: newNumber};
          await this.protocolEntryDataService.update(entryToUpdate, currentProject.id);
        }
      }
      if (entryNumber !== this.selectedProtocolEntry.number) {
        this.selectedProtocolEntry.number = entryNumber;
        await this.protocolEntryDataService.update(this.selectedProtocolEntry, currentProject.id);
      }
      await this.toastService.savingSuccess();
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error changeEntryNumber. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
    } finally {
      this.changeInProgress = false;
      await this.dismissModal();
      await this.protocolEntryService.checkAndHandleGapsBetweenProtocolEntries(
        this.selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData,
        currentProject.id,
        'fillGapsEntries.actions.number'
      );
    }
  }

  async dismissModal() {
    await this.modal.dismiss();
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    if (this.selectedProtocolEntrySubscription) {
      this.selectedProtocolEntrySubscription.unsubscribe();
      this.selectedProtocolEntrySubscription = null;
    }
    if (this.protocolEntriesSubscription) {
      this.protocolEntriesSubscription.unsubscribe();
      this.protocolEntriesSubscription = null;
    }
    if (this.protocolSubEntriesSubscription) {
      this.protocolSubEntriesSubscription.unsubscribe();
      this.protocolSubEntriesSubscription = undefined;
    }
    if (this.allProtocolEntriesSubscription) {
      this.allProtocolEntriesSubscription.unsubscribe();
      this.allProtocolEntriesSubscription = undefined;
    }
  }
}
