import {ColumnConfig} from '../ColumnConfig';
import {AttachmentReportEquipmentColumnSet} from './AttachmentReportEquipmentColumnSet';
import {PdfPlanMarkerProtocolEntryColumnSet} from './PdfPlanMarkerProtocolEntryColumnSet';

export const EquipmentColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'status_editor', prop: 'statusEditor', def: null},
  {name: 'status_employee', prop: 'statusEmployee', def: null},
  {name: 'is_deleted', prop: 'isDeleted'},
  {name: 'position', prop: 'position', def: null},
  {name: 'count', prop: 'count', def: null},
  {name: 'hours', prop: 'hours', def: null},
  {name: 'equipment_item_display', prop: 'equipmentItemDisplay', def: null},
  {name: 'price_per_unit', prop: 'pricePerUnit', def: null},
  {name: 'equipment_item_id', prop: 'equipmentItemId', def: null},
  {name: 'modified_by_id', prop: 'modifiedById', def: null},
  {name: 'report_id', prop: 'reportId'},
  {name: 'attachmentReportEquipments', fk: 'equipmentId', mappedBy: AttachmentReportEquipmentColumnSet, transient: true},
  {name: 'pdfPlanMarkerProtocolEntries', fk: 'equipmentId', mappedBy: PdfPlanMarkerProtocolEntryColumnSet, transient: true},
];
