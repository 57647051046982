import {Client, LicenseType, User, UserConnectionInvite, UserInvite, UserRegistration} from '../models';

export function hasUserInviteOrRegistrationExpired(userInvite: UserInvite | UserConnectionInvite | UserRegistration): boolean {
  const expiredAt: Date = typeof userInvite.validUntil === 'string' ? new Date(userInvite.validUntil) : userInvite.validUntil;
  return expiredAt.getTime() < new Date().getTime();
}

export interface LicenseCount {
  total: number;
  available: number;
}

export type LicenseCountType = 'LIGHT' | 'BASIC' | 'PROFESSIONAL' | 'REPORTS';

export type AvailableLicense = {[key in LicenseCountType]: LicenseCount};

export function determineAvailableLicenses(client: Client, users: Array<User>, userInvites: Array<UserInvite>): AvailableLicense {
  const activeUsers = users.filter((user) => user.isActive);
  const activeUserInvites = userInvites.filter((userInvite) => !hasUserInviteOrRegistrationExpired(userInvite));
  const allUsers: Array<User | UserInvite> = [...activeUsers, ...activeUserInvites];

  const lightUsed = allUsers.filter((user) => user.role === LicenseType.LIGHT).length;
  const basicUsed = allUsers.filter((user) => user.role === LicenseType.BASIC).length;
  const professionalUsed = allUsers.filter((user) => user.role === LicenseType.PROFESSIONAL).length;
  const reportsUsed = allUsers.filter((user) => user.assignedReportRights).length;

  return {
    LIGHT: {
      total: client.licensesAnnualLight,
      available: client.licensesAnnualLight - lightUsed,
    },
    BASIC: {
      total: client.licensesAnnualStandard,
      available: client.licensesAnnualStandard - basicUsed,
    },
    PROFESSIONAL: {
      total: client.licensesAnnualPro,
      available: client.licensesAnnualPro - professionalUsed,
    },
    REPORTS: {
      total: client.licensesReports,
      available: client.licensesReports - reportsUsed,
    },
  };
}
