import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IndividualNextMeetingForm, NextMeetingForm} from 'src/app/components/pdf/next-meeting/next-meeting.interface';
import {IdType} from 'submodules/baumaster-v2-common';

export interface PdfSettingsCache {
  emailSettingsForm: UntypedFormGroup;
  protocolConfigForm: UntypedFormGroup;
  nextMeeting: NextMeetingForm;
  individualNextMeetings: IndividualNextMeetingForm[];
}

@Injectable({
  providedIn: 'root',
})
export class PdfSettingsCacheService {
  private pdfSettingsCaches = new Map<IdType, PdfSettingsCache>();

  get(protocolId: IdType): PdfSettingsCache | undefined {
    if (protocolId) {
      return this.pdfSettingsCaches.get(protocolId);
    }
    return undefined;
  }

  add(protocolId: IdType, pdfSettingsCache: PdfSettingsCache) {
    if (protocolId) {
      this.pdfSettingsCaches.set(protocolId, pdfSettingsCache);
    }
  }

  clear(protocolId: IdType) {
    if (protocolId) {
      this.pdfSettingsCaches.delete(protocolId);
    }
  }
}
