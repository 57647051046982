import {ChangeDetectionStrategy, Component, ContentChild, Directive, HostBinding, Input, TemplateRef} from '@angular/core';
import {Nullish} from 'src/app/model/nullish';
import {TagBase} from 'submodules/baumaster-v2-common';

@Directive({
  selector: '[appTagStartTemplate]',
})
export class TagStartTemplateDirective {}

@Directive({
  selector: '[appTagEndTemplate]',
})
export class TagEndTemplateDirective {}

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  private _startTemplate: Nullish<TemplateRef<{tag: TagBase}>>;
  @Input()
  set startTemplate(template: Nullish<TemplateRef<{tag: TagBase}>>) {
    this._startTemplate = template;
  }
  get startTemplate(): Nullish<TemplateRef<{tag: TagBase}>> {
    return this._startTemplate || this.startTemplateContent;
  }

  private _endTemplate: Nullish<TemplateRef<{tag: TagBase}>>;
  @Input()
  set endTemplate(template: Nullish<TemplateRef<{tag: TagBase}>>) {
    this._endTemplate = template;
  }
  get endTemplate(): Nullish<TemplateRef<{tag: TagBase}>> {
    return this._endTemplate || this.endTemplateContent;
  }

  @ContentChild(TagStartTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private startTemplateContent: Nullish<TemplateRef<{tag: TagBase}>>;

  @ContentChild(TagEndTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private endTemplateContent: Nullish<TemplateRef<{tag: TagBase}>>;

  @Input()
  tag: TagBase;

  @HostBinding('style.--accent-color')
  get varBackground() {
    return `var(--bau-color-${this.tag.color}-rgb)`;
  }

  @HostBinding('style.--accent-opacity')
  get opacityBackground() {
    return `var(--bau-color-${this.tag.color}-opacity)`;
  }

  @HostBinding('style.--accent-opacity-hover')
  get opacityHoverBackground() {
    return `var(--bau-color-${this.tag.color}-opacity-hover)`;
  }
}
