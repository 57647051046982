import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  IonicSelectableComponent,
  IonicSelectableFooterTemplateDirective,
  IonicSelectableGroupEndTemplateDirective,
  IonicSelectableGroupTemplateDirective,
  IonicSelectableHeaderTemplateDirective,
  IonicSelectableIconTemplateDirective,
  IonicSelectableItemIconTemplateDirective,
  IonicSelectableItemTemplateDirective,
  IonicSelectableSearchFailTemplateDirective,
  IonicSelectableValueTemplateDirective,
  IonicSelectableTitleTemplateDirective,
  IonicSelectableCloseButtonTemplateDirective,
  IonicSelectableAddItemTemplateDirective,
  IonicSelectableItemEndTemplateDirective,
  IonicSelectableMessageTemplateDirective,
  IonicSelectableModalComponent,
  IonicSelectablePlaceholderTemplateDirective,
} from 'ionic-selectable';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SelectableInputModule} from '../selectable-input/selectable-input.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectableComponent} from '../../../components/common/selectable/selectable.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [SelectableComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SelectableInputModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    IonicSelectableComponent,
    IonicSelectableIconTemplateDirective,
    IonicSelectableHeaderTemplateDirective,
    IonicSelectableFooterTemplateDirective,
    IonicSelectableGroupEndTemplateDirective,
    IonicSelectableItemTemplateDirective,
    IonicSelectableValueTemplateDirective,
    IonicSelectableGroupTemplateDirective,
    IonicSelectableSearchFailTemplateDirective,
    IonicSelectableItemIconTemplateDirective,
    IonicSelectableTitleTemplateDirective,
    IonicSelectableCloseButtonTemplateDirective,
    IonicSelectableAddItemTemplateDirective,
    IonicSelectableItemEndTemplateDirective,
    IonicSelectableMessageTemplateDirective,
    IonicSelectableModalComponent,
    IonicSelectablePlaceholderTemplateDirective,
  ],
  exports: [SelectableComponent],
})
export class CommonSelectableModule {}
