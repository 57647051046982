import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauStatusContinuousInfo: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'status-continuous-info' as IconName,
  icon: [
    32,
    32,
    [],
    null,
    // eslint-disable-next-line max-len
    'M16 32A16 16 0 1132 16 16 16 0 0116 32ZM16 2.13A13.87 13.87 0 1029.87 16 13.88 13.88 0 0016 2.13ZM11.5 9C11.96 9 12.41 9.18 12.74 9.51 13.07 9.84 13.25 10.29 13.25 10.75 13.25 11.21 13.07 11.66 12.74 11.99 12.41 12.32 11.96 12.5 11.5 12.5 11.04 12.5 10.59 12.32 10.26 11.99 9.93 11.66 9.75 11.21 9.75 10.75 9.75 10.29 9.93 9.84 10.26 9.51 10.59 9.18 11.04 9 11.5 9ZM9.31 20.38H10.63V16.88H9.31C8.59 16.88 8 16.29 8 15.56 8 14.84 8.59 14.25 9.31 14.25H11.94C12.66 14.25 13.25 14.84 13.25 15.56V20.38H13.69C14.41 20.38 15 20.96 15 21.69 15 22.41 14.41 23 13.69 23H9.31C8.59 23 8 22.41 8 21.69 8 20.96 8.59 20.38 9.31 20.38ZM19.23 10.04C19.62 9.65 20.25 9.65 20.64 10.04L25.89 15.29C26.29 15.68 26.29 16.32 25.89 16.71L20.64 21.96C20.25 22.35 19.62 22.35 19.23 21.96 18.84 21.57 18.84 20.93 19.23 20.54L22.77 17H16C15.45 17 15 16.55 15 16 15 15.45 15.45 15 16 15H22.77L19.23 11.46C18.84 11.07 18.84 10.43 19.23 10.04ZM16 3.8C9.26 3.8 3.8 9.26 3.8 16 3.8 22.74 9.26 28.2 16 28.2 22.74 28.2 28.2 22.74 28.2 16 28.2 9.26 22.74 3.8 16 3.8Z',
  ],
};
