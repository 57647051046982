import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmailSignatureFormComponent} from 'src/app/components/common/email-signature/email-signature-form/email-signature-form.component';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {EmailSignaturePreviewComponent} from 'src/app/components/common/email-signature/email-signature-preview/email-signature-preview.component';
import {FormsModule} from '@angular/forms';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {EmailSignatureInlineFormComponent} from 'src/app/components/common/email-signature/email-signature-inline-form/email-signature-inline-form.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  IonicSelectableAddItemTemplateDirective,
  IonicSelectableCloseButtonTemplateDirective,
  IonicSelectableComponent,
  IonicSelectableFooterTemplateDirective,
  IonicSelectableGroupEndTemplateDirective,
  IonicSelectableGroupTemplateDirective,
  IonicSelectableHeaderTemplateDirective,
  IonicSelectableIconTemplateDirective,
  IonicSelectableItemEndTemplateDirective,
  IonicSelectableItemIconTemplateDirective,
  IonicSelectableItemTemplateDirective,
  IonicSelectableMessageTemplateDirective,
  IonicSelectableModalComponent,
  IonicSelectablePlaceholderTemplateDirective,
  IonicSelectableSearchFailTemplateDirective,
  IonicSelectableTitleTemplateDirective,
  IonicSelectableValueTemplateDirective,
} from 'ionic-selectable';
import {UiModule} from '../ui/ui.module';
import {EmailSignatureRawPreviewComponent} from 'src/app/components/common/email-signature/email-signature-raw-preview/email-signature-raw-preview.component';
import {TooltipModule} from '../tooltip/tooltip.module';
import {AttachmentViewerModule} from '../attachment-viewer/attachment-viewer.module';
import {SelectableInputModule} from '../selectable-input/selectable-input.module';

@NgModule({
  declarations: [EmailSignatureFormComponent, EmailSignaturePreviewComponent, EmailSignatureInlineFormComponent, EmailSignatureRawPreviewComponent],
  exports: [EmailSignatureFormComponent, EmailSignaturePreviewComponent, EmailSignatureInlineFormComponent, EmailSignatureRawPreviewComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    PipesModule,
    FontAwesomeModule,
    UiModule,
    TooltipModule,
    AttachmentViewerModule,
    SelectableInputModule,
    IonicSelectableComponent,
    IonicSelectableIconTemplateDirective,
    IonicSelectableHeaderTemplateDirective,
    IonicSelectableFooterTemplateDirective,
    IonicSelectableGroupEndTemplateDirective,
    IonicSelectableItemTemplateDirective,
    IonicSelectableValueTemplateDirective,
    IonicSelectableGroupTemplateDirective,
    IonicSelectableSearchFailTemplateDirective,
    IonicSelectableItemIconTemplateDirective,
    IonicSelectableTitleTemplateDirective,
    IonicSelectableCloseButtonTemplateDirective,
    IonicSelectableAddItemTemplateDirective,
    IonicSelectableItemEndTemplateDirective,
    IonicSelectableMessageTemplateDirective,
    IonicSelectableModalComponent,
    IonicSelectablePlaceholderTemplateDirective,
  ],
})
export class EmailSignatureModule {}
