import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LoggingService} from 'src/app/services/common/logging.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {Address, IdType, Profile, Project, ProjectProfile, UnitProfile} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {UnitProfileDataService} from 'src/app/services/data/unit-profile-data.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {ProjectProfileDataService} from 'src/app/services/data/project-profile-data.service';
import {UnitContactFormComponent} from '../unit-contact-form/unit-contact-form.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UiModule} from '../../../shared/module/ui/ui.module';

const LOG_SOURCE = 'UnitContactCreateComponent';

@Component({
  selector: 'app-unit-contact-create',
  templateUrl: './unit-contact-create.component.html',
  styleUrls: ['./unit-contact-create.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, FontAwesomeModule, TranslateModule, UiModule, UnitContactFormComponent],
})
export class UnitContactCreateComponent implements OnInit, OnDestroy {
  @ViewChild(UnitContactFormComponent) unitContactFormComponent: UnitContactFormComponent;

  @Input()
  hideUnitsSelector = false;

  @Input()
  prefillLastName: string | undefined;

  public loading = false;
  public currentProject: Project | undefined;

  private destroy$ = new Subject<void>();
  private modal: HTMLIonModalElement;
  private ownCompanyId: IdType | undefined;

  constructor(
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private toastService: ToastService,
    private projectDataService: ProjectDataService,
    private alertCtrl: AlertController,
    private projectProfileDataService: ProjectProfileDataService,
    private unitProfileDataService: UnitProfileDataService,
    private userProfileService: UserProfileService
  ) {}

  async ngOnInit() {
    this.setCanDismiss();
    this.currentProject = await this.projectDataService.getMandatoryCurrentProject();
    this.ownCompanyId = (await observableToPromise(this.userProfileService.currentUserOwnProfile$)).companyId;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async createNewContact() {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, `Create unit contact`);
    try {
      this.loading = true;
      const rawData = this.unitContactFormComponent.getRawValue();

      if (!this.currentProject) {
        throw new Error('Current project is not set');
      }

      const projectId = this.currentProject.id;
      const clientId = this.currentProject.clientId;

      const createdAddress = _.head(await this.createAddress(rawData, clientId));
      logInstance.logCheckpoint(() => `address created (id=${createdAddress.id})`);
      const createdProfile = _.head(await this.createProfile(rawData, createdAddress.id, clientId));
      logInstance.logCheckpoint(() => `profile created (id=${createdProfile.id})`);
      const projectProfile = _.head(await this.createProjectProfile(createdProfile, projectId));
      logInstance.logCheckpoint(() => `project profile created (id=${projectProfile.id})`);
      for (const unitId of rawData.selectedUnitIds) {
        const unitProfile = _.head(await this.createUnitProfile(createdProfile, unitId, projectId));
        logInstance.logCheckpoint(() => `unit profile created (id=${unitProfile.id}, unitId=${unitId})`);
      }

      this.unitContactFormComponent.markAsPristine();
      this.dismissModal('ok', createdProfile);
      logInstance.success();
    } catch (error) {
      logInstance.failure(error);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save unit contact ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save unit contact. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
    } finally {
      this.loading = false;
    }
  }

  private async createUnitProfile(profile: Profile, unitId: IdType, projectId: IdType) {
    const unitProfile: UnitProfile = {
      id: uuidv4(),
      unitId: unitId,
      profileId: profile.id,
      isDefault: false,
      isActive: true,
      createdAt: new Date().toISOString(),
      changedAt: new Date().toISOString(),
    };
    return await this.unitProfileDataService.insert(unitProfile, projectId);
  }

  private async createProjectProfile(profile: Profile, projectId: IdType) {
    const projectProfile: ProjectProfile = {
      id: projectId + profile.id,
      projectId,
      profileId: profile.id,
      changedAt: new Date().toISOString(),
    };
    return await this.projectProfileDataService.insert(projectProfile, projectId);
  }

  private async createProfile(rawData: ReturnType<UnitContactFormComponent['getRawValue']>, addressId: IdType, clientId: IdType): Promise<Profile[]> {
    const profile: Profile = {
      id: uuidv4(),
      clientId,
      externalId: rawData.externalId,
      legalEntity: rawData.legalEntity,
      note: rawData.note,
      addressId,
      type: 'UNIT_CONTACT',
      companyId: this.ownCompanyId,
      dsgvoShowEmail: rawData.dsgvoShowEmail,
      dsgvoShowTel: rawData.dsgvoShowTel,
      changedAt: new Date().toISOString(),
      isActive: true,
    };
    return await this.profileDataService.insert(profile, clientId);
  }

  private async createAddress(rawData: ReturnType<UnitContactFormComponent['getRawValue']>, clientId: IdType): Promise<Address[]> {
    const address: Address = {
      id: uuidv4(),
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      city: rawData.city,
      country: rawData.country,
      email: rawData.email,
      phone: rawData.phone,
      salutation: rawData.salutation,
      street1: rawData.street1,
      street2: rawData.street2,
      zipCode: rawData.zipCode,
      changedAt: new Date().toISOString(),
      clientId,
    };
    return await this.addressDataService.insert(address, (await this.clientService.getOwnClientMandatory()).id);
  }

  dismissModal(role?: 'ok' | 'cancel', data?: Profile) {
    return this.modal.dismiss(data, role);
  }

  private canDismiss = async () => {
    if (!this.unitContactFormComponent?.isDirty()) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('okay'),
          role: 'dismiss',
        },
      ],
    });
    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }
}
