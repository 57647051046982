import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {ProjectSelectorColComponent} from './project-selector-col/project-selector-col.component';
import {ProjectSelectorComponent} from './project-selector.component';
import {SelectableInputModule} from '../../../shared/module/selectable-input/selectable-input.module';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {ProjectLoaderComponent} from '../project-loader/project-loader.component';
import {TooltipModule} from '../../../shared/module/tooltip/tooltip.module';
import {ProjectSelectorItemComponent} from './project-selector-item/project-selector-item.component';

@NgModule({
  imports: [TranslateModule, SelectableInputModule, CommonModule, FontAwesomeModule, FormsModule, IonicModule, PipesModule, RouterModule, UiModule, ProjectLoaderComponent, TooltipModule],
  declarations: [ProjectSelectorComponent, ProjectSelectorColComponent, ProjectSelectorItemComponent],
  exports: [ProjectSelectorComponent, ProjectSelectorColComponent, ProjectSelectorItemComponent],
})
export class ProjectSelectorModule {}
