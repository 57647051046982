import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Menu, MenuGroup, MenuService} from 'src/app/services/common/menu.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {LogoutService} from 'src/app/services/logout.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';
import {DeviceService} from 'src/app/services/ui/device.service';
import {IonMenuService} from 'src/app/services/ui/ion-menu.service';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {v4} from 'uuid';
import {Project} from 'submodules/baumaster-v2-common';

const MENU_ITEM_TITLES_TO_TRACK = new Set<string>(['MENU.projects', 'MENU.dashboard', 'MENU.protocols', 'MENU.contacts']);

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
  readonly instanceId = v4();
  public environment = environment;
  public menuPages: Array<Menu | MenuGroup>;
  public selectedMenu: Menu;

  @Input()
  @HostBinding('class.with-menu-button')
  withMenuButton = false;

  @Input()
  renderTooltips = false;

  private destroy$ = new Subject<void>();

  sideBarSetting$ = this.sideBarSettingsService.sideBarSetting$;
  project$: Observable<Project> = this.projectDataService.currentProjectObservable;

  constructor(
    public menuService: MenuService,
    private logoutService: LogoutService,
    private ionMenuService: IonMenuService,
    private deviceService: DeviceService,
    private posthogService: PosthogService,
    private sideBarSettingsService: SideBarSettingsService,
    private projectDataService: ProjectDataService
  ) {
    this.menuPages = this.menuService.mainMenus;
    this.menuService.currentMenu$.pipe(takeUntil(this.destroy$)).subscribe((currentMenu) => {
      this.selectedMenu = currentMenu;
    });
  }

  async ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async logout() {
    await this.logoutService.logout();
  }

  async menuClicked(menu: Menu) {
    this.posthogService.captureEvent('[Menu] Clicked on ' + menu.title, {});
    if (menu.actionFn) {
      menu.actionFn();
    } else {
      this.menuService.setMenu(menu);

      if (await this.deviceService.isNativeApp()) {
        if (MENU_ITEM_TITLES_TO_TRACK.has(menu.title)) {
          this.posthogService.captureEvent('Mobile Navigate SideMenu', {destination: menu.title});
        }
      }
    }
  }

  hideMenu(menu: Menu) {
    if (menu?.keepMenuOpenAfterClick) {
      return;
    }
    this.ionMenuService.ionMenu?.close();
  }
}
