import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  IonicSelectableAddItemTemplateDirective,
  IonicSelectableCloseButtonTemplateDirective,
  IonicSelectableComponent,
  IonicSelectableFooterTemplateDirective,
  IonicSelectableGroupEndTemplateDirective,
  IonicSelectableGroupTemplateDirective,
  IonicSelectableHeaderTemplateDirective,
  IonicSelectableIconTemplateDirective,
  IonicSelectableItemEndTemplateDirective,
  IonicSelectableItemIconTemplateDirective,
  IonicSelectableItemTemplateDirective,
  IonicSelectableMessageTemplateDirective,
  IonicSelectableModalComponent,
  IonicSelectablePlaceholderTemplateDirective,
  IonicSelectableSearchFailTemplateDirective,
  IonicSelectableTitleTemplateDirective,
  IonicSelectableValueTemplateDirective,
} from 'ionic-selectable';
import {SelectableCommonDirective} from '../../../directives/selectable-common.directive';
import {
  ProjectSelectorFooterEndTemplateDirective,
  ProjectSelectorFooterStartTemplateDirective,
  SelectableFooterComponent,
} from 'src/app/components/ui/selectable/selectable-footer/selectable-footer.component';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SelectableHeaderComponent} from 'src/app/components/ui/selectable/selectable-header/selectable-header.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SelectableItemIconComponent} from 'src/app/components/ui/selectable/selectable-item-icon/selectable-item-icon.component';
import {SelectableValueComponent} from 'src/app/components/ui/selectable/selectable-value/selectable-value.component';

@NgModule({
  declarations: [
    SelectableCommonDirective,
    SelectableFooterComponent,
    SelectableHeaderComponent,
    SelectableItemIconComponent,
    SelectableValueComponent,
    ProjectSelectorFooterStartTemplateDirective,
    ProjectSelectorFooterEndTemplateDirective,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FontAwesomeModule,
    IonicSelectableComponent,
    IonicSelectableIconTemplateDirective,
    IonicSelectableHeaderTemplateDirective,
    IonicSelectableFooterTemplateDirective,
    IonicSelectableGroupEndTemplateDirective,
    IonicSelectableItemTemplateDirective,
    IonicSelectableValueTemplateDirective,
    IonicSelectableGroupTemplateDirective,
    IonicSelectableSearchFailTemplateDirective,
    IonicSelectableItemIconTemplateDirective,
    IonicSelectableTitleTemplateDirective,
    IonicSelectableCloseButtonTemplateDirective,
    IonicSelectableAddItemTemplateDirective,
    IonicSelectableItemEndTemplateDirective,
    IonicSelectableMessageTemplateDirective,
    IonicSelectableModalComponent,
    IonicSelectablePlaceholderTemplateDirective,
  ],
  exports: [
    SelectableCommonDirective,
    ProjectSelectorFooterStartTemplateDirective,
    IonicSelectableComponent,
    IonicSelectableIconTemplateDirective,
    IonicSelectableHeaderTemplateDirective,
    IonicSelectableFooterTemplateDirective,
    IonicSelectableGroupEndTemplateDirective,
    IonicSelectableItemTemplateDirective,
    IonicSelectableValueTemplateDirective,
    IonicSelectableGroupTemplateDirective,
    IonicSelectableSearchFailTemplateDirective,
    IonicSelectableItemIconTemplateDirective,
    IonicSelectableTitleTemplateDirective,
    IonicSelectableCloseButtonTemplateDirective,
    IonicSelectableAddItemTemplateDirective,
    IonicSelectableItemEndTemplateDirective,
    IonicSelectableMessageTemplateDirective,
    IonicSelectableModalComponent,
    IonicSelectablePlaceholderTemplateDirective,
    SelectableFooterComponent,
    SelectableHeaderComponent,
    SelectableItemIconComponent,
    SelectableValueComponent,
    ProjectSelectorFooterEndTemplateDirective,
  ],
})
export class SelectableInputModule {}
