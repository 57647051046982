import {Component} from '@angular/core';
import {Device} from '@capacitor/device';
import {TranslateService} from '@ngx-translate/core';
import {UserDeviceService} from 'src/app/services/auth/user-device.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {LogoutService} from 'src/app/services/logout.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {SyncService} from 'src/app/services/sync/sync.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {convertErrorToMessage} from 'src/app/shared/errors';

const LOG_SOURCE = 'UserDevicesModalComponent';

@Component({
  selector: 'app-user-devices-modal',
  templateUrl: './user-devices-modal.component.html',
  styleUrls: ['./user-devices-modal.component.scss'],
})
export class UserDevicesModalComponent {
  private modal: HTMLIonModalElement;
  protected selectedDeviceUuid: string | undefined;
  private inactiveDeviceIds: string[];
  buttonDisabled = false;

  constructor(
    private userDeviceService: UserDeviceService,
    private logoutService: LogoutService,
    private systemEventService: SystemEventService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private syncService: SyncService,
    private posthogService: PosthogService
  ) {}

  selectionChanged(deviceUuid: string) {
    this.selectedDeviceUuid = deviceUuid;
  }

  inactiveChanged(inactiveDeviceIds: string[]) {
    this.inactiveDeviceIds = inactiveDeviceIds;
  }

  async removeSelectedDevice() {
    try {
      this.buttonDisabled = true;
      const currentDeviceUuid = (await Device.getId()).identifier;
      if (this.inactiveDeviceIds.includes(currentDeviceUuid)) {
        await this.userDeviceService.deactivateAndActivateUserDevice(currentDeviceUuid, this.selectedDeviceUuid, 10000);
      } else {
        await this.userDeviceService.deactivateUserDevice(this.selectedDeviceUuid, 10000);
      }

      this.posthogService.captureEvent('[Settings][DeviceSettings][DeviceLimit]: Removed individual Device via modal', {});
      this.userDeviceService.updateObservableWithoutInterval();
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - removeSelectedDevice', convertErrorToMessage(error));
      await this.alertService.ok({
        header: this.translateService.instant('maxDevices.errorHeader'),
        message: this.translateService.instant('maxDevices.errorMessage'),
        confirmLabel: 'understood',
      });
    } finally {
      this.buttonDisabled = false;
    }
    await this.modal.dismiss();
  }

  async logout() {
    if (await this.logoutService.logout()) {
      await this.modal.dismiss();
    }
  }

  async refresh() {
    await this.modal.dismiss();
  }
}
