import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {observableToPromise} from 'src/app/utils/async-utils';
import {IdType, PdfPlanVersionAccess, PdfPlanVersionAccessType} from 'submodules/baumaster-v2-common';
import {v4 as uuidv4} from 'uuid';
import {PdfPlanVersionAccessDataService} from '../data/pdf-plan-version-access-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {UserDataService} from '../data/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class PdfPlanVersionAccessService {
  constructor(
    private pdfPlanVersionAccessDataService: PdfPlanVersionAccessDataService,
    private userDataService: UserDataService,
    private projectDataService: ProjectDataService
  ) {}

  currentUserHasViewedOrDownloaded$(pdfPlanVersionId: IdType, type: PdfPlanVersionAccessType): Observable<boolean> {
    return this.userDataService.currentUser$
      .pipe(switchMap((currentUser) => (currentUser ? this.pdfPlanVersionAccessDataService.getFirstByPlanVersionIdUserIdAndType$(pdfPlanVersionId, currentUser.id, type) : of(undefined))))
      .pipe(map((pdfPlanVersionAccess) => !!pdfPlanVersionAccess));
  }

  async getAccessObject(pdfPlanVersionId: IdType, type: PdfPlanVersionAccessType): Promise<PdfPlanVersionAccess> {
    const userId = (await observableToPromise(this.userDataService.currentUser$)).id;
    const changedAt = new Date().toISOString();
    const viewedPlanObject: PdfPlanVersionAccess = {
      id: uuidv4(),
      pdfPlanVersionId,
      userId,
      type,
      createdAt: changedAt,
      changedAt,
    };
    return viewedPlanObject;
  }

  async trackAccess(pdfPlanVersionId: IdType, type: PdfPlanVersionAccessType) {
    if (!(await observableToPromise(this.currentUserHasViewedOrDownloaded$(pdfPlanVersionId, type)))) {
      const viewedPlan = await this.getAccessObject(pdfPlanVersionId, type);
      const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
      await this.pdfPlanVersionAccessDataService.insert(viewedPlan, projectId);
    }
  }
}
