import {ColumnConfig} from '../ColumnConfig';
import {ClientColumnSet} from './AddressAndClientColumnSet';

export const AttachmentClientColumnSet: Array<ColumnConfig> = [
  {name: 'attachment_ptr_id', prop: 'id', cnd: true},
  {name: 'client_id', prop: 'clientId', cnd: true, transient: true},
  {name: 'clients', fk: 'logoId', mappedBy: ClientColumnSet, transient: true},
  {name: 'clients', fk: 'pdfendbannerId', mappedBy: ClientColumnSet, transient: true},
  {name: 'clients', fk: 'pdfstartbannerId', mappedBy: ClientColumnSet, transient: true},
];
