import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {environment} from 'src/environments/environment';
import {IdAware, SyncPerformancesRequest} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {NetworkStatusService} from '../common/network-status.service';
import {SystemEventService} from '../event/system-event.service';
import {StorageMutationOptions, StorageService} from '../storage.service';
import {SyncConflict} from './sync-utils';
import {BasePerformanceMeasurerResult, PerformanceMeasurerResult} from './utils/performance-utils';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {AsyncResultCallback, QueueObject, queue} from 'async';

const LOG_SOURCE = 'SyncPerformanceStatisticsService';
const STORAGE_KEY = StorageKeyEnum.SYNC_PERFORMANCE_STATISTIC;
const URL = 'api/advanced/syncPerformance';
const NORMALIZED_DURATION_THRESHOLD_IN_MS = 5 * 1000;
const TOTAL_DURATION_THRESHOLD_IN_MS = 30 * 1000;
const INCLUDE_LAST_N_PERF = 1;
const MINIMUM_SYNC_PERFORMANCES_TO_FLUSH = 5;

@Injectable({
  providedIn: 'root',
})
export class SyncPerformanceStatisticsService implements OnDestroy {
  private static readonly synchronizedStorageAccessQueue: QueueObject<() => Promise<unknown>> = queue(async (task: () => Promise<unknown>, callback: AsyncResultCallback<unknown>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);
  private authenticationSubscription: Subscription;
  protected isAuthenticated: boolean;

  constructor(
    private storage: StorageService,
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private httpClient: HttpClient,
    private networkStatusService: NetworkStatusService,
    private systemEventService: SystemEventService
  ) {
    this.authenticationSubscription = this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.loggingService.debug(LOG_SOURCE, 'authenticationService subscribed.');
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        await this.clearStorageData();
      }
    });
  }

  public async flushToServer() {
    try {
      this.assertAuthenticated();
      if (!this.networkStatusService.online) {
        return;
      }
      const syncPerformances = await this.getDataFromStorage();
      if (syncPerformances.length < MINIMUM_SYNC_PERFORMANCES_TO_FLUSH) {
        return;
      }
      await SyncPerformanceStatisticsService.synchronizedStorageAccessQueue.pushAsync(async () => {
        const body: SyncPerformancesRequest = {syncPerformances};
        await observableToPromise(this.httpClient.post(`${environment.serverUrl}${URL}`, body));
        const dataAfterSend = await this.getDataFromStorage();
        this.assertAuthenticated();
        const sentSyncIds = new Set(syncPerformances.map(({id}) => id));
        await this.storage.set(
          STORAGE_KEY,
          dataAfterSend.filter(({id}) => !sentSyncIds.has(id))
        );
      });
    } catch (e) {
      this.loggingService.error(LOG_SOURCE, `flushToServer: failed to flush ${convertErrorToMessage(e)}`);
      this.systemEventService.logErrorEvent(() => `${LOG_SOURCE}.flushToServer: failed to flush`, e);
    }
  }

  public async addPerformance(
    performanceResult: BasePerformanceMeasurerResult,
    syncConflicts: SyncConflict<IdAware>[],
    {mutationOptions}: {mutationOptions?: Partial<StorageMutationOptions>} = {}
  ): Promise<void> {
    const normalizedDuration = performanceResult.duration / Math.max(1, performanceResult.syncCountByType.PA);
    // If sync was: quick, without conflicts, and without errors; then we can exclude perf listing, as we don't care about them.
    const shouldMinimizePerf =
      normalizedDuration < NORMALIZED_DURATION_THRESHOLD_IN_MS && performanceResult.duration < TOTAL_DURATION_THRESHOLD_IN_MS && syncConflicts.length === 0 && !performanceResult.errored;

    const data = await this.getDataFromStorage();
    this.assertAuthenticated();
    // We want to include N last perf checkpoints, as those include status of finished sync.
    const perf = shouldMinimizePerf ? performanceResult.perf.slice(INCLUDE_LAST_N_PERF * -1) : performanceResult.perf;
    data.push({
      ...performanceResult,
      perf,
      syncConflicts: syncConflicts.map(({localValue, serverValue, ...c}) => ({...c})),
      conflicts: syncConflicts.length > 0,
      unresolvedConflicts: syncConflicts.some((c) => !c.resolved),
    });
    await this.storage.set(STORAGE_KEY, data, mutationOptions);
  }

  private async getDataFromStorage(): Promise<Array<PerformanceMeasurerResult>> {
    return (await this.storage.get(STORAGE_KEY)) || [];
  }

  protected assertAuthenticated() {
    if (!this.isAuthenticated) {
      throw new Error('User not authenticated (auth is null or undefined)');
    }
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  private async clearStorageData() {
    await this.storage.remove(STORAGE_KEY);
  }
}
