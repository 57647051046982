import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import _ from 'lodash';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, map, shareReplay} from 'rxjs/operators';
import {ProjectClientAddress} from 'src/app/model/project-company';
import {ProjectIdWithOffline, ProjectWithOffline, RecentlyUsed} from 'src/app/model/project-with-offline';
import {ProjectNumberPipe} from 'src/app/pipes/project-number.pipe';
import {abortableObservable} from 'src/app/utils/abortable-observable';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {environment} from 'src/environments/environment';
import {
  Attachment,
  AttachmentChat,
  AttachmentProject,
  AttachmentProtocolEntry,
  AttachmentReportActivity,
  AttachmentReportCompany,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  Client,
  ClientType,
  convertOptionalProjectNumberToString,
  IdType,
  Project,
  ProjectStatusEnum,
  SUPERUSER_DEVICE_UUID,
  User,
  UserDeviceOfflineProject,
} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectMetaInfo, ProjectMetaInfoService} from './project-meta-info.service';
import {UserDataService} from '../data/user-data.service';
import {CACHE_STORAGE_SIZE_META_SIZE_FACTOR} from '../../shared/constants';
import {convertISOStringToDate} from '../../utils/date-utils';
import {AuthenticationService} from '../auth/authentication.service';
import {ClientService} from '../client/client.service';
import {AbstractProjectAwareDataService} from '../data/abstract-project-aware-data.service';
import {AddressDataService} from '../data/address-data.service';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {AttachmentProjectDataService} from '../data/attachment-project-data.service';
import {AttachmentReportActivityDataService} from '../data/attachment-report-activity-data.service';
import {AttachmentReportCompanyDataService} from '../data/attachment-report-company-data.service';
import {AttachmentReportEquipmentDataService} from '../data/attachment-report-equipment-data.service';
import {AttachmentReportMaterialDataService} from '../data/attachment-report-material-data.service';
import {ClientDataService} from '../data/client-data.service';
import {CountryDataService} from '../data/country-data.service';
import {DataServiceFactoryService} from '../data/data-service-factory.service';
import {ReportService} from '../report/report.service';
import {Device} from '@capacitor/device';
import {UserDeviceOfflineProjectDataService} from '../data/user-device-offline-project.data.service';
import {ProjectLoaderComponent} from '../../components/common/project-loader/project-loader.component';
import {ModalController} from '@ionic/angular';
import {ProjectAvailabilityExpirationService} from './project-availability-expiration.service';
import {LoggingService} from '../common/logging.service';
import {TranslateService} from '@ngx-translate/core';
import {SyncService} from '../sync/sync.service';
import {ToastService} from '../common/toast.service';
import {DevModeService} from '../common/dev-mode.service';
import {v4 as uuid4} from 'uuid';
import {MakeProjectsOfflineAvailableModalResult, ProjectAvailabilityUtils, ProjectAvailableDefaultOptions, ProjectAvailableOptions} from '../../utils/project-availability-utils';
import {LastUsedProjectService} from '../common/last-used-project.service';
import ProjectForDisplay from '../../model/ProjectForDisplay';
import {getProjectDisplayName, isProjectActive} from '../../utils/project-utils';
import {SyncHistoryService} from '../sync/sync-history.service';
import {NetworkStatusService} from '../common/network-status.service';

const CREATE_PROJECT_PATH = 'api/data/projects';
const LOG_SOURCE = 'ProjectService';

@Injectable({
  providedIn: 'root',
})
export class ProjectService implements OnDestroy {
  projectsForDisplay$: Observable<ProjectForDisplay[]>;
  activeProjectsForDisplay$: Observable<ProjectForDisplay[]>;
  private authenticatedUser: User | undefined;
  private authenticatedUserSubscription: Subscription;
  private projectAvailabilityUtils: ProjectAvailabilityUtils;

  constructor(
    private projectDataService: ProjectDataService,
    private projectMetaInfoService: ProjectMetaInfoService,
    private userDataService: UserDataService,
    private authenticationService: AuthenticationService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private attachmentChatDataService: AttachmentChatDataService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private addressDataService: AddressDataService,
    private clientDataService: ClientDataService,
    private projectNumberPipe: ProjectNumberPipe,
    private countryDataService: CountryDataService,
    private attachmentReportCompanyDataService: AttachmentReportCompanyDataService,
    private attachmentReportActivityDataService: AttachmentReportActivityDataService,
    private attachmentReportMaterialDataService: AttachmentReportMaterialDataService,
    private attachmentReportEquipmentDataService: AttachmentReportEquipmentDataService,
    private clientService: ClientService,
    private reportService: ReportService,
    private userDeviceOfflineProjectDataService: UserDeviceOfflineProjectDataService,
    private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    private lastUsedProjectService: LastUsedProjectService,
    private syncHistoryService: SyncHistoryService,
    private http: HttpClient,
    private modalController: ModalController,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private syncService: SyncService,
    private toastService: ToastService,
    private devModeService: DevModeService,
    private networkStatusService: NetworkStatusService
  ) {
    this.projectAvailabilityUtils = new ProjectAvailabilityUtils(
      this.projectAvailabilityExpirationService,
      this.dataServiceFactoryService,
      this.syncService,
      this.projectDataService,
      this.devModeService,
      this.loggingService,
      this.toastService,
      this.translateService
    );
    const sortByProjectNumberFn = (a: ProjectForDisplay, b: ProjectForDisplay): number => {
      return convertOptionalProjectNumberToString(a.number).localeCompare(convertOptionalProjectNumberToString(b.number), ['de', 'fr', 'en'], {numeric: true});
    };

    const sortByAvailableAndDisplayNameFn = (a: ProjectForDisplay, b: ProjectForDisplay): number => {
      if ((a.isAvailable || a.isOfflineAvailable) === (b.isAvailable || b.isOfflineAvailable)) {
        return a.displayName.localeCompare(b.displayName, ['de', 'fr', 'en']);
      }
      if ((a.isAvailable || a.isOfflineAvailable) && !(b.isAvailable || b.isOfflineAvailable)) {
        return -1;
      }
      return 1;
    };

    this.authenticatedUserSubscription = this.userDataService.currentUser$.subscribe((user) => {
      this.authenticatedUser = user;
    });

    this.projectsForDisplay$ = combineLatest([
      this.projectDataService.dataAcrossClients$,
      this.projectAvailabilityExpirationService.projectIdsWithOfflineAcrossClients$,
      this.lastUsedProjectService.recentlyUsedByProjectId$,
      this.projectMetaInfoService.projectMetaInfosObservable,
      this.clientService.ownClient$,
      this.getProjectClientAddresses(),
    ]).pipe(
      debounceTime(0),
      map(
        ([projects, projectIdsWithOffline, recentlyUsedByProjectId, projectMetaInfos, ownClient, projectClientAddresses]: [
          Project[],
          ProjectIdWithOffline[],
          Record<IdType, RecentlyUsed>,
          Map<string, ProjectMetaInfo>,
          Client,
          ProjectClientAddress[],
        ]) => {
          return projects
            .map((project) => ({
              ...project,
              isOfflineAvailable: isProjectActive(project) && projectIdsWithOffline.some((projectIdWithOffline) => projectIdWithOffline.id === project.id && projectIdWithOffline.isOfflineAvailable),
              isAvailable: isProjectActive(project) && projectIdsWithOffline.some((projectIdWithOffline) => projectIdWithOffline.id === project.id && projectIdWithOffline.isAvailable),
              size:
                (projectMetaInfos.get(project.id)?.attachmentSizeOfflineFromServer !== undefined
                  ? projectMetaInfos.get(project.id)?.attachmentSizeOfflineFromServer
                  : projectMetaInfos.get(project.id)?.attachmentSizeFromServer) * CACHE_STORAGE_SIZE_META_SIZE_FACTOR,
              isConnected: project.clientId !== ownClient?.id,
              clientAddress: projectClientAddresses.find((projectClientAddress) => projectClientAddress.id === project.clientId),
              displayName: getProjectDisplayName(project),
              lastUsed: recentlyUsedByProjectId[project.id]?.lastUsed,
              recentlyUsed: recentlyUsedByProjectId[project.id]?.recentlyUsed,
              veryRecentlyUsed: recentlyUsedByProjectId[project.id]?.veryRecentlyUsed,
            }))
            .sort(sortByProjectNumberFn);
        }
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
    this.activeProjectsForDisplay$ = this.projectsForDisplay$.pipe(map((projects) => projects.filter((project) => isProjectActive(project)).sort(sortByAvailableAndDisplayNameFn)));
  }

  async createProject(project: Omit<Project, 'id' | 'changedAt' | 'createdAt' | 'clientId'>, clientId: IdType): Promise<{projectId: IdType; changedAt: string}> {
    return observableToPromise(this.http.post<{projectId: IdType; changedAt: string}>(`${environment.serverUrl}${CREATE_PROJECT_PATH}?clientId=${clientId}`, project));
  }

  isProjectInConnectedClient$(project: Project): Observable<boolean> {
    return this.clientService.clients$.pipe(map((clients) => clients.find((client) => client.id === project.clientId)?.type === ClientType.CONNECTED ?? true));
  }

  getProjectClientAddresses(): Observable<ProjectClientAddress[]> {
    return combineLatest([this.clientDataService.data, this.addressDataService.dataAcrossClients$, this.countryDataService.dataAcrossClients$]).pipe(
      debounceTime(0),
      map(([clients, addresses, countries]) => {
        return clients.map((client) => {
          const projectClientCompany = client as ProjectClientAddress;
          projectClientCompany.address = client.addressId ? addresses.find((address) => address.id === client.addressId) : undefined;
          projectClientCompany.country = client.countryId ? countries.find((country) => country.id === client.countryId) : undefined;
          return projectClientCompany;
        });
      })
    );
  }

  getById(projectId: IdType): Observable<ProjectForDisplay | undefined> {
    return this.projectsForDisplay$.pipe(map((projects) => projects.find((project) => project.id === projectId)));
  }

  async changeProjectOfflineAvailable(project: Project, offlineAvailableNewValue: boolean) {
    if (!this.authenticatedUser) {
      throw new Error('Authenticated user does not exist.');
    }
    if (!this.authenticatedUser.myProjectsActive) {
      await this.activateMyProjectsActiveForUser(this.authenticatedUser);
    }
    const userOfflineProject = await observableToPromise(this.userDeviceOfflineProjectDataService.getByProjectId(project.id));
    if (offlineAvailableNewValue) {
      if (userOfflineProject) {
        return;
      }
      await this.insertUserOfflineProject(this.authenticatedUser, project);
      const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
      await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(
        project.id,
        activeProjects.map((p) => p.id)
      );
    } else {
      if (!userOfflineProject) {
        return;
      }
      this.loggingService.info(LOG_SOURCE, `changeProjectOfflineAvailable - delete userOfflineProject for project ${userOfflineProject?.projectId} and client ${project?.clientId}`);
      await this.userDeviceOfflineProjectDataService.delete(userOfflineProject, project.clientId);
      const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
      await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(
        project.id,
        activeProjects.map((p) => p.id),
        undefined,
        true
      );
    }
  }

  getEntryAndChatAttachments(): Observable<Array<AttachmentProtocolEntry | AttachmentChat>> {
    function mergeAttachments(attachmentProtocolEntries: Array<AttachmentProtocolEntry>, attachmentChats: Array<AttachmentChat>): Array<AttachmentProtocolEntry | AttachmentChat> {
      return _.orderBy(_.concat(attachmentProtocolEntries, attachmentChats), (attachment) => convertISOStringToDate(attachment.createdAt), 'desc');
    }

    return combineLatest([this.attachmentEntryDataService.data, this.attachmentChatDataService.data])
      .pipe(debounceTime(0))
      .pipe(map(([attachmentProtocolEntries, attachmentChats]) => mergeAttachments(attachmentProtocolEntries, attachmentChats)));
  }

  getProjectRoomAttachmentsInProject(projectId: IdType): Observable<Attachment[]> {
    return this.getProjectRoomAttachments$(
      this.attachmentEntryDataService.getDataForProject$(projectId),
      this.attachmentChatDataService.getDataForProject$(projectId),
      this.attachmentProjectDataService.getDataForProject$(projectId),
      this.attachmentReportCompanyDataService.getDataForProject$(projectId),
      this.attachmentReportActivityDataService.getDataForProject$(projectId),
      this.attachmentReportMaterialDataService.getDataForProject$(projectId),
      this.attachmentReportEquipmentDataService.getDataForProject$(projectId)
    );
  }

  getProjectRoomAttachments(): Observable<Array<Attachment>> {
    return this.getProjectRoomAttachments$();
  }

  getProjectRoomAttachments$(
    attachmentEntryData = this.attachmentEntryDataService.data,
    attachmentChatData = this.attachmentChatDataService.data,
    attachmentProjectData = this.attachmentProjectDataService.data,
    attachmentReportCompanyData = this.attachmentReportCompanyDataService.data,
    attachmentReportActivityData = this.attachmentReportActivityDataService.data,
    attachmentReportMaterialData = this.attachmentReportMaterialDataService.data,
    attachmentReportEquipmentData = this.attachmentReportEquipmentDataService.data
  ): Observable<Array<Attachment>> {
    function mergeAttachments(
      attachmentProtocolEntries: Array<AttachmentProtocolEntry>,
      attachmentChats: Array<AttachmentChat>,
      attachmentProjects: Array<AttachmentProject>,
      attachmentsInReports: Array<AttachmentReportCompany | AttachmentReportActivity | AttachmentReportMaterial | AttachmentReportEquipment>
    ): Array<Attachment> {
      return _.orderBy(
        _.concat(attachmentProtocolEntries, attachmentChats, attachmentProjects, attachmentsInReports),
        (attachment) => (attachment?.originalCreationDate ? new Date(attachment.originalCreationDate) : convertISOStringToDate(attachment.createdAt)),
        'desc'
      );
    }

    return combineLatestAsync([
      attachmentEntryData,
      attachmentChatData,
      attachmentProjectData,
      attachmentReportCompanyData,
      attachmentReportActivityData,
      attachmentReportMaterialData,
      attachmentReportEquipmentData,
      this.reportService.hasPermissionForReport(),
    ]).pipe(
      map(
        ([
          attachmentProtocolEntries,
          attachmentChats,
          attachmentProjects,
          attachmentReportCompanies,
          attachmentReportActivities,
          attachmentReportMaterials,
          attachmentReportEquipments,
          hasPermissionForReport,
        ]: [
          Array<AttachmentProtocolEntry>,
          Array<AttachmentChat>,
          Array<AttachmentProject>,
          Array<AttachmentReportCompany>,
          Array<AttachmentReportActivity>,
          Array<AttachmentReportMaterial>,
          Array<AttachmentReportEquipment>,
          boolean,
        ]) =>
          mergeAttachments(
            attachmentProtocolEntries,
            attachmentChats,
            attachmentProjects,
            hasPermissionForReport ? [...attachmentReportCompanies, ...attachmentReportActivities, ...attachmentReportMaterials, ...attachmentReportEquipments] : []
          )
      )
    );
  }

  ngOnDestroy(): void {
    this.authenticatedUserSubscription.unsubscribe();
  }

  private async insertUserOfflineProject(user: User, project: Project): Promise<UserDeviceOfflineProject> {
    const auth = await observableToPromise(this.authenticationService.data);
    if (auth?.impersonated) {
      this.loggingService.error(LOG_SOURCE, 'Impersonated superusers may not add UserDeviceOfflineProject');
      throw new Error('Impersonated superusers may not add UserDeviceOfflineProject');
    }
    const deviceUuid = auth?.impersonated ? SUPERUSER_DEVICE_UUID : (await Device.getId()).identifier;
    const userDeviceOfflineProject: UserDeviceOfflineProject = {
      id: uuid4(),
      userId: user.id,
      deviceUuid,
      projectId: project.id,
      changedAt: new Date().toISOString(),
    };
    await this.userDeviceOfflineProjectDataService.insert(userDeviceOfflineProject, project.clientId);
    return userDeviceOfflineProject;
  }

  private async activateMyProjectsActiveForUser(user: User) {
    const projects = await observableToPromise(this.projectDataService.dataActive$);
    for (const project of projects) {
      const userOfflineProject = await observableToPromise(this.userDeviceOfflineProjectDataService.getByProjectId(project.id));
      if (!userOfflineProject) {
        await this.insertUserOfflineProject(user, project);
      }
    }
    const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
    await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(
      projects.map((project) => project.id),
      activeProjects.map((p) => p.id)
    );
    user.myProjectsActive = true;
    await this.userDataService.update(user);
  }

  public async insertProjectAndInitializeStorage(project: Project) {
    await this.projectDataService.insert(project, project.clientId);
    for (const key of Object.keys(this.dataServiceFactoryService.projectAwareServices)) {
      const service: AbstractProjectAwareDataService<any> = this.dataServiceFactoryService.projectAwareServices[key];
      await service.setStorageDataPublic([], project.id);
    }
  }

  public searchFilter(projects: ProjectForDisplay[], searchText: string, showArchived?: boolean): ProjectForDisplay[] {
    let filteredProjectList;
    if (_.isEmpty(searchText)) {
      filteredProjectList = projects;
    } else {
      filteredProjectList = projects?.filter((project: ProjectWithOffline) => {
        const projectNumber = this.projectNumberPipe.transform(project.number);
        return _.includes(projectNumber, searchText) || _.includes(project.name?.toLowerCase(), searchText?.toLowerCase());
      });
    }
    filteredProjectList = filteredProjectList?.filter((project: ProjectWithOffline) => {
      if (showArchived === true) {
        return true;
      }
      return project.status !== ProjectStatusEnum.ARCHIVED;
    });

    return filteredProjectList;
  }

  async exportPublicLink(project: Project, abortSignal?: AbortSignal): Promise<string> {
    const isAuthenticated = await observableToPromise(this.authenticationService.isAuthenticated$);
    if (!isAuthenticated) {
      throw new Error('Not authenticated.');
    }
    const projectId = project.id;
    const clientId = project.clientId;
    const url = `${environment.serverUrl}api/data/projects/${projectId}/exportPublicLink?clientId=${clientId}`;
    const publicLink = await observableToPromise(
      abortableObservable(
        this.http.get(url, {
          responseType: 'text',
        }),
        abortSignal
      )
    );

    return `${environment.serverUrl}mediaPublic/exportProject/${publicLink}`;
  }

  public async ensureProjectDataOfflineAvailable(projectIdOrProjectIds: IdType | Array<IdType>, optionsPartial?: Partial<ProjectAvailableOptions>): Promise<MakeProjectsOfflineAvailableModalResult> {
    const options = {...ProjectAvailableDefaultOptions, ...optionsPartial};
    const projectIds = _.isArray(projectIdOrProjectIds) ? projectIdOrProjectIds : [projectIdOrProjectIds];
    const projectIdsStorageNotInitialized = projectIds.filter((projectId) => !this.dataServiceFactoryService.isProjectAwareStorageDataInitialized(projectId));
    const projectIdsInitialized = projectIds.filter((projectId) => !projectIdsStorageNotInitialized.includes(projectId));
    const projectsIdsDataOlderThan = await this.extractProjectsDataOlderThan(projectIdsInitialized, options);
    if (!projectIdsStorageNotInitialized.length && !projectsIdsDataOlderThan.length) {
      return {success: true};
    }

    if (!projectIdsStorageNotInitialized.length && projectsIdsDataOlderThan.length) {
      const isOnline = await this.networkStatusService.isOnline();
      if (!isOnline) {
        return {success: true};
      }
    }

    if (options.useModal) {
      const makeProjectsOfflineAvailableModalResult = await this.makeProjectsAvailableViaModal(projectIdsStorageNotInitialized, options, projectsIdsDataOlderThan);
      if (makeProjectsOfflineAvailableModalResult.success === false && options.throwErrorIfMakingProjectsAvailableFailed) {
        throw new Error(`Making project(s) ${projectIds} offline Available failed.`);
      }
      return makeProjectsOfflineAvailableModalResult;
    }
    return {success: !!(await this.projectAvailabilityUtils.makeProjectsOfflineAvailable(projectIdsStorageNotInitialized, options, projectsIdsDataOlderThan))};
  }

  private async extractProjectsDataOlderThan(projectIdsInitialized: IdType[], options: ProjectAvailableOptions): Promise<IdType[]> {
    const projectsIdsDataOlderThan = new Array<string>();
    if (options.ensureDataNotOlderThanMs && options.ensureDataNotOlderThanMs > 0) {
      const olderThanTimestamp = Date.now() - options.ensureDataNotOlderThanMs;
      for (const projectId of projectIdsInitialized) {
        const syncHistory = await this.syncHistoryService.getSyncHistory(projectId);
        const endServerTime = convertISOStringToDate(syncHistory.endServerTime);
        if (!syncHistory || !endServerTime || endServerTime.getTime() < olderThanTimestamp) {
          projectsIdsDataOlderThan.push(projectId);
        }
      }
    }
    return projectsIdsDataOlderThan;
  }

  private async makeProjectsAvailableViaModal(
    projectIds: IdType[],
    optionsPartial?: Partial<ProjectAvailableOptions>,
    additionalProjectIdsToSync?: IdType[]
  ): Promise<MakeProjectsOfflineAvailableModalResult> {
    const options = {...ProjectAvailableDefaultOptions, ...optionsPartial};
    const modal = await this.modalController.create({
      component: ProjectLoaderComponent,
      cssClass: 'omg omg-modal omg-boundary basic-modal-x-small-high',
      keyboardClose: true,
      backdropDismiss: true,
      componentProps: {
        projectIds,
        additionalProjectIdsToSync,
        temporarily: options.temporarily,
        allowInBackground: options.modalAllowInBackground,
        allowCancel: options.modalAllowCancel,
        confirmWhenCancel: options.modalConfirmWhenCancel,
        showToastWhenDone: options.showToastWhenDone,
        throwErrorIfSyncForEnsureDataNotOlderThanFails: options.throwErrorIfSyncForEnsureDataNotOlderThanFails,
      },
    });
    await modal.present();
    const modalResult = await modal.onDidDismiss();
    if (modalResult.role === 'continueInBackground') {
      if (modalResult.data) {
        return modalResult.data;
      }
      return {success: undefined};
    } else if (modalResult.role === 'cancel') {
      return {success: false};
    } else if (modalResult.role === 'ok') {
      return {success: true};
    }
    throw new Error(`Unexpected modalResult.role "${modalResult.role}" from ProjectLoaderComponent `);
  }

  public async setCurrentProjectId(projectId: IdType, optionsPartial?: Partial<ProjectAvailableOptions>): Promise<boolean> {
    const options = {...ProjectAvailableDefaultOptions, ...optionsPartial};
    const isOfflineAvailableResult = await this.ensureProjectDataOfflineAvailable(projectId, options);
    if (!isOfflineAvailableResult.success) {
      return false;
    }
    await this.projectDataService.setCurrentProjectId(projectId);
    return true;
  }

  public async setCurrentProject(project: Project, optionsPartial?: Partial<ProjectAvailableOptions>): Promise<boolean> {
    const options = {...ProjectAvailableDefaultOptions, ...optionsPartial};
    const isOfflineAvailableResult = await this.ensureProjectDataOfflineAvailable(project.id, options);
    if (!isOfflineAvailableResult.success) {
      return false;
    }
    await this.projectDataService.setCurrentProject(project);
    return true;
  }

  private isProjectRecentlyUsedGroup(projectForDisplay: ProjectWithOffline): boolean {
    return projectForDisplay?.isAvailable || projectForDisplay?.isOfflineAvailable;
  }

  getProjectGroupText = (projectWithOffline: ProjectWithOffline, index: number, projectsWithOffline: ProjectWithOffline[]): string | null => {
    if (index === 0 || this.isProjectRecentlyUsedGroup(projectWithOffline) !== this.isProjectRecentlyUsedGroup(projectsWithOffline[index - 1])) {
      return this.isProjectRecentlyUsedGroup(projectWithOffline) ? this.translateService.instant('recently_used') : this.translateService.instant('unused');
    }
    return null;
  };
}
