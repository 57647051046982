import {Injectable} from '@angular/core';
import {AbstractPdfGlobalSearchCommonService, GlobalSearchPdfProtocolGenerateData, PdfGlobalSearchSendReq, PdfProtocolSendReq, SendPdfProtocolOption} from 'submodules/baumaster-v2-common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';
import mulishJson from '../../../assets/fonts/mulish/mulish.json';
import interJson from '../../../assets/fonts/inter/inter.json';
import {getImageDimension, PdfHelperFunctionsImpl} from './pdf-client-util';
import {LoggingService} from '../common/logging.service';
import {convertErrorToMessage} from 'src/app/shared/errors';

(pdfMake as any).vfs = pdfMakeUnicode.pdfMake.vfs;

const PDF_GENERATION_TIMEOUT_IN_MS = 3 * 60 * 1000;
const LOG_SOURCE = 'PdfGlobalSearchCommonService';

@Injectable({
  providedIn: 'root',
})
export class PdfGlobalSearchCommonService extends AbstractPdfGlobalSearchCommonService {
  constructor(private loggingService: LoggingService) {
    super();
  }

  public async generatePdf(pdfGlobalSearchSendReq: PdfGlobalSearchSendReq, data: GlobalSearchPdfProtocolGenerateData, abortSignal?: AbortSignal): Promise<Blob> {
    // TODO find a solution to handle errors. getBlob just never returns that's why callbacks are here.
    const pdfGenerationPromise = new Promise<Blob>(async (resolve, reject) => {
      try {
        const pdf = await this.createPdf(pdfGlobalSearchSendReq, data);
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      } catch (e) {
        reject(e);
      }
    });

    const promises: Array<Promise<Blob>> = [pdfGenerationPromise, new Promise<Blob>((_, reject) => setTimeout(() => reject(new Error('Generating PDF timed out.')), PDF_GENERATION_TIMEOUT_IN_MS))];
    if (abortSignal) {
      const abortSignalPromise = new Promise<Blob>((_, reject) => {
        abortSignal.onabort = () => {
          reject(new Error('generatePdf aborted'));
        };
      });
      promises.push(abortSignalPromise);
    }

    return await Promise.race(promises);
  }

  public async openPdf(pdfGlobalSearchSendReq: PdfGlobalSearchSendReq, data: GlobalSearchPdfProtocolGenerateData) {
    const pdf = await this.createPdf(pdfGlobalSearchSendReq, data);
    pdf.open();
  }

  private async createPdf(pdfGlobalSearchSendReq: PdfGlobalSearchSendReq, data: GlobalSearchPdfProtocolGenerateData): Promise<pdfMake.TCreatedPdf> {
    const pdfProtocolSendReq: PdfProtocolSendReq = {
      sendPdfProtocolAdditionalInfo: pdfGlobalSearchSendReq.sendPdfProtocolAdditionalInfo,
      sendPdfProtocolOption: SendPdfProtocolOption.SEND_ONLY,
      pdfProtocolSetting: pdfGlobalSearchSendReq.pdfProtocolSetting,
    };
    const imgSrc = data.attachmentClients?.get('pdfEndBanner')?.contentBase64;
    let footerDimension = {width: 1, height: 1};
    if (imgSrc) {
      try {
        footerDimension = await getImageDimension(imgSrc);
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `getImageDimension failed ${convertErrorToMessage(error)}`);
      }
    }
    const imgSrcHeader = data.attachmentClients?.get('pdfStartBanner')?.contentBase64;
    let headerDimension = {width: 1, height: 1};
    if (imgSrcHeader) {
      try {
        headerDimension = await getImageDimension(imgSrc);
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `getImageDimension failed ${convertErrorToMessage(error)}`);
      }
    }
    return await pdfMake.createPdf(
      this.getDocDefinition(pdfProtocolSendReq, data, PdfHelperFunctionsImpl, [footerDimension.width, footerDimension.height, headerDimension.width, headerDimension.height]),
      null,
      {
        Mulish: {
          normal: 'Mulish-Regular.ttf',
          bold: 'Mulish-Bold.ttf',
          italics: 'Mulish-Italic.ttf',
          bolditalics: 'Mulish-BoldItalic.ttf',
        },
        Inter: {
          normal: 'Inter-Regular.ttf',
          bold: 'Inter-Bold.ttf',
          italics: 'Inter-Italic.ttf',
          bolditalics: 'Inter-BoldItalic.ttf',
        },
      },
      {
        'Mulish-Regular.ttf': mulishJson.MulishRegular,
        'Mulish-Bold.ttf': mulishJson.MulishBold,
        'Mulish-Italic.ttf': mulishJson.MulishItalic,
        'Mulish-BoldItalic.ttf': mulishJson.MulishBoldItalic,
        'Inter-Regular.ttf': interJson.InterRegular,
        'Inter-Bold.ttf': interJson.InterBold,
        'Inter-Italic.ttf': interJson.InterItalic,
        'Inter-BoldItalic.ttf': interJson.InterBoldItalic,
      }
    );
  }
}
