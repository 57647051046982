import {ColumnConfig} from '../ColumnConfig';

export const MailHistoryColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'sender_email', prop: 'senderEmail'},
  {name: 'mail_type', prop: 'mailType'},
  {name: 'subject', prop: 'subject'},
  {name: 'body', prop: 'body'},
  {name: 'project_id', prop: 'projectId', def: null},
  {name: 'client_id', prop: 'clientId'},
  {name: 'protocol_id', prop: 'protocolId', def: null},
  {name: 'report_id', prop: 'reportId', def: null},
  {name: 'report_ids', prop: 'reportIds', def: null},
  {name: 'receiver_profile_id', prop: 'receiverProfileId', def: null},
  {name: 'receiver_email', prop: 'receiverEmail'},
  {name: 'distribution_id', prop: 'distributionId', def: null},
];
