import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {Observable, Subscription} from 'rxjs';
import {ClientService} from 'src/app/services/client/client.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {CountryDataService} from 'src/app/services/data/country-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {EMAIL_REG_EXP_PATTERN} from 'src/app/shared/constants';
import {observableToPromise} from 'src/app/utils/async-utils';
import {environment} from 'src/environments/environment';
import {Address, Client, Company, Country, HostedPageRequest, LicenseProduct, ZohoAddonCode} from 'submodules/baumaster-v2-common/';
import {BuyingWorkflowCanduComponent} from './buying-workflow-candu/buying-workflow-candu.component';
import _ from 'lodash';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {switchMap, take, tap} from 'rxjs/operators';
import {LoggingService} from 'src/app/services/common/logging.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {IonicSelectableComponent} from 'ionic-selectable';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';

const LOG_SOURCE = 'BuyingWorkflowComponent';

enum BuyingWorkflowStep {
  LICENSE_PAGE = 'LICENSE_PAGE',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  ADDRESS_DETAILS = 'ADDRESS_DETAILS',
}

interface BuyingWorkflowStepConfig {
  currentStep: BuyingWorkflowStep;
  previousStep: BuyingWorkflowStep | null;
  nextStep: BuyingWorkflowStep | null;
}

@Component({
  selector: 'app-buying-workflow',
  templateUrl: './buying-workflow.component.html',
  styleUrls: ['./buying-workflow.component.scss'],
})
export class BuyingWorkflowComponent implements OnInit, OnDestroy {
  public readonly emailRegExpPattern = EMAIL_REG_EXP_PATTERN;

  modal: HTMLIonModalElement;
  workflowStepConfigs: Array<BuyingWorkflowStepConfig>;
  currentWorkflowStepConfig: BuyingWorkflowStepConfig;
  currentStep: BuyingWorkflowStep;
  termsYearly = true;
  zohoProducts: Record<string, LicenseProduct>;
  contactDetailsForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.maxLength(50)]],
    lastName: ['', [Validators.required, Validators.maxLength(50)]],
    email: ['', [Validators.required, Validators.maxLength(254), Validators.pattern(this.emailRegExpPattern)]],
    phone: ['', [Validators.required, Validators.maxLength(25)]],
    companyName: ['', [Validators.required, Validators.maxLength(101)]],
    uidNumber: ['', Validators.maxLength(35)],
  });
  addressDetailsForm = this.fb.group({
    street: ['', [Validators.required, Validators.maxLength(60)]],
    number: ['', [Validators.required, Validators.maxLength(60)]],
    zip: ['', [Validators.required, Validators.maxLength(10)]],
    city: ['', [Validators.required, Validators.maxLength(60)]],
    country: [null as Country | null, Validators.required],
  });
  licenseDetailsForm = this.fb.group(
    {
      amountSmart: [null as number | null, Validators.pattern(/^\d+$/)],
      amountExpert: [null as number | null, Validators.pattern(/^\d+$/)],
      amountReport: [null as number | null, Validators.pattern(/^\d+$/)],
      couponCode: [''],
    },
    {validators: this.atLeastOneRequiredValidator(['amountSmart', 'amountExpert'])}
  );

  countries$: Observable<Country[]>;
  preSelectedCountry: Country | undefined;
  preparingHostedPage = false;
  checkingVat = false;
  discount: number | undefined;

  private userAddressSubscription: Subscription | undefined;
  private companySubscription: Subscription | undefined;
  private companyAddressSubscription: Subscription | undefined;
  private countrySubscription: Subscription | undefined;
  private client: Client;
  private userAddress: Address | undefined;
  private company: Company | undefined;
  private companyAddress: Address | undefined;
  private validCoupon: boolean;
  private validVat: boolean;
  private vatVerified = true;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  get currentStepIndex() {
    return Math.max(this.workflowStepConfigs?.indexOf(this.currentWorkflowStepConfig) ?? 0, 0);
  }

  get isAmountEntered() {
    const rawData = this.licenseDetailsForm.getRawValue();
    return rawData.amountSmart || rawData.amountExpert;
  }

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private addressDataService: AddressDataService,
    private clientService: ClientService,
    private userProfileService: UserProfileService,
    private alertService: AlertService,
    private countryDataService: CountryDataService,
    private http: HttpClient,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private posthogService: PosthogService,
    private selectableUtilService: SelectableUtilService
  ) {}

  async ngOnInit() {
    this.workflowStepConfigs = this.getWorkflowConfigs();
    this.currentStep = BuyingWorkflowStep.LICENSE_PAGE;
    this.currentWorkflowStepConfig = this.getCurrentWorkflowConfig();
    this.client = this.clientService.getCurrentClient();

    this.userAddressSubscription = this.userProfileService.currentUserOwnAddress$.subscribe((addressUser) => {
      this.userAddress = addressUser;
    });
    this.companySubscription = this.userProfileService.currentUserOwnCompany$
      .pipe(
        tap((company) => (this.company = company)),
        switchMap((company) => this.addressDataService.getById(company.addressId))
      )
      .subscribe((address) => {
        this.companyAddress = address;
      });

    try {
      this.zohoProducts = _.keyBy(await observableToPromise(this.http.get<LicenseProduct[]>(environment.serverUrl + 'purchase/getAllProducts/')), 'code');
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnInit', error?.userMessage + '-' + convertErrorToMessage(error));
      this.loggingService.error(LOG_SOURCE, `Error fetching products "${error?.userMessage}" - "${error?.message}"`);
    }

    if (_.isEmpty(this.zohoProducts)) {
      await this.modal.dismiss();
      await this.alertService.ok({
        header: 'buyingWorkflow.errorProductsHeader',
        message: 'buyingWorkflow.errorProductsMessage',
        confirmLabel: 'understood',
      });
    }

    this.discount = this.calculateDiscountYearlyTerms();

    this.countries$ = this.countryDataService.dataSorted$;

    this.countrySubscription = this.countries$.pipe(take(1)).subscribe((countries) => {
      this.preSelectedCountry = countries.find((country) => country.name.toLowerCase() === this.companyAddress.country.toLowerCase());
      if (this.preSelectedCountry && !this.addressDetailsForm.controls.country.dirty) {
        this.addressDetailsForm.controls.country.setValue(this.preSelectedCountry);
      }
    });

    this.contactDetailsForm.patchValue(this.getPatchValueContact());
    this.addressDetailsForm.patchValue(this.getPatchValueAddress());
  }

  ngOnDestroy(): void {
    this.userAddressSubscription?.unsubscribe();
    this.userAddressSubscription = undefined;
    this.companyAddressSubscription?.unsubscribe();
    this.companyAddressSubscription = undefined;
    this.companySubscription?.unsubscribe();
    this.companySubscription = undefined;
    this.countrySubscription?.unsubscribe();
    this.countrySubscription = undefined;
  }

  private getWorkflowConfigs(): Array<BuyingWorkflowStepConfig> {
    return [
      {
        currentStep: BuyingWorkflowStep.LICENSE_PAGE,
        previousStep: null,
        nextStep: BuyingWorkflowStep.CONTACT_DETAILS,
      },
      {
        currentStep: BuyingWorkflowStep.CONTACT_DETAILS,
        previousStep: BuyingWorkflowStep.LICENSE_PAGE,
        nextStep: BuyingWorkflowStep.ADDRESS_DETAILS,
      },
      {
        currentStep: BuyingWorkflowStep.ADDRESS_DETAILS,
        previousStep: BuyingWorkflowStep.CONTACT_DETAILS,
        nextStep: null,
      },
    ];
  }

  private getPatchValueContact() {
    return {
      firstName: this.userAddress.firstName ?? '',
      lastName: this.userAddress.lastName ?? '',
      email: this.userAddress.email ?? '',
      phone: this.userAddress.mobile ?? this.userAddress.phone ?? '',
      companyName: this.company.name,
      uidNumber: this.client.uid ?? '',
    };
  }

  private getPatchValueAddress() {
    return {
      street: this.companyAddress.street1 ?? '',
      number: this.companyAddress.street2 ?? '',
      zip: this.companyAddress.zipCode ?? '',
      city: this.companyAddress.city ?? '',
    };
  }

  getCurrentWorkflowConfig(): BuyingWorkflowStepConfig {
    const workflowConfig = this.workflowStepConfigs.find((config) => config.currentStep === this.currentStep);
    if (!workflowConfig) {
      throw new Error('WorkflowConfig not found for the current workflow step ' + this.currentStep);
    }
    return workflowConfig;
  }

  back() {
    const workflowConfig = this.getCurrentWorkflowConfig();
    this.currentStep = workflowConfig.previousStep;
    this.currentWorkflowStepConfig = this.getCurrentWorkflowConfig();
  }

  async next() {
    const workflowConfig = this.getCurrentWorkflowConfig();
    if (workflowConfig.nextStep === BuyingWorkflowStep.CONTACT_DETAILS && !_.isEmpty(this.licenseDetailsForm.getRawValue().couponCode) && this.termsYearly) {
      this.validCoupon = await this.checkCouponValid();
      if (!this.validCoupon) {
        await this.alertService.ok({
          header: 'buyingWorkflow.couponErrorHeader',
          message: 'buyingWorkflow.couponErrorMessage',
          confirmLabel: 'understood',
        });
        return;
      }
    }
    if (workflowConfig.nextStep === BuyingWorkflowStep.ADDRESS_DETAILS && !_.isEmpty(this.contactDetailsForm.getRawValue().uidNumber)) {
      this.checkingVat = true;
      this.validVat = await this.checkVatValid();
      this.checkingVat = false;
      if (!this.validVat) {
        const confirm = await this.alertService.confirm({
          header: 'buyingWorkflow.vatErrorHeader',
          message: 'buyingWorkflow.vatErrorMessage',
          confirmLabel: 'next',
          cancelLabel: 'cancel',
          confirmButton: {
            color: 'text-primary',
            fill: 'solid',
          },
        });
        if (!confirm) {
          return;
        } else {
          this.vatVerified = false;
        }
      }
    }
    this.currentStep = workflowConfig.nextStep;
    this.currentWorkflowStepConfig = this.getCurrentWorkflowConfig();
  }

  async dismissModal() {
    if (this.contactDetailsForm.dirty || this.addressDetailsForm.dirty || this.licenseDetailsForm.dirty) {
      const confirm = await this.alertService.confirm({
        header: 'buyingWorkflow.dataLossHeader',
        message: 'buyingWorkflow.dataLossMessage',
      });
      if (confirm) {
        this.sendPosthogEvent('[Buying] Left Buying Workflow ' + this.currentStep);
        await this.modal.dismiss();
      }
    } else {
      this.sendPosthogEvent('[Buying] Left Buying Workflow ' + this.currentStep);
      await this.modal.dismiss();
    }
  }

  sendPosthogEvent(eventName: string) {
    this.posthogService.captureEvent(eventName, {});
  }

  onTermsChange(terms: boolean) {
    this.termsYearly = terms;
  }

  private calculateDiscountYearlyTerms() {
    const discount =
      Math.floor(
        Math.min(
          (1 - this.zohoProducts[ZohoAddonCode.EXPERT_ANNUAL].price / this.zohoProducts[ZohoAddonCode.EXPERT_MONTHLY].price) * 100,
          (1 - this.zohoProducts[ZohoAddonCode.SMART_ANNUAL].price / this.zohoProducts[ZohoAddonCode.SMART_MONTHLY].price) * 100,
          (1 - this.zohoProducts[ZohoAddonCode.REPORT_ANNUAL].price / this.zohoProducts[ZohoAddonCode.REPORT_MONTHLY].price) * 100
        ) / 5
      ) * 5;
    return discount;
  }

  private atLeastOneRequiredValidator(controlNames: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const values = controlNames.map((name) => control.get(name)?.value);
      const hasValue = values.some((value) => value !== null && value > 0);

      return hasValue ? null : {atLeastOneInvalid: true};
    };
  }

  async openCanduModal() {
    const modal = await this.modalController.create({
      component: BuyingWorkflowCanduComponent,
      backdropDismiss: true,
      cssClass: 'candu-modal',
      componentProps: {
        annual: this.termsYearly,
      },
    });
    await modal.present();
  }

  async getHostedPage() {
    try {
      this.sendPosthogEvent('[Buying] Clicked to Payment Page');
      this.preparingHostedPage = true;
      const hostedPageUrl = await observableToPromise(this.http.post<{hostedPageLink: string}>(environment.serverUrl + `purchase/${this.client.id}/createHostedPage/`, this.getHostedPageRequest()));
      if (hostedPageUrl.hostedPageLink) {
        window.location.href = hostedPageUrl.hostedPageLink;
        await this.modal.dismiss();
      } else {
        await this.alertService.ok({
          header: 'buyingWorkflow.errorHostedPageHeader',
          message: 'buyingWorkflow.errorHostedPageMessage',
          confirmLabel: 'understood',
        });
      }
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - getHostedPage', error?.userMessage + '-' + convertErrorToMessage(error));
      await this.alertService.ok({
        header: 'buyingWorkflow.errorHostedPageHeader',
        message: 'buyingWorkflow.errorHostedPageMessage',
        confirmLabel: 'understood',
      });
    } finally {
      this.preparingHostedPage = false;
    }
  }

  private async checkCouponValid() {
    try {
      const valid = await observableToPromise(this.http.post<{valid: boolean}>(environment.serverUrl + 'purchase/checkCouponValid/', {code: this.licenseDetailsForm.getRawValue().couponCode}));
      return valid.valid;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error checking Coupon Code ${this.licenseDetailsForm.getRawValue().couponCode}. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - checkCouponValid', error?.userMessage + '-' + convertErrorToMessage(error));
      return false;
    }
  }

  private async checkVatValid() {
    try {
      const result = await observableToPromise(
        this.http.post<{valid: boolean}>(environment.serverUrl + 'purchase/checkVatValid/', {vat: this.contactDetailsForm.getRawValue().uidNumber.replace(/\s/g, '')})
      );
      return result.valid;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error checking VAT number ${this.contactDetailsForm.getRawValue().uidNumber}. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - checkVatValid', error?.userMessage + '-' + convertErrorToMessage(error));
      return false;
    }
  }

  private getHostedPageRequest(): HostedPageRequest {
    const dataLicense = this.licenseDetailsForm.getRawValue();
    const dataContact = this.contactDetailsForm.getRawValue();
    const dataAddress = this.addressDetailsForm.getRawValue();

    return {
      customerNumber: this.client.customerNumber,
      termsYearly: this.termsYearly,
      vatVerified: this.vatVerified,
      contact: {
        firstName: dataContact.firstName,
        lastName: dataContact.lastName,
        email: dataContact.email,
        phone: dataContact.phone,
        companyName: dataContact.companyName,
        uidNumber: dataContact.uidNumber,
      },
      address: {
        street: dataAddress.street,
        number: dataAddress.number,
        zip: dataAddress.zip,
        city: dataAddress.city,
        country: dataAddress.country,
      },
      license: {
        amountSmart: dataLicense.amountSmart,
        amountExpert: dataLicense.amountExpert,
        amountReport: dataLicense.amountReport,
        couponCode: this.termsYearly ? dataLicense.couponCode : '',
      },
    };
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
