import {Injectable, OnDestroy} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {LoggingService} from '../common/logging.service';
import {TranslateService} from '@ngx-translate/core';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {observableToPromise, startWorker} from '../../utils/async-utils';
import {AuthenticationService} from '../auth/authentication.service';
import {BehaviorSubject, from, Subscription} from 'rxjs';
import {
  Attachment,
  ATTACHMENT_SIZE_IN_BYTES_PROBABLY_CORRUPT_FILE_WITH_DEVIATION,
  AttachmentProtocolEntry,
  Client,
  ClientAwareKey,
  ClientAwareSyncResponse,
  ClientType,
  equalWithDeviation,
  ErrorCodeType,
  ErrorResponse,
  IdAware,
  IdType,
  LicenseType,
  NonClientAwareKey,
  NonClientAwareSyncResponse,
  NotChanged,
  Project,
  ProjectAwareKey,
  ProjectAwareSyncResponse,
  ProjectStatusEnum,
  Protocol,
  ProtocolEntry,
  SUPERUSER_DEVICE_UUID,
  SyncResponse,
  User,
  UserDeviceOfflineProject,
} from 'submodules/baumaster-v2-common';
import {environment} from '../../../environments/environment';
import {
  DataSyncContext,
  LocalProjectSyncRequest,
  LocalSyncClientAwareResponse,
  LocalSyncNonClientAwareResponse,
  LocalSyncProjectAwareResponse,
  LocalSyncResponse,
  LocalSyncToServerResponse,
  SyncHistory,
  SyncToServerHistory,
} from '../../model/sync-models';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {v4 as uuid4} from 'uuid';
import {AbstractDataService} from '../data/abstract-data.service';
import {ConflictType, SyncConflict, syncDataArray, SyncStrategy, SyncUtilRequest, SyncUtilResponse} from './sync-utils';
import {filter, mergeMap} from 'rxjs/operators';
import {
  AbstractFileAccessUtil,
  AttachmentSyncMode,
  DataSyncMode,
  deleteAttachmentsFromCache,
  isAttachmentScheduledForUpload,
  isQuotaExceededError,
  scheduleUploadMediaFile,
  syncAttachmentsConcurrently,
  uploadFilesFromMediaQueue,
  UploadFilesResult,
} from '../../utils/attachment-utils';
import {SyncStatusService} from './sync-status.service';
import {SyncHistoryService} from './sync-history.service';
import {AbstractClientAwareDataService} from '../data/abstract-client-aware-data.service';
import {AbstractProjectAwareDataService} from '../data/abstract-project-aware-data.service';
import {SyncConflictsComponent} from '../../components/sync/sync-conflicts/sync-conflicts.component';
import {LocalChange, LocalChangesData} from '../../model/local-changes';
import _ from 'lodash';
import {DataServiceFactoryService} from '../data/data-service-factory.service';
import {ProjectDataService} from '../data/project-data.service';
import {NetworkStatusService} from '../common/network-status.service';
import {AttachmentProjectDataService} from '../data/attachment-project-data.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';
import {Insomnia} from '@awesome-cordova-plugins/insomnia/ngx';
import {convertErrorToMessage, NetworkError} from '../../shared/errors';
import {ProjectMetaInfo, ProjectMetaInfoService} from '../project/project-meta-info.service';
import {PdfPlanAttachmentDataService} from '../data/pdf-plan-attachment-data.service';
import {AttachmentProjectImageDataService} from '../data/attachment-project-image-data.service';
import {AttachmentClientDataService} from '../data/attachment-client-data.service';
import {SystemEventService} from '../event/system-event.service';
import async, {AsyncResultCallback, QueueObject} from 'async';
import {AbstractNonClientAwareDataService} from '../data/abstract-non-client-aware-data.service';
import {LicenseService} from '../auth/license.service';
import {AttachmentReportActivityDataService} from '../data/attachment-report-activity-data.service';
import {AttachmentReportMaterialDataService} from '../data/attachment-report-material-data.service';
import {AttachmentReportEquipmentDataService} from '../data/attachment-report-equipment-data.service';
import {StorageService} from '../storage.service';
import {AttachmentReportCompanyDataService} from '../data/attachment-report-company-data.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {AttachmentService} from '../attachment/attachment.service';
import {AttachmentReportSignatureDataService} from '../data/attachment-report-signature-data.service';
import {AbstractProjectAwareAttachmentDataService} from '../data/abstract-project-aware-attachment-data.service';
import {AttachmentProtocolSignatureDataService} from '../data/attachment-protocol-signature-data.service';
import {DevModeService} from '../common/dev-mode.service';
import {AttachmentUserEmailSignatureDataService} from '../data/attachment-user-email-signature-data.service';
import {ToastService} from '../common/toast.service';
import {Device} from '@capacitor/device';
import {AuthDetails} from 'src/app/model/auth';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {UserDeviceOfflineProjectDataService} from '../data/user-device-offline-project.data.service';
import {PosthogService} from '../posthog/posthog.service';
import {TokenManagerService} from '../auth/token-manager.service';
import {AttachmentBimMarkerScreenshotDataService} from '../data/attachment-bim-marker-screenshot-data.service';
import {AttachmentProjectBannerDataService} from '../data/attachment-project-banner-data.service';
import {DOWNLOADED_PROJECT_EXPIRATION_IN_MS} from '../../shared/constants';
import {getPerformanceMeasurer} from './utils/performance-utils';
import {SyncPerformanceStatisticsService} from './sync-performance-statistic.service';

const LOG_SOURCE = 'SyncService';
const PROJECTS_CONCURRENT_SYNC = 10;
const SYNC_DATA_AS_WEB_WORKER = false; // Using a WebWorker has some overhead and is slower in total. We need to use a WebWorker cache if we want to use this feature.
const TOAST_DURATION_INFO_IN_MS = 3000;
const TOAST_DURATION_WARNING_IN_MS = 10000;
const TOAST_DURATION_INFO_DETAIL_BUTTON_IN_MS = 15000;
const TOAST_DURATION_ERROR_IN_MS = 25000;
const RECENTLY_USED_LIMIT_IN_DAYS = 30;
const RECENTLY_USED_LIMIT_IN_MS = RECENTLY_USED_LIMIT_IN_DAYS * 24 * 60 * 60 * 1000;
const SYSTEM_EVENT_MAX_LENGTH = 2048;

const filterClosedProtocolEntry = (protocols: Array<Protocol>, protocolEntries: Array<ProtocolEntry>): ((attachmentProtocolEntry: AttachmentProtocolEntry) => boolean) => {
  const protocolsById = _.keyBy(protocols, 'id') as {[key in IdType]: Protocol};
  const protocolEntriesById = _.keyBy(protocolEntries, 'id') as {[key in IdType]: ProtocolEntry};
  return (attachmentProtocolEntry: AttachmentProtocolEntry) => {
    const protocolEntry = protocolEntriesById[attachmentProtocolEntry?.protocolEntryId];
    const protocol = protocolsById[protocolEntry?.protocolId];
    return !!protocol?.closedAt;
  };
};

const emptyLocalChangesData: LocalChangesData<any> = {
  insert: [],
  update: [],
  delete: [],
  localChangesInsertById: new Map(),
  localChangesUpdateById: new Map(),
  localChangesDeleteById: new Map(),
};

export interface SyncOptions {
  showInfoToastMessages?: boolean;
  syncWithoutSince?: boolean;
  clearLocalChanges?: boolean;
  ignoreAttachmentVersion?: boolean;
  additionalProjectIdsToSync?: Array<IdType>;
  doNotWaitForAttachmentSync?: boolean;
  forceReadingAttachmentFileInfo?: boolean;
}

interface SyncCall {
  syncStrategy: SyncStrategy;
  options?: SyncOptions;
  promise: Promise<DataSyncResultStatus>;
  resolvePromiseFunc: (dataSyncResultStatus: DataSyncResultStatus) => void;
  rejectPromiseFunc: (error: any) => void;
}

export type DataSyncResultStatus = 'ALREADY_IN_PROGRESS' | 'NO_NETWORK' | 'FINISHED_NOTHING_DONE' | 'FINISHED_WITH_ERRORS' | 'FINISHED';

export interface DataSyncResult {
  syncStarted: Date;
  syncFinished: Date;
  status: DataSyncResultStatus;
  result?: {nonClientAwareResult: LocalSyncNonClientAwareResponse<any>; clientAwareResults: Array<LocalSyncClientAwareResponse<any>>; projectAwareResults: Array<LocalSyncProjectAwareResponse<any>>};
}

@Injectable({
  providedIn: 'root',
})
export class SyncService implements OnDestroy {
  private static readonly synchronizedSyncQueue: QueueObject<() => Promise<any>> = async.queue<any>(async (task: () => Promise<any>, callback: AsyncResultCallback<any>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);

  private authenticationSubscription: Subscription;
  private auth: AuthDetails | null;
  private licenseSubscription: Subscription;
  private currentUserLicenseType: LicenseType | null;
  private readonly urlNonClientAware = environment.serverUrl + 'api/sync/nonClientAware';
  private readonly urlClientAware = environment.serverUrl + 'api/sync/clientAware';
  private readonly urlProjectAware = environment.serverUrl + 'api/sync/projectAware';
  private readonly mediaUrl = environment.serverUrl + 'media';
  private readonly nonClientAwareServices: {[key in NonClientAwareKey]: AbstractNonClientAwareDataService<IdAware>};
  private readonly clientAwareServices: {[key in ClientAwareKey]: AbstractClientAwareDataService<IdAware>};
  private readonly projectAwareServices: {[key in ProjectAwareKey]: AbstractProjectAwareDataService<IdAware>};
  private nextScheduledSync: SyncCall | undefined;
  private readonly allServices: {[key in ProjectAwareKey | ClientAwareKey]: AbstractDataService<IdAware>};
  private additionalActiveProjectIdsToSyncSubject = new BehaviorSubject<Array<IdType>>([]);

  private static async runSynchronized<R>(functionToCall: () => Promise<R>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      SyncService.synchronizedSyncQueue.push<R>(functionToCall, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  }

  get additionalActiveProjectIdsToSync(): Array<IdType> {
    return this.additionalActiveProjectIdsToSyncSubject.value;
  }

  set additionalActiveProjectIdsToSync(additionalActiveProjectIdsToSync: Array<IdType>) {
    this.loggingService.debug(LOG_SOURCE, `additionalActiveProjectIdsToSync - length=${additionalActiveProjectIdsToSync?.length}`);
    this.additionalActiveProjectIdsToSyncSubject.next(additionalActiveProjectIdsToSync?.length ? additionalActiveProjectIdsToSync : []);
  }

  constructor(
    private authenticationService: AuthenticationService,
    private storage: StorageService,
    private http: HttpClient,
    private platform: Platform,
    private loggingService: LoggingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private networkStatusService: NetworkStatusService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private attachmentChatDataService: AttachmentChatDataService,
    private attachmentReportCompanyDataService: AttachmentReportCompanyDataService,
    private attachmentReportActivityDataService: AttachmentReportActivityDataService,
    private attachmentReportMaterialDataService: AttachmentReportMaterialDataService,
    private attachmentReportEquipmentDataService: AttachmentReportEquipmentDataService,
    private attachmentReportSignatureDataService: AttachmentReportSignatureDataService,
    private attachmentProtocolSignatureDataService: AttachmentProtocolSignatureDataService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private attachmentProjectBannerDataService: AttachmentProjectBannerDataService,
    private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private syncStatusService: SyncStatusService,
    private syncHistoryService: SyncHistoryService,
    private projectDataService: ProjectDataService,
    private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    private insomnia: Insomnia,
    private systemEventService: SystemEventService,
    private projectMetaInfoService: ProjectMetaInfoService,
    private attachmentProjectImageDataService: AttachmentProjectImageDataService,
    private attachmentClientDataService: AttachmentClientDataService,
    private licenseService: LicenseService,
    private attachmentSettingService: AttachmentSettingService,
    private attachmentService: AttachmentService,
    private devModeService: DevModeService,
    private attachmentUserEmailSignatureDataService: AttachmentUserEmailSignatureDataService,
    private tokenManagerService: TokenManagerService,
    private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
    private userDeviceOfflineProjectDataService: UserDeviceOfflineProjectDataService,
    private posthogService: PosthogService,
    private syncPerformanceStatisticsService: SyncPerformanceStatisticsService
  ) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called');
    this.authenticationSubscription = this.authenticationService.data.subscribe((auth) => {
      this.loggingService.debug(LOG_SOURCE, 'authenticationService subscribed.');
      this.auth = auth;
    });
    this.licenseSubscription = this.licenseService.currentUserLicense$.subscribe((licenseType) => {
      this.loggingService.debug(LOG_SOURCE, 'authenticationService subscribed.');
      this.currentUserLicenseType = licenseType;
    });
    this.nonClientAwareServices = dataServiceFactoryService.nonClientAwareServices;
    this.clientAwareServices = dataServiceFactoryService.clientAwareServices;
    this.projectAwareServices = dataServiceFactoryService.projectAwareServices;
    this.allServices = dataServiceFactoryService.allServices;
    this.syncStatusService.dataSyncInProgressObservable.pipe(filter((dataSyncInProgress) => !dataSyncInProgress.inProgress)).subscribe((dataSyncInProgress) => {
      this.dataSyncFinished();
    });
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
    this.licenseSubscription.unsubscribe();
  }

  private shouldFireErrorToast(error: unknown): boolean {
    const deviceLimitReached = error instanceof HttpErrorResponse && error.status === 412;
    if (deviceLimitReached) {
      return false;
    }
    const unauthorized = error instanceof HttpErrorResponse && error.status === 401;
    return !unauthorized;
  }

  private async toastMessage(message: string, duration = TOAST_DURATION_INFO_IN_MS) {
    await this.toastService.toast(message, duration);
  }

  private async toastMessageWithButton(message: string, buttonLabelKey: string, buttonClickCallback: () => void, duration = TOAST_DURATION_INFO_IN_MS) {
    await this.toastService.infoWithMessageAndButtons(message, [
      {
        side: 'end',
        text: this.translateService.instant(buttonLabelKey),
        handler: buttonClickCallback,
      },
    ]);
  }

  private getSinceParameter(
    clientOrProjectId: IdType | undefined,
    latestSyncHistory: SyncHistory | null | undefined,
    latestSyncToServerHistory: SyncToServerHistory | null | undefined,
    hasLocalChanges: boolean
  ): string | undefined {
    if (!latestSyncHistory || !latestSyncHistory?.startServerTime) {
      this.systemEventService.logEvent(
        LOG_SOURCE + ' getSinceParameter',
        () => `return undefined for since for nonClientAware/client/project ${clientOrProjectId}- no latestSyncHistory or startServerTime`
      );
      return undefined;
    }
    if (latestSyncToServerHistory && !latestSyncToServerHistory.success && latestSyncToServerHistory.errorResponse?.errorCode === 'SYNC_EMPTY_RESULT' && hasLocalChanges) {
      this.systemEventService.logEvent(
        LOG_SOURCE + ' getSinceParameter',
        () => `Doing a full sync for clientId ${latestSyncToServerHistory.clientId}/projectId ${latestSyncToServerHistory.projectId} because latestSyncToServer failed`
      );
      this.loggingService.warn(LOG_SOURCE, `Doing a full sync for clientId ${latestSyncToServerHistory.clientId}/projectId ${latestSyncToServerHistory.projectId} because latestSyncToServer failed`);
      return undefined;
    }
    return typeof latestSyncHistory.startServerTime === 'string' ? latestSyncHistory.startServerTime : latestSyncHistory.startServerTime.toString();
  }

  private async syncAllProjectAwareChanges<T extends IdAware>(
    startLocalTime: Date,
    dataServices: {[key in ProjectAwareKey]: AbstractProjectAwareDataService<any>},
    syncResponse: ProjectAwareSyncResponse,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<T>,
    clientAwareResult: LocalSyncClientAwareResponse<T>,
    projectId: IdType,
    options: SyncOptions & {context: DataSyncContext}
  ): Promise<{[K in ProjectAwareKey]: SyncUtilResponse<T>}> {
    return this.syncAll(startLocalTime, dataServices, syncResponse, options, nonClientAwareResult, clientAwareResult, projectId);
  }

  private async syncAllNonClientAwareChanges<T extends IdAware>(
    startLocalTime: Date,
    dataServices: {[key in NonClientAwareKey]: AbstractNonClientAwareDataService<any>},
    syncResponse: NonClientAwareSyncResponse,
    options: SyncOptions & {context: DataSyncContext}
  ): Promise<{[K in NonClientAwareKey]: SyncUtilResponse<T>}> {
    return this.syncAll(startLocalTime, dataServices, syncResponse, options);
  }

  private async syncAllClientAwareChanges<T extends IdAware>(
    startLocalTime: Date,
    dataServices: {[key in ClientAwareKey]: AbstractClientAwareDataService<any>},
    syncResponse: ClientAwareSyncResponse,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<T>,
    clientId: IdType,
    options: SyncOptions & {context: DataSyncContext}
  ): Promise<{[K in ClientAwareKey]: SyncUtilResponse<T>}> {
    return this.syncAll(startLocalTime, dataServices, syncResponse, options, nonClientAwareResult, undefined, clientId);
  }

  private async syncAll<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
    startLocalTime: Date,
    dataServices: {[key in K]: AbstractDataService<any>},
    syncResponse: ProjectAwareSyncResponse | ClientAwareSyncResponse | NonClientAwareSyncResponse,
    options: SyncOptions & {context: DataSyncContext},
    nonClientAwareResult?: LocalSyncNonClientAwareResponse<T>,
    clientAwareResult?: LocalSyncClientAwareResponse<T>,
    clientOrProjectId?: IdType
  ): Promise<{[key in K]: SyncUtilResponse<T>}> {
    const {
      context: {perfMeasurer},
    } = options;
    // @ts-ignore
    const syncRequests: {[key in K]: SyncRequest<T>} = {};
    const cpPrefix = `${!nonClientAwareResult ? 'NCA' : !clientAwareResult ? 'CA' : 'PA'}(${clientOrProjectId ?? 'undef'}): syncAll`;
    perfMeasurer.checkpoint(`${cpPrefix} before RISSA`);
    return await AbstractDataService.runInSynchronizedStorageAccess(async () => {
      perfMeasurer.checkpoint(`${cpPrefix} before LC gather`);
      for (const key of Object.keys(dataServices)) {
        const dataService = dataServices[key];
        if (!dataService.hasCurrentUserPermission) {
          const emptySyncRequest: SyncUtilRequest<T> = {
            storageKey: dataService.storageKey,
            localValues: [],
            serverValues: [],
            localChangesData: emptyLocalChangesData,
            localValuesNull: true,
          };
          syncRequests[key] = emptySyncRequest;
          continue;
        }
        const serverValues = syncResponse[key];

        const localValuesOrNull: Array<T> | null = await dataService.getDataFromMemoryOrStorageOrNull(clientOrProjectId);
        let localValues: Array<T> = localValuesOrNull || [];

        if (options?.clearLocalChanges) {
          await dataService.updateLocalChanges({inserted: [], updated: [], deleted: []}, clientOrProjectId);
          localValues = serverValues;
        }
        const localChangesData = await dataService.getLatestLocalChanges(clientOrProjectId);
        const syncRequest: SyncUtilRequest<T> = {
          storageKey: dataService.storageKey,
          localValues,
          serverValues,
          localChangesData,
          localValuesNull: localValuesOrNull === null,
        };
        syncRequests[key] = syncRequest;
      }
      perfMeasurer.checkpoint(`${cpPrefix} after LC gather`);

      this.assertAuthenticated();
      let syncUtilResponses: {[key in K]: SyncUtilResponse<T>};
      perfMeasurer.checkpoint(`${cpPrefix} before syncDataArray`);
      if (SYNC_DATA_AS_WEB_WORKER && typeof Worker !== 'undefined') {
        // eslint-disable-next-line
        syncUtilResponses = await startWorker(new Worker(new URL('./sync-web.worker', import.meta.url), {type: 'module'}), {
          syncRequests,
          nonClientAwareResult: nonClientAwareResult?.data,
          clientAwareResult: clientAwareResult?.data,
          clientOrProjectId,
        });
      } else {
        syncUtilResponses = await syncDataArray<T, K>(syncRequests, nonClientAwareResult?.data, clientAwareResult?.data, clientOrProjectId);
      }
      perfMeasurer.checkpoint(`${cpPrefix} after syncDataArray`);

      for (const key of Object.keys(syncUtilResponses)) {
        const syncRequest = syncRequests[key];
        const syncUtilResponse = syncUtilResponses[key];
        const dataService = dataServices[key];
        if (!dataService.hasCurrentUserPermission) {
          continue;
        }
        const changes =
          syncUtilResponse.changedValues.filter((changedValue) => !changedValue.onlyAttachmentFileChanged).length > 0 ||
          syncUtilResponse.newValues.length > 0 ||
          syncUtilResponse.deletedValues.length > 0 ||
          syncUtilResponse.localChangesRemove?.length ||
          syncUtilResponse.localChangesUpdate?.length ||
          syncUtilResponse.localChangesInsert?.length;
        const allValuesDeletedOnServer = syncRequest.localValues.length && !syncUtilResponse.syncedValues?.length;
        if (changes || allValuesDeletedOnServer || syncRequest.localValuesNull || options.syncWithoutSince) {
          perfMeasurer.checkpoint(`${cpPrefix}<${key}> before set data`);
          await dataService.setStorageDataPublic(syncUtilResponse.syncedValues || [], clientOrProjectId);
          perfMeasurer.checkpoint(`${cpPrefix}<${key}> after set data`);
        } else {
          this.loggingService.info(LOG_SOURCE, `No changes from server for data with storage Key "${dataService.storageKey}" and project ${clientOrProjectId}`);
        }
      }

      perfMeasurer.checkpoint(`${cpPrefix} RISSA finished`);
      return syncUtilResponses;
    });
  }

  private async syncNonClientAwareFromServer(commonSyncId: IdType, options: SyncOptions, context: DataSyncContext): Promise<LocalSyncNonClientAwareResponse<any>> {
    const {perfMeasurer} = context;
    const startLocalTime = new Date();
    try {
      const latestSyncHistory = await this.syncHistoryService.getSyncHistory();
      perfMeasurer.checkpoint('NCA: post getSyncHistory');
      const latestSyncToServerHistory = await this.syncHistoryService.getSyncToServerHistory();
      perfMeasurer.checkpoint('NCA: post getSyncToServerHistory');
      const localChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
      perfMeasurer.checkpoint('NCA: post localChanges');
      const hasNonClientAwareLocalChanges = localChanges.has(undefined);

      let url = this.urlNonClientAware;
      let since = options?.syncWithoutSince ? undefined : this.getSinceParameter(undefined, latestSyncHistory, latestSyncToServerHistory, hasNonClientAwareLocalChanges);
      if (latestSyncHistory?.unresolvedConflict) {
        if (this.auth) {
          this.systemEventService.logEvent(LOG_SOURCE + 'syncNonClientAwareFromServer', 'Running a full sync because of unresolvedConflicts of lastSync');
          this.toastMessage(this.translateService.instant('sync_full_due_to_unresolved_sync_conflicts'), TOAST_DURATION_WARNING_IN_MS);
        }
        since = undefined;
        this.loggingService.warn(LOG_SOURCE, 'syncClientAwareFromServer - starting a full sync due to unresolved sync conflicts.');
      }
      if (since) {
        url += '?since=' + since;
        perfMeasurer.increaseDeltaSyncCount('NCA');
      }
      if (options.forceReadingAttachmentFileInfo) {
        const operator = url.includes('?') ? '&' : '?';
        url += operator + 'forceReadingAttachmentFileInfo=true';
      }

      this.assertAuthenticated();
      perfMeasurer.checkpoint(`NCA: pre GET (FRAFI: ${!!options.forceReadingAttachmentFileInfo}, since: ${since})`);
      const syncGetResponse = await observableToPromise(this.http.get<NonClientAwareSyncResponse>(url));
      perfMeasurer.checkpoint(`NCA: post GET`);
      this.assertAuthenticated();

      perfMeasurer.checkpoint(`NCA: pre syncAll`);
      const data = await this.syncAllNonClientAwareChanges(startLocalTime, this.nonClientAwareServices, syncGetResponse, {...options, context});
      perfMeasurer.checkpoint(`NCA: post syncAll`);
      const conflicts = this.extractConflicts(data);
      const unresolvedConflicts = this.extractUnresolvedConflicts(data);

      perfMeasurer.checkpoint(`NCA: pre apply LC`);
      const localChangesData = await this.applyLocalChangesInSyncResponse(startLocalTime, data);
      perfMeasurer.checkpoint(`NCA: post apply LC`);
      const ret: LocalSyncNonClientAwareResponse<any> = {
        success: true,
        conflict: conflicts.length > 0,
        unresolvedConflict: unresolvedConflicts.length > 0,
        conflicts,
        startLocalTime,
        data,
        localChangesData,
      };

      const endLocalTime = new Date();
      const syncHistory: SyncHistory = {
        id: uuid4(),
        commonSyncId,
        startServerTime: syncGetResponse.startServerTime,
        endServerTime: syncGetResponse.endServerTime,
        startLocalTime,
        endLocalTime,
        conflict: conflicts.length > 0,
        unresolvedConflict: unresolvedConflicts.length > 0,
        conflicts,
      };
      const projectMetaInfo: ProjectMetaInfo = {};
      if (this.auth) {
        perfMeasurer.checkpoint(`NCA: pre set sync hist`);
        await this.syncHistoryService.setSyncHistory(syncHistory, {mutationOptions: {ensureStored: false, immediate: false}});
        perfMeasurer.checkpoint(`NCA: post set sync hist`);
        if (!since || options.forceReadingAttachmentFileInfo) {
          perfMeasurer.checkpoint(`NCA: pre set sync meta`);
          this.loggingService.info(LOG_SOURCE, `syncNonClientAwareFromServer - forceReadingAttachmentFileInfo was set. Calling setProjectMetaInfo.`);
          await this.projectMetaInfoService.setProjectMetaInfo(projectMetaInfo, {mutationOptions: {ensureStored: false, immediate: false}});
          perfMeasurer.checkpoint(`NCA: post set sync meta`);
        }
      }
      return ret;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error in syncNonClientAwareFromServer. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' syncNonClientAwareFromServer', error);
      return {
        success: false,
        conflict: false,
        unresolvedConflict: false,
        error,
        startLocalTime,
      } as LocalSyncNonClientAwareResponse<any>;
    }
  }

  private async syncClientAwareFromServer<T extends IdAware>(
    clientId: IdType,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<T>,
    commonSyncId: IdType,
    options: SyncOptions,
    context: DataSyncContext
  ): Promise<LocalSyncClientAwareResponse<any>> {
    const {perfMeasurer} = context;
    const startLocalTime = new Date();
    try {
      const latestSyncHistory = await this.syncHistoryService.getSyncHistory(clientId);
      perfMeasurer.checkpoint(`CA(${clientId}): post getSyncHistory`);
      const latestSyncToServerHistory = await this.syncHistoryService.getSyncToServerHistory(clientId);
      perfMeasurer.checkpoint(`CA(${clientId}): post getSyncToServerHistory`);
      const localChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
      perfMeasurer.checkpoint(`CA(${clientId}): post localChanges`);
      const hasClientAwareLocalChanges = localChanges.has(clientId);

      let url = this.urlClientAware + '?clientId=' + clientId;
      let since = options?.syncWithoutSince ? undefined : this.getSinceParameter(clientId, latestSyncHistory, latestSyncToServerHistory, hasClientAwareLocalChanges);
      if (latestSyncHistory?.unresolvedConflict) {
        if (this.auth) {
          this.systemEventService.logEvent(LOG_SOURCE + 'syncClientAwareFromServer', `Running a full sync for client ${clientId} because of unresolvedConflicts of lastSync`);
          await this.toastMessage(this.translateService.instant('sync_full_due_to_unresolved_sync_conflicts'), TOAST_DURATION_WARNING_IN_MS);
        }
        since = undefined;
        this.loggingService.warn(LOG_SOURCE, 'syncClientAwareFromServer - starting a full sync due to unresolved sync conflicts.');
      }
      if (since) {
        url += '&since=' + since;
        perfMeasurer.increaseDeltaSyncCount('CA');
      }
      if (options.forceReadingAttachmentFileInfo) {
        url += '&forceReadingAttachmentFileInfo=true';
      }

      this.assertAuthenticated();
      perfMeasurer.checkpoint(`CA(${clientId}): pre GET (FRAFI: ${!!options.forceReadingAttachmentFileInfo}, since: ${since})`);
      const syncGetResponse = await observableToPromise(this.http.get<ClientAwareSyncResponse>(url));
      perfMeasurer.checkpoint(`CA(${clientId}): post GET`);
      this.assertAuthenticated();

      perfMeasurer.checkpoint(`CA(${clientId}): pre syncAll`);
      const data = await this.syncAllClientAwareChanges(startLocalTime, this.clientAwareServices, syncGetResponse, nonClientAwareResult, clientId, {...options, context});
      perfMeasurer.checkpoint(`CA(${clientId}): post syncAll`);
      const conflicts = this.extractConflicts(data);
      const unresolvedConflicts = this.extractUnresolvedConflicts(data);

      perfMeasurer.checkpoint(`CA(${clientId}): pre apply LC`);
      const localChangesData = await this.applyLocalChangesInSyncResponse(startLocalTime, data, clientId);
      perfMeasurer.checkpoint(`CA(${clientId}): post apply LC`);
      const ret: LocalSyncClientAwareResponse<any> = {
        clientId,
        success: true,
        conflict: conflicts.length > 0,
        unresolvedConflict: unresolvedConflicts.length > 0,
        conflicts,
        startLocalTime,
        data,
        localChangesData,
      };

      const endLocalTime = new Date();
      const syncHistory: SyncHistory = {
        id: uuid4(),
        commonSyncId,
        clientId,
        startServerTime: syncGetResponse.startServerTime,
        endServerTime: syncGetResponse.endServerTime,
        startLocalTime,
        endLocalTime,
        conflict: conflicts.length > 0,
        unresolvedConflict: unresolvedConflicts.length > 0,
        conflicts,
      };
      const projectMetaInfo: ProjectMetaInfo = {};
      if (this.auth) {
        perfMeasurer.checkpoint(`CA(${clientId}): pre set sync hist`);
        await this.syncHistoryService.setSyncHistory(syncHistory, {mutationOptions: {ensureStored: false, immediate: false}});
        perfMeasurer.checkpoint(`CA(${clientId}): post set sync hist`);
        if (!since || options.forceReadingAttachmentFileInfo) {
          this.loggingService.info(LOG_SOURCE, `syncClientAwareFromServer - forceReadingAttachmentFileInfo was set. Calling setProjectMetaInfo.`);
          perfMeasurer.checkpoint(`CA(${clientId}): pre set sync meta`);
          await this.projectMetaInfoService.setProjectMetaInfo(projectMetaInfo, {mutationOptions: {ensureStored: false, immediate: false}});
          perfMeasurer.checkpoint(`CA(${clientId}): post set sync meta`);
        }
      }
      return ret;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error in syncClientAwareFromServer. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' syncClientAwareFromServer', error);
      return {
        success: false,
        conflict: false,
        unresolvedConflict: false,
        clientId,
        error,
        startLocalTime,
      } as LocalSyncClientAwareResponse<any>;
    }
  }

  private async syncProjectAwareFromServer<T extends IdAware>(
    projectSyncRequest: LocalProjectSyncRequest,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<T>,
    clientAwareResult: LocalSyncClientAwareResponse<T>,
    commonSyncId: IdType,
    options: SyncOptions,
    context: DataSyncContext
  ): Promise<LocalSyncProjectAwareResponse<T>> {
    if (!projectSyncRequest.project) {
      throw new Error('Error in method syncProjectAwareFromServer. projectSyncRequest.project is null or undefined.');
    }
    const {perfMeasurer} = context;
    const projectId = projectSyncRequest.project.id;
    this.loggingService.debug(LOG_SOURCE, `syncProjectAware(${projectId}) called.`);
    const startLocalTime = new Date();
    try {
      perfMeasurer.checkpoint(`PA(${projectId}): pre getSyncHistory`);
      const latestSyncHistory = await this.syncHistoryService.getSyncHistory(projectId);
      perfMeasurer.checkpoint(`PA(${projectId}): post getSyncHistory`);
      const latestSyncToServerHistory = await this.syncHistoryService.getSyncToServerHistory(projectId);
      perfMeasurer.checkpoint(`PA(${projectId}): post getSyncToServerHistory`);
      const localChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
      perfMeasurer.checkpoint(`PA(${projectId}): post localChanges`);
      const hasProjectAwareLocalChanges = localChanges.has(projectId);

      let since = options?.syncWithoutSince ? undefined : this.getSinceParameter(projectId, latestSyncHistory, latestSyncToServerHistory, hasProjectAwareLocalChanges);
      if (latestSyncHistory?.unresolvedConflict) {
        if (this.auth) {
          this.systemEventService.logEvent(
            LOG_SOURCE + 'syncProjectAwareFromServer',
            () =>
              `Running a full sync for project ${projectId} because of unresolvedConflicts of lastSync.` + _.truncate(JSON.stringify(latestSyncHistory?.conflicts), {length: SYSTEM_EVENT_MAX_LENGTH})
          );
          await this.toastMessage(this.translateService.instant('sync_full_due_to_unresolved_sync_conflicts'), TOAST_DURATION_WARNING_IN_MS);
        }
        since = undefined;
        this.loggingService.warn(LOG_SOURCE, 'syncProjectAwareFromServer - starting a full sync due to unresolved sync conflicts.');
      }
      let url = this.urlProjectAware + '?projectId=' + projectId + (since ? '&since=' + since : '');
      if (options.forceReadingAttachmentFileInfo) {
        url += '&forceReadingAttachmentFileInfo=true';
      }
      if (since) {
        perfMeasurer.increaseDeltaSyncCount('PA');
      }
      this.assertAuthenticated();
      perfMeasurer.checkpoint(`PA(${projectId}): pre GET (FRAFI: ${!!options.forceReadingAttachmentFileInfo}, since: ${since})`);
      const syncResponse = await observableToPromise(this.http.get<ProjectAwareSyncResponse>(url));
      perfMeasurer.checkpoint(`PA(${projectId}): post GET`);
      this.assertAuthenticated();
      perfMeasurer.measure(`PA(${projectId} GET`, `PA(${projectId}): pre GET (FRAFI: ${!!options.forceReadingAttachmentFileInfo}, since: ${since})`, `PA(${projectId}): post GET`);

      perfMeasurer.checkpoint(`PA(${projectId}): pre syncAll`);
      const data = await this.syncAllProjectAwareChanges(startLocalTime, this.projectAwareServices, syncResponse, nonClientAwareResult, clientAwareResult, projectId, {...options, context});
      perfMeasurer.checkpoint(`PA(${projectId}): post syncAll`);
      perfMeasurer.measure(`PA(${projectId} syncAll`, `PA(${projectId}): pre syncAll`, `PA(${projectId}): post syncAll`);

      perfMeasurer.checkpoint(`PA(${projectId}): pre apply LC`);
      const localChangesData = await this.applyLocalChangesInSyncResponse(startLocalTime, data, projectId);
      perfMeasurer.checkpoint(`PA(${projectId}): post apply LC`);

      const conflicts = this.extractConflicts(data);
      const unresolvedConflicts = this.extractUnresolvedConflicts(data);
      const ret: LocalSyncProjectAwareResponse<T> = {
        success: true,
        conflict: conflicts.length > 0,
        unresolvedConflict: unresolvedConflicts.length > 0,
        conflicts,
        projectId,
        data,
        projectSyncRequest,
        localChangesData,
        startLocalTime,
      };

      const endLocalTime = new Date();
      const syncHistory: SyncHistory = {
        id: uuid4(),
        projectId,
        commonSyncId,
        startServerTime: syncResponse.startServerTime,
        endServerTime: syncResponse.endServerTime,
        startLocalTime,
        endLocalTime,
        conflict: conflicts.length > 0,
        unresolvedConflict: unresolvedConflicts.length > 0,
        conflicts,
      };
      const projectMetaInfo: ProjectMetaInfo = {
        projectId,
        attachmentSizeFromServer: syncResponse.totalAttachmentSize,
        attachmentSizeOfflineFromServer: syncResponse.totalAttachmentSizeOffline,
      };
      if (this.auth) {
        perfMeasurer.checkpoint(`PA(${projectId}): pre set sync hist`);
        await this.syncHistoryService.setSyncHistory(syncHistory, {mutationOptions: {ensureStored: false, immediate: false}});
        perfMeasurer.checkpoint(`PA(${projectId}): post set sync hist`);
        if (!since || options.forceReadingAttachmentFileInfo) {
          perfMeasurer.checkpoint(`PA(${projectId}): pre set sync meta`);
          this.loggingService.info(LOG_SOURCE, `syncProjectAwareFromServer - forceReadingAttachmentFileInfo was set. Calling setProjectMetaInfo.`);
          await this.projectMetaInfoService.setProjectMetaInfo(projectMetaInfo, {mutationOptions: {ensureStored: false, immediate: false}});
          perfMeasurer.checkpoint(`PA(${projectId}): post set sync meta`);
        }
      }
      perfMeasurer.checkpoint(`PA(${projectId}): finished`);
      this.loggingService.debug(LOG_SOURCE, `syncProjectAware(${projectId}) successful.`);
      perfMeasurer.measure(`PA(${projectId} from server`, `PA(${projectId}): pre getSyncHistory`, `PA(${projectId}): finished`);
      return ret;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `syncProjectAware(${projectId}) failed with error: ${error?.message}. ${error.stack}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` syncProjectAware(${projectId})`, error);
      return {
        success: false,
        conflict: false,
        unresolvedConflict: false,
        projectSyncRequest,
        projectId,
        error,
        startLocalTime,
      } as LocalSyncProjectAwareResponse<T>;
    }
  }

  private async applyLocalChangesInSyncResponse<V extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey, T extends IdAware>(
    startLocalTime: Date,
    syncResponseData: {[K in V]: SyncUtilResponse<T>},
    clientOrProjectId?: IdType
  ): Promise<{[K in V]: LocalChangesData<T>}> {
    const ret = {};
    for (const key of Object.keys(syncResponseData)) {
      const dataService: AbstractDataService<T> = this.allServices[key];
      const data: SyncUtilResponse<T> = syncResponseData[key];
      // Todo: cloneDeep is a long operation, can we somehow use more specific function, that copies that data, but quicker?
      const clonedLocalChangesData: LocalChangesData<T> = _.cloneDeep(data.localChangesData);
      if (data.localChangesInsert?.length) {
        for (const localChangeInsert of data.localChangesInsert) {
          const newValue: LocalChange<T> = {changedAt: startLocalTime.toISOString(), value: localChangeInsert};
          await dataService.updateLocalChangesInSynchronizedStorageAccess((localChanges) => localChanges.inserted.push(newValue), clientOrProjectId);
          clonedLocalChangesData.insert.push(newValue.value);
          clonedLocalChangesData.localChangesInsertById.set(newValue.value.id, newValue);
        }
      }

      if (data.localChangesUpdate?.length) {
        for (const localChangeUpdate of data.localChangesUpdate) {
          let updatedValue: LocalChange<T> | undefined;
          // TODO check version, what if user has changed data in the meantime.
          await dataService.updateLocalChangesInSynchronizedStorageAccess((localChanges) => {
            const originalValue = dataService.findOriginalValueFromLocalChanges(localChanges, localChangeUpdate);
            updatedValue = {changedAt: startLocalTime.toISOString(), value: localChangeUpdate, oldValue: localChangeUpdate, originalValue};
            localChanges.updated.push(updatedValue);
          }, clientOrProjectId);
          if (updatedValue) {
            const index = clonedLocalChangesData.update.findIndex((object) => {
              return object.id === updatedValue.value.id;
            });
            if (index === -1) {
              clonedLocalChangesData.update.push(updatedValue.value);
            } else {
              clonedLocalChangesData.update[index] = updatedValue.value;
            }
            clonedLocalChangesData.localChangesInsertById.set(updatedValue.value.id, updatedValue);
          }
        }
      }

      if (data.localChangesRemove.length) {
        await dataService.deleteLocalChangesWithIdsInSynchronizedStorageAccess(data.localChangesRemove, clientOrProjectId);
        clonedLocalChangesData.insert = clonedLocalChangesData.insert.filter((object) => !data.localChangesRemove.find((id) => id === object.id));
        clonedLocalChangesData.update = clonedLocalChangesData.update.filter((object) => !data.localChangesRemove.find((id) => id === object.id));
        clonedLocalChangesData.delete = clonedLocalChangesData.delete.filter((object) => !data.localChangesRemove.find((id) => id === object.id));
      }

      ret[key] = clonedLocalChangesData;
    }
    return ret as {[K in V]: LocalChangesData<T>};
  }

  private async syncToServer<T extends IdAware>(
    url: string,
    commonSyncId: IdType,
    syncFromServerResponse: LocalSyncProjectAwareResponse<T> | LocalSyncClientAwareResponse<T> | LocalSyncNonClientAwareResponse<T>,
    services:
      | {[key in NonClientAwareKey]: AbstractNonClientAwareDataService<T>}
      | {[key in ClientAwareKey]: AbstractClientAwareDataService<T>}
      | {[key in ProjectAwareKey]: AbstractProjectAwareDataService<T>},
    clientId: IdType | undefined,
    projectId: IdType | undefined
  ): Promise<LocalSyncToServerResponse> {
    const clientOrProjectId = clientId || projectId || undefined;
    const startLocalTime = new Date();
    let anyChanges = false;
    let wasError = false;
    try {
      const syncPostRequest = {};
      for (const key of Object.keys(services)) {
        const localChanges = this.removeObsoleteChanges(syncFromServerResponse.localChangesData[key]);
        syncPostRequest[key] = localChanges;
        if (localChanges.insert.length || localChanges.update.length || localChanges.delete.length) {
          anyChanges = true;
        }
      }
      if (!anyChanges) {
        this.loggingService.info(LOG_SOURCE, `No localChanges.`);
        return {success: true, clientOrProjectId};
      }
      this.assertAuthenticated();
      const syncResponse = await observableToPromise(this.http.post<SyncResponse>(url, syncPostRequest));
      this.assertAuthenticated();

      for (const key of Object.keys(services)) {
        const updatedItems = await services[key].deleteLocalChangesBeforeAndUpdateChangedAtInSynchronizedStorageAccess(
          syncFromServerResponse.startLocalTime,
          syncResponse.startServerTime,
          clientOrProjectId
        );
        syncFromServerResponse.data[key].syncedValues
          .filter((syncedValue) => updatedItems.find((updatedItem) => updatedItem.id === syncedValue.id))
          .forEach((syncedValue) => {
            syncedValue.changedAt = syncResponse.startServerTime;
          });
      }
      await this.syncHistoryService.setSyncToServerHistory(
        {
          id: uuid4(),
          commonSyncId,
          clientId,
          projectId,
          startServerTime: syncResponse?.startServerTime,
          endServerTime: syncResponse?.endServerTime,
          startLocalTime,
          endLocalTime: new Date(),
          success: true,
        },
        {mutationOptions: {ensureStored: false, immediate: false}}
      );
      return {success: true, clientOrProjectId};
    } catch (error) {
      wasError = true;
      this.loggingService.error(LOG_SOURCE, `Error in syncToServer. ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` syncToServer(${clientOrProjectId})`, error);
      let errorResponse: ErrorResponse<ErrorCodeType>;
      if (error.status === 400) {
        errorResponse = error.error;
      }
      await this.syncHistoryService.setSyncToServerHistory(
        {
          id: uuid4(),
          commonSyncId,
          clientId,
          projectId,
          startLocalTime,
          endLocalTime: new Date(),
          success: false,
          error: error?.message,
          errorResponse,
        },
        {mutationOptions: {ensureStored: false, immediate: false}}
      );
      return {success: false, clientOrProjectId, error, errorResponse};
    } finally {
      if (anyChanges || wasError) {
        await this.storage.persistPendingChanges();
      }
    }
  }

  private extractConflicts<T extends IdAware>(data: {[K in NonClientAwareKey]: SyncUtilResponse<T>} | {[K in ClientAwareKey]: SyncUtilResponse<T>} | {[K in ProjectAwareKey]: SyncUtilResponse<T>}) {
    let conflicts = new Array<SyncConflict<T>>();
    Object.values(data)
      .filter((o) => o.conflict)
      .forEach((syncUtilResponse) => (conflicts = conflicts.concat(syncUtilResponse.conflicts)));
    return conflicts;
  }

  private extractUnresolvedConflicts<T extends IdAware>(
    data: {[K in NonClientAwareKey]: SyncUtilResponse<T>} | {[K in ClientAwareKey]: SyncUtilResponse<T>} | {[K in ProjectAwareKey]: SyncUtilResponse<T>}
  ) {
    let conflicts = new Array<SyncConflict<T>>();
    Object.values(data)
      .filter((o) => o.conflict && !o.resolved)
      .forEach((syncUtilResponse) => (conflicts = conflicts.concat(syncUtilResponse.conflicts)));
    return conflicts;
  }

  async syncProjectAwareFromServerConcurrently<T extends IdAware>(
    projectsSyncRequest: Array<LocalProjectSyncRequest>,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<T>,
    clientAwareResult: LocalSyncClientAwareResponse<T>,
    commonSyncId: IdType,
    options: SyncOptions,
    context: DataSyncContext,
    concurrentDownloads = PROJECTS_CONCURRENT_SYNC
  ): Promise<Array<LocalSyncProjectAwareResponse<T>>> {
    const ret = new Array<LocalSyncProjectAwareResponse<T>>();
    if (projectsSyncRequest.length) {
      const downloadFilesObservable = from(_.orderBy(projectsSyncRequest, ['project.number', 'project.name'], ['asc', 'asc'])).pipe(
        mergeMap(async (projectSyncRequest) => {
          const result = await this.syncProjectAwareFromServer(projectSyncRequest, nonClientAwareResult, clientAwareResult, commonSyncId, options, context);
          ret.push(result);
          context.perfMeasurer.increaseSyncCount('PA');
        }, concurrentDownloads)
      );

      await downloadFilesObservable.toPromise();
    }
    return ret;
  }

  async syncProjectAwareToServerConcurrently<T extends IdAware>(
    syncProjectAwareResponse: Array<LocalSyncProjectAwareResponse<T>>,
    clientAwareResult: LocalSyncClientAwareResponse<T>,
    commonSyncId: IdType,
    concurrentDownloads = PROJECTS_CONCURRENT_SYNC
  ): Promise<Array<LocalSyncToServerResponse>> {
    const ret = new Array<LocalSyncToServerResponse>();
    if (syncProjectAwareResponse.length) {
      const syncObservable = from(syncProjectAwareResponse).pipe(
        mergeMap(async (value) => {
          const projectId = value.projectId;
          const url = this.urlProjectAware + '?projectId=' + projectId;
          const response = await this.syncToServer(url, commonSyncId, value, this.projectAwareServices, undefined, projectId);
          ret.push(response);
        }, concurrentDownloads)
      );

      await syncObservable.toPromise();
    }
    return ret;
  }

  private assertOptions(options: SyncOptions) {
    if (!options) {
      return;
    }
    if (options.clearLocalChanges && !options.syncWithoutSince) {
      throw new Error('Invalid sync options. If clearLocalChanges is set, syncWithoutSince must also be set.');
    }
  }

  private async dataSyncFinished() {
    if (!this.nextScheduledSync) {
      return;
    }
    const nextScheduledSync = this.nextScheduledSync;
    this.nextScheduledSync = undefined;
    this.startSyncInternally(nextScheduledSync.syncStrategy, nextScheduledSync.options)
      .then((dataSyncResultStatus) => nextScheduledSync.resolvePromiseFunc(dataSyncResultStatus))
      .catch((error) => nextScheduledSync.rejectPromiseFunc(error));
  }

  public async startSync(syncStrategy: SyncStrategy, options?: SyncOptions): Promise<DataSyncResultStatus> {
    if (syncStrategy <= SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES && this.additionalActiveProjectIdsToSync?.length) {
      if (options) {
        if (options.additionalProjectIdsToSync?.length > 0) {
          options.additionalProjectIdsToSync = _.compact([...options.additionalProjectIdsToSync, ...this.additionalActiveProjectIdsToSync]);
        } else {
          options.additionalProjectIdsToSync = [...this.additionalActiveProjectIdsToSync];
        }
      } else {
        options = {
          additionalProjectIdsToSync: [...this.additionalActiveProjectIdsToSync],
        };
      }
    }
    if (this.devModeService.enabled && this.devModeService.settings.disableSync) {
      this.loggingService.warn(LOG_SOURCE, 'DevMode enabled. Skipping sync.');
      return 'NO_NETWORK';
    }
    if (!this.syncStatusService.dataSyncInProgress?.inProgress) {
      const result = await this.startSyncInternally(syncStrategy, options);
      if (result !== 'ALREADY_IN_PROGRESS') {
        return result;
      }
    }

    if (!this.nextScheduledSync) {
      let resolvePromiseFunc: (dataSyncResultStatus: DataSyncResultStatus) => void;
      let rejectPromiseFunc: (error: any) => void;
      const promise = new Promise<DataSyncResultStatus>((resolve, reject) => {
        resolvePromiseFunc = resolve;
        rejectPromiseFunc = reject;
      });
      this.nextScheduledSync = {syncStrategy, options, promise, resolvePromiseFunc, rejectPromiseFunc};
      return promise;
    } else if (this.nextScheduledSync.syncStrategy > syncStrategy) {
      this.nextScheduledSync.syncStrategy = syncStrategy;
      const previousOptionAdditionalProjectIdsToSync = this.nextScheduledSync.options?.additionalProjectIdsToSync;
      this.nextScheduledSync.options = options;
      if (previousOptionAdditionalProjectIdsToSync?.length) {
        if (!this.nextScheduledSync.options) {
          this.nextScheduledSync.options = {};
        }
        if (this.nextScheduledSync.options.additionalProjectIdsToSync?.length) {
          this.nextScheduledSync.options.additionalProjectIdsToSync = _.compact(previousOptionAdditionalProjectIdsToSync.concat(this.nextScheduledSync.options.additionalProjectIdsToSync));
        } else {
          this.nextScheduledSync.options.additionalProjectIdsToSync = previousOptionAdditionalProjectIdsToSync;
        }
      }
      return this.nextScheduledSync.promise;
    }
  }

  public async startSyncInternally(syncStrategy: SyncStrategy, options?: SyncOptions): Promise<DataSyncResultStatus> {
    this.loggingService.info(LOG_SOURCE, 'startSync - syncStrategy ' + syncStrategy);
    options = options || {};
    this.assertOptions(options);

    const start = new Date();
    try {
      if (this.platform.is('cordova')) {
        await this.insomnia.keepAwake();
      }
      await this.systemEventService.logEvent(LOG_SOURCE + ' startSync', `dataSync(syncStrategy=${syncStrategy}, showInfoToastMessages=${options?.showInfoToastMessages}) started.`);
      const dataSyncResult = await this.dataSync(syncStrategy, options);
      const durationInMs = new Date().getTime() - start.getTime();
      this.loggingService.info(LOG_SOURCE, `Data-Sync finished in ${durationInMs / 1000} seconds.`);
      await this.systemEventService.logEvent(
        LOG_SOURCE + ' startSync',
        `dataSync(syncStrategy=${syncStrategy}, showInfoToastMessages=${options?.showInfoToastMessages}) finished in ${durationInMs} ms`
      );

      this.syncPerformanceStatisticsService.flushToServer();

      if (
        dataSyncResult.status !== 'ALREADY_IN_PROGRESS' &&
        dataSyncResult.status !== 'NO_NETWORK' &&
        dataSyncResult.result &&
        (dataSyncResult.result.nonClientAwareResult || dataSyncResult.result.clientAwareResults?.length || dataSyncResult.result.projectAwareResults?.length)
      ) {
        const attachmentSyncPromise = this.attachmentSync(dataSyncResult, options, syncStrategy);
        if (!options.doNotWaitForAttachmentSync) {
          await attachmentSyncPromise;
        }
      }

      return dataSyncResult.status;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Sync failed with error ${convertErrorToMessage(error)}.`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' startSync', error);
      throw error;
    } finally {
      await this.storage.persistPendingChanges();
      const durationInMs = new Date().getTime() - start.getTime();
      this.loggingService.info(LOG_SOURCE, `Sync finished in ${durationInMs / 1000} seconds.`);
      await this.systemEventService.logEvent(LOG_SOURCE + ' startSync', `sync(syncStrategy=${syncStrategy}, showInfoToastMessages=${options?.showInfoToastMessages}) finished in ${durationInMs} ms`);
      if (this.platform.is('cordova')) {
        await this.insomnia.allowSleepAgain();
      }
    }
  }

  private async attachmentSync(dataSyncResult: DataSyncResult, options: SyncOptions, syncStrategy: SyncStrategy) {
    await this.scheduleAttachmentsForUpload(
      dataSyncResult.result?.projectAwareResults,
      dataSyncResult.result?.clientAwareResults,
      dataSyncResult.result?.nonClientAwareResult,
      options.ignoreAttachmentVersion
    );
    await this.downloadAttachments(
      syncStrategy,
      dataSyncResult.result?.projectAwareResults,
      dataSyncResult.result?.clientAwareResults,
      dataSyncResult.result?.nonClientAwareResult,
      dataSyncResult.syncStarted,
      options
    );
    await this.uploadAllFilesFromMediaQueue(this.auth?.token);
  }

  private async checkAndSetDataSyncInProgress(syncStrategy: SyncStrategy): Promise<boolean> {
    return await SyncService.runSynchronized<boolean>(async () => {
      if (this.syncStatusService.dataSyncInProgress?.inProgress) {
        return false;
      }
      this.syncStatusService.dataSyncInProgress = {inProgress: true, syncStrategy};
      return true;
    });
  }

  /**
   * Starts the data sync.
   *
   * @param syncStrategy defines what to sync
   * @param options
   * @returns the result of the sync or null if the sync is already in progress or the NonClientAware sync was not successful (failed or unresolved conflicts).
   */
  public async dataSync(syncStrategy: SyncStrategy, options?: SyncOptions): Promise<DataSyncResult> {
    const commonSyncId = uuid4();
    const perfMeasurer = getPerformanceMeasurer(syncStrategy, commonSyncId);
    options = options || {};
    const syncStarted = new Date();
    this.assertOptions(options);
    this.assertAuthenticated();

    const statusChanged = await this.checkAndSetDataSyncInProgress(syncStrategy);
    perfMeasurer.checkpoint('post checkAndSetDataSyncInProgress');
    if (!statusChanged) {
      this.systemEventService.logEvent('SyncService', `dataSync(syncStrategy=${syncStrategy}) already in progress.`);
      if (options?.showInfoToastMessages) {
        this.toastMessage(this.translateService.instant('sync_data_already_in_progress'), TOAST_DURATION_WARNING_IN_MS);
      }
      perfMeasurer.checkpoint('Finished (ALREADY_IN_PROGRESS)');
      return {status: 'ALREADY_IN_PROGRESS', syncStarted, syncFinished: new Date()};
    }

    let nonClientAwareResult: LocalSyncNonClientAwareResponse<any> | undefined;
    const clientAwareResults = new Array<LocalSyncClientAwareResponse<any>>();
    let projectAwareResults = new Array<LocalSyncProjectAwareResponse<any>>();

    try {
      this.loggingService.debug(LOG_SOURCE, `dataSync(${syncStrategy}) started`);
      this.assertAuthenticated();
      const start = new Date();
      if (options?.showInfoToastMessages) {
        this.toastMessage(this.translateService.instant('sync_data_started'), TOAST_DURATION_INFO_IN_MS);
      }

      if (this.networkStatusService.offline) {
        this.loggingService.warn(LOG_SOURCE, 'Network not connected. Sync will be aborted.');
        perfMeasurer.checkpoint('Finished (NO_NETWORK)');
        return {status: 'NO_NETWORK', syncStarted, syncFinished: new Date()};
      }

      const additionalProjectIdsToSync: IdType[] = _.compact([...this.additionalActiveProjectIdsToSync, ...(options?.additionalProjectIdsToSync ?? [])]);
      const clientOrProjectsWithLocalChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
      perfMeasurer.checkpoint('post clientOrProjectsWithLocalChanges');
      if (clientOrProjectsWithLocalChanges.size === 0 && syncStrategy === SyncStrategy.PROJECTS_WITH_CHANGES && !additionalProjectIdsToSync.length) {
        this.loggingService.info(LOG_SOURCE, 'requested to sync clients/projects with changes but there are no local changes.');
        perfMeasurer.checkpoint('Finished (FINISHED_NOTHING_DONE)');
        return {status: 'FINISHED_NOTHING_DONE', syncStarted, syncFinished: new Date()};
      }

      perfMeasurer.checkpoint('pre NCA from server');
      nonClientAwareResult = await this.syncNonClientAwareFromServer(commonSyncId, options, {perfMeasurer});
      perfMeasurer.checkpoint('post NCA from server');
      perfMeasurer.increaseSyncCount('NCA');

      if (!nonClientAwareResult.success || nonClientAwareResult.unresolvedConflict) {
        perfMeasurer.checkpoint('pre persistPendingChanges post NCA from server');
        await this.storage.persistPendingChanges();
        perfMeasurer.checkpoint('post persistPendingChanges post NCA from server');

        const durationNonClientAware = Math.round((new Date().getTime() - start.getTime()) / 1000);
        await this.printDataSyncSuccessOrError(options, nonClientAwareResult, undefined, undefined, undefined, undefined, undefined, durationNonClientAware);
        perfMeasurer.checkpoint('Finished (FINISHED_WITH_ERRORS)');
        return {status: 'FINISHED_WITH_ERRORS', syncStarted, syncFinished: new Date()};
      }

      perfMeasurer.checkpoint('pre NCA to server');
      const nonClientAwareToServerResult = await this.syncToServer(this.urlNonClientAware, commonSyncId, nonClientAwareResult, this.nonClientAwareServices, undefined, undefined);
      perfMeasurer.checkpoint('post NCA to server');

      if (!nonClientAwareToServerResult.success) {
        perfMeasurer.checkpoint('pre persistPendingChanges post NCA to server');
        await this.storage.persistPendingChanges();
        perfMeasurer.checkpoint('post persistPendingChanges post NCA to server');

        const durationNonClientAware = Math.round((new Date().getTime() - start.getTime()) / 1000);
        await this.printDataSyncSuccessOrError(options, nonClientAwareResult, nonClientAwareToServerResult, undefined, undefined, undefined, undefined, durationNonClientAware);
        perfMeasurer.checkpoint('Finished (FINISHED_WITH_ERRORS)');
        return {
          status: 'FINISHED_WITH_ERRORS',
          result: {nonClientAwareResult, clientAwareResults: [], projectAwareResults: []},
          syncStarted,
          syncFinished: new Date(),
        };
      }

      const clients: Array<Client> = nonClientAwareResult.data.clients.syncedValues;
      const clientAwareToServerResults = new Array<LocalSyncToServerResponse>();
      let projectAwareToServerResults = new Array<LocalSyncToServerResponse>();
      let allProjectsToSync = new Array<LocalProjectSyncRequest>();
      for (const client of clients) {
        if (this.currentUserLicenseType === LicenseType.VIEWER) {
          options.syncWithoutSince = true;
        }
        if (!(await this.needsClientToBeSynced(syncStrategy, client, nonClientAwareResult.data.users.syncedValues, options))) {
          this.loggingService.info(LOG_SOURCE, `Skip sync of client ${client.id} due to syncStrategy ${syncStrategy}`);
          continue;
        }

        perfMeasurer.checkpoint(`pre CA(${client.id}) from server`);
        const clientAwareResult = await this.syncClientAwareFromServer(client.id, nonClientAwareResult, commonSyncId, options, {perfMeasurer});
        perfMeasurer.checkpoint(`post CA(${client.id}) from server`);
        perfMeasurer.increaseSyncCount('CA');
        perfMeasurer.measure(`CA(${client.id} from server`, `pre CA(${client.id}) from server`, `post CA(${client.id}) from server`);

        clientAwareResults.push(clientAwareResult);
        if (!clientAwareResult.success || clientAwareResult.unresolvedConflict) {
          continue;
        }

        const urlClientAwareToServer = `${this.urlClientAware}?clientId=${client.id}`;

        perfMeasurer.checkpoint(`pre CA(${client.id}) to server`);
        const clientAwareToServerResult = await this.syncToServer(urlClientAwareToServer, commonSyncId, clientAwareResult, this.clientAwareServices, client.id, undefined);
        perfMeasurer.checkpoint(`post CA(${client.id}) to server`);

        clientAwareToServerResults.push(clientAwareToServerResult);
        if (!clientAwareToServerResult.success) {
          continue;
        }
        perfMeasurer.checkpoint(`pre CA(${client.id}) determine`);
        const projectsToSync = await this.determineProjectsToSync(
          syncStrategy,
          client,
          clientAwareResult.data.projects.syncedValues,
          nonClientAwareResult.data.users.syncedValues,
          clientAwareResult.data.userDeviceOfflineProjects.syncedValues,
          options,
          clientAwareResult.data.userDeviceOfflineProjects
        );
        perfMeasurer.checkpoint(`post CA(${client.id}) determine`);

        allProjectsToSync = allProjectsToSync.concat(projectsToSync);

        perfMeasurer.checkpoint(`pre CA(${client.id}) PA concurrently from server`);
        const clientProjectAwareResults = await this.syncProjectAwareFromServerConcurrently(projectsToSync, nonClientAwareResult, clientAwareResult, commonSyncId, options, {perfMeasurer});
        perfMeasurer.checkpoint(`post CA(${client.id}) PA concurrently from server`);

        projectAwareResults = projectAwareResults.concat(clientProjectAwareResults);

        const successfulProjectAwareResults = clientProjectAwareResults.filter((projectAwareResult) => projectAwareResult.success && !projectAwareResult.unresolvedConflict);

        let projectAwareToServerResult: Array<LocalSyncToServerResponse> | undefined;
        if (successfulProjectAwareResults.length > 0) {
          perfMeasurer.checkpoint(`pre CA(${client.id}) PA concurrently to server`);
          projectAwareToServerResult = await this.syncProjectAwareToServerConcurrently(successfulProjectAwareResults, clientAwareResult, commonSyncId);
          perfMeasurer.checkpoint(`post CA(${client.id}) PA concurrently to server`);

          projectAwareToServerResults = projectAwareToServerResults.concat(projectAwareToServerResult);
        }
      }

      const failedProjectAwareResults = projectAwareResults.filter((projectAwareResult) => !projectAwareResult.success);
      const failedProjectAwareToServerResults = !projectAwareToServerResults ? [] : projectAwareToServerResults.filter((projectAwareResult) => !projectAwareResult.success);

      perfMeasurer.checkpoint(`pre remove synced projects`);
      if (syncStrategy === SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE && failedProjectAwareResults.length === 0 && failedProjectAwareToServerResults.length === 0) {
        const allProjectIdsToSync = allProjectsToSync.map((projectToSync) => projectToSync.project.id);
        await this.removeSyncedProjectDataForOtherProjects(allProjectIdsToSync, {perfMeasurer});
      }
      perfMeasurer.checkpoint(`post remove synced projects`);

      perfMeasurer.checkpoint(`pre final persist`);
      await this.storage.persistPendingChanges();
      perfMeasurer.checkpoint(`post final persist`);

      const end = new Date();
      const durationInSec = Math.round((end.getTime() - start.getTime()) / 1000);

      perfMeasurer.checkpoint(`pre print data sync`);
      await this.printDataSyncSuccessOrError(
        options,
        nonClientAwareResult,
        nonClientAwareToServerResult,
        clientAwareResults,
        clientAwareToServerResults,
        projectAwareResults,
        projectAwareToServerResults,
        durationInSec
      );
      perfMeasurer.checkpoint(`post print data sync`);

      perfMeasurer.checkpoint('Finished (FINISHED)');
      return {
        status: 'FINISHED',
        result: {nonClientAwareResult, clientAwareResults, projectAwareResults},
        syncStarted,
        syncFinished: new Date(),
      };
    } catch (error) {
      perfMeasurer.errored(error);
      perfMeasurer.checkpoint(`pre persist in catch`);
      await this.storage.persistPendingChanges();
      perfMeasurer.checkpoint(`post persist in catch`);
      this.loggingService.error(LOG_SOURCE, `error in startSyncData ${error?.message}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` dataSync`, error);
      if (this.auth && !(error instanceof NetworkError) && this.shouldFireErrorToast(error)) {
        await this.toastMessage(this.translateService.instant('sync_data_fail', {message: error?.message}), TOAST_DURATION_ERROR_IN_MS);
      }
      throw error;
    } finally {
      perfMeasurer.measureTotal();
      const perf = perfMeasurer.getPerf();
      const conflicts: SyncConflict<IdAware>[] = [];

      if (nonClientAwareResult?.conflicts?.length > 0) {
        conflicts.push(...nonClientAwareResult.conflicts);
      }
      for (const res of clientAwareResults) {
        if (res.conflicts?.length > 0) {
          conflicts.push(...res.conflicts);
        }
      }
      for (const res of projectAwareResults) {
        if (res.conflicts?.length > 0) {
          conflicts.push(...res.conflicts);
        }
      }

      this.syncPerformanceStatisticsService.addPerformance(perf, conflicts);
      this.syncStatusService.dataSyncInProgress = {inProgress: false, syncStrategy};
    }
  }

  private async printDataSyncIntegrityConflicts(
    nonClientAwareResult: LocalSyncNonClientAwareResponse<any>,
    clientAwareResults?: Array<LocalSyncClientAwareResponse<any>>,
    projectAwareResults?: Array<LocalSyncProjectAwareResponse<any>>
  ) {
    try {
      const allConflicts: SyncConflict<IdAware>[] = [
        ...(nonClientAwareResult?.conflicts ?? []),
        ...(clientAwareResults?.reduce((acc, arr) => acc.concat(arr.conflicts), []) ?? []),
        ...(projectAwareResults?.reduce((acc, arr) => acc.concat(arr.conflicts), []) ?? []),
      ];

      const deletedConflicts = allConflicts.filter((conflict) => conflict.type === ConflictType.MODIFIED_LOCAL_DEPENDENCY_DELETED_SERVER);
      const recoveryConflicts = allConflicts.filter((conflict) => conflict.type === ConflictType.PROTOCOL_REMOVED_ENTRIES_RECOVERED);

      if (deletedConflicts.length > 0) {
        const deletedList = Object.values(_.groupBy(deletedConflicts, 'storageKey') as Record<string, SyncConflict<IdAware>[]>)
          .map((conflicts) => `- ${conflicts[0].storageKey}: ${conflicts.length}`)
          .join('\n');

        await this.systemEventService.logErrorEvent(LOG_SOURCE, `Sync conflicts: deleted objects:\n${deletedList}`);
      }

      if (recoveryConflicts.length > 0) {
        const recoveryProtocolIds = (_.uniqBy(recoveryConflicts, _.iteratee('context.recoveryProtocol.id')) as SyncConflict<IdAware>[])
          .map(({context}) => context?.recoveryProtocol?.id)
          .filter((v) => Boolean(v));

        await this.systemEventService.logErrorEvent(LOG_SOURCE, `Sync conflicts: recovered ${recoveryConflicts.length} entries; recovered protocol ids: ${recoveryProtocolIds.join(',')}`);
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Ignoring error in printDataSyncIntegrityConflicts. ${error?.message || error}`);
    }
  }

  private async printDataSyncSuccessOrError(
    options: SyncOptions | undefined,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<any>,
    nonClientAwareToServerResult?: LocalSyncToServerResponse,
    clientAwareResults?: Array<LocalSyncClientAwareResponse<any>>,
    clientAwareToServerResults?: Array<LocalSyncToServerResponse>,
    projectAwareResults?: Array<LocalSyncProjectAwareResponse<any>>,
    projectAwareToServerResults?: Array<LocalSyncToServerResponse>,
    durationInSec?: number
  ): Promise<boolean> {
    if (!this.auth) {
      this.loggingService.warn(LOG_SOURCE, 'not printing error messages as no authentication information is provided. User probably logged out and sync was failing in background.');
      return false;
    }

    const filterFailed = (result: LocalSyncResponse | LocalSyncToServerResponse) => !result.success;
    const filterFailedNotNetworkError = (result: LocalSyncResponse | LocalSyncToServerResponse) => !result.success && !(result.error && result.error instanceof NetworkError);
    const filterUnresolvedSyncConflict = (result: LocalSyncResponse) => result.conflict && result.unresolvedConflict;
    const filterResolvedSyncConflict = (result: LocalSyncResponse) => result.conflict && !result.unresolvedConflict;

    const failedNonClientAware = filterFailed(nonClientAwareResult);
    const failedNonClientAwareNotNetwork = filterFailedNotNetworkError(nonClientAwareResult);
    const unresolvedSyncConflictNonClientAware = filterUnresolvedSyncConflict(nonClientAwareResult);
    const resolvedSyncConflictNonClientAware = filterResolvedSyncConflict(nonClientAwareResult);
    const failedNonClientAwareToServer = nonClientAwareToServerResult ? filterFailed(nonClientAwareToServerResult) : false;
    const failedNonClientAwareToServerNotNetworkError = nonClientAwareToServerResult ? filterFailed(nonClientAwareToServerResult) : false;

    const failedClientAware = clientAwareResults ? clientAwareResults.filter(filterFailed) : [];
    const failedClientAwareNotNetworkError = clientAwareResults ? clientAwareResults.filter(filterFailedNotNetworkError) : [];
    const unresolvedSyncConflictClientAware = clientAwareResults ? clientAwareResults.filter(filterUnresolvedSyncConflict) : [];
    const resolvedSyncConflictClientAware = clientAwareResults ? clientAwareResults.filter(filterResolvedSyncConflict) : [];
    const failedClientAwareToServer = clientAwareToServerResults ? clientAwareToServerResults.filter(filterFailed) : [];
    const failedClientAwareToServerNotNetworkError = clientAwareToServerResults ? clientAwareToServerResults.filter(filterFailedNotNetworkError) : [];

    const failedProjectAware = projectAwareResults ? projectAwareResults.filter(filterFailed) : [];
    const failedProjectAwareNotNetworkError = projectAwareResults ? projectAwareResults.filter(filterFailedNotNetworkError) : [];
    const unresolvedSyncConflictProjectAware = projectAwareResults ? projectAwareResults.filter(filterUnresolvedSyncConflict) : [];
    const resolvedSyncConflictProjectAware = projectAwareResults ? projectAwareResults.filter(filterResolvedSyncConflict) : [];
    const failedProjectAwareToServer = projectAwareToServerResults ? projectAwareToServerResults.filter(filterFailed) : [];
    const failedProjectAwareToServerNotNetworkError = projectAwareToServerResults ? projectAwareToServerResults.filter(filterFailedNotNetworkError) : [];

    await this.printDataSyncIntegrityConflicts(nonClientAwareResult, clientAwareResults, projectAwareResults);

    if (failedNonClientAwareNotNetwork || failedClientAwareNotNetworkError.length || failedProjectAwareNotNetworkError.length) {
      const error = nonClientAwareResult.error || _.head(failedClientAwareNotNetworkError)?.error || _.head(failedProjectAwareNotNetworkError)?.error;
      if (this.shouldFireErrorToast(error)) {
        this.toastMessage(this.translateService.instant('sync_data_fail', {message: error?.message}), TOAST_DURATION_ERROR_IN_MS);
      }
      this.loggingService.error(LOG_SOURCE, `Sync data from server failed. Reason: "${error?.message}"`);
      this.systemEventService.logErrorEvent('Sync data from Server', error);
      return true;
    }

    if (options.showInfoToastMessages && (failedNonClientAware || failedClientAware.length || failedProjectAware.length)) {
      const error = nonClientAwareResult.error || _.head(failedClientAware)?.error || _.head(failedProjectAware)?.error;
      if (this.shouldFireErrorToast(error)) {
        await this.toastMessage(this.translateService.instant('sync_data_fail', {message: error.message}), TOAST_DURATION_ERROR_IN_MS);
      }
      this.loggingService.error(LOG_SOURCE, `Sync data from server failed (Network Error). Reason: "${error?.message}"`);
      this.systemEventService.logErrorEvent('Sync data from Server (Network Error)', error);
      return true;
    }

    if (options.showInfoToastMessages && (failedNonClientAwareToServer || failedClientAwareToServer.length || failedProjectAwareToServer.length)) {
      const error = nonClientAwareToServerResult.error || _.head(failedClientAwareToServer)?.error || _.head(failedProjectAware)?.error;
      const errorCode =
        nonClientAwareToServerResult.errorResponse?.errorCode || _.head(failedClientAwareToServer)?.errorResponse?.errorCode || _.head(failedProjectAwareToServer)?.errorResponse?.errorCode;
      const message = errorCode ? this.translateService.instant('errorCodeMessages.' + errorCode) : error?.message || '';
      if (this.shouldFireErrorToast(error)) {
        await this.toastMessage(this.translateService.instant('sync_data_to_server_fail', {message}), TOAST_DURATION_ERROR_IN_MS);
      }
      this.loggingService.error(LOG_SOURCE, `Sync data from server failed (Network Error). Reason: "${message}"`);
      this.systemEventService.logErrorEvent('Sync data from Server (Network Error)', message);
      return true;
    }

    if (unresolvedSyncConflictNonClientAware || unresolvedSyncConflictClientAware.length || unresolvedSyncConflictProjectAware.length) {
      await this.toastMessageWithButton(
        this.translateService.instant('sync_data_success_unresolved_conflict', {duration: durationInSec}),
        'details',
        () => this.showSyncConflictDialog(nonClientAwareResult, clientAwareResults, projectAwareResults),
        TOAST_DURATION_INFO_DETAIL_BUTTON_IN_MS
      );
      this.loggingService.error(LOG_SOURCE, `Sync data from server failed with unresolved Sync conflicts.`);
      this.systemEventService.logErrorEvent('Sync data from Server', 'Unresolved sync conflicts');
      try {
        const message = _.truncate(
          'Unresolved sync conflicts.. ' +
            `resolvedSyncConflictNonClientAware: ${resolvedSyncConflictNonClientAware}, ` +
            `resolvedSyncConflictClientAware: ${this.conflictsToJsonFromSyncResponse(resolvedSyncConflictClientAware)}, ` +
            `resolvedSyncConflictProjectAware: ${this.conflictsToJsonFromSyncResponse(resolvedSyncConflictProjectAware)}`,
          {length: SYSTEM_EVENT_MAX_LENGTH}
        );
        this.systemEventService.logErrorEvent('Sync data from Server', message);
      } catch (error) {
        // ignore
      }
      return true;
    }

    if (resolvedSyncConflictNonClientAware || resolvedSyncConflictClientAware.length || resolvedSyncConflictProjectAware.length) {
      await this.toastMessageWithButton(
        this.translateService.instant('sync_data_success_resolved_conflict', {duration: durationInSec}),
        'details',
        () => this.showSyncConflictDialog(nonClientAwareResult, clientAwareResults, projectAwareResults),
        TOAST_DURATION_INFO_DETAIL_BUTTON_IN_MS
      );
      this.loggingService.info(LOG_SOURCE, `Sync data from server successful with resolved Sync conflicts in ${durationInSec} seconds.`);
      try {
        const message = _.truncate(
          `Successful with resolved sync conflicts in ${durationInSec} seconds. ` +
            `resolvedSyncConflictNonClientAware: ${resolvedSyncConflictNonClientAware}, ` +
            `resolvedSyncConflictClientAware: ${this.conflictsToJsonFromSyncResponse(resolvedSyncConflictClientAware)}, ` +
            `resolvedSyncConflictProjectAware: ${this.conflictsToJsonFromSyncResponse(resolvedSyncConflictProjectAware)}`,
          {length: SYSTEM_EVENT_MAX_LENGTH}
        );
        this.systemEventService.logErrorEvent('Sync data from Server', message);
      } catch (error) {
        // ignore
      }
      return true;
    }

    if (failedNonClientAwareToServerNotNetworkError || failedClientAwareToServerNotNetworkError.length || failedProjectAwareToServerNotNetworkError.length) {
      const error = nonClientAwareToServerResult.error || _.head(failedClientAwareToServerNotNetworkError)?.error || _.head(failedProjectAwareNotNetworkError)?.error;
      const errorCode =
        nonClientAwareToServerResult.errorResponse?.errorCode ||
        _.head(failedClientAwareToServerNotNetworkError)?.errorResponse?.errorCode ||
        _.head(failedProjectAwareToServerNotNetworkError)?.errorResponse?.errorCode;
      const message = errorCode ? this.translateService.instant('errorCodeMessages.' + errorCode) : error?.message || '';
      if (this.shouldFireErrorToast(error)) {
        await this.toastMessage(this.translateService.instant('sync_data_to_server_fail', {message}), TOAST_DURATION_ERROR_IN_MS);
      }
      this.loggingService.error(LOG_SOURCE, `Sync data to server failed. Reason: "${message}"`);
      this.systemEventService.logErrorEvent('Sync data to Server', message);
      return true;
    }

    this.loggingService.info(LOG_SOURCE, `Sync data successful in ${durationInSec} seconds.`);
    this.systemEventService.logEvent('Sync data', `Successful in ${durationInSec} seconds.`);
    if (options?.showInfoToastMessages) {
      await this.toastMessage(this.translateService.instant('sync_data_success', {duration: durationInSec}), TOAST_DURATION_INFO_IN_MS);
    }
    return true;
  }

  private conflictsToJsonFromSyncResponse(
    localSyncResponses: Array<LocalSyncNonClientAwareResponse<any>> | Array<LocalSyncClientAwareResponse<any>> | Array<LocalSyncProjectAwareResponse<any>>
  ): string {
    try {
      const dataWithConflict = localSyncResponses.filter((localSyncResponse) => localSyncResponse.conflict).map((localSyncResponse) => localSyncResponse.conflicts);
      return JSON.stringify(dataWithConflict);
    } catch (error) {
      // ignore;
      return 'error converting json';
    }
  }

  private async showSyncConflictDialog<T extends IdAware>(
    nonClientAwareResult: LocalSyncNonClientAwareResponse<T>,
    clientAwareResults: Array<LocalSyncClientAwareResponse<T>>,
    projectAwareResults: Array<LocalSyncProjectAwareResponse<T>>
  ) {
    const modal = await this.modalController.create({
      component: SyncConflictsComponent,
      componentProps: {
        nonClientAwareResult,
        clientAwareResults,
        projectAwareResults,
      },
    });
    await modal.present();
  }

  private fileSizeIndicatingPotentialCorruptFile(fileSize: number): boolean {
    return fileSize < ATTACHMENT_SIZE_IN_BYTES_PROBABLY_CORRUPT_FILE_WITH_DEVIATION;
  }

  private async getSyncedAttachmentValuesInsertedOrToUploadAgain<T extends Attachment>(syncUtilResponse: SyncUtilResponse<T>, fileAccessUtil: AbstractFileAccessUtil): Promise<Array<T>> {
    const filteredAttachments = new Array<T>();
    for (const syncedValue of syncUtilResponse.syncedValues) {
      const attachmentInsertedLocally = syncUtilResponse.localChangesData.localChangesInsertById.has(syncedValue.id);
      if (attachmentInsertedLocally) {
        filteredAttachments.push(syncedValue);
        continue;
      }
      if (!syncedValue.filePath) {
        continue; // file is not on the client either. No further checks make sense
      }
      const serverFileSize = syncUtilResponse.serverFileSizes ? syncUtilResponse.serverFileSizes[syncedValue.id] : undefined;
      if (serverFileSize === undefined) {
        continue; // no way to tell, whether or not the file is already on the server.
      }
      if (serverFileSize === null) {
        // file is definitely NOT on the server
        if (!(await isAttachmentScheduledForUpload(syncedValue, fileAccessUtil))) {
          filteredAttachments.push(syncedValue);
        }
        continue;
      }
      if (this.fileSizeIndicatingPotentialCorruptFile(serverFileSize)) {
        const localFileSize = await fileAccessUtil.attachmentSize(syncedValue, 'filePath');
        if (localFileSize !== undefined && !equalWithDeviation(serverFileSize, localFileSize)) {
          filteredAttachments.push(syncedValue); // server file is probably corrupt. Upload it again.
          continue;
        }
      }
    }
    return filteredAttachments;
  }

  private async scheduleAttachmentsForUpload(
    projectAwareResults: Array<LocalSyncProjectAwareResponse<any>>,
    clientAwareResults: Array<LocalSyncClientAwareResponse<any>>,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<any>,
    ignoreAttachmentVersion = false
  ) {
    this.loggingService.debug(LOG_SOURCE, 'scheduleAttachmentsForUpload called.');
    this.systemEventService.logEvent('SyncService', 'scheduleAttachmentsForUpload started');
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    await this.scheduleFilesForUpload(
      this.auth?.token,
      await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(nonClientAwareResult.data.attachmentClients, fileAccessUtil),
      ignoreAttachmentVersion
    );
    await this.scheduleFilesForUpload(
      this.auth?.token,
      await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(nonClientAwareResult.data.attachmentUserEmailSignatures, fileAccessUtil),
      ignoreAttachmentVersion
    );
    for (const clientAwareResult of clientAwareResults) {
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(clientAwareResult.data.attachmentProjectImages, fileAccessUtil),
        ignoreAttachmentVersion
      );
    }
    for (const projectAwareResult of projectAwareResults) {
      if (!projectAwareResult.data) {
        this.loggingService.warn(LOG_SOURCE, `projectAwareResult for project ${projectAwareResult?.projectId} does not have data.`);
        continue;
      }
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentProjects, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentProjectBanners, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentProtocolEntries, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentChats, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.pdfPlanAttachments, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(this.auth?.token, await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.pdfPlanPages, fileAccessUtil), ignoreAttachmentVersion);
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentReportCompanies, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentReportActivities, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentReportEquipments, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentReportMaterials, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentReportSignatures, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentProtocolSignatures, fileAccessUtil),
        ignoreAttachmentVersion
      );
      await this.scheduleFilesForUpload(
        this.auth?.token,
        await this.getSyncedAttachmentValuesInsertedOrToUploadAgain(projectAwareResult.data.attachmentBimMarkerScreenshots, fileAccessUtil),
        ignoreAttachmentVersion
      );
    }
    this.loggingService.debug(LOG_SOURCE, 'scheduleAttachmentsForUpload finished.');
    this.systemEventService.logEvent('SyncService', 'scheduleAttachmentsForUpload finished');
  }

  private async downloadAttachments(
    syncStrategy: SyncStrategy,
    projectAwareResults: Array<LocalSyncProjectAwareResponse<any>>,
    clientAwareResults: Array<LocalSyncClientAwareResponse<any>>,
    nonClientAwareResult: LocalSyncNonClientAwareResponse<any>,
    dataSyncStarted: Date,
    options: SyncOptions
  ): Promise<void> {
    if (this.syncStatusService.attachmentSyncInProgress?.inProgress) {
      if (options?.showInfoToastMessages) {
        await this.toastMessage(this.translateService.instant('sync_attachments_already_in_progress'), 10000);
      }
      this.systemEventService.logEvent('SyncService', `downloadAttachments(syncStrategy=${syncStrategy}) already in progress.`);
      return;
    }
    this.loggingService.info(LOG_SOURCE, 'downloadAttachments - starting download.');
    const startTime = new Date().getTime();
    try {
      this.assertAuthenticated();
      this.syncStatusService.attachmentSyncInProgress = {inProgress: true, syncStrategy};
      const start = new Date();

      this.systemEventService.logEvent('SyncService', `downloadAttachments(syncStrategy=${syncStrategy}) started.`);

      const attachmentClientChanged = await this.syncAllFilesConcurrently(
        this.auth?.token,
        'THUMBNAIL_AND_IMAGE',
        nonClientAwareResult.data.attachmentClients.syncedValues,
        dataSyncStarted,
        nonClientAwareResult.data.attachmentClients.newValues,
        nonClientAwareResult.data.attachmentClients.changedValues,
        _.merge(nonClientAwareResult.data.attachmentClients.deletedValues, nonClientAwareResult.data.attachmentClients.localChangesDelete)
      );
      if (attachmentClientChanged) {
        this.attachmentClientDataService.attachmentFilesChangedExternally();
      }

      const attachmentUserEmailSignatureChanged = await this.syncAllFilesConcurrently(
        this.auth?.token,
        'THUMBNAIL_AND_IMAGE',
        nonClientAwareResult.data.attachmentUserEmailSignatures.syncedValues,
        dataSyncStarted,
        nonClientAwareResult.data.attachmentUserEmailSignatures.newValues,
        nonClientAwareResult.data.attachmentUserEmailSignatures.changedValues,
        _.merge(nonClientAwareResult.data.attachmentUserEmailSignatures.deletedValues, nonClientAwareResult.data.attachmentUserEmailSignatures.localChangesDelete)
      );
      if (attachmentUserEmailSignatureChanged) {
        this.attachmentUserEmailSignatureDataService.attachmentFilesChangedExternally();
      }

      for (const clientAwareResult of clientAwareResults) {
        const attachmentProjectImageChanged = await this.syncAllFilesConcurrently(
          this.auth?.token,
          'THUMBNAIL_AND_IMAGE',
          clientAwareResult.data.attachmentProjectImages.syncedValues,
          dataSyncStarted,
          clientAwareResult.data.attachmentProjectImages.newValues,
          clientAwareResult.data.attachmentProjectImages.changedValues,
          _.merge(clientAwareResult.data.attachmentProjectImages.deletedValues, clientAwareResult.data.attachmentProjectImages.localChangesDelete),
          clientAwareResult.clientId
        );
        if (attachmentProjectImageChanged) {
          this.attachmentProjectImageDataService.attachmentFilesChangedExternally();
        }
      }

      for (const projectAwareResult of projectAwareResults) {
        if (projectAwareResult.success) {
          const attachmentSyncMode = projectAwareResult.projectSyncRequest.attachmentSyncMode;
          const attachmentProjectsChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentProjects.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentProjects.newValues,
            projectAwareResult.data.attachmentProjects.changedValues,
            _.merge(projectAwareResult.data.attachmentProjects.deletedValues, projectAwareResult.data.attachmentProjects.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentProjectsChanged) {
            this.attachmentProjectDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentProjectBannersChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentProjectBanners.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentProjectBanners.newValues,
            projectAwareResult.data.attachmentProjectBanners.changedValues,
            _.merge(projectAwareResult.data.attachmentProjectBanners.deletedValues, projectAwareResult.data.attachmentProjectBanners.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentProjectBannersChanged) {
            this.attachmentProjectBannerDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          let attachmentProtocolEntriesChanged = false;
          if (attachmentSyncMode === 'THUMBNAIL_AND_IMAGE_FOR_OPEN') {
            const filterClosed = filterClosedProtocolEntry(projectAwareResult.data.protocols.syncedValues, projectAwareResult.data.protocolEntries.syncedValues);
            const changed1 = await this.syncAllFilesConcurrently(
              this.auth?.token,
              'THUMBNAIL',
              projectAwareResult.data.attachmentProtocolEntries.syncedValues.filter(filterClosed),
              dataSyncStarted,
              projectAwareResult.data.attachmentProtocolEntries.newValues.filter(filterClosed),
              projectAwareResult.data.attachmentProtocolEntries.changedValues.filter((value) => filterClosed(value.localValue)),
              _.merge(projectAwareResult.data.attachmentProtocolEntries.deletedValues.filter(filterClosed), projectAwareResult.data.attachmentProtocolEntries.localChangesDelete),
              projectAwareResult.projectId
            );
            const changed2 = await this.syncAllFilesConcurrently(
              this.auth?.token,
              'THUMBNAIL_AND_IMAGE',
              projectAwareResult.data.attachmentProtocolEntries.syncedValues.filter((value) => !filterClosed(value)),
              dataSyncStarted,
              projectAwareResult.data.attachmentProtocolEntries.newValues.filter((value) => !filterClosed(value)),
              projectAwareResult.data.attachmentProtocolEntries.changedValues.filter((value) => !filterClosed(value.localValue)),
              _.merge(
                projectAwareResult.data.attachmentProtocolEntries.deletedValues.filter((value) => !filterClosed(value)),
                projectAwareResult.data.attachmentProtocolEntries.localChangesDelete
              ),
              projectAwareResult.projectId
            );
            attachmentProtocolEntriesChanged = attachmentProtocolEntriesChanged || changed1 || changed2;
          } else {
            const changed1 = await this.syncAllFilesConcurrently(
              this.auth?.token,
              attachmentSyncMode,
              projectAwareResult.data.attachmentProtocolEntries.syncedValues,
              dataSyncStarted,
              projectAwareResult.data.attachmentProtocolEntries.newValues,
              projectAwareResult.data.attachmentProtocolEntries.changedValues,
              _.merge(projectAwareResult.data.attachmentProtocolEntries.deletedValues, projectAwareResult.data.attachmentProtocolEntries.localChangesDelete),
              projectAwareResult.projectId
            );
            attachmentProtocolEntriesChanged = attachmentProtocolEntriesChanged || changed1;
          }
          if (attachmentProtocolEntriesChanged) {
            this.attachmentChatDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentChatsChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentChats.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentChats.newValues,
            projectAwareResult.data.attachmentChats.changedValues,
            _.merge(projectAwareResult.data.attachmentChats.deletedValues, projectAwareResult.data.attachmentChats.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentChatsChanged) {
            this.attachmentChatDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const pdfPlanAttachmentsChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.pdfPlanAttachments.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.pdfPlanAttachments.newValues,
            projectAwareResult.data.pdfPlanAttachments.changedValues,
            _.merge(projectAwareResult.data.pdfPlanAttachments.deletedValues, projectAwareResult.data.pdfPlanAttachments.localChangesDelete),
            projectAwareResult.projectId
          );
          if (pdfPlanAttachmentsChanged) {
            this.pdfPlanAttachmentDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const pdfPlanPageChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.pdfPlanPages.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.pdfPlanPages.newValues,
            projectAwareResult.data.pdfPlanPages.changedValues,
            _.merge(projectAwareResult.data.pdfPlanPages.deletedValues, projectAwareResult.data.pdfPlanPages.localChangesDelete),
            projectAwareResult.projectId
          );
          if (pdfPlanPageChanged) {
            this.pdfPlanPageDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentBimMarkerScreenshotsChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentBimMarkerScreenshots.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentBimMarkerScreenshots.newValues,
            projectAwareResult.data.attachmentBimMarkerScreenshots.changedValues,
            _.merge(projectAwareResult.data.attachmentBimMarkerScreenshots.deletedValues, projectAwareResult.data.attachmentBimMarkerScreenshots.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentBimMarkerScreenshotsChanged) {
            this.attachmentBimMarkerScreenshotDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentReportCompanyChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentReportCompanies.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentReportCompanies.newValues,
            projectAwareResult.data.attachmentReportCompanies.changedValues,
            _.merge(projectAwareResult.data.attachmentReportCompanies.deletedValues, projectAwareResult.data.attachmentReportCompanies.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentReportCompanyChanged) {
            this.attachmentReportCompanyDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentActivityChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentReportActivities.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentReportActivities.newValues,
            projectAwareResult.data.attachmentReportActivities.changedValues,
            _.merge(projectAwareResult.data.attachmentReportActivities.deletedValues, projectAwareResult.data.attachmentReportActivities.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentActivityChanged) {
            this.attachmentReportActivityDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentEquipmentChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentReportEquipments.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentReportEquipments.newValues,
            projectAwareResult.data.attachmentReportEquipments.changedValues,
            _.merge(projectAwareResult.data.attachmentReportEquipments.deletedValues, projectAwareResult.data.attachmentReportEquipments.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentEquipmentChanged) {
            this.attachmentReportEquipmentDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentMaterialChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentReportMaterials.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentReportMaterials.newValues,
            projectAwareResult.data.attachmentReportMaterials.changedValues,
            _.merge(projectAwareResult.data.attachmentReportMaterials.deletedValues, projectAwareResult.data.attachmentReportMaterials.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentMaterialChanged) {
            this.attachmentReportMaterialDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentSignaturesChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentReportSignatures.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentReportSignatures.newValues,
            projectAwareResult.data.attachmentReportSignatures.changedValues,
            _.merge(projectAwareResult.data.attachmentReportSignatures.deletedValues, projectAwareResult.data.attachmentReportSignatures.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentSignaturesChanged) {
            this.attachmentReportSignatureDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }

          const attachmentProtocolSignaturesChanged = await this.syncAllFilesConcurrently(
            this.auth?.token,
            attachmentSyncMode,
            projectAwareResult.data.attachmentProtocolSignatures.syncedValues,
            dataSyncStarted,
            projectAwareResult.data.attachmentReportSignatures.newValues,
            projectAwareResult.data.attachmentProtocolSignatures.changedValues,
            _.merge(projectAwareResult.data.attachmentProtocolSignatures.deletedValues, projectAwareResult.data.attachmentProtocolSignatures.localChangesDelete),
            projectAwareResult.projectId
          );
          if (attachmentProtocolSignaturesChanged) {
            this.attachmentProtocolSignatureDataService.attachmentFilesChangedExternally(projectAwareResult.projectId);
          }
        }
      }

      const end = new Date();
      const durationInSec = Math.round((end.getTime() - start.getTime()) / 1000);
      if (options?.showInfoToastMessages) {
        await this.toastMessage(this.translateService.instant('sync_attachments_success', {duration: durationInSec}), TOAST_DURATION_INFO_IN_MS);
      }
    } catch (error) {
      const errorMessage = error?.message || error;
      this.loggingService.error(LOG_SOURCE, 'downloadAttachments failed with error ' + errorMessage);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` downloadAttachments`, error);
      if (this.auth && !(error instanceof NetworkError)) {
        if (isQuotaExceededError(errorMessage)) {
          await this.attachmentService.showToastQuotaExceeded();
        } else if (this.shouldFireErrorToast(error)) {
          await this.toastMessage(this.translateService.instant('sync_attachments_fail', {errorMessage}), TOAST_DURATION_ERROR_IN_MS);
        }
      }
      this.systemEventService.logErrorEvent('SyncService', `downloadAttachments(syncStrategy=${syncStrategy}) failed ${errorMessage}.`);
    } finally {
      this.loggingService.info(LOG_SOURCE, `downloadAttachments finished in ${new Date().getTime() - startTime} ms`);
      this.syncStatusService.attachmentSyncInProgress = {inProgress: false, syncStrategy};
      this.systemEventService.logEvent('SyncService', `downloadAttachments(syncStrategy=${syncStrategy}) finished in ${new Date().getTime() - startTime} ms.`);
    }
  }

  private async removeSyncedProjectDataForOtherProjects(projectIdsToKeep: Array<IdType>, context: DataSyncContext) {
    const {perfMeasurer} = context;
    const offloadedProjectIds = new Set<IdType>();

    perfMeasurer.checkpoint('pre remove PA data');
    for (const key of Object.keys(this.dataServiceFactoryService.projectAwareServices)) {
      const projectAwareDataService = this.dataServiceFactoryService.projectAwareServices[key];
      const {deletedElements, deletedProjectIds} = await projectAwareDataService.removeAllStorageDataButForProjects(projectIdsToKeep, {immediate: false, ensureStored: false});
      if (deletedElements.length && this.dataServiceFactoryService.attachmentServices[key]) {
        await this.deleteAttachments(deletedElements);
      }
      deletedProjectIds.forEach((projectId) => offloadedProjectIds.add(projectId));
    }
    perfMeasurer.checkpoint('post remove PA data');

    perfMeasurer.checkpoint('pre log offloaded');
    if (offloadedProjectIds.size) {
      const offlineProjects = await observableToPromise(this.userDeviceOfflineProjectDataService.data);
      for (const projectId of offloadedProjectIds) {
        if (offlineProjects.some((project) => project.projectId === projectId)) {
          this.loggingService.info(LOG_SOURCE, `removeSyncedProjectDataForOtherProjects - Project ${projectId} offloaded (was downloaded)`);
          this.systemEventService.logEvent('removeSyncedProjectDataForOtherProjects', () => `removeSyncedProjectDataForOtherProjects - Project ${projectId} offloaded (was downloaded)`);
          this.posthogService.captureEvent('[Projects] Project offloaded', {projectState: 'downloaded'});
        } else {
          this.loggingService.info(LOG_SOURCE, `removeSyncedProjectDataForOtherProjects - Project ${projectId} offloaded (was available)`);
          this.systemEventService.logEvent('removeSyncedProjectDataForOtherProjects', () => `removeSyncedProjectDataForOtherProjects - Project ${projectId} offloaded (was available)`);
          this.posthogService.captureEvent('[Projects] Project offloaded', {projectState: 'available'});
        }
      }
    }
    perfMeasurer.checkpoint('post log offloaded');

    perfMeasurer.checkpoint('pre remove sync history');
    await this.syncHistoryService.removeAllButForProjects(projectIdsToKeep, {mutationOptions: {immediate: false, ensureStored: false}});
    perfMeasurer.checkpoint('post remove sync history');
  }

  private async syncAllFilesConcurrently(
    authenticationToken: string | undefined,
    attachmentSyncMode: AttachmentSyncMode,
    attachments: Array<Attachment>,
    dataSyncStarted: Date,
    newAttachments?: Array<Attachment>,
    changedAttachments?: Array<{localValue: Attachment; serverValue: Attachment}>,
    deletedAttachments?: Array<Attachment>,
    clientOrProjectId?: IdType
  ): Promise<boolean> {
    if (!authenticationToken) {
      throw new Error('Unable to start syncAllFilesConcurrently because authenticationToken is undefined (user probably logged out)');
    }
    if (!attachments.length && !newAttachments.length && !changedAttachments.length && !deletedAttachments.length) {
      return;
    }
    this.systemEventService.logEvent(
      LOG_SOURCE + '.syncAllFilesConcurrently',
      () =>
        `Called for clientOrProject ${clientOrProjectId}. newAttachments=${newAttachments?.map((a) => a.id)?.join()}, changedAttachments=${changedAttachments
          ?.map((a) => a?.localValue?.id)
          ?.join()}, deletedAttachments=${deletedAttachments?.map((a) => a.id)?.join()}`
    );
    this.loggingService.debug(LOG_SOURCE, 'syncAllFilesConcurrently called.');
    this.assertAuthenticated();
    const deviceUuid = await this.getDeviceUuid();
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    if (typeof Worker !== 'undefined' && fileAccessUtil.webWorkerSupported) {
      this.loggingService.debug(LOG_SOURCE, 'Web-Worker supported.');
      // eslint-disable-next-line
      const worker = new Worker(new URL('../sync/download-attachments-web.worker', import.meta.url), {type: 'module'});
      const message = {
        authenticationToken,
        deviceUuid,
        fileAccessUtilClassName: fileAccessUtil.className,
        mediaUrl: fileAccessUtil.mediaUrl,
        attachmentSyncMode,
        attachments,
        newAttachments,
        changedAttachments,
        deletedAttachments,
        clientOrProjectId,
        dataSyncStarted,
      };
      await this.tokenManagerService.startWorkerWithAuthToken(worker, message);
    } else {
      this.loggingService.warn(LOG_SOURCE, 'Web-Worker is not supported.');
      await this.tokenManagerService.runWithTokenGetter(async (tokenGetter) => {
        await syncAttachmentsConcurrently(
          tokenGetter,
          deviceUuid,
          fileAccessUtil,
          attachmentSyncMode,
          attachments,
          dataSyncStarted,
          newAttachments,
          changedAttachments,
          deletedAttachments,
          clientOrProjectId
        );
      });
    }
  }

  private async scheduleFilesForUpload(
    authenticationToken: string | undefined,
    attachments?: Array<Attachment>,
    ignoreAttachmentVersion = false
  ): Promise<{errorAttachments: Array<Attachment>} | undefined> {
    if (!authenticationToken) {
      throw new Error('Unable to start scheduleFilesForUpload because authenticationToken is undefined (user probably logged out)');
    }
    if (!attachments?.length) {
      return;
    }

    try {
      this.loggingService.debug(LOG_SOURCE, `scheduleFilesForUpload called for ${attachments.length} attachments.`);
      this.systemEventService.logEvent(LOG_SOURCE + '.scheduleFilesForUpload', () => `called for the following attachments. ${attachments?.map((a) => a?.id)?.join()}`);
      const errors = [];
      const successAttachments: Array<Attachment> = [];
      const errorAttachments: Array<Attachment> = [];
      for (const attachment of attachments) {
        try {
          const success = await scheduleUploadMediaFile(attachment, authenticationToken, await this.attachmentSettingService.getFileAccessUtil(), ignoreAttachmentVersion);
          if (success) {
            successAttachments.push(attachment);
          } else {
            this.loggingService.warn(LOG_SOURCE, `Unable to scheduleUploadMediaFile for attachment ${attachment?.id} since the attachment-file does not exist.`);
            this.systemEventService.logErrorEvent(
              LOG_SOURCE + '.scheduleFilesForUpload',
              `Unable to scheduleUploadMediaFile for attachment ${attachment?.id} since the attachment-file does not exist.`
            );
          }
        } catch (error) {
          errors.push(error);
          errorAttachments.push(attachment);
        }
      }
      if (errors.length) {
        const errorMessagesString = errors.map((error) => convertErrorToMessage(error)).join(',');
        const successCount = attachments.length - errors.length;
        const logMessage = `scheduleFilesForUpload successfully processed ${successCount} attachments but failed ${errors.length} attachments. FailingAttachments=${errorAttachments
          ?.map((a) => a?.id)
          ?.join()}, ErrorMessages="${errorMessagesString}".`;
        this.loggingService.error(LOG_SOURCE, logMessage);
        this.systemEventService.logErrorEvent(LOG_SOURCE + '.scheduleFilesForUpload', logMessage);
        await this.toastMessage(this.translateService.instant('sync_upload_attachments_failed', {errorMessage: errorMessagesString}), TOAST_DURATION_ERROR_IN_MS);
        await this.syncStatusService.updateNumberOfMediaFilesScheduledForUpload();
        return {errorAttachments};
      } else {
        await this.syncStatusService.updateNumberOfMediaFilesScheduledForUpload();
        this.loggingService.debug(LOG_SOURCE, `scheduleFilesForUpload finished for ${attachments.length} attachments.`);
        this.systemEventService.logEvent(LOG_SOURCE + '.scheduleFilesForUpload', () => `finished for the following attachments. ${attachments?.map((a) => a?.id)?.join()}`);
      }
    } catch (error) {
      const message = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, `scheduleFilesForUpload failed with error ${message}.`);
      this.systemEventService.logErrorEvent(LOG_SOURCE + '.scheduleFilesForUpload', error);
      throw error;
    }
  }

  private async getDeviceUuid(): Promise<string> {
    if (this.auth?.impersonated) {
      return SUPERUSER_DEVICE_UUID;
    }
    const deviceId = await Device.getId();
    return deviceId.identifier;
  }

  /**
   * Uploads all files from the upload queue to the server.
   *
   * @param authenticationToken
   * @private
   * @returns returns the number of successfully upload attachments or null if the upload is already in progress.
   */
  private async uploadAllFilesFromMediaQueue(authenticationToken: string | undefined, ignoreAttachmentVersion = false): Promise<number | null> {
    if (this.devModeService.enabled && this.devModeService.settings.disableUploadAttachments) {
      this.loggingService.warn(LOG_SOURCE, 'DevMode enabled. Skipping uploadAllFilesFromMediaQueue.');
      return null;
    }
    if (!authenticationToken) {
      throw new Error('Unable to start uploadAllFilesFromMediaQueue because authenticationToken is undefined (user probably logged out)');
    }
    if (this.syncStatusService.attachmentUploadInProgress) {
      this.loggingService.info(LOG_SOURCE, 'uploadAllFilesFromMediaQueue - Attachment upload already in progress.');
      this.systemEventService.logEvent('SyncService', 'uploadAllFilesFromMediaQueue already in progress');
      return null;
    }
    const startTime = new Date().getTime();
    this.loggingService.debug(LOG_SOURCE, 'uploadAllFilesFromMediaQueue called.');
    try {
      this.assertAuthenticated();
      const deviceUuid = await this.getDeviceUuid();
      this.syncStatusService.attachmentUploadInProgress = true;
      this.systemEventService.logEvent('SyncService', 'uploadAllFilesFromMediaQueue started');
      const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
      let result: UploadFilesResult;
      if (typeof Worker !== 'undefined' && typeof FormData !== 'undefined' && fileAccessUtil.webWorkerSupported) {
        this.loggingService.debug(LOG_SOURCE, 'Web-Worker supported.');
        // eslint-disable-next-line
        const worker = new Worker(new URL('../sync/upload-files-from-media-queue-web.worker', import.meta.url), {type: 'module'});
        const message = {authenticationToken, deviceUuid, mediaUrl: fileAccessUtil.mediaUrl, fileAccessUtilClassName: fileAccessUtil.className, ignoreAttachmentVersion};
        result = await this.tokenManagerService.startWorkerWithAuthToken(worker, message);
      } else {
        this.loggingService.warn(LOG_SOURCE, 'Web-Worker is not supported.');
        await this.tokenManagerService.runWithTokenGetter(async (tokenGetter) => {
          result = await uploadFilesFromMediaQueue(fileAccessUtil, tokenGetter, deviceUuid, ignoreAttachmentVersion);
        });
      }
      const durationInSeconds = (new Date().getTime() - startTime) / 1000;
      if (result.warningMessages?.length) {
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' uploadAllFilesFromMediaQueue - warningMessages', result.warningMessages.join(','));
      }
      if (result.totalCount === 0) {
        this.loggingService.debug(LOG_SOURCE, 'uploadAllFilesFromMediaQueue - there was nothing to upload.');
        this.systemEventService.logEvent('SyncService', 'uploadAllFilesFromMediaQueue finished. There was nothing to upload.');
      } else if (result.errorCount > 0) {
        const errorMessages = result.errorMessages.join(',');
        this.loggingService.info(
          LOG_SOURCE,
          `uploadAllFilesFromMediaQueue - Uploaded ${result.successfulCount} successfully but ${result.errorCount} with errors in ${durationInSeconds} seconds. ${errorMessages}`
        );
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' uploadAllFilesFromMediaQueue', errorMessages);
        if (result.errorCountMovedToErrorQueue > 0) {
          const errorMessage = result.errorMessagesMovedToErrorQueue[0];
          await this.toastMessage(
            this.translateService.instant('sync_upload_attachments_with_errors', {successfulCount: result.successfulCount, errorCount: result.errorCountNotNetworkError, errorMessages: errorMessage}),
            TOAST_DURATION_ERROR_IN_MS
          );
        }
      } else {
        const message = `Successfully uploaded ${result.successfulCount} files in ${durationInSeconds} seconds.`;
        this.loggingService.info(LOG_SOURCE, 'uploadAllFilesFromMediaQueue - ' + message);
        await this.systemEventService.logEvent(LOG_SOURCE + ' uploadAllFilesFromMediaQueue', message);
      }
      return result.successfulCount;
    } catch (error) {
      const errorMessage = error?.message || error;
      this.loggingService.error(LOG_SOURCE, 'Error in uploadAllFilesFromMediaQueue: ' + errorMessage);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' uploadAllFilesFromMediaQueue', errorMessage);
      if (!(error instanceof NetworkError) && this.shouldFireErrorToast(error)) {
        await this.toastMessage(this.translateService.instant('sync_upload_attachments_failed', {errorMessage}), TOAST_DURATION_ERROR_IN_MS);
      }
      throw error;
    } finally {
      this.loggingService.info(LOG_SOURCE, `uploadAllFilesFromMediaQueues - finished upload in ${new Date().getTime() - startTime} ms.`);
      this.syncStatusService.attachmentUploadInProgress = false;
      await this.syncStatusService.updateNumberOfMediaFilesScheduledForUpload();
    }
  }

  public async regenerateFilesInMediaQueue(): Promise<{
    regeneratedCount: number;
    uploadCount: number;
  }> {
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    const files = await fileAccessUtil.files('upload');

    let regeneratedCount = 0;

    this.systemEventService.logEvent(LOG_SOURCE + '.regenerateFilesInMediaQueue', () => `called for ${files?.length} files.`);
    for (const file of files) {
      const lastSlash = file.lastIndexOf('/') + 1;
      const attachmentId = file.substr(lastSlash, 36);
      const attachment = await observableToPromise(this.attachmentService.getUnknownAttachmentById(attachmentId));

      if (!attachment) {
        const [err] = await fileAccessUtil.moveToErrorQueue(file);
        if (err) {
          this.loggingService.error(LOG_SOURCE, `regenerateFilesInMediaQueue - ${err}`);
          await this.systemEventService.logErrorEvent(LOG_SOURCE + ' regenerateFilesInMediaQueue', err);
        }
        continue;
      }

      try {
        await this.scheduleFilesForUpload(this.auth.token, [attachment]);
        regeneratedCount++;
      } catch (e) {
        const err = `failed to schedule file for upload (attachmentId=${attachment.id}), error '${convertErrorToMessage(e)}'`;
        this.loggingService.error(LOG_SOURCE, `regenerateFilesInMediaQueue - ${err}`);
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' regenerateFilesInMediaQueue', err);
      }
    }

    return {
      regeneratedCount,
      uploadCount: files.length,
    };
  }

  public async uploadFilesFromErrorQueue(): Promise<number> {
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    const filesMoved = await fileAccessUtil.moveFilesFromUploadErrorToUpload();
    this.systemEventService.logEvent(LOG_SOURCE + '.uploadFilesFromErrorQueue', () => `Moved ${filesMoved} files from upload- to error-queue.`);
    if (filesMoved === 0) {
      return filesMoved;
    }
    await this.uploadAllFilesFromMediaQueue(this.auth?.token);
    return filesMoved;
  }

  private async deleteAttachments(attachments: Array<Attachment>): Promise<any> {
    if (attachments.length === 0) {
      this.systemEventService.logEvent(LOG_SOURCE + '.deleteAttachments', () => 'Called with 0 attachments.');
      return;
    }
    this.systemEventService.logEvent(LOG_SOURCE + '.deleteAttachments', () => `Called for attachments: ${attachments?.map((a) => a.id).join()}`);
    this.assertAuthenticated();
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    if (typeof Worker !== 'undefined' && fileAccessUtil.webWorkerSupported) {
      this.loggingService.debug(LOG_SOURCE, 'Web-Worker supported.');
      // eslint-disable-next-line
      const worker = new Worker(new URL('../sync/delete-attachments-from-cache-web.worker.ts', import.meta.url), {type: 'module'});
      const message = {mediaUrl: this.mediaUrl, fileAccessUtilClassName: fileAccessUtil.className, attachments};
      return startWorker(worker, message);
    } else {
      this.loggingService.warn(LOG_SOURCE, 'Web-Worker is not supported.');
      await deleteAttachmentsFromCache(attachments, fileAccessUtil);
    }
  }

  private async needsClientToBeSynced(syncStrategy: SyncStrategy, client: Client, users: User[], options: SyncOptions | undefined): Promise<boolean> {
    if (client.type === ClientType.OWN) {
      // Own client should always be synced since this method should solve performance issues for connected clients only.
      return true;
    }
    if (syncStrategy === SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE) {
      return true;
    }

    const clientOrProjectIdsWithLocalChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
    if (clientOrProjectIdsWithLocalChanges.has(client.id)) {
      // client needs to be synced because client data has changed locally.
      return true;
    }
    const projectsOfClient = (await observableToPromise(this.projectDataService.dataByClientId$)).get(client.id);
    const userOfflineProjectsOfClient = (await observableToPromise(this.userDeviceOfflineProjectDataService.dataByClientId$)).get(client.id);
    const projectsToSync = await this.determineProjectsToSync(syncStrategy, client, projectsOfClient, users, userOfflineProjectsOfClient, options);
    return !!projectsToSync.length;
  }

  private async determineProjectsToSync(
    syncStrategy: SyncStrategy,
    client: Client,
    projects: Project[],
    users: User[],
    userDeviceOfflineProjects: UserDeviceOfflineProject[],
    options: SyncOptions | undefined,
    userDeviceOfflineProjectsResponse?: SyncUtilResponse<UserDeviceOfflineProject>
  ): Promise<Array<LocalProjectSyncRequest>> {
    const activeProjects = projects.filter((project) => project.status === null || project.status === undefined || project.status === ProjectStatusEnum.ACTIVE);

    const user = users.find((value) => value.id === this.auth.userId);
    if (!user) {
      throw new Error(`Unable to find currentUser in the list of users (users.length = ${users?.length})`);
    }

    const projectIdsWithLocalChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
    const availableProjectIds = await observableToPromise(this.projectAvailabilityExpirationService.availableProjectIds$);

    const currentProjectId = (await this.projectDataService.getCurrentProjectWithTimeout())?.id;
    let currentProject: Project | undefined;
    if (!currentProjectId) {
      currentProject = activeProjects[0] ?? projects[0];
      this.loggingService.warn(LOG_SOURCE, `determineProjectsToSync did not find a currentProject. Use the first project instead, which is ${currentProject?.id}`);
      if (currentProject) {
        await this.projectDataService.setCurrentProject(currentProject);
        this.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES); // this will schedule another sync
      }
    } else {
      currentProject = currentProjectId ? activeProjects.find((project) => project.id === currentProjectId) : undefined; // need to get it from projects, as it might has changed.
    }

    // helper functions
    const isCurrentProject = (project: Project) => currentProject && project.id === currentProject.id;
    const isProjectWithLocalChanges = (project: Project) => projectIdsWithLocalChanges.has(project.id);

    // status functions
    const mapUserDeviceOfflineProjectToProjectId = (userDeviceOfflineProject: UserDeviceOfflineProject) => userDeviceOfflineProject.projectId;
    const downloadedProjectIds = new Set(userDeviceOfflineProjects.map(mapUserDeviceOfflineProjectToProjectId));
    const isDownloadedProject = (project: Project) => downloadedProjectIds.has(project.id);
    const isAvailableProject = (project: Project) => !isDownloadedProject(project) && availableProjectIds.includes(project.id);
    const isAdditionalProjectToBeSynced = (project: Project) =>
      (Boolean(options?.additionalProjectIdsToSync?.length) && options.additionalProjectIdsToSync.includes(project.id)) ||
      Boolean(this.additionalActiveProjectIdsToSync?.length && this.additionalActiveProjectIdsToSync.includes(project.id));

    let projectIdsOfflineStatusChanged = new Array<IdType>();
    if (userDeviceOfflineProjectsResponse) {
      projectIdsOfflineStatusChanged = _.uniq(
        userDeviceOfflineProjectsResponse.localChangesData.insert
          .map(mapUserDeviceOfflineProjectToProjectId)
          .concat(userDeviceOfflineProjectsResponse.localChangesData.delete.map(mapUserDeviceOfflineProjectToProjectId))
          .concat(userDeviceOfflineProjectsResponse.newValues.map(mapUserDeviceOfflineProjectToProjectId))
          .concat(userDeviceOfflineProjectsResponse.deletedValues.map(mapUserDeviceOfflineProjectToProjectId))
      );
    }
    if (userDeviceOfflineProjectsResponse && userDeviceOfflineProjectsResponse.newValues.length) {
      await this.setExpiryDateForUserDeviceOfflineProjects(userDeviceOfflineProjectsResponse.newValues);
    }
    if (userDeviceOfflineProjectsResponse && userDeviceOfflineProjectsResponse.deletedValues.length) {
      await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(
        userDeviceOfflineProjectsResponse.deletedValues.map(mapUserDeviceOfflineProjectToProjectId),
        activeProjects.map((p) => p.id)
      );
    }
    const isDownloadStatusChangedProject = (project: Project) => projectIdsOfflineStatusChanged.includes(project.id);

    const activeProjectsSyncRequests: Array<LocalProjectSyncRequest> = activeProjects.map((project) => {
      const attachmentSyncMode: AttachmentSyncMode = isDownloadedProject(project) ? 'THUMBNAIL_AND_IMAGE_FOR_OPEN' : 'NONE'; // TODO double check if that is right
      const dataSyncMode: DataSyncMode =
        isDownloadedProject(project) || isAvailableProject(project) || isCurrentProject(project) || isProjectWithLocalChanges(project) || isAdditionalProjectToBeSynced(project) ? 'SYNC' : 'NONE';
      return {
        client,
        project,
        dataSyncMode,
        attachmentSyncMode,
      };
    });

    let syncStrategyProjectsSyncRequest: Array<LocalProjectSyncRequest>;
    if (syncStrategy === SyncStrategy.PROJECTS_WITH_CHANGES) {
      syncStrategyProjectsSyncRequest = activeProjectsSyncRequests.filter(
        (syncRequest) => isDownloadStatusChangedProject(syncRequest.project) || isProjectWithLocalChanges(syncRequest.project) || isAdditionalProjectToBeSynced(syncRequest.project)
      );
    } else if (syncStrategy === SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES) {
      syncStrategyProjectsSyncRequest = activeProjectsSyncRequests.filter(
        (syncRequest) =>
          isDownloadStatusChangedProject(syncRequest.project) ||
          isProjectWithLocalChanges(syncRequest.project) ||
          isAdditionalProjectToBeSynced(syncRequest.project) ||
          isCurrentProject(syncRequest.project)
      );
    } else if (syncStrategy === SyncStrategy.DOWNLOADED_AND_PROJECTS_WITH_CHANGES) {
      syncStrategyProjectsSyncRequest = activeProjectsSyncRequests.filter(
        (syncRequest) =>
          isDownloadStatusChangedProject(syncRequest.project) ||
          isProjectWithLocalChanges(syncRequest.project) ||
          isAdditionalProjectToBeSynced(syncRequest.project) ||
          isCurrentProject(syncRequest.project) ||
          isDownloadedProject(syncRequest.project)
      );
    } else if (syncStrategy === SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE) {
      syncStrategyProjectsSyncRequest = activeProjectsSyncRequests.filter((syncRequest) => {
        const shouldDownload =
          isDownloadStatusChangedProject(syncRequest.project) ||
          isProjectWithLocalChanges(syncRequest.project) ||
          isAdditionalProjectToBeSynced(syncRequest.project) ||
          isCurrentProject(syncRequest.project) ||
          isDownloadedProject(syncRequest.project) ||
          isAvailableProject(syncRequest.project);
        return shouldDownload;
      });
      this.capturePostHogEventSyncAll({projects: projects.length, activeProjects: activeProjects.length, downloadedProjects: downloadedProjectIds.size});
    } else if (syncStrategy === SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE) {
    } else {
      throw new Error(`syncStrategy ${syncStrategy} not supported.`);
    }
    return _.uniq(syncStrategyProjectsSyncRequest);
  }

  public removeObsoleteChanges<T extends IdAware>(localChangesData: LocalChangesData<T>): LocalChangesData<T> {
    const idsToRemoveFromLocalChangesDataDelete = new Array<IdType>();
    localChangesData.delete.forEach((deletedValue) => {
      const id = deletedValue.id;
      this.loggingService.debug(LOG_SOURCE, `removeObsoleteChanges with id ${id}`);

      if (localChangesData.localChangesInsertById.has(id)) {
        localChangesData.localChangesDeleteById.delete(id);
        idsToRemoveFromLocalChangesDataDelete.push(id);
      }

      localChangesData.localChangesInsertById.delete(id);
      _.remove(localChangesData.insert, (value) => value.id === id);

      localChangesData.localChangesUpdateById.delete(id);
      _.remove(localChangesData.update, (value) => value.id === id);
    });
    if (idsToRemoveFromLocalChangesDataDelete.length) {
      localChangesData.delete = localChangesData.delete.filter((item) => !idsToRemoveFromLocalChangesDataDelete.includes(item.id));
    }

    localChangesData.insert.forEach((insertedValue) => {
      const id = insertedValue.id;
      this.loggingService.debug(LOG_SOURCE, `removeObsoleteChanges with id ${id}`);
      localChangesData.localChangesUpdateById.delete(id);
      _.remove(localChangesData.update, (value) => value.id === id);
    });
    return localChangesData;
  }

  protected assertAuthenticated() {
    if (!this.auth) {
      throw new Error('User not authenticated (auth is null or undefined)');
    }
  }

  private attachmentsOfOneTypeMissingOnServer<T extends Attachment>(key: string, serverValues: Array<T | NotChanged>, clientValues: Array<T>, includeIfNotOnClient = false): Array<T> {
    const attachmentsMissingOnServer = new Array<T>();
    for (const clientValue of clientValues) {
      const id = clientValue.id;
      const serverValueOrNotChanged = serverValues.find((value) => value.id === id);
      if (!serverValueOrNotChanged) {
        this.loggingService.info(LOG_SOURCE, `Attachment of type "${key}" with id ${id} not found on server.`);
        continue;
      }
      const isNotChanged = 'notChanged' in serverValueOrNotChanged && serverValueOrNotChanged.notChanged;
      if (isNotChanged) {
        throw new Error(`Attachment of type "${key}" with id ${id} returned as notChanged from Server. That should never be the case if no since parameter is passed to the server.`);
      }
      const serverValue = serverValueOrNotChanged as T;
      if (!serverValue.filePath && (clientValue.filePath || includeIfNotOnClient)) {
        attachmentsMissingOnServer.push(clientValue);
      }
    }
    return attachmentsMissingOnServer;
  }

  private async filterAttachmentsNotScheduledForUpload<T extends Attachment>(attachments: Array<T>, fileAccessUtil: AbstractFileAccessUtil): Promise<Array<T>> {
    const attachmentsNotScheduledForUpload = new Array<T>();
    for (const attachment of attachments) {
      if (!(await isAttachmentScheduledForUpload(attachment, fileAccessUtil))) {
        attachmentsNotScheduledForUpload.push(attachment);
      }
    }
    return attachmentsNotScheduledForUpload;
  }

  public async attachmentsMissingOnServer(projectIds: Array<IdType>, includeIfNotOnClient = false): Promise<Array<Attachment>> {
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    let allMissing = new Array<Attachment>();
    for (const projectId of projectIds) {
      const url = `${this.urlProjectAware}?projectId=${projectId}&forceReadingAttachmentFileInfo=true`;
      this.assertAuthenticated();
      const syncResponse = await observableToPromise(this.http.get<ProjectAwareSyncResponse>(url));
      this.assertAuthenticated();
      for (const attachmentServiceKey of Object.keys(this.dataServiceFactoryService.attachmentServices)) {
        const attachmentService: AbstractProjectAwareAttachmentDataService<any> = this.dataServiceFactoryService.attachmentServices[attachmentServiceKey];
        const clientValues = await observableToPromise(attachmentService.getDataForProject$(projectId));
        const serverValues = syncResponse[attachmentServiceKey];
        const missingAttachments = this.attachmentsOfOneTypeMissingOnServer(attachmentServiceKey, serverValues, clientValues, includeIfNotOnClient);
        const missingAttachmentsAndNotScheduledForUpload = await this.filterAttachmentsNotScheduledForUpload(missingAttachments, fileAccessUtil);
        allMissing = allMissing.concat(missingAttachmentsAndNotScheduledForUpload);
      }
    }
    return allMissing;
  }

  public async uploadMissingAttachments(attachments: Array<Attachment>) {
    await this.scheduleFilesForUpload(this.auth.token, attachments, true);
    await this.uploadAllFilesFromMediaQueue(this.auth?.token, true);
  }

  private async capturePostHogEventSyncAll(args: {projects: number; downloadedProjects: number; activeProjects: number}) {
    try {
      const deviceInfo = await Device.getInfo();
      const extendedArgs = {...args, deviceUuid: await this.getDeviceUuid(), platform: deviceInfo.platform, operatingSystem: deviceInfo.operatingSystem};
      this.posthogService.captureEvent('[Projects] Sync AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE', extendedArgs);
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `captucePostHogEventSyncAll failed with error ${convertErrorToMessage(error)}`);
    }
  }

  public async removeExpiredUserDeviceOfflineProjects(availableProjectIds?: Array<IdType>, userDeviceOfflineProjects?: Array<UserDeviceOfflineProject>): Promise<Array<UserDeviceOfflineProject>> {
    if (!availableProjectIds) {
      availableProjectIds = await observableToPromise(this.projectAvailabilityExpirationService.availableProjectIds$);
    }
    if (!userDeviceOfflineProjects) {
      userDeviceOfflineProjects = await observableToPromise(this.userDeviceOfflineProjectDataService.data);
    }
    const userDeviceOfflineProjectsToRemove = userDeviceOfflineProjects.filter((v) => !availableProjectIds.includes(v.projectId));
    if (!userDeviceOfflineProjectsToRemove.length) {
      return [];
    }
    const projectIdsOffloaded = _.compact(userDeviceOfflineProjectsToRemove.map((v) => v.projectId));
    this.loggingService.info(LOG_SOURCE, `removeExpiredUserDeviceOfflineProjects - The following project(s) are being offloaded. ${projectIdsOffloaded}`);
    this.systemEventService.logEvent(LOG_SOURCE + ' removeExpiredUserDeviceOfflineProjects', `The following project(s) are being offloaded. ${projectIdsOffloaded}`);
    this.posthogService.captureEvent('[Projects] Projects offloaded', {projectState: 'downloaded', projectIdsOffloaded});
    await this.userDeviceOfflineProjectDataService.delete(userDeviceOfflineProjectsToRemove);
    return userDeviceOfflineProjectsToRemove;
  }

  private async setExpiryDateForUserDeviceOfflineProjects(userDeviceOfflineProjects?: Array<UserDeviceOfflineProject>): Promise<Array<IdType>> {
    if (!userDeviceOfflineProjects) {
      userDeviceOfflineProjects = await observableToPromise(this.userDeviceOfflineProjectDataService.data);
    }
    if (!userDeviceOfflineProjects.length) {
      return [];
    }
    const projectIds = _.uniq(userDeviceOfflineProjects.map((userDeviceOfflineProject) => userDeviceOfflineProject.projectId));
    const expireInMs = this.devModeService.enabled ? this.devModeService.settings.downloadedProjectExpirationInMs : DOWNLOADED_PROJECT_EXPIRATION_IN_MS;
    const newExpiryDates = userDeviceOfflineProjects.map((userDeviceOfflineProject) => new Date(Date.now() + expireInMs));
    const activeProjects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
    await this.projectAvailabilityExpirationService.storeProjectExpirationDateAndInit(
      projectIds,
      activeProjects.map((p) => p.id),
      newExpiryDates
    );
    return projectIds;
  }
}
