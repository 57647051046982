import {IdType} from 'submodules/baumaster-v2-common';

export const getBaseProtocolsPagePath = (url: string) => (url.includes('protocols-search') ? '/protocols-search' : '/protocols');

export const getProtocolPagePathFn = (base: string) => (protocolId: IdType) => [base, 'view', protocolId];

export const getBaseProtocolPagePath = (url: string, protocolId: IdType) => getProtocolPagePathFn(getBaseProtocolsPagePath(url))(protocolId);

export const getProtocolPagePath = getProtocolPagePathFn('/protocols');

export const getProtocolPagePathForSearch = getProtocolPagePathFn('/protocols-search');

export const getBaseProtocolEntryPagePath = (url: string, protocolId: IdType, protocolEntryId: IdType) => [...getBaseProtocolPagePath(url, protocolId), 'entry', protocolId, protocolEntryId];

export const getProtocolEntryPagePath = (protocolId: IdType, protocolEntryId: IdType) => getBaseProtocolEntryPagePath('/protocols', protocolId, protocolEntryId);

export const getProtocolEntryPagePathForSearch = (protocolId: IdType, protocolEntryId: IdType) => getBaseProtocolEntryPagePath('/protocols-search', protocolId, protocolEntryId);

export const getTaskPagePath = (_protocolId: IdType, entryId: IdType) => ['/tasks', 'card', 'entry', entryId];
