import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AuthenticationService} from './authentication.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {SystemEventService} from '../event/system-event.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {LoginModalComponent} from 'src/app/components/auth/login-modal/login-modal.component';
import {PosthogService} from '../posthog/posthog.service';

const LOG_SOURCE = 'UnauthorizedModalService';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedModalService {
  private modalOpened = false;

  constructor(
    private modalController: ModalController,
    private authService: AuthenticationService,
    private systemEventService: SystemEventService,
    private posthogService: PosthogService
  ) {}

  async openModal(requestAuthHeader?: string) {
    if (this.modalOpened) {
      return;
    }

    const auth = await observableToPromise(this.authService.data);

    if (!auth) {
      return; // Login again modal is only presented to the (potentially) logged in users.
    }

    if (this.modalOpened) {
      return;
    }

    if (requestAuthHeader && !requestAuthHeader.includes(auth.token)) {
      return; // Request has been made with an old token; there is no need of showing login again modal.
    }

    try {
      this.modalOpened = true;
      this.posthogService.captureEvent('[Security][Forced_re-authentication] open_modal', {});
      const modal = await this.modalController.create({
        component: LoginModalComponent,
        cssClass: 'omg omg-modal omg-boundary',
      });

      await modal.present();

      await modal.onDidDismiss();
    } catch (e) {
      this.systemEventService.logErrorEvent(LOG_SOURCE, `openModal - failed; reason: "${convertErrorToMessage(e)}"`);
      throw e;
    } finally {
      this.modalOpened = false;
    }
  }
}
