import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

export const bauPaperClip: IconDefinition = {
  prefix: 'bau' as IconPrefix,
  iconName: 'paper-clip' as IconName,
  icon: [
    17,
    18,
    [],
    null,
    // eslint-disable-next-line max-len
    'M13.0324 5.22309L7.24441 12.2086C6.9787 12.5549 6.56063 12.7786 6.09051 12.7786C5.28794 12.7786 4.63732 12.128 4.63732 11.3255C4.63732 10.985 4.75443 10.6719 4.95046 10.4243L11.7916 2.35618C12.271 1.79832 12.982 1.44531 13.7749 1.44531C15.2193 1.44531 16.3904 2.61642 16.3904 4.06079C16.3904 4.64971 16.1961 5.19287 15.8674 5.62983L7.78295 15.3941C7.0954 16.1106 6.12828 16.5564 5.05707 16.5564C2.97048 16.5564 1.2793 14.8652 1.2793 12.7786C1.2793 12.0298 1.49715 11.3313 1.87325 10.7441L8.83485 2.70457',
  ],
};
