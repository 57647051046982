import {Component, OnInit} from '@angular/core';
import {ProtocolEntryPriorityLevel} from 'submodules/baumaster-v2-common';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-protocol-entry-priority-popover',
  templateUrl: './protocol-entry-priority-popover.component.html',
  styleUrls: ['./protocol-entry-priority-popover.component.scss'],
})
export class ProtocolEntryPriorityPopoverComponent implements OnInit {
  public priorityLevel = ProtocolEntryPriorityLevel;

  constructor(private popoverCtr: PopoverController) {}

  ngOnInit() {}

  changePriority(priority) {
    this.popoverCtr.dismiss({
      newPriority: priority,
    });
  }
}
