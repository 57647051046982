import {Directive, ElementRef, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: '[appWatchDimensions]',
  exportAs: 'appWatchDimensions',
})
export class WatchDimensionsDirective implements OnInit {
  private rectSubject = new BehaviorSubject<Record<'width' | 'height' | 'x' | 'y', number>>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  get rect(): Record<'width' | 'height' | 'x' | 'y', number> {
    return this.rectSubject.value;
  }

  rect$ = this.rectSubject.asObservable();

  private resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      this.rectSubject.next({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
        x: entry.contentRect.x,
        y: entry.contentRect.y,
      });
    }
  });

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }
}
