import {Injectable} from '@angular/core';
import {Observable, map, shareReplay} from 'rxjs';
import {DetailedNotification} from 'src/app/model/notifications';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {IdType} from 'submodules/baumaster-v2-common';
import {NotificationConfigDataService} from '../data/notification-config-data.service';
import {NotificationEventDataService} from '../data/notification-event-data.service';
import {NotificationEventRecipientDataService} from '../data/notification-event-recipient-data.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly allNotifications$: Observable<DetailedNotification[]> = combineLatestAsync([
    this.notificationEventDataService.data,
    this.notificationEventRecipientDataService.dataByEventId$,
    this.notificationConfigDataService.dataGroupedById,
  ]).pipe(
    map(([events, recipientByEventId, configById]) =>
      events
        .map((event) => ({
          event,
          recipient: recipientByEventId[event.id],
          config: configById[event.notificationConfigId],
        }))
        .filter(({event, recipient, config}) => Boolean(event && recipient && config))
    ),
    shareReplay(1)
  );

  constructor(
    private notificationEventDataService: NotificationEventDataService,
    private notificationEventRecipientDataService: NotificationEventRecipientDataService,
    private notificationConfigDataService: NotificationConfigDataService
  ) {}

  async setSeenAt(recipientIds: IdType[], seen: boolean) {
    const seenAt = seen ? new Date() : null;

    await this.notificationEventRecipientDataService.update((data) => {
      data.forEach((item) => {
        if (recipientIds.includes(item.id)) {
          item.seenAt = seenAt;
        }
      });

      return data;
    });
  }
}
