import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {
  Address,
  Company,
  Craft,
  IdType,
  NameableDropdown,
  Protocol,
  ProtocolEntry,
  ProtocolEntryChat,
  ProtocolEntryCompany,
  ProtocolEntryIconStatus,
  ProtocolEntryLocation,
  ProtocolEntryPriorityLevel,
  ProtocolEntryPriorityType,
  ProtocolEntryType,
  ProtocolType,
  Unit,
} from 'submodules/baumaster-v2-common';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {TranslateService} from '@ngx-translate/core';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {AddressDataService} from '../data/address-data.service';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import _ from 'lodash';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {UserNameString} from '../../utils/user-name.pipe';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {memoizedFormatNumber} from 'src/app/utils/number-utils';
import {getProtocolEntryStatus} from 'submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {ProtocolService} from './protocol.service';
import {CompanyDataService} from '../data/company-data.service';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';
import {EMPTY_FILTER_ID} from 'src/app/utils/filter-utils';
import {UnitDataService} from '../data/unit-data.service';

export interface ProtocolEntriesUsed {
  statuses: SelectableStatusesFilter[];
  priorities: SelectablePriorityLevelFilter[];
  companies: Company[];
  observerCompanies: Company[];
  types: ProtocolEntryType[];
  crafts: Craft[];
  locations: ProtocolEntryLocation[];
  customFields: NameableDropdown[];
  responsibilities: SelectableResponsibilitiesFilter[];
  units: Unit[];
}

export interface SelectableResponsibilitiesFilter {
  id: IdType;
  name: string;
  isActive: boolean;
}

export interface SelectableStatusesFilter {
  id: ProtocolEntryIconStatus;
  name: string;
}

export interface SelectablePriorityLevelFilter {
  id: ProtocolEntryPriorityLevel;
  name: string;
}

export interface ProtocolEntryStandardParameterFilter {
  fieldName: string;
  value: any;
  label: string;
  originalValue: any;
  originalKey: string;
  mode: 'standard' | 'sort';
}

export interface ProtocolEntryContainsFilter {
  value: any;
  mode: 'contains';
}

export interface ProtocolEntryInFilter {
  fieldName: string;
  value: any[];
  label?: string;
  originalValue?: any[];
  originalKey?: string;
  mode: 'in';
}

export interface ProtocolEntryCompareFilter {
  value: any;
  fieldName: string;
  comparator?: (value: any, target: any) => number;
  direction: 'lte' | 'lt' | 'gt' | 'gte';
  mode: 'compare';
}

export type ProtocolEntryParameterFilter = ProtocolEntryStandardParameterFilter | ProtocolEntryContainsFilter | ProtocolEntryInFilter | ProtocolEntryCompareFilter;

@Injectable({
  providedIn: 'root',
})
export class ProtocolEntryFilterService {
  protected observableProtocolEntryFilters = new BehaviorSubject<ProtocolEntryParameterFilter[]>([]);
  public readonly protocolEntryFilters: Observable<Array<ProtocolEntryParameterFilter>> = this.observableProtocolEntryFilters.asObservable();

  constructor(
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolEntryService: ProtocolEntryService,
    private craftDataService: CraftDataService,
    private companyDataService: CompanyDataService,
    private nameableDropdownDataService: NameableDropdownDataService,
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private addressDataService: AddressDataService,
    private translateService: TranslateService,
    private usernameString: UserNameString,
    private protocolTypeDataService: ProtocolTypeDataService,
    @Inject(LOCALE_ID) private locale: string,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private protocolService: ProtocolService,
    private protocolEntryChatDataService: ProtocolEntryChatDataService,
    private unitDataService: UnitDataService
  ) {}

  createEmptyProtocolEntriesUsed(): ProtocolEntriesUsed {
    return {
      statuses: [],
      priorities: [],
      companies: [],
      observerCompanies: [],
      types: [],
      crafts: [],
      locations: [],
      customFields: [],
      responsibilities: [],
      units: [],
    };
  }

  getProtocolEntriesUsed(protocolId: IdType, isLayoutShort: boolean): Promise<ProtocolEntriesUsed> {
    return new Promise(async (resolve, reject) => {
      try {
        const protocolEntries = await observableToPromise(this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId));
        const protocolEntryCompanies = await observableToPromise(this.protocolEntryCompanyDataService.findAllByProtocolEntryIds(protocolEntries.map(({id}) => id)));
        const companies: Company[] = [
          {
            id: null,
            name: this.translateService.instant('project_team'),
            clientId: null,
            changedAt: new Date(),
            isActive: true,
          },
          ...(await observableToPromise(this.companyDataService.data)),
        ];
        const types = await observableToPromise(this.protocolEntryTypeDataService.dataWithDeletedSuffix);
        const crafts = await observableToPromise(this.craftDataService.dataWithDeletedSuffix);
        const locations = await observableToPromise(this.protocolEntryLocationDataService.dataWithDeletedSuffix);
        const customFields = await observableToPromise(this.nameableDropdownDataService.dataWithDeletedSuffix$);
        const units = await observableToPromise(this.unitDataService.data);
        const protocolEntriesUsed = this.createEmptyProtocolEntriesUsed();

        for (const entry of protocolEntries) {
          if (isLayoutShort) {
            await this.setShortProtocolEntryStatus(entry, protocolEntriesUsed);
          } else {
            await this.setProtocolEntryStatus(entry, protocolEntriesUsed);
          }
          this.setProtocolEntryLevel(entry, protocolEntriesUsed);
          this.setProtocolEntryCompany(companies, entry, protocolEntriesUsed);
          this.setProcolEntryType(types, entry, protocolEntriesUsed);
          this.setProtocolEntryCraft(crafts, entry, protocolEntriesUsed);
          this.setProtocolEntryLocation(locations, entry, protocolEntriesUsed);
          this.setProtocolEntryCustomField(customFields, entry, protocolEntriesUsed);
          this.setProtocolEntryUnit(units, entry, protocolEntriesUsed);
        }
        this.setProtocolEntryObserverCompany(companies, protocolEntryCompanies, protocolEntriesUsed);
        await this.setProtocolEntryResponsible(protocolEntriesUsed, protocolEntries);
        resolve(protocolEntriesUsed);
      } catch (e) {
        reject(e);
      }
    });
  }

  private async setProtocolEntryResponsible(protocolEntriesUsed: ProtocolEntriesUsed, protocolEntries: ProtocolEntry[]) {
    const profilesWithMapping = await observableToPromise(this.projectProfileDataService.getProjectProfilesWithMapping(protocolEntries.map(({internalAssignmentId}) => internalAssignmentId)));
    const addresses = await observableToPromise(this.addressDataService.data);
    const protocolEntryProfiles = profilesWithMapping.filter(({profile}) => protocolEntriesUsed.companies.find((company: Company) => company.id === profile.companyId));
    protocolEntryProfiles.forEach(({profile: protocolEntryProfile, projectProfile}) => {
      const profileAddress = addresses.find((address: Address) => address.id === protocolEntryProfile.addressId);
      if (profileAddress && !protocolEntriesUsed.responsibilities.find((responsible) => responsible.id === protocolEntryProfile.id)) {
        protocolEntriesUsed.responsibilities.push({
          id: protocolEntryProfile.id,
          name: this.usernameString.transform(profileAddress, protocolEntryProfile.isActive === undefined || protocolEntryProfile.isActive, !projectProfile),
          isActive: protocolEntryProfile.isActive,
        });
      }
    });
  }

  private setProtocolEntryCustomField(customFields: NameableDropdown[], entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    const protocolEntryCustomField = customFields.find((customField: NameableDropdown) => customField.id === entry.nameableDropdownId);
    if (protocolEntryCustomField && !protocolEntriesUsed.customFields.find((customField) => customField.id === entry.nameableDropdownId)) {
      protocolEntriesUsed.customFields.push(protocolEntryCustomField);
    }
  }

  private setProtocolEntryLocation(locations: ProtocolEntryLocation[], entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    const protocolEntryLocation = locations.find((location: ProtocolEntryLocation) => location.id === entry.locationId);
    if (protocolEntryLocation && !protocolEntriesUsed.locations.find((location) => location.id === entry.locationId)) {
      protocolEntriesUsed.locations.push(protocolEntryLocation);
    }
  }

  private setProtocolEntryUnit(units: Unit[], entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    const protocolEntryUnit = entry.unitId ? units.find((unit: Unit) => unit.id === entry.unitId) : undefined;
    if (protocolEntryUnit && !protocolEntriesUsed.units.find((unit) => unit.id === entry.unitId)) {
      protocolEntriesUsed.units.push(protocolEntryUnit);
    }
  }

  private setProtocolEntryCraft(crafts: Craft[], entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    const protocolEntryCraft = crafts.find((craft: ProtocolEntryType) => craft.id === entry.craftId);
    if (protocolEntryCraft && !protocolEntriesUsed.crafts.find((craft) => craft.id === entry.craftId)) {
      protocolEntriesUsed.crafts.push(protocolEntryCraft);
    }
  }

  private setProcolEntryType(types: ProtocolEntryType[], entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    const protocolEntryType = types.find((type: ProtocolEntryType) => type.id === entry.typeId);
    if (protocolEntryType && !protocolEntriesUsed.types.find((type) => type.id === entry.typeId)) {
      protocolEntriesUsed.types.push(protocolEntryType);
    }
  }

  private async setProtocolEntryStatus(entry: ProtocolEntryOrOpen, protocolEntriesUsed?: ProtocolEntriesUsed) {
    const status = await this.protocolEntryService.getProtocolEntryIconStatusByEntryId(entry.id);
    const iconStatus = this.getSelectableStatus(status);
    if (protocolEntriesUsed && !protocolEntriesUsed.statuses.find((protocolEntryStatus) => protocolEntryStatus.id === status)) {
      protocolEntriesUsed.statuses.push(iconStatus);
    }
  }

  private async setShortProtocolEntryStatus(entry: ProtocolEntryOrOpen, protocolEntriesUsed?: ProtocolEntriesUsed) {
    const status = getProtocolEntryStatus(entry);
    const iconStatus = this.getSelectableStatus(status);
    if (protocolEntriesUsed && !protocolEntriesUsed.statuses.find((protocolEntryStatus) => protocolEntryStatus.id === status)) {
      protocolEntriesUsed.statuses.push(iconStatus);
    }
  }

  private setProtocolEntryLevel(entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    if (entry.priority && !protocolEntriesUsed.priorities.find((protocolEntryPriority) => protocolEntryPriority.id === entry.priority || (!protocolEntryPriority.id && !entry.priority))) {
      protocolEntriesUsed.priorities.push(this.getSelectablePriorityLevel(this.protocolEntryPriorityTypeToProtocolEntryPriorityLevel(entry.priority)));
    }
  }

  private protocolEntryPriorityTypeToProtocolEntryPriorityLevel(priorityType: ProtocolEntryPriorityType | undefined | null): ProtocolEntryPriorityLevel {
    switch (priorityType) {
      case 1:
        return ProtocolEntryPriorityLevel.HIGH;
      case 2:
        return ProtocolEntryPriorityLevel.MEDIUM;
      case 3:
        return ProtocolEntryPriorityLevel.LOW;
      default:
        return ProtocolEntryPriorityLevel.LOW;
    }
  }

  private setProtocolEntryCompany(companies: Company[], entry: ProtocolEntryOrOpen, protocolEntriesUsed: ProtocolEntriesUsed) {
    const protocolCompany: Company = companies.find((company: Company) => company.id === entry.companyId);
    if (protocolCompany && !protocolEntriesUsed.companies.find((company) => company.id === entry.companyId)) {
      protocolEntriesUsed.companies.push(protocolCompany);
    }
  }

  private setProtocolEntryObserverCompany(companies: Company[], entryCompanies: ProtocolEntryCompany[], protocolEntriesUsed: ProtocolEntriesUsed) {
    protocolEntriesUsed.observerCompanies.push(...companies.filter((company) => entryCompanies.some(({companyId}) => companyId === company.id)));
  }

  public getSelectablePriorityLevel(priorityLevel: ProtocolEntryPriorityLevel): SelectablePriorityLevelFilter {
    switch (priorityLevel) {
      case ProtocolEntryPriorityLevel.LOW:
        return {id: ProtocolEntryPriorityLevel.LOW, name: this.translateService.instant('lowPriority')};
      case ProtocolEntryPriorityLevel.HIGH:
        return {id: ProtocolEntryPriorityLevel.HIGH, name: this.translateService.instant('highPriority')};
      case ProtocolEntryPriorityLevel.MEDIUM:
        return {id: ProtocolEntryPriorityLevel.MEDIUM, name: this.translateService.instant('mediumPriority')};
      default:
        return {id: null, name: this.translateService.instant('no_priority')};
    }
  }

  public getSelectableStatus(status: ProtocolEntryIconStatus): SelectableStatusesFilter {
    switch (status) {
      case ProtocolEntryIconStatus.INFO:
        return {id: ProtocolEntryIconStatus.INFO, name: this.translateService.instant('protocolEntry.status.info')};
      case ProtocolEntryIconStatus.DONE:
        return {id: ProtocolEntryIconStatus.DONE, name: this.translateService.instant('protocolEntry.status.done')};
      case ProtocolEntryIconStatus.ON_HOLD:
        return {id: ProtocolEntryIconStatus.ON_HOLD, name: this.translateService.instant('protocolEntry.status.waiting')};
      case ProtocolEntryIconStatus.OPEN:
        return {id: ProtocolEntryIconStatus.OPEN, name: this.translateService.instant('protocolEntry.status.open')};
    }
  }

  async applyFilter(filterParameters: ProtocolEntryParameterFilter[]): Promise<void> {
    this.observableProtocolEntryFilters.next(filterParameters);
  }

  async clearFilters() {
    await this.applyFilter([]);
  }

  private matchFilter(protocolEntryFilter: ProtocolEntryParameterFilter, protocolEntry: ProtocolEntry, protocolEntryShortId: string, additionalStrings: string[] = []) {
    if (protocolEntryFilter.mode === 'contains') {
      const lookup = protocolEntryFilter.value.toLowerCase();
      const prepare = (valueOrNullish) => (valueOrNullish || '').toLowerCase();

      const check = (...strings) => strings.map(prepare).some((str) => str.includes(lookup));

      return check(protocolEntry.title, protocolEntry.text, protocolEntryShortId, ...additionalStrings);
    } else if (protocolEntryFilter.mode === 'in') {
      const value = protocolEntry[protocolEntryFilter.fieldName];
      return protocolEntryFilter.value.includes(protocolEntry[protocolEntryFilter.fieldName]) || (protocolEntryFilter.value.includes(EMPTY_FILTER_ID) && (value === undefined || value === null));
    } else if (protocolEntryFilter.mode === 'compare') {
      const comparator =
        protocolEntryFilter.comparator ||
        ((left: any, right: any) => {
          if (left < right) {
            return 1;
          } else if (left > right) {
            return -1;
          }
          return 0;
        });
      const allDirections = {
        lt: (value: any) => comparator(value, protocolEntryFilter.value) > 0,
        lte: (value: any) => comparator(value, protocolEntryFilter.value) >= 0,
        gt: (value: any) => comparator(value, protocolEntryFilter.value) < 0,
        gte: (value: any) => comparator(value, protocolEntryFilter.value) <= 0,
      };
      return allDirections[protocolEntryFilter.direction](protocolEntry[protocolEntryFilter.fieldName]);
    }

    return protocolEntry[protocolEntryFilter.fieldName] === protocolEntryFilter.value;
  }

  private isCompanyRelatedFilter(filter: ProtocolEntryParameterFilter): filter is ProtocolEntryInFilter | ProtocolEntryStandardParameterFilter {
    if (filter.mode !== 'in' && filter.mode !== 'standard') {
      return false;
    }

    return filter.fieldName === 'companyId' || filter.fieldName === 'allCompanies' || filter.fieldName === 'observerCompanies';
  }

  private matchCompaniesFilter(companyFilter: ProtocolEntryInFilter, protocolEntryCompanies: ProtocolEntryCompany[]): boolean {
    if (companyFilter.value.includes(EMPTY_FILTER_ID) && _.isEmpty(protocolEntryCompanies)) {
      return true;
    }
    return protocolEntryCompanies.some(({companyId}) => companyFilter.value.includes(companyId));
  }

  private isProtocolEntryMatchingFilter(
    protocolEntryFilters: ProtocolEntryParameterFilter[],
    protocolEntry: ProtocolEntry,
    protocolEntryShortId: string,
    protocolEntryIconStatus: ProtocolEntryIconStatus | null,
    protocolEntryCompanies: ProtocolEntryCompany[],
    protocolEntryChats: ProtocolEntryChat[]
  ): boolean {
    const companyFilter = protocolEntryFilters.find((filter) => filter.mode === 'in' && filter.fieldName === 'companyId');
    const allCompaniesStandardFilter = protocolEntryFilters.find((filter) => filter.mode === 'standard' && filter.fieldName === 'allCompanies');
    const allCompaniesInFilter = protocolEntryFilters.find((filter) => filter.mode === 'in' && filter.fieldName === 'allCompanies');
    const observerCompaniesFilter = protocolEntryFilters.find((filter) => filter.mode === 'in' && filter.fieldName === 'observerCompanies') as ProtocolEntryInFilter | undefined;
    const hasAnyCompanyFilter = Boolean(companyFilter || allCompaniesInFilter || allCompaniesStandardFilter || observerCompaniesFilter);
    for (const protocolEntryFilter of protocolEntryFilters) {
      if (protocolEntryFilter.mode === 'sort') {
        // temporary
        continue;
      }

      if (hasAnyCompanyFilter && this.isCompanyRelatedFilter(protocolEntryFilter)) {
        // Company filters should be joined with OR
        const conditions = [];
        if (companyFilter) {
          conditions.push(this.matchFilter(companyFilter, protocolEntry, protocolEntryShortId));
        }
        if (allCompaniesStandardFilter) {
          conditions.push(this.matchFilter(allCompaniesStandardFilter, protocolEntry, protocolEntryShortId));
        }
        if (allCompaniesInFilter) {
          conditions.push(this.matchFilter(allCompaniesInFilter, protocolEntry, protocolEntryShortId));
        }
        if (observerCompaniesFilter) {
          conditions.push(this.matchCompaniesFilter(observerCompaniesFilter, protocolEntryCompanies));
        }
        if (conditions.includes(true)) {
          continue;
        }
        return false;
      }

      if ((protocolEntryFilter.mode === 'standard' || protocolEntryFilter.mode === 'in') && protocolEntryFilter.fieldName === 'status' && protocolEntryIconStatus !== null) {
        if (protocolEntryFilter.mode === 'in') {
          if (!protocolEntryFilter.value.includes(protocolEntryIconStatus)) {
            return false;
          }
        } else if (protocolEntryIconStatus !== protocolEntryFilter.value) {
          return false;
        }
      } else if (
        !this.matchFilter(
          protocolEntryFilter,
          protocolEntry,
          protocolEntryShortId,
          protocolEntryChats.map(({message}) => message)
        )
      ) {
        return false;
      }
    }
    return true;
  }

  async filterProtocolEntries(
    protocolEntryFilters: Array<ProtocolEntryParameterFilter>,
    protocolEntries: Array<ProtocolEntryOrOpen>,
    protocol: Protocol
  ): Promise<[ProtocolEntry[] | null, ProtocolEntryOrOpen[]]> {
    const allChildren = await observableToPromise(this.getChildrenEntries(protocol.id, protocolEntries));
    if (_.isEmpty(protocolEntryFilters)) {
      return [allChildren, protocolEntries];
    }
    const protocolTypesById = await observableToPromise(this.protocolTypeDataService.dataWithoutHiddenAcrossClientsGroupedById$);
    const protocolEntryTypesById = await observableToPromise(this.protocolEntryTypeDataService.dataAcrossClientsGroupedById);
    const [children, filtered] = this.filterProtocolEntriesSync(protocolEntryFilters, protocolEntries, protocol, protocolTypesById, protocolEntryTypesById, allChildren);
    return [children, filtered] as [ProtocolEntry[] | null, ProtocolEntryOrOpen[]];
  }

  filterProtocolEntriesSync(
    protocolEntryFilters: Array<ProtocolEntryParameterFilter>,
    protocolEntries: Array<ProtocolEntryOrOpen>,
    protocol: Protocol,
    protocolTypesById: Record<IdType, ProtocolType>,
    protocolEntryTypesById: Record<IdType, ProtocolEntryType>,
    childProtocolEntries: Array<ProtocolEntry>,
    {
      protocolEntryStatusById,
      protocolEntryCompaniesById,
      protocolEntryChatsById,
      isLayoutShort,
    }: {
      protocolEntryStatusById?: Record<IdType, ProtocolEntryIconStatus>;
      protocolEntryCompaniesById?: Record<IdType, ProtocolEntryCompany[]>;
      protocolEntryChatsById?: Record<IdType, ProtocolEntryChat[]>;
      isLayoutShort?: boolean;
    } = {}
  ): [ProtocolEntry[] | null, ProtocolEntryOrOpen[], ProtocolEntryOrOpen[]] {
    if (_.isEmpty(protocolEntryFilters)) {
      return [childProtocolEntries, protocolEntries, protocolEntries];
    }
    const allMatchingChildren = [];

    const hasStatusFilter = protocolEntryFilters.find(
      (protocolEntryFilter) => (protocolEntryFilter.mode === 'standard' || protocolEntryFilter.mode === 'in') && protocolEntryFilter.fieldName === 'status'
    );
    // Contains all parent protocol entries, that either matches filter criteria or one of its child matches filter criteria
    const filteredProtocolEntries: ProtocolEntryOrOpen[] = [];
    // Contains all parent protocol entries, that matches filter criteria
    const matchingProtocolEntries: ProtocolEntryOrOpen[] = [];
    for (const protocolEntry of protocolEntries) {
      let protocolIconStatus: ProtocolEntryIconStatus | null = null;
      const matchingChildren = childProtocolEntries
        .filter((child) => child.parentId === protocolEntry.id)
        .filter((child) => {
          const childShortId = `${protocolTypesById[protocol.typeId]?.code || ''}${memoizedFormatNumber(protocol?.number, this.locale, '2.0')}.${memoizedFormatNumber(
            protocolEntry?.number,
            this.locale,
            '3.0'
          )}.${memoizedFormatNumber(child?.number, this.locale, '3.0')}`;
          let childProtocolIconStatus: ProtocolEntryIconStatus | null = null;
          if (hasStatusFilter) {
            childProtocolIconStatus = protocolEntryStatusById?.[child.id] ?? this.protocolEntryService.getIconStatus(child, protocolEntryTypesById[child.typeId]);
          }
          return this.isProtocolEntryMatchingFilter(protocolEntryFilters, child, childShortId, childProtocolIconStatus, [], protocolEntryChatsById?.[child.id] ?? []);
        });

      allMatchingChildren.push(...matchingChildren);

      const protocolEntryShortId = `${protocolTypesById[protocol.typeId]?.code || ''}${memoizedFormatNumber(protocol?.number, this.locale, '2.0')}.${memoizedFormatNumber(
        protocolEntry?.number,
        this.locale,
        '3.0'
      )}`;

      if (hasStatusFilter) {
        if (isLayoutShort) {
          protocolIconStatus = getProtocolEntryStatus(protocolEntry);
        } else {
          protocolIconStatus = protocolEntryStatusById?.[protocolEntry.id] ?? this.protocolEntryService.getIconStatus(protocolEntry, protocolEntryTypesById[protocolEntry.typeId]);
        }
      }
      const matching = this.isProtocolEntryMatchingFilter(
        protocolEntryFilters,
        protocolEntry,
        protocolEntryShortId,
        protocolIconStatus,
        protocolEntryCompaniesById?.[protocolEntry.id] ?? [],
        protocolEntryChatsById?.[protocolEntry.id] ?? []
      );
      if (matching) {
        matchingProtocolEntries.push(protocolEntry);
      }
      if (matchingChildren.length > 0 || matching) {
        filteredProtocolEntries.push(protocolEntry);
      }
    }
    return [allMatchingChildren, filteredProtocolEntries, matchingProtocolEntries];
  }

  getFilteredProtocolEntries(protocolId: IdType): Observable<[ProtocolEntry[] | null, ProtocolEntryOrOpen[]]> {
    return combineLatestAsync([
      this.protocolEntryFilters,
      this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId),
      this.protocolDataService.getById(protocolId),
      this.protocolTypeDataService.dataGroupedById,
      this.protocolEntryTypeDataService.dataGroupedById,
      this.protocolEntryCompanyDataService.dataGroupedByEntryId,
      this.protocolService.getIsProtocolLayoutShort$(protocolId),
      this.protocolEntryChatDataService.dataByProtocolEntryId$,
    ]).pipe(
      switchMap(async ([protocolEntryFilters, protocolEntries, protocol, protocolTypesById, protocolEntryTypesById, protocolEntryCompaniesByEntryId, isLayoutShort, protocolEntryChatsByEntryId]) => {
        const parentProtocolEntries = protocolEntries.filter((protocolEntry) => !protocolEntry.parentId);
        const childProtocolEntries = protocolEntries.filter((protocolEntry) => !!protocolEntry.parentId);
        const [children, filtered] = this.filterProtocolEntriesSync(protocolEntryFilters, parentProtocolEntries, protocol, protocolTypesById, protocolEntryTypesById, childProtocolEntries, {
          protocolEntryCompaniesById: protocolEntryCompaniesByEntryId,
          isLayoutShort,
          protocolEntryChatsById: protocolEntryChatsByEntryId,
        });
        return [children, filtered] as [ProtocolEntry[] | null, ProtocolEntryOrOpen[]];
      })
    );
  }

  getFilteredProtocolEntriesAcrossProjects(protocolId: IdType): Observable<[ProtocolEntry[] | null, ProtocolEntryOrOpen[]]> {
    return combineLatest([
      this.protocolDataService.getByIdAcrossProjects(protocolId),
      this.protocolEntryFilters,
      this.protocolEntryDataService.getParentEntriesOrOpenByProtocolIdAcrossProjects(protocolId),
    ]).pipe(switchMap(([protocol, protocolEntryFilters, protocolEntries]) => this.filterProtocolEntries(protocolEntryFilters, protocolEntries, protocol)));
  }

  getChildrenEntries(protocolId: IdType, protocolEntries: ProtocolEntryOrOpen[]): Observable<ProtocolEntry[]> {
    if (protocolEntries.length === 0) {
      return of([] as ProtocolEntry[]);
    }

    const protocolEntriesById = _.keyBy(protocolEntries, 'id');

    return this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId).pipe(
      map((entries) =>
        entries.filter((entry) => {
          if (!entry.parentId) {
            return false;
          }

          const parentEntry = protocolEntriesById[entry.parentId];

          if (!parentEntry) {
            return false;
          }

          return entry.isOpenEntry || !parentEntry.isOpenEntry;
        })
      )
    );
  }

  replaceFilter(filter: ProtocolEntryParameterFilter) {
    const filters = this.observableProtocolEntryFilters.getValue();
    if (filter.mode === 'contains') {
      this.applyFilter([...filters.filter((f) => f.mode !== 'contains'), filter]);
    }
  }

  getFilteredAndSortedProtocolEntries(protocol: Protocol): Observable<{
    parents: ProtocolEntryOrOpen[];
    children: ProtocolEntry[] | null;
  }> {
    return combineLatestAsync([this.getFilteredProtocolEntries(protocol.id), this.protocolEntryFilters]).pipe(
      switchMap(async ([[children, protocolEntries], filters]) => {
        const theProtocol = {
          ...protocol,
          sortEntriesBy: filters.find((protocolEntryFilter) => protocolEntryFilter.mode === 'sort' && protocolEntryFilter.fieldName === 'sortEntriesBy')?.value.uniqueId ?? protocol.sortEntriesBy,
        };

        return {
          children,
          parents: await this.protocolEntryService.sortProtocolEntriesByProtocolSortEntry(protocolEntries, theProtocol),
        };
      })
    );
  }
}
