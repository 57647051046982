import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {observableToPromise} from 'src/app/utils/async-utils';
import {Project, ProjectStatusEnum} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../data/project-data.service';
import _ from 'lodash';

export enum ProjectOmissionMessage {
  ARCHIVED,
  DELETED,
}

@Injectable({
  providedIn: 'root',
})
export class ProjectOmissionService {
  constructor(
    private projectDataService: ProjectDataService,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private router: Router
  ) {}

  isProjectActive(project: Project): boolean {
    return project.status === null || project.status === undefined || project.status === ProjectStatusEnum.ACTIVE;
  }

  async setNextActiveProject() {
    const projects = await observableToPromise(this.projectDataService.dataAcrossClientsActive$);
    const nextProject = _.head(projects);
    if (nextProject) {
      await this.setSelectedProject(nextProject);
    } else {
      await this.projectDataService.setCurrentProject(undefined);
    }
  }

  getProjectOmissionMessage(name: string, type: ProjectOmissionMessage) {
    if (type === ProjectOmissionMessage.ARCHIVED) {
      return this.translateService.instant('project.alert.projectArchived', {projectName: name});
    } else if (type === ProjectOmissionMessage.DELETED) {
      return this.translateService.instant('project.alert.projectDeleted', {projectName: name});
    }
  }

  private async setSelectedProject(project?: Project) {
    const currentProject = await this.projectDataService.getCurrentProject();
    if (project && (!currentProject || currentProject.id !== project.id)) {
      await this.projectDataService.setCurrentProject(project);
    }
  }

  async showMessageAndNavigate(message: string) {
    const alert = await this.alertCtrl.create({
      message,
      buttons: [
        {
          text: this.translateService.instant('okay'),
          role: 'accept',
          handler: async () => {
            await this.router.navigate(['/projects']);
          },
        },
      ],
    });
    alert.present();
  }
}
