import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertController, IonicModule, LoadingController, ModalController} from '@ionic/angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {PipesModule} from '../../../pipes/pipes.module';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {PdfMailingListComponent} from '../../pdf/pdf-mailing-list/pdf-mailing-list.component';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {EntryMailSendWorkflowStep, EntryMailSendWorkflowStepConfig} from '../../../model/entry-mail-send';
import {PdfMailingListEmployee, WorkflowType} from '../../../model/send-protocol';
import {
  Address,
  Attachment,
  convertRichTextToPlainText,
  ENTRY_MAIL_REQ_DEFAULT,
  EntryMailReq,
  ErrorCodeAuthenticationType,
  formatProjectNumberOptional,
  IdType,
  isRichText,
  Participant,
  PdfProtocolSetting,
  Project,
  UserEmailSignature,
} from 'submodules/baumaster-v2-common';
import {FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ParticipantDataService} from '../../../services/data/participant-data.service';
import {ToastService} from '../../../services/common/toast.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {LoggingService} from '../../../services/common/logging.service';
import {SyncStrategy} from '../../../services/sync/sync-utils';
import {SyncStatusService} from '../../../services/sync/sync-status.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {SyncService} from '../../../services/sync/sync.service';
import {map, switchMap, takeUntil, takeWhile} from 'rxjs/operators';
import {EntryMailService} from '../../../services/entry/entry-mail.service';
import {AlertService} from '../../../services/ui/alert.service';
import {EmptyUserEmailSignature, UserEmailSignatureFormType} from '../../../model/email-signature-form-model';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {AddressDataService} from '../../../services/data/address-data.service';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {UserEmailSignatureService} from '../../../services/user/user-email-signature.service';
import {PdfProtocolSettingService} from '../../../services/pdf/pdf-protocol-setting.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MissingAttachmentsComponent} from '../../pdf/missing-attachments/missing-attachments.component';
import {UserEmailSignatureDataService} from '../../../services/data/user-email-signature-data.service';
import {PdfEmailSettingsBetweenTemplateDirective, PdfEmailSettingsComponent} from '../../pdf/pdf-email-settings/pdf-email-settings.component';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {WithParamsTranslateService} from 'src/app/services/common/with-params-translate.service';

const LOG_SOURCE = 'EntryMailSendModalComponent';

@Component({
  selector: 'app-entry-mail-send-modal',
  templateUrl: './entry-mail-send-modal.component.html',
  styleUrls: ['./entry-mail-send-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    TranslateModule,
    PipesModule,
    PdfMailingListComponent,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
    PdfEmailSettingsComponent,
    PdfEmailSettingsBetweenTemplateDirective,
  ],
})
export class EntryMailSendModalComponent implements OnInit, OnDestroy {
  @HostBinding('class.omg-boundary') readonly omg = true;
  @ViewChild('appPdfMailingList') appPdfMailingList: PdfMailingListComponent;

  private destroy$ = new Subject<void>();
  private modal: HTMLIonModalElement;

  networkConnected$ = this.networkStatusService.onlineOrUnknown$;
  workflowType = WorkflowType.EntryMail;
  currentStep: EntryMailSendWorkflowStep;
  workflowStepConfigs: Array<EntryMailSendWorkflowStepConfig> = [
    {
      currentStep: EntryMailSendWorkflowStep.MAILING_LIST,
      previousStep: null,
      nextStep: EntryMailSendWorkflowStep.EMAIL_SETTINGS,
    },
    {
      currentStep: EntryMailSendWorkflowStep.EMAIL_SETTINGS,
      previousStep: EntryMailSendWorkflowStep.MAILING_LIST,
      nextStep: null,
    },
  ];
  currentWorkflowConfig = this.workflowStepConfigs[0];
  private allParticipants: Array<Participant> | undefined;
  private localChangesSubscription: Subscription;
  private localChanges: Set<IdType | undefined>;
  private beforeSendPdfDataSyncStatusSubscription: Subscription | undefined;
  private currentProject: Project | undefined;
  htmlContent: string | undefined;
  attachmentsMissingContent: Array<Attachment> | undefined;
  get currentStepIndex() {
    return Math.max(this.workflowStepConfigs?.indexOf(this.currentWorkflowConfig) ?? 0, 0);
  }
  searchTextInput: string | undefined;
  private participantChanges = new Map<string, Participant>();
  isMailingListNotEmpty: boolean | undefined;
  loading = false;
  private syncedLocalChangesBeforeSend = false;
  private spinnerSyncInProgress: HTMLIonLoadingElement | undefined;
  emailSettingsForm: UntypedFormGroup;
  emailFormDirty = false;
  pdfProtocolSettings: PdfProtocolSetting | undefined;
  userEmailSignature$ = this.userEmailSignatureDataService.dataReally.pipe(map((signatures) => signatures?.[0]));
  userEmailSignature: UserEmailSignature | undefined;
  recipientEmails$: Observable<string[]>;

  @Input()
  entryIds: Array<IdType>;
  address$ = this.userProfileService.currentUserProfile$.pipe(switchMap((profile) => this.addressDataService.getById(profile.addressId)));

  constructor(
    private entryMailService: EntryMailService,
    private networkStatusService: NetworkStatusService,
    private modalController: ModalController,
    private projectDataService: ProjectDataService,
    private participantDataService: ParticipantDataService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private syncService: SyncService,
    private syncStatusService: SyncStatusService,
    private userEmailSignatureService: UserEmailSignatureService,
    private toastService: ToastService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private alertService: AlertService,
    private pdfProtocolSettingService: PdfProtocolSettingService,
    private posthogService: PosthogService,
    private cRef: ChangeDetectorRef,
    private alertController: AlertController,
    private userEmailSignatureDataService: UserEmailSignatureDataService,
    private userProfileService: UserProfileService,
    private withParamsTranslateService: WithParamsTranslateService
  ) {}

  async ngOnInit() {
    this.localChangesSubscription = this.syncStatusService.clientOrProjectsWithLocalChanges$.subscribe((data) => (this.localChanges = data));
    this.projectDataService.currentProjectObservable.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.currentProject = project;
    });
    if (!this.currentStep) {
      this.currentStep = EntryMailSendWorkflowStep.MAILING_LIST;
      this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
    }
    this.entryMailService
      .generateHtmlContent$(this.entryIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe((content) => {
        this.htmlContent = content.html;
        this.attachmentsMissingContent = content.attachmentsMissingContent;
        this.cRef.detectChanges();
      });
    this.emailSettingsForm = this.pdfProtocolSettingService.getEmptyEmailSettingsFormGroup();
    this.emailSettingsForm
      .get('subject')
      .setValue(this.translateService.instant('entryMailSend.mail.subject', {projectNumber: formatProjectNumberOptional(this.currentProject?.number), projectName: this.currentProject?.name}), {
        onlySelf: true,
      });
    this.emailSettingsForm.get('text').setValue(this.translateService.instant('entryMailSend.mail.salutation'), {onlySelf: true});
    await this.applyFallbackTextsToEmailSettings();
    this.userEmailSignature$.pipe(takeUntil(this.destroy$)).subscribe((userEmailSignature) => {
      this.userEmailSignature = userEmailSignature;
      const userEmailSignatureForm: UserEmailSignatureFormType = this.emailSettingsForm.get('emailSignature').value;
      userEmailSignatureForm.text = userEmailSignature.text;
      this.emailSettingsForm.get('emailSignature').setValue(userEmailSignatureForm, {onlySelf: true});
    });
    this.recipientEmails$ = this.getParticipantsObservable().pipe(
      map((participants) => Array.from(new Map(participants.map((part) => [part.id, part])).values())),
      switchMap((participants) =>
        combineLatestAsync([this.profileDataService.dataWithDefaultType$, this.addressDataService.dataGroupedById]).pipe(
          map(([profiles, addressesById]) => {
            const addressMap = new Map<IdType, Address>();

            profiles.forEach((profile) => addressMap.set(profile.id, addressesById[profile.addressId]));

            return addressMap;
          }),
          map((addressMap) =>
            participants
              .filter((participant) => participant.mailingList)
              .map((participant) => addressMap.get(participant.profileId)?.email)
              .filter((email) => email)
          )
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.beforeSendPdfDataSyncStatusSubscription?.unsubscribe();
    this.localChangesSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getParticipantsObservable() {
    return this.participantDataService.getForGlobalSearchCurrentProject();
  }

  onSearchTextChanged() {
    if (this.appPdfMailingList) {
      this.appPdfMailingList.searchTextInput = this.searchTextInput;
      this.appPdfMailingList.searchEmployee();
    }
  }

  async saveMailingList() {
    try {
      const changesAsArray: Array<Participant> = Array.from(this.participantChanges.values());
      if (!changesAsArray.length) {
        return;
      }
      const currentProject = await this.projectDataService.getMandatoryCurrentProject();
      await this.participantDataService.insertOrUpdate(changesAsArray, currentProject.id);
      this.participantChanges.clear();
      await this.toastService.savingSuccess();
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save mailing list ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save mailing list. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.savingError();
      throw error;
    }
  }

  async onParticipantsLoaded(participants: Array<Participant>) {
    this.allParticipants = participants;
    this.updateCanNavigateNext();
  }

  onRecipientChanged(employee: PdfMailingListEmployee) {
    this.participantChanges.set(employee.participant.profileId, employee.participant);
    this.updateCanNavigateNext();
  }

  onPresentChanged(employee: PdfMailingListEmployee) {
    this.participantChanges.set(employee.participant.profileId, employee.participant);
  }

  updateCanNavigateNext() {
    this.isMailingListNotEmpty = !!this.allParticipants.find((participant) => participant.mailingList) || !!Array.from(this.participantChanges.values()).find((participant) => participant.mailingList);
  }

  getCurrentWorkflowConfig(): EntryMailSendWorkflowStepConfig {
    const workflowConfig = this.workflowStepConfigs.find((config) => config.currentStep === this.currentStep);
    if (!workflowConfig) {
      throw new Error('WorkflowConfig not found for the current workflow step ' + this.currentStep);
    }
    return workflowConfig;
  }

  back() {
    const workflowConfig = this.getCurrentWorkflowConfig();
    this.currentStep = workflowConfig.previousStep;
    this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
  }

  async next() {
    if (this.currentStep === EntryMailSendWorkflowStep.MAILING_LIST) {
      this.loading = true;
      try {
        await this.saveMailingList();
      } finally {
        this.loading = false;
      }
      this.syncLocalChanges();
    }
    if (this.currentStep === EntryMailSendWorkflowStep.EMAIL_SETTINGS) {
      this.syncedLocalChangesBeforeSend = false;
    }
    const workflowConfig = this.getCurrentWorkflowConfig();
    this.currentStep = workflowConfig.nextStep;
    this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
  }

  goToFirstStep() {
    this.currentStep = this.workflowStepConfigs[0].currentStep;
    this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
  }

  async syncLocalChanges() {
    if (this.localChanges.size > 0 && !this.syncStatusService.dataSyncInProgress.inProgress) {
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
  }

  async syncCurrentProject() {
    const spinnerSyncInProgress = await this.loadingController.create({
      message: this.translateService.instant('sendProtocol.syncInProgress'),
    });
    try {
      await spinnerSyncInProgress.present();
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    } finally {
      await spinnerSyncInProgress.dismiss();
    }
  }

  async syncLocalChangesBeforeSending() {
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    await this.spinnerSyncInProgress?.dismiss();
  }

  async triggerSend() {
    if (this.syncStatusService.dataSyncInProgress.inProgress) {
      this.spinnerSyncInProgress = await this.loadingController.create({
        message: this.translateService.instant('sendProtocol.syncInProgress'),
      });
      await this.spinnerSyncInProgress.present();
      this.beforeSendPdfDataSyncStatusSubscription?.unsubscribe();
      this.beforeSendPdfDataSyncStatusSubscription = this.syncStatusService.dataSyncInProgressObservable
        .pipe(takeWhile((syncStatus) => !this.syncedLocalChangesBeforeSend))
        .subscribe(async (dataSyncStatus) => {
          if (!dataSyncStatus.inProgress && this.localChanges.size > 0) {
            this.syncedLocalChangesBeforeSend = true;
            await this.syncLocalChangesBeforeSending();
            await this.confirmIfAttachmentsNotUploadedAndSendPdf();
          } else if (!dataSyncStatus.inProgress && this.localChanges.size === 0) {
            this.syncedLocalChangesBeforeSend = true;
            await this.spinnerSyncInProgress.dismiss();
            await this.confirmIfAttachmentsNotUploadedAndSendPdf();
          }
        });
    } else {
      if (this.localChanges.size > 0) {
        this.spinnerSyncInProgress = await this.loadingController.create({
          message: this.translateService.instant('sendProtocol.syncInProgress'),
        });
        await this.spinnerSyncInProgress.present();
        await this.syncLocalChangesBeforeSending();
        await this.confirmIfAttachmentsNotUploadedAndSendPdf();
        return;
      }
      const success = await this.sendMail();
      if (success) {
        await this.modal.dismiss();
      }
    }
  }

  private async confirmIfAttachmentsNotUploadedAndSendPdf(beforeCallingSend?: () => Promise<void>) {
    if (this.syncStatusService.numberOfAttachmentsInUploadQueue > 0) {
      const send = await this.alertService.confirm({
        header: 'sendProtocol.confirmNotUploadedAttachments',
        message: '',
        cancelLabel: 'cancel',
        confirmLabel: 'send',
      });
      if (send) {
        await this.sendMail();
      }
    } else {
      await this.sendMail();
    }
    await this.modal.dismiss();
  }

  private getParticipantsProfileIdMailingList(): Array<IdType> {
    if (!this.allParticipants) {
      return [];
    }
    return this.allParticipants.filter((participant) => participant.mailingList).map((participant) => participant.profileId);
  }

  private async sendMail(ignoreMissingAttachments = false): Promise<boolean> {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('entryMailSend.sending'),
    });
    let req: EntryMailReq;
    try {
      await loading.present();
      const recipientProfileIds = this.getParticipantsProfileIdMailingList();
      const emailSettings = this.emailSettingsForm.getRawValue();
      req = {
        entryIds: this.entryIds,
        recipientProfileIds,
        language: this.translateService.currentLang,
        text: emailSettings.text,
        subject: emailSettings.subject,
        mailTextUnderContent: emailSettings.mailTextUnderDownloadLink,
        emailSignature: this.emailSettingsForm.get('addSignature').value === true ? emailSettings.emailSignature : undefined,
        ignoreMissingAttachments,
      };
      await this.entryMailService.sendMail(req);
      this.posthogService.captureEvent('Task email sent', {
        amount: this.entryIds.length,
      });
      await this.toastService.info('entryMailSend.sendSuccess');
      if (ignoreMissingAttachments) {
        await this.modal.dismiss();
      }
      return true;
    } catch (error) {
      const syncPromise = this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      let errorMessage = convertErrorToMessage(error);
      if (error instanceof HttpErrorResponse && error.status === 400) {
        const errorCode = error.error.errorCode as ErrorCodeAuthenticationType;
        if (errorCode === 'MISSING_ATTACHMENTS' && !ignoreMissingAttachments) {
          this.loggingService.warn('PdfWorkflowComponent - missing attachments in callSendPdf', errorCode);
          const attachmentsMissingContent: Array<Attachment> = error.error.errorData.attachmentsMissingContent;
          if (attachmentsMissingContent?.length) {
            this.alertMissingAttachments(attachmentsMissingContent, req); // no "await" here
            return false;
          }
        } else {
          errorMessage = this.translateService.instant(`errorCodeMessages.${errorMessage}`);
        }
      }
      this.systemEventService.logErrorEvent('EntryMailSendModal.sendMail', errorMessage);
      this.loggingService.error(LOG_SOURCE, `'EntryMailSendModal.sendMail failed with error ${errorMessage}`);
      await this.toastService.error(this.translateService.instant('entryMailSend.sendFailed', {message: errorMessage}));
      return false;
    } finally {
      await loading.dismiss();
    }
  }

  async applyDataToEmailSettings() {
    if (this.pdfProtocolSettings) {
      this.emailSettingsForm.get('subject').setValue(this.pdfProtocolSettings.mailSubject, {onlySelf: true});
      this.emailSettingsForm
        .get('text')
        .setValue(isRichText(this.pdfProtocolSettings.mailText) ? convertRichTextToPlainText(this.pdfProtocolSettings.mailText) : this.pdfProtocolSettings.mailText, {onlySelf: true});
      this.emailSettingsForm.get('mailTextUnderDownloadLink').setValue(this.pdfProtocolSettings.mailTextUnderDownloadLink ?? '', {onlySelf: true});
    } else {
      this.emailSettingsForm.get('mailTextUnderDownloadLink').setValue(this.pdfProtocolSettings.mailTextUnderDownloadLink ?? '', {onlySelf: true});
      await this.applyFallbackTextsToEmailSettings();
    }

    this.emailSettingsForm.get('addSignature').setValue(this.pdfProtocolSettings?.addSignature ?? false, {onlySelf: true});
    if (this.userEmailSignature) {
      const {id, changedAt, ...signatureValues} = this.userEmailSignature;
      this.emailSettingsForm.get('emailSignature').setValue(signatureValues, {onlySelf: true});
    } else {
      this.emailSettingsForm.get('emailSignature').setValue({...EmptyUserEmailSignature}, {onlySelf: true});
    }
  }

  handleAttachmentUserEmailSignatureCreate({blob, filename}: {blob: Blob; filename: string}) {
    return this.userEmailSignatureService.uploadAttachmentUserEmailSignature(blob, filename);
  }

  private async alertMissingAttachments(missingAttachments: Array<Attachment>, req: EntryMailReq) {
    const detailsText = this.translateService.instant('details');
    const closeText = this.translateService.instant('close');
    const sendAnywayText = this.translateService.instant('sendAnyway');
    const alert = await this.alertController.create({
      message: this.translateService.instant('entryMailSend.errorSendingMissingAttachments'),
      buttons: [
        {
          text: detailsText,
          role: 'details',
        },
        {
          text: closeText,
          role: 'close',
        },
        {
          text: sendAnywayText,
          role: 'ignore',
        },
      ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'details') {
      const modal = await this.modalController.create({
        component: MissingAttachmentsComponent,
        componentProps: {attachments: missingAttachments, attachmentsOrigin: 'server'},
      });
      await modal.present();
    } else if (result.role === 'ignore') {
      await this.sendMail(true);
    }
  }

  async cancel() {
    await this.modal.dismiss();
  }

  async applyFallbackTextsToEmailSettings() {
    this.emailSettingsForm.get('text').setValue(
      await observableToPromise(
        this.withParamsTranslateService.translateInLanguage(
          {
            key: 'entryMailSend.mail.salutation',
            params: {projectNumber: `${formatProjectNumberOptional(this.currentProject?.number)}`, projectName: `${this.currentProject?.name}`},
          },
          this.currentProject.language
        )
      ),
      {onlySelf: true}
    );
    const address = await observableToPromise(this.address$);
    let endingText = await observableToPromise(
      this.withParamsTranslateService.translateInLanguage(
        {
          key: 'entryMailSend.mail.endingText',
          params: {userName: `${address?.firstName} ${address?.lastName}`},
        },
        this.currentProject.language
      )
    );
    if (ENTRY_MAIL_REQ_DEFAULT.includeZipFileLink) {
      // in case we later want to make this optional
      // That translation must be kept in sync with the server and this link will be replaced by the actual link. See src/services/entryMail/entryMail.service.ts line 174
      const downloadLink = this.translateService.instant('entryMailSend.mail.downloadLinkText');
      endingText += '\n\n' + this.translateService.instant('entryMailSend.mail.downloadFooter', {downloadLink});
    }

    this.emailSettingsForm.get('mailTextUnderDownloadLink').setValue(endingText, {onlySelf: true});
  }
}
