import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DeviceLimitService} from './device-limit.service';

@Injectable()
export class HttpResponseDeviceLimitInterceptor implements HttpInterceptor, OnDestroy {
  constructor(private deviceLimitService: DeviceLimitService) {}

  ngOnDestroy(): void {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error instanceof Error || error?.status === 412) {
          this.deviceLimitService.handleDeviceLimitReached();
        }
        return throwError(error);
      })
    );
  }
}
