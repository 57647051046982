import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ConstructionScheduleTask, IdType, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {DataServiceDeleteOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/constructionScheduleTask';

@Injectable({
  providedIn: 'root',
})
export class ConstructionScheduleTaskDataService extends AbstractProjectAwareDataService<ConstructionScheduleTask> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.CONSTRUCTION_SCHEDULE_TASK,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService,
      VERSION_INTRODUCED_DEFAULT,
      ['sortOrder', 'changedAt'],
      ['asc', 'asc']
    );
  }

  getLastSortOrderForProject(projectId: IdType): Observable<number> {
    return this.dataByProjectId$.pipe(
      filter((dataByProjectId) => !!dataByProjectId && dataByProjectId.has(projectId)),
      map((dataByProjectId) => {
        const data = dataByProjectId.get(projectId);
        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].sortOrder !== undefined && data[i].sortOrder !== null) {
            return data[i].sortOrder;
          }
        }

        return 0;
      })
    );
  }

  public findForParent(parentId: IdType | undefined | null): Observable<Array<ConstructionScheduleTask>> {
    return this.data.pipe(map((values) => values.filter((value) => value.parentId === parentId || (!value.parentId && !parentId))));
  }

  public findAllDescendantsForParent(parentId: IdType | undefined | null): Observable<Array<ConstructionScheduleTask>> {
    return this.data.pipe(
      map((values) => {
        if (!parentId) {
          return values.filter((value) => !value.parentId);
        }

        const valuesByParentId: {[parentId: string]: ConstructionScheduleTask[]} = _.groupBy(values, 'parentId');

        const getByParentId = (theParentId: IdType): ConstructionScheduleTask[] => {
          const all = valuesByParentId[theParentId];

          if (!all) {
            return [];
          }

          return [...all.map((v) => getByParentId(v.id)), all].reduce((acc, v) => {
            acc.push(...v);

            return acc;
          }, []);
        };

        return getByParentId(parentId);
      })
    );
  }

  private allChildren(value: ConstructionScheduleTask, storageData: Array<ConstructionScheduleTask>): Array<ConstructionScheduleTask> {
    const directChildren = storageData.filter((item) => item.parentId === value.id);
    if (!directChildren.length) {
      return [];
    }
    let allChildren = new Array<ConstructionScheduleTask>();
    for (const child of directChildren) {
      const childChildren = this.allChildren(child, storageData);
      if (childChildren.length) {
        allChildren = allChildren.concat(childChildren);
      }
    }
    return allChildren.concat(directChildren);
  }

  public async deleteCascade(value, projectId: IdType, options?: DataServiceDeleteOptions): Promise<Array<ConstructionScheduleTask>> {
    let deleteElements = new Array<ConstructionScheduleTask>();
    await super.delete(
      (storageData) => {
        const children = this.allChildren(value, storageData);
        deleteElements = children.concat(value);
        return deleteElements;
      },
      projectId,
      options
    );
    return deleteElements;
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
