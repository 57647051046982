<app-modal-header (close)="dismiss()">
    <ion-title class="ion-text-center">
      {{selectedAttachment?.fileName}}
    </ion-title>
</app-modal-header>


<ion-content scroll-y="false">

  <button ion-button class="swiper-button swiper-button-prev" type="button" (click)="slidePrev()"  *ngIf="!ionSlideReachStart"></button>

  <swiper-container #fullScreenSwiperContainer speed="200" navigation="false" [modules]="swiperModules" class="slides" [id]="'slides-container' + instanceId" zoom="true">
    @if (selectedAttachment && slideViewConfig) {
    <swiper-slide>

      <div *ngIf="slideViewConfig[selectedAttachment.id] && slideViewConfig[selectedAttachment.id]?.isContentAvailable && slideViewConfig[selectedAttachment.id]?.isImageFile && !slideViewConfig[selectedAttachment.id]?.loadingImageFailed" class="slide-content d-flex ion-justify-content-center">
        <img *ngIf="!slideViewConfig[selectedAttachment.id]?.isCanvasReady" [attr.src]="cachedFullSizeImages[selectedAttachment.id]?.thumbnail |async| safeUrl">
        <canvas id="canvas-{{selectedAttachment.id}}"></canvas>
      </div>

      <app-offline-info class="d-flex ion-align-self-center" *ngIf="slideViewConfig[selectedAttachment.id] && slideViewConfig[selectedAttachment.id].isContentAvailable && !slideViewConfig[selectedAttachment.id].loadingImageFailed &&
        cachedFullSizeImages[selectedAttachment.id] && ((cachedFullSizeImages[selectedAttachment.id].thumbnail | async) === undefined || (cachedFullSizeImages[selectedAttachment.id].image | async) === undefined)"
        [showRetry]="true" (retry)="retryDownload(selectedAttachment.id)">
      </app-offline-info>

      <div *ngIf="!slideViewConfig[selectedAttachment.id]?.isContentAvailable || slideViewConfig[selectedAttachment.id]?.loadingImageFailed" class="file-preview-container">
        <fa-icon [icon]="['fal', 'image']" class="no-image-icon ion-no-padding inactive"></fa-icon>
        <div class="ion-padding size-m">{{'modal.no_preview' | translate}}</div>
      </div>

      <div *ngIf="slideViewConfig[selectedAttachment.id] && slideViewConfig[selectedAttachment.id]?.isContentAvailable && !slideViewConfig[selectedAttachment.id]?.isImageFile" class="file-preview-container">
        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isPdfFile">
          <fa-icon *ngIf="!slideViewConfig[selectedAttachment.id]?.showPreview" [icon]="['fal', 'file-pdf']"></fa-icon>
          <div *ngIf="!slideViewConfig[selectedAttachment.id]?.showPreview" class="ion-padding">{{selectedAttachment.fileName}}</div>
          <div *ngIf="!slideViewConfig[selectedAttachment.id]?.showPreview" class="ion-padding size-m">
            <ion-button (click)="showPdfPreview(selectedAttachment)" class="omg-btn-secondary">{{'preview' | translate}}</ion-button>
          </div>
        </div>

        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isAudioFile">
          <fa-icon *ngIf="slideViewConfig[selectedAttachment.id]?.isAudioFile" [icon]="['fal', 'microphone']"></fa-icon>
          <div class="ion-padding">{{selectedAttachment.fileName}}</div>
          <div>
            <audio [id]="selectedAttachment.id" controls preload="auto">
              <source [src]="(cachedFullSizeImages[selectedAttachment.id]?.image) |async| safeUrl" [type]="selectedAttachment.mimeType">
            </audio>
            <div *ngIf="slideViewConfig[selectedAttachment.id]?.loadingMediaFailed" class="ion-padding size-m">{{'modal.no_preview' | translate}}</div>
          </div>
        </div>

        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isVideoFile">
          <video [attr.src]="(cachedFullSizeImages[selectedAttachment.id]?.image) |async| safeUrl" [id]="selectedAttachment.id" class="d-none" controls preload="auto">
          </video>
          <div *ngIf="slideViewConfig[selectedAttachment.id]?.loadingMediaFailed">
            <fa-icon [icon]="['fal', 'file-video']" class="no-image-icon ion-no-padding inactive"></fa-icon>
            <div class="ion-padding size-m">{{'modal.no_preview' | translate}}</div>
          </div>
        </div>

        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isWordFile">
          <fa-icon [icon]="['fal', 'file-word']"></fa-icon>
          <div class="ion-padding">{{selectedAttachment.fileName}}</div>
        </div>

        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isExcelFile">
          <fa-icon [icon]="['fal', 'file-excel']"></fa-icon>
          <div class="ion-padding">{{selectedAttachment.fileName}}</div>
        </div>

        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isPowerPointFile">
          <fa-icon [icon]="['fal', 'file-powerpoint']"></fa-icon>
          <div class="ion-padding">{{selectedAttachment.fileName}}</div>
        </div>

        <div *ngIf="slideViewConfig[selectedAttachment.id]?.isCompressedFile">
          <fa-icon [icon]="['fal', 'file-archive']"></fa-icon>
          <div class="ion-padding">{{selectedAttachment.fileName}}</div>
        </div>

        <div *ngIf="!slideViewConfig[selectedAttachment.id]?.isPdfFile && !slideViewConfig[selectedAttachment.id]?.isAudioFile && !slideViewConfig[selectedAttachment.id]?.isVideoFile
                    && !slideViewConfig[selectedAttachment.id]?.isWordFile && !slideViewConfig[selectedAttachment.id]?.isExcelFile && !slideViewConfig[selectedAttachment.id]?.isPowerPointFile
                    && !slideViewConfig[selectedAttachment.id]?.isCompressedFile">
          <fa-icon [icon]="['fal', 'file-alt']"></fa-icon>
          <div class="ion-padding">{{selectedAttachment.fileName}}</div>
        </div>
      </div>

      <div class="attachment-info-footer">
        <div class="full-width ion-text-start d-flex ion-align-items-center ion-justify-content-between">
          <div>
            <ion-button *ngIf="showEntryLink && !slideViewConfig[selectedAttachment.id]?.isAttachmentProject && !slideViewConfig[selectedAttachment.id]?.isAttachmentReport" class="size-l omg-btn-text" (click)="goToEntry(selectedAttachment.id)">{{'modal.goToEntry' | translate}}</ion-button>
            <ion-button *ngIf="showEntryLink && slideViewConfig[selectedAttachment.id]?.isAttachmentReport" class="size-l omg-btn-text" (click)="goToReport(selectedAttachment.id)">{{'modal.goToReport' | translate}}</ion-button>
          </div>
          <div>
            <ion-text *ngIf="selectedAttachment.createdById !== null">{{( ((addressById | async)[(profileById |async)[(userById | async)[selectedAttachment["createdById"]]?.profileId]?.addressId]) || ((addressById | async)[(profileByAttachedToUserId |async).get(selectedAttachment["createdById"])?.addressId]) ) | userNameString}}</ion-text>
            <ion-text class="ion-padding-start">{{selectedAttachment.changedAt | date: 'dd.MM.yyyy'}}</ion-text>
            <ion-icon *ngIf="slideViewConfig[selectedAttachment.id]?.isChatFile" class="chat-icon" name="chatbox-outline"></ion-icon>
          </div>
        </div>
      </div>
    </swiper-slide>
    }
  </swiper-container>

  <button ion-button class="swiper-button swiper-button-next" type="button" (click)="slideNext()" *ngIf="!ionSlideReachEnd"></button>
</ion-content>

<app-modal-footer>
  <ion-buttons *ngIf="isSlidesReady" slot="start">
    <ion-button class="omg-btn-secondary ion-hide-sm-down" (click)="zoom(true)"><fa-icon [icon]="['fal', 'search-plus']"></fa-icon></ion-button>
    <ion-button class="omg-btn-secondary ion-hide-sm-down" (click)="zoom(false)"><fa-icon [icon]="['fal', 'search-minus']"></fa-icon></ion-button>
    <ion-button class="omg-btn-secondary ion-hide-sm-down" (click)="openEditMode()" *ngIf="(slideViewConfig[selectedAttachment.id]?.isImageFile && onMarkingsChanged !== undefined) && enabledForViewer"
                [disabled]="!changingAttachmentEnabled || !slideViewConfig[selectedAttachment.id]?.isContentAvailable || slideViewConfig[selectedAttachment.id]?.loadingImageFailed"
                ><fa-icon [icon]="['fal', 'edit']"></fa-icon></ion-button>
    <ion-button *ngIf="(onAttachmentDeleted !== undefined) && enabledForViewer" [disabled]="!changingAttachmentEnabled" (click)="deleteFile()" class="ion-hide-sm-down omg-btn-secondary"><fa-icon [icon]="['fal', 'trash-alt']"></fa-icon></ion-button>
    <ion-button (click)="downloadFile()" *ngIf="!forceDisableDownload" [disabled]="!slideViewConfig[selectedAttachment.id]?.isContentAvailable || (slideViewConfig[selectedAttachment.id]?.isImageFile && slideViewConfig[selectedAttachment.id]?.loadingImageFailed)" class="ion-hide-sm-down omg-btn-secondary">
      <fa-icon [icon]="['fal', 'download']"></fa-icon>
    </ion-button>
    <ion-button (click)="downloadFile(true)" *ngIf="!forceDisableDownload && selectedAttachment.markings" [disabled]="!slideViewConfig[selectedAttachment.id]?.isContentAvailable || (slideViewConfig[selectedAttachment.id]?.isImageFile && slideViewConfig[selectedAttachment.id]?.loadingImageFailed)" class="ion-hide-sm-down omg-btn-secondary">
      <fa-layers>
        <fa-icon [icon]="['fal', 'download']"></fa-icon>
        <fa-icon [icon]="['fas', 'signature']" transform="shrink-4 up-8 right-8"></fa-icon>
      </fa-layers>
    </ion-button>
    <ion-button (click)="openPopoverMenu($event)" class="ion-hide-sm-up omg-btn-secondary"><fa-icon [icon]="['fal','ellipsis-h']"></fa-icon></ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismiss()">
      {{ 'button.cancel' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>