import {TemplatePortal} from '@angular/cdk/portal';
import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, computed, signal} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AutoSizeVirtualScrollStrategy, RxVirtualFor, RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';
import {compact, maxBy} from 'lodash';
import {SelectorUnit, SelectorUnitLevel} from 'src/app/model/selector-unit';
import {IdType} from 'submodules/baumaster-v2-common';
import {SelectorUnitWithPathAndLevel, SelectorUnitWithPathAndLevelAndSelected, SelectorUnitsByLevelAndParent, UnitSelectorModalFooterContext} from '../unit-selector-model';

export interface UnitInLevelSelectEvent {
  unit: SelectorUnitWithPathAndLevel;
  level: SelectorUnitLevel;
}

@Component({
  selector: 'app-unit-selector-tree',
  templateUrl: './unit-selector-tree.component.html',
  styleUrls: ['./unit-selector-tree.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, FontAwesomeModule, TranslateModule, RxVirtualFor, AutoSizeVirtualScrollStrategy, RxVirtualScrollViewportComponent],
})
export class UnitSelectorTreeComponent implements AfterViewInit, OnInit {
  @Input()
  selectorUnits: SelectorUnit[] = [];

  @Input()
  selectorUnitLevels: SelectorUnitLevel[] = [];

  private selectedUnitByLevelSignal = signal<Record<IdType, SelectorUnitWithPathAndLevel | undefined>>({});

  @Input()
  set selectedUnitByLevel(selectedUnitByLevel: Record<IdType, SelectorUnitWithPathAndLevel | undefined>) {
    this.selectedUnitByLevelSignal.set(selectedUnitByLevel);
  }
  get selectedUnitByLevel(): Record<IdType, SelectorUnitWithPathAndLevel | undefined> {
    return this.selectedUnitByLevelSignal();
  }

  @Input()
  selectorUnitsByLevelAndParent: SelectorUnitsByLevelAndParent;

  @Input()
  canReset = false;

  @ViewChild('toolbar', {read: TemplateRef})
  toolbar: TemplateRef<{}>;

  @ViewChild('footer', {read: TemplateRef})
  footer: TemplateRef<UnitSelectorModalFooterContext>;

  @Output()
  toolbarPortalSet = new EventEmitter<TemplatePortal<unknown>>();

  @Output()
  footerPortalSet = new EventEmitter<TemplatePortal<UnitSelectorModalFooterContext>>();

  @Output()
  unitInLevelSelect = new EventEmitter<UnitInLevelSelectEvent>();

  @Output()
  searchClick = new EventEmitter<MouseEvent>();

  protected currentLevelId = signal<IdType | undefined>(undefined);
  protected previousLevelId = computed<IdType | undefined>(() => {
    const currentLevelId = this.currentLevelId();
    if (!currentLevelId) {
      return undefined;
    }

    const levels = this.selectorUnitLevels;
    const index = levels.findIndex((l) => l.id === currentLevelId);
    if (index > 0) {
      return levels[index - 1].id;
    }
    return undefined;
  });
  protected nextLevelId = computed<IdType | undefined>(() => {
    const currentLevelId = this.currentLevelId();
    if (!currentLevelId) {
      return undefined;
    }

    const levels = this.selectorUnitLevels;
    const index = levels.findIndex((l) => l.id === currentLevelId);
    if (index > -1) {
      return levels[index + 1]?.id;
    }
    return undefined;
  });
  protected isFirstLevelCurrent = computed<boolean>(() => this.isFirstLevel(this.currentLevelId()));

  protected filteredSelectorUnitsByLevelAndParent = computed<SelectorUnitsByLevelAndParent>(() => {
    const selectorUnitsByLevelAndParent = this.getClonedSelectorUnitsByParentAndLevel();

    const selectedUnitsArray = this.selectorUnitLevels.map((level) => this.selectedUnitByLevel[level.id]);

    for (let i = 1; i < this.selectorUnitLevels.length; i++) {
      const level = this.selectorUnitLevels[i];
      const unitsByParentIdInLevel = selectorUnitsByLevelAndParent[level.id];
      for (const parentId of Object.keys(unitsByParentIdInLevel)) {
        const unitsInParentId = unitsByParentIdInLevel[parentId];

        unitsByParentIdInLevel[parentId] = unitsInParentId.filter((unit) => {
          for (let pathIndex = 0; pathIndex < i; pathIndex++) {
            const pathSegment = selectedUnitsArray[pathIndex];
            if (!pathSegment) {
              continue;
            }
            if (unit.path[pathIndex]?.id !== pathSegment.id) {
              return false;
            }
          }
          return true;
        });
      }
    }

    return selectorUnitsByLevelAndParent;
  });

  protected currentLevelUnits = computed<SelectorUnitWithPathAndLevelAndSelected[]>(() => this.getUnitsForLevel(this.currentLevelId(), this.previousLevelId()));

  protected canGoBack = computed<boolean>(() => !!this.previousLevelId());
  protected canGoNext = computed<boolean>(() => {
    if (!this.nextLevelId()) {
      return false;
    }

    return this.getUnitsForLevel(this.nextLevelId(), this.currentLevelId()).length > 0;
  });

  protected selectedUnit = computed<SelectorUnitWithPathAndLevel | undefined>(() => maxBy(compact(Object.values(this.selectedUnitByLevel)), 'level.index'));

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.preselectLevel();
  }

  private preselectLevel() {
    const selectedUnits = compact(Object.values(this.selectedUnitByLevel));
    if (selectedUnits.length === 0) {
      this.currentLevelId.set(this.selectorUnitLevels[0]?.id);
    } else {
      this.currentLevelId.set(maxBy(selectedUnits, 'level.index').unitLevelId);
    }
  }

  ngAfterViewInit() {
    this.toolbarPortalSet.emit(new TemplatePortal(this.toolbar, this.viewContainerRef));
    this.footerPortalSet.emit(new TemplatePortal(this.footer, this.viewContainerRef));
  }

  private getClonedSelectorUnitsByParentAndLevel(): SelectorUnitsByLevelAndParent {
    return Object.entries(this.selectorUnitsByLevelAndParent).reduce<typeof this.selectorUnitsByLevelAndParent>((acc, [key, val]) => {
      const valCopy = Object.entries(val).reduce<typeof val>((innerAcc, [innerKey, innerVal]) => ({...innerAcc, [innerKey]: [...innerVal]}), {});
      return {...acc, [key]: {...valCopy}};
    }, {});
  }

  private isFirstLevel(levelId: IdType | undefined) {
    if (!levelId) {
      return false;
    }

    const levels = this.selectorUnitLevels;
    const index = levels.findIndex((l) => l.id === levelId);
    return index === 0;
  }

  private getUnitsForLevel(levelId: IdType, previousLevelId: IdType) {
    const currentLevelSelectedUnit = this.selectedUnitByLevel[levelId];
    if (this.isFirstLevel(levelId)) {
      return this.mapUnitsWithSelected(this.filteredSelectorUnitsByLevelAndParent()[levelId]['undefined'], currentLevelSelectedUnit);
    }
    if (!previousLevelId) {
      return [];
    }

    const previousLevelSelectedUnit = this.selectedUnitByLevel[previousLevelId];

    if (!previousLevelSelectedUnit) {
      const unitsInLevel: SelectorUnitWithPathAndLevel[] = [];
      for (const values of Object.values(this.filteredSelectorUnitsByLevelAndParent()[levelId])) {
        unitsInLevel.push(...values);
      }
      return this.mapUnitsWithSelected(unitsInLevel, currentLevelSelectedUnit);
    }

    const unitsInLevel = this.filteredSelectorUnitsByLevelAndParent()[levelId][previousLevelSelectedUnit.id];
    return this.mapUnitsWithSelected(unitsInLevel, currentLevelSelectedUnit);
  }

  private mapUnitsWithSelected(units: SelectorUnitWithPathAndLevel[] | undefined, selectedUnit: SelectorUnitWithPathAndLevel | undefined): SelectorUnitWithPathAndLevelAndSelected[] {
    return units?.map((u) => ({...u, isSelected: selectedUnit?.id === u.id})) ?? [];
  }

  selectUnit(unit: SelectorUnitWithPathAndLevel) {
    this.unitInLevelSelect.emit({unit, level: unit.level});
  }

  protected back() {
    const previousLevelId = this.previousLevelId();
    if (previousLevelId) {
      this.currentLevelId.set(previousLevelId);
    }
  }

  protected next() {
    const nextLevelId = this.nextLevelId();
    if (nextLevelId) {
      this.currentLevelId.set(nextLevelId);
    }
  }
}
