import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';
import {CompanyCraftDetails} from 'src/app/model/company-craft-details';
import {CompanyDetails} from 'src/app/model/company-details';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {Company, CompanyCraft, IdType, customComparators, Address, ProtocolEntry, Protocol} from 'submodules/baumaster-v2-common';
import {AddressDataService} from '../data/address-data.service';
import {CompanyCraftDataService} from '../data/company-craft-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {ProfileCraftDataService} from '../data/profile-craft-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import _ from 'lodash';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ContactService} from '../contact/contact.service';
import {AuthenticationService} from '../auth/authentication.service';
import {UserPublicDataService} from '../data/user-public-data.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  data: Observable<CompanyDetails[]>;

  constructor(
    private companyDataService: CompanyDataService,
    private companyCraftDataService: CompanyCraftDataService,
    private addressDataService: AddressDataService,
    private craftDataService: CraftDataService,
    private profileCraftDataService: ProfileCraftDataService,
    private profileDataService: ProfileDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private contactService: ContactService,
    private authenticationService: AuthenticationService,
    private userPublicDataService: UserPublicDataService
  ) {
    this.data = combineLatest([
      this.companyDataService.dataForOwnClient$,
      this.companyCraftDataService.dataForOwnClient$,
      this.addressDataService.dataForOwnClient$,
      this.craftDataService.dataForOwnClientWithDeletedSuffix$,
    ]).pipe(
      map(([companies, companiesCrafts, addresses, crafts]) =>
        companies.map((company) => {
          const companyCrafts = companiesCrafts
            .filter((theCompanyCraft) => theCompanyCraft.companyId === company.id)
            .map(
              (theCompanyCraft) =>
                ({
                  ...theCompanyCraft,
                  craft: crafts.find((theCraft) => theCraft.id === theCompanyCraft.craftId),
                }) as CompanyCraftDetails
            );

          const address = addresses.find((theAddress) => theAddress.id === company.addressId);

          return {
            ...company,
            address,
            crafts: companyCrafts,
          } as CompanyDetails;
        })
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  getById(id: IdType) {
    return this.data.pipe(map((companies) => companies.find((company) => company.id === id)));
  }

  private async updateCrafts(company: CompanyDetails) {
    const currentCompanyCrafts = await observableToPromise(this.companyCraftDataService.getByCompanyId(company.id).pipe(take(1)));

    const compareFn = customComparators.get('companyCrafts');

    const deletedCompanyCrafts = currentCompanyCrafts.filter((companyCraft) => !company.crafts.some(compareFn.bind(undefined, companyCraft)));
    const insertedCompanyCrafts = company.crafts.filter((newCompanyCraft) => !currentCompanyCrafts.some(compareFn.bind(undefined, newCompanyCraft))).map(({craft, ...companyCraft}) => companyCraft);

    const allContactsCraftsInCompany = await observableToPromise(
      combineLatest([this.profileCraftDataService.dataForOwnClient$.pipe(take(1)), this.profileDataService.dataForOwnClientWithDefaultType$.pipe(take(1))]).pipe(
        map(([profilesCrafts, profiles]) => {
          const contactsInCompany = profiles.filter((profile) => profile.companyId === company.id);

          return profilesCrafts.filter((profileCraft) => contactsInCompany.some((contact) => contact.id === profileCraft.profileId));
        })
      )
    );

    const deletedProfileCrafts = allContactsCraftsInCompany.filter((c) => deletedCompanyCrafts.some((d) => d.craftId === c.craftId));

    insertedCompanyCrafts.forEach((companyCraft) => {
      if (!companyCraft.id) {
        companyCraft.id = companyCraft.companyId + companyCraft.craftId;
      }
      if (!companyCraft.changedAt) {
        companyCraft.changedAt = new Date().toISOString();
      }
    });
    await this.companyCraftDataService.insert(insertedCompanyCrafts, company.clientId);
    await this.companyCraftDataService.delete(deletedCompanyCrafts, company.clientId);
    await this.profileCraftDataService.delete(deletedProfileCrafts, company.clientId);
  }

  private async updateAddress(company: CompanyDetails) {
    if (company.address) {
      const result = await this.addressDataService.insertOrUpdate(company.address, company.clientId);
      if (result[0].id !== company.addressId) {
        company.addressId = result[0].id;
      }
    }
  }

  async updateDetailed(company: CompanyDetails) {
    await this.updateCrafts(company);
    await this.updateAddress(company);
    const {address, crafts, ...rawCompany} = company;

    await this.companyDataService.update(rawCompany, rawCompany.clientId);
  }

  async insertDetailed(company: CompanyDetails): Promise<Company> {
    const {address, crafts, ...rawCompany} = company;
    if (!rawCompany.changedAt) {
      rawCompany.changedAt = new Date().toISOString();
    }
    if (rawCompany.isActive === undefined) {
      rawCompany.isActive = true;
    }
    const createdAddress: Address = _.head(await this.addressDataService.insert(address, rawCompany.clientId));
    rawCompany.addressId = createdAddress.id;
    const createdCompany: Company = _.head(await this.companyDataService.insert(rawCompany, rawCompany.clientId));
    await this.insertNewCrafts(crafts, createdCompany.id, rawCompany.clientId);

    return createdCompany;
  }

  private async insertNewCrafts(crafts, companyId: IdType, clientId: IdType) {
    const tobeInsertedCrafts: CompanyCraft[] = [];
    for (const craft of crafts) {
      tobeInsertedCrafts.push({
        id: companyId + craft.craftId,
        craftId: craft.craftId,
        changedAt: new Date().toISOString(),
        companyId,
      });
    }
    await this.companyCraftDataService.insert(tobeInsertedCrafts, clientId);
  }

  public isCompanyAssignedToOpenEntry(company: Company): Observable<boolean> {
    return combineLatest([this.protocolEntryDataService.dataByProjectId$, this.protocolDataService.dataWithoutHiddenByProjectId$]).pipe(
      map(([allEntriesMap, allProtocolsMap]) => {
        const entries: ProtocolEntry[] = _.flatten(Array.from(allEntriesMap.values())).filter((entry) => entry.companyId === company.id);
        const nonClosedProtocols: Protocol[] = _.flatten(Array.from(allProtocolsMap.values())).filter((protocol) => !protocol.closedAt);
        for (const entry of entries) {
          const isAssigned = nonClosedProtocols.some((protocol) => protocol.id === entry.protocolId);
          if (isAssigned) {
            return true;
          }
        }
        return false;
      })
    );
  }

  public async deleteCompany(company: Company) {
    company.isActive = false;
    const allProfiles = await observableToPromise(this.profileDataService.dataForOwnClientWithDefaultType$);
    const profilesByCompany = allProfiles.filter((profile) => profile.companyId === company.id);
    await this.contactService.deleteContacts(profilesByCompany);
    await this.companyDataService.update(company, company.clientId);
  }

  public getOwnCompany$(): Observable<Company | undefined> {
    return combineLatestAsync([this.authenticationService.authenticatedUserId$, this.userPublicDataService.data, this.companyDataService.data, this.profileDataService.dataWithDefaultType$]).pipe(
      map(([authenticatedUserId, userPublics, companies, profiles]) => {
        const currentUserPublic = userPublics.find((user) => user.id === authenticatedUserId);
        const currentProfile = profiles.find((profile) => profile.id === currentUserPublic?.profileId);
        return companies.find((company) => company.id === currentProfile?.companyId);
      })
    );
  }
}
