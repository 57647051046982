import {Pipe, PipeTransform} from '@angular/core';
import {Company} from 'submodules/baumaster-v2-common';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'companyNameString'})
export class CompanyNameString implements PipeTransform {
  constructor(private translationService: TranslateService) {}

  transform(company: Company | undefined, isRemoved?: boolean): string {
    if (!company) {
      return '';
    }
    let companyNameStr = '';
    if (company.name) {
      companyNameStr = company.name;
    }

    if (company.isActive !== undefined && company.isActive === false) {
      companyNameStr = companyNameStr + ' ' + this.translationService.instant('deleted');
    } else if (isRemoved === true) {
      companyNameStr = companyNameStr + ' ' + this.translationService.instant('removed_suffix');
    }
    return companyNameStr;
  }
}
