import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {StorageQuotaLevelEnum, SystemInfoService} from '../../services/common/system-info.service';

@Component({
  selector: 'app-storage-quota-notification-header',
  templateUrl: './storage-quota-notification-header.component.html',
  styleUrls: ['./storage-quota-notification-header.component.scss'],
})
export class StorageQuotaNotificationHeaderComponent implements OnInit {
  isStorageQuotaLevelWarningRelevantForDevice: boolean;
  storageQuotaLevel$: Observable<StorageQuotaLevelEnum | undefined> | undefined;
  StorageQuotaLevelEnum = StorageQuotaLevelEnum;

  constructor(private systemInfoService: SystemInfoService) {}

  ngOnInit() {
    this.isStorageQuotaLevelWarningRelevantForDevice = this.systemInfoService.isStorageQuotaLevelWarningRelevantForDevice();
    if (this.isStorageQuotaLevelWarningRelevantForDevice) {
      this.storageQuotaLevel$ = this.systemInfoService.storageQuotaLevel$;
    }
  }

  redirectHowToFreeStorage() {
    this.systemInfoService.redirectHowToFreeStorage();
  }
}
