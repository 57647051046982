import {Component, Input} from '@angular/core';
import {AppUpdateInfo} from '@capawesome/capacitor-app-update';

const UPDATE_INFO_URL = 'https://bmst.link/updateinfo';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
})
export class UpdateModalComponent {
  @Input() isPwa: boolean;
  @Input() updateInfo: AppUpdateInfo;

  private modal: HTMLIonModalElement;

  constructor() {}

  async close() {
    await this.modal.dismiss();
  }

  openUpdateInfo() {
    window.open(UPDATE_INFO_URL);
  }

  startUpdateOrOpenStore() {
    this.modal.dismiss(undefined, 'update');
  }
}
