import {CommonModule} from '@angular/common';
import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule, CommonModule],
})
export class NotificationCardComponent {
  @HostBinding('class.seen')
  @Input()
  seen: boolean;

  @Input()
  date: string | Date;

  @HostBinding('class.has-project')
  @Input()
  project: string | undefined;

  @Input()
  icon?: [string, string];

  @Input()
  iconClasses?: string | string[];

  @HostBinding('class.unseen')
  protected get unseen() {
    return !this.seen;
  }

  @Output()
  statusClick = new EventEmitter<MouseEvent>();

  constructor() {}
}
