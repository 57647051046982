import {ColumnConfig} from '../ColumnConfig';

export const ParticipantColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'invited'},
  {name: 'mailing_list', prop: 'mailingList'},
  {name: 'present'},
  {name: 'present_from', prop: 'presentFrom', cast: 'Time', def: null},
  {name: 'present_to', prop: 'presentTo', cast: 'Time', def: null},
  {name: 'profile_id', prop: 'profileId'},
  {name: 'protocol_id', prop: 'protocolId', def: null},
  {name: 'seen_at', prop: 'seenAt', cast: 'Timestamp', def: null},
  {name: 'pdfpreview_id', prop: 'pdfpreviewId', def: null},
  {name: 'report_id', prop: 'reportId', def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
// If a new reference (similar to protocol_id or report_id) is being added, make sure that GlobalSearch is still working
// (e.g. changing scripts like src/db/sql/participant/findForGlobalSearch.sql)
