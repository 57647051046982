import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, tap, timeout} from 'rxjs/operators';
import {DEFAULT_FETCH_TIMEOUT_IN_MS, DEFAULT_PDF_GENERATION_TIMEOUT_IN_MS} from '../../utils/fetch-utils';
import {NetworkError, OfflineError} from '../../shared/errors';
import {NetworkStatusService} from '../common/network-status.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class TimeoutHttpInterceptor implements HttpInterceptor, OnDestroy {
  static readonly PDF_GENERATION_URLS = [
    /api\/pdf\/globalSearch\/send/,
    /api\/pdf\/protocol\/[a-zA-Z0-9\-]+\/close/,
    /api\/pdf\/protocol\/[a-zA-Z0-9\-]+\/send/,
    /api\/pdf\/report\/[a-zA-Z0-9\-]+\/send/,
    /autodesk\/model/,
  ];
  private onlineSubscription: Subscription;
  private online: boolean | undefined;
  constructor(private networkStatusService: NetworkStatusService) {
    this.onlineSubscription = this.networkStatusService.online$.subscribe((online) => (this.online = online));
  }

  ngOnDestroy(): void {
    this.onlineSubscription?.unsubscribe();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = TimeoutHttpInterceptor.PDF_GENERATION_URLS.some((regex) => regex.test(request.url)) ? DEFAULT_PDF_GENERATION_TIMEOUT_IN_MS : DEFAULT_FETCH_TIMEOUT_IN_MS;
    return next
      .handle(request)
      .pipe(timeout(timeoutValue))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.error instanceof Error || error?.statusText === 'Unknown Error') {
            // A client-side or network error occurred. Handle it accordingly.
            this.networkStatusService.markFailedServerRequest();
            const errorInstance = this.online === false ? new OfflineError((error.error as Error).message) : new NetworkError((error.error as Error).message);
            return throwError(errorInstance);
          }
          return throwError(error);
        })
      )
      .pipe(
        tap((v: HttpEvent<any>) => {
          // Also calls to localhost (e.g. assets/de.json) are being handled here. Make sure to only handle calls to the backend.
          if (v && v instanceof HttpResponse && v.url?.startsWith(environment.serverUrl)) {
            if (v.status !== 504) {
              this.networkStatusService.markSuccessfulServerRequest();
            }
          }
        })
      );
  }
}
