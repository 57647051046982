import {IdType, Unit, UnitForBreadcrumbs} from './models';
import {CLIENT_IDS_UNIT_FEATURE_ENABLED, UNIT_NAME_BREADCRUMBS_SEPARATOR} from './constants';
import _ from 'lodash';

const MAX_ITERATIONS_TO_AVOID_ENDLESS_LOOP = 1000;

function addChildUnitsRecursive(parentUnit: UnitForBreadcrumbs, unitsByParentId: Record<IdType, Unit[]>, unitsWithParents: Array<UnitForBreadcrumbs>, iteration: number): Array<UnitForBreadcrumbs> {
  if (iteration > MAX_ITERATIONS_TO_AVOID_ENDLESS_LOOP) {
    console.error(`unitUtils.addChildUnitsRecursive - Stopped processing at parentUnit ${parentUnit?.id} after ${iteration} iterations.`);
    return unitsWithParents;
  }
  const childUnits = unitsByParentId[parentUnit.id] ?? [];
  if (!childUnits.length) {
    return unitsWithParents;
  }
  for (const childUnit of childUnits) {
    const childUnitWithParent: UnitForBreadcrumbs = {
      ...childUnit,
      orderNumber: 0, // proper value will be filled at the end of unitsToUnitForBreadcrumbs
      parents: [...parentUnit.parents, parentUnit],
      breadcrumbsName: parentUnit.breadcrumbsName + UNIT_NAME_BREADCRUMBS_SEPARATOR + childUnit.name,
    };
    unitsWithParents.push(childUnitWithParent);
    addChildUnitsRecursive(childUnitWithParent, unitsByParentId, unitsWithParents, iteration + 1);
  }
  return unitsWithParents;
}

export function unitsToUnitForBreadcrumbs(units: Array<Unit>): Array<UnitForBreadcrumbs> {
  const unitsWithParents = new Array<UnitForBreadcrumbs>();

  const rootUnits = units.filter((unit) => !unit.parentId);
  const unitsByParentId = _.groupBy(
    units.filter((unit) => unit.parentId),
    'parentId'
  );
  for (const rootUnit of rootUnits) {
    const rootUnitWithParents: UnitForBreadcrumbs = {
      ...rootUnit,
      parents: [],
      orderNumber: 0, // proper value will be filled at the end
      breadcrumbsName: rootUnit.name,
    };
    unitsWithParents.push(rootUnitWithParents);
    addChildUnitsRecursive(rootUnitWithParents, unitsByParentId, unitsWithParents, 0);
  }
  return unitsWithParents.map((unit, index) => ({...unit, orderNumber: index}));
}

export function isUnitFeatureEnabledForClient(clientId: IdType): boolean {
  return CLIENT_IDS_UNIT_FEATURE_ENABLED.includes(clientId);
}

export function isUnitImportEnabledForClient(clientId: IdType): boolean {
  return CLIENT_IDS_UNIT_FEATURE_ENABLED.includes(clientId);
}
