import {Injectable} from '@angular/core';
import {IonicSafeString, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ToastDurationInMs} from 'src/app/shared/constants';

type ToastOptions = Parameters<ToastController['create']>[0];

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  async toast(stringToTranslate: string, duration: number, opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate),
      duration,
      buttons: [
        {
          text: this.translateService.instant('close'),
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  async toastWithTranslateParams(stringToTranslate: string, params: any, duration: number, opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate, params),
      duration,
      buttons: [
        {
          text: this.translateService.instant('close'),
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  async toastWithTranslateParamsAndButton(stringToTranslate: string, params: any, duration: number, buttons: ToastOptions['buttons'], opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate, params),
      buttons,
      duration,
    });

    await toast.present();
  }

  async info(stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO, opts);
  }

  async infoWithMessage(stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO_WITH_MESSAGE, opts);
  }

  async infoWithMessageAndHeader(headerToTranslate: string, stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.INFO_WITH_MESSAGE, {
      header: this.translateService.instant(headerToTranslate),
      ...(opts ?? {}),
    });
  }

  async infoWithMessageAndButtons(stringToTranslate: string, buttons: ToastOptions['buttons'], opts?: ToastOptions, optsOverride?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      message: this.translateService.instant(stringToTranslate),
      buttons,
      duration: ToastDurationInMs.INFO_WITH_MESSAGE,
      ...optsOverride,
    });

    await toast.present();
  }

  async error(stringToTranslate: string, opts?: ToastOptions) {
    await this.toast(stringToTranslate, ToastDurationInMs.ERROR, opts);
  }

  async errorWithMessageAndHeader(stringToTranslate: string, errorMessage: IonicSafeString | string, opts?: ToastOptions) {
    const toast = await this.toastController.create({
      ...opts,
      header: this.translateService.instant(stringToTranslate),
      message: errorMessage,
      duration: ToastDurationInMs.ERROR,
      buttons: [
        {
          text: this.translateService.instant('close'),
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  async savingSuccess(title?: string) {
    if (title) {
      await this.toastWithTranslateParams('saving_with_title_success', {title}, ToastDurationInMs.INFO);
    } else {
      await this.info('saving_success');
    }
  }

  async savingError() {
    await this.error('error_saving_message');
  }
}
