import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PdfPlanHoldersActionsService} from 'src/app/services/project-room/pdf-plan-holders-actions.service';
import {IdType, LicenseType, MIME_TYPE_PDF, MIME_TYPES_CAD, PdfPlan, PdfPlanAttachment, PdfPlanFolder, PdfPlanPage, PdfPlanVersion, PdfPlanVersionQualityEnum} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../../../services/common/logging.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {ToastService} from '../../../services/common/toast.service';
import {PdfPlanFolderDataService} from '../../../services/data/pdf-plan-folder-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {observableToPromise} from '../../../utils/async-utils';
import {BlobFilenameBundle, MIME_TYPES_PDF_PLAN} from '../../../utils/attachment-utils';
import _ from 'lodash';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {AlertService} from '../../../services/ui/alert.service';

const LOG_SOURCE = 'UploadPdfPlanWorkflowComponent';

interface UploadPdfPlanWorkflowStep {
  key: 'FILE_SELECT' | 'FOLDER';
  titleTranslationKey: string;
}

export const WORKFLOW_STEPS: UploadPdfPlanWorkflowStep[] = [
  {key: 'FILE_SELECT', titleTranslationKey: 'project_room.upload_pdf_plan_version.step.FILE_SELECT.title'},
  {key: 'FOLDER', titleTranslationKey: 'project_room.upload_pdf_plan_version.step.FOLDER.title'},
];

@Component({
  selector: 'app-upload-pdf-plan-workflow',
  templateUrl: './upload-pdf-plan-workflow.component.html',
  styleUrls: ['./upload-pdf-plan-workflow.component.scss'],
})
export class UploadPdfPlanWorkflowComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  private destroy$ = new Subject<void>();
  readonly workflowSteps = WORKFLOW_STEPS;
  readonly maximumPdfPlans = 10;
  readonly pdfPlanVersionQualities = _.orderBy(Object.values(PdfPlanVersionQualityEnum).filter(_.isNumber));
  acceptedMimeTypes = MIME_TYPES_PDF_PLAN;
  cadFilesEnabled$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.PROFESSIONAL]);
  pdfPlanVersionQuality = PdfPlanVersionQualityEnum.DEFAULT;
  @Input()
  pdfPlanFolderId: IdType | undefined;
  currentStepIndex = 0;
  currentStep: UploadPdfPlanWorkflowStep = this.workflowSteps[0];
  processingUploadedFile = false;
  files = new Array<File>();
  processing = false;

  pdfPlanDetails: {
    file: File;
    pdfPlan: PdfPlan;
    pdfPlanAttachment: PdfPlanAttachment;
    pdfPlanCadAttachments: Array<PdfPlanAttachment>;
    pdfPlanVersion: PdfPlanVersion;
    pdfPlanPages: PdfPlanPage[];
    pdfBlob: Blob;
    imageBlobs: Blob[];
    cadBlobs: Blob[];
  }[] = [];
  isNetworkConnected$: Observable<boolean> | undefined = this.networkStatusService.onlineOrUnknown$;
  private processFileAbortController: AbortController | undefined;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private pdfPlanService: PdfPlanService,
    private toastService: ToastService,
    private alertService: AlertService,
    private pdfPlanFolderDataService: PdfPlanFolderDataService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private networkStatusService: NetworkStatusService,
    private pdfPlanHoldersActionsService: PdfPlanHoldersActionsService,
    private router: Router,
    private featureEnabledService: FeatureEnabledService
  ) {}

  ngOnInit() {
    this.currentStepIndex = 0;
    this.currentStep = this.workflowSteps[0];
    this.cadFilesEnabled$.pipe(takeUntil(this.destroy$)).subscribe((cadFilesEnabled) => {
      this.acceptedMimeTypes = cadFilesEnabled ? MIME_TYPES_PDF_PLAN.concat(MIME_TYPES_CAD) : MIME_TYPES_PDF_PLAN;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async dismissModal() {
    await this.modal.dismiss();
  }

  async back() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.currentStep = this.workflowSteps[this.currentStepIndex];
    }
  }

  async next() {
    if (!(await this.postProcessStep(this.currentStep))) {
      return;
    }
    if (this.currentStepIndex < this.workflowSteps.length - 1) {
      this.currentStepIndex++;
      this.currentStep = this.workflowSteps[this.currentStepIndex];
      await this.preProcessStep(this.currentStep);
    }
  }

  async finish() {
    try {
      this.processing = true;
      if (!this.pdfPlanDetails.length) {
        this.loggingService.warn(LOG_SOURCE, 'finish - Nothing to save - pdfPlanDetails is empty.');
        return;
      }
      if (!this.pdfPlanFolderId) {
        throw new Error('finish - No PdfPlanFolder selected (pdfPlanFolderId undefined).');
      }
      this.pdfPlanDetails.forEach(({pdfPlan}) => {
        pdfPlan.folderId = this.pdfPlanFolderId;
      });
      for (const {pdfPlan, pdfPlanAttachment, pdfPlanCadAttachments, pdfPlanVersion, pdfPlanPages, pdfBlob, imageBlobs, cadBlobs} of this.pdfPlanDetails) {
        await this.pdfPlanService.finishUploadingPdfPlanVersion(
          of(undefined),
          undefined,
          undefined,
          pdfPlan,
          pdfPlanAttachment,
          pdfPlanCadAttachments,
          pdfPlanVersion,
          pdfPlanPages,
          pdfBlob,
          imageBlobs,
          cadBlobs,
          undefined,
          undefined,
          undefined,
          undefined
        );
      }
      await this.router.navigate(['project-room', 'pdf-plan-folders', this.pdfPlanFolderId]);
      this.pdfPlanHoldersActionsService.enterEditMode(this.pdfPlanDetails.map(({pdfPlan}) => pdfPlan));
      await this.dismissModal();
    } catch (error) {
      const errorMessage = `finish - inserting files failed with error ${convertErrorToMessage(error)}`;
      this.systemEventService.logErrorEvent(LOG_SOURCE, errorMessage);
      this.loggingService.error(LOG_SOURCE, errorMessage);
      this.toastService.error(this.translateService.instant('project_room.upload_pdf_plan_version.step.SUMMARY.errorSaving', {errorMessage: convertErrorToMessage(error)}));
    } finally {
      this.processing = false;
    }
  }

  public filesChange(files: Array<File>) {
    if (files.length > this.maximumPdfPlans) {
      this.loggingService.error(LOG_SOURCE, 'filesChange - file limit reached');
      return;
    }
    this.files = this.validateFiles(files).files;
    this.handleFilesChange();
  }

  private validateFiles(files: Array<File>): {valid: boolean; files: Array<File>} {
    const filesToRemove = new Array<File>();

    let errorMessageKey: string | undefined;
    let pdfCount = 0;
    let cadCount = 0;
    for (const file of files) {
      const isPdfFile = file.type === MIME_TYPE_PDF;
      const isCadFile = MIME_TYPES_CAD.includes(file.type);
      const isOtherFile = !isPdfFile && !isCadFile;
      if (isOtherFile) {
        errorMessageKey = 'project_room.upload_pdf_plan_version.step.FILE_SELECT.invalidFilesRemoved';
        filesToRemove.push(file);
      } else if (isPdfFile) {
        if (pdfCount > 0 && cadCount > 0) {
          errorMessageKey = 'project_room.upload_pdf_plan_version.step.FILE_SELECT.additionalPdfRemoved';
          filesToRemove.push(file);
        } else {
          pdfCount++;
        }
      } else if (isCadFile) {
        if (pdfCount > 1) {
          errorMessageKey = 'project_room.upload_pdf_plan_version.step.FILE_SELECT.additionalCadRemoved';
          filesToRemove.push(file);
        } else {
          cadCount++;
        }
      }
    }

    if (!filesToRemove.length) {
      return {valid: true, files};
    }
    const fileNamesToRemove = filesToRemove.map((file) => file.name).join(',');
    this.alertService.ok({message: this.translateService.instant(errorMessageKey, {fileNames: fileNamesToRemove})});
    return {valid: false, files: files.filter((file) => !filesToRemove.includes(file))};
  }

  private async handleFilesChange() {
    const pdfFiles = this.files.filter((file) => file.type === MIME_TYPE_PDF);
    const cadFiles = this.files.filter((file) => file.type !== MIME_TYPE_PDF);
    const cadFilesBundle: Array<BlobFilenameBundle> = cadFiles.map((file) => {
      return {blob: file, fileName: file.name};
    });
    if (pdfFiles.length > 1 && cadFilesBundle.length) {
      throw new Error(`Found ${pdfFiles.length} PDF files but only one is allowed when uploaded together with cad files (counting ${cadFilesBundle.length}).`);
    }
    if (pdfFiles.length) {
      try {
        this.processingUploadedFile = true;
        this.processing = true;
        this.processFileAbortController = new AbortController();

        const pdfPlanFolder = this.pdfPlanFolderId ? await observableToPromise(this.pdfPlanFolderDataService.getById(this.pdfPlanFolderId)) : undefined;
        this.pdfPlanDetails = this.pdfPlanDetails.filter((pdfPlanDetail) => pdfFiles.includes(pdfPlanDetail.file));
        for (const file of pdfFiles) {
          if (this.pdfPlanDetails.find((pdfPlanDetail) => pdfPlanDetail.file === file)) {
            continue; // no need to process again.
          }
          await this.processFile(file, pdfPlanFolder, cadFilesBundle, this.pdfPlanVersionQuality, this.processFileAbortController.signal);
        }
      } catch (error) {
        const errorMessage = `Processing file(s) failed with error ${convertErrorToMessage(error)}`;
        this.systemEventService.logErrorEvent(LOG_SOURCE, errorMessage);
        this.loggingService.error(LOG_SOURCE, errorMessage);
        this.toastService.error(this.translateService.instant('project_room.upload_pdf_plan_version.step.FILE_SELECT.errorProcessingFile', {errorMessage: convertErrorToMessage(error)}));
        this.files = this.files.filter((file) => cadFiles.includes(file) || this.pdfPlanDetails.some((pdfPlanDetail) => pdfPlanDetail.file === file));
      } finally {
        this.processingUploadedFile = false;
        this.processing = false;
        this.processFileAbortController = undefined;
      }
    } else {
      this.clearUploadedPdfPlanObjects();
    }
  }

  private async processFile(file: File, pdfPlanFolder: PdfPlanFolder, cadFilesBundle: Array<BlobFilenameBundle>, pdfPlanVersionQuality: PdfPlanVersionQualityEnum, abortSignal?: AbortSignal) {
    try {
      const {pdfPlanAttachment, pdfPlanCadAttachments, pdfPlan, pdfPlanVersion, pdfPlanPages, pdfBlob, imageBlobs, cadBlobs} = await this.pdfPlanService.upload(
        pdfPlanFolder,
        {blob: file, fileName: file.name},
        cadFilesBundle,
        new Date(),
        undefined,
        false,
        pdfPlanVersionQuality,
        abortSignal
      );

      this.pdfPlanDetails.push({
        file,
        imageBlobs,
        pdfBlob,
        pdfPlanPages,
        pdfPlanVersion,
        pdfPlanAttachment,
        pdfPlanCadAttachments,
        pdfPlan,
        cadBlobs,
      });
    } catch (error) {
      if (pdfPlanVersionQuality === this.pdfPlanVersionQualities[0]) {
        throw error;
      }
      const qualityIndex = this.pdfPlanVersionQualities.findIndex((q) => q === pdfPlanVersionQuality);
      const nextLowerQuality = qualityIndex > 0 ? this.pdfPlanVersionQualities[qualityIndex - 1] : undefined;
      if (!nextLowerQuality) {
        throw new Error('nextLowerQuality not found (should not happen).');
      }
      await this.processFile(file, pdfPlanFolder, cadFilesBundle, nextLowerQuality, abortSignal);
    }
  }

  private clearUploadedPdfPlanObjects() {
    this.pdfPlanDetails = [];
  }

  public isCurrentStepValid(): boolean {
    return this.isStepValid(this.currentStep);
  }

  public isAllStepsValid(): boolean {
    for (const step of this.workflowSteps) {
      if (!this.isStepValid(step)) {
        return false;
      }
    }
    return true;
  }

  private async preProcessStep(step: UploadPdfPlanWorkflowStep): Promise<boolean> {
    switch (step.key) {
      case 'FILE_SELECT':
        return true;
      case 'FOLDER':
        return true;
      default:
        throw new Error(`processStep - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  private async postProcessStep(step: UploadPdfPlanWorkflowStep): Promise<boolean> {
    switch (step.key) {
      case 'FILE_SELECT':
        return true;
      default:
        throw new Error(`processStep - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  private isStepValid(step: UploadPdfPlanWorkflowStep): boolean {
    switch (step.key) {
      case 'FILE_SELECT':
        return this.networkStatusService.onlineOrUnknown !== false && this.pdfPlanDetails.length && !this.processingUploadedFile;
      case 'FOLDER':
        return this.networkStatusService.onlineOrUnknown !== false && Boolean(this.pdfPlanFolderId);
      default:
        throw new Error(`isStepValid - currentStep with key "${this.currentStep.key}" is not supported.`);
    }
  }

  public qualityForFile(file: File): PdfPlanVersionQualityEnum | undefined {
    const pdfPlanDetail = this.pdfPlanDetails.find((value) => value.file === file);
    return pdfPlanDetail?.pdfPlanVersion?.quality;
  }

  public isOneWithOtherQuality(): boolean {
    return this.pdfPlanDetails.some((pdfPlanDetail) => pdfPlanDetail?.pdfPlanVersion?.quality && pdfPlanDetail.pdfPlanVersion.quality !== this.pdfPlanVersionQuality);
  }

  public abortProcessingFile() {
    this.processFileAbortController?.abort();
  }

  changeQuality(event: PdfPlanVersionQualityEnum) {
    this.pdfPlanVersionQuality = event;
  }
}
