const PLAN_ITEM_DEFAULT_HEIGHT = 56;
const PLAN_ITEM_EXPANDED_EXTRA_HEIGHT = 54; // that area for "upload new plan version"
const PLAN_ITEM_MARGIN = 16;
const PLAN_ITEM_MD_HEIGHT = 113;

export function getPlanItemExpandedExtraHeight(versionsCount: number): number {
  return PLAN_ITEM_EXPANDED_EXTRA_HEIGHT + (versionsCount - 1) * PLAN_ITEM_DEFAULT_HEIGHT;
}

export const PLAN_ITEM_HEIGHT = PLAN_ITEM_DEFAULT_HEIGHT + PLAN_ITEM_MARGIN;

export const PLAN_ITEM_HEIGHT_MD = PLAN_ITEM_MD_HEIGHT + PLAN_ITEM_MARGIN;
