import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';

@Component({
  selector: 'app-no-items',
  templateUrl: './no-items.component.html',
  styleUrls: ['./no-items.component.scss'],
  standalone: true,
  imports: [IonicModule, FontAwesomeModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoItemsComponent {
  @Input()
  ctaLabel: string;

  @Input()
  ctaClass = 'omg-btn-primary';

  @Input()
  label: string;

  @Output()
  ctaClick = new EventEmitter<void>();
}
