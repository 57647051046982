import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ModalController, NavController, ViewDidEnter, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import {of, Subscription} from 'rxjs';
import {debounceTime, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {ProtocolCreateComponent} from 'src/app/components/protocol/protocol-create/protocol-create.component';
import {ProtocolListComponent} from 'src/app/components/protocol/protocol-list/protocol-list.component';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {ProtocolFilterService} from 'src/app/services/protocol/protocol-filter.service';
import {ProtocolListService} from 'src/app/services/protocol/protocol-list.service';
import {SelectedProtocolService} from 'src/app/services/protocol/selected-protocol.service';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';
import {Breakpoints, DeviceService, DISPLAY_SIZE, QUERY} from 'src/app/services/ui/device.service';
import {PINNED_MENU_WIDTH} from 'src/app/shared/constants';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {IdType, Project} from 'submodules/baumaster-v2-common';

export const WIDTH_ENOUGH_SPACE_FOUR_COLUMN_PINNED = DISPLAY_SIZE[Breakpoints.xxl] + PINNED_MENU_WIDTH;

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.page.html',
  styleUrls: ['./protocols.page.scss'],
  providers: [PageDidEnterLifecycleService],
})
export class ProtocolsPage implements ViewWillEnter, ViewWillLeave, ViewDidEnter, OnInit {
  @ViewChild(ProtocolListComponent, {
    static: false,
  })
  protocolList: ProtocolListComponent;

  // To programmatically turn off 4 column layout, use value '(max-width: 0px)'
  readonly fourColumnLayoutBreakpoint = QUERY[Breakpoints.xxl];

  private project: Project | null = null;

  private currentUrl$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(this.router.url),
    map(() => this.router.url)
  );

  isInEntriesView$ = this.currentUrl$.pipe(map((url) => url.includes('/view')));
  private isFourColumnWithNotEnoughSpace$ = this.deviceService.matchesMediaQuery(`(min-width: ${DISPLAY_SIZE[Breakpoints.xxl]}px) and (max-width: ${WIDTH_ENOUGH_SPACE_FOUR_COLUMN_PINNED - 1}px)`);
  private isFourColumn$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl);
  isFourColumnWithNotEnoughSpaceAndPinnedSideBar$ = combineLatestAsync([this.sideBarSettingsService.sideBarSetting$, this.isFourColumnWithNotEnoughSpace$]).pipe(
    map(([sideBarSetting, isFourColumnWithNotEnoughSpace]) => {
      return sideBarSetting.pinned && isFourColumnWithNotEnoughSpace;
    })
  );
  showBackButton$ = combineLatestAsync([this.isInEntriesView$, this.isFourColumn$, this.isFourColumnWithNotEnoughSpaceAndPinnedSideBar$]).pipe(
    map(([isInEntriesView, isFourColumn, isFourColumnWithNotEnoughSpaceAndPinnedSideBar]) => {
      return (isInEntriesView && !isFourColumn) || (isInEntriesView && isFourColumnWithNotEnoughSpaceAndPinnedSideBar);
    })
  );
  showRouterOutletFab$ = combineLatestAsync([this.isInEntriesView$, this.isFourColumn$, this.isFourColumnWithNotEnoughSpaceAndPinnedSideBar$]).pipe(
    map(([isInEntryView, isFourColumn, isFourColumnWithNotEnoughSpaceAndPinnedSideBar]) => (!isInEntryView && !isFourColumn) || (!isInEntryView && isFourColumnWithNotEnoughSpaceAndPinnedSideBar))
  );

  showFullSizeCreateButton$ = this.deviceService.isAboveBreakpoint(Breakpoints.lg);
  backButtonPage$ = combineLatestAsync([this.deviceService.isAboveBreakpoint(Breakpoints.lg), this.isFourColumnWithNotEnoughSpaceAndPinnedSideBar$]).pipe(
    switchMap(([hasSplitScreenInEntryView, hasSplitScreenInEntryViewAndPinnedSideBar]) => {
      if (hasSplitScreenInEntryView || hasSplitScreenInEntryViewAndPinnedSideBar) {
        return of('/protocols');
      }

      return this.currentUrl$.pipe(map((url) => (url.includes('/entry') ? url.substr(0, url.indexOf('/entry')) : '/protocols')));
    })
  );

  private redirectToFirstProtocolSubscription?: Subscription;

  constructor(
    public modalController: ModalController,
    private router: Router,
    private deviceService: DeviceService,
    private protocolFilterService: ProtocolFilterService,
    private protocolListService: ProtocolListService,
    private projectDataService: ProjectDataService,
    private selectedProtocolService: SelectedProtocolService,
    private navController: NavController,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService,
    private sideBarSettingsService: SideBarSettingsService,
    private protocolTypeDataService: ProtocolTypeDataService
  ) {}

  ngOnInit() {}

  private subscribeToRedirectToFirstProtocol() {
    this.unsubscribeFromRedirectToFirstProtocol();
    this.redirectToFirstProtocolSubscription = combineLatestAsync([
      this.selectedProtocolService.getCurrentProtocol(),
      this.protocolListService.filteredProtocols$,
      this.deviceService.isAboveBreakpoint(Breakpoints.xxl),
      this.isFourColumnWithNotEnoughSpaceAndPinnedSideBar$,
      this.projectDataService.currentProjectObservable.pipe(
        tap((project) => {
          if (!this.project) {
            this.project = project;
          }
        })
      ),
    ])
      .pipe(
        filter(([activeProtocol, protocols, isFourColumn, isFourColumnWithNotEnoughSpaceAndPinnedSideBar, currentProject]) => {
          // Automatically redirect to the first entry only in four column layout
          if (!isFourColumn || isFourColumnWithNotEnoughSpaceAndPinnedSideBar) {
            return false;
          }

          // Redirect to the first entry when navigation from empty protocol type
          // or/and from empty project
          if (!this.router.url.includes('/entry') && protocols.length > 0) {
            return true;
          }

          // Assuming no action is needed if active protocol is null
          if (!activeProtocol) {
            return false;
          }

          if (this.project && this.project.id !== currentProject.id) {
            this.project = currentProject;
            // We're in the middle of current project change;
            // go to first protocol, if active protocol is not a part of new project
            return !protocols.some(({id}) => activeProtocol.id === id);
          }

          // No protocols found in the selected protocol type; redirect to empty page
          if (protocols.length === 0) {
            return true;
          }

          // Redirect to the first entry only if currently selected entry is not
          // present in current protocols list
          return !protocols.some(({id}) => activeProtocol.id === id);
        })
      )
      .subscribe(() => this.navigateToFirstProtocol());
  }

  private unsubscribeFromRedirectToFirstProtocol() {
    this.redirectToFirstProtocolSubscription?.unsubscribe();
  }

  async ionViewWillEnter() {
    this.subscribeToRedirectToFirstProtocol();
    const currentProtocol = await observableToPromise(this.selectedProtocolService.getCurrentProtocol());
    if (currentProtocol && !this.protocolFilterService.selectedProtocolType) {
      const currentProtocolType = await observableToPromise(this.protocolTypeDataService.getById(currentProtocol.typeId));
      this.protocolFilterService.selectedProtocolType = currentProtocolType;
    }
  }

  ionViewDidEnter() {
    this.pageDidEnterLifecycleService.pageDidEnter();
    this.protocolList?.scrollOnEnter();
  }

  ionViewWillLeave() {
    this.unsubscribeFromRedirectToFirstProtocol();
  }

  async addNewProtocol() {
    const modal = await this.modalController.create({
      component: ProtocolCreateComponent,
      keyboardClose: false,
      cssClass: 'protocol-entry-modal',
      componentProps: {
        protocolType: this.protocolFilterService.selectedProtocolType,
      },
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    await this.gotToProtocolEntryList(data?.id);
  }

  async gotToProtocolEntryList(protocolId: IdType | undefined) {
    if (typeof protocolId !== 'undefined') {
      await this.router.navigate(getProtocolEntryPagePath(protocolId, 'first'));
    }
  }

  async navigateToFirstProtocol() {
    // debounceTime(0) to make sure `combineLatestAsync` completes after we try to get first protocol
    const protocol = await observableToPromise(this.protocolListService.firstProtocol$.pipe(debounceTime(0)));
    // navigateBack makes sure that we are not stacking pages of the same type. Such behavior leads to race conditions.
    if (protocol) {
      await this.navController.navigateBack(getProtocolEntryPagePath(protocol.id, 'first'));
    } else {
      await this.navController.navigateBack(['/protocols']);
    }
  }

  async onSplitPaneVisible(event: CustomEvent<{visible: boolean}>) {
    if (event.detail.visible && !this.router.url.includes('/entry') && !(await observableToPromise(this.isFourColumnWithNotEnoughSpaceAndPinnedSideBar$))) {
      await this.navigateToFirstProtocol();
    }
  }
}
