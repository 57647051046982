<app-modal-header>
  <ion-title>{{ 'project_settings.units' | translate }}</ion-title>
  @if (toolbarPortal) {
  <ng-template [cdkPortalOutlet]="toolbarPortal"></ng-template>
  } @else {
  <div class="toolbar-placeholder pt-2"></div>
  }
  <div class="unit-path mt-2 omg-attention omg-attention-warning ion-justify-content-start">
    <span class="omg-t-medium-body" [class.text-grey]="!selectedPath()">{{ selectedPath() ?? ('unit_selector.no_selection' | translate) }}</span>
  </div>
</app-modal-header>

<ion-content [scrollY]="false">
  @if (viewMode() === 'tree') {
  <app-unit-selector-tree
    [selectorUnits]="selectorUnits"
    [selectorUnitLevels]="selectorUnitLevels"
    [selectedUnitByLevel]="selectedUnitByLevel()"
    [selectorUnitsByLevelAndParent]="selectorUnitsByLevelAndParent"
    [canReset]="canReset()"
    (toolbarPortalSet)="setToolbarPortal($event)"
    (footerPortalSet)="setFooterPortal($event)"
    (unitInLevelSelect)="handleUnitInLevelSelect($event)"
    (searchClick)="viewMode.set('list')"
  ></app-unit-selector-tree>
  } @else if (viewMode() === 'list') {
    <app-unit-selector-list
      [selectorUnitsWithPathAndLevel]="selectorUnitsWithPathAndLevel"
      [selectedUnitByLevel]="selectedUnitByLevel()"
      [canReset]="canReset()"
      (unitSelect)="handleUnitSelect($event)"
      (toolbarPortalSet)="setToolbarPortal($event)"
      (footerPortalSet)="setFooterPortal($event)"
      (clearClick)="viewMode.set('tree')"
    ></app-unit-selector-list>
  }
</ion-content>

<app-modal-footer>
  <ng-template [cdkPortalOutlet]="footerPortal"></ng-template>
</app-modal-footer>
