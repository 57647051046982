<ion-button expand="block" class="omg-btn-secondary" *ngIf="layout === 'selectable'">
  <ionic-selectable #protocolTypeSelectable
    item-content
    class="text-primary"
    [placeholder]="'select_protocol_type' | translate"
    [items]="protocolTypes"
    itemValueField="id"
    [(ngModel)]="protocolType"
    (onChange)="onFilterChange($event)"
    itemTextField="name"
    searchPlaceholder="{{'search' | translate}}"
    searchFailText="{{'noItemsFound' | translate}}"
    [canSearch]="true"
    [className]="'protocol-type-selector'"
    (onOpen)="onOpen($event)"
    (onClose)="onClose($event)"
    appSelectableCommon
    #fs="appSelectableCommon"
    [shouldFocusSearchbar]="fs.isDesktop">
    <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
      <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
    </ng-template>
    <ng-template ionicSelectableValueTemplate let-value="value">
      <app-selectable-value [value]="value"></app-selectable-value>
    </ng-template>
    <ng-template ionicSelectableHeaderTemplate>
      <app-selectable-header></app-selectable-header>
    </ng-template>
    <ng-template ionicSelectableFooterTemplate>
      <app-selectable-footer></app-selectable-footer>
    </ng-template>
    <ng-template ionicSelectableItemTemplate let-item="item">
      <span class="text-wrap-one-line">{{item.name}}</span>
      <span slot="end">({{ ((groupedProtocolsCount$ | async) || {})[item.id] || 0 }})</span>
    </ng-template>
  </ionic-selectable>
</ion-button>

<ion-list *ngIf="layout === 'list'" class="inner-scroll scroll-y protocol-type-list split-pane-visible omg-hoverable">
  <ion-item (click)="onFilterChange({value: null})" class="cursor-pointer all-protocol-types" [class.active-item]="!protocolType">
    <fa-icon [icon]="['fal', 'folder']" class="pr-2"></fa-icon>
    <span class="text-wrap-one-line start-item">{{'select_protocol_type' | translate}}</span>
    <span class="end-item badge" slot="end">{{ ((groupedProtocolsCount$ | async) || {}).all }}</span>
  </ion-item>
  <ion-item *ngFor="let item of protocolTypes" (click)="onFilterChange({value: item})" class="cursor-pointer" [class.active-item]="item.id === protocolType?.id">
    <div class="protocol-type-item text-wrap-one-line">
      <div class="start-item tagline size-xs">
        <span class="tagline__abbv">{{ item.code }}</span>
        <ion-note class="tagline__type">{{ ('protocolLayoutShort.' + (((groupedProtocolLayouts$ | async) || {})[item.layoutId] || {}).name) | translate }}</ion-note>
      </div>
      <span class="text-wrap-one-line start-item protocol-type-name">{{item.name}}</span>
    </div>
    <span class="end-item badge">{{ ((groupedProtocolsCount$ | async) || {})[item.id] || 0 }}</span>
  </ion-item>
</ion-list>
