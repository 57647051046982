import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserDevice} from 'submodules/baumaster-v2-common/';
import _ from 'lodash';
import {Device} from '@capacitor/device';
import {UserDeviceService} from 'src/app/services/auth/user-device.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {Subscription} from 'rxjs';
import {DevModeService} from 'src/app/services/common/dev-mode.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';

const LOG_SOURCE = 'UserDevicesComponent';

@Component({
  selector: 'app-user-devices',
  templateUrl: './user-devices.component.html',
  styleUrls: ['./user-devices.component.scss'],
})
export class UserDevicesComponent implements OnInit, OnDestroy {
  @Input()
  protected isInSettings = false;

  @Output() selectionChanged = new EventEmitter<string>();
  @Output() inactiveChanged = new EventEmitter<string[]>();
  protected devices: UserDevice[] | undefined;
  protected activeDevices: UserDevice[] | undefined;
  protected inactiveDevices: UserDevice[] | undefined;
  protected inactiveDeviceIds: string[] | undefined;
  protected selectedDeviceId: string | undefined;
  protected currentDeviceUuid: string;
  private deviceSubscription: Subscription | undefined;
  protected devModeEnabled$ = this.devModeService.enabled$;
  protected showInactive = false;

  constructor(
    private translateService: TranslateService,
    private userDeviceService: UserDeviceService,
    private alertService: AlertService,
    private devModeService: DevModeService,
    private posthogService: PosthogService
  ) {}

  async ngOnInit() {
    this.deviceSubscription = this.userDeviceService.getDevices$().subscribe(async (devices) => {
      if (devices) {
        this.devices = devices;
        this.activeDevices = _.orderBy(
          devices.filter((device) => device.active),
          'lastDataSync',
          this.isInSettings ? 'desc' : 'asc'
        );
        this.inactiveDevices = devices.filter((device) => !device.active);
        this.inactiveDeviceIds = devices.filter((device) => !device.active).map((device) => device.deviceUuid);
        this.selectedDeviceId = this.isInSettings ? '' : this.activeDevices[0]?.deviceUuid;
        this.currentDeviceUuid = (await Device.getId()).identifier;
        this.selectionChanged.emit(this.selectedDeviceId);
        this.inactiveChanged.emit(this.inactiveDeviceIds);
      }
    });
  }

  ngOnDestroy(): void {
    this.deviceSubscription?.unsubscribe();
    this.deviceSubscription = undefined;
  }

  changeSelection(device: UserDevice) {
    this.selectedDeviceId = this.isInSettings ? '' : device.deviceUuid;
    this.selectionChanged.emit(this.selectedDeviceId);
  }

  changeShowInactive() {
    this.showInactive = !this.showInactive;
  }

  async removeAllOther() {
    const confirmed = await this.alertService.confirm({
      header: 'maxDevices.headerAll',
      message: 'maxDevices.messageAll',
      cancelLabel: 'button.cancel',
      confirmLabel: 'button.remove',
      confirmButton: {
        color: 'danger',
        fill: 'outline',
      },
    });
    if (confirmed) {
      const deviceIdsToRemove = this.activeDevices.filter((device) => device.deviceUuid !== this.currentDeviceUuid).map((device) => device.deviceUuid);
      for (const deviceId of deviceIdsToRemove) {
        await this.userDeviceService.deactivateUserDevice(deviceId, 10000);
      }
      this.posthogService.captureEvent('[Settings][DeviceSettings][DeviceLimit]: Remove other devices clicked', {});
      this.activeDevices = this.activeDevices.filter((device) => !deviceIdsToRemove.includes(device.deviceUuid));
    }
  }

  async removeDevice(device: UserDevice) {
    const confirmed = await this.alertService.confirm({
      header: 'maxDevices.header',
      message: 'maxDevices.message',
      cancelLabel: 'button.cancel',
      confirmLabel: 'button.remove',
      confirmButton: {
        color: 'danger',
        fill: 'outline',
      },
    });
    if (confirmed) {
      await this.userDeviceService.deactivateUserDevice(device.deviceUuid, 10000);
      this.posthogService.captureEvent('[Settings][DeviceSettings][DeviceLimit]: Removed individual Device via settings', {});
      this.activeDevices = this.activeDevices.filter((activeDevice) => activeDevice.deviceUuid !== device.deviceUuid);
    }
  }

  getIcon(device: UserDevice): 'question' | 'tablet' | 'mobile' | 'desktop' {
    if (!device.platforms) {
      return 'question';
    } else if (device.platforms.includes('tablet')) {
      return 'tablet';
    } else if (device.platforms.includes('mobile')) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }

  getDeviceType(device: UserDevice): string {
    if (!device.platforms) {
      return this.translateService.instant('maxDevices.unknown');
    } else if (device.platforms.includes('tablet')) {
      return this.translateService.instant('maxDevices.tablet');
    } else if (device.platforms.includes('mobile')) {
      return this.translateService.instant('maxDevices.mobile');
    } else {
      return this.translateService.instant('maxDevices.desktop');
    }
  }

  getDeviceNameAndOs(device: UserDevice): string {
    if (!device.platform || !device.userAgent || !device.operatingSystem) {
      return this.translateService.instant('maxDevices.unknown');
    }
    if (device.platform === 'web') {
      return this.getBrowser(device.userAgent) + ', ' + device.operatingSystem.charAt(0).toUpperCase() + device.operatingSystem.slice(1);
    } else {
      return device.deviceName ?? '' + ', ' + device.operatingSystem.charAt(0).toUpperCase() + device.operatingSystem.slice(1);
    }
  }

  getBrowser(userAgent: string): 'Firefox' | 'Chrome' | 'Safari' | 'Opera' | 'Edge' | 'Internet Explorer' | 'Browser' {
    if (/edge/i.test(userAgent) || /Edg/i.test(userAgent)) {
      return 'Edge';
    } else if (/firefox/i.test(userAgent)) {
      return 'Firefox';
    } else if (/chrome/i.test(userAgent)) {
      return 'Chrome';
    } else if (/safari/i.test(userAgent)) {
      return 'Safari';
    } else if (/opera|opr/i.test(userAgent)) {
      return 'Opera';
    } else if (/msie|trident/i.test(userAgent)) {
      return 'Internet Explorer';
    } else {
      return 'Browser';
    }
  }

  isOneFirefox(): boolean {
    if (this.activeDevices) {
      for (const device of this?.activeDevices) {
        if (!device.userAgent || this.getBrowser(device.userAgent) === 'Firefox') {
          return true;
        }
      }
    } else {
      return false;
    }
    return false;
  }
}
