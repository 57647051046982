import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {PdfPlanMarkerProtocolEntry, PdfPlanPage, PdfPlanPageMarking} from 'submodules/baumaster-v2-common';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {PdfPlanPageDataService} from '../../../services/data/pdf-plan-page-data.service';
import {MoveChoiceType} from '../../../model/pdf-plan-marker-migration';
import {MarkerData, OldMarkerSize} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/fabricPdfPlanMarker';
import {LoggingService} from '../../../services/common/logging.service';
import {PhotoService} from '../../../services/photo/photo.service';
import _ from 'lodash';

const LOG_SOURCE = 'PdfPlanMarkerMigrationComponent';

@Component({
  selector: 'app-pdf-plan-marker-migration',
  templateUrl: './pdf-plan-marker-migration.component.html',
  styleUrls: ['./pdf-plan-marker-migration.component.scss'],
})
export class PdfPlanMarkerMigrationComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  readonly = false;

  @Input()
  pdfPlanPage: PdfPlanPage;

  @Input()
  imageBlob?: Blob;

  @Input()
  migratePdfPlanPageMarkingGeneral: boolean;

  @Input()
  moveChoice?: MoveChoiceType;

  @Output()
  moveChoiceChange = new EventEmitter<MoveChoiceType>();

  @Input()
  width?: number;

  @Input()
  height?: number;

  @Input()
  pdfPlanMarkerProtocolEntries?: Array<PdfPlanMarkerProtocolEntry>;

  @Input()
  moveEntriesWhenOutsideVisibleArea = false;

  @Output()
  pdfPlanMarkerProtocolEntriesChange = new EventEmitter<Array<PdfPlanMarkerProtocolEntry>>();

  @Input()
  pdfPlanPageMarkings?: Array<PdfPlanPageMarking>;

  @Output()
  pdfPlanPageMarkingsChange = new EventEmitter<Array<PdfPlanPageMarking>>();

  @Output()
  loadedImage = new EventEmitter<any>();

  @Output()
  entriesMovedFromOutsideVisibleArea = new EventEmitter<boolean>();

  imageUrl?: string;
  markerData?: Array<MarkerData>;

  constructor(
    private pdfPlanService: PdfPlanService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private loggingService: LoggingService,
    private photoService: PhotoService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.imageBlob && changes.imageBlob.currentValue) {
      this.resetImageUrl();
      this.imageUrl = URL.createObjectURL(this.imageBlob);
    }
    if (changes.pdfPlanPage && changes.pdfPlanPage.currentValue) {
      if (!this.imageBlob) {
        this.resetImageUrl();
        this.imageUrl = (await this.photoService.getObjectUrl(this.pdfPlanPage, 'image')) ?? undefined;
      }
    }
    if (changes.pdfPlanMarkerProtocolEntries) {
      if (!this.pdfPlanMarkerProtocolEntries) {
        this.pdfPlanMarkerProtocolEntries = [];
      }
      const isCurrentEntryMarker = (pdfPlanMarkerProtocolEntry: PdfPlanMarkerProtocolEntry): boolean => {
        return !this.readonly;
      };
      this.markerData = (
        await this.pdfPlanService.convertPdfPlanMarkerToMarkerData(this.pdfPlanMarkerProtocolEntries, this.pdfPlanPage.id, OldMarkerSize.SMALL, undefined, isCurrentEntryMarker)
      ).markers;
    }
    if (changes.pdfPlanPageMarkings) {
      if (!this.pdfPlanPageMarkings) {
        this.pdfPlanPageMarkings = [];
      }
    }
    if (changes.width || changes.height) {
      this.loggingService.debug(LOG_SOURCE, `ngOnChanges - width=${this.width}, height=${this.height}`);
    }
  }

  ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, `ngOnInit - imageBlob=${!!this.imageBlob}, imageUrl=${this.imageUrl}, pdfPlanPage.id=${this.pdfPlanPage?.id}`);
    this.loggingService.debug(LOG_SOURCE, `ngOnInit - width=${this.width}, height=${this.height}`);
  }

  ngOnDestroy(): void {
    this.resetImageUrl();
  }

  private resetImageUrl() {
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl);
      this.imageUrl = undefined;
    }
  }

  markerDataChanged(markerData: Array<MarkerData>) {
    const changedPdfPlanMarkerProtocolEntries = this.pdfPlanService.convertMarkerDataToPdfPlanMarkers(markerData, this.pdfPlanMarkerProtocolEntries);
    this.pdfPlanMarkerProtocolEntries = _.differenceBy(this.pdfPlanMarkerProtocolEntries, changedPdfPlanMarkerProtocolEntries, 'id').concat(changedPdfPlanMarkerProtocolEntries);
    this.pdfPlanMarkerProtocolEntriesChange.emit(this.pdfPlanMarkerProtocolEntries);
  }

  pdfPlanPageMarkingsChanged(changedPdfPLanPageMarkings: Array<PdfPlanPageMarking>) {
    this.pdfPlanPageMarkings = _.differenceBy(this.pdfPlanPageMarkings, changedPdfPLanPageMarkings, 'id').concat(changedPdfPLanPageMarkings);
    this.pdfPlanPageMarkingsChange.emit(this.pdfPlanPageMarkings);
  }

  moveChoiceChanged(moveChoice: MoveChoiceType) {
    this.moveChoiceChange.emit(moveChoice);
  }
}
