import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {PdfPlanHolderListHeaderComponent} from 'src/app/components/project-room/pdf-plan-folders/pdf-plan-holder-list-header/pdf-plan-holder-list-header.component';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {PdfPlanHolderSelectionService} from 'src/app/services/project-room/pdf-plan-holder-selection.service';
import {PdfPlanFolderWithHolders, PdfPlansFilteredDataService} from 'src/app/services/project-room/pdf-plans-filtered-data.service';
import _ from 'lodash';
import {IdType} from 'submodules/baumaster-v2-common';
import {PlanAnalyticsService} from 'src/app/services/project-room/plan-analytics.service';

@Directive({
  selector: 'app-pdf-plan-holder-list-header[appPdfPlansSelectAllHeader]',
  exportAs: 'appPdfPlansSelectAllHeader',
})
export class PdfPlansSelectAllHeaderDirective implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();

  @Input()
  folderId$: Observable<string>;

  allCurrentPlans: PdfPlanWithDeletable[] | undefined;
  selectedSet: Set<IdType> | undefined;

  constructor(
    private pdfPlanHolderListHeaderComponent: PdfPlanHolderListHeaderComponent,
    private cdRef: ChangeDetectorRef,
    private pdfPlanHolderSelectionService: PdfPlanHolderSelectionService,
    private pdfPlansFilteredDataService: PdfPlansFilteredDataService,
    private planAnalyticsService: PlanAnalyticsService
  ) {}

  private isArrayWithFoldersAndHolders(foldersOrHolders: PdfPlanWithDeletable[] | PdfPlanFolderWithHolders[]): foldersOrHolders is PdfPlanFolderWithHolders[] {
    return foldersOrHolders.some((folderOrHolder) => 'pdfPlanFolder' in folderOrHolder);
  }

  private initSubscriptions() {
    this.folderId$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((folderId) =>
          folderId === 'all' ? this.pdfPlansFilteredDataService.getPdfPlanFoldersWithPlans$(this.folderId$) : this.pdfPlansFilteredDataService.getPdfPlansForFolder$(this.folderId$)
        )
      )
      .subscribe((foldersOrHolders) => {
        if (!foldersOrHolders.length) {
          this.allCurrentPlans = [];
          return;
        }
        if (this.isArrayWithFoldersAndHolders(foldersOrHolders)) {
          this.allCurrentPlans = _.flatten(foldersOrHolders.map((folderOrHolder) => folderOrHolder.pdfPlanHolders));
        } else {
          this.allCurrentPlans = foldersOrHolders;
        }
      });
    this.pdfPlanHolderListHeaderComponent.selectedChange.pipe(takeUntil(this.destroy$)).subscribe((selected) => {
      if (selected) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    });
    this.pdfPlanHolderSelectionService.selectedSet$.pipe(takeUntil(this.destroy$)).subscribe((selectedSet) => {
      this.selectedSet = selectedSet;
      if (!this.allCurrentPlans) {
        return;
      }
      if (this.allSelected()) {
        this.pdfPlanHolderListHeaderComponent.selected = true;
        this.pdfPlanHolderListHeaderComponent.indeterminate = false;
      } else {
        this.pdfPlanHolderListHeaderComponent.selected = false;
        this.pdfPlanHolderListHeaderComponent.indeterminate = selectedSet.size !== 0;
      }
      this.cdRef.detectChanges();
    });
  }

  allSelected() {
    return this.selectedSet.size > 0 && this.allCurrentPlans.every(({id}) => this.selectedSet.has(id));
  }

  unselectAll() {
    this.pdfPlanHolderSelectionService.unselectAll();
    this.planAnalyticsService.planSelected(0);
  }

  selectAll() {
    const plans = this.allCurrentPlans ?? [];
    this.pdfPlanHolderSelectionService.select(plans);
    this.planAnalyticsService.planSelected(plans.length);
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
