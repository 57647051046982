import {Injectable} from '@angular/core';
import {AbstractPdfReportCommonService, PdfReportGenerateData, PdfReportSendReq} from 'submodules/baumaster-v2-common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import mulishJson from '../../../assets/fonts/mulish/mulish.json';
import interJson from '../../../assets/fonts/inter/inter.json';

const PDF_GENERATION_TIMEOUT_IN_MS = 3 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class PdfReportCommonService extends AbstractPdfReportCommonService {
  constructor() {
    super();
  }

  public async generatePdf(pdfReportSendReq: PdfReportSendReq, data: PdfReportGenerateData, abortSignal?: AbortSignal): Promise<Blob> {
    // TODO find a solution to handle errors. getBlob just never returns that's why callbacks are here.
    const pdfGenerationPromise = new Promise<Blob>(async (resolve, reject) => {
      try {
        const pdf = await this.createPdf(pdfReportSendReq, data);
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      } catch (e) {
        reject(e);
      }
    });

    const promises: Array<Promise<Blob>> = [pdfGenerationPromise, new Promise<Blob>((_, reject) => setTimeout(() => reject(new Error('Generating PDF timed out.')), PDF_GENERATION_TIMEOUT_IN_MS))];
    if (abortSignal) {
      const abortSignalPromise = new Promise<Blob>((_, reject) => {
        abortSignal.onabort = () => {
          reject(new Error('generatePdf aborted'));
        };
      });
      promises.push(abortSignalPromise);
    }

    return await Promise.race(promises);
  }

  public async openPdf(pdfReportSendReq: PdfReportSendReq, data: PdfReportGenerateData) {
    const pdf = await this.createPdf(pdfReportSendReq, data);
    pdf.open();
  }

  private async createPdf(pdfReportSendReq: PdfReportSendReq, data: PdfReportGenerateData): Promise<pdfMake.TCreatedPdf> {
    return await pdfMake.createPdf(
      this.getDocDefinition(pdfReportSendReq, data),
      null,
      {
        Mulish: {
          normal: 'Mulish-Regular.ttf',
          bold: 'Mulish-Bold.ttf',
          italics: 'Mulish-Italic.ttf',
          bolditalics: 'Mulish-BoldItalic.ttf',
        },
        Inter: {
          normal: 'Inter-Regular.ttf',
          bold: 'Inter-Bold.ttf',
          italics: 'Inter-Italic.ttf',
          bolditalics: 'Inter-BoldItalic.ttf',
        },
      },
      {
        'Mulish-Regular.ttf': mulishJson.MulishRegular,
        'Mulish-Bold.ttf': mulishJson.MulishBold,
        'Mulish-Italic.ttf': mulishJson.MulishItalic,
        'Mulish-BoldItalic.ttf': mulishJson.MulishBoldItalic,
        'Inter-Regular.ttf': interJson.InterRegular,
        'Inter-Bold.ttf': interJson.InterBold,
        'Inter-Italic.ttf': interJson.InterItalic,
        'Inter-BoldItalic.ttf': interJson.InterBoldItalic,
      }
    );
  }
}
