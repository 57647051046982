import {NavigationStart, Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {filter} from 'rxjs/operators';

export const dismissOverlayOnBackButtonOrNavigation = (overlay: HTMLIonAlertElement | HTMLIonModalElement, router: Router, platform: Platform) => {
  const navigationSub = router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
    overlay.dismiss();
    navigationSub.unsubscribe();
  });
  const backButtonSub = platform.backButton.subscribe(() => {
    overlay.dismiss();
    backButtonSub.unsubscribe();
  });
  overlay.onWillDismiss().then(() => {
    backButtonSub.unsubscribe();
    navigationSub.unsubscribe();
  });
};
