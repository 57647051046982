import {Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Protocol, ProtocolEntry, ProtocolEntryStatus, ProtocolType} from 'submodules/baumaster-v2-common';
import {Observable, Subscription} from 'rxjs';
import {ProtocolTypeDataService} from '../../../services/data/protocol-type-data.service';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {LoggingService} from '../../../services/common/logging.service';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {ProtocolEntryTypeDataService} from '../../../services/data/protocol-entry-type-data.service';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {getColorName} from 'src/app/utils/protocol-entry-utils';

const LOG_SOURCE = 'ProtocolEntryShortIdComponent';

@Component({
  selector: 'app-protocol-entry-short-id',
  templateUrl: './protocol-entry-short-id.component.html',
  styleUrls: ['./protocol-entry-short-id.component.scss'],
})
export class ProtocolEntryShortIdComponent implements OnInit, OnChanges, OnDestroy {
  @Input() acrossProjects = true;
  @Input() protocolEntry: ProtocolEntry & Partial<ProtocolEntryOrOpen>;
  @Input() hideBorderLeft = false;
  @Input() isProtocolLayoutShort = false;
  @Input() active = false;
  @Input() isTask?: boolean = false;
  @Input() isEditEnabled = false;
  public protocolTypesById: Observable<Record<string, ProtocolType> | null>;
  public protocolData: Observable<Protocol | null>;
  public createdInProtocolData: Observable<Protocol | null> | undefined;
  public protocolEntryStatus = ProtocolEntryStatus;
  public statusFieldActive = false;
  private protocolEntryTypeSubscription: Subscription;

  @HostBinding('style.--background-color')
  get varBackgroundColor() {
    return `var(--ion-color-${this.colorName}-rgb)`;
  }

  @HostBinding('style.--color')
  get varColor() {
    return `var(--ion-color-${this.colorName})`;
  }

  @HostBinding('style.--color-dark')
  get varColorDark() {
    const color = this.colorName;
    if (color === 'danger') {
      return `var(--ion-color-text-danger)`;
    }
    return `var(--ion-color-${this.colorName}-tint)`;
  }

  get colorName() {
    const {protocolEntry, statusFieldActive, isProtocolLayoutShort} = this;
    return getColorName(protocolEntry, statusFieldActive, isProtocolLayoutShort);
  }

  constructor(
    private protocolDataService: ProtocolDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called');
    this.protocolTypesById = this.protocolTypeDataService.dataWithoutHiddenAcrossClientsGroupedById$;
    if (this.isProtocolLayoutShort) {
      this.statusFieldActive = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const protocolId = this.protocolEntry?.originalProtocolId ?? this.protocolEntry?.protocolId;
    this.protocolData = this.acrossProjects ? this.protocolDataService.getByIdAcrossProjects(protocolId) : this.protocolDataService.getById(protocolId);
    this.createdInProtocolData = this.protocolEntry?.createdInProtocolId
      ? this.acrossProjects
        ? this.protocolDataService.getByIdAcrossProjects(this.protocolEntry?.createdInProtocolId)
        : this.protocolDataService.getById(this.protocolEntry?.createdInProtocolId)
      : undefined;
    this.protocolEntryTypeSubscription?.unsubscribe();
    if (!this.isProtocolLayoutShort) {
      this.protocolEntryTypeSubscription = this.protocolEntryTypeDataService.getByIdAcrossClients(this.protocolEntry?.typeId).subscribe((protocolEntryType) => {
        this.statusFieldActive = !!protocolEntryType?.statusFieldActive;
      });
    }
  }

  getProtocolTypeCode(protocolTypes: Record<string, ProtocolType> | undefined, protocol: Protocol | undefined): string {
    if (protocolTypes && protocol) {
      return protocolTypes[protocol.typeId]?.code;
    }
    return '';
  }

  getParentProtocolEntryId(parentProtocolEntryId): Observable<ProtocolEntry> {
    if (this.acrossProjects) {
      return this.protocolEntryDataService.getByIdAcrossProjects(parentProtocolEntryId);
    }

    return this.protocolEntryDataService.getById(parentProtocolEntryId);
  }

  ngOnDestroy() {
    this.protocolEntryTypeSubscription?.unsubscribe();
    this.protocolEntryTypeSubscription = undefined;
  }
}
