import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Pipe({
  name: 'toObservable',
})
export class ToObservablePipe implements PipeTransform, OnDestroy {
  private subject = new ReplaySubject(1);
  private observable = this.subject.asObservable();

  transform(value: unknown): Observable<unknown> {
    this.subject.next(value);
    return this.observable;
  }

  ngOnDestroy() {
    this.subject.complete();
  }
}
