import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {IndividualNextMeetingFormComponent} from '../individual-next-meeting-form/individual-next-meeting-form.component';
import {IndividualNextMeetingForm, NextMeetingForm} from '../next-meeting.interface';

@Component({
  selector: 'app-individual-next-meetings',
  templateUrl: './individual-next-meetings.component.html',
  styleUrls: ['./individual-next-meetings.component.scss'],
})
export class IndividualNextMeetingsComponent implements OnInit {
  @Input()
  nextMeeting: NextMeetingForm;
  @Input()
  individualNextMeetings: IndividualNextMeetingForm[];
  @Output()
  individualNextMeetingsChange = new EventEmitter<IndividualNextMeetingForm[]>(true);

  @Output()
  deleteClick = new EventEmitter<IndividualNextMeetingForm>();

  @ViewChildren(IndividualNextMeetingFormComponent)
  forms: QueryList<IndividualNextMeetingFormComponent>;

  readonly trackByProfile = (individualNextMeeting: IndividualNextMeetingForm) => individualNextMeeting.profile?.id;

  constructor() {}

  ngOnInit() {}

  handleMeetingChange(meeting: IndividualNextMeetingForm) {
    const existingIndex = this.individualNextMeetings.findIndex((theMeeting) => theMeeting.profile.id === meeting.profile.id);
    this.individualNextMeetings.splice(existingIndex, 1, meeting);
    this.individualNextMeetings = [...this.individualNextMeetings];
    this.individualNextMeetingsChange.emit(this.individualNextMeetings);
  }

  handleDeleteMeeting(meeting: IndividualNextMeetingForm) {
    this.individualNextMeetings = this.individualNextMeetings.filter((theMeeting) => theMeeting !== meeting);
    this.individualNextMeetingsChange.emit(this.individualNextMeetings);
  }

  isValid() {
    if (!this.forms?.length) {
      return true;
    }
    return !this.forms.some(({form}) => form.invalid);
  }
}
