import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastController} from '@ionic/angular';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {AttachmentChat, generateFilePathsForAttachmentChat, IdType, User} from 'submodules/baumaster-v2-common';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {LoggingService} from '../common/logging.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ATTACHMENT_DEFAULT_SORT_COLUMNS, ATTACHMENT_DEFAULT_SORT_ORDER, StorageKeyEnum} from '../../shared/constants';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {SystemEventService} from '../event/system-event.service';
import {DevModeService} from '../common/dev-mode.service';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/attachmentChats';

@Injectable({
  providedIn: 'root',
})
export class AttachmentChatDataService extends AbstractProjectAwareAttachmentDataService<AttachmentChat> {
  readonly dataByProtocolEntryId$: Observable<Record<IdType, AttachmentChat[]>> = this.data.pipe(map((attachments) => _.groupBy(attachments ?? [], 'protocolEntryId')));
  readonly dataByProtocolEntryIdAcrossProjects$: Observable<Record<IdType, AttachmentChat[]>> = this.dataAcrossProjects$.pipe(map((attachments) => _.groupBy(attachments ?? [], 'protocolEntryId')));

  constructor(
    http: HttpClient,
    storage: StorageService,
    private toastController: ToastController,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    loggingService: LoggingService,
    systemEventService: SystemEventService,
    protected devModeService: DevModeService,
    integrityResolverService: IntegrityResolverService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    protected translateService: TranslateService,
    protected attachmentSettingService: AttachmentSettingService
  ) {
    super(
      StorageKeyEnum.ATTACHMENT_CHAT,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      systemEventService,
      devModeService,
      integrityResolverService,
      translateService,
      attachmentSettingService,
      projectAvailabilityExpirationService,
      VERSION_INTRODUCED_DEFAULT,
      ATTACHMENT_DEFAULT_SORT_COLUMNS,
      ATTACHMENT_DEFAULT_SORT_ORDER
    );
  }

  public getByProtocolEntry(protocolEntryId: IdType): Observable<Array<AttachmentChat>> {
    return this.data.pipe(map((entries) => entries.filter((attachment) => attachment.protocolEntryId === protocolEntryId)));
  }

  public getByProtocolEntryAcrossProjects(protocolEntryId: IdType): Observable<Array<AttachmentChat>> {
    return this.dataAcrossProjects$.pipe(map((entries) => entries.filter((attachment) => attachment.protocolEntryId === protocolEntryId)));
  }

  public getByProtocolEntries(protocolEntryIds: Array<IdType>): Observable<Array<AttachmentChat>> {
    return this.data.pipe(map((entries) => entries.filter((attachment) => protocolEntryIds.some((protocolEntryId) => attachment.protocolEntryId === protocolEntryId))));
  }

  public getByProtocolEntriesAcrossProjects(protocolEntryIds: Array<IdType>): Observable<Array<AttachmentChat>> {
    return this.dataAcrossProjects$.pipe(map((entries) => entries.filter((attachment) => protocolEntryIds.some((protocolEntryId) => attachment.protocolEntryId === protocolEntryId))));
  }
  public getByProtocolEntryAndChat(protocolEntryId: IdType, chatId: IdType): Observable<Array<AttachmentChat>> {
    return this.data.pipe(map((entries) => entries.filter((attachment) => attachment.protocolEntryId === protocolEntryId && attachment.chatId === chatId)));
  }
  public getByProtocolEntryAndChatAcrossProjects(protocolEntryId: IdType, chatId: IdType): Observable<Array<AttachmentChat>> {
    return this.dataAcrossProjects$.pipe(map((entries) => entries.filter((attachment) => attachment.protocolEntryId === protocolEntryId && attachment.chatId === chatId)));
  }

  protected setGeneratedFilePath(attachment: AttachmentChat) {
    attachment.filePath = generateFilePathsForAttachmentChat(attachment).filePath;
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
