import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stopwatch',
})
export class StopwatchPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return `00:00:00`;
    }
    const hours: number = Math.floor(value / 60 / 60);
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value - minutes * 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
