import {Component, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import {AttachmentProtocolEntry, IdType, ProjectStatusEnum, Protocol, ProtocolType} from 'submodules/baumaster-v2-common';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {ImageOffline} from '../../../model/attachments';
import {AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ProjectService} from '../../../services/project/project.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {LoggingService} from '../../../services/common/logging.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {PhotoService} from '../../../services/photo/photo.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import {observableToPromise} from '../../../utils/async-utils';
import {convertErrorToMessage, ErrorWithUserMessage} from '../../../shared/errors';
import _ from 'lodash';
import {CopyProtocolService} from '../../../services/copy/copy-protocol.service';
import {CopyProtocolSetting, ProtocolCopyResult} from '../../../model/copy';
import {isQuotaExceededError} from '../../../utils/attachment-utils';
import {AttachmentService} from '../../../services/attachment/attachment.service';
import {getProtocolEntryPagePath, getProtocolEntryPagePathForSearch} from 'src/app/utils/router-utils';
import {ToastService} from 'src/app/services/common/toast.service';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import ProjectForDisplay from '../../../model/ProjectForDisplay';

const LOG_SOURCE = 'CopyProtocolComponent';
const MIN_COPY_AMOUNT = 1;
const MAX_COPY_AMOUNT = 50;

@Component({
  selector: 'app-copy-protocol',
  templateUrl: './copy-protocol.component.html',
  styleUrls: ['./copy-protocol.component.scss'],
})
export class CopyProtocolComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  public sourceProject: ProjectForDisplay | undefined;
  public destinationProjectSubject = new BehaviorSubject<ProjectForDisplay | undefined>(undefined);

  public protocolId: IdType | undefined;
  public protocol: Protocol | undefined;
  public projects: ProjectForDisplay[] | undefined;
  public networkConnected$: Observable<boolean>;
  public allAttachmentsWithOffline: Array<AttachmentProtocolEntry & ImageOffline>;
  public isConnected: boolean;
  private copyInProgress: HTMLIonAlertElement;
  public allAttachmentsOfflineAvailable: boolean | undefined;
  private protocolTypes: ProtocolType[];
  private networkConnectedSubscription: Subscription | undefined;
  private abortController = new AbortController();
  private protocolSubscription: Subscription | undefined;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  public copyForm: UntypedFormGroup = new UntypedFormGroup({
    project: new UntypedFormControl(''),
    protocolName: new UntypedFormControl('', [Validators.required], [this.continuousProtocolTypeExistsValidator()]),
    copyAttachments: new UntypedFormControl(true),
    copyParentEntry: new UntypedFormControl(false),
    copySubEntries: new UntypedFormControl(false),
    copyDetails: new UntypedFormControl(true),
    copyCreationDate: new UntypedFormControl(true),
    copyContacts: new UntypedFormControl(true),
    copyMarkers: new UntypedFormControl(false),
    ignoreMissingAttachments: new UntypedFormControl(false),
    amountCopies: new UntypedFormControl(1, [Validators.required], [this.copyAmountValidator(MIN_COPY_AMOUNT, MAX_COPY_AMOUNT)]),
  });

  get getProtocolEntryPath() {
    return this.router.url.includes('protocols-search') ? getProtocolEntryPagePathForSearch : getProtocolEntryPagePath;
  }

  constructor(
    private protocolDataService: ProtocolDataService,
    private protocolService: ProtocolService,
    private navParams: NavParams,
    private networkStatusService: NetworkStatusService,
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private copyProtocolService: CopyProtocolService,
    private router: Router,
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    private alertController: AlertController,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private photoService: PhotoService,
    private attachmentService: AttachmentService,
    private selectableUtilService: SelectableUtilService
  ) {}

  async projectOnChange(event: {component: IonicSelectableComponent; value: ProjectForDisplay}) {
    await this.projectService.ensureProjectDataOfflineAvailable(event.value.id, {temporarily: true, modalAllowInBackground: false});
    this.destinationProjectSubject.next(event.value);
    this.copyForm.get('protocolName').updateValueAndValidity();
  }

  getProtocolTypeCode(protocol): string {
    if (this.protocolTypes && protocol) {
      return this.protocolTypes.find((type) => type.id === protocol.typeId).code;
    }
    return '';
  }

  async ngOnInit() {
    this.protocolId = this.navParams.data.protocolId;
    this.protocolSubscription = combineLatest([this.projectService.projectsForDisplay$, this.projectDataService.currentProjectObservable, this.protocolDataService.getById(this.protocolId)]).subscribe(
      ([projects, currentProject, protocol]) => {
        this.protocol = protocol;
        this.projects = projects.filter((project) => !project.status || project.status === ProjectStatusEnum.ACTIVE);

        if (_.isEmpty(this.copyForm.get('protocolName').value)) {
          this.copyForm.get('protocolName').setValue(this.protocol.name + this.translateService.instant('copyWorkflow.copySuffix'));
        }

        this.sourceProject = projects.find((project) => project.id === protocol.projectId);
        if (!this.destinationProjectSubject.value || projects.find((project) => project.id === this.destinationProjectSubject.value.id)) {
          this.destinationProjectSubject.next(this.sourceProject);
          this.copyForm.get('project').setValue(this.destinationProjectSubject.value);
        }
        this.copyForm.updateValueAndValidity();
        this.updateForm();
      }
    );

    this.allAttachmentsOfflineAvailable = await this.checkAttachmentsAvailability();
    this.updateForm();

    this.networkConnected$ = this.networkStatusService.onlineOrUnknown$;
    this.networkConnectedSubscription = this.networkConnected$.subscribe(async (connected) => {
      this.isConnected = connected;
      this.updateForm();
    });
  }

  private updateForm() {
    if (!this.sourceProject) {
      return;
    }
    if (this.sourceProject.isOfflineAvailable && this.allAttachmentsWithOffline?.length && !this.allAttachmentsOfflineAvailable) {
      if (this.isConnected) {
        this.copyForm.get('copyAttachments').enable();
      } else {
        this.copyForm.get('copyAttachments').setValue(false);
        this.copyForm.get('copyAttachments').disable();
      }
    }
    if (!this.sourceProject.isOfflineAvailable && this.allAttachmentsWithOffline?.length) {
      if (this.isConnected) {
        this.copyForm.get('copyAttachments').enable();
      } else {
        this.copyForm.get('copyAttachments').setValue(false);
        this.copyForm.get('copyAttachments').disable();
      }
    }
  }

  private async checkAttachmentsAvailability(): Promise<boolean> {
    const {attachmentProtocolEntries} = await this.copyProtocolService.getEntriesToCopy(this.protocol);
    this.allAttachmentsWithOffline = await this.photoService.toAttachmentsWithOffline(attachmentProtocolEntries);
    return _.some(this.allAttachmentsWithOffline, (attachment) => attachment.imageOffline);
  }

  private continuousProtocolTypeExistsValidator(): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      if (!this.protocol) {
        return null;
      }
      if (!(await this.protocolService.isContinuousProtocol(this.protocol))) {
        return null;
      }
      const destinationProjectId = this.destinationProjectSubject.value?.id;
      if (!destinationProjectId) {
        return null;
      }
      const protocolExists = await observableToPromise(this.protocolService.hasProjectProtocolOfType(this.protocol.typeId, destinationProjectId));

      if (protocolExists) {
        return {continuousProtocolExists: true};
      }

      return null;
    };
  }

  private copyAmountValidator(min: number, max: number): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      const value = control.value;
      const numericValue = Number(value);

      if (isNaN(numericValue)) {
        return {notANumber: true};
      }

      if (numericValue < min || numericValue > max) {
        return {numberOutOfRange: true};
      }
      if (this.protocol) {
        if ((await this.protocolService.isContinuousProtocol(this.protocol)) && numericValue > 1) {
          return {continuousOnlyOneCopy: true};
        }
      }
      return null;
    };
  }

  async copyEntry() {
    const ensureOfflineResult = await this.projectService.ensureProjectDataOfflineAvailable([this.sourceProject.id, this.destinationProjectSubject.value.id], {
      throwErrorIfMakingProjectsAvailableFailed: true,
      temporarily: true,
      modalAllowInBackground: false,
    });
    if (!ensureOfflineResult.success) {
      return;
    }
    this.copyInProgress = await this.alertController.create({
      message: this.translateService.instant('copyWorkflow.protocol.copyInProgress'),

      backdropDismiss: false,
      buttons: [
        {
          text: this.translateService.instant('cancel'),
          role: 'cancel',
          handler: async () => {
            this.abortController.abort();
            await this.toastService.infoWithMessage('copyWorkflow.cancelled');
            await this.modal.dismiss();
          },
        },
      ],
    });
    await this.copyInProgress.present();
    const logInstance = this.systemEventService.logAction(
      LOG_SOURCE,
      () => `Copy protocol (id=${this.protocol.id}, typeId=${this.protocol.typeId}, sourceProject=${this.protocol.projectId}, destinationProject=${this.destinationProjectSubject.value?.id})`
    );
    try {
      const rawData = this.copyForm.getRawValue();
      const copyProtocolSetting = {
        protocol: rawData.protocol,
        protocolName: rawData.protocolName,
        copyAttachments: rawData.copyAttachments,
        copyParentEntry: rawData.copyParentEntry,
        copySubEntries: rawData.copySubEntries,
        copyDetails: rawData.copyDetails,
        copyCreationDate: rawData.copyCreationDate,
        copyContacts: rawData.copyContacts,
        copyMarkers: rawData.copyMarkers,
        ignoreMissingAttachments: rawData.ignoreMissingAttachments,
        amountCopies: rawData.amountCopies,
      } as CopyProtocolSetting;

      const {protocol, ...settingsForLog} = copyProtocolSetting;

      logInstance.logCheckpoint(() => `(settings=${JSON.stringify(settingsForLog)})`);

      const firstProtocolName = copyProtocolSetting.protocolName;

      for (let index = 0; index < copyProtocolSetting.amountCopies; index++) {
        if (index > 0) {
          copyProtocolSetting.protocolName = firstProtocolName + ' (' + index + ')';
        }
        const result = await this.performCopy(copyProtocolSetting, index);
        if (result === null) {
          logInstance.success('aborted');
        } else {
          logInstance.success(() => `newId=${result.copiedProtocol.id},newEntryIds=${result.copiedEntries.map((entry) => entry.id)}`);
        }
      }
    } catch (error) {
      logInstance.failure(error);
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage || convertErrorToMessage(error);
      } else {
        message = convertErrorToMessage(error);
      }
      this.loggingService.error(LOG_SOURCE, `Error copying protocolEntry. "${message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - copyDismiss', message);
      if (!this.abortController.signal.aborted || message.indexOf('abort') === -1) {
        if (isQuotaExceededError(message)) {
          await this.attachmentService.showToastQuotaExceeded();
        } else {
          await this.toastService.errorWithMessageAndHeader('copyWorkflow.errorCopyingMessage', message);
        }
      }
    } finally {
      await this.copyInProgress.dismiss();
    }
  }

  copyUpdateProgressCallback = (protocol: Protocol, numberEntriesCopied: number, numberEntries: number, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => {
    if (text) {
      this.copyInProgress.message = text;
      return;
    }
    let message = this.translateService.instant('copyWorkflow.progressCopyEntry', {name: protocol.name});
    if (numberCopiedAttachments !== undefined) {
      message =
        message +
        this.translateService.instant('copyWorkflow.progressCopyAttachment', {
          numberCopiedAttachments,
          numberOfAttachments,
        });
    }
    this.copyInProgress.message = this.sanitizer.sanitize(SecurityContext.HTML, message);
  };

  private async performCopy(copyProtocolSetting: CopyProtocolSetting, currentCopyIndex: number): Promise<ProtocolCopyResult | null> {
    const copyResult = await this.copyProtocolService.copy(this.protocol, this.destinationProjectSubject.value, copyProtocolSetting, this.abortController, this.copyUpdateProgressCallback);
    if (this.abortController.signal.aborted) {
      return null;
    }

    this.copyInProgress.querySelector('button').hidden = true;
    await this.toastService.infoWithMessageAndButtons('copyWorkflow.protocol.success', [
      {
        side: 'end',
        text: this.translateService.instant('modal.goToEntry'),
        handler: async () => {
          if (this.destinationProjectSubject.value?.id !== (await this.projectDataService.getCurrentProject())?.id) {
            await this.projectService.setCurrentProject(this.destinationProjectSubject.value);
          }
          await this.router.navigate(this.getProtocolEntryPath(copyResult.copiedProtocol.id, 'first'));
        },
      },
    ]);
    if (currentCopyIndex === copyProtocolSetting.amountCopies - 1) {
      await this.modal.dismiss();
    }
    return copyResult;
  }

  ngOnDestroy(): void {
    this.protocolSubscription?.unsubscribe();
    this.protocolSubscription = undefined;
  }

  async cancel() {
    await this.modal.dismiss();
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }

  getGroupText = (projectForDisplay: ProjectForDisplay, index: number, projectsForDisplay: ProjectForDisplay[]) => {
    return this.projectService.getProjectGroupText(projectForDisplay, index, projectsForDisplay);
  };
}
