<app-modal-header>

</app-modal-header>

<ion-content [scrollX]="false" [scrollY]="false">
  <div class="pdf-navigation-container">
    <div class="page-navigation-container d-flex gap-1 ion-justify-content-center ion-align-items-center">
      <ion-item lines="none" class="input-item" *ngIf="allPdfDocumentsLoaded">
        <ion-input #input type="number" class="page-number-input" min=1 [max]="numPages" [ngModel]="currentPage"
                   (ngModelChange)="updatePage($event)"></ion-input>
      </ion-item>
      <span>/ </span>
      <span>{{numPages}}</span>
    </div>
    <div class="margin-top-5 pr-2 pl-3 searchbar-container">
      <ion-searchbar #autofocus debounce="200" [(ngModel)]="searchText" (ngModelChange)="searchTextChanged()"
      (keydown.enter)="enterKeyPressed()" placeholder="{{'search' | translate}}" class="omg slim-searchbar"></ion-searchbar>
    </div>
  
    <div class="margin-top-5">
      <ion-button class="omg-btn-text" (click)="zoomIn()">
        <fa-icon [icon]="['fal', 'search-plus']"></fa-icon>
      </ion-button>
    </div>
  
    <div class="margin-top-5">
      <ion-button class="omg-btn-text" (click)="zoomOut()" [disabled]="zoom <= 0.2">
        <fa-icon [icon]="['fal', 'search-minus']"></fa-icon>
      </ion-button>
    </div>
  
    <div *ngIf="showDownload" class="margin-top-5">
      <ion-button [appDownload]="blob" [appDownloadFilename]="filename" (click)="onDownloadClick && onDownloadClick()" class="omg-btn-text">
        <fa-icon [icon]="['fal', 'download']"></fa-icon>
      </ion-button>
    </div>
  </div>
  
    <div class="pdf-viewer-container">
      <div class="ion-text-center">
        <pdf-viewer #pdfViewer [src]="pdfObjectUrl"
                    [render-text]="true"
                    [show-all]="true"
                    [page]="currentPageOfDocument"
                    [zoom]="zoom"
                    [original-size]="false"
                    [fit-to-page]="false"
                    (after-load-complete)="afterLoadComplete($event)"
                    (error)="onError($event)"
                    (on-progress)="onProgress($event)"
                    (page-rendered)="pageRendered($event)"
                    (pageChange)="pageChanged($event)"
        ></pdf-viewer>
      </div>
    </div>
</ion-content>

<app-modal-footer>
  <ion-grid class="ion-no-padding">
    <ion-row *ngIf="signers?.length" class="signers">
      <ion-col size="12">
        <app-signature [signers]="signers" [showSignatureWhenSigned]="true" [(signersWithSignature)]="signersWithSignature" (signersWithSignatureChange)="signersWithSignatureChanged && signersWithSignatureChanged($event)"
                        [showSendButtons]="showSendButtons" [showCloseCheckbox]="showCloseCheckbox" (requestCloseEmitter)="requestClose && requestClose()" (requestRenderEmitter)="requestRender && requestRender()" ></app-signature>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="pdfProtocolSignatures?.length || (!signers?.length && showSendButtons)" class="signersMultiple">
      <ion-col size="12">
        <app-pdf-protocol-signatures-footer [pdfProtocolSignatures]="pdfProtocolSignatures" (pdfProtocolSignaturesChange)="pdfProtocolSignaturesChange($event)" [projectId]="projectId" [protocolId]="protocolId"
                       [showSendButtons]="showSendButtons" [showCloseCheckbox]="showCloseCheckbox" (requestCloseEmitter)="requestClose && requestClose()" (requestRenderEmitter)="requestRender && requestRender()" ></app-pdf-protocol-signatures-footer>
      </ion-col>
    </ion-row>
  </ion-grid>
</app-modal-footer>
