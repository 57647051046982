import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LicenseType, ProtocolType} from 'submodules/baumaster-v2-common';
import {ProjectProtocolTypeDataService} from '../data/project-protocol-type-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';

@Injectable({
  providedIn: 'root',
})
export class ProtocolTypeService {
  constructor(
    private protocolTypeService: ProtocolTypeDataService,
    private protocolDataService: ProtocolDataService,
    private projectProtocolTypeDataService: ProjectProtocolTypeDataService,
    private featureEnabledService: FeatureEnabledService
  ) {}

  /**
   * Returns an observable of list of the active and used
   * protocol types in current project.
   */
  getActiveAndUsedProtocolTypes(): Observable<ProtocolType[]> {
    return combineLatest([this.projectProtocolTypeDataService.data, this.protocolTypeService.dataWithoutHidden$, this.protocolDataService.dataWithoutHidden$]).pipe(
      map(([projectProtocolTypes, protocolTypes, protocols]) =>
        protocolTypes.filter(
          (protocolType) =>
            projectProtocolTypes.find((projectProtocolType) => projectProtocolType.protocoltypeId === protocolType.id) || protocols.some((protocol) => protocol.typeId === protocolType.id)
        )
      )
    );
  }

  /**
   * Returns an observable of list of the active and used
   * protocol types in current project. Returns only active
   * protocol types if the user is viewer/connected.
   */
  getActiveAndUsedProtocolTypesOrOnlyUsed(): Observable<ProtocolType[]> {
    return combineLatest([
      this.projectProtocolTypeDataService.data,
      this.protocolTypeService.dataWithoutHiddenWithDeletedSuffix$,
      this.protocolDataService.dataWithoutHidden$,
      this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]),
    ]).pipe(
      map(([projectProtocolTypes, protocolTypes, protocols, isNotConnectedOrViewer]) =>
        protocolTypes.filter((protocolType) => {
          const isActiveInProject = protocolType.isActive && projectProtocolTypes.some((projectProtocolType) => projectProtocolType.protocoltypeId === protocolType.id);
          const hasProtocolWithType = protocols.some((protocol) => protocol.typeId === protocolType.id);

          if (isNotConnectedOrViewer) {
            return isActiveInProject || hasProtocolWithType;
          }
          // We should protocol types that are not present in any protocol for viewer and connected users
          return isActiveInProject && hasProtocolWithType;
        })
      )
    );
  }
}
