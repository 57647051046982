import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IdType} from 'submodules/baumaster-v2-common';
import {IonicModule} from '@ionic/angular';
import {ProjectAvailabilityExpirationService} from '../../../services/project/project-availability-expiration.service';
import {SyncService} from '../../../services/sync/sync.service';
import {convertErrorToMessage} from '../../../shared/errors';
import {DataServiceFactoryService} from '../../../services/data/data-service-factory.service';
import {LoggingService} from '../../../services/common/logging.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../services/common/toast.service';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {DevModeService} from '../../../services/common/dev-mode.service';
import {AlertService} from '../../../services/ui/alert.service';
import {MakeProjectsOfflineAvailableResult, ProjectAvailabilityUtils, ProjectAvailableDefaultOptions} from 'src/app/utils/project-availability-utils';
import {SelectableInputModule} from '../../../shared/module/selectable-input/selectable-input.module';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '../../../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {UiModule} from '../../../shared/module/ui/ui.module';
import {TooltipModule} from '../../../shared/module/tooltip/tooltip.module';
import {PosthogService} from '../../../services/posthog/posthog.service';

const LOG_SOURCE = 'ProjectLoaderComponent';
const WAIT_TIME_TO_AVOID_FLICKERING_IN_MS = 2000;
const TIME_AFTER_SHOW_TAKING_LONGER_IN_MS = 3000;

@Component({
  selector: 'app-project-loader',
  templateUrl: './project-loader.component.html',
  styleUrls: ['./project-loader.component.scss'],
  standalone: true,
  imports: [TranslateModule, SelectableInputModule, CommonModule, FontAwesomeModule, FormsModule, IonicModule, PipesModule, RouterModule, UiModule, TooltipModule],
})
export class ProjectLoaderComponent implements OnInit, OnDestroy {
  @Input()
  projectIds: IdType[];
  @Input()
  temporarily = false;
  @Input()
  allowInBackground = ProjectAvailableDefaultOptions.modalAllowInBackground;
  @Input()
  allowCancel = ProjectAvailableDefaultOptions.modalAllowCancel;
  @Input()
  confirmWhenCancel = ProjectAvailableDefaultOptions.modalConfirmWhenCancel;
  @Input()
  throwErrorIfSyncForEnsureDataNotOlderThanFails = ProjectAvailableDefaultOptions.throwErrorIfSyncForEnsureDataNotOlderThanFails;
  @Input()
  showToastWhenDone = ProjectAvailableDefaultOptions.showToastWhenDone;
  @Input()
  additionalProjectIdsToSync?: IdType[];

  private modal: HTMLIonModalElement;
  loading: boolean | undefined;
  errorMessage: string | undefined;
  success: boolean | undefined;
  noInternet: boolean | undefined;
  takingLongerThanExpected: boolean | undefined;
  private dismissAlert: HTMLIonAlertElement | undefined;
  private projectAvailabilityUtils: ProjectAvailabilityUtils;
  private abortMakeProjectsOfflineAvailableController: AbortController | undefined;
  private makeProjectsOfflineAvailablePromise: Promise<MakeProjectsOfflineAvailableResult> | undefined;

  constructor(
    private projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    private dataServiceFactoryService: DataServiceFactoryService,
    private syncService: SyncService,
    private loggingService: LoggingService,
    private networkStatusService: NetworkStatusService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private projectDataService: ProjectDataService,
    private devModeService: DevModeService,
    private alertService: AlertService,
    private posthogService: PosthogService
  ) {
    this.projectAvailabilityUtils = new ProjectAvailabilityUtils(
      this.projectAvailabilityExpirationService,
      this.dataServiceFactoryService,
      this.syncService,
      this.projectDataService,
      this.devModeService,
      this.loggingService,
      this.toastService,
      this.translateService
    );
  }

  ngOnInit() {
    this.setCanDismiss();
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
  }

  async ionViewDidEnter() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidEnter called.');
    await this.makeProjectsOfflineAvailableIfNetworkConnected();
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
  }

  cancel(): void {
    this.loggingService.debug(LOG_SOURCE, 'cancel called.');
    this.posthogService.captureEvent('[ProjectLoader][Cancel]', {projects: this.projectIds?.length});
    this.modal.dismiss(undefined, 'cancel');
  }

  setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  async continueInBackground(): Promise<void> {
    if (!this.allowInBackground) {
      return;
    }
    this.showToastWhenDone = true;
    this.loggingService.debug(LOG_SOURCE, 'continueInBackground called.');
    this.loading = false;
    await this.abortDismissAlert();

    this.posthogService.captureEvent('[ProjectLoader][Background]', {projects: this.projectIds?.length});
    await this.modal.dismiss({makeProjectsOfflineAvailablePromise: this.makeProjectsOfflineAvailablePromise}, 'continueInBackground');
  }

  async tryAgain() {
    this.loggingService.debug(LOG_SOURCE, 'tryAgain called.');
    await this.makeProjectsOfflineAvailableIfNetworkConnected();
  }

  private async makeProjectsOfflineAvailableIfNetworkConnected() {
    const netWorkStatus = await this.networkStatusService.getNetworkStatus();
    this.noInternet = netWorkStatus ? !netWorkStatus.connected : undefined;
    if (!this.noInternet) {
      await this.makeProjectsOfflineAvailable();
    }
  }

  private async makeProjectsOfflineAvailable() {
    this.loggingService.debug(LOG_SOURCE, 'makeProjectsOfflineAvailable called.');
    this.loading = true;
    this.success = undefined;
    this.takingLongerThanExpected = undefined;

    try {
      if (this.allowInBackground) {
        this.startTakingLongerThanExpected();
      }
      const waitToAvoidFlickeringPromise = this.waitToAvoidFlickering(); // needs to be called at the beginning so the timer can start.
      this.makeProjectsOfflineAvailablePromise = undefined;
      this.abortMakeProjectsOfflineAvailableController = new AbortController();
      const makeOfflinePromise = this.projectAvailabilityUtils.makeProjectsOfflineAvailable(
        this.projectIds,
        {
          showToastWhenDone: this.showToastWhenDone,
          throwErrorIfMakingProjectsAvailableFailed: true,
          temporarily: this.temporarily,
          throwErrorIfSyncForEnsureDataNotOlderThanFails: this.throwErrorIfSyncForEnsureDataNotOlderThanFails,
        },
        this.additionalProjectIdsToSync,
        this.abortMakeProjectsOfflineAvailableController?.signal
      );
      this.makeProjectsOfflineAvailablePromise = makeOfflinePromise;
      const result = await makeOfflinePromise;
      this.abortMakeProjectsOfflineAvailableController = undefined;
      await waitToAvoidFlickeringPromise;
      this.success = !!result;
      this.loading = false;
      this.loggingService.debug(LOG_SOURCE, `makeProjectsOfflineAvailable finished (success=${this.success}).`);
      try {
        await this.abortDismissAlert();
        await this.modal.dismiss(undefined, 'ok');
      } catch (error) {
        this.loggingService.warn(LOG_SOURCE, `Error closing modal. ${convertErrorToMessage(error)}`);
      }
    } catch (error) {
      await this.abortDismissAlert();
      this.errorMessage = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, `makeProjectsOfflineAvailable failed with error ${this.errorMessage}`);
      this.success = false;
    } finally {
      this.loading = false;
      this.abortMakeProjectsOfflineAvailableController = undefined;
      this.loggingService.debug(LOG_SOURCE, 'makeProjectsOfflineAvailable finished.');
    }
  }

  private waitToAvoidFlickering(): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, WAIT_TIME_TO_AVOID_FLICKERING_IN_MS);
    });
  }

  private startTakingLongerThanExpected() {
    this.takingLongerThanExpected = false;
    setTimeout(() => (this.takingLongerThanExpected = true), TIME_AFTER_SHOW_TAKING_LONGER_IN_MS);
  }

  private canDismiss = async (data?: any, role?: string): Promise<boolean> => {
    if (!this.loading) {
      return true;
    }
    if (!this.confirmWhenCancel) {
      return true;
    }

    if (!this.dismissAlert) {
      this.dismissAlert = await this.alertService.createAlert({
        header: this.translateService.instant('projectLoader.cancelDownloadDialog.title'),
        message: this.translateService.instant('projectLoader.cancelDownloadDialog.text'),
        buttons: [
          {
            text: this.translateService.instant('cancel'),
            role: 'cancel',
            cssClass: this.alertService.getButtonCssClass({color: 'text-primary', fill: 'clear'}),
          },
          {
            text: this.translateService.instant('okay'),
            role: 'confirm',
            cssClass: this.alertService.getButtonCssClass({color: 'text-primary', fill: 'clear'}),
          },
        ],
      });

      await this.dismissAlert.present();
    }

    const confirmed = (await this.dismissAlert.onWillDismiss()).role === 'confirm';
    this.abortMakeProjectsOfflineAvailableController?.abort();
    this.dismissAlert = undefined;
    return confirmed;
  };

  private async abortDismissAlert() {
    if (this.dismissAlert) {
      try {
        await this.dismissAlert.dismiss(undefined, 'abort');
        this.dismissAlert = undefined;
      } catch (error) {
        this.loggingService.warn(LOG_SOURCE, `abortDismissAlert failed with error ${convertErrorToMessage(error)}`);
      }
    }
  }
}
