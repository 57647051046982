import {Component, Input} from '@angular/core';
import {IonicSelectableComponent} from 'ionic-selectable';

@Component({
  selector: 'app-selectable-value',
  templateUrl: './selectable-value.component.html',
  styleUrls: ['./selectable-value.component.scss'],
})
export class SelectableValueComponent {
  @Input()
  value: any;

  constructor(public selectable: IonicSelectableComponent) {}
}
