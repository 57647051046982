import _ from 'lodash';
import {DataDependency} from 'src/app/model/data-dependency';
import {DATA_DEPENDENCIES, STORAGE_KEY_TO_RAW_SYNC_KEYS} from 'src/app/shared/data-dependencies';
import {
  Activity,
  Attachment,
  AttachmentChat,
  AttachmentProtocolEntry,
  AttachmentReportActivity,
  AttachmentReportCompany,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  AttachmentReportSignature,
  ClientAwareKey,
  Employee,
  Equipment,
  IdAware,
  IdType,
  Material,
  NonClientAwareKey,
  NotChanged,
  NotificationConfigRecipient,
  PdfPlanMarker,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  PdfProtocolSetting,
  Project,
  ProjectAwareKey,
  ProjectCalendar,
  Protocol,
  ProtocolEntry,
  ProtocolEntryChat,
  ProtocolEntryCompany,
  ProtocolEntryDefaultValue,
  ProtocolEntryStatus,
  ProtocolEntryType,
  ProtocolLayout,
  ProtocolOpenEntry,
  ProtocolType,
  REGEXP_IS_NUMBER,
  Report,
  ReportCompany,
  ReportCompanyActivity,
  ReportWeek,
  Staff,
} from 'submodules/baumaster-v2-common';
import {v4 as uuid4} from 'uuid';
import {LocalChangesData} from '../../model/local-changes';
import {PROTOCOL_LAYOUT_NAME_CONTINUOUS, PROTOCOL_LAYOUT_NAME_STANDARD, PROTOCOL_TYPE_RECOVER_CODE, PROTOCOL_TYPE_RECOVER_NAME, StorageKeyEnum} from '../../shared/constants';
import {ProtocolEntryOrOpen} from '../../model/protocol';
import {convertAllToProtocolEntriesOrOpen, getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId} from '../../utils/entry-utils';

const STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD: Array<StorageKeyEnum> = [StorageKeyEnum.PROJECT];
const STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_PDF_PLAN_ID: Array<StorageKeyEnum> = [StorageKeyEnum.PDF_PLAN_VERSION];
const STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_PROTOCOL_ID: Array<StorageKeyEnum> = [StorageKeyEnum.PROTOCOL_ENTRY];
const STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_TYPE: Array<StorageKeyEnum> = [StorageKeyEnum.PROTOCOL];
const STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_PROTOCOL_ENTRY_ID: Array<StorageKeyEnum> = [StorageKeyEnum.PROTOCOL_ENTRY_CHAT];
const STORAGE_KEYS_WITH_UNIQUE_DATE_NAME_FIELD: Array<StorageKeyEnum> = [StorageKeyEnum.PROJECT_CALENDAR_DAY];
type StorageKeyFieldLevelMerge = StorageKeyEnum.PROTOCOL_ENTRY;
const STORAGE_KEYS_FIELD_LEVEL_MERGE_SUPPORTED: Record<StorageKeyFieldLevelMerge, true> = {protocolEntry: true};
const FIELD_LEVEL_MERGE_PROTOCOL_ENTRY_GROUPS: Array<Array<keyof ProtocolEntry>> = [
  ['companyId', 'internalAssignmentId', 'allCompanies'],
  ['typeId', 'status', 'isContinuousInfo'],
  ['protocolId', 'parentId', 'number'],
];
const FIELD_LEVEL_MERGE_PROTOCOL_ENTRY_GROUPS_BY_ID: Map<keyof ProtocolEntry, Array<keyof ProtocolEntry>> = convertFieldGroupsToMap(FIELD_LEVEL_MERGE_PROTOCOL_ENTRY_GROUPS);

function convertFieldGroupsToMap<T extends IdAware>(groups: Array<Array<keyof T>>): Map<keyof T, Array<keyof T>> {
  const map = new Map<keyof T, Array<keyof T>>();

  for (const group of groups) {
    for (const groupElement of group) {
      map.set(
        groupElement,
        group.filter((g) => g !== groupElement)
      );
    }
  }
  return map;
}

function assertNever(v: never, message: string) {
  return new Error(message);
}

export enum ConflictType {
  INCONSISTENT_NO_LOCAL_SERVER_NOT_MODIFIED = 'INCONSISTENT_NO_LOCAL_SERVER_NOT_MODIFIED',
  MODIFIED_LOCAL_AND_SERVER_SERVER_NEWER = 'MODIFIED_LOCAL_AND_SERVER_SERVER_NEWER',
  MODIFIED_LOCAL_AND_SERVER_LOCAL_NEWER = 'MODIFIED_LOCAL_AND_SERVER_LOCAL_NEWER',
  MODIFIED_LOCAL_AND_SERVER_MERGED_SERVER_NEWER = 'MODIFIED_LOCAL_AND_SERVER_MERGED_SERVER_NEWER',
  MODIFIED_LOCAL_AND_SERVER_MERGED_LOCAL_NEWER = 'MODIFIED_LOCAL_AND_SERVER_MERGED_LOCAL_NEWER',
  DELETED_LOCAL_MODIFIED_SERVER = 'DELETED_LOCAL_MODIFIED_SERVER',
  MODIFIED_LOCAL_DELETED_SERVER = 'MODIFIED_LOCAL_DELETED_SERVER',
  CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_MOVED = 'CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_MOVED',
  CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_PARTLY_REVERTED = 'CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_PARTLY_REVERTED',
  CONTINUOUS_PROTOCOL_CREATED_SERVER_AND_LOCAL = 'CONTINUOUS_PROTOCOL_CREATED_SERVER_AND_LOCAL',
  PROTOCOL_CLOSED_LOCAL_CHANGES_LOST = 'PROTOCOL_CLOSED_LOCAL_CHANGES_LOST',
  MODIFIED_LOCAL_DEPENDENCY_DELETED_SERVER = 'MODIFIED_LOCAL_DEPENDENCY_DELETED_SERVER',
  DELETED_LOCAL_DEPENDENCY_ADDED_SERVER = 'DELETED_LOCAL_DEPENDENCY_ADDED_SERVER',
  PROTOCOL_REMOVED_ENTRIES_RECOVERED = 'PROTOCOL_REMOVED_ENTRIES_RECOVERED',
  DELETED_LOCAL_MARKER_SERVER_MARKER_DIFFERENT_PLAN = 'DELETED_LOCAL_MARKER_SERVER_MARKER_DIFFERENT_PLAN',
  MARKER_NOT_UNIQUE_PER_PROTOCOL_ENTRY = 'MARKER_NOT_UNIQUE_PER_PROTOCOL_ENTRY',
  REPORT_CLOSED_LOCAL_CHANGES_LOST = 'REPORT_CLOSED_LOCAL_CHANGES_LOST',
  DELETED_LOCAL_DUPLICATES = 'DELETED_LOCAL_DUPLICATES',
  PROTOCOL_CLOSED_ENTRIES_RECOVERED = 'PROTOCOL_CLOSED_ENTRIES_RECOVERED',
  CHILD_ENTRY_ADDED_TO_MOVED_ENTRY_MOVE_DISCARDED = 'CHILD_ENTRY_ADDED_TO_MOVED_ENTRY_MOVE_DISCARDED',
  PDF_PROTOCOL_SETTING_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST = 'PDF_PROTOCOL_SETTING_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST',
  PROTOCOL_DEFAULT_VALUES_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST = 'PROTOCOL_DEFAULT_VALUES_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST',
  PROJECT_CALENDAR_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST = 'PROJECT_CALENDAR_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST',
  NOTIFICATION_CONFIG_RECIPIENT_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST = 'NOTIFICATION_CONFIG_RECIPIENT_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST',
}

// Order of the elements SyncStrategy is important.
export enum SyncStrategy {
  AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE = 1,
  DOWNLOADED_AND_PROJECTS_WITH_CHANGES = 2,
  CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES = 3,
  PROJECTS_WITH_CHANGES = 4,
}

export interface SyncSettings {
  attachmentStorage: 'filesytem' | 'cache';
}

export interface SyncConflict<T extends IdAware> {
  storageKey: StorageKeyEnum;
  type: ConflictType;
  id: IdType;
  localValue?: T;
  localValueBeforeMerge?: T;
  serverValue?: T | NotChanged;
  resolved: boolean;
  context?: any;
}

export interface SyncUtilRequest<T extends IdAware> {
  storageKey: StorageKeyEnum;
  localValues: Array<T>;
  localChangesData: LocalChangesData<T>;
  serverValues: Array<T | NotChanged>;
  localValuesNull: boolean;
}

export interface ChangedValue<T> {
  localValue: T;
  serverValue: T;
  onlyAttachmentFileChanged?: boolean;
}

export interface SyncUtilResponse<T extends IdAware> {
  storageKey: StorageKeyEnum;
  /**
   * The values, that will be stored in the key-value-store
   */
  syncedValues: Array<T>;
  /**
   * Values, that have been added on the server (values are also included in syncedValues)
   */
  newValues: Array<T>;
  /**
   * Values, that have been removed on the server (values have also been deleted in syncedValues)
   */
  deletedValues: Array<T>;
  /**
   * Values, that have been updated on the server. Changes are usually also syncedValues, unless the same value has been changed locally also
   */
  changedValues: Array<ChangedValue<T>>;
  /**
   * Only populated for attachments (undefined otherwise). Contains the fileSize of the attachment file on the server, grouped by id.
   */
  serverFileSizes: {[key in IdType]: number | null};
  /**
   * If there were any conflicts, automatically resolved or not.
   */
  conflict: boolean;
  /**
   * If there were conflicts, that could have been resolved automatically.
   */
  resolved: boolean;
  /**
   * Contains all conflicts, automatically resolved ones and others.
   */
  conflicts?: Array<SyncConflict<T>>;
  /**
   * Values that were not added by the user locally nor by someone on the server, but have been created by the sync process itself.
   */
  localChangesInsert: Array<T>;
  /**
   * Values that were not update by the user locally nor by someone on the server, but have been update by the sync process itself.
   */
  localChangesUpdate: Array<T>;
  /**
   * Values that were not deleted by the user locally nor by someone on the server, but have been deleted by the sync process itself.
   */
  localChangesDelete: Array<T>;
  /**
   * Local changes that are not valid anymore and should be removed from the local store.
   */
  localChangesRemove: Array<IdType>;
  /**
   * The local changes this sync was the basis of. Coped from SyncUtilRequest
   */
  localChangesData: LocalChangesData<T>;
}

export function deepEqualExcept<T extends IdAware>(o1: T, o2: T, ...keysToIgnore: Array<string>): boolean {
  const keys = _.uniq(Object.keys(o1).concat(Object.keys(o2)));
  for (const key of keys) {
    if (keysToIgnore.find((keyToIgnore) => keyToIgnore === key)) {
      continue;
    }
    if (((o1[key] === undefined || o1[key] === null) && (o2[key] === undefined || o2[key] === null)) || o1[key] === o2[key]) {
      continue;
    }
    return false;
  }
  return true;
}

export function deepEqualExceptChangedAt<T extends IdAware>(o1: T, o2: T): boolean {
  return deepEqualExcept(o1, o2, 'changedAt', 'createdAt');
}

function deepEqualExceptChangedAtAndFileChangedAt<T extends IdAware>(o1: T, o2: T): boolean {
  return deepEqualExcept(o1, o2, 'changedAt', 'createdAt', 'fileChangedAt');
}

function deepEqualExceptChangedAtAndFileChangedAtAndFilePaths<T extends IdAware>(o1: T, o2: T): boolean {
  return deepEqualExcept(o1, o2, 'changedAt', 'createdAt', 'fileChangedAt', 'filePath', 'thumbnailPath', 'bigThumbnailPath', 'mediumThumbnailPath');
}

export function notEqualKeysExcept<T extends IdAware>(o1: T, o2: T, ...keysToIgnore: Array<keyof T>): Array<keyof T> {
  const keys = _.uniq(Object.keys(o1).concat(Object.keys(o2))) as Array<keyof T>;
  const keysNotEqual = new Array<keyof T>();
  for (const key of keys) {
    if (keysToIgnore.includes(key)) {
      continue;
    }
    if (!isPropertyEqual(o1, o2, key)) {
      keysNotEqual.push(key);
    }
  }
  return keysNotEqual;
}

function isPropertyEqual<T extends IdAware>(o1: T, o2: T, key: keyof T): boolean {
  return ((o1[key] === undefined || o1[key] === null) && (o2[key] === undefined || o2[key] === null)) || o1[key] === o2[key];
}

function compareDates(date1: string | Date, date2: string | Date): number {
  const date1AsDate: Date = _.isDate(date1) ? (date1 as Date) : new Date(date1);
  const date2AsDate: Date = _.isDate(date2) ? (date2 as Date) : new Date(date2);
  if (date1AsDate.getTime() === date2AsDate.getTime()) {
    return 0;
  }
  return date1AsDate.getTime() > date2AsDate.getTime() ? 1 : -1;
}

function isAfter(date1: string | Date, date2: string | Date): boolean {
  return compareDates(date1, date2) > 0;
}

function isEqualDate(date1: string | Date | null | undefined, date2: string | Date | null | undefined): boolean {
  if (!date1 || !date2) {
    return false;
  }
  return compareDates(date1, date2) === 0;
}

function equalOrBothNullOrUndefined<T>(value: T | undefined | null, other: T | undefined | null): boolean {
  if ((value === null || value === undefined) && (other === null || other === undefined)) {
    return true;
  }
  return value === other;
}

export async function syncDataArray<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  syncRequests: {[key in K]: SyncUtilRequest<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>},
  clientOrProjectId?: IdType
): Promise<{[key in K]: SyncUtilResponse<T>}> {
  const result = {};
  for (const key of Object.keys(syncRequests)) {
    result[key] = await syncData(syncRequests[key]);
  }

  // First try to recover lost protocol entries (non-destructive)
  if (_.has(result, 'protocols') && _.has(result, 'protocolEntries')) {
    await recoverProtocolData(result, nonClientAwareResult, clientAwareResult, clientOrProjectId);
  }

  // Then ensure data integrity (destructive for mutated objects in the local storage that does not have all dependencies)
  await ensureAndFixDataIntegrity(result, nonClientAwareResult, clientAwareResult);

  if (_.has(result, 'protocols') && _.has(result, 'protocolEntries')) {
    const protocolsRequest = _.get(syncRequests, 'protocols') as SyncUtilRequest<Protocol>;
    const protocolsResponse: SyncUtilResponse<Protocol> = _.get(result, 'protocols');
    const protocolEntriesRequest = _.get(syncRequests, 'protocolEntries') as SyncUtilRequest<ProtocolEntry>;
    const protocolEntriesResponse: SyncUtilResponse<ProtocolEntry> = _.get(result, 'protocolEntries');
    const protocolEntryChatsRequest = _.get(syncRequests, 'protocolEntryChats') as SyncUtilRequest<ProtocolEntryChat>;
    const protocolEntryChatsResponse: SyncUtilResponse<ProtocolEntryChat> = _.get(result, 'protocolEntryChats');
    const protocolEntryCompaniesRequest = _.get(syncRequests, 'protocolEntryCompanies') as SyncUtilRequest<ProtocolEntryCompany>;
    const protocolEntryCompaniesResponse: SyncUtilResponse<ProtocolEntryCompany> = _.get(result, 'protocolEntryCompanies');
    const attachmentProtocolEntriesRequest = _.get(syncRequests, 'attachmentProtocolEntries') as SyncUtilRequest<AttachmentProtocolEntry>;
    const attachmentProtocolEntriesResponse: SyncUtilResponse<AttachmentProtocolEntry> = _.get(result, 'attachmentProtocolEntries');
    const attachmentChatsRequest = _.get(syncRequests, 'attachmentChats') as SyncUtilRequest<AttachmentChat>;
    const attachmentChatsResponse: SyncUtilResponse<AttachmentChat> = _.get(result, 'attachmentChats');
    const pdfPlanPagesRequest = _.get(syncRequests, 'pdfPlanPages') as SyncUtilRequest<PdfPlanPage>;
    const pdfPlanPagesResponse: SyncUtilResponse<PdfPlanPage> = _.get(result, 'pdfPlanPages');
    const pdfPlanMarkerProtocolEntriesRequest = _.get(syncRequests, 'pdfPlanMarkerProtocolEntries') as SyncUtilRequest<PdfPlanMarkerProtocolEntry>;
    const pdfPlanMarkerProtocolEntriesResponse: SyncUtilResponse<PdfPlanMarkerProtocolEntry> = _.get(result, 'pdfPlanMarkerProtocolEntries');
    const pdfPlanPageMarkingsRequest = _.get(syncRequests, 'pdfPlanPageMarkings') as SyncUtilRequest<PdfPlanPageMarking>;
    const pdfPlanPageMarkingsResponse: SyncUtilResponse<PdfPlanPageMarking> = _.get(result, 'pdfPlanPageMarkings');
    const protocolOpenEntriesRequestRequest = _.get(syncRequests, 'protocolOpenEntries') as SyncUtilRequest<ProtocolOpenEntry>;
    const protocolOpenEntriesRequestResponse: SyncUtilResponse<ProtocolOpenEntry> = _.get(result, 'protocolOpenEntries');
    const protocolTypesRequestResponse: SyncUtilResponse<ProtocolType> = clientAwareResult.protocolTypes;
    const protocolTypes = clientAwareResult.protocolTypes.syncedValues;
    const protocolEntryTypes = clientAwareResult.protocolEntryTypes.syncedValues;
    const protocolLayouts = clientAwareResult.protocolLayouts.syncedValues;
    const projectsResponse: SyncUtilResponse<Project> = clientAwareResult.projects;
    const syncProtocols = new SyncProtocols(
      protocolsRequest,
      protocolsResponse,
      protocolEntriesRequest,
      protocolEntriesResponse,
      protocolEntryChatsRequest,
      protocolEntryChatsResponse,
      attachmentProtocolEntriesRequest,
      attachmentProtocolEntriesResponse,
      attachmentChatsRequest,
      attachmentChatsResponse,
      pdfPlanPagesRequest,
      pdfPlanPagesResponse,
      pdfPlanMarkerProtocolEntriesRequest,
      pdfPlanMarkerProtocolEntriesResponse,
      protocolOpenEntriesRequestRequest,
      protocolOpenEntriesRequestResponse,
      protocolTypes,
      protocolEntryTypes,
      protocolLayouts,
      projectsResponse,
      protocolEntryCompaniesRequest,
      protocolEntryCompaniesResponse,
      pdfPlanPageMarkingsRequest,
      pdfPlanPageMarkingsResponse,
      protocolTypesRequestResponse,
      clientOrProjectId
    );
    syncProtocols.sync();
  }
  if (_.has(result, 'reports') && _.has(result, 'reportWeeks')) {
    const reportsResponse: SyncUtilResponse<Report> = _.get(result, 'reports');
    const reportWeeksResponse: SyncUtilResponse<ReportWeek> = _.get(result, 'reportWeeks');

    const syncReports = new SyncReports(syncRequests, result as {[key in K]: SyncUtilResponse<T>});
    syncReports.sync();

    const reportWeeksById = _.keyBy(reportWeeksResponse.syncedValues, 'id') as {[key in IdType]: ReportWeek};
    const compareFn = (value: Report, other: Report): boolean => {
      return (
        reportWeeksById[value.reportWeekId] &&
        reportWeeksById[other.reportWeekId] &&
        reportWeeksById[value.reportWeekId].typeId === reportWeeksById[other.reportWeekId].typeId &&
        equalOrBothNullOrUndefined(reportWeeksById[value.reportWeekId].customReportTypeId, reportWeeksById[other.reportWeekId].customReportTypeId)
      );
    };
    ensureNumbersAreSetAndUniqueByCompareFunction(reportsResponse.syncedValues, reportsResponse.localChangesData.insert, compareFn, 'reportNumber');
    ensureNumbersAreSetAndUniqueByCompareFunction(reportsResponse.syncedValues, reportsResponse.localChangesData.update, compareFn, 'reportNumber');
  }
  if (_.has(result, 'pdfPlanMarkerProtocolEntries') && _.has(result, 'pdfPlanPageMarkings')) {
    const pdfPlanMarkerProtocolEntriesResponse: SyncUtilResponse<PdfPlanMarkerProtocolEntry> = _.get(result, 'pdfPlanMarkerProtocolEntries');
    const pdfPlanPageMarkingsResponse: SyncUtilResponse<PdfPlanPageMarking> = _.get(result, 'pdfPlanPageMarkings');
    const pdfPlanPages: SyncUtilResponse<PdfPlanPage> = _.get(result, 'pdfPlanPages');
    const pdfPlanVersions: SyncUtilResponse<PdfPlanVersion> = _.get(result, 'pdfPlanVersions');
    ensureMarkersForTheSamePlan(pdfPlanMarkerProtocolEntriesResponse, pdfPlanPageMarkingsResponse, pdfPlanVersions, pdfPlanPages);
    ensureMarkersUniquePerPdfPlanAndProtocolEntry(pdfPlanMarkerProtocolEntriesResponse, pdfPlanVersions, pdfPlanPages);
    ensureMarkersUniquePerPdfPlanAndProtocolEntry(pdfPlanPageMarkingsResponse, pdfPlanVersions, pdfPlanPages);
  }
  if (_.has(result, 'pdfProtocolSettings')) {
    const syncPdfProtocolSettings = new SyncPdfProtocolSettings(syncRequests, result as {[key in K]: SyncUtilResponse<T>});
    syncPdfProtocolSettings.sync();
  }

  if (_.has(result, 'projectCalendars')) {
    const syncProjectCalendar = new SyncProjectCalendar(syncRequests, result as {[key in K]: SyncUtilResponse<T>});
    syncProjectCalendar.sync();
  }

  if (_.has(result, 'notificationConfigRecipients')) {
    const syncnotificationConfigRecipient = new SyncNotificationConfigRecipient(syncRequests, result as {[key in K]: SyncUtilResponse<T>});
    syncnotificationConfigRecipient.sync();
  }

  if (_.has(result, 'protocolEntryDefaultValues')) {
    const syncDefaultValues = new SyncProtocolEntryDefaultValues(syncRequests, result as {[key in K]: SyncUtilResponse<T>});
    syncDefaultValues.sync();
  }

  return result as {[key in K]: SyncUtilResponse<T>};
}

export function checkObjectDataDependencyReferences<T extends IdAware>(
  onReferenceFound: (targetReference: IdAware) => boolean,
  dataDependency: DataDependency,
  objectToCheck: T,
  objects: IdAware[]
): void {
  for (const target of objects) {
    if (dataDependency.optional && (target[dataDependency.destinationKeyPath] === null || target[dataDependency.destinationKeyPath] === undefined)) {
      continue;
    }

    if (objectToCheck[dataDependency.sourceKeyPath] === target[dataDependency.destinationKeyPath]) {
      if (onReferenceFound(target) === true) {
        return;
      }
    }
  }
}

export function doesObjectHasAnyDataDependencyReference<T extends IdAware>(dataDependency: DataDependency, objectToCheck: T, objects: IdAware[]): boolean {
  let result = false;

  checkObjectDataDependencyReferences(() => (result = true), dataDependency, objectToCheck, objects);

  return result;
}

export function getAllObjectDataDependencyReferences<T extends IdAware>(dataDependency: DataDependency, objectToCheck: T, objects: IdAware[]): IdAware[] {
  const references = [];

  checkObjectDataDependencyReferences(
    (reference) => {
      references.push(reference);
      return false;
    },
    dataDependency,
    objectToCheck,
    objects
  );

  return references;
}

interface ReferencesForDependency {
  dataDependency: DataDependency;
  hasValueInSource?: boolean;
  references: IdAware[];
}

type ActInsertUpdateIntegrityFunction<T extends IdAware> = (
  dataDependencies: DataDependency[],
  objectsWithCorrectDeps: T[],
  objectsWithMissingDeps: {
    obj: T;
    missingDeps: ReferencesForDependency[];
  }[]
) => Promise<void>;

async function checkAndActSingleInsertUpdateDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  actIfChanged: ActInsertUpdateIntegrityFunction<T>,
  syncKey: K,
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
): Promise<void> {
  const response = result[syncKey];
  const localChangesData = response?.localChangesData;

  if (!localChangesData) {
    console.log(`checkAndActSingleInsertUpdateDataIntegrity - Unable to ensure dataIntegrity for syncKey "${syncKey}" as it does not exist in the response.`);
    return;
  }

  if (localChangesData.insert.length === 0 && localChangesData.update.length === 0) {
    return;
  }

  const dataDependencies = DATA_DEPENDENCIES.get(response.storageKey);
  if (!dataDependencies || dataDependencies.length === 0) {
    return;
  }

  const checkData = [...localChangesData.insert, ...localChangesData.update];

  const objectsWithCorrectDeps: T[] = [];
  const objectsWithMissingDeps: {
    obj: T;
    missingDeps: ReferencesForDependency[];
  }[] = [];
  let changed = false;

  const allSyncUtilResponses = [...Object.values(result), ...Object.values(nonClientAwareResult ?? {}), ...Object.values(clientAwareResult ?? {})] as SyncUtilResponse<any>[];

  for (const objectToCheck of checkData) {
    const referencesPerDependency: ReferencesForDependency[] = (
      await Promise.all(
        dataDependencies.filter(({direction}) => direction === 'SOURCE_TO_DESTINATION').map((dataDependency) => getReferencesForDataDependency<T>(objectToCheck, dataDependency, allSyncUtilResponses))
      )
    ).filter((ref) => ref !== null);

    if (referencesPerDependency.some(({dataDependency, hasValueInSource, references}) => references.length === 0 && (!dataDependency.optional || hasValueInSource))) {
      changed = true;

      const emptyReferences = referencesPerDependency.filter(({references}) => references.length === 0);
      objectsWithMissingDeps.push({
        obj: objectToCheck,
        missingDeps: emptyReferences,
      });

      const emptyReferencesToPrint = emptyReferences
        .filter(({dataDependency}) => !dataDependency.optional || objectToCheck[dataDependency.sourceKeyPath])
        .map(({dataDependency}) => `${dataDependency.destinationStorageKey}(${dataDependency.destinationKeyPath}=${objectToCheck[dataDependency.sourceKeyPath]})`)
        .join('\n');
      const message = `Object ${response.storageKey}(id=${
        objectToCheck.id
      }) has missing dependency. Removing this object/preventing adding this object. Missing dependencies:\n${emptyReferencesToPrint}`;

      console.warn(message);

      continue;
    }

    objectsWithCorrectDeps.push(objectToCheck);
  }

  if (changed) {
    await actIfChanged(dataDependencies, objectsWithCorrectDeps, objectsWithMissingDeps);
  }
}

async function getReferencesForDataDependency<T extends IdAware>(objectToCheck: T, dataDependency: DataDependency, allSyncUtilResponses: SyncUtilResponse<any>[]): Promise<ReferencesForDependency> {
  const destinationResponse = allSyncUtilResponses.find((r) => r.storageKey === dataDependency.destinationStorageKey);

  if (!destinationResponse) {
    // Shouldn't happen; silently skip this data dependency, due to the missing destination data list
    return null;
  }

  const destinationData = destinationResponse.syncedValues;

  return {
    dataDependency,
    hasValueInSource: Boolean(objectToCheck[dataDependency.sourceKeyPath]),
    references: _.flattenDeep(getAllObjectDataDependencyReferences(dataDependency, objectToCheck, destinationData)) as IdAware[],
  };
}

async function ensureAndFixSingleInsertUpdateDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  syncKey: K,
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
): Promise<void> {
  const handleActIfChange: ActInsertUpdateIntegrityFunction<T> = async (dataDependencies, objectsToBeKept, objectsWithDepsToBeRemoved) => {
    const objectsToBeRemoved = objectsWithDepsToBeRemoved.map(({obj}) => obj);
    const response = result[syncKey];
    const localChangesData = response?.localChangesData;

    if (!localChangesData) {
      console.log(`ensureAndFixSingleInsertUpdateDataIntegrity - Unable to ensure dataIntegrity for syncKey "${syncKey}" as it does not exist in the response.`);
      return;
    }

    localChangesData.update = localChangesData.update.filter((change) => objectsToBeKept.some((value) => value.id === change.id));
    localChangesData.insert = localChangesData.insert.filter((change) => objectsToBeKept.some((value) => value.id === change.id));
    response.localChangesDelete.push(...objectsToBeRemoved);
    response.localChangesRemove.push(...objectsToBeRemoved.map(({id}) => id));
    if (localChangesData.localChangesInsertById) {
      localChangesData.localChangesInsertById.forEach((__, id) => {
        if (objectsToBeRemoved.some((value) => value.id === id)) {
          localChangesData.localChangesInsertById.delete(id);
        }
      });
    }
    if (localChangesData.localChangesUpdateById) {
      localChangesData.localChangesUpdateById.forEach((__, id) => {
        if (objectsToBeRemoved.some((value) => value.id === id)) {
          localChangesData.localChangesUpdateById.delete(id);
        }
      });
    }
    objectsToBeRemoved.forEach((obj) => {
      _.remove(response.syncedValues, (syncValue) => syncValue.id === obj.id);
    });
    response.conflict = true;
    response.resolved = response.resolved && true;
    if (!response.conflicts) {
      response.conflicts = [];
    }

    response.conflicts.push(
      ...objectsToBeRemoved.map<SyncConflict<T>>((obj) => ({
        id: obj.id,
        resolved: true,
        storageKey: response.storageKey,
        type: ConflictType.MODIFIED_LOCAL_DEPENDENCY_DELETED_SERVER,
        localValue: obj,
      }))
    );

    await Promise.all(
      dataDependencies
        .filter(({direction}) => direction === 'DESTINATION_TO_SOURCE')
        .map(async (dataDependency) => {
          // We've deleted some objects; now we need to check the integrity of destination dependencies
          // (removal of those object might have created missing dependencies with objects to be created/updated)
          await ensureAndFixSingleInsertUpdateDataIntegrity(STORAGE_KEY_TO_RAW_SYNC_KEYS[dataDependency.destinationStorageKey], result, nonClientAwareResult, clientAwareResult);
        })
    );
  };

  await checkAndActSingleInsertUpdateDataIntegrity(handleActIfChange, syncKey, result, nonClientAwareResult, clientAwareResult);
}

async function ensureAndFixInsertUpdateDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
) {
  for (const key of Object.keys(result) as K[]) {
    await ensureAndFixSingleInsertUpdateDataIntegrity(key, result, nonClientAwareResult, clientAwareResult);
  }
}

type ActDeleteIntegrityFunction<T extends IdAware> = (dataDependencies: DataDependency[], objectsWithNoDeps: T[], objectsWithDeps: T[]) => Promise<void>;

async function checkAndActSingleDeleteDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  actIfChanged: ActDeleteIntegrityFunction<T>,
  syncKey: K,
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
): Promise<void> {
  const response = result[syncKey];
  const localChangesData = response?.localChangesData;

  if (!localChangesData) {
    console.log(`checkAndActSingleDeleteDataIntegrity - Unable to ensure dataIntegrity for syncKey "${syncKey}" as it does not exist in the response.`);
    return;
  }

  if (localChangesData.delete.length === 0) {
    return;
  }

  const dataDependencies = DATA_DEPENDENCIES.get(response.storageKey);
  if (!dataDependencies || dataDependencies.length === 0) {
    return;
  }

  const checkData = localChangesData.delete;

  const objectsWithNoDeps: T[] = [];
  const objectsWithDeps: T[] = [];
  let changed = false;

  const allSyncUtilResponses = [...Object.values(result), ...Object.values(nonClientAwareResult ?? {}), ...Object.values(clientAwareResult ?? {})] as SyncUtilResponse<any>[];

  for (let objectToCheck of checkData) {
    objectToCheck = getObjectFromSyncConflictWhenItIsNewer(objectToCheck.id, response) ?? objectToCheck;
    const referencesPerDependency = (
      await Promise.all(
        dataDependencies.filter(({direction}) => direction === 'DESTINATION_TO_SOURCE').map((dataDependency) => getReferencesForDataDependency<T>(objectToCheck, dataDependency, allSyncUtilResponses))
      )
    ).filter((ref) => ref !== null);

    if (referencesPerDependency.some(({references}) => references.length > 0)) {
      changed = true;
      objectsWithDeps.push(objectToCheck);

      const notEmptyReferences = referencesPerDependency.filter(({references}) => references.length > 0);

      const referencesToPrint = notEmptyReferences
        .map(({references, dataDependency}) => references.map((reference) => `${dataDependency.destinationStorageKey}(id=${reference.id}) at ${dataDependency.destinationKeyPath}`).join('\n'))
        .join('\n');
      const message = `Object ${response.storageKey}(id=${objectToCheck.id}) will not be removed, it has still bound dependencies:\n${referencesToPrint}`;

      console.warn(message);

      continue;
    }

    objectsWithNoDeps.push(objectToCheck);
  }

  if (changed) {
    await actIfChanged(dataDependencies, objectsWithNoDeps, objectsWithDeps);
  }
}

function getObjectFromSyncConflictWhenItIsNewer<T extends IdAware>(id: IdType, syncResponse: SyncUtilResponse<T>): T | undefined {
  if (!syncResponse.conflicts?.length) {
    return undefined;
  }
  const conflict = syncResponse.conflicts.find(
    (c) =>
      c.id === id &&
      (c.type === 'MODIFIED_LOCAL_AND_SERVER_LOCAL_NEWER' ||
        c.type === 'MODIFIED_LOCAL_AND_SERVER_MERGED_LOCAL_NEWER' ||
        c.type === 'MODIFIED_LOCAL_AND_SERVER_MERGED_SERVER_NEWER' ||
        c.type === 'DELETED_LOCAL_MODIFIED_SERVER')
  );
  if (!conflict?.serverValue) {
    return undefined;
  }
  if ('notChanged' in conflict.serverValue && conflict.serverValue.notChanged) {
    return undefined; // that should never be the case as the conflict types listed above should always have a serverValue but need to handle it anyway.
  }

  console.log(`getObjectFromSyncConflictWhenItIsNewer - Need to undo local change for id ${id} but replace it with serverValue.`);
  return conflict.serverValue as T;
}

async function ensureAndFixSingleDeleteDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  syncKey: K,
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
): Promise<void> {
  const handleActIfChange: ActDeleteIntegrityFunction<T> = async (dataDependencies, objectsToBeStillRemoved, objectsToBeRecovered) => {
    const response = result[syncKey];
    const localChangesData = response?.localChangesData;
    const localChangesRemove = response?.localChangesRemove;

    if (!localChangesData || !localChangesRemove) {
      console.log(`ensureAndFixSingleDeleteDataIntegrity - Unable to ensure dataIntegrity for syncKey "${syncKey}" as it does not exist in the response.`);
      return;
    }

    localChangesRemove.push(...objectsToBeRecovered.map(({id}) => id));
    localChangesData.delete = localChangesData.delete.filter((change) => objectsToBeStillRemoved.some((value) => value.id === change.id));
    if (localChangesData.localChangesDeleteById) {
      localChangesData.localChangesDeleteById.forEach((__, id) => {
        if (!objectsToBeStillRemoved.some((value) => value.id === id)) {
          localChangesData.localChangesDeleteById.delete(id);
        }
      });
    }
    response.syncedValues.push(...objectsToBeRecovered);
    response.conflict = true;
    response.resolved = response.resolved && true;
    if (!response.conflicts) {
      response.conflicts = [];
    }

    response.conflicts.push(
      ...objectsToBeRecovered.map<SyncConflict<T>>((obj) => ({
        id: obj.id,
        resolved: true,
        storageKey: response.storageKey,
        type: ConflictType.DELETED_LOCAL_DEPENDENCY_ADDED_SERVER,
        localValue: obj,
      }))
    );

    await Promise.all(
      dataDependencies
        .filter(({direction}) => direction === 'DESTINATION_TO_SOURCE')
        .map(async (dataDependency) => {
          // We've prevented deletion of some objects; now we need to check the integrity of destination dependencies
          // (existence of those object might have created bounds with objects to be deleted)
          await ensureAndFixSingleDeleteDataIntegrity(STORAGE_KEY_TO_RAW_SYNC_KEYS[dataDependency.destinationStorageKey], result, nonClientAwareResult, clientAwareResult);
        })
    );
  };

  await checkAndActSingleDeleteDataIntegrity(handleActIfChange, syncKey, result, nonClientAwareResult, clientAwareResult);
}

async function ensureAndFixDeleteDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
) {
  for (const key of Object.keys(result) as K[]) {
    await ensureAndFixSingleDeleteDataIntegrity(key, result, nonClientAwareResult, clientAwareResult);
  }
}

async function ensureAndFixDataIntegrity<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>}
) {
  await ensureAndFixInsertUpdateDataIntegrity(result, nonClientAwareResult, clientAwareResult);
  await ensureAndFixDeleteDataIntegrity(result, nonClientAwareResult, clientAwareResult);
}

async function recoverProtocolData<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey>(
  result: {[key in K]: SyncUtilResponse<T>},
  nonClientAwareResult?: {[N in NonClientAwareKey]: SyncUtilResponse<any>},
  clientAwareResult?: {[N in ClientAwareKey]: SyncUtilResponse<any>},
  clientOrProjectId?: IdType
) {
  const allProtocolEntriesWithNoParent: ProtocolEntry[] = [];
  await checkAndActSingleInsertUpdateDataIntegrity(
    async (dataDependencies, objectsWithCorrectDeps, objectsWithMissingDeps) => {
      allProtocolEntriesWithNoParent.push(
        ...objectsWithMissingDeps
          // Fix only protocol entries that became orphans due to the missing protocol
          .filter(
            ({missingDeps}) =>
              // We care only about the dependencies that are either required or optional with a value
              missingDeps.filter(({dataDependency: {optional}, hasValueInSource}) => !optional || (optional && hasValueInSource)).length === 1 &&
              missingDeps[0].dataDependency.destinationStorageKey === StorageKeyEnum.PROTOCOL
          )
          .map(({obj}) => obj as IdAware as ProtocolEntry)
      );
    },
    'protocolEntries' as K,
    result,
    nonClientAwareResult,
    clientAwareResult
  );

  if (allProtocolEntriesWithNoParent.length === 0) {
    return;
  }

  const protocolEntries: SyncUtilResponse<ProtocolEntry> = (result as {[key: string]: SyncUtilResponse<any>}).protocolEntries;

  if (
    allProtocolEntriesWithNoParent.every((entry) => !protocolEntries.localChangesData.localChangesInsertById.has(entry.id) && !protocolEntries.localChangesData.insert.some((e) => e.id === entry.id))
  ) {
    // All entries with no parent are coming from update; skipping...
    return;
  }

  const protocolEntriesWithNoParent = allProtocolEntriesWithNoParent.filter(
    (entry) => protocolEntries.localChangesData.localChangesInsertById.has(entry.id) && protocolEntries.localChangesData.insert.some((e) => e.id === entry.id)
  );

  const changedAtDate = new Date();

  const protocolLayouts: SyncUtilResponse<ProtocolLayout> = clientAwareResult.protocolLayouts;
  const protocolTypes: SyncUtilResponse<ProtocolType> = clientAwareResult.protocolTypes;
  const projects: SyncUtilResponse<Project> = clientAwareResult.projects;
  const protocols: SyncUtilResponse<Protocol> = (result as {[key: string]: SyncUtilResponse<any>}).protocols;

  const recoveryProtocol: Protocol = getRecoveryProtocol(projects.syncedValues, clientOrProjectId, protocolLayouts.syncedValues, protocolTypes.syncedValues, protocols.syncedValues, changedAtDate);

  protocols.syncedValues.push(recoveryProtocol);
  protocols.localChangesInsert.push(recoveryProtocol);

  protocolEntries.conflict = true;
  protocolEntries.resolved = protocolEntries.resolved && true;
  if (!protocolEntries.conflicts) {
    protocolEntries.conflicts = [];
  }

  protocolEntries.conflicts.push(
    ...protocolEntriesWithNoParent.map((entry) => ({
      id: entry.id,
      resolved: true,
      storageKey: protocolEntries.storageKey,
      type: ConflictType.PROTOCOL_REMOVED_ENTRIES_RECOVERED,
      localValue: entry,
      context: {recoveryProtocol},
    }))
  );

  protocolEntriesWithNoParent.forEach((entry, index) => {
    entry.protocolId = recoveryProtocol.id;
    entry.number = index + 1;

    const localEntryInsert = protocolEntries.localChangesData.localChangesInsertById.get(entry.id);
    if (localEntryInsert?.value) {
      localEntryInsert.value.protocolId = recoveryProtocol.id;
      localEntryInsert.value.number = index + 1;
    }
    const localEntryUpdate = protocolEntries.localChangesData.localChangesUpdateById.get(entry.id);
    if (localEntryUpdate?.value) {
      localEntryUpdate.value.protocolId = recoveryProtocol.id;
      localEntryUpdate.value.number = index + 1;
    }

    protocolEntries.localChangesData.insert.forEach((theLocalEntry) => {
      if (theLocalEntry.id === entry.id) {
        theLocalEntry.protocolId = recoveryProtocol.id;
        theLocalEntry.number = index + 1;
      }
    });
    protocolEntries.localChangesData.update.forEach((theLocalEntry) => {
      if (theLocalEntry.id === entry.id) {
        theLocalEntry.protocolId = recoveryProtocol.id;
        theLocalEntry.number = index + 1;
      }
    });
  });
}

function getRecoveryProtocol(
  projects: Array<Project>,
  clientOrProjectId: IdType | undefined,
  protocolLayouts: Array<ProtocolLayout>,
  protocolTypes: Array<ProtocolType>,
  protocols: Array<Protocol>,
  changedAtDate: Date
) {
  const changedAt = changedAtDate.toISOString();

  const project = projects.find((theProject) => theProject.id === clientOrProjectId);

  const standardProtocolLayout = protocolLayouts.find((layout) => layout.name === PROTOCOL_LAYOUT_NAME_STANDARD);
  const recoverProtocolType = protocolTypes.find((type) => type.name === PROTOCOL_TYPE_RECOVER_NAME && type.code === PROTOCOL_TYPE_RECOVER_CODE);
  const firstStandardProtocolType = protocolTypes.find((type) => type.layoutId === standardProtocolLayout.id);
  const protocolType = recoverProtocolType ?? firstStandardProtocolType;

  const protocolsInFirstProtocolType: Protocol[] = _.sortBy(
    protocols.filter((protocol) => protocol.typeId === protocolType.id),
    ['number']
  ).reverse();

  // dd.MM.yyyy HH:mm
  const formatDate = (date: Date) =>
    `${_.padStart('' + date.getDate(), 2, '0')}.${_.padStart('' + (date.getMonth() + 1), 2, '0')}.${_.padStart('' + date.getFullYear(), 4, '0')} ${_.padStart(
      '' + date.getHours(),
      2,
      '0'
    )}:${_.padStart('' + date.getMinutes(), 2, '0')}`;

  const recoveryProtocol: Protocol = {
    id: uuid4(),
    changedAt,
    createdAt: changedAt,
    date: changedAt,
    typeId: protocolType.id,
    ownerClientId: project.clientId,
    projectId: project.id,
    number: Math.max(recoverProtocolType ? 0 : 900, protocolsInFirstProtocolType[0]?.number ?? 0) + 1,
    includesVat: false,
    closedAt: null,
    name: `Recover ${formatDate(changedAtDate)}`,
  };

  return recoveryProtocol;
}

function getUniquePdfPlanIdsForMarkers<T extends PdfPlanMarkerProtocolEntry | PdfPlanPageMarking>(
  entries: Array<T>,
  pdfPlanVersions: Array<PdfPlanVersion>,
  pdfPlanPages: Array<PdfPlanPage>
): Array<IdType> {
  const pdfPlanVersionIds: Array<IdType> = _.union(_.compact(entries.map((v) => pdfPlanPages.find((pdfPlanPage) => pdfPlanPage.id === v.pdfPlanPageId)?.pdfPlanVersionId)));
  const pdfPlanIds: Array<IdType> = _.union(_.compact(pdfPlanVersions.filter((pdfPlanVersion) => pdfPlanVersionIds.includes(pdfPlanVersion.id)).map((pdfPlanVersion) => pdfPlanVersion.pdfPlanId)));
  return pdfPlanIds;
}

function getUniquePdfPlanIdsForMarkersByProtocolEntryId<T extends PdfPlanMarkerProtocolEntry | PdfPlanPageMarking>(
  entries: Array<T>,
  pdfPlanVersionResponse: SyncUtilResponse<PdfPlanVersion>,
  pdfPlanPageResponse: SyncUtilResponse<PdfPlanPage>
): Map<IdType, Array<IdType>> {
  const {pdfPlanPages: latestPdfPlanPages, pdfPlanVersions: latestPdfPlanVersions} = filterLatestPdfPlanVersionAndPages(pdfPlanVersionResponse.syncedValues, pdfPlanPageResponse.syncedValues);
  const latestPdfPlanPageIds = latestPdfPlanPages.map((value) => value.id);
  const markersOfLatestVersion = entries.filter((entry) => latestPdfPlanPageIds.includes(entry.pdfPlanPageId));
  const entriesGroupedByProtocolEntryId: Record<IdType, Array<T>> = _.groupBy(
    markersOfLatestVersion.filter((entry) => entry.protocolEntryId),
    'protocolEntryId'
  );
  const result = new Map<IdType, Array<IdType>>();
  for (const [protocolEntryId, values] of Object.entries(entriesGroupedByProtocolEntryId)) {
    result.set(protocolEntryId, getUniquePdfPlanIdsForMarkers(values, latestPdfPlanVersions, latestPdfPlanPages));
  }
  return result;
}

function getPdfPlanIdForPdfPlanPageId(pdfPlanPageId: IdType, pdfPlanVersionResponse: SyncUtilResponse<PdfPlanVersion>, pdfPlanPageResponse: SyncUtilResponse<PdfPlanPage>): IdType | undefined {
  const matchingPdfPlanPage = pdfPlanPageResponse.syncedValues.find((pdfPlanPage) => pdfPlanPage.id === pdfPlanPageId);
  if (!matchingPdfPlanPage) {
    return undefined;
  }
  return pdfPlanVersionResponse.syncedValues.find((pdfPlanVersion) => pdfPlanVersion.id === matchingPdfPlanPage.pdfPlanVersionId)?.pdfPlanId;
}

function filterLatestPdfPlanVersionAndPages(pdfPlanVersions: Array<PdfPlanVersion>, pdfPlanPages: Array<PdfPlanPage>): {pdfPlanVersions: Array<PdfPlanVersion>; pdfPlanPages: Array<PdfPlanPage>} {
  const latestPdfPlanVersions = filterLatestPdfPlanVersions(pdfPlanVersions);
  const latestPdfPlanVersionIds = latestPdfPlanVersions.map((value) => value.id);
  const latestPdfPlanPages = pdfPlanPages.filter((pdfPlanPage) => latestPdfPlanVersionIds.includes(pdfPlanPage.pdfPlanVersionId));
  return {
    pdfPlanVersions: latestPdfPlanVersions,
    pdfPlanPages: latestPdfPlanPages,
  };
}

function filterLatestPdfPlanVersions(pdfPlanVersions: Array<PdfPlanVersion>): Array<PdfPlanVersion> {
  const pdfPlanVersionsByPlanId: Record<IdType, Array<PdfPlanVersion>> = _.groupBy(pdfPlanVersions, 'pdfPlanId');
  const latestPdfPlanVersions = new Array<PdfPlanVersion>();
  for (const pdfPlanId of Object.keys(pdfPlanVersionsByPlanId)) {
    const sortedPdfPlanVersions = _.orderBy(pdfPlanVersionsByPlanId[pdfPlanId], 'number');
    latestPdfPlanVersions.push(_.last(sortedPdfPlanVersions));
  }
  return latestPdfPlanVersions;
}

function ensureMarkersForTheSamePlan(
  pdfPlanMarkerProtocolEntriesResponse: SyncUtilResponse<PdfPlanMarkerProtocolEntry>,
  pdfPlanPageMarkingsResponse: SyncUtilResponse<PdfPlanPageMarking>,
  pdfPlanVersionResponse: SyncUtilResponse<PdfPlanVersion>,
  pdfPlanPageResponse: SyncUtilResponse<PdfPlanPage>
) {
  if (pdfPlanMarkerProtocolEntriesResponse.localChangesData.insert.length) {
    const pdfPlanIdsByProtocolEntryId = getUniquePdfPlanIdsForMarkersByProtocolEntryId(pdfPlanPageMarkingsResponse.syncedValues, pdfPlanVersionResponse, pdfPlanPageResponse);
    for (const pdfPlanMarkerProtocolEntryInserted of pdfPlanMarkerProtocolEntriesResponse.localChangesData.insert) {
      const pdfPlanIds = pdfPlanIdsByProtocolEntryId.get(pdfPlanMarkerProtocolEntryInserted.protocolEntryId);
      const pdfPlanIdInserted = getPdfPlanIdForPdfPlanPageId(pdfPlanMarkerProtocolEntryInserted.pdfPlanPageId, pdfPlanVersionResponse, pdfPlanPageResponse);
      if (pdfPlanIds?.length && pdfPlanIds.some((pdfPlanId) => pdfPlanId !== pdfPlanIdInserted)) {
        if (!pdfPlanMarkerProtocolEntriesResponse.conflicts) {
          pdfPlanMarkerProtocolEntriesResponse.conflicts = [];
        }
        pdfPlanMarkerProtocolEntriesResponse.conflicts.push({
          type: ConflictType.DELETED_LOCAL_MARKER_SERVER_MARKER_DIFFERENT_PLAN,
          id: pdfPlanMarkerProtocolEntryInserted.id,
          localValue: pdfPlanMarkerProtocolEntryInserted,
          storageKey: pdfPlanMarkerProtocolEntriesResponse.storageKey,
          resolved: true,
        });
        pdfPlanMarkerProtocolEntriesResponse.conflict = true;
        pdfPlanMarkerProtocolEntriesResponse.localChangesRemove.push(pdfPlanMarkerProtocolEntryInserted.id);
      }
    }
  }

  if (pdfPlanPageMarkingsResponse.localChangesData.insert.length) {
    const pdfPlanIdsByProtocolEntryId = getUniquePdfPlanIdsForMarkersByProtocolEntryId(pdfPlanMarkerProtocolEntriesResponse.syncedValues, pdfPlanVersionResponse, pdfPlanPageResponse);
    for (const pdfPlanPageMarkingInserted of pdfPlanPageMarkingsResponse.localChangesData.insert) {
      const pdfPlanIds = pdfPlanIdsByProtocolEntryId.get(pdfPlanPageMarkingInserted.protocolEntryId);
      const pdfPlanIdInserted = getPdfPlanIdForPdfPlanPageId(pdfPlanPageMarkingInserted.pdfPlanPageId, pdfPlanVersionResponse, pdfPlanPageResponse);
      if (pdfPlanIds?.length && pdfPlanIds.some((pdfPlanId) => pdfPlanId !== pdfPlanIdInserted)) {
        if (!pdfPlanPageMarkingsResponse.conflicts) {
          pdfPlanPageMarkingsResponse.conflicts = [];
        }
        pdfPlanPageMarkingsResponse.conflicts.push({
          type: ConflictType.DELETED_LOCAL_MARKER_SERVER_MARKER_DIFFERENT_PLAN,
          id: pdfPlanPageMarkingInserted.id,
          localValue: pdfPlanPageMarkingInserted,
          storageKey: pdfPlanPageMarkingsResponse.storageKey,
          resolved: true,
        });
        pdfPlanPageMarkingsResponse.localChangesRemove.push(pdfPlanPageMarkingInserted.id);
        pdfPlanPageMarkingsResponse.conflict = true;
      }
    }
  }
}

function ensureMarkersUniquePerPdfPlanAndProtocolEntry<T extends PdfPlanMarkerProtocolEntry | PdfPlanPageMarking>(
  entryResponse: SyncUtilResponse<T>,
  pdfPlanVersionResponse: SyncUtilResponse<PdfPlanVersion>,
  pdfPlanPageResponse: SyncUtilResponse<PdfPlanPage>
) {
  if (!entryResponse.localChangesData.insert.length) {
    return;
  }
  const {pdfPlanPages: latestPdfPlanPages, pdfPlanVersions: latestPdfPlanVersions} = filterLatestPdfPlanVersionAndPages(pdfPlanVersionResponse.syncedValues, pdfPlanPageResponse.syncedValues);
  const latestPdfPlanPageIds = latestPdfPlanPages.map((value) => value.id);
  const syncedValuesWithProtocolEntryId = entryResponse.syncedValues.filter((synced) => latestPdfPlanPageIds.includes(synced.pdfPlanPageId) && !!synced.protocolEntryId);
  const entriesByProtocolEntryId: Record<IdType, T[]> = _.groupBy(syncedValuesWithProtocolEntryId, 'protocolEntryId');
  const insertedWithProtocolEntryId = entryResponse.localChangesData.insert.filter((inserted) => !!inserted.protocolEntryId);
  for (const insertedValue of insertedWithProtocolEntryId) {
    if (!entriesByProtocolEntryId[insertedValue.protocolEntryId]?.length) {
      continue;
    }
    const localChangesOther = entriesByProtocolEntryId[insertedValue.protocolEntryId].filter((value) => value.id !== insertedValue.id);
    if (!localChangesOther.length) {
      continue;
    }
    const otherPdfPlanIds = getUniquePdfPlanIdsForMarkers(localChangesOther, latestPdfPlanVersions, latestPdfPlanPages);
    const currentPdfPlanIds = getUniquePdfPlanIdsForMarkers([insertedValue], latestPdfPlanVersions, latestPdfPlanPages);
    const currentPdfPlanId = currentPdfPlanIds.length ? currentPdfPlanIds[0] : undefined;

    if (currentPdfPlanId && (otherPdfPlanIds.length === 0 || (otherPdfPlanIds.length === 1 && otherPdfPlanIds.includes(currentPdfPlanId)))) {
      continue; // currentPdfPlanId can be found and all other markers for this entry belong to the same plan (just a different plan-version).
    }

    if (!entryResponse.conflicts) {
      entryResponse.conflicts = [];
    }
    entryResponse.conflicts.push({
      type: ConflictType.MARKER_NOT_UNIQUE_PER_PROTOCOL_ENTRY,
      id: insertedValue.id,
      localValue: insertedValue,
      storageKey: entryResponse.storageKey,
      resolved: true,
    });
    entryResponse.localChangesRemove.push(insertedValue.id);
    entryResponse.conflict = true;
  }
}

function copyAttachmentFilePathIfExisting(sourceAttachment: Attachment, destinationAttachment: Attachment): boolean {
  let changed = false;
  if (sourceAttachment.filePath && !destinationAttachment.filePath) {
    destinationAttachment.filePath = sourceAttachment.filePath;
    changed = true;
  }
  if (sourceAttachment.bigThumbnailPath && !destinationAttachment.bigThumbnailPath) {
    destinationAttachment.bigThumbnailPath = sourceAttachment.bigThumbnailPath;
    changed = true;
  }
  if (sourceAttachment.mediumThumbnailPath && !destinationAttachment.mediumThumbnailPath) {
    destinationAttachment.mediumThumbnailPath = sourceAttachment.mediumThumbnailPath;
    changed = true;
  }
  if (sourceAttachment.thumbnailPath && !destinationAttachment.thumbnailPath) {
    destinationAttachment.thumbnailPath = sourceAttachment.thumbnailPath;
    changed = true;
  }
  return changed;
}

export async function syncData<T extends IdAware>(syncRequest: SyncUtilRequest<T>): Promise<SyncUtilResponse<T>> {
  const ret: SyncUtilResponse<T> = {
    storageKey: syncRequest.storageKey,
    syncedValues: new Array<T>(),
    newValues: new Array<T>(),
    deletedValues: new Array<T>(),
    changedValues: new Array<ChangedValue<T>>(),
    serverFileSizes: {},
    conflict: false,
    resolved: true,
    localChangesInsert: new Array<T>(),
    localChangesUpdate: new Array<T>(),
    localChangesDelete: new Array<T>(),
    localChangesRemove: new Array<IdType>(),
    localChangesData: syncRequest.localChangesData,
  };
  const storageKey = syncRequest.storageKey;
  const localValuesById = _.keyBy(syncRequest.localValues, 'id');
  const localValuesInsertedById = _.keyBy(syncRequest.localChangesData.insert, 'id');
  const localValuesUpdatedById = _.keyBy(syncRequest.localChangesData.update, 'id');
  const localValuesDeletedById = _.keyBy(syncRequest.localChangesData.delete, 'id');
  const serverValuesById = _.keyBy(syncRequest.serverValues, 'id');
  const conflicts = new Array<SyncConflict<T>>();

  for (const serverValue of syncRequest.serverValues) {
    const serverValueT: T = serverValue as T;
    const id = serverValue.id;
    const localValue = localValuesById[id];
    const isAttachment = 'fileExt' in serverValue;
    const isNotChanged = 'notChanged' in serverValue && serverValue.notChanged;
    if ('fileSize' in serverValue) {
      const fileSize = serverValue.fileSize;
      if (fileSize !== undefined) {
        ret.serverFileSizes[id] = fileSize;
      }
    }
    if (isAttachment && localValue) {
      // @ts-ignore
      copyAttachmentFilePathIfExisting(localValue as Attachment, serverValue as Attachment);
    }
    if (
      serverValue &&
      !localValue &&
      (localValuesDeletedById[id] || syncRequest.localChangesData.localChangesDeleteById.has(id)) &&
      (localValuesInsertedById[id] || syncRequest.localChangesData.localChangesInsertById.has(id))
    ) {
      // New entry was inserted locally and changes were sent to the server but the client did not confirmation for that. And on top of that the user deleted the value locally.
      // Remove the localChanges and force a automatically resolved sync conflict.
      ret.localChangesRemove.push(id);
      console.log(`Entity was created locally but was already on the server. It was then also deleted locally by the user. storageKey="${storageKey}", id="${id}"`);
      continue;
    }
    if (isNotChanged) {
      if (localValue) {
        if (localValuesInsertedById[id]) {
          // new entry was already sent to the server. The client did not get confirmation for that, probably due to network issues. Remove the value from the local store.
          ret.syncedValues.push(localValue); // since we do not have serverValue (it is not changed) we can only use the local value.
          ret.localChangesRemove.push(id);
          console.log(`Entity was created locally but was already on the server. storageKey="${storageKey}", id="${id}"`);
          // Since localValue may not have the same changedAt as serverValue (which changedAt we do not have), we need to return a sync conflict that starts a full sync to get the changedAt
          conflicts.push({
            type: ConflictType.INCONSISTENT_NO_LOCAL_SERVER_NOT_MODIFIED,
            serverValue,
            storageKey,
            id,
            resolved: false,
          });
          continue;
        }
        ret.syncedValues.push(localValue);
        continue;
      }
      if (!localValuesDeletedById[id]) {
        conflicts.push({
          type: ConflictType.INCONSISTENT_NO_LOCAL_SERVER_NOT_MODIFIED,
          serverValue,
          storageKey,
          id,
          resolved: false,
        });
        console.log(`conflict INCONSISTENT_NO_LOCAL_SERVER_NOT_MODIFIED. storageKey="${storageKey}", id="${id}"`);
        continue;
      }
      continue;
    }

    const localValueChangedAt = localValuesDeletedById[id]
      ? (_.get(localValuesDeletedById[id], 'changedAt') as Date | string)
      : localValue
        ? (_.get(localValue, 'changedAt') as Date | string)
        : undefined;
    const hasLocalAndServerValueSameChangedAt =
      localValueChangedAt && 'changedAt' in serverValue && isEqualDate(_.get(serverValue, 'changedAt') as Date | string | null | undefined, localValueChangedAt);
    if (hasLocalAndServerValueSameChangedAt) {
      // it was marked as changed on the server but we already have that data locally. It was probably already synced but was not able to write the sync status locally.
      if (localValuesDeletedById[id]) {
        continue;
      }
      if (isAttachment && localValue) {
        if (!deepEqualExceptChangedAtAndFileChangedAt(localValue, serverValueT)) {
          // this can only mean that the attachment's filePath properties have changed (e.g. attachment was uploaded or thumbnail was created) but not the data of the attachment itself.
          // @ts-ignore
          if (copyAttachmentFilePathIfExisting(serverValue as Attachment, localValue as Attachment)) {
            // Adding the server value to changedValues is important to update the store and observables. However, sync conflict check is not necessary because the data has not really changed.
            ret.changedValues.push({localValue, serverValue: serverValueT});
          } else {
            ret.changedValues.push({localValue, serverValue: serverValueT, onlyAttachmentFileChanged: true});
          }
        } else {
          // the attachment properties do not have changed but the fileChangedAt, which means, one of the thumbnail files on the server have changed.
          ret.changedValues.push({localValue, serverValue: serverValueT, onlyAttachmentFileChanged: true});
        }
      }
      ret.syncedValues.push(localValue);
      continue;
    }
    // changed on the server
    if (localValuesUpdatedById[id]) {
      if ((!isAttachment && deepEqualExceptChangedAt(localValue, serverValueT)) || (isAttachment && deepEqualExceptChangedAtAndFileChangedAtAndFilePaths(localValue, serverValueT))) {
        ret.syncedValues.push(serverValueT);
        ret.localChangesRemove.push(id);
      } else {
        if (isStorageKeyFieldLevelMerge(storageKey)) {
          const merged = fieldLevelMerge(conflicts, storageKey as StorageKeyFieldLevelMerge, syncRequest, ret, localValue, serverValueT);
          if (merged) {
            continue;
          }
        }
        ret.changedValues.push({localValue, serverValue: serverValueT});
        if ('changedAt' in serverValueT && isAfter(syncRequest.localChangesData.localChangesUpdateById.get(id).changedAt, _.get(serverValueT, 'changedAt') as Date | string)) {
          conflicts.push({
            type: ConflictType.MODIFIED_LOCAL_AND_SERVER_LOCAL_NEWER,
            id,
            localValue,
            serverValue,
            storageKey,
            resolved: true,
          });
          console.log(`conflict MODIFIED_LOCAL_AND_SERVER_LOCAL_NEWER. storageKey="${storageKey}", id="${id}"`);
          const changedAtValue = _.get(serverValueT, 'changedAt') as Date | string;
          _.set(localValue, 'changedAt', changedAtValue);
          ret.syncedValues.push(localValue);
        } else {
          conflicts.push({
            type: ConflictType.MODIFIED_LOCAL_AND_SERVER_SERVER_NEWER,
            id,
            localValue,
            serverValue,
            storageKey,
            resolved: true,
          });
          console.log(`conflict MODIFIED_LOCAL_AND_SERVER_SERVER_NEWER. storageKey="${storageKey}", id="${id}"`);
          ret.syncedValues.push(serverValueT);
          ret.localChangesRemove.push(id);
        }
      }
      continue;
    }
    if (localValuesDeletedById[id]) {
      _.set(localValuesDeletedById[id], 'changedAt', _.get(serverValueT, 'changedAt'));
      ret.changedValues.push({localValue: localValuesDeletedById[id], serverValue: serverValueT});
      conflicts.push({
        type: ConflictType.DELETED_LOCAL_MODIFIED_SERVER,
        id,
        localValue: localValuesDeletedById[id],
        serverValue,
        storageKey,
        resolved: true,
      });
      console.log(`conflict DELETED_LOCAL_MODIFIED_SERVER. storageKey="${storageKey}", id="${id}"`);
      continue;
    }
    if (localValuesInsertedById[id]) {
      // new entry was already sent to the server. The client did not get confirmation for that, probably due to network issues. Remove the value from the local store.
      ret.syncedValues.push(serverValueT);
      ret.localChangesRemove.push(id);
      console.log(`Entity was created locally but was already on the server. storageKey="${storageKey}", id="${id}"`);
      continue;
    }
    ret.syncedValues.push(serverValueT);
    if (localValue) {
      ret.changedValues.push({localValue, serverValue: serverValueT});
    } else {
      ret.newValues.push(serverValueT);
    }
  }

  for (const localValue of syncRequest.localValues) {
    const id = localValue.id;
    const serverValue = serverValuesById[id];

    if (serverValue) {
      continue; // already treated by for loop above
    }
    if (localValuesInsertedById[id]) {
      ret.syncedValues.push(localValue);
      continue;
    }
    if (localValuesUpdatedById[id]) {
      ret.localChangesRemove.push(id);
      ret.deletedValues.push(localValue);
      conflicts.push({type: ConflictType.MODIFIED_LOCAL_DELETED_SERVER, id, localValue, storageKey, resolved: true});
      continue;
    }
    ret.deletedValues.push(localValue);
  }

  if (syncRequest.localChangesData.insert.length) {
    if (isStorageKeyWithUniqueNumber(storageKey)) {
      ensureNumbersAreSetAndUnique(ret.syncedValues, syncRequest.localChangesData.insert);
    } else if (isStorageKeyWithUniqueNumberByPdfPlanId(storageKey)) {
      ensureNumbersAreSetAndUniqueByFieldOrSubField(ret.syncedValues, syncRequest.localChangesData.insert, 'pdfPlanId');
    } else if (isStorageKeyWithUniqueNumberByType(storageKey)) {
      ensureNumbersAreSetAndUniqueByFieldOrSubField(ret.syncedValues, syncRequest.localChangesData.insert, 'typeId');
    } else if (isStorageKeyWithUniqueNumberByProtocolId(storageKey)) {
      ensureNumbersAreSetAndUniqueByFieldOrSubField(ret.syncedValues, syncRequest.localChangesData.insert, 'parentId', 'protocolId');
    } else if (isStorageKeyWithUniqueNumberByProtocolEntryId(storageKey)) {
      ensureNumbersAreSetAndUniqueByFieldOrSubField(ret.syncedValues, syncRequest.localChangesData.insert, 'protocolEntryId');
    } else if (isStorageKeyWithUniqueDateName(storageKey)) {
      ensureDateNameAreSetAndUnique(ret.syncedValues, syncRequest.localChangesData.insert, 'date', 'name');
    }
  }

  if (syncRequest.localChangesData.update.length) {
    if (isStorageKeyWithUniqueNumberByType(storageKey)) {
      ensureNumbersAreSetAndUniqueByFieldOrSubField(ret.syncedValues, syncRequest.localChangesData.update, 'typeId');
    } else if (isStorageKeyWithUniqueNumberByProtocolId(storageKey)) {
      ensureNumbersAreSetAndUniqueByFieldOrSubField(ret.syncedValues, syncRequest.localChangesData.update, 'parentId', 'protocolId');
    } else if (isStorageKeyWithUniqueDateName(storageKey)) {
      ensureDateNameAreSetAndUnique(ret.syncedValues, syncRequest.localChangesData.update, 'date', 'name');
    }
  }

  if (conflicts.length > 0) {
    ret.conflict = true;
    ret.resolved = conflicts.filter((conflict) => conflict.resolved).length === conflicts.length;
    ret.conflicts = conflicts;
  }

  return ret;
}

function isStorageKeyWithUniqueNumber(storageKey: StorageKeyEnum): boolean {
  return !!STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD.find((storageKeyWithUniqueNumber) => storageKeyWithUniqueNumber === storageKey);
}

function isStorageKeyWithUniqueNumberByPdfPlanId(storageKey: StorageKeyEnum): boolean {
  return !!STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_PDF_PLAN_ID.find((storageKeyWithUniqueNumber) => storageKeyWithUniqueNumber === storageKey);
}

function isStorageKeyWithUniqueNumberByProtocolId(storageKey: string): boolean {
  return !!STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_PROTOCOL_ID.find((storageKeyWithUniqueNumber) => storageKeyWithUniqueNumber === storageKey);
}

function isStorageKeyWithUniqueNumberByType(storageKey: string): boolean {
  return !!STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_TYPE.find((storageKeyWithUniqueNumber) => storageKeyWithUniqueNumber === storageKey);
}

function isStorageKeyWithUniqueNumberByProtocolEntryId(storageKey: string): boolean {
  return !!STORAGE_KEYS_WITH_UNIQUE_NUMBER_FIELD_PER_PROTOCOL_ENTRY_ID.find((storageKeyWithUniqueNumber) => storageKeyWithUniqueNumber === storageKey);
}

function isStorageKeyWithUniqueDateName(storageKey: StorageKeyEnum): boolean {
  return STORAGE_KEYS_WITH_UNIQUE_DATE_NAME_FIELD.some((storageKeyWithUniqueDateName) => storageKeyWithUniqueDateName === storageKey);
}

function isStorageKeyFieldLevelMerge(storageKey: StorageKeyEnum): storageKey is StorageKeyFieldLevelMerge {
  return !!STORAGE_KEYS_FIELD_LEVEL_MERGE_SUPPORTED[storageKey];
}

function fieldLevelMerge<T extends IdAware>(
  conflicts: SyncConflict<T>[],
  storageKey: StorageKeyFieldLevelMerge,
  syncRequest: SyncUtilRequest<T>,
  ret: SyncUtilResponse<T>,
  localValue: T,
  serverValue: T
): boolean {
  const localNewer = 'changedAt' in serverValue && isAfter(syncRequest.localChangesData.localChangesUpdateById.get(localValue.id).changedAt, _.get(serverValue, 'changedAt') as Date | string);
  switch (storageKey) {
    case StorageKeyEnum.PROTOCOL_ENTRY:
      const syncRequestProtocolEntry = syncRequest as unknown as SyncUtilRequest<ProtocolEntry>;
      const retProtocolEntry = ret as unknown as SyncUtilResponse<ProtocolEntry>;
      const localValueProtocolEntry = localValue as unknown as ProtocolEntry;
      const serverValueProtocolEntry = serverValue as unknown as ProtocolEntry;
      return fieldLevelMergeProtocolEntry(conflicts, storageKey, syncRequestProtocolEntry, retProtocolEntry, localValueProtocolEntry, serverValueProtocolEntry, localNewer);
    default:
      throw assertNever(storageKey, `fieldLevelMerge - fieldLevelMerge for storageKey "${storageKey}" not implemented.`);
  }
}

function fieldLevelMergeGeneral<T extends IdAware>(
  conflicts: SyncConflict<IdAware>[],
  storageKey: StorageKeyEnum,
  syncRequest: SyncUtilRequest<T>,
  ret: SyncUtilResponse<T>,
  localValue: T,
  serverValue: T,
  isLocalNewer: boolean,
  keysToIgnore: Array<keyof T>,
  shouldOverwriteServerValueWithLocalValueFn?: (propertyNotEqual: keyof T, propertiesChangedOnServer: Array<keyof T>) => {shouldOverwrite: boolean; additionalPropertiesToOverwrite?: Array<keyof T>}
): boolean {
  const id = localValue.id;
  const localChange = syncRequest.localChangesData.localChangesUpdateById.get(localValue.id);
  const originalValue = localChange.originalValue;
  if (!originalValue) {
    console.log(`fieldLevelMergeGeneral not possible. Unable to find localChange.originalValue for object with id ${serverValue?.id}.`);
    return false;
  }
  const propertiesNotEqual = notEqualKeysExcept(localValue, serverValue, ...keysToIgnore);
  if (!propertiesNotEqual.length) {
    // All values equal anyway, use the server value and remove local changes.
    ret.syncedValues.push(serverValue);
    if (!ret.localChangesRemove.includes(serverValue.id)) {
      ret.localChangesRemove.push(serverValue.id);
    }
    return true;
  }
  let mergedObject: T | undefined;
  const setPropertyOfMergedObject = (key: keyof T, value: unknown) => {
    if (!mergedObject) {
      mergedObject = {...serverValue};
    }
    _.set(mergedObject, key, value);
  };
  const propertiesChangedOnServer = notEqualKeysExcept(serverValue, originalValue, ...keysToIgnore);
  const propertiesChangedLocally = notEqualKeysExcept(localValue, originalValue, ...keysToIgnore);
  const propertiesNotEqualAndChangedLocally = propertiesNotEqual.filter((property) => propertiesChangedLocally.includes(property));
  for (const propertyNotEqual of propertiesNotEqualAndChangedLocally) {
    const hasPropertyChangedOnServer = propertiesChangedOnServer.includes(propertyNotEqual);
    let shouldOverwriteServerValueWithLocalValue = !hasPropertyChangedOnServer || isLocalNewer;
    if (shouldOverwriteServerValueWithLocalValueFn) {
      // special cases with dependencies
      const {shouldOverwrite, additionalPropertiesToOverwrite} = shouldOverwriteServerValueWithLocalValueFn(propertyNotEqual, propertiesChangedOnServer);
      shouldOverwriteServerValueWithLocalValue = shouldOverwrite;
      if (additionalPropertiesToOverwrite?.length) {
        additionalPropertiesToOverwrite.forEach((property) => setPropertyOfMergedObject(property, localValue[property]));
      }
    }

    if (shouldOverwriteServerValueWithLocalValue) {
      setPropertyOfMergedObject(propertyNotEqual, localValue[propertyNotEqual]);
    }
  }

  if (mergedObject) {
    const type = isLocalNewer ? ConflictType.MODIFIED_LOCAL_AND_SERVER_MERGED_LOCAL_NEWER : ConflictType.MODIFIED_LOCAL_AND_SERVER_MERGED_SERVER_NEWER;
    conflicts.push({
      type,
      id,
      localValue: mergedObject,
      localValueBeforeMerge: localValue,
      serverValue,
      storageKey,
      resolved: true,
    });
    console.log(`conflict ${type}. storageKey="${storageKey}", id="${id}"`);
    ret.syncedValues.push(mergedObject);
    const indexOfLocalChangesUpdated = ret.localChangesUpdate.findIndex((v) => v.id === id);
    if (indexOfLocalChangesUpdated >= 0) {
      ret.localChangesUpdate[indexOfLocalChangesUpdated] = mergedObject;
    } else {
      ret.localChangesUpdate.push(mergedObject);
    }
    return true;
  }

  return false;
}

function fieldLevelMergeProtocolEntry(
  conflicts: SyncConflict<IdAware>[],
  storageKey: StorageKeyEnum,
  syncRequest: SyncUtilRequest<ProtocolEntry>,
  ret: SyncUtilResponse<ProtocolEntry>,
  localValue: ProtocolEntry,
  serverValue: ProtocolEntry,
  isLocalNewer: boolean
): boolean {
  const shouldOverwriteServerValueWithLocalValueFn = (
    propertyNotEqual: keyof ProtocolEntry,
    propertiesChangedOnServer: Array<keyof ProtocolEntry>
  ): {shouldOverwrite: boolean; additionalPropertiesToOverwrite?: Array<keyof ProtocolEntry>} => {
    const hasPropertyChangedOnServer = propertiesChangedOnServer.includes(propertyNotEqual);
    let shouldOverwrite = !hasPropertyChangedOnServer || isLocalNewer;
    const hasPropertyChangedOnServerFn = (property: keyof ProtocolEntry) => propertiesChangedOnServer.includes(property);
    const shouldOverwriteServerValueWithLocalValueFn = (property: keyof ProtocolEntry) => !hasPropertyChangedOnServerFn(property) || isLocalNewer;
    if (shouldOverwrite && FIELD_LEVEL_MERGE_PROTOCOL_ENTRY_GROUPS_BY_ID.has(propertyNotEqual)) {
      const otherPropertiesInGroup = FIELD_LEVEL_MERGE_PROTOCOL_ENTRY_GROUPS_BY_ID.get(propertyNotEqual);
      if (otherPropertiesInGroup?.some((otherProperty) => !shouldOverwriteServerValueWithLocalValueFn(otherProperty))) {
        return {shouldOverwrite: false};
      }
      return {shouldOverwrite: shouldOverwrite, additionalPropertiesToOverwrite: otherPropertiesInGroup ?? undefined};
    }
    return {shouldOverwrite: shouldOverwrite};
  };
  return fieldLevelMergeGeneral(conflicts, storageKey, syncRequest, ret, localValue, serverValue, isLocalNewer, ['changedAt', 'createdAtDb'], shouldOverwriteServerValueWithLocalValueFn);
}

function hasNotNullNumber<T>(obj: T, numberField = 'number') {
  return _.has(obj, numberField) && _.get(obj, numberField) !== undefined && _.get(obj, numberField) !== null;
}

function hasNotNullDateName<T>(obj: T, dateField = 'date', nameField = 'name') {
  return (
    _.has(obj, dateField) && _.get(obj, dateField) !== undefined && _.get(obj, dateField) !== null && _.has(obj, nameField) && _.get(obj, nameField) !== undefined && _.get(obj, nameField) !== null
  );
}

function getNextNumber(numbersSorted: Array<number>): number {
  const nextNumber = numbersSorted.length === 0 ? 1 : +numbersSorted[numbersSorted.length - 1] + 1;
  numbersSorted.push(nextNumber);
  return nextNumber;
}

function getChangedName(name: string, namesOfSameDate: Array<string>): string {
  const availableSuffixes = ('123456789' + 'abcdefghijklmnopqrstuvwxyz'.toUpperCase() + 'abcdefghijklmnopqrstuvwxyz').split('');
  name += '-0';
  if (namesOfSameDate.includes(name)) {
    for (const suffix of availableSuffixes) {
      name = name.slice(0, -1) + suffix;
      if (!namesOfSameDate.includes(name)) {
        return name;
      }
    }
  } else {
    return name;
  }
  throw new Error('Could not find a replacement calendar day name for conflict');
}

function findNewUniqueProjectNumberString(currentString: string, stringsSorted: Array<string>): string {
  const availableSuffixes = ('123456789' + 'abcdefghijklmnopqrstuvwxyz'.toUpperCase() + 'abcdefghijklmnopqrstuvwxyz').split('');
  currentString += '-0';
  if (stringsSorted.includes(currentString)) {
    for (const suffix of availableSuffixes) {
      currentString = currentString.slice(0, -1) + suffix;
      if (!stringsSorted.includes(currentString)) {
        return currentString;
      }
    }
  } else {
    return currentString;
  }
  throw new Error('Could not find a replacement projectnumber for conflict');
}

function getNextProtocolNumber(protocols: Array<Protocol>, typeId: IdType): number {
  const sortedNumbers = _.sortBy(protocols.filter((protocol) => protocol.typeId === typeId).map((protocol) => protocol.number));
  return getNextNumber(sortedNumbers);
}

function getNextProtocolEntryNumber(
  protocolEntries: Array<ProtocolEntry>,
  protocolId: IdType,
  parentProtocolEntryId: IdType | null | undefined,
  skipEntries?: Array<ProtocolEntry> | null | undefined
): number {
  function isPartOfProtocol(protocolEntry: ProtocolEntry) {
    return protocolEntry.protocolId === protocolId || protocolEntry.createdInProtocolId === protocolId;
  }

  function isParentOrChildIfHasParent(protocolEntry: ProtocolEntry): unknown {
    return (!protocolEntry.parentId && !parentProtocolEntryId) || protocolEntry.parentId === parentProtocolEntryId;
  }

  const sortedNumbers = _.sortBy(
    protocolEntries
      .filter((protocolEntry) => isPartOfProtocol(protocolEntry) && isParentOrChildIfHasParent(protocolEntry) && (!skipEntries || skipEntries.every((skipEntry) => skipEntry.id !== protocolEntry.id)))
      .map((protocolEntry) => protocolEntry.number)
  );
  return getNextNumber(sortedNumbers);
}

function getUniqueFieldValuesFromBoth<T extends IdAware>(syncedValues: Array<T>, localValuesInserted: Array<T>, field: string): Array<any> {
  return _.uniq(syncedValues.map((syncedValue) => _.get(syncedValue, field)).concat(localValuesInserted.map((localValueInserted) => _.get(localValueInserted, field))));
}

export function ensureNumbersAreSetAndUniqueByFieldOrSubField<T extends IdAware>(syncedValues: Array<T>, localValuesInserted: Array<T>, field: string, subField?: string, numberField = 'number') {
  if (!localValuesInserted.length || !syncedValues.length) {
    return;
  }
  if (!subField) {
    assertValuesHavePropertyWithValue(syncedValues, field);
    assertValuesHavePropertyWithValue(localValuesInserted, field);
    ensureNumbersAreSetAndUniqueByField(syncedValues, localValuesInserted, field, numberField);
  } else {
    assertValuesHavePropertyWithValue(syncedValues, field, true);
    assertValuesHavePropertyWithValue(localValuesInserted, field, true);
    assertValuesHavePropertyWithValue(syncedValues, subField);
    assertValuesHavePropertyWithValue(localValuesInserted, subField);
    const fieldValues = getUniqueFieldValuesFromBoth(syncedValues, localValuesInserted, field);
    fieldValues.forEach((fieldValue) => {
      const filterByField = (value) => value[field] === fieldValue;
      ensureNumbersAreSetAndUniqueByField(syncedValues.filter(filterByField), localValuesInserted.filter(filterByField), subField, numberField);
    });
  }
}

export function ensureNumbersAreSetAndUniqueByCompareFunction<T extends IdAware>(
  syncedValues: Array<T>,
  localValuesInsertedUpdated: Array<T>,
  compareFn: (value: T, other: T) => boolean,
  numberField = 'number'
) {
  if (!localValuesInsertedUpdated.length || !syncedValues.length) {
    return;
  }
  for (const localValueInsertedUpdated of localValuesInsertedUpdated) {
    const syncedValuesFiltered = syncedValues.filter((syncValue) => compareFn(localValueInsertedUpdated, syncValue));
    ensureNumbersAreSetAndUnique(syncedValuesFiltered, [localValueInsertedUpdated], numberField);
  }
}

function ensureNumbersAreSetAndUniqueByField<T extends IdAware>(syncedValues: Array<T>, localValuesInserted: Array<T>, field: string, numberField = 'number') {
  const syncValuesByType = _.groupBy(syncedValues, field);
  const localValuesInsertedByType = _.groupBy(localValuesInserted, field);
  const types = getUniqueFieldValuesFromBoth(syncedValues, localValuesInserted, field);
  for (const type of types) {
    const typedSyncValues = syncValuesByType[type];
    const typedLocalValuesInserted = localValuesInsertedByType[type];
    if (typedSyncValues?.length && typedLocalValuesInserted?.length) {
      ensureNumbersAreSetAndUnique(typedSyncValues, typedLocalValuesInserted, numberField);
    }
  }
}

function assertValuesHavePropertyWithValue(values: Array<IdAware>, property: string, allowNull = false) {
  for (const value of values) {
    if (!_.has(value, property)) {
      throw new Error(`Value with id ${value.id} does not have a property "${property}".`);
    }
    const propertyValue = _.get(value, property);
    if (propertyValue === undefined) {
      throw new Error(`Value with id ${value.id} does have a property "${property}" but it is undefined.`);
    } else if (!allowNull && propertyValue === null) {
      throw new Error(`Value with id ${value.id} does have a property "${property}" but it is null.`);
    }
  }
}

export function ensureNumbersAreSetAndUnique<T extends IdAware>(syncedValues: Array<T>, localValuesInserted: Array<T>, numberField = 'number') {
  if (!localValuesInserted.length || !syncedValues.length) {
    return;
  }
  const syncValuesWithNumber = syncedValues.filter((syncValue) => hasNotNullNumber(syncValue, numberField));
  const valuesByNumber: {[key: string]: Array<T>} = _.groupBy(syncValuesWithNumber, numberField);
  const numbersSorted: Array<number> = _.sortBy(
    Object.keys(valuesByNumber)
      .filter((numberAsString) => REGEXP_IS_NUMBER.test(numberAsString))
      .map((numberAsString) => +numberAsString)
  );
  const stringsSorted: Array<string> = _.sortBy(
    Object.keys(valuesByNumber)
      .filter((numberAsString) => !REGEXP_IS_NUMBER.test(numberAsString))
      .map((numberAsString) => numberAsString)
  );
  for (const value of localValuesInserted) {
    if (!hasNotNullNumber(value, numberField)) {
      _.set(value, numberField, getNextNumber(numbersSorted));
      continue;
    }
    const currentNumberOrString: number | string = _.get(value, numberField);

    if (valuesByNumber[currentNumberOrString]?.length > 1) {
      if (!isNumber(currentNumberOrString)) {
        const currentString = currentNumberOrString.toString();
        const nextString = findNewUniqueProjectNumberString(currentString, stringsSorted);
        _.set(value, numberField, nextString);
        const syncValue = syncedValues.find((o) => o.id === value.id);
        if (syncValue) {
          _.set(syncValue, numberField, nextString);
        }
      } else {
        const nextNumber = getNextNumber(numbersSorted);
        _.set(value, numberField, nextNumber);
        const syncValue = syncedValues.find((o) => o.id === value.id);
        if (syncValue) {
          _.set(syncValue, numberField, nextNumber);
        }
      }
    }
  }
}

export function ensureDateNameAreSetAndUnique<T extends IdAware>(syncedValues: Array<T>, localValuesInserted: Array<T>, dateField = 'date', nameField = 'name') {
  if (!localValuesInserted.length || !syncedValues.length) {
    return;
  }
  const syncValuesWithDateName = syncedValues.filter((syncValue) => hasNotNullDateName(syncValue, dateField, nameField));
  for (const value of localValuesInserted) {
    const valueToChangeName = syncValuesWithDateName.find(
      (syncedValue) =>
        syncedValue.id !== value.id &&
        _.get(value, dateField) &&
        new Date(_.get(value, dateField)).toISOString() === new Date(_.get(syncedValue, dateField)).toISOString() &&
        _.get(value, nameField) === _.get(syncedValue, nameField)
    );
    if (valueToChangeName) {
      const allNamesWithSameDate: Array<string> = syncValuesWithDateName
        .filter((syncedValue) => new Date(_.get(value, dateField)).toISOString() === new Date(_.get(syncedValue, dateField)).toISOString())
        .map((syncedValue) => _.get(syncedValue, nameField).toString());
      const currentName: string = _.get(value, nameField);
      const changedName = getChangedName(currentName, allNamesWithSameDate);
      _.set(value, nameField, changedName);
      const syncValue = syncedValues.find((o) => o.id === value.id);
      if (syncValue) {
        _.set(syncValue, nameField, changedName);
      }
    }
  }
}

function isNumber(numberOrString: string | number): boolean {
  return typeof numberOrString === 'number' || (typeof numberOrString === 'string' && REGEXP_IS_NUMBER.test(numberOrString));
}

abstract class SyncBase {
  protected undoLocalChangeAndAddSyncConflict<T extends IdAware>(syncRequest: SyncUtilRequest<T>, syncResponse: SyncUtilResponse<T>, filterFunction: (value: any) => any, conflictType: ConflictType) {
    const inserted = _.filter(syncRequest.localChangesData.insert, filterFunction);
    const updated = _.filter(syncRequest.localChangesData.update, filterFunction);
    const deleted = _.filter(syncRequest.localChangesData.delete, filterFunction);
    const mapId = (object: IdAware) => object.id;
    const locallyChangedIds = _.uniq(inserted.map(mapId).concat(updated.map(mapId)).concat(deleted.map(mapId)));

    for (const id of locallyChangedIds) {
      const localChangeAlreadyRevertedBySync = syncResponse.localChangesRemove.includes(id);
      if (localChangeAlreadyRevertedBySync) {
        console.log(`undoLocalChangeAndAddSyncConflict - local change for id ${id} already reverted`);
        continue;
      }

      const newerValueFromResolvedSyncConflict = getObjectFromSyncConflictWhenItIsNewer(id, syncResponse);
      let value = this.undoLocalChange(syncRequest, syncResponse, id, newerValueFromResolvedSyncConflict);
      if (value) {
        this.addSyncConflict(syncResponse, conflictType, value);
      }
    }
  }

  protected addSyncConflict<T extends IdAware>(syncResponse: SyncUtilResponse<T>, conflictType: ConflictType, value: T, context?: any) {
    if (!syncResponse.conflicts) {
      syncResponse.conflicts = [];
      syncResponse.conflict = true;
    }
    syncResponse.conflicts.push({
      type: conflictType,
      localValue: value,
      resolved: true,
      id: value.id,
      storageKey: syncResponse.storageKey,
      context,
    });
  }

  protected undoLocalChange<T extends IdAware>(syncRequest: SyncUtilRequest<T>, syncResponse: SyncUtilResponse<T>, localValueId: IdType, valueToReplaceWith?: T): T | undefined {
    if (syncResponse.localChangesRemove.find((id) => id === localValueId)) {
      return; // already marked for removal.
    }

    const inserted = syncRequest.localChangesData.localChangesInsertById.get(localValueId);
    const updated = syncRequest.localChangesData.localChangesUpdateById.get(localValueId);
    const deleted = syncRequest.localChangesData.localChangesDeleteById.get(localValueId);

    let removedValue: T | undefined;
    if (inserted) {
      _.remove(syncResponse.syncedValues, (syncValue) => syncValue.id === localValueId);
      removedValue = inserted.value;
    } else if (deleted) {
      syncResponse.syncedValues.push(valueToReplaceWith ?? deleted.originalValue);
      removedValue = deleted.value;
    } else if (updated) {
      const index = syncResponse.syncedValues.findIndex((syncedValue) => syncedValue.id === localValueId);
      syncResponse.syncedValues[index] = valueToReplaceWith ?? updated.originalValue;
      removedValue = updated.value;
    }
    syncResponse.localChangesRemove.push(localValueId);
    return removedValue;
  }
}

class SyncProtocols<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey> extends SyncBase {
  private readonly PROTOCOL_ENTRY_PROPERTIES_ALLOWED_TO_CHANGE: Array<keyof ProtocolEntry> = ['status', 'todoUntil', 'reminderAt', 'internalAssignmentId'];

  constructor(
    private protocolsRequest: SyncUtilRequest<Protocol>,
    private protocolsResponse: SyncUtilResponse<Protocol>,
    private protocolEntriesRequest: SyncUtilRequest<ProtocolEntry>,
    private protocolEntriesResponse: SyncUtilResponse<ProtocolEntry>,
    private protocolEntryChatsRequest: SyncUtilRequest<ProtocolEntryChat>,
    private protocolEntryChatsResponse: SyncUtilResponse<ProtocolEntryChat>,
    private attachmentProtocolEntriesRequest: SyncUtilRequest<AttachmentProtocolEntry>,
    private attachmentProtocolEntriesResponse: SyncUtilResponse<AttachmentProtocolEntry>,
    private attachmentChatsRequest: SyncUtilRequest<AttachmentChat>,
    private attachmentChatsResponse: SyncUtilResponse<AttachmentChat>,
    private pdfPlanPagesRequest: SyncUtilRequest<PdfPlanPage>,
    private pdfPlanPagesResponse: SyncUtilResponse<PdfPlanPage>,
    private pdfPlanMarkerProtocolEntriesRequest: SyncUtilRequest<PdfPlanMarkerProtocolEntry>,
    private pdfPlanMarkerProtocolEntriesResponse: SyncUtilResponse<PdfPlanMarkerProtocolEntry>,
    private protocolOpenEntriesRequest: SyncUtilRequest<ProtocolOpenEntry>,
    private protocolOpenEntriesResponse: SyncUtilResponse<ProtocolOpenEntry>,
    private protocolTypes: Array<ProtocolType>,
    private protocolEntryTypes: Array<ProtocolEntryType>,
    private protocolLayouts: Array<ProtocolLayout>,
    private projectsResponse: SyncUtilResponse<Project>,
    private protocolEntryCompaniesRequest: SyncUtilRequest<ProtocolEntryCompany>,
    private protocolEntryCompaniesResponse: SyncUtilResponse<ProtocolEntryCompany>,
    private pdfPlanPageMarkingsRequest: SyncUtilRequest<PdfPlanPageMarking>,
    private pdfPlanPageMarkingsResponse: SyncUtilResponse<PdfPlanPageMarking>,
    private protocolTypesResponse: SyncUtilResponse<ProtocolType>,
    private clientOrProjectId?: IdType
  ) {
    super();
  }

  public sync() {
    this.ensureMovedEntriesAreNotPartOfClosedProtocols();
    this.ensureSubEntriesArePartOfTheSameProtocolAsParentEntry();
    this.ensureNewEntriesAreNotInClosedProtocol();
    this.ensureProtocolTypesLayoutsDidNotChangeForProtocols();

    // BM2-87 - Special case 1 - protocols closed on the client, while being changed on the server, are not treated specially.

    const protocolsClosedServer = this.protocolsResponse.changedValues.filter((changedValue) => !!changedValue.serverValue.closedAt);
    for (const protocol of protocolsClosedServer) {
      const isProtocolTypeContinuous = this.isProtocolTypeContinuous(protocol.serverValue);
      if (isProtocolTypeContinuous) {
        // BM2-87 - Special case 2, 3.a, 3b
        this.syncClosedContinuousProtocol(protocol.serverValue);
      } else {
        // BM2-87 - Special case 3.c
        this.removeAllChangesForProtocol(protocol.serverValue);
      }
    }
    // BM2-87 Special case 2 - find Continuous protocols that were inserted locally, but another Continuous protocol was created or re-opened on the server.
    const protocolsOpenedOnServer = this.protocolsResponse.changedValues.filter((v) => !v.serverValue.closedAt && v.localValue.closedAt).map((v) => v.serverValue);
    for (const protocolAddedServer of this.protocolsResponse.newValues.concat(protocolsOpenedOnServer)) {
      const isProtocolTypeContinuous = this.isProtocolTypeContinuous(protocolAddedServer);
      if (!isProtocolTypeContinuous) {
        continue;
      }
      const typeId = protocolAddedServer.typeId;
      const continuousProtocolsAddedLocally = this.protocolsRequest.localChangesData.insert.filter(
        (localValueInserted) => localValueInserted.typeId === typeId && localValueInserted.id !== protocolAddedServer.id && !localValueInserted.closedAt
      );
      if (continuousProtocolsAddedLocally.length === 0) {
        continue;
      }
      if (continuousProtocolsAddedLocally.length > 1) {
        throw new Error(`There are ${continuousProtocolsAddedLocally.length} open protocols of type ${typeId} (continuous) added locally but only one is be allowed.`);
      }
      const continuousProtocolAddedLocally = continuousProtocolsAddedLocally[0];
      this.mergeServerAndLocalContinuousProtocol(protocolAddedServer, continuousProtocolAddedLocally);
    }
  }

  private ensureProtocolTypesLayoutsDidNotChangeForProtocols() {
    const protocolTypesWithNewLayoutId = this.protocolTypesResponse.changedValues.filter(({localValue, serverValue}) => localValue.layoutId !== serverValue.layoutId);

    if (protocolTypesWithNewLayoutId.length === 0) {
      return;
    }

    const protocolTypesWithNewLayoutIdSet = new Set(protocolTypesWithNewLayoutId.map((v) => v.serverValue.id));

    const locallyInsertedProtocolsWithChangedProtocolTypes = this.protocolsRequest.localChangesData.insert.filter((protocol) => protocolTypesWithNewLayoutIdSet.has(protocol.typeId));

    const locallyUpdatedProtocolsWithChangedProtocolTypes = this.protocolsRequest.localChangesData.update.filter((protocol) => protocolTypesWithNewLayoutIdSet.has(protocol.typeId));

    if (!locallyInsertedProtocolsWithChangedProtocolTypes.length && !locallyUpdatedProtocolsWithChangedProtocolTypes.length) {
      return;
    }

    if (locallyInsertedProtocolsWithChangedProtocolTypes.length) {
      const locallyInsertedProtocolsWithChangedProtocolTypesSet = new Set(locallyInsertedProtocolsWithChangedProtocolTypes.map((v) => v.id));

      let recoveryProtocol: Protocol | undefined;
      let nextEntryNumber: number | undefined;
      // Some protocol type(s) changed, and since last sync, new protocols with those type(s) have been created.
      // Remove those protocols, and recover any entry.
      const protocolEntriesLocalDataInsertedOrUpdated = this.protocolEntriesRequest.localChangesData
        ? [...this.protocolEntriesRequest.localChangesData.insert, ...this.protocolEntriesRequest.localChangesData.update]
        : [];

      for (const protocolEntry of protocolEntriesLocalDataInsertedOrUpdated) {
        if (!locallyInsertedProtocolsWithChangedProtocolTypesSet.has(protocolEntry.protocolId)) {
          // Entry not a part of to-be-deleted-protocols
          continue;
        }

        const result = this.moveProtocolEntriesToRecoveryProtocol([protocolEntry], ConflictType.PROTOCOL_REMOVED_ENTRIES_RECOVERED, {
          recoveryProtocol,
          nextEntryNumber,
        });
        recoveryProtocol = result.recoveryProtocol;
        nextEntryNumber = result.nextEntryNumber;
      }

      if (recoveryProtocol) {
        this.protocolsResponse.syncedValues.push(recoveryProtocol);
        this.protocolsResponse.localChangesInsert.push(recoveryProtocol);
      }

      // Entries moved, now we can get rid of protocol
      this.undoLocalChangeAndAddSyncConflict(
        this.protocolsRequest,
        this.protocolsResponse,
        (protocol) => locallyInsertedProtocolsWithChangedProtocolTypesSet.has(protocol.id),
        ConflictType.MODIFIED_LOCAL_AND_SERVER_SERVER_NEWER
      );
    }
    if (locallyUpdatedProtocolsWithChangedProtocolTypes.length) {
      for (const protocolEntry of locallyUpdatedProtocolsWithChangedProtocolTypes) {
        this.undoLocalChangeAndAddSyncConflict(this.protocolsRequest, this.protocolsResponse, (protocol) => protocol.id === protocolEntry.id, ConflictType.MODIFIED_LOCAL_AND_SERVER_SERVER_NEWER);
      }
    }
  }

  private updateProtocolIdAndNumberForLocallyInsertedEntry(protocolEntry: ProtocolEntry, protocolId: IdType, newNumber: number) {
    const mapFn = (entry: ProtocolEntry): ProtocolEntry => {
      if (entry.id === protocolEntry.id) {
        entry.protocolId = protocolId;
        entry.number = newNumber;
      }

      return entry;
    };
    protocolEntry = mapFn(protocolEntry);
    this.protocolEntriesResponse.localChangesData.insert = this.protocolEntriesResponse.localChangesData.insert.map(mapFn);
    this.protocolEntriesResponse.syncedValues = this.protocolEntriesResponse.syncedValues.map(mapFn);
    const entryInById = this.protocolEntriesResponse.localChangesData.localChangesInsertById.get(protocolEntry.id);
    if (entryInById) {
      entryInById.value.protocolId = protocolId;
      entryInById.value.number = newNumber;
      this.protocolEntriesResponse.localChangesData.localChangesInsertById.set(protocolEntry.id, entryInById);
    }
  }

  private moveProtocolEntriesToRecoveryProtocol(
    protocolEntries: ProtocolEntry[],
    conflictType: ConflictType,
    {recoveryProtocol, nextEntryNumber = 1}: {recoveryProtocol?: Protocol; nextEntryNumber?: number} = {}
  ): {recoveryProtocol: Protocol; nextEntryNumber: number} | undefined {
    if (!protocolEntries.length) {
      return;
    }
    if (!recoveryProtocol) {
      recoveryProtocol = getRecoveryProtocol(this.projectsResponse.syncedValues, this.clientOrProjectId, this.protocolLayouts, this.protocolTypes, this.protocolsResponse.syncedValues, new Date());
    }

    const protocolEntryIds = protocolEntries.map((protocolEntry) => protocolEntry.id);
    for (const protocolEntry of protocolEntries) {
      const isChildAndParentNotMoved = protocolEntry.parentId && !protocolEntryIds.includes(protocolEntry.parentId);
      if (isChildAndParentNotMoved) {
        protocolEntry.parentId = null;
      }
      this.updateProtocolIdAndNumberForLocallyInsertedEntry(protocolEntry, recoveryProtocol.id, nextEntryNumber++);
      this.addSyncConflict(this.protocolEntriesResponse, conflictType, protocolEntry, {recoveryProtocol});
    }

    return {recoveryProtocol, nextEntryNumber};
  }

  private ensureNewEntriesAreNotInClosedProtocol() {
    const protocolEntriesLocalDataInserted = this.protocolEntriesRequest.localChangesData ? [...this.protocolEntriesRequest.localChangesData.insert] : [];

    const protocolById = _.keyBy(this.protocolsResponse.syncedValues, 'id');
    const recoveryProtocol = getRecoveryProtocol(this.projectsResponse.syncedValues, this.clientOrProjectId, this.protocolLayouts, this.protocolTypes, this.protocolsResponse.syncedValues, new Date());
    let entriesMovedToRecovery = false;
    let entryNumber = 1;

    const protocolEntryIdsInserted = protocolEntriesLocalDataInserted.map((protocolEntry) => protocolEntry.id);
    for (const protocolEntry of protocolEntriesLocalDataInserted) {
      const protocol = protocolById[protocolEntry.protocolId];
      if (!protocol) {
        // It should never happen, because integrity logic runs before sync protocols. Integrity logic should not allow orphan objects to exist.
        throw new Error(`SyncProtocols: protocol ${protocolEntry.protocolId} not found (should never happen)`);
      }

      if (this.isProtocolTypeContinuous(protocol)) {
        // Skipping check for continuous, as continuous protocol has it's own logic
        continue;
      }

      if (protocol.closedAt) {
        // Protocol has been closed; move entry to recovery protocol
        entriesMovedToRecovery = true;
        if (protocolEntry.parentId && !protocolEntryIdsInserted.includes(protocolEntry.parentId)) {
          // Child entry is being moved to recover protocol but not it's parent. Convert it to a main entry.
          protocolEntry.parentId = null;
        }
        this.updateProtocolIdAndNumberForLocallyInsertedEntry(protocolEntry, recoveryProtocol.id, entryNumber++);
        this.addSyncConflict(this.protocolEntriesResponse, ConflictType.PROTOCOL_CLOSED_ENTRIES_RECOVERED, protocolEntry);
      }
    }

    if (entriesMovedToRecovery) {
      this.protocolsResponse.syncedValues.push(recoveryProtocol);
      this.protocolsResponse.localChangesInsert.push(recoveryProtocol);
    }
  }

  private ensureMovedEntriesAreNotPartOfClosedProtocols() {
    const protocolEntriesLocalDataChanged = this.protocolEntriesRequest.localChangesData
      ? [...this.protocolEntriesRequest.localChangesData.insert, ...this.protocolEntriesRequest.localChangesData.update]
      : [];

    const protocolById = _.keyBy(this.protocolsResponse.syncedValues, 'id');
    const protocolEntryById = _.keyBy(this.protocolEntriesResponse.syncedValues, 'id');

    for (const protocolEntryLocal of protocolEntriesLocalDataChanged) {
      const synced = protocolEntryById[protocolEntryLocal.id];
      const beforeChange = this.protocolEntriesRequest.localChangesData.localChangesUpdateById.get(protocolEntryLocal.id)?.originalValue;

      if (!synced || !beforeChange || synced.protocolId === beforeChange.protocolId) {
        continue;
      }
      const sourceProtocol = protocolById[beforeChange.createdInProtocolId ?? beforeChange.protocolId];
      if (!sourceProtocol) {
        continue;
      }
      if (sourceProtocol.closedAt) {
        // Source protocol is closed; any change to that entry must be discarded
        this.undoLocalChangeAndAddSyncConflict(
          this.protocolEntriesRequest,
          this.protocolEntriesResponse,
          (entry: ProtocolEntry) => entry.id === synced.id,
          ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
        );
      }
    }
  }

  private ensureSubEntriesArePartOfTheSameProtocolAsParentEntry() {
    const protocolEntriesLocalDataChanged = this.protocolEntriesRequest.localChangesData
      ? [...this.protocolEntriesRequest.localChangesData.insert, ...this.protocolEntriesRequest.localChangesData.update]
      : [];
    const protocolEntryById = _.keyBy(this.protocolEntriesResponse.syncedValues, 'id');
    const protocolEntryChildrenById = _.groupBy(
      this.protocolEntriesResponse.syncedValues.filter(({parentId}) => !!parentId),
      'parentId'
    );
    for (const protocolEntryLocal of protocolEntriesLocalDataChanged) {
      const entry = protocolEntryById[protocolEntryLocal.id];
      if (!entry) {
        continue;
      }
      if (!entry.parentId) {
        // Check if children entries have been added by another user.
        const children = protocolEntryChildrenById[entry.id];
        if (children?.length) {
          const notSameProtocolChildren = children.filter((child) => child.protocolId !== entry.protocolId);
          if (notSameProtocolChildren.length) {
            const oldProtocolId = notSameProtocolChildren[0].protocolId;
            // Children have been added to the entry by another user, we need to update their protocol ids to original one
            entry.protocolId = oldProtocolId;
            this.addSyncConflict(this.protocolEntriesResponse, ConflictType.CHILD_ENTRY_ADDED_TO_MOVED_ENTRY_MOVE_DISCARDED, entry);
            for (const child of children) {
              if (child.protocolId === oldProtocolId) {
                continue;
              }
              child.protocolId = oldProtocolId;
              this.protocolEntriesResponse.localChangesUpdate.push(child);
            }
          }
        }
      } else {
        // Check if parent entry has been moved by another user
        const parentEntry = protocolEntryById[entry.parentId];
        if (!parentEntry) {
          // It should never happen, because integrity logic runs before sync protocols. Integrity logic should not allow orphan objects to exist.
          throw new Error(`SyncProtocols: parent entry ${entry.parentId} not found for ${entry.id} (should never happen)`);
        }
        if (parentEntry.protocolId === entry.protocolId) {
          continue;
        }
        // Parent entry has been moved, we need to update current entry's protocol id
        entry.protocolId = parentEntry.protocolId;
        this.protocolEntriesResponse.localChangesUpdate.push(entry);
      }
    }
  }

  private isProtocolTypeContinuous(protocol: Protocol): boolean {
    const protocolType: ProtocolType = this.protocolTypes.find((value) => value.id === protocol.typeId);
    const protocolLayout: ProtocolLayout = this.protocolLayouts.find((value) => value.id === protocolType?.layoutId);
    return protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
  }

  private cloneProtocol(protocols: Array<Protocol>, originalProtocol: Protocol): Protocol {
    const nextNumber = getNextProtocolNumber(protocols, originalProtocol.typeId);
    const nowAsString = new Date().toISOString();
    return {
      id: uuid4(),
      number: nextNumber,
      projectId: originalProtocol.projectId,
      typeId: originalProtocol.typeId,
      date: new Date().toISOString(),
      closedAt: null,
      name: originalProtocol.name + ' ' + nextNumber,
      ownerClientId: originalProtocol.ownerClientId,
      changedAt: nowAsString,
      createdAt: nowAsString,
    } as Protocol;
  }

  private isUnfinished(protocolEntry: ProtocolEntry): boolean {
    if (!protocolEntry.typeId) {
      return Boolean(protocolEntry.isContinuousInfo);
    }
    const protocolEntryType = this.protocolEntryTypes.find((value) => value.id === protocolEntry.typeId);
    if (!protocolEntryType || !protocolEntryType.statusFieldActive) {
      return Boolean(protocolEntry.isContinuousInfo);
    }
    return protocolEntry.status !== ProtocolEntryStatus.DONE;
  }

  private getProtocolEntryOrOpenByProtocolId(protocolId: IdType): Array<ProtocolEntryOrOpen> {
    const protocolEntries = this.protocolEntriesResponse.syncedValues;
    const protocolOpenEntries = this.protocolOpenEntriesResponse.syncedValues;

    const protocolEntriesForProtocol = protocolEntries.filter((protocolEntry) => protocolEntry.protocolId === protocolId || protocolEntry.createdInProtocolId === protocolId);
    const protocolOpenEntriesForProtocol = protocolOpenEntries.filter((protocolOpenEntry) => protocolOpenEntry.protocolId === protocolId);
    return convertAllToProtocolEntriesOrOpen(protocolEntries, protocolOpenEntriesForProtocol, protocolEntriesForProtocol);
  }

  private createNextContinuousProtocol(lastProtocol: Protocol, localValuesInserted: Array<ProtocolEntry>): Protocol {
    const openContinuousProtocols = this.protocolsResponse.syncedValues.filter((protocol) => protocol.typeId === lastProtocol.typeId && !protocol.closedAt);
    if (openContinuousProtocols.length) {
      throw new Error(`Not allowed to create a new continuousProtocol of type ${lastProtocol.typeId} because there are already ${openContinuousProtocols.length} open protocols of that type.`);
    }
    const newProtocol = this.cloneProtocol(this.protocolsResponse.syncedValues, lastProtocol);
    this.protocolsResponse.syncedValues.push(newProtocol);
    this.protocolsResponse.localChangesInsert.push(newProtocol);

    const insertedLocallyIds = localValuesInserted.map(({id}) => id);
    const protocolEntriesOrOpen = this.getProtocolEntryOrOpenByProtocolId(lastProtocol.id);
    const unfinishedProtocolEntriesOrOpen = getUnfinishedEntriesOrOpenAndTheirParentsByProtocolId(protocolEntriesOrOpen, this.protocolEntryTypes, false, localValuesInserted);

    for (const lastProtocolEntry of unfinishedProtocolEntriesOrOpen) {
      if (insertedLocallyIds.includes(lastProtocolEntry.id)) {
        // protocol is already closed and this entry was inserted locally (after it was closed). It will not be a ProtocolOpenEntry but a ProtocolEntry in the new Protocol.
        continue;
      }
      const newProtocolOpenEntry: ProtocolOpenEntry = {
        id: newProtocol.id + lastProtocolEntry.id,
        protocolId: newProtocol.id,
        protocolEntryId: lastProtocolEntry.id,
        changedAt: new Date().toISOString(),
      };
      this.protocolOpenEntriesResponse.syncedValues.push(newProtocolOpenEntry);
      this.protocolOpenEntriesResponse.localChangesInsert.push(newProtocolOpenEntry);
    }

    return newProtocol;
  }

  private isEntryCarriedOver(protocolId: IdType, protocolEntryId: IdType | undefined): boolean | undefined {
    if (!protocolId || !protocolEntryId) {
      return undefined;
    }
    return this.protocolOpenEntriesResponse.syncedValues.some((openEntry) => openEntry.protocolEntryId === protocolEntryId && openEntry.protocolId === protocolId);
  }

  private syncClosedContinuousProtocol(closedProtocol: Protocol) {
    function isPartOfClosedProtocol(localValue: ProtocolEntry): boolean {
      return localValue.protocolId === closedProtocol.id || localValue.createdInProtocolId === closedProtocol.id;
    }

    const localValuesInserted = this.protocolEntriesRequest.localChangesData.insert.filter((localValue) => isPartOfClosedProtocol(localValue));
    const localValuesUpdated = this.protocolEntriesRequest.localChangesData.update.filter((localValue) => isPartOfClosedProtocol(localValue));
    const localValuesDeleted = this.protocolEntriesRequest.localChangesData.delete.filter((localValue) => isPartOfClosedProtocol(localValue));
    if (localValuesInserted.length === 0 && localValuesUpdated.length === 0 && localValuesDeleted.length === 0) {
      return;
    }
    if (localValuesDeleted.length) {
      for (const localValueDeleted of localValuesDeleted) {
        this.undoLocalChangeAndAddSyncConflict(
          this.protocolEntriesRequest,
          this.protocolEntriesResponse,
          (value: ProtocolEntry) => value.id === localValueDeleted.id,
          ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
        );
        this.undoLocalChangeAndAddSyncConflict(
          this.protocolOpenEntriesRequest,
          this.protocolOpenEntriesResponse,
          (value: ProtocolOpenEntry) => value.protocolEntryId === localValueDeleted.id,
          ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
        );

        const filterByProtocolEntryId = (value: ProtocolEntryChat | AttachmentProtocolEntry | AttachmentChat | PdfPlanMarkerProtocolEntry) => value.protocolEntryId === localValueDeleted.id;

        this.undoLocalChangeAndAddSyncConflict(this.protocolEntryChatsRequest, this.protocolEntryChatsResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
        this.undoLocalChangeAndAddSyncConflict(this.attachmentProtocolEntriesRequest, this.attachmentProtocolEntriesResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
        this.undoLocalChangeAndAddSyncConflict(this.attachmentChatsRequest, this.attachmentChatsResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
        this.undoLocalChangeAndAddSyncConflict(
          this.pdfPlanMarkerProtocolEntriesRequest,
          this.pdfPlanMarkerProtocolEntriesResponse,
          filterByProtocolEntryId,
          ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
        );
        this.undoLocalChangeAndAddSyncConflict(this.pdfPlanPageMarkingsRequest, this.pdfPlanPageMarkingsResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
        this.undoLocalChangeAndAddSyncConflict(this.protocolEntryCompaniesRequest, this.protocolEntryCompaniesResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
      }
    }
    let nextContinuousProtocol = this.protocolsResponse.syncedValues.find((protocol) => protocol.typeId === closedProtocol.typeId && protocol.number > closedProtocol.number && !protocol.closedAt);
    if (localValuesInserted.length) {
      // BM2-87 - Special case 3.a
      if (!nextContinuousProtocol) {
        nextContinuousProtocol = this.createNextContinuousProtocol(closedProtocol, localValuesInserted);
      }
      const unprocessedLocalValues = [...localValuesInserted];
      for (const localValueInserted of localValuesInserted) {
        const syncedValueInserted = this.protocolEntriesResponse.syncedValues.find((syncedValue) => syncedValue.id === localValueInserted.id);
        if (!localValueInserted.createdInProtocolId && localValueInserted.parentId && this.isEntryCarriedOver(nextContinuousProtocol.id, localValueInserted.parentId)) {
          localValueInserted.createdInProtocolId = closedProtocol.id;
          // the method handleSyncConflictOfSubEntryInCarriedOverParent will add localValueInserted to protocolEntriesResponse.localChangesUpdate to ensure it is updated.
        }
        if (syncedValueInserted.createdInProtocolId) {
          this.handleSyncConflictOfSubEntryInCarriedOverParent(syncedValueInserted, nextContinuousProtocol, unprocessedLocalValues, localValueInserted);
        } else {
          const nextNumber = getNextProtocolEntryNumber(this.protocolEntriesResponse.syncedValues, nextContinuousProtocol.id, syncedValueInserted.parentId, unprocessedLocalValues);

          this.changeProtocolEntryProtocolId(localValueInserted, nextNumber, syncedValueInserted, nextContinuousProtocol);
          unprocessedLocalValues.splice(unprocessedLocalValues.indexOf(localValueInserted), 1);

          this.protocolEntriesResponse.localChangesUpdate.push(localValueInserted);
        }

        this.addSyncConflict(this.protocolEntriesResponse, ConflictType.CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_MOVED, localValueInserted);
      }
    }
    if (localValuesUpdated.length) {
      // BM2-87 - Special case 3.b
      for (const localValueUpdated of localValuesUpdated) {
        const protocolEntryId = localValueUpdated.id;
        const localChangeAlreadyRevertedBySync = this.protocolEntriesResponse.localChangesRemove.includes(protocolEntryId);
        if (localChangeAlreadyRevertedBySync) {
          continue;
        }
        const protocolOpenEntryOfNextContinuousProtocol = this.protocolOpenEntriesResponse.syncedValues.filter(
          (o) => o.protocolId === nextContinuousProtocol?.id && o.protocolEntryId === localValueUpdated.id
        );
        if (protocolOpenEntryOfNextContinuousProtocol.length) {
          const syncedValue = this.protocolEntriesResponse.syncedValues.find((value) => value.id === localValueUpdated.id);
          const localChange = this.protocolEntriesRequest.localChangesData.localChangesUpdateById.get(protocolEntryId);
          const reverted = this.revertPropertyChangesOfProtocolEntry(syncedValue, localChange.originalValue, this.PROTOCOL_ENTRY_PROPERTIES_ALLOWED_TO_CHANGE);
          this.revertPropertyChangesOfProtocolEntry(localValueUpdated, localChange.originalValue, this.PROTOCOL_ENTRY_PROPERTIES_ALLOWED_TO_CHANGE);
          if (reverted) {
            this.protocolEntriesResponse.localChangesUpdate.push(syncedValue);
            this.addSyncConflict(this.protocolEntriesResponse, ConflictType.CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_PARTLY_REVERTED, syncedValue);
          }
          this.undoLocalChangeAndAddSyncConflict(
            this.attachmentProtocolEntriesRequest,
            this.attachmentProtocolEntriesResponse,
            (value) => value.protocolEntryId === protocolEntryId,
            ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
          );
          this.undoLocalChangeAndAddSyncConflict(
            this.pdfPlanMarkerProtocolEntriesRequest,
            this.pdfPlanMarkerProtocolEntriesResponse,
            (value) => value.protocolEntryId === protocolEntryId,
            ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
          );
        } else {
          this.removeAllChangesForProtocolEntries((protocolEntry) => protocolEntry.id === protocolEntryId);
        }
      }
    }
  }

  private handleSyncConflictOfSubEntryInCarriedOverParent(
    syncedValueInserted: ProtocolEntry,
    nextContinuousProtocol: Protocol,
    unprocessedLocalValues: ProtocolEntry[],
    localValueInserted: ProtocolEntry
  ) {
    const isParentCarriedOver = this.isEntryCarriedOver(nextContinuousProtocol.id, syncedValueInserted.parentId);

    if (isParentCarriedOver) {
      const nextNumber = getNextProtocolEntryNumber(this.protocolEntriesResponse.syncedValues, syncedValueInserted.protocolId, syncedValueInserted.parentId, unprocessedLocalValues);
      unprocessedLocalValues.splice(unprocessedLocalValues.indexOf(localValueInserted), 1);

      this.createOpenEntriesForSubEntryInCarriedOverParent(syncedValueInserted, nextContinuousProtocol);
      this.changeProtocolEntryProtocolId(localValueInserted, nextNumber, syncedValueInserted, nextContinuousProtocol);

      this.protocolEntriesResponse.localChangesUpdate.push(localValueInserted);
    } else {
      const nextNumber = getNextProtocolEntryNumber(this.protocolEntriesResponse.syncedValues, nextContinuousProtocol.id, undefined, unprocessedLocalValues);
      unprocessedLocalValues.splice(unprocessedLocalValues.indexOf(localValueInserted), 1);

      localValueInserted.number = nextNumber;
      syncedValueInserted.number = nextNumber;
      localValueInserted.parentId = null;
      syncedValueInserted.parentId = null;
      localValueInserted.createdInProtocolId = undefined;
      syncedValueInserted.createdInProtocolId = undefined;
      localValueInserted.protocolId = nextContinuousProtocol.id;
      syncedValueInserted.protocolId = nextContinuousProtocol.id;

      this.protocolEntriesResponse.localChangesUpdate.push(localValueInserted);
    }
  }

  private createOpenEntriesForSubEntryInCarriedOverParent(syncedValueInserted: ProtocolEntry, nextContinuousProtocol: Protocol) {
    if (!syncedValueInserted.parentId) {
      return;
    }

    const parentEntry = this.protocolEntriesResponse.syncedValues.find((entry) => entry.id === syncedValueInserted.parentId);
    const parentProtocol = this.protocolsResponse.syncedValues.find((protocol) => protocol.id === parentEntry.protocolId);

    if (parentProtocol) {
      const protocolsBetweenParentProtocolAndCurrentProtocolIds = _.sortBy(
        this.protocolsResponse.syncedValues.filter((protocol) => nextContinuousProtocol.typeId === protocol.typeId),
        ['number'],
        ['asc']
      ).filter((protocol) => protocol.number > parentProtocol.number && protocol.number < nextContinuousProtocol.number);

      const openEntryProtocolIds = this.protocolOpenEntriesResponse.syncedValues.filter((openEntry) => openEntry.protocolEntryId === syncedValueInserted.id).map(({protocolId}) => protocolId);
      const missingOpenEntryForProtocols = protocolsBetweenParentProtocolAndCurrentProtocolIds.filter(({id}) => !openEntryProtocolIds.includes(id));

      for (const protocol of missingOpenEntryForProtocols) {
        const newProtocolOpenEntry: ProtocolOpenEntry = {
          id: protocol.id + syncedValueInserted.id,
          protocolId: protocol.id,
          protocolEntryId: syncedValueInserted.id,
          changedAt: new Date().toISOString(),
        };
        this.protocolOpenEntriesResponse.syncedValues.push(newProtocolOpenEntry);
        this.protocolOpenEntriesResponse.localChangesInsert.push(newProtocolOpenEntry);
      }
    }
  }

  private changeProtocolEntryProtocolId(localValueInserted: ProtocolEntry, nextNumber: number, syncedValueInserted: ProtocolEntry, nextContinuousProtocol: Protocol) {
    localValueInserted.number = nextNumber;
    syncedValueInserted.number = nextNumber;
    if (localValueInserted.createdInProtocolId) {
      // Newly created entry has been created in another protocol, which means it's a sub entry of already closed protocol.
      // Since local protocol is already closed on a server, and we move changes to the next continuous protocol, we want
      // to change **createdInProtocolId**, not protocolId (as protocolId is set to already closed protocol - the one we
      // want to append an additional sub entry).
      localValueInserted.createdInProtocolId = nextContinuousProtocol.id;
      syncedValueInserted.createdInProtocolId = nextContinuousProtocol.id;
    } else {
      localValueInserted.protocolId = nextContinuousProtocol.id;
      syncedValueInserted.protocolId = nextContinuousProtocol.id;
    }
  }

  private revertPropertyChangesOfProtocolEntry(protocolEntry: ProtocolEntry, originalValue: ProtocolEntry, propertiesAllowToChange: Array<keyof ProtocolEntry>): boolean {
    let changesReverted = false;
    for (const propertyKey in protocolEntry) {
      if (propertiesAllowToChange.find((object) => object === propertyKey)) {
        continue;
      }
      if (protocolEntry[propertyKey] === originalValue[propertyKey]) {
        continue;
      }
      protocolEntry[propertyKey] = originalValue[propertyKey];
      changesReverted = true;
    }
    return changesReverted;
  }

  private removeAllChangesForProtocol(protocol: Protocol) {
    const filterById = (value: Protocol) => value.id === protocol.id;
    this.undoLocalChangeAndAddSyncConflict(this.protocolEntriesRequest, this.protocolEntriesResponse, filterById, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);

    this.removeAllChangesForProtocolEntries((protocolEntry) => protocolEntry.protocolId === protocol.id);
  }

  private removeAllChangesForProtocolEntries(protocolEntryFilter: (value: ProtocolEntry) => any) {
    const protocolEntriesInserted = this.protocolEntriesRequest.localChangesData.insert.filter(protocolEntryFilter);
    const protocolEntriesUpdated = this.protocolEntriesRequest.localChangesData.update.filter(protocolEntryFilter);
    const protocolEntriesDeleted = this.protocolEntriesRequest.localChangesData.delete.filter(protocolEntryFilter);
    const mapId = (value: IdAware) => value.id;
    const locallyChangedProtocolEntryIds = protocolEntriesInserted.map(mapId).concat(protocolEntriesUpdated.map(mapId)).concat(protocolEntriesDeleted.map(mapId));

    this.undoLocalChangeAndAddSyncConflict(this.protocolEntriesRequest, this.protocolEntriesResponse, protocolEntryFilter, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);

    const filterByProtocolEntryId = (value: ProtocolEntryChat | AttachmentProtocolEntry | AttachmentChat | PdfPlanMarkerProtocolEntry) => _.find(locallyChangedProtocolEntryIds, value.protocolEntryId);

    this.undoLocalChangeAndAddSyncConflict(this.protocolEntryChatsRequest, this.protocolEntryChatsResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.attachmentProtocolEntriesRequest, this.attachmentProtocolEntriesResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.attachmentChatsRequest, this.attachmentChatsResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(
      this.pdfPlanMarkerProtocolEntriesRequest,
      this.pdfPlanMarkerProtocolEntriesResponse,
      filterByProtocolEntryId,
      ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST
    );
    this.undoLocalChangeAndAddSyncConflict(this.pdfPlanPageMarkingsRequest, this.pdfPlanPageMarkingsResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.protocolEntryCompaniesRequest, this.protocolEntryCompaniesResponse, filterByProtocolEntryId, ConflictType.PROTOCOL_CLOSED_LOCAL_CHANGES_LOST);
  }

  private mergeServerAndLocalContinuousProtocol(serverProtocol: Protocol, localProtocol: Protocol) {
    this.undoLocalChangeAndAddSyncConflict(this.protocolsRequest, this.protocolsResponse, (protocol) => protocol.id === localProtocol.id, ConflictType.CONTINUOUS_PROTOCOL_CREATED_SERVER_AND_LOCAL);

    const protocolEntryLocalValuesInsertedUnsorted = this.protocolEntriesRequest.localChangesData.insert.filter(
      (value) => value.protocolId === localProtocol.id || value.createdInProtocolId === localProtocol.id
    );
    const protocolEntryLocalValuesInserted = _.sortBy(protocolEntryLocalValuesInsertedUnsorted, 'number');
    const unprocessedLocalValues = [...protocolEntryLocalValuesInserted];
    for (const localValueInserted of protocolEntryLocalValuesInserted) {
      const syncedValueInserted = this.protocolEntriesResponse.syncedValues.find((syncedValue) => syncedValue.id === localValueInserted.id);
      if (syncedValueInserted.createdInProtocolId) {
        this.handleSyncConflictOfSubEntryInCarriedOverParent(syncedValueInserted, serverProtocol, unprocessedLocalValues, localValueInserted);
      } else {
        const newProtocolId = serverProtocol.id;

        const nextNumber = getNextProtocolEntryNumber(this.protocolEntriesResponse.syncedValues, newProtocolId, syncedValueInserted.parentId, unprocessedLocalValues);
        unprocessedLocalValues.splice(unprocessedLocalValues.indexOf(localValueInserted), 1);

        this.changeProtocolEntryProtocolId(localValueInserted, nextNumber, syncedValueInserted, serverProtocol);

        this.protocolEntriesResponse.localChangesUpdate.push(localValueInserted);
      }

      this.addSyncConflict(this.protocolEntriesResponse, ConflictType.CONTINUOUS_PROTOCOL_CREATED_SERVER_AND_LOCAL, localValueInserted);
    }

    const protocolOpenEntryLocalValueInserted = this.protocolOpenEntriesRequest.localChangesData.insert.filter((value) => value.protocolId === localProtocol.id);
    for (const localValueInserted of protocolOpenEntryLocalValueInserted) {
      const serverInserted = this.protocolOpenEntriesResponse.newValues.find((value) => value.protocolId === serverProtocol.id && value.protocolEntryId === localValueInserted.protocolEntryId);

      if (serverInserted) {
        this.undoLocalChangeAndAddSyncConflict(
          this.protocolOpenEntriesRequest,
          this.protocolOpenEntriesResponse,
          (value) => value.id === localValueInserted.id,
          ConflictType.CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_MOVED
        );
      } else {
        const syncedValueInserted = this.protocolOpenEntriesResponse.syncedValues.find((syncedValue) => syncedValue.id === localValueInserted.id);
        const newProtocolId = serverProtocol.id;
        const existingSyncedValueInserted = this.protocolOpenEntriesResponse.syncedValues.find((syncedValue) => syncedValue.id === newProtocolId + localValueInserted.protocolEntryId);
        if (existingSyncedValueInserted) {
          this.undoLocalChangeAndAddSyncConflict(
            this.protocolOpenEntriesRequest,
            this.protocolOpenEntriesResponse,
            (value) => value.id === existingSyncedValueInserted.id,
            ConflictType.CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_MOVED
          );
        } else {
          localValueInserted.protocolId = newProtocolId;
          syncedValueInserted.protocolId = newProtocolId;

          this.protocolOpenEntriesResponse.localChangesUpdate.push(localValueInserted);
          this.addSyncConflict(this.protocolOpenEntriesResponse, ConflictType.CONTINUOUS_PROTOCOL_CLOSED_LOCAL_CHANGES_MOVED, localValueInserted);
        }
      }
    }
  }
}

class SyncReports<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey> extends SyncBase {
  private reportsRequest: SyncUtilRequest<Report> = _.get(this.syncRequests, 'reports') as SyncUtilRequest<Report>;
  private reportsResponse: SyncUtilResponse<Report> = _.get(this.result, 'reports') as SyncUtilResponse<Report>;
  private reportWeeksRequest: SyncUtilRequest<ReportWeek> = _.get(this.syncRequests, 'reportWeeks') as SyncUtilRequest<ReportWeek>;
  private reportWeeksResponse: SyncUtilResponse<ReportWeek> = _.get(this.result, 'reportWeeks') as SyncUtilResponse<ReportWeek>;
  private reportCompanyRequest: SyncUtilRequest<ReportCompany> = _.get(this.syncRequests, 'reportCompanies') as SyncUtilRequest<ReportCompany>;
  private reportCompanyResponse: SyncUtilResponse<ReportCompany> = _.get(this.result, 'reportCompanies') as SyncUtilResponse<ReportCompany>;
  private reportCompanyActivityRequest: SyncUtilRequest<ReportCompanyActivity> = _.get(this.syncRequests, 'reportCompanyActivities') as SyncUtilRequest<ReportCompanyActivity>;
  private reportCompanyActivityResponse: SyncUtilResponse<ReportCompanyActivity> = _.get(this.result, 'reportCompanyActivities') as SyncUtilResponse<ReportCompanyActivity>;
  private employeeRequest: SyncUtilRequest<Employee> = _.get(this.syncRequests, 'employees') as SyncUtilRequest<Employee>;
  private employeeResponse: SyncUtilResponse<Employee> = _.get(this.result, 'employees') as SyncUtilResponse<Employee>;
  private activityRequest: SyncUtilRequest<Activity> = _.get(this.syncRequests, 'activities') as SyncUtilRequest<Activity>;
  private activityResponse: SyncUtilResponse<Activity> = _.get(this.result, 'activities') as SyncUtilResponse<Activity>;
  private equipmentRequest: SyncUtilRequest<Equipment> = _.get(this.syncRequests, 'equipments') as SyncUtilRequest<Equipment>;
  private equipmentResponse: SyncUtilResponse<Equipment> = _.get(this.result, 'equipments') as SyncUtilResponse<Equipment>;
  private materialRequest: SyncUtilRequest<Material> = _.get(this.syncRequests, 'materials') as SyncUtilRequest<Material>;
  private materialResponse: SyncUtilResponse<Material> = _.get(this.result, 'materials') as SyncUtilResponse<Material>;
  private staffRequest: SyncUtilRequest<Staff> = _.get(this.syncRequests, 'staffs') as SyncUtilRequest<Staff>;
  private staffResponse: SyncUtilResponse<Staff> = _.get(this.result, 'staffs') as SyncUtilResponse<Staff>;
  private attachmentReportMaterialRequest: SyncUtilRequest<AttachmentReportMaterial> = _.get(this.syncRequests, 'attachmentReportMaterials') as SyncUtilRequest<AttachmentReportMaterial>;
  private attachmentReportMaterialResponse: SyncUtilResponse<AttachmentReportMaterial> = _.get(this.result, 'attachmentReportMaterials') as SyncUtilResponse<AttachmentReportMaterial>;
  private attachmentReportEquipmentRequest: SyncUtilRequest<AttachmentReportEquipment> = _.get(this.syncRequests, 'attachmentReportEquipments') as SyncUtilRequest<AttachmentReportEquipment>;
  private attachmentReportEquipmentResponse: SyncUtilResponse<AttachmentReportEquipment> = _.get(this.result, 'attachmentReportEquipments') as SyncUtilResponse<AttachmentReportEquipment>;
  private attachmentReportActivityRequest: SyncUtilRequest<AttachmentReportActivity> = _.get(this.syncRequests, 'attachmentReportActivities') as SyncUtilRequest<AttachmentReportActivity>;
  private attachmentReportActivityResponse: SyncUtilResponse<AttachmentReportActivity> = _.get(this.result, 'attachmentReportActivities') as SyncUtilResponse<AttachmentReportActivity>;
  private attachmentReportCompanyRequest: SyncUtilRequest<AttachmentReportCompany> = _.get(this.syncRequests, 'attachmentReportCompanies') as SyncUtilRequest<AttachmentReportCompany>;
  private attachmentReportCompanyResponse: SyncUtilResponse<AttachmentReportCompany> = _.get(this.result, 'attachmentReportCompanies') as SyncUtilResponse<AttachmentReportCompany>;
  private attachmentReportSignatureRequest: SyncUtilRequest<AttachmentReportSignature> = _.get(this.syncRequests, 'attachmentReportSignatures') as SyncUtilRequest<AttachmentReportSignature>;
  private attachmentReportSignatureResponse: SyncUtilResponse<AttachmentReportSignature> = _.get(this.result, 'attachmentReportSignatures') as SyncUtilResponse<AttachmentReportSignature>;

  constructor(
    private syncRequests: {[key in K]: SyncUtilRequest<T>},
    private result: {[key in K]: SyncUtilResponse<T>}
  ) {
    super();
  }

  public sync() {
    if (this.reportsResponse?.changedValues?.length) {
      const reportsClosedServer = this.reportsResponse.changedValues.filter((changedValue) => !!changedValue.serverValue.closedAt);
      for (const report of reportsClosedServer) {
        this.removeAllChangesForReport(report.serverValue);
      }
    }
  }

  private removeAllChangesForReport(report: Report) {
    const mapToId = (value: IdAware): IdType => value.id;
    const filterByReportId = (value: AttachmentReportSignature | ReportCompany | Employee | Activity | Equipment | Material | Staff) => value.reportId === report.id;
    this.undoLocalChangeAndAddSyncConflict(this.attachmentReportSignatureRequest, this.attachmentReportSignatureResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.reportCompanyRequest, this.reportCompanyResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.employeeRequest, this.employeeResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.activityRequest, this.activityResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.equipmentRequest, this.equipmentResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.materialRequest, this.materialResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.staffRequest, this.staffResponse, filterByReportId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);

    const reportCompanyIds = this.reportCompanyResponse.syncedValues.filter(filterByReportId).map(mapToId);
    const filterByReportCompanyId = (value: AttachmentReportCompany | ReportCompanyActivity): boolean => reportCompanyIds.includes(value.reportCompanyId);
    this.undoLocalChangeAndAddSyncConflict(this.attachmentReportCompanyRequest, this.attachmentReportCompanyResponse, filterByReportCompanyId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.reportCompanyActivityRequest, this.reportCompanyActivityResponse, filterByReportCompanyId, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);

    const activityIds = this.activityResponse.syncedValues.filter(filterByReportId).map(mapToId);
    const filterByActivity = (value: AttachmentReportActivity | PdfPlanMarker | ReportCompanyActivity): boolean => activityIds.includes(value.activityId);
    this.undoLocalChangeAndAddSyncConflict(this.attachmentReportActivityRequest, this.attachmentReportActivityResponse, filterByActivity, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
    this.undoLocalChangeAndAddSyncConflict(this.reportCompanyActivityRequest, this.reportCompanyActivityResponse, filterByActivity, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);

    const equipmentIds = this.equipmentResponse.syncedValues.filter(filterByReportId).map(mapToId);
    const filterByEquipment = (value: AttachmentReportEquipment | PdfPlanMarker): boolean => equipmentIds.includes(value.equipmentId);
    this.undoLocalChangeAndAddSyncConflict(this.attachmentReportEquipmentRequest, this.attachmentReportEquipmentResponse, filterByEquipment, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);

    const materialIds = this.materialResponse.syncedValues.filter(filterByReportId).map(mapToId);
    const filterByMaterial = (value: AttachmentReportMaterial | PdfPlanMarker): boolean => materialIds.includes(value.materialId);
    this.undoLocalChangeAndAddSyncConflict(this.attachmentReportMaterialRequest, this.attachmentReportMaterialResponse, filterByMaterial, ConflictType.REPORT_CLOSED_LOCAL_CHANGES_LOST);
  }
}

class SyncPdfProtocolSettings<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey> extends SyncBase {
  private pdfProtocolSettingsRequest: SyncUtilRequest<PdfProtocolSetting> = _.get(this.syncRequests, 'pdfProtocolSettings') as SyncUtilRequest<PdfProtocolSetting>;
  private pdfProtocolSettingsResponse: SyncUtilResponse<PdfProtocolSetting> = _.get(this.result, 'pdfProtocolSettings') as SyncUtilResponse<PdfProtocolSetting>;

  constructor(
    private syncRequests: {[key in K]: SyncUtilRequest<T>},
    private result: {[key in K]: SyncUtilResponse<T>}
  ) {
    super();
  }

  public sync() {
    if (this.pdfProtocolSettingsRequest?.localChangesData?.insert?.length) {
      const localSettingsInsert = this.pdfProtocolSettingsRequest.localChangesData.insert;
      for (const pdfProtocolSetting of localSettingsInsert) {
        const syncValueWithSameTypeAndProject = this.pdfProtocolSettingsResponse?.syncedValues.find(
          (syncedValue) => syncedValue.id !== pdfProtocolSetting.id && syncedValue?.projectId === pdfProtocolSetting?.projectId && syncedValue.protocolTypeId === pdfProtocolSetting?.protocolTypeId
        );
        if (syncValueWithSameTypeAndProject) {
          this.removeAllChangesForProtocolSetting(pdfProtocolSetting);
        }
      }
    }
  }

  private removeAllChangesForProtocolSetting(pdfProtocolSetting: PdfProtocolSetting) {
    const filterByProtocolSettingId = (value: PdfProtocolSetting) => value.id === pdfProtocolSetting.id;
    this.undoLocalChangeAndAddSyncConflict(
      this.pdfProtocolSettingsRequest,
      this.pdfProtocolSettingsResponse,
      filterByProtocolSettingId,
      ConflictType.PDF_PROTOCOL_SETTING_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST
    );
  }
}

class SyncProjectCalendar<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey> extends SyncBase {
  private projectCalendarRequest: SyncUtilRequest<ProjectCalendar> = _.get(this.syncRequests, 'projectCalendars') as SyncUtilRequest<ProjectCalendar>;
  private projectCalendarResponse: SyncUtilResponse<ProjectCalendar> = _.get(this.result, 'projectCalendars') as SyncUtilResponse<ProjectCalendar>;

  constructor(
    private syncRequests: {[key in K]: SyncUtilRequest<T>},
    private result: {[key in K]: SyncUtilResponse<T>}
  ) {
    super();
  }

  public sync() {
    if (this.projectCalendarRequest?.localChangesData?.insert?.length) {
      const localCalendarInsert = this.projectCalendarRequest.localChangesData.insert;
      for (const projectCalendar of localCalendarInsert) {
        const syncValueWithSameNameAndProjectAndClient = this.projectCalendarResponse?.syncedValues.find(
          (syncedValue) =>
            syncedValue.id !== projectCalendar.id &&
            syncedValue?.projectId === projectCalendar?.projectId &&
            syncedValue.name === projectCalendar?.name &&
            syncedValue?.clientId === projectCalendar?.clientId
        );
        if (syncValueWithSameNameAndProjectAndClient) {
          this.removeAllChangesForProtocolSetting(projectCalendar);
        }
      }
    }
  }

  private removeAllChangesForProtocolSetting(projectCalendar: ProjectCalendar) {
    const filterByCalendarId = (value: ProjectCalendar) => value.id === projectCalendar.id;
    this.undoLocalChangeAndAddSyncConflict(this.projectCalendarRequest, this.projectCalendarResponse, filterByCalendarId, ConflictType.PROJECT_CALENDAR_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST);
  }
}

class SyncNotificationConfigRecipient<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey> extends SyncBase {
  private notificationConfigRecipientRequest: SyncUtilRequest<NotificationConfigRecipient> = _.get(this.syncRequests, 'notificationConfigRecipients') as SyncUtilRequest<NotificationConfigRecipient>;
  private notificationConfigRecipientResponse: SyncUtilResponse<NotificationConfigRecipient> = _.get(this.result, 'notificationConfigRecipients') as SyncUtilResponse<NotificationConfigRecipient>;

  constructor(
    private syncRequests: {[key in K]: SyncUtilRequest<T>},
    private result: {[key in K]: SyncUtilResponse<T>}
  ) {
    super();
  }

  public sync() {
    if (this.notificationConfigRecipientRequest?.localChangesData?.insert?.length) {
      const localRecipientInsert = this.notificationConfigRecipientRequest.localChangesData.insert;
      for (const recipient of localRecipientInsert) {
        const syncValueWithSameConfigIdAndRecipientTypeAndProfileId = this.notificationConfigRecipientResponse?.syncedValues.find(
          (syncedValue) =>
            syncedValue.id !== recipient.id &&
            syncedValue?.profileId === recipient?.profileId &&
            syncedValue.notificationRecipientType === recipient?.notificationRecipientType &&
            syncedValue?.notificationConfigId === recipient?.notificationConfigId
        );
        const insertAndDeleteValueWithSameConfigIdAndRecipientTypeAndProfileId = this.notificationConfigRecipientResponse?.localChangesData?.delete.find(
          (deleteValue) =>
            deleteValue.id !== recipient.id &&
            deleteValue?.profileId === recipient?.profileId &&
            deleteValue.notificationRecipientType === recipient?.notificationRecipientType &&
            deleteValue?.notificationConfigId === recipient?.notificationConfigId
        );
        if (syncValueWithSameConfigIdAndRecipientTypeAndProfileId || insertAndDeleteValueWithSameConfigIdAndRecipientTypeAndProfileId) {
          this.removeChangesForNotificationConfigRecipient(recipient, insertAndDeleteValueWithSameConfigIdAndRecipientTypeAndProfileId);
        }
      }
    }
    if (this.notificationConfigRecipientRequest?.localChangesData?.localChangesInsertById?.size) {
      const localRecipientInsertById: NotificationConfigRecipient[] = Array.from(this.notificationConfigRecipientRequest.localChangesData.localChangesInsertById.values()).map(
        (localChange) => localChange.value
      );
      for (const recipient of localRecipientInsertById) {
        const insertAndDeleteValueWithSameConfigIdAndRecipientTypeAndProfileId = this.notificationConfigRecipientResponse?.localChangesData?.delete.find(
          (deleteValue) =>
            deleteValue.id === recipient.id &&
            deleteValue?.profileId === recipient?.profileId &&
            deleteValue.notificationRecipientType === recipient?.notificationRecipientType &&
            deleteValue?.notificationConfigId === recipient?.notificationConfigId
        );
        if (insertAndDeleteValueWithSameConfigIdAndRecipientTypeAndProfileId) {
          this.removeChangesForNotificationConfigRecipient(recipient, insertAndDeleteValueWithSameConfigIdAndRecipientTypeAndProfileId);
        }
      }
    }
  }

  private removeChangesForNotificationConfigRecipient(recipient: NotificationConfigRecipient | undefined, deleteRecipient: NotificationConfigRecipient | undefined) {
    const filterByRecipientId = (value: NotificationConfigRecipient) => value.id === recipient?.id || value.id === deleteRecipient?.id;
    this.undoLocalChangeAndAddSyncConflict(
      this.notificationConfigRecipientRequest,
      this.notificationConfigRecipientResponse,
      filterByRecipientId,
      ConflictType.NOTIFICATION_CONFIG_RECIPIENT_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST
    );
  }
}

class SyncProtocolEntryDefaultValues<T extends IdAware, K extends NonClientAwareKey | ClientAwareKey | ProjectAwareKey> extends SyncBase {
  private protocolEntryDefaultValueRequest: SyncUtilRequest<ProtocolEntryDefaultValue> = _.get(this.syncRequests, 'protocolEntryDefaultValues') as SyncUtilRequest<ProtocolEntryDefaultValue>;
  private protocolEntryDefaultValueResponse: SyncUtilResponse<ProtocolEntryDefaultValue> = _.get(this.result, 'protocolEntryDefaultValues') as SyncUtilResponse<ProtocolEntryDefaultValue>;

  constructor(
    private syncRequests: {[key in K]: SyncUtilRequest<T>},
    private result: {[key in K]: SyncUtilResponse<T>}
  ) {
    super();
  }

  public sync() {
    if (this.protocolEntryDefaultValueRequest?.localChangesData?.insert?.length) {
      const localDefaultInsert = this.protocolEntryDefaultValueRequest.localChangesData.insert;
      for (const defaultValue of localDefaultInsert) {
        const syncValueWithSameProtocolId = this.protocolEntryDefaultValueResponse?.syncedValues.find(
          (syncedValue) => syncedValue.id !== defaultValue.id && syncedValue.protocolId === defaultValue.protocolId
        );
        if (syncValueWithSameProtocolId) {
          this.removeAllChangesForDefaultValues(defaultValue);
        }
      }
    }
  }

  private removeAllChangesForDefaultValues(protocolEntryDefaultValue: ProtocolEntryDefaultValue) {
    const filterByDefaultValueId = (value: ProtocolEntryDefaultValue) => value.id === protocolEntryDefaultValue.id;
    this.undoLocalChangeAndAddSyncConflict(
      this.protocolEntryDefaultValueRequest,
      this.protocolEntryDefaultValueResponse,
      filterByDefaultValueId,
      ConflictType.PROTOCOL_DEFAULT_VALUES_EXISTS_ON_SERVER_LOCAL_CHANGES_LOST
    );
  }
}
