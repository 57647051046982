import {ProtocolEntryTypeDataService} from './protocol-entry-type-data.service';
import {Injectable} from '@angular/core';
import {IdType, ProjectProtocolEntryType, ProtocolEntryType, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import _ from 'lodash';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {combineLatestAsync, mapObjectsNameWithDeletedSuffix} from 'src/app/utils/async-utils';
import {TranslateService} from '@ngx-translate/core';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectProtocolEntryTypes';

@Injectable({
  providedIn: 'root',
})
export class ProjectProtocolEntryTypeDataService extends AbstractProjectAwareMappingDataService<ProjectProtocolEntryType> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private protocolEntryTypeService: ProtocolEntryTypeDataService,
    private translateService: TranslateService
  ) {
    super(
      StorageKeyEnum.PROJECT_PROTOCOL_ENTRY_TYPE,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  getProjectProtocolEntryTypes(additionalEntryTypeIdsToInclude?: Array<IdType>): Observable<ProtocolEntryType[]> {
    const additionalEntryTypesToIncludeSet = new Set(additionalEntryTypeIdsToInclude || []);
    return combineLatest([this.data, this.protocolEntryTypeService.data]).pipe(
      map(([projectProtocolEntryTypes, protocolEntryTypes]) =>
        protocolEntryTypes.filter(
          (entryType) =>
            (projectProtocolEntryTypes.find((projectEntryType) => projectEntryType.protocolentrytypeId === entryType.id) && entryType.isActive !== false) ||
            additionalEntryTypesToIncludeSet.has(entryType.id)
        )
      )
    );
  }

  getProjectProtocolEntryTypesWithDeletedSuffix(additionalEntryTypeIdsToInclude?: Array<IdType>): Observable<ProtocolEntryType[]> {
    return this.getProjectProtocolEntryTypes(additionalEntryTypeIdsToInclude).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }

  getProjectProtocolEntryTypesInProject(projectId: IdType, additionalEntryTypeIdsToInclude?: Array<IdType>): Observable<ProtocolEntryType[]> {
    const additionalEntryTypesToIncludeSet = new Set(additionalEntryTypeIdsToInclude || []);
    return combineLatestAsync([this.getDataForProject$(projectId), this.protocolEntryTypeService.dataAcrossClients$]).pipe(
      map(([projectProtocolEntryTypes, protocolEntryTypes]) =>
        protocolEntryTypes.filter(
          (entryType) =>
            (projectProtocolEntryTypes.find((projectEntryType) => projectEntryType.protocolentrytypeId === entryType.id) && entryType.isActive !== false) ||
            additionalEntryTypesToIncludeSet.has(entryType.id)
        )
      )
    );
  }

  getProjectProtocolEntryTypesInProjectWithDeletedSuffix(projectId: IdType, additionalEntryTypeIdsToInclude?: Array<IdType>): Observable<ProtocolEntryType[]> {
    if (!projectId) {
      return of([]);
    }
    return this.getProjectProtocolEntryTypesInProject(projectId, additionalEntryTypeIdsToInclude).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }

  getByProtocolEntryTypeAcrossProjects(protocolEntryType: ProtocolEntryType): Observable<ProjectProtocolEntryType[]> {
    return this.dataByProjectId$.pipe(
      map((allProjectProtocolEntryTypesMap) =>
        _.flatten(Array.from(allProjectProtocolEntryTypesMap.values())).filter(
          (projectProtocolEntryType: ProjectProtocolEntryType) => projectProtocolEntryType.protocolentrytypeId === protocolEntryType.id
        )
      )
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
