import {Pipe, PipeTransform} from '@angular/core';
import {PhotoService} from '../services/photo/photo.service';
import {Attachment} from 'submodules/baumaster-v2-common';
import {AttachmentBlob, OfflineAttachmentType} from '../model/attachments';
import {LoggingService} from '../services/common/logging.service';
import {OfflineError, TimeoutError} from '../shared/errors';

const LOG_SOURCE = 'ImageUriPipe';

@Pipe({
  name: 'imageUri',
})
export class ImageUriPipe implements PipeTransform {
  constructor(
    private photoService: PhotoService,
    private loggingService: LoggingService
  ) {}

  async transform(
    imageUri: string | Attachment | AttachmentBlob,
    attachmentType: OfflineAttachmentType = 'thumbnail',
    objectUrlCreated?: (objectUrl: string) => void,
    abortSignal?: AbortSignal
  ): Promise<string | null | undefined> {
    if (abortSignal?.aborted) {
      return null;
    }
    try {
      const objectUrl = await this.photoService.getObjectUrl(imageUri, attachmentType, objectUrlCreated, abortSignal);
      if (abortSignal?.aborted) {
        return null;
      }
      if (!objectUrlCreated) {
        this.loggingService.warn(LOG_SOURCE, 'transform called without objectUrlCreated. This might cause memory leaks.');
      }
      return objectUrl;
    } catch (error) {
      if (error instanceof OfflineError || error instanceof TimeoutError) {
        return undefined;
      }
    }
  }
}
