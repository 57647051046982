import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {PdfPlansFilter} from 'src/app/model/pdf-plans-filter';
import {ALL_FOLDERS_PAGE_SLUG} from 'src/app/shared/constants';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {IdType, LicenseType, PdfPlanFolder, ProtocolEntryLocation, TagBase, TagClientObject} from 'submodules/baumaster-v2-common';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {ProjectProtocolLocationDataService} from '../data/project-protocol-location-data.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {TagService} from '../tags/tag.service';
import {PdfPlanFolderService, PdfPlanFolderWithDeletable} from './pdf-plan-folder.service';
import {PdfPlanHolderService} from './pdf-plan-holder.service';
import {PdfPlansFilterService} from './pdf-plans-filter.service';
import {EMPTY_FILTER_ID} from 'src/app/utils/filter-utils';

const getFilterHoldersFn = (
  plansFilter: PdfPlansFilter,
  allTagObjectsById: Record<IdType, TagClientObject[]>
): ((value: PdfPlanWithDeletable, index: number, array: PdfPlanWithDeletable[]) => boolean) => {
  return (holder) =>
    ((plansFilter.includeActive && holder.active) || (plansFilter.includeInactive && !holder.active)) &&
    (!plansFilter.locationIds?.length ||
      plansFilter.locationIds.includes(holder.latestPdfPlanVersion.locationId) ||
      (plansFilter.locationIds.includes(EMPTY_FILTER_ID) && _.isEmpty(holder.latestPdfPlanVersion.locationId))) &&
    (!plansFilter.tags?.length ||
      holder.pdfPlanVersions.some(({id}) => {
        if (allTagObjectsById[id]) {
          const tags = allTagObjectsById[id].map((tagsObject) => tagsObject.tagId);
          return plansFilter.tags.every((tag) => tags.some((tagId) => tagId === tag.id));
        } else {
          return false;
        }
      }));
};

const distinctUntilTagsFilterPresenceChange = () => {
  return distinctUntilChanged((a: PdfPlansFilter, b: PdfPlansFilter) => {
    const aHasTags = !!a.tags?.length;
    const bHasTags = !!b.tags?.length;

    return aHasTags === bHasTags;
  });
};

export interface PdfPlanFolderWithHolders {
  id: IdType;
  pdfPlanFolder: PdfPlanFolder;
  pdfPlanHolders: PdfPlanWithDeletable[];
}

@Injectable({
  providedIn: 'root',
})
export class PdfPlansFilteredDataService {
  private allTagObjectById$ = this.pdfPlansFilterService.filter$.pipe(
    distinctUntilTagsFilterPresenceChange(),
    switchMap(({tags}) => (tags?.length > 0 ? this.tagService.getTagObjectsForObjectTypeById$('pdfPlanVersions') : of({})))
  );

  tagsUsedInPdfVersions$: Observable<TagBase[]> = this.pdfPlanVersionDataService.data.pipe(
    map((planVersions) => planVersions.map(({id}) => id)),
    distinctUntilChanged((a, b) => a.join(',') === b.join(',')),
    switchMap((planVersionIds) => this.tagService.getTagsForObjects$(planVersionIds, 'pdfPlanVersions')),
    map((tagsPerId) => _.uniqBy(_.flatten(Object.values(tagsPerId)), 'id'))
  );

  activeLocationsAndUsedInPdfVersions$: Observable<ProtocolEntryLocation[]> = this.pdfPlanVersionDataService.data.pipe(
    map((planVersions) => planVersions.map(({locationId}) => locationId)),
    distinctUntilChanged((a, b) => {
      if (a.length !== b.length) {
        return false;
      }
      const bSet = new Set(b);
      return a.every((id) => bSet.has(id));
    }),
    switchMap((locationIds) => this.projectLocationDataService.getProjectProtocolLocations(locationIds))
  );

  constructor(
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private projectLocationDataService: ProjectProtocolLocationDataService,
    private tagService: TagService,
    private route: ActivatedRoute,
    private featureEnabledService: FeatureEnabledService,
    private pdfPlanFolderService: PdfPlanFolderService,
    private pdfPlanHolderService: PdfPlanHolderService,
    private pdfPlansFilterService: PdfPlansFilterService
  ) {}

  getPdfPlansForFolder$(folderId$: Observable<string | undefined>): Observable<PdfPlanWithDeletable[]> {
    return this.getCanSeeInactiveHoldersAndFolderId$(folderId$).pipe(
      switchMap(([folderId, canSeeInactiveHolders]) => {
        if (!folderId || folderId === ALL_FOLDERS_PAGE_SLUG) {
          return of([]);
        }

        const query$ = this.route.queryParams.pipe(map(({q}) => q));
        return this.allTagObjectById$.pipe(
          switchMap((allTagObjectsById) => this.getFilteredPdfPlansForFolder$(folderId, query$, allTagObjectsById)),
          map((holders) => (canSeeInactiveHolders ? holders : holders.filter((holder) => holder.active)))
        );
      })
    );
  }

  getPdfPlanFoldersWithPlans$(folderId$: Observable<string | undefined>): Observable<PdfPlanFolderWithHolders[]> {
    return this.getCanSeeInactiveHoldersAndFolderId$(folderId$).pipe(
      switchMap(([folderId, canSeeInactiveHolders]) => {
        if (!folderId || folderId !== ALL_FOLDERS_PAGE_SLUG) {
          return of([] as PdfPlanFolderWithHolders[]);
        }

        const query$ = this.route.queryParams.pipe(map(({q}) => q));
        return combineLatestAsync([this.pdfPlanFolderService.getPdfPlanFolderWithDeletable$(of(undefined)), this.allTagObjectById$]).pipe(
          switchMap(([folders, allTagObjectsById]) => this.getFilteredPdfPlansPerFolder$(folders, query$, allTagObjectsById, canSeeInactiveHolders))
        );
      })
    );
  }

  private getCanSeeInactiveHoldersAndFolderId$(folderId$: Observable<string | undefined>) {
    return combineLatestAsync([folderId$, this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER])]);
  }

  private getFilteredPdfPlansPerFolder$(
    folders: PdfPlanFolderWithDeletable[],
    query$: Observable<string | undefined>,
    allTagObjectsById: Record<IdType, TagClientObject[]>,
    canSeeInactiveHolders: boolean
  ): Observable<PdfPlanFolderWithHolders[]> {
    if (folders.length === 0) {
      return of([]);
    }

    return combineLatestAsync(
      folders.map((pdfPlanFolder) =>
        this.getFilteredPdfPlansForFolder$(pdfPlanFolder.id, query$, allTagObjectsById).pipe(
          map((holders) => ({
            id: pdfPlanFolder.id,
            pdfPlanFolder,
            pdfPlanHolders: canSeeInactiveHolders ? holders : holders.filter((holder) => holder.active),
          }))
        )
      )
    );
  }

  private getFilteredPdfPlansForFolder$(pdfPlanFolderId: IdType, query$: Observable<string | undefined>, allTagObjectsById: Record<IdType, TagClientObject[]>): Observable<PdfPlanWithDeletable[]> {
    return this.pdfPlanHolderService
      .getPdfPlansForFolderId$(pdfPlanFolderId, query$)
      .pipe(
        switchMap((holders) =>
          this.pdfPlansFilterService.sortAndDirectionAndFilter$.pipe(
            map(([sort, sortDirection, plansFilter]) => _.orderBy(holders.filter(getFilterHoldersFn(plansFilter, allTagObjectsById)), [`latestPdfPlanVersion.${sort}`], [sortDirection]))
          )
        )
      );
  }
}
