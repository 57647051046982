<ion-toolbar>
  <ion-buttons slot="start">
    <ng-content></ng-content>
  </ion-buttons>

  <ion-buttons slot="end">
    <ng-container *ngIf="startTemplate">
      <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
    </ng-container>
    <ion-button class="omg-btn-text ion-hide-sm-down" (click)="selectable._close()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button *ngIf="selectable.canClear" [ngClass]="(selectable.isMultiple || !autoCloseOnSelect) ? 'omg-btn-secondary' : 'omg-btn-primary'" (click)="selectable._clear()">
      {{ 'reset' | translate }}
    </ion-button>
    <ion-button class="omg-btn-primary" *ngIf="selectable.isMultiple || !autoCloseOnSelect" (click)="selectable._confirm()">
      {{ 'button.apply' | translate }}
    </ion-button>
    <ng-container *ngIf="endTemplate">
      <ng-container *ngTemplateOutlet="endTemplate"></ng-container>
    </ng-container>
  </ion-buttons>
</ion-toolbar>
