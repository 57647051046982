import {ColumnConfig} from '../ColumnConfig';

export const PdfPlanVersionAccessColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'pdfplan_version_id', prop: 'pdfPlanVersionId', cnd: true},
  {name: 'user_id', prop: 'userId', cnd: true},
  {name: 'type', prop: 'type'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
