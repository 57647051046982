import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {TagObjectType} from 'src/app/model/tag';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {IdType, TagBase, TagClient, TagClientObject} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {ClientService} from '../client/client.service';
import {TagClientDataService} from '../data/tag-client-data.service';
import {TagClientObjectDataService} from '../data/tag-client-object-data.service';

const objectTypeToBucket: Partial<Record<TagObjectType, 'client'>> = {
  pdfPlanVersions: 'client',
};

@Injectable({
  providedIn: 'root',
})
export class TagService {
  clientTags$ = this.tagClientDataService.dataActive$.pipe(startWith([]));

  constructor(
    private tagClientDataService: TagClientDataService,
    private clientService: ClientService,
    private tagClientObjectDataService: TagClientObjectDataService
  ) {}

  getAllTagsForObjectType$(objectType: TagObjectType): Observable<TagBase[]> {
    const tagsBucket = this.getTagsBucketOrThrow(objectType);
    if (tagsBucket === 'client') {
      return this.clientTags$;
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  getTagObjectsForObjectTypeById$(targetObjectType: TagObjectType): Observable<Record<IdType, TagClientObject[]>> {
    const tagsBucket = this.getTagsBucketOrThrow(targetObjectType);
    if (tagsBucket === 'client') {
      return this.tagClientObjectDataService.data.pipe(
        map((tagObjects) => tagObjects.filter(({objectType}) => objectType === targetObjectType)),
        map((tagObjects) => {
          const groupedTags: Record<IdType, TagClientObject[]> = {};

          tagObjects.forEach((tagObject) => {
            groupedTags[tagObject.objectId] = [...(groupedTags[tagObject.objectId] ?? []), tagObject];
          });

          return groupedTags;
        }),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      );
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  getTagsForObject$(objectId: IdType, objectType: TagObjectType): Observable<TagClient[]> {
    const tagsBucket = this.getTagsBucketOrThrow(objectType);
    if (tagsBucket === 'client') {
      return this.getClientTagsForObject$(objectId, objectType);
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  getTagsForObjects$(objectIds: IdType[], objectType: TagObjectType): Observable<Record<IdType, TagClient[]>> {
    const tagsBucket = this.getTagsBucketOrThrow(objectType);
    if (tagsBucket === 'client') {
      return this.getClientTagsForObjects$(objectIds, objectType);
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  async createTag(tag: TagBase, objectType: TagObjectType): Promise<TagBase> {
    const tagsBucket = this.getTagsBucketOrThrow(objectType);
    if (tagsBucket === 'client') {
      const existing = await observableToPromise(this.tagClientDataService.getByName(tag.name));
      if (existing) {
        return (
          await this.tagClientDataService.update({
            ...existing,
            color: tag.color,
            isActive: true,
          })
        )[0];
      }
      const tagToInsert = {
        ...tag,
        clientId: this.clientService.getCurrentClientMandatory().id,
        changedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      };
      await this.tagClientDataService.insert(tagToInsert);
      return tagToInsert;
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  deleteTag(tag: TagBase, objectType: TagObjectType): Promise<TagBase[]> {
    return this.updateTag({...tag, isActive: false}, objectType);
  }

  async updateTag(tag: TagBase, objectType: TagObjectType): Promise<TagBase[]> {
    const tagsBucket = this.getTagsBucketOrThrow(objectType);
    if (tagsBucket === 'client') {
      return this.tagClientDataService.update((storage) => {
        const tagInStorage = storage.find((theTag) => theTag.id === tag.id);

        if (!tagInStorage) {
          return [];
        }

        return {
          ...tagInStorage,
          ...tag,
        };
      });
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  async saveGroupedTags(tagsByObjectId: Record<IdType, TagBase[]>, objectType: TagObjectType): Promise<void> {
    const tagsBucket = this.getTagsBucketOrThrow(objectType);
    if (tagsBucket === 'client') {
      const affectedObjectIds = Object.keys(tagsByObjectId);
      if (affectedObjectIds.length === 0) {
        return;
      }
      const desiredTagObjects = Object.entries(tagsByObjectId)
        .map(([objectId, tags]) =>
          tags.map((tag) => ({
            tagId: tag.id,
            objectId,
            objectType,
          }))
        )
        .reduce((acc, val) => acc.concat(val), []);
      const insertFn = (storageData: TagClientObject[]) => {
        // Set of existing tags in the storage
        const existingTagsInStorage = new Set(storageData.map(({objectId, tagId}) => `${objectId},${tagId}`));
        const tagsToInsert: TagClientObject[] = desiredTagObjects
          // Do not include already existing tags in the insert
          .filter(({objectId, tagId}) => !existingTagsInStorage.has(`${objectId},${tagId}`))
          .map((tagObject) => ({
            ...tagObject,
            id: v4(),
            changedAt: new Date().toISOString(),
          }));

        return tagsToInsert;
      };
      const deleteFn = (storageData: TagClientObject[]) => {
        // Set of tags, that should stay
        const tagsToStaySet = new Set(desiredTagObjects.map(({objectId, tagId}) => `${objectId},${tagId}`));
        const tagsToDelete = storageData
          // Include only tags that are not in tags to stay set
          .filter(({objectId, tagId, objectType: type}) => objectType === type && affectedObjectIds.includes(objectId) && !tagsToStaySet.has(`${objectId},${tagId}`));

        return tagsToDelete;
      };
      await this.tagClientObjectDataService.insertUpdateDelete({
        inserts: insertFn,
        deletes: deleteFn,
        insertOptions: {
          dismissDuplicateKeys: true,
        },
        deleteOptions: {
          dismissNotExistingValue: true,
        },
      });

      return;
    }

    throw new Error(`Unhandled tags bucket ${tagsBucket} (programmer error)`);
  }

  private getTagsBucketOrThrow(objectType: TagObjectType): 'client' {
    const tagsBucket = objectTypeToBucket[objectType];
    if (tagsBucket === 'client') {
      return 'client';
    }

    throw new Error(`Unsupported tags bucket "${tagsBucket}" (for object type ${objectType})`);
  }

  private getClientTagsForObjects$(objectIds: IdType[], objectType: IdType): Observable<Record<IdType, TagClient[]>> {
    return combineLatestAsync([this.tagClientObjectDataService.dataGroupedByObjectId$, this.tagClientDataService.dataGroupedById]).pipe(
      map(([groupedTagObjects, tagsById]) => {
        const groupedTags: Record<IdType, TagClient[]> = {};

        objectIds.forEach((objectId) => {
          groupedTags[objectId] = groupedTagObjects[objectId]?.map((tagObject) => tagsById[tagObject.tagId]) ?? [];
        });

        return groupedTags;
      }),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  private getClientTagsForObject$(objectId: IdType, objectType: IdType): Observable<TagClient[]> {
    return this.tagClientObjectDataService.dataGroupedByObjectId$.pipe(
      switchMap((groupedTagObjects) =>
        groupedTagObjects[objectId]
          ? this.tagClientDataService.getByIds(groupedTagObjects[objectId].filter((tagObject) => tagObject.objectType === objectType).map((tagObject) => tagObject.tagId))
          : of([])
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }
}
