import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {Address, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/addresses/';

@Injectable({
  providedIn: 'root',
})
export class AddressDataService extends AbstractClientAwareDataService<Address> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(StorageKeyEnum.ADDRESS, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  protected async isValid(obj: Address): Promise<{valid: true; message?: string} | {valid: false; message: string}> {
    if (!obj) {
      return {valid: false, message: `Address is falsy.`};
    }
    const missingFields = this.validateMandatoryFields(obj, 'clientId', 'changedAt');
    if (missingFields.length) {
      return {valid: false, message: `Mandatory fields "${missingFields}" missing for Address with id ${obj.id}`};
    }
    if (!this.ownClient) {
      return {valid: false, message: `Unable to valid address with id ${obj.id} as ownClient is falsy`};
    }
    if (obj.clientId !== this.ownClient?.id) {
      return {valid: false, message: `Address with id ${obj.id} is for clientId ${obj.clientId} but only the ownClientId (${this.ownClient.id}) is allowed.`};
    }
    return {valid: true};
  }
}
