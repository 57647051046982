import {Injectable, OnDestroy} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {SUPERUSER_DEVICE_UUID} from 'submodules/baumaster-v2-common';
import {from, Observable} from 'rxjs';
import {LoggingService} from '../common/logging.service';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import {UserInviteService} from './userInvite.service';
import {UserConnectionInviteService} from './userConnectionInvite.service';
import {Device} from '@capacitor/device';
import {combineLatestAsync} from '../../utils/async-utils';
import {AuthDetails} from 'src/app/model/auth';
import {TokenManagerService} from './token-manager.service';

const LOG_SOURCE = 'AuthHttpInterceptor';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor, OnDestroy {
  private readonly allowList: string[] = [
    this.authenticationService.LOGIN_URL,
    this.authenticationService.IMPERSONATE_LOGIN_URL,
    this.authenticationService.RESET_PASSWORD_SEND_URL,
    this.authenticationService.RESET_PASSWORD_CONFIRM_URL,
    UserInviteService.USER_INVITE_CONFIRM_URL,
    UserConnectionInviteService.USER_INVITE_CONFIRM_URL,
  ];
  private readonly allowListPatterns: RegExp[] = [
    /\/[a-zA-Z]{2}\.json$/, // Translation files
  ];
  private readonly ignoreExpiredTokenList: string[] = [AuthenticationService.REFRESH_TOKEN_URL];
  private deviceUuid$: Observable<string>;

  constructor(
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private tokenManagerService: TokenManagerService
  ) {
    this.deviceUuid$ = from(Device.getId()).pipe(map((deviceId) => deviceId.identifier));
  }

  ngOnDestroy(): void {}

  private interceptWithAuth(request: HttpRequest<any>, next: HttpHandler, auth: AuthDetails | null | undefined, deviceUuid: string): Observable<HttpEvent<any>> {
    if (auth) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Token ' + auth.token,
          'Device-Uuid': auth?.impersonated ? SUPERUSER_DEVICE_UUID : deviceUuid,
        },
      });
    } else {
      this.loggingService.info(LOG_SOURCE, `No auth set. Unable to add Authorization header for request "${request.url}".`);
    }
    return next.handle(request);
  }

  private isInAllowList(url: string) {
    if (this.allowList.includes(url)) {
      return true;
    }

    if (this.allowListPatterns.some((pattern) => pattern.test(url))) {
      return true;
    }

    return false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isInAllowList(request.url)) {
      return next.handle(request);
    }
    return combineLatestAsync([this.deviceUuid$, this.authenticationService.data])
      .pipe(
        filter(([, auth]) => {
          if (!auth) {
            return true;
          }

          if (!('refreshToken' in auth)) {
            return true;
          }

          const isTokenExpired = auth.tokenExpiresAt < Date.now();

          if (isTokenExpired) {
            this.tokenManagerService.scheduleTokenRefresh();
          }

          return !isTokenExpired || this.ignoreExpiredTokenList.includes(request.url);
        }),
        take(1) // Make sure it won't re-fire the request once next auth data is pushed
      )
      .pipe(mergeMap(([deviceUuid, auth]) => this.interceptWithAuth(request, next, auth, deviceUuid)));
  }
}
