import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ProtocolEntryCreateComponent} from 'src/app/components/protocol/protocol-entry-create/protocol-entry-create.component';
import {Nullish} from 'src/app/model/nullish';
import {observableToPromise} from 'src/app/utils/async-utils';
import {IdType, LicenseType, ProtocolEntry, ProtocolEntryType} from 'submodules/baumaster-v2-common';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {EntryService} from '../entry/entry.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {EntryCreationMode} from 'src/app/model/entry-creation-mode';
import {UnitService} from '../unit/unit.service';

type CreateEntryAdditionalOptions = {
  entryCreationMode?: EntryCreationMode;
  entryCreateComponentProps?: Nullish<
    Partial<{
      expressView: boolean;
      navigateOnSuccess: boolean;
      parentEntryId: IdType;
      onlyActionableEntryTypes: boolean;
      defaultEntryType: ProtocolEntryType;
      typeRequired: boolean;
    }>
  >;
};

type CreateEntryResult =
  | {role: undefined; data: unknown; entry?: never}
  | {
      role: 'save';
      data: ProtocolEntry;
      entry: ProtocolEntry;
    };

@Injectable({
  providedIn: 'root',
})
export class CreateEntryService {
  readonly canCreateEntry$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);
  readonly canCreateProtocolEntry$ = this.canCreateEntry$;
  readonly canCreateTask$ = this.canCreateEntry$;

  constructor(
    private featureEnabledService: FeatureEnabledService,
    private entryService: EntryService,
    private modalController: ModalController,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private unitService: UnitService
  ) {}

  async createTask({protocolTaskId, entryCreationMode, entryCreateComponentProps = {}}: CreateEntryAdditionalOptions & {protocolTaskId?: Nullish<IdType>} = {}): Promise<CreateEntryResult> {
    if (!(await observableToPromise(this.canCreateTask$))) {
      return;
    }
    if (!protocolTaskId) {
      protocolTaskId = (await observableToPromise(this.entryService.taskProtocol$))?.id;
    }
    if (!protocolTaskId) {
      throw new Error(`Couldn't find task protocol!`);
    }

    const defaultEntryType = await observableToPromise(this.protocolEntryTypeDataService.taskEntryType$);

    return this.createEntry(protocolTaskId, {
      entryCreationMode,
      entryCreateComponentProps: {
        navigateOnSuccess: false,
        onlyActionableEntryTypes: true,
        defaultEntryType,
        typeRequired: true,
        ...entryCreateComponentProps,
      },
    });
  }

  async createProtocolEntry(protocolId: IdType, {entryCreateComponentProps = {}}: CreateEntryAdditionalOptions = {}): Promise<CreateEntryResult> {
    if (!(await observableToPromise(this.canCreateProtocolEntry$))) {
      return;
    }

    return this.createEntry(protocolId, {entryCreateComponentProps});
  }

  private async createEntry(protocolId: IdType, {entryCreationMode, entryCreateComponentProps = {}}: CreateEntryAdditionalOptions = {}): Promise<CreateEntryResult> {
    const defaultUnit = await observableToPromise(this.unitService.getUnitDefaultForProtocol$(protocolId));
    const modal = await this.modalController.create({
      component: ProtocolEntryCreateComponent,
      keyboardClose: false,
      backdropDismiss: false,
      componentProps: {
        protocolId,
        expressView: true,
        isTask: true,
        startWithWorkflow: entryCreationMode,
        defaultUnit,
        ...entryCreateComponentProps,
      },
    });
    await modal.present();

    const {role, data} = await modal.onWillDismiss();

    if (role === 'save') {
      return {role, data, entry: data};
    }
    return {role: undefined, data};
  }
}
