import {ColumnConfig} from '../ColumnConfig';

const UniqueDeviceProjectUser = ['device_uuid', 'project_id', 'user_id'];

export const UserDeviceOfflineProjectColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'device_uuid', prop: 'deviceUuid', uniqueWith: [UniqueDeviceProjectUser]},
  {name: 'project_id', prop: 'projectId', uniqueWith: [UniqueDeviceProjectUser]},
  {name: 'user_id', prop: 'userId', uniqueWith: [UniqueDeviceProjectUser]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
