import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ProjectCompanyDataService} from '../../../services/data/project-company-data.service';
import {IdType, Participant, PdfPreview, Profile, Protocol, ProtocolEntry, Report, ReportCompany} from 'submodules/baumaster-v2-common';
import {ProtocolDataService} from '../../../services/data/protocol-data.service';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ContactService} from '../../../services/contact/contact.service';
import _ from 'lodash';
import {CompanySource, Employee} from '../../../model/contacts';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {ParticipantDataService} from '../../../services/data/participant-data.service';
import {PdfMailingListCompany, PdfMailingListEmployee, WorkflowType} from '../../../model/send-protocol';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {UserNameString} from '../../../utils/user-name.pipe';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {IonSearchbar, IonicModule, Platform} from '@ionic/angular';
import {ProtocolEntrySearchFilterService} from '../../../services/search/protocol-entry-search-filter.service';
import {ReportCompanyDataService} from '../../../services/data/report-company-data.service';
import {ReportService} from '../../../services/report/report.service';
import {LoggingService} from '../../../services/common/logging.service';
import {ProjectProfileDataService} from '../../../services/data/project-profile-data.service';
import {ProtocolEntrySearchFilteredDataService} from 'src/app/services/search/protocol-entry-search-filtered-data.service';
import {PdfPreviewDataService} from '../../../services/data/pdf-preview-data.service';
import {UnitService} from '../../../services/unit/unit.service';
import {UnitForBreadcrumbsWithProfileAddresses} from '../../../model/unit';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {PdfMailingListTableComponent} from '../pdf-mailing-list-table/pdf-mailing-list-table.component';
import {PipesModule} from 'src/app/pipes/pipes.module';

const LOG_SOURCE = 'PdfMailingListComponent';

interface ParticipantInsertUpdateDelete {
  inserts: Array<Participant>;
  updates: Array<Participant>;
  deletes: Array<Participant>;
}

@Component({
  selector: 'app-pdf-mailing-list',
  templateUrl: './pdf-mailing-list.component.html',
  styleUrls: ['./pdf-mailing-list.component.scss'],
  standalone: true,
  imports: [IonicModule, FormsModule, TranslateModule, CommonModule, PdfMailingListTableComponent, PipesModule],
})
export class PdfMailingListComponent implements OnInit, OnDestroy {
  @Input() hideHeader = false;
  @Input() workflowType: WorkflowType = WorkflowType.Protocol;
  @Input() protocol?: Protocol;
  @Input() report?: Report;
  @Input() reports?: Report[];
  @Input() searchTextInput: string;
  @Input() pdfPreview?: PdfPreview;
  @Input() readOnly = false;
  @Input() skipEmptyCompanies = false;
  @Input() skipInactiveContacts = false;
  @Input() skipInactiveCompanies = false;
  @Input() showProjectTeam = true;
  @Input() showAssignedInProject = true;
  @Input() hideParticipantCheckbox = false;
  @Input() mailingListCompanies$?: Observable<Array<PdfMailingListCompany>>;
  @Input() showCompanyPin = true;
  @Input() hideSelectAllButtons = false;
  @Output() recipientChanged = new EventEmitter<{employee: PdfMailingListEmployee}>();
  @Output() presentChanged = new EventEmitter<{employee: PdfMailingListEmployee}>();
  @Output() searchTextChanged = new EventEmitter<{searchText: string}>();
  @Output() allParticipantLoaded = new EventEmitter<{participants: Participant[]}>();

  private sortCompaniesByNameOnlySubject = new BehaviorSubject(false);
  isUnitsEnabled: boolean | undefined;

  @Input()
  set sortCompaniesByNameOnly(sortCompaniesByNameOnly: boolean | undefined) {
    const value = sortCompaniesByNameOnly ?? true;
    if (value !== this.sortCompaniesByNameOnly) {
      this.sortCompaniesByNameOnlySubject.next(value);
    }
  }

  get sortCompaniesByNameOnly() {
    return this.sortCompaniesByNameOnlySubject.value;
  }

  @ViewChild('autofocus', {static: false}) searchbar: IonSearchbar;
  public mailingListCompanies: Array<PdfMailingListCompany>;
  public filteredMailingListCompanies: Array<PdfMailingListCompany>;
  private mailingListCompaniesSubscription: Subscription | undefined;
  private changes = new Map<string, Participant>();
  public isUnitSectionEmpty: boolean;
  public isPreSelectedSectionEmpty: boolean;
  public isAssignedEmployeeSectionEmpty: boolean;
  public isProjectTeamSectionEmpty: boolean;
  private currentNumberOfSubscriptionCalls = 0;
  private readonly MAX_NUMBER_OF_SUBSCRIPTION_CALL = 500;
  public WorkflowTypeEnum = WorkflowType;
  allCheckedRecipientAssigned = false;
  allCheckedPresentAssigned = false;
  allCheckedRecipient = false;
  allCheckedPresent = false;
  allCheckedRecipientUnassigned = false;
  allCheckedPresentUnassigned = false;
  allCheckedRecipientUnit = false;
  allCheckedPresentUnit = false;

  constructor(
    private projectCompanyDataService: ProjectCompanyDataService,
    private protocolDataService: ProtocolDataService,
    private projectDataService: ProjectDataService,
    private participantDataService: ParticipantDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private contactService: ContactService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private userNameString: UserNameString,
    private protocolService: ProtocolService,
    public platform: Platform,
    private pdfPreviewDataService: PdfPreviewDataService,
    private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService,
    private reportCompanyDataService: ReportCompanyDataService,
    private protocolEntrySearchFilteredDataService: ProtocolEntrySearchFilteredDataService,
    private reportService: ReportService,
    private loggingService: LoggingService,
    private unitService: UnitService
  ) {}

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy');
    this.mailingListCompaniesUnsubscribe();
  }

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, `ngOnInit (workflowType=${this.workflowType}`);
    let participants$: Observable<Array<Participant>>;
    let protocolEntries$: Observable<Array<ProtocolEntry>>;
    let mailingListCompanies$: Observable<Array<PdfMailingListCompany>>;
    let sortedCompanies$: Observable<Array<CompanySource>>;

    this.isUnitsEnabled =
      (await observableToPromise(this.unitService.isFeatureEnabled$)) &&
      this.workflowType === WorkflowType.Protocol &&
      this.protocol &&
      (await this.protocolService.isStandardProtocol(this.protocol)) &&
      !!this.protocol.unitId;

    if (this.mailingListCompanies$) {
      mailingListCompanies$ = this.mailingListCompanies$;
    } else if (this.workflowType === WorkflowType.Protocol) {
      if (!this.protocol) {
        throw new Error('Workflow is of type Protocol but no protocol was provided.');
      }
      participants$ = this.pdfPreview
        ? this.participantDataService.getByPdfPreviewIdAndProtocolId(this.pdfPreview.id, this.protocol.id)
        : this.participantDataService.getByProtocolId(this.protocol.id);
      protocolEntries$ = this.protocolEntryDataService.getByProtocolId(this.protocol.id);
      sortedCompanies$ = this.isUnitsEnabled ? this.contactService.getSortedCompaniesWithUnitContacts$(this.protocol.id) : this.contactService.sortedCompanies$;
      const unitForBreadcrumbs$ = this.isUnitsEnabled ? this.unitService.getUnitForBreadcrumbAndProfileAddressesByProtocolId$(this.protocol.id) : of(undefined);
      mailingListCompanies$ = this.getMailingListCompaniesForProtocol(participants$, sortedCompanies$, protocolEntries$, unitForBreadcrumbs$);
    } else if (this.workflowType === WorkflowType.GlobalSearch) {
      participants$ = this.pdfPreview ? this.participantDataService.getForGlobalSearchAndPdfPreview(this.pdfPreview.id) : this.participantDataService.getForGlobalSearchCurrentProject();
      protocolEntries$ = this.protocolEntrySearchFilteredDataService.protocolEntries$.pipe(
        map((values) => {
          const entries = new Array<ProtocolEntry>();
          values.forEach((value) => {
            entries.push(value.entry);
            entries.push(...value.entryChildren);
          });
          return entries;
        })
      );
      sortedCompanies$ = this.contactService.sortedCompaniesAcrossProjects$;
      mailingListCompanies$ = this.getMailingListCompaniesForGlobalSearch(participants$, sortedCompanies$, protocolEntries$);
    } else if (this.workflowType === WorkflowType.Report) {
      if (!this.report) {
        throw new Error('Workflow is of type Report but no report was provided.');
      }
      mailingListCompanies$ = this.getMailingListCompaniesForReport(this.report);
    } else if (this.workflowType === WorkflowType.EntryMail) {
      participants$ = this.participantDataService.getForGlobalSearchCurrentProject().pipe(map((participant) => participant.filter((p) => this.isParticipantAssignedToCurrentProject(p))));
      protocolEntries$ = this.protocolEntryDataService.getByProtocolId(
        (await observableToPromise(this.protocolService.getTaskProtocolForProject$((await this.projectDataService.getMandatoryCurrentProject()).id))).id
      );
      sortedCompanies$ = this.contactService.getFilteredAndSortedCompaniesActiveOnly();
      mailingListCompanies$ = this.getMailingListCompaniesForGlobalSearch(participants$, sortedCompanies$, protocolEntries$);
    } else {
      throw new Error(`WorkflowType ${this.workflowType} does not exist.does not supported`);
    }

    this.mailingListCompaniesSubscription = mailingListCompanies$
      .pipe(
        switchMap((companies) =>
          this.sortCompaniesByNameOnlySubject.pipe(
            switchMap((sortCompaniesByNameOnly) => {
              if (sortCompaniesByNameOnly) {
                return of(companies);
              }

              return this.projectCompanyDataService.dataByCompanyId$.pipe(
                map((dataByCompanyId) => _.sortBy(companies, [(company) => dataByCompanyId[company.id]?.sortOrder, (company) => company.name.toLocaleLowerCase()], ['asc', 'asc']))
              );
            })
          )
        )
      )
      .subscribe((mailingListCompanies) => {
        this.mailingListCompanies = mailingListCompanies;
        this.filteredMailingListCompanies = mailingListCompanies;
        if (!_.isEmpty(this.searchTextInput)) {
          this.searchEmployee();
        }
        const allParticipants = _.chain(this.mailingListCompanies).map('allEmployees').flatten().map('participant').value();
        this.updateParticipants(allParticipants);
        this.initSectionEmptyStatus();
      });
  }

  private getMailingListCompaniesForReport(report: Report): Observable<PdfMailingListCompany[]> {
    const participants$ = this.pdfPreview ? this.participantDataService.getByPdfPreviewIdAndReportId(this.pdfPreview.id, this.report.id) : this.participantDataService.getByReportId(this.report.id);
    const sortedCompanies$ = this.contactService.sortedCompanies$;
    const reportCompanies$ = this.reports ? this.reportCompanyDataService.getByReports(this.reports.map((value) => value.id)) : this.reportCompanyDataService.getByReport(report.id);
    const lastPdfPreview$ = this.reportService.getLastSentPdfPreview(report);

    return combineLatest([participants$, sortedCompanies$, reportCompanies$, lastPdfPreview$]).pipe(
      debounceTime(0),
      switchMap(async ([participants, companySources, reportCompanies, lastPdfPreview]) => {
        if (participants.length === 0 && lastPdfPreview) {
          let participantsFromLastReport = await observableToPromise(this.participantDataService.getByReportId(lastPdfPreview.reportId));
          participantsFromLastReport = _.uniqBy(participantsFromLastReport, 'profileId').filter((participant) => participant.mailingList);
          if (participantsFromLastReport.length !== 0) {
            participants = await this.copyParticipantsToReport(participantsFromLastReport, report);
          }
        }
        return await this.getMailingListCompanies(companySources, participants, null, reportCompanies, this.reports ?? [report]);
      })
    );
  }

  private getMailingListCompaniesForProtocol(
    participants$: Observable<Participant[]>,
    sortedCompanies$: Observable<CompanySource[]>,
    protocolEntries$: Observable<ProtocolEntry[]>,
    unitForBreadcrumbs$: Observable<UnitForBreadcrumbsWithProfileAddresses | undefined>
  ): Observable<PdfMailingListCompany[]> {
    return combineLatestAsync([participants$, sortedCompanies$, protocolEntries$, unitForBreadcrumbs$]).pipe(
      switchMap(async ([participants, companySources, protocolEntries, unitForBreadcrumbs]) => {
        if (participants.length === 0 && this.protocol && (await this.protocolService.isContinuousProtocol(this.protocol))) {
          const previousProtocol: Protocol = await this.protocolService.getPreviousContinuousProtocol(this.protocol);
          if (previousProtocol) {
            let participantsFromPreviousProtocol = await observableToPromise(this.participantDataService.getByProtocolId(previousProtocol.id));
            if (!participantsFromPreviousProtocol?.length) {
              const pdfPreviews = await observableToPromise(this.pdfPreviewDataService.getByProtocolIdSortedByIndexNumber(previousProtocol.id));
              const lastPdfPreview = _.last(pdfPreviews);
              if (lastPdfPreview) {
                participantsFromPreviousProtocol = await observableToPromise(this.participantDataService.getByPdfPreviewId(previousProtocol.id, lastPdfPreview.id));
              }
            }
            participantsFromPreviousProtocol = _.uniqBy(
              participantsFromPreviousProtocol.filter((participant) => participant.mailingList),
              'profileId'
            );
            if (participantsFromPreviousProtocol.length !== 0) {
              participants = await this.copyParticipantsToProtocol(participantsFromPreviousProtocol, this.protocol);
            }
          }
        }
        return this.getMailingListCompanies(companySources, participants, protocolEntries, undefined, undefined, unitForBreadcrumbs);
      })
    );
  }

  private getMailingListCompaniesForGlobalSearch(participants$: Observable<Participant[]>, sortedCompanies$: Observable<CompanySource[]>, protocolEntries$: Observable<ProtocolEntry[]>) {
    return combineLatestAsync([participants$, sortedCompanies$, protocolEntries$]).pipe(
      switchMap(async ([participants, companySources, protocolEntries]) => {
        return this.getMailingListCompanies(companySources, participants, protocolEntries);
      })
    );
  }

  private async getMailingListCompanies(
    companySources: CompanySource[],
    participants: Participant[],
    protocolEntries?: ProtocolEntry[],
    reportCompanies?: ReportCompany[],
    reports?: Report[],
    unitForBreadcrumbs?: UnitForBreadcrumbsWithProfileAddresses
  ): Promise<PdfMailingListCompany[]> {
    const unitEnabledForWorkflowType = this.isUnitsEnabled && this.workflowType === WorkflowType.Protocol;
    const initUnitContactsRecipientParticipant = unitEnabledForWorkflowType && participants.length === 0;
    const participantsToUpdateOrDelete: ParticipantInsertUpdateDelete = {
      inserts: new Array<Participant>(),
      updates: new Array<Participant>(),
      deletes: new Array<Participant>(),
    };
    const filters = await observableToPromise(this.protocolEntrySearchFilterService.filters$);
    const projectCompanies: Array<CompanySource> = _.filter(companySources, (companySource) => {
      if ((this.workflowType === WorkflowType.GlobalSearch || this.workflowType === WorkflowType.EntryMail) && filters.project.in.length > 0) {
        return this.skipInactiveCompanies
          ? !!companySource.projectCompany &&
              (companySource.isActive === undefined || companySource.isActive) &&
              companySource.projectCompanies.some((projectCompany) => filters.project.in.includes(projectCompany.projectId))
          : true;
      }
      if (unitEnabledForWorkflowType && companySource.isUserCompany) {
        return true;
      }
      return this.skipInactiveCompanies ? !!companySource.projectCompany && (companySource.isActive === undefined || companySource.isActive) : !!companySource.projectCompany;
    });
    const otherCompanies = _.difference(companySources, projectCompanies);
    for (const otherCompany of otherCompanies) {
      const participantsToDelete = this.filterByEmployeeNotInProjectOrDeleted(otherCompany.employees, participants);
      participantsToUpdateOrDelete.deletes.push(...participantsToDelete);
    }
    const pdfMailingListCompanies = projectCompanies.map((projectCompany) => {
      const participantsToDelete = this.filterByEmployeeNotInProjectOrDeleted(projectCompany.employees, participants);
      participantsToUpdateOrDelete.deletes.push(...participantsToDelete);

      const mailingListEmployees = projectCompany.employees
        .filter((employee) => {
          if ((this.workflowType === WorkflowType.GlobalSearch || this.workflowType === WorkflowType.EntryMail) && filters.project.in.length > 0) {
            if ((this.workflowType === WorkflowType.GlobalSearch || this.workflowType === WorkflowType.EntryMail) && filters.project.in.length > 0) {
              return this.skipInactiveContacts
                ? !_.isEmpty(employee.projectProfile) &&
                    (employee.profile.isActive === undefined || employee.profile.isActive) &&
                    employee.projectProfiles.some((projectProfile) => filters.project.in.includes(projectProfile.projectId))
                : true;
            }
          }
          return this.skipInactiveContacts ? !_.isEmpty(employee.projectProfile) && (employee.profile.isActive === undefined || employee.profile.isActive) : true;
        })
        .filter((employee) => !this.pdfPreview || participants.some((participant) => participant.profileId === employee.profile.id))
        .map((employee) => {
          let participant = participants.find((participantData) => participantData.profileId === employee.profile.id);
          if (!participant) {
            participant = this.createParticipant(employee.profile.id, this.protocol?.id || null, this.report?.id || null);
            if (initUnitContactsRecipientParticipant && this.isUnitProfile(employee.profile)) {
              participant.mailingList = true;
              participant.present = true;
              participantsToUpdateOrDelete.inserts.push(participant);
            }
          } else {
            const localChangedParticipant = this.changes.get(employee.profile.id);
            if (localChangedParticipant) {
              participant.mailingList = localChangedParticipant.mailingList;
              participant.present = localChangedParticipant.present;
            }
            if (_.isEmpty(employee.email) && participant.mailingList) {
              participant.mailingList = false;
              participantsToUpdateOrDelete.updates.push(participant);
            }
          }
          return {
            ...employee,
            hasProtocol: protocolEntries ? protocolEntries.some((protocolEntry) => protocolEntry.internalAssignmentId === employee.profile.id) : undefined,
            hasReport:
              reportCompanies && reports
                ? reportCompanies.some((reportCompany) => reportCompany.companyId === employee.profile.companyId && reports.some((report) => report.id === reportCompany.reportId))
                : undefined,
            participant: _.cloneDeep(participant),
          } as PdfMailingListEmployee;
        });
      const unitContacts = !this.isUnitsEnabled
        ? undefined
        : mailingListEmployees.filter(
            (employee) => this.isUnitContact(employee) && unitForBreadcrumbs?.unitProfileAddresses.some((unitProfileAddress) => unitProfileAddress.profile.id === employee.profile.id)
          );

      const pdfMailingListCompany: PdfMailingListCompany = {
        ...projectCompany,
        allEmployees: mailingListEmployees,
        preSelectedEmployees: mailingListEmployees.filter((employee) => !this.isUnitContact(employee) && projectCompany.projectCompany && employee.participant.mailingList),
        assignedEmployees: mailingListEmployees.filter((employee) => !this.isUnitContact(employee) && projectCompany.projectCompany && this.isAssignedEmployee(employee)),
        unassignedEmployees: mailingListEmployees.filter((employee) => !this.isUnitContact(employee) && projectCompany.projectCompany && this.isUnassignedEmployee(employee)),
        unitContacts,
      };
      if (unitContacts?.length && unitForBreadcrumbs) {
        pdfMailingListCompany.unitPdfMailingListCompany = {
          ...pdfMailingListCompany,
          name: unitForBreadcrumbs.breadcrumbsName,
          crafts: [],
        };
      }
      return pdfMailingListCompany;
    });
    if (participantsToUpdateOrDelete.inserts.length || participantsToUpdateOrDelete.updates.length || participantsToUpdateOrDelete.deletes.length) {
      await this.cleanupParticipantsFromLocalStorage(participantsToUpdateOrDelete);
    }
    return pdfMailingListCompanies;
  }

  private isAssignedEmployee = (employee: PdfMailingListEmployee): boolean => {
    if (this.workflowType === WorkflowType.Protocol || this.workflowType === WorkflowType.GlobalSearch || this.workflowType === WorkflowType.EntryMail) {
      return employee.hasProtocol && !employee.participant.mailingList;
    }
    if (this.workflowType === WorkflowType.Report) {
      return employee.hasReport && !employee.participant.mailingList;
    }
    return false;
  };

  private isUnassignedEmployee = (employee: PdfMailingListEmployee): boolean => {
    if (this.workflowType === WorkflowType.Protocol || this.workflowType === WorkflowType.GlobalSearch || this.workflowType === WorkflowType.EntryMail) {
      return !employee.hasProtocol && !employee.participant.mailingList;
    }
    if (this.workflowType === WorkflowType.Report) {
      return !employee.hasReport && !employee.participant.mailingList;
    }
    return false;
  };

  private isUnitContact = (employee: PdfMailingListEmployee): boolean => {
    return this.isUnitProfile(employee.profile);
  };

  private isUnitProfile = (profile: Profile): boolean => {
    if (this.workflowType === WorkflowType.Protocol) {
      return profile.type && profile.type === 'UNIT_CONTACT';
    }
    return false;
  };

  private endlessLoopSafetyCheck() {
    if (this.currentNumberOfSubscriptionCalls > this.MAX_NUMBER_OF_SUBSCRIPTION_CALL) {
      throw new Error('Possible endless loop detected. Subscription has benn called more often than ' + this.currentNumberOfSubscriptionCalls);
    }
    return this.currentNumberOfSubscriptionCalls++;
  }

  async cleanupParticipantsFromLocalStorage(participantInsertUpdateDelete: ParticipantInsertUpdateDelete): Promise<void> {
    if (this.readOnly) {
      return; // never updated anything when in readOnly mode (user does not have permission or readonly in ProjectRoom)
    }
    if (!participantInsertUpdateDelete.inserts?.length && !participantInsertUpdateDelete.updates?.length && !participantInsertUpdateDelete.deletes?.length) {
      return;
    }
    this.endlessLoopSafetyCheck();
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    await this.participantDataService.insertUpdateDelete(participantInsertUpdateDelete, currentProject.id);
  }

  initSectionEmptyStatus() {
    this.isPreSelectedSectionEmpty = _.chain(this.filteredMailingListCompanies).map('preSelectedEmployees').flatten().isEmpty().value();
    this.isAssignedEmployeeSectionEmpty = _.chain(this.filteredMailingListCompanies).map('assignedEmployees').flatten().isEmpty().value();
    this.isProjectTeamSectionEmpty = _.chain(this.filteredMailingListCompanies).map('unassignedEmployees').flatten().isEmpty().value();
    this.isUnitSectionEmpty = !!_.chain(this.filteredMailingListCompanies).map('unitContacts')?.flatten()?.isEmpty()?.value();

    this.allCheckedRecipientAssigned = this.checkMasterBox('recipient', 'assigned');
    this.allCheckedRecipientUnassigned = this.checkMasterBox('recipient', 'unassigned');
    this.allCheckedRecipient = this.checkMasterBox('recipient');
    this.allCheckedRecipientUnit = this.checkMasterBox('recipient', 'unit');
    this.allCheckedPresentAssigned = this.checkMasterBox('present', 'assigned');
    this.allCheckedPresentUnassigned = this.checkMasterBox('present', 'unassigned');
    this.allCheckedPresent = this.checkMasterBox('present');
    this.allCheckedPresentUnit = this.checkMasterBox('present', 'unit');
  }

  updateParticipants(allParticipants: Array<Participant>) {
    this.allParticipantLoaded.emit({participants: allParticipants});
  }

  createParticipant(profileId: IdType, protocolId?: IdType, reportId?: IdType): Participant {
    return {
      id: null,
      invited: false,
      mailingList: false,
      present: false,
      profileId,
      protocolId,
      reportId,
      changedAt: new Date().toISOString(),
      pdfpreviewId: null,
      presentFrom: null,
      presentTo: null,
      seenAt: null,
    } as Participant;
  }

  filterByEmployeeNotInProjectOrDeleted(employees: Employee[], participants: Participant[]): Array<Participant> {
    const participantsToDelete = new Array<Participant>();
    for (const employee of employees) {
      const filteredParticipants = participants.filter(
        (participant) =>
          participant.profileId === employee.profile.id && (!employee.projectProfile || !employee.profile.isActive || employee.removedUnitProfile) && !participant.pdfpreviewId && !participant.seenAt
      );
      participantsToDelete.push(...filteredParticipants);
    }
    return participantsToDelete;
  }

  async copyParticipantsToProtocol(participants: Array<Participant>, protocol: Protocol): Promise<Participant[]> {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    const participantsForInsert: Array<Participant> = [];
    for (const participant of participants) {
      if (!(await observableToPromise(this.contactService.isProjectProfileAndActive$(participant.profileId)))) {
        continue;
      }
      const newParticipant = this.createParticipant(participant.profileId, protocol.id);
      newParticipant.mailingList = true;
      participantsForInsert.push(newParticipant);
    }
    return await this.participantDataService.insert(participantsForInsert, currentProject.id);
  }

  private isParticipantAssignedToCurrentProject = async (participant: Participant): Promise<boolean> => {
    return !!(await observableToPromise(this.projectProfileDataService.getByProfileId(participant.profileId)));
  };

  async copyParticipantsToReport(participants: Array<Participant>, report: Report): Promise<Participant[]> {
    const participantsForInsert: Array<Participant> = [];
    for (const participant of participants) {
      if (!(await this.isParticipantAssignedToCurrentProject(participant))) {
        continue;
      }
      const newParticipant = this.createParticipant(participant.profileId, null, report.id);
      newParticipant.mailingList = true;
      participantsForInsert.push(newParticipant);
    }
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    return await this.participantDataService.insert(participantsForInsert, currentProject.id);
  }

  onRecipientChanged(employee: PdfMailingListEmployee) {
    const employeeOriginal = this.findEmployeeInOriginalData(employee);
    if (employeeOriginal) {
      employeeOriginal.participant.mailingList = employee.participant.mailingList;
      this.changes.set(employee.participant.profileId, employee.participant);
      this.recipientChanged.emit({employee: employeeOriginal});
    } else {
      throw new Error(`Updating participant failed, employee with id = "${employee.id}" does not exist.`);
    }
    this.allCheckedRecipientAssigned = this.checkMasterBox('recipient', 'assigned');
    this.allCheckedRecipientUnassigned = this.checkMasterBox('recipient', 'unassigned');
    this.allCheckedRecipient = this.checkMasterBox('recipient');
    this.allCheckedRecipientUnit = this.checkMasterBox('recipient', 'unit');
  }

  onPresentChanged(employee: PdfMailingListEmployee) {
    const employeeOriginal = this.findEmployeeInOriginalData(employee);
    if (employeeOriginal) {
      employeeOriginal.participant.present = employee.participant.present;
      this.changes.set(employee.participant.profileId, employee.participant);
      this.presentChanged.emit({employee: employeeOriginal});
    } else {
      throw new Error(`Updating participant failed, employee with id = "${employee.id}" does not exist.`);
    }
    this.allCheckedPresentAssigned = this.checkMasterBox('present', 'assigned');
    this.allCheckedPresentUnassigned = this.checkMasterBox('present', 'unassigned');
    this.allCheckedPresent = this.checkMasterBox('present');
    this.allCheckedPresentUnit = this.checkMasterBox('present', 'unit');
  }

  private findEmployeeInOriginalData(employee: PdfMailingListEmployee): PdfMailingListEmployee {
    for (const company of this.mailingListCompanies) {
      const employeeOriginal = company.allEmployees.find((e) => e.id === employee.id);
      if (employeeOriginal) {
        return employeeOriginal;
      }
    }
  }

  searchEmployee() {
    this.searchTextChanged.emit({searchText: this.searchTextInput});
    this.filteredMailingListCompanies = _.cloneDeep(this.mailingListCompanies);
    if (!_.isEmpty(this.searchTextInput)) {
      this.filteredMailingListCompanies.forEach((projectCompany) => {
        projectCompany.preSelectedEmployees = projectCompany.preSelectedEmployees.filter((employee) => this.matchesSearchText(employee));
        projectCompany.assignedEmployees = projectCompany.assignedEmployees.filter((employee) => this.matchesSearchText(employee));
        projectCompany.unassignedEmployees = projectCompany.unassignedEmployees.filter((employee) => this.matchesSearchText(employee));
      });
      this.initSectionEmptyStatus();
    }
  }

  matchesSearchText(employee: PdfMailingListEmployee): boolean {
    return this.userNameString.transform(employee)?.toLowerCase().includes(this.searchTextInput.toLowerCase());
  }

  private mailingListCompaniesUnsubscribe() {
    this.mailingListCompaniesSubscription?.unsubscribe();
    this.mailingListCompaniesSubscription = undefined;
  }

  checkAllPresentOrRecipient(allChecked: boolean, recipientOrPresent: 'recipient' | 'present', whichEmployees?: 'unassigned' | 'assigned' | 'unit' | undefined) {
    for (const company of this.filteredMailingListCompanies) {
      let employees;
      switch (whichEmployees) {
        case 'unassigned':
          employees = company.unassignedEmployees;
          break;
        case 'assigned':
          employees = company.assignedEmployees;
          break;
        case 'unit':
          employees = company.unitContacts;
          break;
        default:
          employees = company.preSelectedEmployees;
      }
      for (const employee of employees) {
        if (recipientOrPresent === 'recipient') {
          if (employee.email) {
            employee.participant.mailingList = allChecked;
            this.onRecipientChanged(employee);
          }
        } else {
          employee.participant.present = allChecked;
          this.onPresentChanged(employee);
        }
      }
    }
  }

  checkMasterBox(recipientOrPresent: 'recipient' | 'present', whichEmployees?: 'unassigned' | 'assigned' | 'unit' | undefined) {
    for (const company of this.filteredMailingListCompanies) {
      let employees;
      switch (whichEmployees) {
        case 'unassigned':
          employees = company.unassignedEmployees;
          break;
        case 'assigned':
          employees = company.assignedEmployees;
          break;
        case 'unit':
          employees = company.unitContacts ?? [];
          break;
        default:
          employees = company.preSelectedEmployees;
      }
      for (const employee of employees) {
        if (recipientOrPresent === 'recipient') {
          if (employee.email) {
            if (!employee.participant.mailingList) {
              return false;
            }
          }
        } else {
          if (!employee.participant.present) {
            return false;
          }
        }
      }
    }
    return true;
  }

  showParticipantCheckbox() {
    return !this.hideParticipantCheckbox && this.workflowType !== this.WorkflowTypeEnum.Report && this.workflowType !== this.WorkflowTypeEnum.EntryMail;
  }
}
