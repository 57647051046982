import {Injectable} from '@angular/core';
import {Company, IdType, ProjectCompany, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, map, shareReplay} from 'rxjs/operators';
import {ProjectDataService} from './project-data.service';
import {CompanyDataService} from './company-data.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {CompanyWithProjectId} from 'src/app/model/project-company';
import * as _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectCompanies';

@Injectable({
  providedIn: 'root',
})
export class ProjectCompanyDataService extends AbstractProjectAwareMappingDataService<ProjectCompany> {
  readonly dataByCompanyId$: Observable<Record<IdType, ProjectCompany>> = this.data.pipe(
    map((data) => _.keyBy(data, 'companyId')),
    shareReplay(1)
  );

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private companyService: CompanyDataService
  ) {
    super(
      StorageKeyEnum.PROJECT_COMPANY,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  async update(
    valueArrayOrFunction: Array<ProjectCompany> | ((storageData: Array<ProjectCompany>) => Array<ProjectCompany> | ProjectCompany | undefined) | ProjectCompany,
    projectId: IdType
  ): Promise<Array<ProjectCompany>> {
    return this.updateInternal(valueArrayOrFunction, projectId);
  }

  getDataByCompanyId$(projectId: IdType): Observable<Record<IdType, ProjectCompany>> {
    return this.dataByProjectId$.pipe(
      map((byProjectId) => byProjectId.get(projectId)),
      map((data) => _.keyBy(data, 'companyId')),
      shareReplay(1)
    );
  }

  getProjectCompanies(): Observable<Company[]> {
    return combineLatest([this.data, this.companyService.data]).pipe(
      map(([projectCompanies, companies]) => companies.filter((company) => projectCompanies.find((projectCompany) => projectCompany.companyId === company.id)))
    );
  }

  getProjectCompaniesWithProjectId(withAssignmentIds: IdType[] = []): Observable<CompanyWithProjectId[]> {
    return combineLatest([this.data, this.companyService.data]).pipe(
      map(([projectCompanies, companies]) =>
        companies
          .map((company) => ({
            company,
            projectCompany: projectCompanies.find((projectCompany) => projectCompany.companyId === company.id),
          }))
          .filter(({company, projectCompany}) => withAssignmentIds.includes(company.id) || projectCompany)
          .map(({company, projectCompany}) => ({
            ...company,
            projectIds: projectCompany ? [projectCompany.projectId] : [],
          }))
      )
    );
  }

  getActiveProjectCompanies(): Observable<Company[]> {
    return this.getProjectCompanies().pipe(map((companies) => companies.filter((company) => company.isActive)));
  }

  getAcrossProjectCompanies(): Observable<CompanyWithProjectId[]> {
    return combineLatest([this.dataAcrossProjects$, this.companyService.dataAcrossClients$]).pipe(
      debounceTime(0),
      map(([projectCompanies, companies]) => {
        const companyWithProjectId: CompanyWithProjectId[] = [];
        for (const company of companies) {
          const existingInProjects = projectCompanies.filter((projectCompany) => projectCompany.companyId === company.id);
          if (existingInProjects.length > 0) {
            companyWithProjectId.push({
              ...company,
              projectIds: existingInProjects.map(({projectId}) => projectId),
            });
          }
        }
        return companyWithProjectId;
      })
    );
  }

  getProjectCompanyByProjectIds(projectIds: IdType[], withAssignmentIds: IdType[] = []): Observable<CompanyWithProjectId[]> {
    return this.getAcrossProjectCompanies().pipe(
      debounceTime(0),
      map((projectCompanies) => projectCompanies.filter((projectCompany) => withAssignmentIds.includes(projectCompany.id) || projectCompany.projectIds.some((id) => projectIds.includes(id))))
    );
  }

  isCompanyInTheProject(companyId: IdType): Observable<boolean> {
    return this.getProjectCompanies().pipe(map((companies) => companies.some(({id}) => companyId === id)));
  }

  async insertToProject(companyId: IdType) {
    const projectId = (await this.projectDataService.getCurrentProject()).id;
    const projectCompany: ProjectCompany = {
      id: projectId + companyId,
      projectId,
      companyId,
      changedAt: new Date().toISOString(),
    };
    await this.insert(projectCompany, projectId);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
