import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormGroup} from '@angular/forms';
import {IdType, Project, Protocol, ProtocolEntryDefaultValue, ProtocolType} from 'submodules/baumaster-v2-common';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {Subscription} from 'rxjs';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import _ from 'lodash';
import {AuthenticationService} from '../../../services/auth/authentication.service';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {ProtocolOpenEntryDataService} from '../../../services/data/protocol-open-entry-data.service';
import {ErrorWithUserMessage} from '../../../shared/errors';
import {LoggingService} from '../../../services/common/logging.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ProtocolFormComponent} from 'src/app/components/protocol/protocol-form/protocol-form.component';
import {convertDateTimeToISOString, trimTimeFromDate} from 'src/app/utils/date-utils';
import {v4 as uuid4} from 'uuid';
import {ClientService} from '../../../services/client/client.service';
import {Router} from '@angular/router';
import {ToastService} from 'src/app/services/common/toast.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {ProtocolEntryDefaultValueDataService} from 'src/app/services/data/protocol-entry-default-value-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';

const LOG_SOURCE = 'ProtocolCreateComponent';

@Component({
  selector: 'app-protocol-create',
  templateUrl: './protocol-create.component.html',
  styleUrls: ['./protocol-create.component.scss'],
})
export class ProtocolCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() protocolType: ProtocolType;
  @ViewChild('appProtocolForm', {static: false}) appProtocolForm: ProtocolFormComponent;

  public protocolFormDirty = false;
  public protocolForm: UntypedFormGroup;

  private modal: HTMLIonModalElement;
  protected protocolData: Array<Protocol>;
  private protocolDataSubscription: Subscription | undefined;
  private currentProject: Project | undefined;
  private authSubscription: Subscription | undefined;
  private authenticatedUserId: IdType | undefined;
  public loading: boolean | undefined;
  private currentProjectSubscription: Subscription;
  private formStatusChangeSubscription: Subscription | undefined;

  constructor(
    private alertCtrl: AlertController,
    private toastService: ToastService,
    private projectDataService: ProjectDataService,
    private protocolDataService: ProtocolDataService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private protocolService: ProtocolService,
    private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private posthogService: PosthogService,
    private protocolEntryDefaultValueDataService: ProtocolEntryDefaultValueDataService
  ) {}

  async ngOnInit() {
    this.setCanDismiss();
    this.protocolDataSubscription = this.protocolDataService.dataWithoutHidden$.subscribe((protocols) => {
      this.protocolData = protocols;
    });
    this.authSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => {
      this.authenticatedUserId = authenticatedUserId;
    });
    this.currentProjectSubscription = await this.projectDataService.currentProjectObservable.subscribe((currentProject) => (this.currentProject = currentProject));
    if (this.protocolType) {
      const nextNumber = this.protocolService.getProtocolNextNumber(this.protocolType.id, this.protocolData);
      this.appProtocolForm.setNextNumberForThisType(nextNumber);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.appProtocolForm.inputName.setFocus();
      if (!_.isEmpty(this.protocolType)) {
        this.appProtocolForm.setProtocolType(this.protocolType);
        this.copySortEntriesByFromPreviousProtocol(this.protocolType);
        this.copyIncludesVatFromPreviousContinuousProtocol(this.protocolType);
        this.copyDefaultValuesFromPreviousContinuousProtocol(this.protocolType);
      }
    }, 500);
    this.formStatusChangeSubscription = this.appProtocolForm.protocolForm.statusChanges.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.protocolDataSubscription) {
      this.protocolDataSubscription.unsubscribe();
      this.protocolDataSubscription = undefined;
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = undefined;
    }
    this.currentProjectSubscription?.unsubscribe();
    this.currentProjectSubscription = undefined;
    this.formStatusChangeSubscription?.unsubscribe();
    this.formStatusChangeSubscription = undefined;
  }

  async protocolTypeChanged(protocolType: ProtocolType) {
    const isContinuous = await this.protocolService.isContinuousProtocolType(protocolType.id);
    await this.copyIncludesVatFromPreviousContinuousProtocol(protocolType, isContinuous);
    if (isContinuous) {
      await this.copySortEntriesByFromPreviousProtocol(protocolType);
      await this.copyDefaultValuesFromPreviousContinuousProtocol(protocolType);
    } else {
      this.appProtocolForm.setGroupByEntries(null);
    }
    const nextNumber = this.protocolService.getProtocolNextNumber(protocolType.id, this.protocolData);
    this.appProtocolForm.setNextNumberForThisType(nextNumber);
    const nameControl = this.protocolForm.get('name');
    if (_.isEmpty(nameControl.value)) {
      nameControl.setValue(protocolType.name);
      setTimeout(async () => {
        this.appProtocolForm.inputName.setFocus();
        if (Capacitor.isPluginAvailable('Keyboard')) {
          await Keyboard.show();
        }
      }, 300);
    }
  }

  private async getPreviousProtocol(protocolTypeId: IdType) {
    const dummyProtocol = {id: uuid4(), typeId: protocolTypeId} as Protocol;
    const previousProtocol = await this.protocolService.getPreviousContinuousProtocol(dummyProtocol);

    return previousProtocol;
  }

  private async copyIncludesVatFromPreviousContinuousProtocol(protocolType: ProtocolType, isContinuous?: boolean) {
    if (isContinuous === undefined) {
      isContinuous = await this.protocolService.isContinuousProtocolType(protocolType.id);
    }
    if (isContinuous) {
      const previousProtocol = await this.getPreviousProtocol(protocolType.id);
      if (previousProtocol) {
        this.appProtocolForm.setIncludesVat(previousProtocol.includesVat);
        return;
      }
    }
  }

  private async copySortEntriesByFromPreviousProtocol(protocolType: ProtocolType) {
    const previousProtocol = await this.getPreviousProtocol(protocolType.id);
    if (!_.isEmpty(previousProtocol)) {
      this.appProtocolForm.setGroupByEntries(previousProtocol.sortEntriesBy);
    }
  }

  private async copyDefaultValuesFromPreviousContinuousProtocol(protocolType: ProtocolType) {
    const isContinuous = await this.protocolService.isContinuousProtocolType(protocolType.id);
    if (!isContinuous) {
      return;
    }
    const previousProtocol = await this.getPreviousProtocol(protocolType.id);
    if (!_.isEmpty(previousProtocol)) {
      const defaultValues = await observableToPromise(this.protocolEntryDefaultValueDataService.getByProtocolId(previousProtocol.id));
      if (!defaultValues) {
        return;
      }
      await this.appProtocolForm.defaultValueComponent.fillWithPreviousProtocolValues(defaultValues);
    }
  }

  setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  async saveProtocol() {
    if (!this.protocolForm.valid) {
      await this.showValidationFormError(this.translateService.instant('form_error_validation_message'));
      return;
    }
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, `Create protocol`);
    try {
      this.loading = true;
      const rawData = this.protocolForm.getRawValue();
      const defaultValuesControl = this.protocolForm.get('defaultValues');
      logInstance.logCheckpoint(() => `(layoutId=${rawData.type?.layoutId}, type=${rawData.type?.name} (${rawData.type?.id}))`);
      const protocol = await this.createNewProtocol(rawData);

      if (defaultValuesControl.dirty) {
        const defaultValue = await this.createProtocolEntryDefaultValue(protocol.id);
        logInstance.logCheckpoint(() => `ProtocolEntryDefaultValue created (id=${defaultValue?.id}, protocolId=${protocol.id})`);
      }
      this.protocolForm.reset();
      logInstance.success(() => `newId=${protocol.id},number=${protocol.number}`);
      await this.modal.dismiss(protocol, 'save');
    } catch (error) {
      logInstance.failure(error);
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage;
      } else {
        message = error.message;
      }
      this.loggingService.error(LOG_SOURCE, `Error inserting protocol. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - saveProtocol', error?.userMessage + '-' + error?.message);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', message);
    } finally {
      this.loading = false;
    }
  }

  async createProtocolEntryDefaultValue(protocolId: IdType): Promise<ProtocolEntryDefaultValue> {
    const defaultValuesControl = this.protocolForm.get('defaultValues');
    const rawDefaultValue = defaultValuesControl.getRawValue();
    const newDate = new Date().toISOString();
    const protocolDefaultValue: ProtocolEntryDefaultValue = {
      id: uuid4(),
      protocolId: protocolId,
      createdAt: newDate,
      changedAt: newDate,
      companyId: rawDefaultValue.company?.id ?? null,
      priority: rawDefaultValue.priority,
      craftId: rawDefaultValue.craft?.id ?? null,
      locationId: rawDefaultValue.location?.id ?? null,
      nameableDropdownId: rawDefaultValue.nameableDropdownId ? rawDefaultValue.nameableDropdownId : null,
      internalAssignmentId: rawDefaultValue.internalAssignmentId?.id ?? null,
      observerCompanyIds: rawDefaultValue.observerCompanies,
      startDate: rawDefaultValue.startDate,
      todoUntil: rawDefaultValue.todoUntil,
      allCompanies: rawDefaultValue.company && rawDefaultValue.company?.id === null ? true : false,
    };
    await this.protocolEntryDefaultValueDataService.insert(protocolDefaultValue, this.currentProject.id);
    return protocolDefaultValue;
  }

  async createNewProtocol(rawData): Promise<Protocol> {
    try {
      this.systemEventService.logEvent(LOG_SOURCE + '.createNewProtocol', 'createNewProtocol called');
      if (!this.currentProject) {
        throw new Error('Unable to create new Protocol as currentProject is not set.');
      }
      const ownClient = await this.clientService.getOwnClientMandatory();
      const protocol: Protocol = {
        id: null,
        number: rawData.number ?? this.protocolService.getProtocolNextNumber(rawData.type?.id, this.protocolData),
        name: rawData.name,
        date: convertDateTimeToISOString(trimTimeFromDate(rawData.date)),
        timeFrom: convertDateTimeToISOString(rawData.timeFrom),
        timeUntil: convertDateTimeToISOString(rawData.timeUntil),
        location: rawData.location,
        closedAt: null,
        projectId: this.currentProject.id,
        typeId: rawData.type?.id,
        sortEntriesBy: rawData.sortEntriesBy?.uniqueId,
        ownerClientId: ownClient.id,
        changedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        createdById: this.authenticatedUserId,
        includesVat: rawData.includesVat,
        weather: rawData.weather,
        minTemp: rawData.temperatureMin,
        maxTemp: rawData.temperatureMax,
        humidity: rawData.humidity,
        windspeed: rawData.windspeed,
        weatherActivated: rawData.weatherActivated,
        weatherFromApi: rawData.weatherFromApi ?? false,
        unitId: rawData.unit?.id ?? null,
        isUnitEntryDefault: rawData.unit ? rawData.isUnitEntryDefault : null,
      };
      this.systemEventService.logEvent(LOG_SOURCE + '.createNewProtocol', `before protocolDataService.insert. currentProjectId is ${this.currentProject?.id}`);
      await this.protocolDataService.insert(protocol, this.currentProject.id);
      this.systemEventService.logEvent(LOG_SOURCE + '.createNewProtocol', `after protocolDataService.insert. currentProjectId is ${this.currentProject?.id}`);
      const protocolOpenEntries = await this.protocolService.copyOpenEntriesForContinuousProtocol(protocol);
      this.systemEventService.logEvent(LOG_SOURCE + '.createNewProtocol', `after protocolService.copyOpenEntriesForContinuousProtocol. currentProjectId is ${this.currentProject?.id}`);
      if (protocolOpenEntries?.length) {
        this.systemEventService.logEvent(LOG_SOURCE + '.createNewProtocol', `before protocolOpenEntryDataService.insert. currentProjectId is ${this.currentProject?.id}`);
        await this.protocolOpenEntryDataService.insert(protocolOpenEntries, this.currentProject.id);
        this.systemEventService.logEvent(LOG_SOURCE + '.createNewProtocol', `after protocolOpenEntryDataService.insert. currentProjectId is ${this.currentProject?.id}`);
      }
      this.posthogService.captureEvent('[Protocols] Created new protocol', {});
      return protocol;
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + '.createNewProtocol', error?.userMessage + '-' + error?.message);
      throw error;
    }
  }

  async showValidationFormError(errorMessage) {
    await this.toastService.errorWithMessageAndHeader('form_error_validation_header', errorMessage);
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'save') {
      return true;
    }

    if (!this.protocolFormDirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss',
        },
      ],
    });
    await alert.present();

    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  dismissModal() {
    return this.modal.dismiss();
  }

  async handleNavigateAway(routerUrl: string) {
    if (!routerUrl) {
      this.loggingService.error(LOG_SOURCE, 'Ignoring navigateAway since routerUrl is empty.');
    }
    const closed = await this.dismissModal();
    if (closed) {
      await this.router.navigate([routerUrl]);
    }
  }
}
