import {Injectable} from '@angular/core';
import {Client, ClientType} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/client';

@Injectable({
  providedIn: 'root',
})
export class ClientDataService extends AbstractNonClientAwareDataService<Client> {
  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.CLIENT, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService, integrityResolverService);
  }

  public getOwnClient(): Observable<Client | undefined> {
    return this.data.pipe(map((clients) => clients.find((client) => client.type === undefined || client.type === ClientType.OWN)));
  }
}
