import {ColumnConfig} from '../ColumnConfig';

export const BimVersionColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'name', prop: 'name'},
  {name: 'number', prop: 'number'},
  {name: 'bim_plan_id', prop: 'bimPlanId', cnd: true},
  {name: 'autodesk_urn', prop: 'autodeskUrn', def: null},
  {name: 'index', prop: 'index', def: null},
  {name: 'note', prop: 'note', def: null},
  {name: 'status', prop: 'status'},
];
