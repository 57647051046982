import {inject, Injectable} from '@angular/core';
import _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap} from 'rxjs/operators';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {execEntriesFilter} from 'src/app/utils/filter-utils';
import {IdType, ProtocolEntryChat} from 'submodules/baumaster-v2-common';
import {EntryCardListModel} from '../../model/entry-card-model';
import {combineLatestAsync} from '../../utils/async-utils';
import {comparePrimitiveArrays, distinctUntilUnsortedArraysByObjectKeysChanged} from '../../utils/compare-utils';
import {ProtocolEntryChatDataService} from '../data/protocol-entry-chat-data.service';
import {ProtocolEntrySearchFilterService} from '../search/protocol-entry-search-filter.service';

@Injectable()
export abstract class AbstractEntryListService {
  private searchSubject = new BehaviorSubject<string>('');
  search$ = this.searchSubject.asObservable();

  get search(): string {
    return this.searchSubject.value;
  }

  set search(searchValue: string) {
    this.searchSubject.next(searchValue);
  }

  private readonly withSubentriesSubject = new BehaviorSubject(true);

  withSubEntries$ = this.withSubentriesSubject.asObservable();

  private readonly sortOrderAscSubject = new BehaviorSubject(true);

  sortOrderAsc$ = this.sortOrderAscSubject.asObservable();

  set withSubEntries(withSubEntries: boolean) {
    this.withSubentriesSubject.next(withSubEntries);
  }

  get withSubEntries() {
    return this.withSubentriesSubject.value;
  }

  get sortOrderAsc() {
    return this.sortOrderAscSubject.value;
  }

  set sortOrderAsc(sortOrderAsc: boolean) {
    this.sortOrderAscSubject.next(sortOrderAsc);
  }

  readonly entries$: Observable<EntryCardListModel[]>;
  readonly hasEntries$: Observable<boolean>;
  readonly entriesSortedWithSubEntriesAware$: Observable<EntryCardListModel[]>;
  readonly entryChats$: Observable<ProtocolEntryChat[]>;
  readonly entryChatsByEntryId$: Observable<Record<IdType, ProtocolEntryChat[]>>;

  private readonly filterEntryBySearchText = (searchText: string, entries: EntryCardListModel[], chatsByEntryId: Record<IdType, ProtocolEntryChat[]>): EntryCardListModel[] => {
    const matchedEntries = entries.filter((entry) => {
      const entryMatch = entry.titleText?.toLowerCase()?.includes(searchText?.toLowerCase()) || entry.text?.toLowerCase()?.includes(searchText?.toLowerCase());
      const entryChat = chatsByEntryId[entry.id];
      const chatMatch = Boolean(entryChat?.some((v) => v.message?.toLowerCase()?.includes(searchText?.toLowerCase())));
      const entryNumberMatch = entry.protocolEntryNumber ? entry.protocolEntryNumber?.toLowerCase()?.includes(searchText?.toLowerCase()) : false;
      return entryMatch || chatMatch || entryNumberMatch;
    });
    const parentsWithFilteredChildren = new Set(matchedEntries.map(({parentId}) => parentId));
    const filteredEntries = new Set(matchedEntries.map(({id}) => id));

    return entries.filter((entry) => filteredEntries.has(entry.id) || parentsWithFilteredChildren.has(entry.id));
  };

  readonly entriesFiltered$: Observable<EntryCardListModel[]>;

  readonly hasEntriesFiltered$: Observable<boolean>;

  readonly hasEntriesStatus$: Observable<'has-entries' | 'empty-results' | 'no-entries'>;

  readonly hasFilters$: Observable<boolean>;

  readonly totalEntriesCount$: Observable<number>;
  readonly filteredEntriesCount$: Observable<number>;
  readonly totalVsFilteredEntriesCount$: Observable<string>;

  constructor(
    entries$: Observable<EntryCardListModel[]>,
    private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService = inject(ProtocolEntrySearchFilterService),
    private protocolEntryChatDataService: ProtocolEntryChatDataService = inject(ProtocolEntryChatDataService)
  ) {
    this.entries$ = entries$;
    this.hasEntries$ = this.entries$.pipe(
      map((entries) => entries.length > 0),
      distinctUntilChanged()
    );
    this.entriesSortedWithSubEntriesAware$ = combineLatestAsync([this.entries$, this.sortOrderAsc$, this.withSubEntries$]).pipe(
      map(([entries, sortOrderAsc, withSubEntries]) => {
        const entriesWithOrWithoutSubEntries = withSubEntries ? entries : entries.filter(({isSubtask}) => !isSubtask);
        return this.sortAndMapEntries(entriesWithOrWithoutSubEntries, sortOrderAsc);
      })
    );
    this.entryChats$ = this.entries$.pipe(
      map((entries) => entries.map(({id}) => id)),
      distinctUntilChanged<IdType[]>(comparePrimitiveArrays),
      switchMap((entryIds) => this.protocolEntryChatDataService.getByProtocolEntries(entryIds)),
      distinctUntilUnsortedArraysByObjectKeysChanged(['message'])
    );
    this.entryChatsByEntryId$ = this.entryChats$.pipe(map((entryChats) => _.groupBy(entryChats, 'protocolEntryId')));
    this.entriesFiltered$ = combineLatestAsync([this.entriesSortedWithSubEntriesAware$, this.entryChatsByEntryId$, this.protocolEntrySearchFilterService.filters$, this.search$]).pipe(
      map(([entries, entryChatsByEntryId, filters, search]) => {
        const result = this.filterEntryBySearchText(search, execEntriesFilter(filters, entries), entryChatsByEntryId);

        return this.sortAndMapEntriesAfterFilter(result, filters, search);
      }),
      distinctUntilChanged<EntryCardListModel[]>(_.isEqual),
      shareReplay({refCount: true, bufferSize: 1})
    );

    this.hasEntriesFiltered$ = this.entriesFiltered$.pipe(
      map((entries) => entries.length > 0),
      distinctUntilChanged()
    );

    this.hasEntriesStatus$ = combineLatestAsync([this.hasEntries$, this.hasEntriesFiltered$]).pipe(
      map(([hasEntries, hasEntriesFiltered]) => {
        if (hasEntriesFiltered) {
          return 'has-entries' as const;
        }
        if (hasEntries) {
          return 'empty-results' as const;
        }

        return 'no-entries';
      })
    );

    this.hasFilters$ = combineLatestAsync([this.protocolEntrySearchFilterService.hasFilter$, this.search$.pipe(map((search) => Boolean(search)))]).pipe(
      map(([hasFilter, hasSearch]) => hasFilter || hasSearch),
      distinctUntilChanged()
    );

    this.totalEntriesCount$ = this.entries$.pipe(
      map((entries) => entries.length),
      distinctUntilChanged()
    );
    this.filteredEntriesCount$ = this.entriesFiltered$.pipe(
      map((entries) => entries.length),
      distinctUntilChanged()
    );
    this.totalVsFilteredEntriesCount$ = combineLatestAsync([this.totalEntriesCount$, this.filteredEntriesCount$]).pipe(
      map(([total, filtered]) => `${filtered}/${total}`),
      distinctUntilChanged()
    );
  }

  resetFilters() {
    this.protocolEntrySearchFilterService.clearAllFilters();
    this.searchSubject.next('');
  }

  /**
   * Override this method, if you want to apply custom sorting rules, or/and map an object (e.g. to add post-sorting grouping)
   *
   * Important: this function must be pure, as observable won't be refreshed when external dependencies of this method would change.
   */
  protected sortAndMapEntries(entries: EntryCardListModel[], sortOrderAsc: boolean = true): EntryCardListModel[] {
    return _.orderBy(entries, ['mainEntryNumber'], [sortOrderAsc ? 'asc' : 'desc']);
  }

  /**
   * Override this method, if you want to apply custom sorting rules, or/and map an object (e.g. to add post-sorting grouping) **after** filtering
   *
   * Important: this function must be pure, as observable won't be refreshed when external dependencies of this method would change.
   */
  protected sortAndMapEntriesAfterFilter(entries: EntryCardListModel[], filters: ProtocolEntrySearchFilter, search: string): EntryCardListModel[] {
    return entries;
  }
}
