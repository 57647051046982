import {Injectable} from '@angular/core';
import {ClientCalendar, IdType, ProjectClientCalendar, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectDataService} from './project-data.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {ClientCalendarDataService} from './client-calendar-data.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectClientCalendars';

@Injectable({
  providedIn: 'root',
})
export class ProjectClientCalendarDataService extends AbstractProjectAwareMappingDataService<ProjectClientCalendar> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private clientCalendarDataService: ClientCalendarDataService
  ) {
    super(
      StorageKeyEnum.PROJECT_CLIENT_CALENDAR,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  getByCalendarId(calendarId: IdType): Observable<ProjectClientCalendar> {
    return this.data.pipe(map((calendars) => calendars.find((calendar) => calendar.calendarId === calendarId)));
  }

  getProjectClientCalendars(additionalCalendarsIdsToInclude?: Array<IdType>): Observable<ClientCalendar[]> {
    const additionalCalendarToIncludeSet = new Set(additionalCalendarsIdsToInclude || []);
    return combineLatestAsync([this.data, this.clientCalendarDataService.data]).pipe(
      map(([projectClientCalendars, calendars]) =>
        calendars.filter((calendar) => (projectClientCalendars.some((value) => value.calendarId === calendar.id) && calendar.isActive) || additionalCalendarToIncludeSet.has(calendar.id))
      )
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
