import {ColumnConfig} from '../ColumnConfig';
import {ProjectProtocolEntryTypeColumnSet} from './ProjectProtocolEntryTypeColumnSet';
import {ProtocolEntryColumnSet} from './ProtocolEntryColumnSet';

export const ProtocolEntryTypeColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'name'},
  {name: 'status_field_active', prop: 'statusFieldActive'},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'projectProtocolEntryTypes', fk: 'protocolentrytypeId', mappedBy: ProjectProtocolEntryTypeColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'typeId', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'task_default', prop: 'taskDefault'},
];
