import {Directive, OnDestroy} from '@angular/core';
import {BehaviorSubject, from, isObservable, Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, map, shareReplay, take, takeUntil} from 'rxjs/operators';
import {v4} from 'uuid';
@Directive({
  selector: '[appActionWithLoading]',
  exportAs: 'appActionWithLoading',
})
export class ActionWithLoadingDirective implements OnDestroy {
  private destroy$ = new Subject<void>();
  private pendingActions$ = new BehaviorSubject<Set<string>>(new Set());

  loading$ = this.pendingActions$.pipe(
    map((set) => set.size > 0),
    distinctUntilChanged(),
    shareReplay(1)
  );

  performAction(action: (...args: any[]) => Observable<unknown> | Promise<unknown> | unknown, ...args: any[]) {
    const id = v4();
    const result = action(...args);
    const observable = isObservable(result) || result instanceof Promise ? from(result) : from(undefined);

    this.pendingActions$.value.add(id);
    this.pendingActions$.next(this.pendingActions$.value);

    observable
      .pipe(
        catchError(() => of(undefined)),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(() => {
        this.pendingActions$.value.delete(id);
        this.pendingActions$.next(this.pendingActions$.value);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
