import {ColumnConfig} from '../ColumnConfig';

const SignaturePerProfileInSinglePdfUniq = ['protocol_id', 'profile_id', 'name', 'pdf_preview_id', 'for_closed'];

export const AttachmentProtocolSignatureColumnSet: Array<ColumnConfig> = [
  {name: 'attachment_ptr_id', prop: 'id', cnd: true},
  {name: 'protocol_id', prop: 'protocolId', cnd: true, uniqueWith: [SignaturePerProfileInSinglePdfUniq]},
  {name: 'profile_id', prop: 'profileId', cnd: true, def: null, uniqueWith: [SignaturePerProfileInSinglePdfUniq]},
  {name: 'name', prop: 'name', cnd: true, def: null, uniqueWith: [SignaturePerProfileInSinglePdfUniq]},
  {name: 'for_closed', prop: 'forClosed', uniqueWith: [SignaturePerProfileInSinglePdfUniq]},
  {name: 'pdf_preview_id', prop: 'pdfPreviewId', def: null, uniqueWith: [SignaturePerProfileInSinglePdfUniq]},
];
