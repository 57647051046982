import {ContentChild, Directive, Input, TemplateRef} from '@angular/core';
import {Nullish} from 'src/app/model/nullish';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';

export type PdfPlanViewMode = 'edit' | 'read' | 'select';

@Directive({selector: '[appHolderItemNameTemplate]'})
export class HolderItemNameTemplateDirective {}

@Directive({selector: '[appHolderItemIndexTemplate]'})
export class HolderItemIndexTemplateDirective {}

@Directive({selector: '[appHolderItemDateTemplate]'})
export class HolderItemDateTemplateDirective {}

@Directive({selector: '[appHolderItemScaleTemplate]'})
export class HolderItemScaleTemplateDirective {}

@Directive({selector: '[appHolderItemTagsTemplate]'})
export class HolderItemTagsTemplateDirective {}

@Directive({selector: '[appHolderItemLocationTemplate]'})
export class HolderItemLocationTemplateDirective {}

@Directive({selector: '[appHolderItemCommentTemplate]'})
export class HolderItemCommentTemplateDirective {}

@Directive()
export abstract class PdfPlanHolderItemDirective {
  @Input()
  viewMode: PdfPlanViewMode = 'read';

  private _nameTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set nameTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._nameTemplate = template;
  }
  get nameTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._nameTemplate || this.nameTemplateContent;
  }

  private _indexTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set indexTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._indexTemplate = template;
  }
  get indexTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._indexTemplate || this.indexTemplateContent;
  }

  private _dateTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set dateTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._dateTemplate = template;
  }
  get dateTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._dateTemplate || this.dateTemplateContent;
  }

  private _scaleTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set scaleTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._scaleTemplate = template;
  }
  get scaleTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._scaleTemplate || this.scaleTemplateContent;
  }

  private _tagsTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set tagsTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._tagsTemplate = template;
  }
  get tagsTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._tagsTemplate || this.tagsTemplateContent;
  }

  private _locationTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set locationTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._locationTemplate = template;
  }
  get locationTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._locationTemplate || this.locationTemplateContent;
  }

  private _commentTemplate: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
  @Input()
  set commentTemplate(template: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>) {
    this._commentTemplate = template;
  }
  get commentTemplate(): Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>> {
    return this._commentTemplate || this.commentTemplateContent;
  }

  @ContentChild(HolderItemNameTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private nameTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;

  @ContentChild(HolderItemIndexTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private indexTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;

  @ContentChild(HolderItemDateTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private dateTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;

  @ContentChild(HolderItemScaleTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private scaleTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;

  @ContentChild(HolderItemTagsTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private tagsTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;

  @ContentChild(HolderItemLocationTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private locationTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;

  @ContentChild(HolderItemCommentTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  private commentTemplateContent: Nullish<TemplateRef<{holder: PdfPlanWithDeletable}>>;
}
