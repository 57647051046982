import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {Injectable} from '@angular/core';
import {IdType, Profile, ProjectProfile, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectDataService} from './project-data.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {combineLatestAsync} from '../../utils/async-utils';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/projectProfiles';

@Injectable({
  providedIn: 'root',
})
export class ProjectProfileDataService extends AbstractProjectAwareMappingDataService<ProjectProfile> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private profileService: ProfileDataService
  ) {
    super(
      StorageKeyEnum.PROJECT_PROFILE,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  getProjectProfilesForProjects(projectId: IdType, withAssignments: IdType[] = []): Observable<Profile[]> {
    return combineLatestAsync([this.dataAcrossProjects$, this.profileService.dataAcrossClientsWithDefaultType$]).pipe(
      map(([projectProfiles, profiles]) => [projectProfiles.filter((value) => projectId === value.projectId), profiles] as [ProjectProfile[], Profile[]]),
      map(([projectProfiles, profiles]) => profiles.filter((profile) => withAssignments.includes(profile.id) || projectProfiles.find((projectProfile) => projectProfile.profileId === profile.id)))
    );
  }

  getProjectProfiles(withAssignments: IdType[] = []): Observable<Profile[]> {
    return combineLatestAsync([this.data, this.profileService.dataWithDefaultType$]).pipe(
      map(([projectProfiles, profiles]) => profiles.filter((profile) => withAssignments.includes(profile.id) || projectProfiles.find((projectProfile) => projectProfile.profileId === profile.id)))
    );
  }

  getProjectProfilesWithMapping(withAssignments: IdType[] = []): Observable<{profile: Profile; projectProfile?: ProjectProfile}[]> {
    return combineLatestAsync([this.data, this.profileService.dataWithDefaultType$]).pipe(
      map(([projectProfiles, profiles]) =>
        profiles
          .map((profile) => ({
            profile,
            projectProfile: projectProfiles.find((projectProfile) => projectProfile.profileId === profile.id),
          }))
          .filter(({profile, projectProfile}) => withAssignments.includes(profile.id) || Boolean(projectProfile))
      )
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }

  public getByProfileId(profileId: IdType): Observable<ProjectProfile | undefined> {
    return this.data.pipe(map((projectProfiles) => projectProfiles.find((projectProfile) => projectProfile.profileId === profileId)));
  }
}
