import {TooltipPlacement} from './tooltip-placement.model';

export const tooltipPlacementCalculator = (placement: TooltipPlacement, rect: DOMRect): Record<'top' | 'right' | 'bottom' | 'left', number | undefined> => {
  const {top, left, bottom, width, height} = rect;

  if (placement === 'right') {
    return {
      top: top + height / 2,
      left: width + left,
      right: undefined,
      bottom: undefined,
    };
  }

  if (placement === 'left') {
    return {
      top: top + height / 2,
      left,
      right: undefined,
      bottom: undefined,
    };
  }

  if (placement === 'top') {
    return {
      top,
      left: left + width / 2,
      right: undefined,
      bottom: undefined,
    };
  }

  return {
    top: bottom,
    left: left + width / 2,
    right: undefined,
    bottom: undefined,
  };
};
