import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
  Company,
  Craft,
  IdAware,
  IdType,
  LicenseType,
  NameableDropdown,
  NameableDropdownItem,
  Profile,
  ProjectCompany,
  ProjectCraft,
  ProjectProfile,
  ProjectProtocolEntryType,
  ProjectProtocolLocation,
  ProjectProtocolType,
  ProtocolEntryLocation,
  ProtocolEntryType,
  ProtocolType,
} from 'submodules/baumaster-v2-common';
import {LocationComponent} from '../../components/project-settings/location/location.component';
import {ComponentRef} from '@ionic/core';
import {CraftComponent} from '../../components/project-settings/craft/craft.component';
import {ProjectProtocolLocationDataService} from '../data/project-protocol-location-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {observableToPromise} from '../../utils/async-utils';
import {AbstractProjectAwareMappingDataService} from '../data/abstract-project-aware-mapping-data.service';
import {ProjectCraftDataService} from '../data/project-craft-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProjectProtocolTypeDataService} from '../data/project-protocol-type-data.service';
import {ProjectProtocolEntryTypeDataService} from '../data/project-protocol-entry-type-data.service';
import {CompanyCreateComponent} from '../../components/company/company-create/company-create.component';
import {ContactEmployeCreateComponent} from '../../components/contacts/contact-employe-create/contact-employe-create.component';
import {ProtocolTypeComponent} from '../../components/project-settings/protocol-type/protocol-type.component';
import {ProtocolEntryTypeComponent} from '../../components/project-settings/protocol-entry-type/protocol-entry-type.component';
import {AdditionalFieldComponent} from '../../components/project-settings/additional-field/additional-field.component';
import {NameableDropdownItemDataService} from '../data/nameable-dropdown-item-data.service';
import {CompanyAddresses} from '../../components/protocol/protocol-entry-form/protocol-entry-form.component';
import {FeatureEnabledService} from '../feature/feature-enabled.service';

@Injectable({
  providedIn: 'root',
})
export class SelectableService {
  allowCreatedProjectSettings$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);

  constructor(
    private modalController: ModalController,
    private projectDataService: ProjectDataService,
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
    private projectCraftDataService: ProjectCraftDataService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private projectProtocolTypeDataService: ProjectProtocolTypeDataService,
    private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService,
    private nameableDropdownItemDataService: NameableDropdownItemDataService,
    private featureEnabledService: FeatureEnabledService
  ) {}

  async createNewLocation(prePopulateMainFieldWithText?: string, projectId?: IdType): Promise<ProtocolEntryLocation | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<ProtocolEntryLocation>(LocationComponent, projectId, prePopulateMainFieldWithText);
  }

  async createNewCraft(prePopulateMainFieldWithText?: string, projectId?: IdType): Promise<Craft | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<Craft>(CraftComponent, projectId, prePopulateMainFieldWithText);
  }

  async createNewCompany(prePopulateMainFieldWithText?: string, projectId?: IdType): Promise<Company | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<Company>(CompanyCreateComponent, projectId, prePopulateMainFieldWithText);
  }

  async createNewProfile(company: Company, prePopulateMainFieldWithText?: string, projectId?: IdType): Promise<Profile | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<Profile>(ContactEmployeCreateComponent, projectId, prePopulateMainFieldWithText, {company});
  }

  async createNameableDropdown(prePopulateMainFieldWithText?: string, projectId?: IdType): Promise<NameableDropdown | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<NameableDropdown>(AdditionalFieldComponent, projectId, prePopulateMainFieldWithText);
  }

  async createNewProtocolType(prePopulateMainFieldWithText?: string, layoutId?: IdType, projectId?: IdType): Promise<ProtocolType | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<ProtocolType>(ProtocolTypeComponent, projectId, prePopulateMainFieldWithText, {layoutId});
  }

  async createNewProtocolEntryType(prePopulateMainFieldWithText?: string, projectId?: IdType): Promise<ProtocolEntryType | undefined> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    return await this.callNewDialog<ProtocolEntryType>(ProtocolEntryTypeComponent, projectId, prePopulateMainFieldWithText);
  }

  async assignLocationToProject(location: ProtocolEntryLocation, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + location.id;
    const projectLocation: ProjectProtocolLocation = {
      id,
      projectId,
      protocolentrylocationId: location.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(projectLocation, this.projectProtocolLocationDataService);
    } else {
      return await this.unAssignItemFromProject(projectLocation, this.projectProtocolLocationDataService);
    }
  }

  async assignCraftToProject(craft: Craft, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + craft.id;
    const projectCraft: ProjectCraft = {
      id,
      projectId,
      craftId: craft.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(projectCraft, this.projectCraftDataService);
    } else {
      return await this.unAssignItemFromProject(projectCraft, this.projectCraftDataService);
    }
  }

  async assignCompanyToProject(company: Company, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + company.id;
    const projectCompany: ProjectCompany = {
      id,
      projectId,
      companyId: company.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(projectCompany, this.projectCompanyDataService);
    } else {
      return await this.unAssignItemFromProject(projectCompany, this.projectCompanyDataService);
    }
  }

  async assignProfileToProject(profile: Profile | CompanyAddresses, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + profile.id;
    const projectProfile: ProjectProfile = {
      id,
      projectId,
      profileId: profile.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(projectProfile, this.projectProfileDataService);
    } else {
      return await this.unAssignItemFromProject(projectProfile, this.projectProfileDataService);
    }
  }

  async assignNameableDropdownToProject(nameableDropdown: NameableDropdown, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + nameableDropdown.id;
    const nameableDropdownItem: NameableDropdownItem = {
      id,
      projectId,
      nameabledropdownId: nameableDropdown.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(nameableDropdownItem, this.nameableDropdownItemDataService);
    } else {
      return await this.unAssignItemFromProject(nameableDropdownItem, this.nameableDropdownItemDataService);
    }
  }

  async assignProtocolTypeToProject(protocolType: ProtocolType, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + protocolType.id;
    const projectProtocolType: ProjectProtocolType = {
      id,
      projectId,
      protocoltypeId: protocolType.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(projectProtocolType, this.projectProtocolTypeDataService);
    } else {
      return await this.unAssignItemFromProject(projectProtocolType, this.projectProtocolTypeDataService);
    }
  }

  async assignProtocolEntryTypeToProject(protocolEntryType: ProtocolEntryType, assign: boolean, projectId?: IdType): Promise<boolean> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const id = projectId + protocolEntryType.id;
    const projectProtocolEntryType: ProjectProtocolEntryType = {
      id,
      projectId,
      protocolentrytypeId: protocolEntryType.id,
      changedAt: new Date().toISOString(),
    };
    if (assign) {
      return await this.assignItemToProject(projectProtocolEntryType, this.projectProtocolEntryTypeDataService);
    } else {
      return await this.unAssignItemFromProject(projectProtocolEntryType, this.projectProtocolEntryTypeDataService);
    }
  }

  private async assignItemToProject<T extends IdAware & {projectId: IdType}>(newItem: T, dataService: AbstractProjectAwareMappingDataService<T>): Promise<boolean> {
    const existingProjectLocation = await observableToPromise(dataService.getById(newItem.id));
    if (existingProjectLocation) {
      return false;
    }
    await dataService.insert(newItem, newItem.projectId);
    return true;
  }

  private async unAssignItemFromProject<T extends IdAware & {projectId: IdType}>(item: T, dataService: AbstractProjectAwareMappingDataService<T>): Promise<boolean> {
    const existingProjectLocation = await observableToPromise(dataService.getById(item.id));
    if (existingProjectLocation) {
      await dataService.delete(item, item.projectId);
      return true;
    }
    return false;
  }

  private async callNewDialog<T extends IdAware>(component: ComponentRef, projectId: IdType, prePopulateMainFieldWithText?: string, componentProps?: any): Promise<T | undefined> {
    const modal = await this.modalController.create({
      component,
      componentProps: {
        projectId,
        ...componentProps,
        prePopulateMainFieldWithText,
        createForSelectable: true,
      },
      keyboardClose: false,
      backdropDismiss: true,
    });
    await modal.present();
    const ret = await modal.onDidDismiss();
    if (ret.role === 'ok') {
      const data = ret.data;
      if (!data) {
        throw new Error('callNewDialog - modal returned with role "ok" but without data');
      }
      if (!data.id) {
        throw new Error('callNewDialog - modal returned with role "ok" and data but data does not have the property "id".');
      }
      return data;
    }
    return undefined;
  }
}
