import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {BimVersion} from 'submodules/baumaster-v2-common';
import {DATE_FORMAT} from '../shared/constants';
import {formatDate} from '@angular/common';

@Pipe({
  name: 'bimVersionName',
})
export class BimVersionNamePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: BimVersion | undefined | null, ...args: unknown[]): string | undefined | null {
    if (value === undefined) {
      return undefined;
    }
    if (value === null) {
      return null;
    }
    if (!value) {
      return '';
    }
    const indexWithPipe = value.index ? ` | ${value.index}` : '';
    return `${value.name}${indexWithPipe} | ${formatDate(value.createdAt, DATE_FORMAT, this.locale)}`;
  }
}
