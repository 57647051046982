import {ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {NavController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {EntryCardListComponent} from 'src/app/components/entry/entry-card-list/entry-card-list.component';
import {EntryCardModel} from 'src/app/model/entry-card-model';
import {LoggingService} from 'src/app/services/common/logging.service';
import {EntryCreationModeService} from 'src/app/services/entry/entry-creation-mode.service';
import {EntryThreeDotsActionsService} from 'src/app/services/entry/entry-three-dots-actions.service';
import {CreateEntryService} from 'src/app/services/protocol/create-entry.service';
import {TasksPageService} from 'src/app/services/tasks/tasks-page.service';
import {IdType, LicenseType} from 'submodules/baumaster-v2-common';
import {FeatureEnabledService} from '../../services/feature/feature-enabled.service';

const LOG_SOURCE = 'TaskCardListPage';

@Component({
  selector: 'app-task-card-list-page',
  templateUrl: './task-card-list.page.html',
  styleUrls: ['./task-card-list.page.scss'],
})
export class TaskCardListPage implements OnDestroy {
  @ViewChild(EntryCardListComponent, {static: false})
  entryCardListComponent: EntryCardListComponent;

  private readonly destroy$ = new Subject<void>();
  readonly taskCardListRendered = new Subject<void>();
  readonly hasTasksStatus$ = this.tasksPageService.hasEntriesStatus$;
  public readonly isEditEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER, LicenseType.LIGHT]);
  public entryCreationModeIcon$ = this.entryCreationModeService.entryCreationModeIcon$;
  public entryCreationModeRemembered$ = this.entryCreationModeService.entryCreationModeRemembered$;
  protected readonly currentTaskId$ = this.tasksPageService.currentTaskId$;

  constructor(
    private tasksPageService: TasksPageService,
    private createEntryService: CreateEntryService,
    private cdRef: ChangeDetectorRef,
    private loggingService: LoggingService,
    private navController: NavController,
    private featureEnabledService: FeatureEnabledService,
    private entryCreationModeService: EntryCreationModeService,
    private entryThreeDotsActionsService: EntryThreeDotsActionsService
  ) {
    this.taskCardListRendered
      .pipe(
        take(1),
        tap(() => this.cdRef.detectChanges()),
        switchMap(() => this.tasksPageService.lastTaskId$.pipe(take(1))),
        takeUntil(this.destroy$)
      )
      .subscribe((lastTaskId) => {
        if (lastTaskId) {
          const id = lastTaskId.slice(lastTaskId.indexOf('/entry') + 7);
          this.loggingService.debug(LOG_SOURCE, `Task card list rendered; last task id is ${id}; calling scrollToTask`);
          this.scrollToTask(id);
        }
      });
  }

  private scrollToTask(taskId: IdType) {
    if (!this.entryCardListComponent) {
      this.loggingService.warn(LOG_SOURCE, `Ignored navigation to ${taskId} due to not present EntryCardListComponent in view child`);
      return;
    }
    this.entryCardListComponent.scrollToEntry(taskId);
  }

  async createTask() {
    const result = await this.createEntryService.createTask({entryCreationMode: this.entryCreationModeService.entryCreationMode});
    if (result.role === 'save') {
      await this.navController.navigateRoot(['/tasks', 'card', 'entry', result.entry.id]);
      this.entryCardListComponent.scrollToEntry(result.entry.id);
    }
  }

  async openButtonActions(event: Event) {
    if (await this.entryCreationModeService.openEntryCreationModeOptions(event)) {
      await this.createTask();
    }
  }

  navigateToTask(entry: EntryCardModel) {
    this.navController.navigateRoot(['/tasks', 'card', 'entry', entry.id]);
  }

  async entryActions(event: MouseEvent, entry: EntryCardModel) {
    await this.entryThreeDotsActionsService.taskActions(event, entry, this.tasksPageService);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
