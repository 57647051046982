import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Address, Company, convertRichTextToPlainText} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';

export const getWithDeletedSuffixForKey = <T extends {isActive: boolean}, TKey extends keyof T>(
  obj: Nullish<T>,
  key: TKey,
  translateService: TranslateService,
  getNameFn: (v: T) => string
): Nullish<T> => {
  if (!obj) {
    return obj;
  }

  return {
    ...obj,
    [key]: `${getNameFn(obj)}${obj.isActive === false ? ` ${translateService.instant('deleted')}` : ''}`,
  };
};

export const getObjectNameWithDeletedSuffix = <T extends {isActive: boolean; name: string}>(obj: T, translateService: TranslateService) =>
  getWithDeletedSuffixForKey(obj, 'name', translateService, (v) => v.name);

export const getObjectWithDeletedSuffix = <T extends {isActive: boolean}, TKey extends keyof T>(obj: T, key: TKey, translateService: TranslateService, getNameFn: (v: T) => string) =>
  getWithDeletedSuffixForKey(obj, key, translateService, getNameFn);

export const getFirstCapitalLetter = (str: Nullish<string>) => (str ?? '').trim().slice(0, 1).toUpperCase();

export const memoizedConvertRichTextToPlainText = _.memoize(convertRichTextToPlainText);

export const getPersonInitials = (address: Address) => `${getFirstCapitalLetter(address.firstName)}${getFirstCapitalLetter(address.lastName)}`;
export const getCompanyInitials = (company: Company) => `${getFirstCapitalLetter(company.name)}`;
