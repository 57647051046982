import {Injectable} from '@angular/core';
import {ReportTypeCode, ShowPicturesEnum} from 'submodules/baumaster-v2-common';
import {TranslateService} from '@ngx-translate/core';
import {PdfWorkflowStepConfig, SendPdfWorkflowStep, WorkflowType} from '../../model/send-protocol';

@Injectable({
  providedIn: 'root',
})
export class PdfWorkflowService {
  constructor(private translateService: TranslateService) {}

  getImageSizeOptions() {
    const showPicturesKeys: string[] = Object.keys(ShowPicturesEnum).filter((x) => isNaN(Number(x)));
    return showPicturesKeys.map((key) => {
      return {
        id: ShowPicturesEnum[key],
        name: this.translateService.instant('sendProtocol.protocolConfig.image_' + key),
      };
    });
  }

  getWorkflowConfigs(workflowType: WorkflowType, reportType?: ReportTypeCode): Array<PdfWorkflowStepConfig> {
    if (workflowType === WorkflowType.Report) {
      if (reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY) {
        return [
          {
            currentStep: SendPdfWorkflowStep.MAILING_LIST,
            previousStep: null,
            nextStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
          },
          {
            currentStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
            previousStep: SendPdfWorkflowStep.MAILING_LIST,
            nextStep: null,
          },
        ];
      }
      return [
        {
          currentStep: SendPdfWorkflowStep.MAILING_LIST,
          previousStep: null,
          nextStep: SendPdfWorkflowStep.ADVANCED_SETTINGS,
        },
        {
          currentStep: SendPdfWorkflowStep.ADVANCED_SETTINGS,
          previousStep: SendPdfWorkflowStep.MAILING_LIST,
          nextStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
        },
        {
          currentStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
          previousStep: SendPdfWorkflowStep.ADVANCED_SETTINGS,
          nextStep: null,
        },
      ];
    }
    if (workflowType === WorkflowType.Protocol) {
      return [
        {
          currentStep: SendPdfWorkflowStep.MAILING_LIST,
          previousStep: null,
          nextStep: SendPdfWorkflowStep.PROTOCOL_CONFIGURATION,
        },
        {
          currentStep: SendPdfWorkflowStep.PROTOCOL_CONFIGURATION,
          previousStep: SendPdfWorkflowStep.MAILING_LIST,
          nextStep: SendPdfWorkflowStep.ADVANCED_SETTINGS,
        },
        {
          currentStep: SendPdfWorkflowStep.ADVANCED_SETTINGS,
          previousStep: SendPdfWorkflowStep.PROTOCOL_CONFIGURATION,
          nextStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
        },
        {
          currentStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
          previousStep: SendPdfWorkflowStep.ADVANCED_SETTINGS,
          nextStep: null,
        },
      ];
    }
    if (workflowType === WorkflowType.GlobalSearch) {
      return [
        {
          currentStep: SendPdfWorkflowStep.MAILING_LIST,
          previousStep: null,
          nextStep: SendPdfWorkflowStep.PROTOCOL_CONFIGURATION,
        },
        {
          currentStep: SendPdfWorkflowStep.PROTOCOL_CONFIGURATION,
          previousStep: SendPdfWorkflowStep.MAILING_LIST,
          nextStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
        },
        {
          currentStep: SendPdfWorkflowStep.EMAIL_SETTINGS,
          previousStep: SendPdfWorkflowStep.PROTOCOL_CONFIGURATION,
          nextStep: null,
        },
      ];
    }
    throw new Error(`workflowType ${workflowType} not supported.`);
  }
}
