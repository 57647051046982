import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tasks-list-view',
  templateUrl: './tasks-list-view.page.html',
  styleUrls: ['./tasks-list-view.page.scss'],
})
export class TasksListViewPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
