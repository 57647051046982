import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, shareReplay} from 'rxjs/operators';
import {BimVersion, IdType, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ToastService} from '../common/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {DataServiceDeleteOptions, VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';
import _ from 'lodash';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/BimVersions';
const LOG_SOURCE = 'BimVersionDataService';
const ORDER_BY: Array<keyof BimVersion | ((item: BimVersion) => any)> = ['bimPlanId', 'number', 'changedAt'];

@Injectable({
  providedIn: 'root',
})
export class BimVersionDataService extends AbstractProjectAwareDataService<BimVersion> {
  readonly dataByPlanId$: Observable<Record<IdType, BimVersion[]>> = this.data.pipe(
    map((data) => _.groupBy(data, 'bimPlanId')),
    shareReplay(1)
  );
  readonly dataByPlanIdAcrossProjects$: Observable<Record<IdType, BimVersion[]>> = this.dataAcrossProjects$.pipe(
    map((data) => _.groupBy(data, 'bimPlanId')),
    shareReplay(1)
  );

  readonly dataLatestFinal$ = this.data.pipe(map((data) => this.filterLatestFinal(data)));
  readonly dataLatestFinalAcrossProjects$ = this.dataAcrossProjects$.pipe(map((data) => this.filterLatestFinal(data)));

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    super(
      StorageKeyEnum.BIM_VERSION,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService,
      VERSION_INTRODUCED_DEFAULT,
      ORDER_BY
    );
  }

  getByProjectId$(projectId: IdType): Observable<BimVersion[]> {
    return this.dataByProjectId$.pipe(map((byProjectId) => byProjectId[projectId] ?? []));
  }

  public getDataLatestFinalForProject$(projectId: IdType): Observable<Array<BimVersion> | undefined> {
    return this.dataLatestFinalAcrossProjects$.pipe(map((dataByProjectId) => dataByProjectId[projectId]));
  }

  public findByBimPlan$(bimPlanId: IdType): Observable<Array<BimVersion>> {
    return this.data.pipe(map((pdfPlanVersions) => this.filterByPdfPlanOrderByVersion(pdfPlanVersions, bimPlanId)));
  }

  public getLatestByPdfPlan$(bimPlanId: IdType): Observable<BimVersion | undefined> {
    return this.findByBimPlan$(bimPlanId).pipe(map((pdfPlanVersions) => _.last(pdfPlanVersions)));
  }

  public filterLatestFinal(bimVersions: Array<BimVersion>): Array<BimVersion> {
    const finalBimVersions = bimVersions.filter((bimVersion) => bimVersion.status === 'final');
    const grouped: Record<string, Array<BimVersion>> = _.groupBy(finalBimVersions, 'bimPlanId');
    const latestValues = new Array<BimVersion>();
    for (const values of Object.values(grouped)) {
      if (values.length) {
        const latestValue = _.last(_.orderBy(values, ORDER_BY));
        latestValues.push(latestValue);
      }
    }
    return latestValues;
  }

  public filterByPdfPlanOrderByVersion(bimVersions: Array<BimVersion>, bimPlanId: IdType): Array<BimVersion> {
    return _.orderBy(
      bimVersions.filter((bimVersion) => bimVersion.bimPlanId === bimPlanId),
      ORDER_BY
    );
  }

  public async delete(
    valueArrayOrFunction: BimVersion | Array<BimVersion> | ((storageData: Array<BimVersion>) => BimVersion | Array<BimVersion> | undefined),
    projectId: IdType,
    options?: DataServiceDeleteOptions
  ): Promise<void> {
    throw new Error('BimVersionDataService - offline delete not supported. Use method removeByBimVersionId in BimVersionActionsService');
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
