import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AutodeskAuthToken, BimVersionEditable, IdType, UploadBimVersionResponse} from 'submodules/baumaster-v2-common';
import {AuthenticationService} from '../auth/authentication.service';
import {observableToPromise} from '../../utils/async-utils';
import {LoggingService} from '../common/logging.service';
import {ProjectDataService} from '../data/project-data.service';
import {HttpClient} from '@angular/common/http';
import mime from 'mime';
import {getFilenameFromUrl} from 'pdfjs-dist';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';
import _ from 'lodash';
import {convertErrorToMessage} from '../../shared/errors';

const IS_TOKEN_ABOUT_TO_EXPIRE_THRESHOLD_IN_MS = 5 * 60 * 1000;
const TOKEN_RETRY_IF_OFFLINE_IN_SEC = 60;
const LOG_SOURCE = 'AutodeskService';

@Injectable({
  providedIn: 'root',
})
export class AutodeskService {
  private authTokenSubject = new BehaviorSubject<AutodeskAuthToken | undefined>(undefined);

  private get authToken(): AutodeskAuthToken | undefined {
    return this.authTokenSubject.value;
  }

  private set authToken(autodeskAuthToken: AutodeskAuthToken) {
    this.authTokenSubject.next(autodeskAuthToken);
  }

  public offlineOptions = {
    env: 'Local', // 'Local' can be used to avoid attaching authentication headers to XHR requests.
    api: 'derivativeV2',
  };

  public onlineOptions = {
    env: 'AutodeskProduction2',
    api: 'streamingV2_EU', // for models uploaded to EMEA change this option to 'streamingV2_EU'. Default is 'streamingV2'
    getAccessToken: async (onTokenReady: (accessToken: string, expiresIn: number) => void) => {
      try {
        this.loggingService.debug(LOG_SOURCE, 'options.getAccessToken called.');
        const authToken = await this.getAuthToken();
        this.loggingService.debug(LOG_SOURCE, `options.getAccessToken received token (expires_in=${authToken.expires_in}, expires_at=${authToken.expires_at}). Calling onTokenReady..`);
        const expiresIn = 'expires_at' in authToken ? Math.floor((authToken.expires_at - Date.now()) / 1000) : (authToken as any).expires_in;
        onTokenReady(authToken.access_token, expiresIn);
      } catch (e) {
        // There is no callback like onTokenError and if we do not call onTokenReady, the BimViewer would wait forever.
        this.loggingService.error(LOG_SOURCE, `onlineOptions.getAccessToken failed with error: ${convertErrorToMessage(e)}`);
        onTokenReady(convertErrorToMessage(e), TOKEN_RETRY_IF_OFFLINE_IN_SEC);
      }
    },
  };

  constructor(
    private authenticationService: AuthenticationService,
    private loggingService: LoggingService,
    private projectDataService: ProjectDataService,
    private http: HttpClient,
    private syncService: SyncService
  ) {}

  async getAuthToken(): Promise<AutodeskAuthToken> {
    if (this.authToken && !this.isAboutToExpire(this.authToken)) {
      return this.authToken;
    }
    this.authToken = await this.getAuthTokenFromServer();
    return this.authToken;
  }

  private async getAuthTokenFromServer(): Promise<any> {
    const authentication = await observableToPromise(this.authenticationService.data);
    if (!authentication) {
      throw new Error('AutodeskService.getAuthTokenFromServer - User not authenticated.');
    }
    const project = await this.projectDataService.getCurrentProject();
    const url = `${environment.serverUrl}autodesk/token/?projectId=${project.id}`;
    return await observableToPromise(this.http.get(url));
  }

  private isTokenExpired(authToken: AutodeskAuthToken): boolean {
    return authToken.expires_at <= Date.now();
  }

  private isAboutToExpire(authToken: AutodeskAuthToken): boolean {
    return authToken.expires_at - IS_TOKEN_ABOUT_TO_EXPIRE_THRESHOLD_IN_MS <= Date.now();
  }

  public async getModels(): Promise<any> {
    const authentication = await observableToPromise(this.authenticationService.data);
    if (!authentication) {
      throw new Error('AutodeskService.getAuthTokenFromServer - User not authenticated.');
    }
    const project = await this.projectDataService.getCurrentProject();
    const url = `${environment.serverUrl}autodesk/models/?projectId=${project.id}`;
    const res = await observableToPromise(this.http.get(url));
    return res;
  }

  public async uploadModel(file: File, bimPlanId?: IdType, bimVersionEditable?: BimVersionEditable, abortSignal?: AbortSignal): Promise<IdType | undefined> {
    const authentication = await observableToPromise(this.authenticationService.data);
    if (!authentication) {
      throw new Error('AutodeskService.getAuthTokenFromServer - User not authenticated.');
    }
    if (abortSignal?.aborted) {
      return;
    }
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
    if (abortSignal?.aborted) {
      return;
    }
    const project = await this.projectDataService.getCurrentProject();
    let url = `${environment.serverUrl}autodesk/model?projectId=${project.id}`;
    if (bimPlanId) {
      url += '&bimPlanId=' + bimPlanId;
    }
    if (typeof FormData === 'undefined') {
      const formDataPolyfill = await import('formdata-polyfill');
    }
    const formData = new FormData();
    let blob = file as Blob;
    if (!blob.type || blob.type === '' || blob.type === 'application/octet-stream') {
      const mimeType = mime.getType(getFilenameFromUrl(url));
      if (mimeType) {
        blob = blob.slice(0, blob.size, mimeType);
      }
    }
    formData.append('file', blob);
    formData.append('fileSize', `${blob.size}`);
    if (bimVersionEditable) {
      formData.append('bimVersionEditable', JSON.stringify(bimVersionEditable));
    }
    const body: any = formData;
    if (abortSignal?.aborted) {
      return;
    }
    const response = await observableToPromise(
      this.http.post<UploadBimVersionResponse>(url, body, {
        headers: {'ngsw-bypass': 'true'},
      }),
      abortSignal
    );
    if (abortSignal?.aborted) {
      return;
    }
    const success = !_.isEmpty(response);
    if (success) {
      return response?.bimVersion?.id;
    } else {
      throw Error('No attachmentBim created');
    }
  }
}
