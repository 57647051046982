import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttachmentViewerComponent} from '../../../components/common/attachment-viewer/attachment-viewer.component';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../../pipes/pipes.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RxLet} from '@rx-angular/template/let';

@NgModule({
  declarations: [AttachmentViewerComponent],
  imports: [CommonModule, IonicModule, PipesModule, FontAwesomeModule, RxLet],
  exports: [AttachmentViewerComponent],
})
export class AttachmentViewerModule {}
