import {Injectable} from '@angular/core';
import {DataDependency} from 'src/app/model/data-dependency';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {DependencyStillBoundError, MissingDependencyError, UniqueConstraintError} from 'src/app/shared/errors';
import {ColumnUniqueConfig, IdAware} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {SystemEventService} from '../event/system-event.service';

export interface ReferencesInDependency {
  dataDependency: DataDependency;
  references: IdAware[];
}

const LOG_SOURCE = 'IntegrityResolver';

@Injectable({
  providedIn: 'root',
})
export class IntegrityResolverService {
  constructor(
    private systemEventService: SystemEventService,
    private loggingService: LoggingService
  ) {}

  public async logDependencyStillBound<T extends IdAware>(objectToCheck: T, storageKey: StorageKeyEnum, referencesInDependencies: ReferencesInDependency[]) {
    const notEmptyReferences = referencesInDependencies.filter(({references}) => references.length > 0);

    const referencesToPrint = notEmptyReferences
      .map(({references, dataDependency}) => references.map((reference) => `${dataDependency.destinationStorageKey}(id=${reference.id}) at ${dataDependency.destinationKeyPath}`).join('\n'))
      .join('\n');
    const message = `Object ${storageKey}(id=${objectToCheck.id}) will not be removed, it has still bound dependencies:\n${referencesToPrint}`;

    await this.systemEventService.logEvent(LOG_SOURCE, message);
    this.loggingService.warn(LOG_SOURCE, message);
  }

  public async logMissingDependency<T extends IdAware>(objectToCheck: T, storageKey: StorageKeyEnum, referencesInDependencies: ReferencesInDependency[]) {
    const emptyReferences = referencesInDependencies.filter(({references}) => references.length === 0);

    const emptyReferencesToPrint = emptyReferences
      .filter(({dataDependency}) => !dataDependency.optional || objectToCheck[dataDependency.sourceKeyPath])
      .map(({dataDependency}) => `${dataDependency.destinationStorageKey}(${dataDependency.destinationKeyPath}=${objectToCheck[dataDependency.sourceKeyPath]})`)
      .join('\n');
    const message = `Object ${storageKey}(id=${objectToCheck.id}) has missing dependency. Removing this object/preventing adding this object. Missing dependencies:\n${emptyReferencesToPrint}`;

    await this.systemEventService.logEvent(LOG_SOURCE, message);
    this.loggingService.warn(LOG_SOURCE, message);
  }

  public async logUniqueConstraint<T extends IdAware>(
    storageKey: StorageKeyEnum,
    duplicated: {
      firstDuplicate: T;
      uniqueConstraint: ColumnUniqueConfig;
      objectToCheck: T;
    }
  ) {
    const message =
      `[${storageKey}] Unique constraint check failed for constraint (${duplicated.uniqueConstraint.join(', ')}); ` +
      `wanted to insert/update ${duplicated.objectToCheck.id}, but found ${duplicated.firstDuplicate.id}`;

    await this.systemEventService.logEvent(LOG_SOURCE, message);
    this.loggingService.warn(LOG_SOURCE, message);
  }

  public async handleMissingDependency<T extends IdAware>(storageKey: StorageKeyEnum, objectToCheck: T, allObjects: T[], dataDependency: DataDependency): Promise<void> {
    this.logMissingDependency(objectToCheck, storageKey, [
      {
        references: [],
        dataDependency,
      },
    ]);

    throw new MissingDependencyError(objectToCheck, dataDependency);
  }

  public async handleDependencyStillBound<T extends IdAware>(storageKey: StorageKeyEnum, objectToCheck: T, allObjects: T[], dataDependency: DataDependency, boundTo: IdAware[]): Promise<void> {
    this.logMissingDependency(objectToCheck, storageKey, [
      {
        references: boundTo,
        dataDependency,
      },
    ]);

    throw new DependencyStillBoundError(objectToCheck, dataDependency);
  }

  public async handleUniqueConstraint<T extends IdAware>(
    storageKey: StorageKeyEnum,
    objectsToCheck: T[],
    allObjects: T[],
    duplicated: {
      firstDuplicate: T;
      uniqueConstraint: ColumnUniqueConfig;
      objectToCheck: T;
    }
  ): Promise<void> {
    this.logUniqueConstraint(storageKey, duplicated);

    throw new UniqueConstraintError(storageKey, duplicated.uniqueConstraint, duplicated.objectToCheck, duplicated.firstDuplicate);
  }
}
