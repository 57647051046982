import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NoContentSvgComponent} from 'src/app/components/common/no-content-svg/no-content-svg.component';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {BaumasterLogoSvgComponent} from 'src/app/components/common/baumaster-logo-svg/baumaster-logo-svg.component';
import {PdfWorkflowTopTextSvgComponent} from 'src/app/components/common/pdf-workflow-top-text-svg/pdf-workflow-top-text-svg.component';
import {PdfWorkflowBottomTextSvgComponent} from 'src/app/components/common/pdf-workflow-bottom-text-svg/pdf-workflow-bottom-text-svg.component';

@NgModule({
  declarations: [NoContentSvgComponent, BaumasterLogoSvgComponent, PdfWorkflowTopTextSvgComponent, PdfWorkflowBottomTextSvgComponent],
  imports: [CommonModule, PipesModule],
  exports: [NoContentSvgComponent, BaumasterLogoSvgComponent, PdfWorkflowTopTextSvgComponent, PdfWorkflowBottomTextSvgComponent],
})
export class SharedSvgModule {}
