import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {AbstractFileAccessUtil, CacheStorageFileAccessUtil, FileAccessUtilClassName, FileAccessUtilWithDefault, FilesystemFileAccessUtil} from '../../utils/attachment-utils';
import {environment} from '../../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {StorageService} from '../storage.service';
import {StorageKeyEnum} from '../../shared/constants';
import {observableToPromise} from '../../utils/async-utils';
import {LoggingService} from '../common/logging.service';
import {map} from 'rxjs/operators';

const STORAGE_KEY = StorageKeyEnum.FILE_ACCESS_UTIL;
const LOG_SOURCE = 'AttachmentSettingService';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSettingService {
  private readonly mediaUrl = environment.serverUrl + 'media';
  private readonly cacheStorageFileAccessUtil = new CacheStorageFileAccessUtil(this.mediaUrl);
  private readonly filesystemFileAccessUtil = new FilesystemFileAccessUtil(this.mediaUrl);
  private readonly fileAccessUtilSubject = new ReplaySubject<FileAccessUtilWithDefault>(1);
  public readonly fileAccessUtilWithDefault$: Observable<FileAccessUtilWithDefault> = this.fileAccessUtilSubject.asObservable();
  public readonly fileAccessUtil$: Observable<AbstractFileAccessUtil> = this.fileAccessUtilSubject.asObservable().pipe(map((fileAccessUtilWithDefault) => fileAccessUtilWithDefault.fileAccessUtil));

  constructor(
    private platform: Platform,
    private storage: StorageService,
    private loggingService: LoggingService
  ) {
    this.initFileAccessUtilSubject();
  }

  public getSupportedFileAccessUtils(): Array<AbstractFileAccessUtil> {
    if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
      return [this.cacheStorageFileAccessUtil, this.filesystemFileAccessUtil];
    }
    return [this.cacheStorageFileAccessUtil];
  }

  public getDefaultFileAccessUtilForDevice(): AbstractFileAccessUtil {
    if (this.platform.is('capacitor') && this.platform.is('ios')) {
      return this.filesystemFileAccessUtil;
    }
    return this.cacheStorageFileAccessUtil;
  }

  public getSupportedFileAccessUtilClassNames(): Array<FileAccessUtilClassName> {
    return this.getSupportedFileAccessUtils().map((fileAccessUtil) => fileAccessUtil.className);
  }

  public isSwitchingFileAccessUtilSupported(): boolean {
    return this.getSupportedFileAccessUtils().length > 1;
  }

  public isFileAccessUtilSupported(fileAccessUtilClassName: FileAccessUtilClassName): boolean {
    return this.getSupportedFileAccessUtilClassNames().some((value) => value === fileAccessUtilClassName);
  }

  public async getFileAccessUtil(): Promise<AbstractFileAccessUtil> {
    return await observableToPromise(this.fileAccessUtil$);
  }

  public async getFileAccessUtilWithDefault(): Promise<FileAccessUtilWithDefault> {
    return await observableToPromise(this.fileAccessUtilWithDefault$);
  }

  private async getFileAccessUtilFromStorageOrDefault(): Promise<FileAccessUtilWithDefault> {
    const fileAccessUtilClassName: FileAccessUtilClassName | undefined | null = await this.storage.get(STORAGE_KEY);
    if (fileAccessUtilClassName) {
      this.loggingService.debug(LOG_SOURCE, `getFileAccessUtilFromStorageOrDefault - fileAccessUtilClassName from storage is "${fileAccessUtilClassName}".`);
      return {fileAccessUtil: this.getFileAccessUtilByClassName(fileAccessUtilClassName), usedDefault: false};
    }
    this.loggingService.warn(LOG_SOURCE, `getFileAccessUtilFromStorageOrDefault - storage value for key "${STORAGE_KEY}" was falsy, using default value cacheStorageFileAccessUtil.`);
    const defaultFileAccessUtil = this.cacheStorageFileAccessUtil;
    return {fileAccessUtil: defaultFileAccessUtil, usedDefault: true};
  }

  public async changeFileAccessUtil(newFileAccessUtilClassName: FileAccessUtilClassName): Promise<{oldFileAccessUtil: AbstractFileAccessUtil; newFileAccessUtil?: AbstractFileAccessUtil}> {
    const {fileAccessUtil: oldFileAccessUtil, usedDefault: oldFileAccessUsedDefault} = await observableToPromise(this.fileAccessUtilWithDefault$);
    if (oldFileAccessUtil.className === newFileAccessUtilClassName) {
      const fileAccessUtilClassNameInStorage = await this.storage.get(STORAGE_KEY);
      if (!fileAccessUtilClassNameInStorage || fileAccessUtilClassNameInStorage !== oldFileAccessUtil.className) {
        this.loggingService.info(LOG_SOURCE, `changeFileAccessUtil called and new and old FileAccessUtil is the same ("${newFileAccessUtilClassName}") but it was not persisted or wrongly.`);
        await this.storage.set(STORAGE_KEY, oldFileAccessUtil.className);
      }
      if (oldFileAccessUsedDefault) {
        this.fileAccessUtilSubject.next({fileAccessUtil: oldFileAccessUtil, usedDefault: false});
      }
      return {oldFileAccessUtil};
    }
    if (!this.isFileAccessUtilSupported(newFileAccessUtilClassName)) {
      throw new Error(`newFileAccessUtilClassName ${newFileAccessUtilClassName} is not supported on this device.`);
    }
    const newFileAccessUtil = this.getFileAccessUtilByClassName(newFileAccessUtilClassName);
    await this.storage.set(STORAGE_KEY, newFileAccessUtil.className);
    this.fileAccessUtilSubject.next({fileAccessUtil: newFileAccessUtil, usedDefault: false});
    return {oldFileAccessUtil, newFileAccessUtil};
  }

  private getFileAccessUtilByClassName(fileAccessUtilClassName: FileAccessUtilClassName): AbstractFileAccessUtil {
    switch (fileAccessUtilClassName) {
      case 'CacheStorageFileAccessUtil':
        return this.cacheStorageFileAccessUtil;
      case 'FilesystemFileAccessUtil':
        return this.filesystemFileAccessUtil;
      default:
        return this.cacheStorageFileAccessUtil;
    }
  }

  private async initFileAccessUtilSubject() {
    const fileAccessUtilWithDefault = await this.getFileAccessUtilFromStorageOrDefault();
    this.fileAccessUtilSubject.next(fileAccessUtilWithDefault);
  }
}
