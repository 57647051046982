import {IdType, Protocol} from 'submodules/baumaster-v2-common';
import {EntryCardModel} from '../model/entry-card-model';
import {Nullish} from '../model/nullish';
import {BaseProtocolEntryFilterFields, ProtocolEntryFilterFields, ProtocolEntrySearchFilter, SearchFilterField} from '../model/protocol-entry-search-filter';
import {cloneDeep} from 'lodash';

export const EMPTY_FILTER_ID = 'empty';

const defaultComparator = <T>(left: T, right: T) => {
  if (left === right) {
    return 0;
  } else if ((left === undefined || left === null) && (right !== undefined || right !== null)) {
    return 1;
  } else if ((right === undefined || right === null) && (left !== undefined || left !== null)) {
    return -1;
  } else if (left < right) {
    return 1;
  } else if (left > right) {
    return -1;
  }
  return 0;
};

export const defaultDateComparator = (left: string | Date, right: string | Date) => {
  const leftTime = left ? new Date(left).getTime() : undefined;
  const rightTime = right ? new Date(right).getTime() : undefined;
  return defaultComparator(leftTime, rightTime);
};

export const hasSearchFilter = (filter: SearchFilterField<any>) => (filter.in && filter.in.length > 0) || filter.eq || filter.lt || filter.lte || filter.gt || filter.gte;

export const execSearchFilter = <T>(filter: SearchFilterField<T>, obj: T, allCompanies?: boolean) => {
  const comparator = filter.comparator || defaultComparator;

  const eq = (value: T) => comparator(value, obj) === 0;
  const lt = (value: T) => comparator(value, obj) < 0;
  const lte = (value: T) => comparator(value, obj) <= 0;
  const gt = (value: T) => comparator(value, obj) > 0;
  const gte = (value: T) => comparator(value, obj) >= 0;

  if ((obj === null || obj === undefined) && filter.in && filter.in.includes(EMPTY_FILTER_ID as T) && !allCompanies) {
    return true;
  }

  if (filter.in && (filter.in.some(eq) || filter.in.length === 0)) {
    return true;
  }

  if (filter.eq && eq(filter.eq)) {
    return true;
  }

  if (filter.lt || filter.lte || filter.gt || filter.gte) {
    if ((filter.gte && filter.gte === EMPTY_FILTER_ID) || (filter.lte && filter.lte === EMPTY_FILTER_ID)) {
      if (obj === null || obj === undefined) {
        return true;
      } else {
        return false;
      }
    }
    const left = (filter.lt && lt) || (filter.lte && lte);
    const right = (filter.gt && gt) || (filter.gte && gte);

    return Boolean(obj) && (!left || left(filter.lt || filter.lte)) && (!right || right(filter.gt || filter.gte));
  }

  return false;
};

type EntryFilterObject = {
  [Property in keyof BaseProtocolEntryFilterFields]: Nullish<Property extends 'observerCompanies' ? IdType[] : BaseProtocolEntryFilterFields[Property]>;
};

const COMPANIES_FILTER_KEYS: (keyof ProtocolEntryFilterFields)[] = ['allCompanies', 'companyId', 'observerCompanies'];
const COMPANY_ID_FILTER_KEY: keyof ProtocolEntryFilterFields = 'companyId';

export const execEntrySearchFilter = (filters: ProtocolEntryFilterFields, object: Partial<EntryFilterObject>) => {
  const allFilterKeys = Object.keys(filters) as (keyof ProtocolEntryFilterFields)[];
  const filterKeysWithoutCompanies = allFilterKeys.filter((key) => !COMPANIES_FILTER_KEYS.includes(key));

  const execSingleFilterKey = (key: keyof ProtocolEntryFilterFields): 'empty' | 'match' | 'no-match' => {
    const filter = filters[key];
    if (!hasSearchFilter(filter)) {
      return 'empty';
    }
    const value = object[key];
    if (value && Array.isArray(value)) {
      return value.some((item) => execSearchFilter(filter as SearchFilterField<IdType>, item)) ? 'match' : 'no-match';
    }
    if (key === COMPANY_ID_FILTER_KEY) {
      return execSearchFilter(filter, value, object.allCompanies) ? 'match' : 'no-match';
    } else {
      return execSearchFilter(filter, value) ? 'match' : 'no-match';
    }
  };

  // Company-related filters must match with OR
  const hasAnyCompanyFilter = COMPANIES_FILTER_KEYS.some((key) => hasSearchFilter(filters[key]));
  const matchesCompanyFilters = COMPANIES_FILTER_KEYS.some((key) => execSingleFilterKey(key) === 'match');

  const matchesRestOfTheFilters = filterKeysWithoutCompanies.every((key) => {
    const result = execSingleFilterKey(key);
    return result === 'match' || result === 'empty';
  });

  return (!hasAnyCompanyFilter || matchesCompanyFilters) && matchesRestOfTheFilters;
};

export const execEntriesFilter = (filters: ProtocolEntrySearchFilter, entries: EntryCardModel[]) => {
  const filteredEntries = entries.filter((entry) =>
    execEntrySearchFilter(filters.entry, {
      ...entry,
      createdAt: entry.createdAt ? new Date(entry.createdAt) : undefined,
    })
  );
  const filteredEntryIds = new Set(filteredEntries.map(({id}) => id));
  const parentIds = new Set(filteredEntries.filter(({parentId}) => !!parentId).map(({parentId}) => parentId));

  return entries.filter(({id}) => filteredEntryIds.has(id) || parentIds.has(id));
};

export const protocolSearchFilterFactory = (filters: ProtocolEntrySearchFilter): ((protocol: Protocol) => boolean) => {
  return (protocol: Protocol) => {
    const protoFilters = Object.entries(filters.protocol);

    return protoFilters.filter(([_, filter]) => filter !== null).every(([key, filter]) => execSearchFilter(filter, protocol[key]));
  };
};

export const PROJECT_TEAM_PSEUDO_ID: IdType = 'project_team';

export const passAllCompaniesToCompanyIdFilter = (originalFilter: ProtocolEntrySearchFilter): ProtocolEntrySearchFilter => {
  const filter = cloneDeep(originalFilter);
  if (filter.entry.allCompanies.in?.includes(true) && (!filter.entry.companyId.in || !filter.entry.companyId.in.includes(PROJECT_TEAM_PSEUDO_ID))) {
    filter.entry.companyId.in = [PROJECT_TEAM_PSEUDO_ID, ...(filter.entry.companyId.in ?? [])];
  }
  return filter;
};

export const allCompaniesFromCompanyIdToAllCompaniesFilter = (originalFilter: ProtocolEntrySearchFilter): ProtocolEntrySearchFilter => {
  const filter = cloneDeep(originalFilter);
  if (filter.entry.companyId.in?.includes(PROJECT_TEAM_PSEUDO_ID)) {
    filter.entry.companyId.in = filter.entry.companyId.in.filter((id) => id !== PROJECT_TEAM_PSEUDO_ID);
    filter.entry.allCompanies.in = [true];
  } else {
    filter.entry.allCompanies.in = [];
  }
  return filter;
};
