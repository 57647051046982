import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {DashboardFilterMultiButtonItem, DashboardFilterMultiButtonItemValueDefault} from 'src/app/model/dashboard-filter';

@Component({
  selector: 'app-entry-filter-multi-buttons',
  templateUrl: './entry-filter-multi-buttons.component.html',
  styleUrls: ['./entry-filter-multi-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, FontAwesomeModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntryFilterMultiButtonsComponent),
      multi: true,
    },
  ],
})
export class EntryFilterMultiButtonsComponent implements ControlValueAccessor, OnChanges {
  @Input()
  isMultiple = true;

  @Input()
  forceOneSelected = true;

  @Input()
  buttons: DashboardFilterMultiButtonItem[];

  @Input()
  storeOnlyValue = false;

  value: DashboardFilterMultiButtonItem[] | DashboardFilterMultiButtonItem | DashboardFilterMultiButtonItemValueDefault | DashboardFilterMultiButtonItemValueDefault[] = [];

  isSelected: Record<string | number, boolean> = {};

  readonly trackByValue = (button: DashboardFilterMultiButtonItem) => button.value;

  private propagateOnChange = (_: any) => {};
  private propagateOnTouched = () => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  private isMultipleMode(
    value: DashboardFilterMultiButtonItem | DashboardFilterMultiButtonItem[] | DashboardFilterMultiButtonItemValueDefault | DashboardFilterMultiButtonItemValueDefault[]
  ): value is DashboardFilterMultiButtonItem[] | DashboardFilterMultiButtonItemValueDefault[] {
    return this.isMultiple;
  }

  private isStoreOnlyValueMode(value: DashboardFilterMultiButtonItem | DashboardFilterMultiButtonItemValueDefault): value is DashboardFilterMultiButtonItemValueDefault;
  private isStoreOnlyValueMode(value: DashboardFilterMultiButtonItem[] | DashboardFilterMultiButtonItemValueDefault[]): value is DashboardFilterMultiButtonItemValueDefault[];
  private isStoreOnlyValueMode(
    value: DashboardFilterMultiButtonItem | DashboardFilterMultiButtonItem[] | DashboardFilterMultiButtonItemValueDefault | DashboardFilterMultiButtonItemValueDefault[]
  ): value is DashboardFilterMultiButtonItemValueDefault | DashboardFilterMultiButtonItemValueDefault[] {
    return this.storeOnlyValue;
  }

  private updateSelected() {
    if (this.isMultipleMode(this.value)) {
      if (this.isStoreOnlyValueMode(this.value)) {
        this.isSelected =
          this.value?.reduce((acc, item) => {
            acc[item] = true;
            return acc;
          }, {}) ?? {};
      } else {
        this.isSelected =
          this.value?.reduce((acc, item) => {
            acc[item.value] = true;
            return acc;
          }, {}) ?? {};
      }
    } else {
      if (this.isStoreOnlyValueMode(this.value)) {
        this.isSelected = this.value ? {[this.value]: true} : {};
      } else {
        this.isSelected = this.value ? {[this.value.value]: true} : {};
      }
    }

    this.cdRef.markForCheck();
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateSelected();
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.buttons) {
      this.updateSelected();
    }
  }

  buttonClick(button: DashboardFilterMultiButtonItem) {
    if (this.isMultipleMode(this.value)) {
      if (this.isSelected[button.value]) {
        this.value = this.isStoreOnlyValueMode(this.value) ? this.value.filter((item) => item !== button.value) : this.value.filter((item) => item.label !== button.value);
      } else {
        if (this.isStoreOnlyValueMode(this.value)) {
          this.value = [...(this.value ?? []), button.value];
        } else {
          this.value = [...(this.value ?? []), button];
        }
      }
    } else {
      if (!this.isSelected[button.value]) {
        this.value = this.isStoreOnlyValueMode(this.value) ? button.value : button;
      } else if (!this.forceOneSelected) {
        this.value = null;
      }
    }
    this.updateSelected();

    this.propagateOnChange(this.value);
    this.propagateOnTouched();
  }
}
