import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {filter, map, share, switchMap, take, tap} from 'rxjs/operators';
import {AuthDetails} from 'src/app/model/auth';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {startWorkerWithAuthToken} from 'src/app/utils/async-utils';
import {TokenGetter, TokenHolder} from 'src/app/utils/token-holder';
import {AuthenticateRes, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {UserDataService} from '../data/user-data.service';
import {SystemEventService} from '../event/system-event.service';
import {PosthogService} from '../posthog/posthog.service';
import {AuthenticationService} from './authentication.service';
import {UnauthorizedModalService} from './unauthorized-modal.service';
import {NetworkStatusService} from '../common/network-status.service';

const LOG_SOURCE = 'TokenManagerService';

export class TokenManagerError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class TokenManagerService {
  private pendingNewAuth: Observable<AuthenticateRes> | undefined;
  private alreadyInvalidRefreshTokens = new Set<string>();

  constructor(
    private authService: AuthenticationService,
    private userDataService: UserDataService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private unauthorizedModalService: UnauthorizedModalService,
    private posthogService: PosthogService,
    private networkStatusService: NetworkStatusService
  ) {}

  async startWorkerWithAuthToken(worker: Worker, message: any): Promise<any> {
    return await startWorkerWithAuthToken(worker, this.authService.dataToken, () => this.scheduleTokenRefresh(), message);
  }

  async runWithTokenGetter<T>(fn: (tokenGetter: TokenGetter) => Promise<T>): Promise<T> {
    return new Promise<T>(async (res, rej) => {
      const tokensHolder = new TokenHolder();
      const subInactive = tokensHolder.tokenInvalid$.subscribe(() => this.scheduleTokenRefresh());
      const sub = this.authService.dataToken
        .pipe(
          tap((token) => {
            if (!token) {
              sub.unsubscribe();
              subInactive.unsubscribe();
              const error = new Error('token is not defined');
              rej(error);
              throw error;
            }
          })
        )
        .subscribe((token) => tokensHolder.addToken(token.token, token.expireAt));
      try {
        res(await fn(tokensHolder));
      } catch (error) {
        this.networkStatusService.markFailedServerRequestIfNetworkError(error);
        throw error;
      } finally {
        sub.unsubscribe();
        subInactive.unsubscribe();
      }
    });
  }

  scheduleTokenRefresh() {
    if (!this.pendingNewAuth) {
      this.getNewToken$().subscribe(); // No need to unsubscribe, as everything is handled by getNewToken.
    }
  }

  getNewToken$(): Observable<AuthenticateRes> {
    if (!this.pendingNewAuth) {
      this.loggingService.debug(LOG_SOURCE, 'getNewToken - Will attempt to get new token');
      const observable = this.authService.data.pipe(
        filter((auth) => {
          if (auth && 'refreshToken' in auth) {
            const isInvalid = this.alreadyInvalidRefreshTokens.has(auth.refreshToken);
            if (isInvalid) {
              // Do not request for a new access token, if we already had 401 response for that particular refresh token.
              this.loggingService.debug(LOG_SOURCE, `getNewToken - skipping token ${auth.refreshToken}, as it has already been seen as invalid; `);
              // Also, just to be sure, unauthorized modal should be displayed, in case it has not been displayed by the interceptor. UnauthorizedModalService will not open the modal twice anyway.
              // (it should be only a case when getNewToken$ is called from dev mode or token getter)
              this.posthogService.captureEvent('[Security][Forced_re-authentication] using_already_invalid_refresh_token', {});
              this.unauthorizedModalService.openModal();
            }
            return !isInvalid;
          }

          return true;
        }),
        take(1),
        switchMap((auth) => {
          if (!auth) {
            throw new TokenManagerError('TokenManagerService.getRefreshTokenObservable: Auth is null');
          }

          return this.userDataService.getById(auth.userId).pipe(
            take(1),
            switchMap((user) => this.getAccessToken$(auth, user)),
            tap({
              error: (error) => {
                if ('refreshToken' in auth && error instanceof HttpErrorResponse && error.status === 401) {
                  this.alreadyInvalidRefreshTokens.add(auth.refreshToken);
                }
              },
            })
          );
        }),
        switchMap((auth) => {
          this.loggingService.debug(LOG_SOURCE, 'getNewToken - got new token; replacing...');
          return from(this.authService.replaceToken(auth)).pipe(
            tap(() => {
              this.alreadyInvalidRefreshTokens.clear(); // New token has been placed, meaning no old tokens should be available by now; set can be cleared.
            }),
            map(() => auth)
          );
        }),
        share(),
        tap({
          error: (error) => {
            if (this.pendingNewAuth === observable) {
              this.pendingNewAuth = undefined;
            }
            this.systemEventService.logErrorEvent(LOG_SOURCE, `getNewToken - observable sent error: ${convertErrorToMessage(error)}`);
          },
          complete: () => {
            if (this.pendingNewAuth === observable) {
              this.pendingNewAuth = undefined;
            }
          },
        })
      );
      this.pendingNewAuth = observable;
    }

    return this.pendingNewAuth;
  }

  private getAccessToken$(auth: AuthDetails, user: User): Observable<AuthenticateRes> {
    if (!auth) {
      throw new TokenManagerError('TokenManagerService.getRefreshTokenObservable: Auth is null');
    }
    if (!user) {
      throw new TokenManagerError('TokenManagerService.getRefreshTokenObservable: User is null');
    }
    if (user.id !== auth.userId) {
      throw new TokenManagerError(`TokenManagerService.getRefreshTokenObservable: Auth data user id is not equal to user id (${user.id} != ${auth.userId})`);
    }
    if (!('refreshToken' in auth)) {
      return this.authService.getAccessToken({
        token: auth.token,
      });
    }
    return this.authService.getAccessToken({
      refreshToken: auth.refreshToken,
      username: user.username,
    });
  }
}
