import {BimMarker} from 'submodules/baumaster-v2-common';

export type Box3Bounds = [THREE.Vector3Tuple, THREE.Vector3Tuple];
export type BimMarkerPosition = Pick<BimMarker, 'positionX' | 'positionY' | 'positionZ'>;

export const DEFAULT_EPSILON = 10e-6;

export const compareWithEpsilon = (a: number, b: number, epsilon = DEFAULT_EPSILON) => Math.abs(a - b) < epsilon;

export const compareVectors3WithEpsilon = (a: THREE.Vector3, b: THREE.Vector3, epsilon = DEFAULT_EPSILON) =>
  compareWithEpsilon(a.x, b.x, epsilon) && compareWithEpsilon(a.y, b.y, epsilon) && compareWithEpsilon(a.z, b.z, epsilon);

export const compareVector3TuplesWithEpsilon = (a: THREE.Vector3Tuple, b: THREE.Vector3Tuple, epsilon = DEFAULT_EPSILON) =>
  compareWithEpsilon(a[0], b[0], epsilon) && compareWithEpsilon(a[1], b[1], epsilon) && compareWithEpsilon(a[2], b[2], epsilon);

export const box3ToArray = (box: THREE.Box3): Box3Bounds => [
  [box.min.x, box.min.y, box.min.z],
  [box.max.x, box.max.y, box.max.z],
];
export const vector3ToArray = (vector: THREE.Vector3): THREE.Vector3Tuple => [vector.x, vector.y, vector.z];
export const vector3ToBimMarkerPosition = (vector: THREE.Vector3): BimMarkerPosition => ({
  positionX: vector.x,
  positionY: vector.y,
  positionZ: vector.z,
});
export const vector3TupleToBimMarkerPosition = (vector: THREE.Vector3Tuple): BimMarkerPosition => ({
  positionX: vector[0],
  positionY: vector[1],
  positionZ: vector[2],
});
export const ensurePositionIsNumber = (markerPosition: BimMarkerPosition): BimMarkerPosition => {
  const pos = {
    positionX: +markerPosition.positionX,
    positionY: +markerPosition.positionY,
    positionZ: +markerPosition.positionZ,
  };

  if (isNaN(pos.positionX) || isNaN(pos.positionY) || isNaN(pos.positionZ)) {
    throw new Error(`Marker position is invalid (got ${markerPosition.positionX}, ${markerPosition.positionY}, ${markerPosition.positionZ})`);
  }

  return pos;
};

export const isVector3TupleLike = (tuple: unknown): tuple is THREE.Vector3Tuple => tuple && Array.isArray(tuple) && tuple.length === 3 && tuple.every((v) => !isNaN(+v));
