import {Injectable, OnDestroy} from '@angular/core';
import {IdType, Project} from 'submodules/baumaster-v2-common';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../auth/authentication.service';
import {StorageKeyEnum} from '../../shared/constants';
import {StorageService} from '../storage.service';
import async, {AsyncResultCallback, QueueObject} from 'async';
import {ProjectDataService} from '../data/project-data.service';
import {RecentlyUsedKeyType, SelectableRecentlyUsed, SelectableRecentlyUsedItems} from '../../model/selectable';
import {LoggingService} from './logging.service';
import {defaultIfEmpty, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import _ from 'lodash';

const RECENTLY_USED_LIMIT_AMOUNT = 5;
const RECENTLY_USED_LIMIT_IN_DAYS = 14;
const RECENTLY_USED_LIMIT_IN_MS = RECENTLY_USED_LIMIT_IN_DAYS * 24 * 60 * 60 * 1000;

const LOG_SOURCE = 'RecentlyUsedSelectableService';

@Injectable({
  providedIn: 'root',
})
export class RecentlyUsedSelectableService implements OnDestroy {
  private static readonly synchronizedStorageAccessQueue: QueueObject<() => Promise<any>> = async.queue<any>(async (task: () => Promise<any>, callback: AsyncResultCallback<any>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);
  private currentProject$ = this.projectDataService.currentProjectObservable;
  private currentProject: Project | undefined;
  private currentProjectSubscription: Subscription;
  private recentlyUsedAllSubject = new BehaviorSubject<SelectableRecentlyUsed | undefined>(undefined);
  private recentlyUsedAll$ = this.recentlyUsedAllSubject.pipe(shareReplay(1));
  private recentlyUsedAllCurrentProject$ = this.currentProject$
    .pipe(switchMap((currentProject) => this.recentlyUsedAll$.pipe(map((recentlyUsedAll) => recentlyUsedAll?.[currentProject?.id]))))
    .pipe(shareReplay(1));
  private keyChangedSubject = new BehaviorSubject<{projectId: IdType; recentlyUsedKey: RecentlyUsedKeyType; items: SelectableRecentlyUsedItems} | undefined>(undefined);
  private keyChanged$ = this.keyChangedSubject.pipe(shareReplay());
  private keyChangedCurrentProject$ = this.currentProject$.pipe(
    switchMap((currentProject) => this.keyChanged$.pipe(filter((keyChanged) => !keyChanged || keyChanged?.projectId === currentProject.id)))
  );

  set recentlyUsedAll(newValue: SelectableRecentlyUsed) {
    this.recentlyUsedAllSubject.next(newValue);
  }

  get recentlyUsedAll(): SelectableRecentlyUsed | undefined {
    return this.recentlyUsedAllSubject.value;
  }

  public recentlyUsedItemsOfCurrentProject$: Observable<Record<string, SelectableRecentlyUsedItems> | undefined> = this.currentProject$.pipe(
    switchMap((currentProject) => this.recentlyUsedAll$.pipe(map((recentlyUsedAll) => (currentProject ? recentlyUsedAll[currentProject.id] : undefined))))
  );

  constructor(
    private storage: StorageService,
    private authenticationService: AuthenticationService,
    private projectDataService: ProjectDataService,
    private loggingService: LoggingService
  ) {
    this.currentProjectSubscription = this.currentProject$.subscribe((currentProject) => {
      this.currentProject = currentProject;
    });
    this.readRecentlyUsedFromStorage();
  }

  ngOnDestroy(): void {
    this.currentProjectSubscription.unsubscribe();
  }

  private static async runInSynchronizedStorageAccess<R>(functionToCall: () => Promise<R>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      RecentlyUsedSelectableService.synchronizedStorageAccessQueue.push<R>(functionToCall, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  }

  private async readRecentlyUsedFromStorage(): Promise<SelectableRecentlyUsed> {
    this.loggingService.debug(LOG_SOURCE, 'readRecentlyUsedFromStorage called');
    const ret = await RecentlyUsedSelectableService.runInSynchronizedStorageAccess(async () => {
      const recentlyUsedAll: SelectableRecentlyUsed = (await this.storage.get(StorageKeyEnum.SELECTABLE_RECENTLY_USED)) ?? {};
      this.loggingService.debug(LOG_SOURCE, `readRecentlyUsedFromStorage`);
      let changedAny = false;
      for (const projectId of Object.keys(recentlyUsedAll)) {
        const recentlyUsedForProject = recentlyUsedAll[projectId] ?? {};
        for (const recentlyUsedKey of Object.keys(recentlyUsedForProject)) {
          const recentlyUsedItems = recentlyUsedForProject[recentlyUsedKey];
          const {recentlyUsedItems: newRecentlyUsedItems, changed} = this.cleanupRecentlyUsedItems(recentlyUsedItems);
          if (changed) {
            changedAny = true;
            recentlyUsedForProject[recentlyUsedKey] = newRecentlyUsedItems;
          }
        }
      }
      if (changedAny) {
        await this.storage.set(StorageKeyEnum.SELECTABLE_RECENTLY_USED, recentlyUsedAll);
      }
      return recentlyUsedAll;
    });
    this.loggingService.debug(LOG_SOURCE, `readRecentlyUsedFromStorage finished`);
    this.recentlyUsedAll = ret;
    return this.recentlyUsedAll;
  }

  public getRecentlyUsedItemsOfCurrentProject(recentlyUsedKey: RecentlyUsedKeyType): SelectableRecentlyUsedItems | undefined {
    if (!this.currentProject) {
      this.loggingService.warn(LOG_SOURCE, 'Unable to return a value for getRecentlyUsedItemsOfCurrentProject since no project is currently selected.');
      return undefined;
    }
    if (!this.recentlyUsedAll) {
      this.loggingService.warn(LOG_SOURCE, 'Unable to return a value for getRecentlyUsedItemsOfCurrentProject since Data is not initiliazed.');
      return undefined;
    }
    return this.recentlyUsedAll[this.currentProject.id]?.[recentlyUsedKey];
  }

  public getRecentlyUsedItemsOfCurrentProject$(recentlyUsedKey: RecentlyUsedKeyType): Observable<SelectableRecentlyUsedItems | undefined> {
    return combineLatestAsync([
      this.recentlyUsedAllCurrentProject$.pipe(map((recentlyUsedAllCurrentProject) => recentlyUsedAllCurrentProject?.[recentlyUsedKey])),
      this.keyChangedCurrentProject$
        .pipe(filter((keyChanged) => !keyChanged || keyChanged?.recentlyUsedKey === recentlyUsedKey))
        .pipe(shareReplay(1))
        .pipe(map((keyChanged) => keyChanged?.items))
        .pipe(defaultIfEmpty(undefined)),
    ]).pipe(map(([recentlyUsedItems1, recentlyUsedItems2]) => recentlyUsedItems2 ?? recentlyUsedItems1));
  }

  public async markRecentlyUsedForCurrentProject(recentlyUsedKey: RecentlyUsedKeyType, idsOrId: IdType | Array<IdType>): Promise<SelectableRecentlyUsedItems> {
    if (!this.currentProject) {
      this.loggingService.warn(LOG_SOURCE, 'Unable to mark a value as currently used since no project is currently selected.');
      return undefined;
    }
    if (!this.recentlyUsedAll) {
      await this.readRecentlyUsedFromStorage();
    }
    if (!this.recentlyUsedAll) {
      throw new Error('RecentlyUsedSelectableService - markRecentlyUsed - variable recentlyUsedAll is still not initialized after reading from storage');
    }
    const recentlyUsedItems = this.recentlyUsedAll[this.currentProject.id]?.[recentlyUsedKey] ?? [];
    const ids: Array<IdType> = _.isArray(idsOrId) ? (idsOrId as Array<IdType>) : [idsOrId as IdType];
    for (const id of ids) {
      const indexOfExistingEntry = recentlyUsedItems.findIndex((recentlyUsedItem) => recentlyUsedItem.id === id);
      if (indexOfExistingEntry !== undefined && indexOfExistingEntry >= 0) {
        recentlyUsedItems[indexOfExistingEntry].lastUsedTimestamp = Date.now();
      } else {
        recentlyUsedItems.push({id, lastUsedTimestamp: Date.now()});
      }
    }
    const {recentlyUsedItems: cleanedRecentlyUsedItems} = this.cleanupRecentlyUsedItems(recentlyUsedItems);

    await RecentlyUsedSelectableService.runInSynchronizedStorageAccess(async () => {
      const recentlyUsedAll: SelectableRecentlyUsed = (await this.storage.get(StorageKeyEnum.SELECTABLE_RECENTLY_USED)) ?? {};
      if (!recentlyUsedAll[this.currentProject.id]) {
        recentlyUsedAll[this.currentProject.id] = {company: [], craft: [], location: [], protocolEntryType: [], user: [], additionalField: [], protocol: [], pdfPlans: [], unit: []};
      }
      recentlyUsedAll[this.currentProject.id][recentlyUsedKey] = cleanedRecentlyUsedItems;
      await this.storage.set(StorageKeyEnum.SELECTABLE_RECENTLY_USED, recentlyUsedAll);
      this.recentlyUsedAll = recentlyUsedAll;
    });

    this.keyChangedSubject.next({projectId: this.currentProject.id, recentlyUsedKey, items: cleanedRecentlyUsedItems});

    return cleanedRecentlyUsedItems;
  }

  private cleanupRecentlyUsedItems(recentlyUsedItems: SelectableRecentlyUsedItems): {recentlyUsedItems: SelectableRecentlyUsedItems; changed: boolean} {
    const minTimestampRecentlyUsed = Date.now() - RECENTLY_USED_LIMIT_IN_MS;
    const newRecentlyUsedItems = _.orderBy(recentlyUsedItems, 'lastUsedTimestamp', 'desc').filter((recentlyUsedItem) => recentlyUsedItem.lastUsedTimestamp >= minTimestampRecentlyUsed);
    if (newRecentlyUsedItems.length > RECENTLY_USED_LIMIT_AMOUNT) {
      newRecentlyUsedItems.splice(RECENTLY_USED_LIMIT_AMOUNT);
    }
    const changed = recentlyUsedItems.length !== newRecentlyUsedItems.length;
    return {
      recentlyUsedItems: newRecentlyUsedItems,
      changed,
    };
  }
}
