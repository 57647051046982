import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {IdType, LicenseType, ReportWeek, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {Nullish} from 'src/app/model/nullish';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/reportWeeks';

@Injectable({
  providedIn: 'root',
})
export class ReportWeekDataService extends AbstractProjectAwareDataService<ReportWeek> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.REPORT_WEEK,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  getByYearAndWeek(year: number, week: number): Observable<ReportWeek | undefined> {
    return this.data.pipe(map((reportWeeks) => reportWeeks.find((reportWeek) => reportWeek.year === year && reportWeek.calenderWeek === week)));
  }

  getByYearWeekAndTypeId(year: number, week: number, typeId: IdType): Observable<ReportWeek | undefined> {
    return this.data.pipe(map((reportWeeks) => reportWeeks.find((reportWeek) => reportWeek.year === year && reportWeek.calenderWeek === week && reportWeek.typeId === typeId)));
  }

  getByYearWeekAndTypeIdAndCustomTypeId(year: number, calenderWeek: number, typeId: IdType, customReportTypeId: Nullish<IdType>): Observable<ReportWeek | undefined> {
    return this.data.pipe(
      map((reportWeeks) =>
        reportWeeks.find((reportWeek) =>
          haveObjectsEqualProperties<Pick<ReportWeek, 'year' | 'calenderWeek' | 'typeId' | 'customReportTypeId'>>(reportWeek, {year, calenderWeek, typeId, customReportTypeId}, [
            'year',
            'calenderWeek',
            'typeId',
            'customReportTypeId',
          ])
        )
      )
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }
}
