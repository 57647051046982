import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {groupBy} from 'lodash';
import {map} from 'rxjs';
import {NotificationConfig} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {StorageService} from '../storage.service';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';

const REST_ENDPOINT_URI = 'api/data/notificationConfigs';
const VERSION_INTRODUCED = '2.28.0'; // todo: Change before release

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigDataService extends AbstractNonClientAwareDataService<NotificationConfig> {
  dataByProjectId$ = this.data.pipe(map((data) => groupBy(data, 'projectId')));

  constructor(http: HttpClient, storage: StorageService, authenticationService: AuthenticationService, loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.NOTIFICATION_CONFIG, REST_ENDPOINT_URI, [], http, storage, authenticationService, loggingService, integrityResolverService, VERSION_INTRODUCED);
  }
}
