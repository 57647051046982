import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fabric} from 'fabric';
import {Image} from 'fabric/fabric-impl';
import _ from 'lodash';
import * as Hammer from 'hammerjs';
import {IGroupOptions} from 'fabric/fabric-impl';
import {AlertController, IonButton, LoadingController, NavParams, Platform} from '@ionic/angular';
import {TranslatePipe} from '@ngx-translate/core';
import {calculateOptimalDimension, CanvasDimension, enclose} from '../../../utils/canvas-utils';
import {MarkerData} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/fabricPdfPlanMarker';
import {addCanvasMarkers, loadImageEnsureWidthHeight, loadMarkerImages} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {ProtocolEntryIconStatus} from 'submodules/baumaster-v2-common';
import {Nullish} from '../../../model/nullish';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoggingService} from '../../../services/common/logging.service';

const LOG_SOURCE = 'SketchComponent';
const ZOOMSPEED = 1.12;

export enum DrawMode {
  LINE = 'line',
  ARROW = 'arrow',
  DUAL_ARROW = 'dualArrow',
  TEXT = 'text',
  ERASE = 'erase',
  FREE_DRAW = 'freeDraw',
  MOUSE = 'mouse',
  MOVE = 'move',
}

export enum Shortcuts {
  LINE = 'KeyL',
  ARROW = 'KeyA',
  TEXT = 'KeyT',
  ERASE = 'KeyE',
  FREE_DRAW = 'KeyP',
  MOUSE = 'KeyS',
  MOVE = 'KeyV',
  DELETE = 'Delete',
}

const PIXEL_LIMIT = 2;

@Component({
  selector: 'app-sketch',
  templateUrl: './sketch.component.html',
  styleUrls: ['./sketch.component.scss'],
  providers: [TranslatePipe],
})
export class SketchComponent implements OnInit, OnDestroy {
  modal: HTMLIonModalElement;
  public applyInsteadOfSave = false;
  public attachmentUrl: string;
  public attachmentWidth?: number | null;
  public attachmentHeight?: number | null;
  public canErase = true;
  public revokeObjectUrlOnDestroy = false;
  public markings: string;
  public emptyTemplate: false;
  public signature: false;
  public onMarkingsChanged: (markings: Nullish<string>) => void;
  @ViewChild('canvasSketchContainer') canvasSketchContainer: ElementRef;
  @ViewChild('saveButton') saveButton: IonButton;
  public currentMode: string;
  public canvas: any;
  public hammer;
  private destroy$ = new Subject<void>();
  public imageScaled: boolean | undefined;

  public group;
  public history;

  public undoRedo: boolean;
  public didUndo: boolean;
  public drawHistory = [];
  public modeMethods = {};
  public fabActivated: boolean;

  public STATES = Object.freeze({
    LOADING: 0,
    FAILED: 1,
    SUCCESS: 2,
  });

  public HEIGHT_UI_CONTAINER_REM = 8;
  public WIDTH_MAX_PERCENT_OF_VIEWPORT = 90;
  public SMALL_UI_WIDTH = 600;
  public LARGE_UI_WIDTH = 992;
  public SIGNATURE_STROKE_WIDTH = 5;
  public SIGNATURE_COLOR = '#000000';
  public hiddenTextarea: any;
  public strokeWidth = 5;
  public viewportMeta: any = {
    penOnly: false,
    radius: null,
    state: this.STATES.LOADING,
    width: 0,
    height: 0,
    factor: 1,
    currentMode: null,
    redoUndo: false,
    version: 0,
    user: 'x-net',
    scale: 1,
    historyPointer: 0,
    // lastSavedAt is reverse-0-indexed, meaning the last item has index 0, and the first item has index length-1
    lastSavedAt: 0,
    lastJSON: null,
    scaleOffset: {x: 0, y: 0},
    movePositionMode: false, // is true when zooming or panning - mostly used to check if new line/arrow/freedraw should be placed on screen or not
    isSmallScreen: false,
    panSpeed: 1.0,
    backgroundImg: null,
    saveButtonDisabled: false,
    stateConfig: {
      canvasState: [],
      currentStateIndex: -1,
      undoStatus: false,
      redoStatus: false,
      undoFinishedStatus: 1,
      redoFinishedStatus: 1,
      undoButton: document.getElementById('undo'),
      redoButton: document.getElementById('redo'),
    },

    lastArrowObjects: [],
    lastDrawnLine: null,
    objectsSelected: false,
    colorPicker: false,
    textToAdd: null,

    colors: [
      {name: 'sketching-red', hex: '#FF0000'},
      {name: 'sketching-yellow', hex: '#FFFF00'},
      {name: 'sketching-green', hex: '#008000'},
      {name: 'sketching-blue', hex: '#00FFFF'},
      {name: 'sketching-orange', hex: '#FFA000'},
      {name: 'sketching-pink', hex: '#FF00FF'},
      {name: 'sketching-darkblue', hex: '#1900FF'},
      {name: 'sketching-white', hex: '#FFFFFF'},
      {name: 'sketching-grey', hex: '#808080'},
      {name: 'sketching-black', hex: '#000000'},
    ],

    modesVars: {
      color: '#000000',
      colorClass: 'sketching-black',
      strokewidth: this.strokeWidth,
      swmin: 0.5,
      swmax: 9.5,
      swstep: 1.5,
    },
    viewportWidth: 0,
    viewportHeight: 0,
  };
  private next = null;
  private planMarkers: Array<MarkerData> | undefined;
  private loadedSvgMarkers: Map<ProtocolEntryIconStatus, CanvasImageSource>;
  private currentBackgroundImage: Image | undefined;

  isLargeScreen() {
    return this.platform.width() >= this.LARGE_UI_WIDTH;
  }

  changeColor(colorHex: string) {
    const color = _.find(this.viewportMeta.colors, {hex: colorHex});
    this.viewportMeta.modesVars.color = color.hex;
    this.viewportMeta.modesVars.colorClass = color.name;
  }

  changeSize(event) {
    const sw = parseFloat(event.target.value);
    this.strokeWidth = sw;
    this.viewportMeta.modesVars.strokewidth = this.size_to_factor(sw);
    this.canvas.freeDrawingBrush.width = this.size_to_factor(sw);
  }

  setInputValue(event) {
    this.viewportMeta.textToAdd = event.target.value;
  }

  resolveFabricPromise() {
    this.next = true;
  }

  rejectFabricPromise() {
    this.next = false;
  }

  async waitUserInput() {
    const timeout = async (ms) => new Promise((res) => setTimeout(res, ms));
    while (this.next === null) {
      await timeout(50); // pause script
    }
    const promiseStatus = this.next;
    this.next = null; // reset var
    return promiseStatus;
  }

  constructor(
    public platform: Platform,
    public navParams: NavParams,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public translatePipe: TranslatePipe,
    private loggingService: LoggingService,
    private elementRef: ElementRef
  ) {}

  undo() {
    this.canvas.discardActiveObject();
    this.undoRedo = true;
    const stateConfig = this.viewportMeta.stateConfig;
    const canvasObject = this.canvas;
    this.didUndo = true;
    if (stateConfig.currentStateIndex === -1) {
      stateConfig.undoStatus = false;
    } else {
      if (stateConfig.canvasState.length >= 1) {
        stateConfig.undoFinishedStatus = 0;
        if (stateConfig.currentStateIndex > 0) {
          stateConfig.undoStatus = true;
          const objects = stateConfig.canvasState[stateConfig.currentStateIndex - 1];
          this.canvas.remove(...this.canvas._objects);
          this.canvas.add(...objects);
          this.canvas.renderAll();
          stateConfig.undoStatus = false;
          stateConfig.currentStateIndex -= 1;
          stateConfig.undoFinishedStatus = 1;
        } else if (stateConfig.currentStateIndex !== 0) {
          // not sure when this happens
          canvasObject.clear();
          stateConfig.undoFinishedStatus = 1;
          stateConfig.currentStateIndex -= 1;
        }
      }
    }

    if (this.currentMode === DrawMode.MOUSE) {
      this.canvas.isDrawingMode = false;
      this.canvas.selection = true;
      this.getCanvasObjectsButMarker().forEach((obj) => (obj.selectable = true));
    }
  }

  redo() {
    this.canvas.discardActiveObject();
    this.undoRedo = true;
    const _config = this.viewportMeta.stateConfig;
    if (_config.currentStateIndex === _config.canvasState.length - 1 && _config.currentStateIndex !== -1) {
      // _config.redoButton.disabled= "disabled";
    } else {
      if (_config.canvasState.length > _config.currentStateIndex && _config.canvasState.length !== 0) {
        _config.redoFinishedStatus = 0;
        _config.redoStatus = true;
        const objects = _config.canvasState[_config.currentStateIndex + 1];
        this.canvas.remove(...this.canvas._objects);
        this.canvas.add(...objects);
        _config.redoStatus = false;
        _config.currentStateIndex += 1;
        _config.redoFinishedStatus = 1;
      }
    }

    if (this.currentMode === DrawMode.MOUSE) {
      this.canvas.isDrawingMode = false;
      this.canvas.selection = true;
      this.canvas.getObjects().forEach((obj) => (obj.selectable = true));
    }
  }

  async save() {
    this.removePlanMarkerElementsFromCanvas();
    const fabricData = this.canvas.toJSON();
    fabricData.backgroundImage = null;
    delete fabricData.src;

    let newFabricDataAsJson: Nullish<string>;
    if (fabricData.objects && !fabricData.objects.length) {
      newFabricDataAsJson = null;
    } else {
      newFabricDataAsJson = JSON.stringify({
        user: this.viewportMeta.user, // do we need to set the current user id here?
        last_modified: Date.now(),
        version: this.viewportMeta.version,
        fabricData,
      });
    }

    await this.onMarkingsChanged(newFabricDataAsJson);
    this.clearCanvas();
    await this.modal.dismiss();
  }

  private removePlanMarkerElementsFromCanvas() {
    const markerObjects = this.canvas.getObjects().filter((object) => this.isMarkerObject(object));
    for (const markerObject of markerObjects) {
      this.canvas.remove(markerObject);
    }
  }

  private isMarkerObject(object: any): boolean {
    return !!object.markerData;
  }

  async close() {
    if (!this.hasCanvasChanged()) {
      this.clearCanvas();
      await this.modal.dismiss();
    } else {
      await this.handleButtonClick();
    }
  }

  private clearCanvas() {
    if (this.canvas) {
      this.canvas.setDimensions({width: 0, height: 0});
      this.canvas.clear();
      this.canvas = null;
    }
  }

  async handleButtonClick() {
    const alert = await this.alertController.create({
      message: this.translatePipe.transform('modal.cancel.confirm'),
      buttons: [
        {
          text: this.translatePipe.transform('no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: this.translatePipe.transform('yes'),
          handler: async () => {
            this.clearCanvas();
            await this.modal.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  deleteSelectedObjects() {
    const activeObjects = this.canvas.getActiveObjects();
    if (activeObjects) {
      activeObjects.forEach((obj) => {
        this.canvas.discardActiveObject(obj);
        this.canvas.remove(obj);
        this.drawHistory.push(obj);
      });
    }
    this.canvasClick();
    this.updateSaveButton();
  }

  switchMode(mode: string) {
    if (this.currentMode) {
      if (this.currentMode === mode) {
        return;
      }
      this.canvas.off();
    }

    this.canvas.on('mouse:down:before', (o) => {
      if (o.e.touches && o.e.touches.length > 1) {
        // more than 1 finger
        if (mode === DrawMode.FREE_DRAW) {
          this.canvas.isDrawingMode = false;
        }
      }
    });

    this.canvas.on('object:modified', (e) => {
      if ([DrawMode.ERASE.valueOf(), DrawMode.ARROW.valueOf(), DrawMode.DUAL_ARROW.valueOf(), DrawMode.LINE.valueOf()].includes(this.currentMode) || this.undoRedo === true) {
        return;
      }
      this.updateCanvasState('modified');
    });

    this.canvas.on('mouse:up', (o) => {
      this.viewportMeta.objectsSelected = !!this.canvas.getActiveObjects().length;
    });

    this.canvas.on('object:added', (e) => {
      if (
        [DrawMode.ERASE.valueOf(), DrawMode.ARROW.valueOf(), DrawMode.DUAL_ARROW.valueOf(), DrawMode.LINE.valueOf(), DrawMode.TEXT.valueOf()].includes(this.currentMode) ||
        e.target.manuallyAdded === true
      ) {
        return;
      }
    });
    this.undoRedo = false;

    this.canvas.freeDrawingBrush.color = this.viewportMeta.modesVars.color;
    this.canvas.freeDrawingBrush.width = this.viewportMeta.modesVars.strokewidth;
    this.canvas.freeDrawingBrush.hasControls = true;
    this.canvas.freeDrawingBrush.hasBorders = true;

    if (mode === DrawMode.MOUSE) {
      this.canvas.isDrawingMode = false;
      this.canvas.selection = true;
      this.getCanvasObjectsButMarker().forEach((obj) => (obj.selectable = true));
    } else {
      this.canvas.selection = false;
      this.getCanvasObjectsButMarker().forEach((obj) => (obj.selectable = false));
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
    }

    switch (mode) {
      case DrawMode.MOUSE:
        this.canvas.defaultCursor = 'default';
        this.canvas.hoverCursor = 'move';
        break;
      case DrawMode.MOVE:
        this.canvas.defaultCursor = 'grab';
        this.canvas.hoverCursor = 'grab';
        break;
      case DrawMode.TEXT:
        this.canvas.defaultCursor = 'text';
        this.canvas.hoverCursor = 'text';
        break;
      default:
        this.canvas.defaultCursor = 'default';
        this.canvas.hoverCursor = 'default';
    }
    this.startMode(mode);
  }

  private getCanvasObjectsButMarker(): fabric.Object[] {
    return this.canvas.getObjects().filter((canvasObject) => !this.isMarkerObject(canvasObject));
  }

  updateCanvasState(objectAction = null) {
    if (!this.canvas) {
      return;
    }
    const _config = this.viewportMeta.stateConfig;

    if (_config.undoStatus === false && _config.redoStatus === false) {
      const objects = [];
      for (const obj of this.canvas._objects.slice()) {
        const newObj = _.clone(obj);
        const addProperty = (name, value) => {
          if (!newObj.hasOwnProperty(name)) {
            newObj[name] = value;
          }
        };
        addProperty('zoomX', 1);
        addProperty('zoomY', 1);
        addProperty('scaleX', 1);
        addProperty('scaleY', 1);
        addProperty('skewX', 0);
        addProperty('skewY', 0);
        addProperty('flipX', 0);
        addProperty('flipY', 0);
        addProperty('angle', 0);
        addProperty('x1', 0);
        addProperty('x2', 0);
        addProperty('matrixCache', null);
        objects.push(newObj);
      }
      if (_config.currentStateIndex < _config.canvasState.length - 1) {
        const indexToBeInserted = _config.currentStateIndex + 1;
        _config.canvasState[indexToBeInserted] = objects; // canvasAsJson
        const numberOfElementsToRetain = indexToBeInserted + 1;
        _config.canvasState = _config.canvasState.splice(0, numberOfElementsToRetain);
      } else {
        _config.canvasState.push(objects); // canvasAsJson)
      }
      _config.currentStateIndex = _config.canvasState.length - 1;
    }
    this.updateSaveButton();
  }

  startMode(modeId) {
    this.currentMode = modeId;
    const mode = this.modeMethods[modeId];
    if (!mode.initObjects) {
      console.error('initObjects required');
      return;
    }
    if (mode.updateObjects) {
      const mousedown = (ev) => {
        let tempMouseupFired = false;
        let objects;
        const tempMouseup = () => {
          tempMouseupFired = true;
        };
        this.canvas.on({'mouse:up': tempMouseup, 'touch:end': tempMouseup});
        const mouseup = (mouseUpEvent) => {
          if (objects) {
            const keep = mode.updateObjects(objects, mouseUpEvent);

            if (!this.viewportMeta.movePositionMode && keep) {
              if (mode.finalize) {
                mode.finalize(mouseUpEvent);
              } else {
                this.updateCanvasState();
              }
            }
            this.canvas.renderAll();
          }
          this.canvas.off({'mouse:up': mouseup, 'touch:end': mouseup});
          this.canvas.off({'mouse:move': mousemove, 'touch:move': mousemove});
          objects = null;
        };

        const mousemove = (mouseMoveEvent) => {
          if (!this.viewportMeta.movePositionMode && objects) {
            mode.updateObjects(objects, mouseMoveEvent);
            this.canvas.renderAll();
          }
        };

        // mousedown
        if (!this.viewportMeta.movePositionMode && !objects) {
          setTimeout(async () => {
            this.canvas.off({'mouse:up': tempMouseup, 'touch:end': tempMouseup});
            if (!tempMouseupFired && !this.viewportMeta.movePositionMode && !objects) {
              objects = mode.initObjects(ev);

              if (objects) {
                for (const obj of objects) {
                  this.canvas.add(obj);
                }
                this.canvas.on({'mouse:up': mouseup, 'touch:end': mouseup});
                this.canvas.on({'mouse:move': mousemove, 'touch:move': mousemove});
              }
            }
          }, 50);
        }
      };
      // handle mouse:out?? and touch:cancel
      this.canvas.on({'mouse:down': mousedown, 'touch:start': mousedown});
    } else {
      const mousedown = (ev) => {
        if (!this.viewportMeta.movePositionMode) {
          setTimeout(() => {
            if (!this.viewportMeta.movePositionMode) {
              const objects = mode.initObjects(ev);
              if (objects) {
                this.canvas.remove(this.group);
                for (const obj of objects) {
                  // group.addWithUpdate(obj)
                  this.canvas.add(obj);
                }
                this.updateCanvasState();
              }
            }
          }, 50);
        }
      };
      this.canvas.on({'mouse:down': mousedown, 'touch:start': mousedown});
    }
  }

  async mounted() {
    let img;
    if (!this.emptyTemplate && this.attachmentUrl) {
      const loading = await this.loadingController.create({
        message: this.translatePipe.transform('modal.image_loading'),
      });
      await loading.present();
      this.loggingService.debug(LOG_SOURCE, `mounted - calling loadImageEnsureWidthHeight - attachmentWidth=${this.attachmentWidth}, attachmentHeight=${this.attachmentHeight}`);
      const {image, imageScaled} = await loadImageEnsureWidthHeight(this.attachmentUrl, this.attachmentWidth, this.attachmentHeight);
      if (imageScaled) {
        this.loggingService.warn(LOG_SOURCE, `mounted. Device was rendering image smaller scaled width/height (${image.width}/${image.height}).`);
      } else {
        this.loggingService.debug(LOG_SOURCE, `mounted. Image was rendered in full width/height (${image.width}/${image.height}) imageScaled=${imageScaled}`);
      }
      this.loggingService.debug(LOG_SOURCE, `mounted - calling loadImageEnsureWidthHeight - attachmentWidth=${this.attachmentWidth}, attachmentHeight=${this.attachmentHeight}`);
      img = image;
      this.imageScaled = imageScaled;
      this.viewportMeta.backgroundImg = img;
      await loading.dismiss();
    }

    const windowWidth = this.canvasSketchContainer.nativeElement.clientWidth;
    const windowHeight = this.canvasSketchContainer.nativeElement.clientHeight;
    const vw = windowWidth;
    const vh = windowHeight;

    let iw, ih;
    if (img) {
      iw = img.width;
      ih = img.height;
      this.currentBackgroundImage = img;
    }

    const canvasDimension: CanvasDimension = calculateOptimalDimension(iw, ih, vw, vh);
    this.viewportMeta.viewportWidth = vw;
    this.viewportMeta.viewportHeight = vh;
    this.viewportMeta.width = canvasDimension.width;
    this.viewportMeta.height = canvasDimension.height;
    this.viewportMeta.factor = canvasDimension.zoomFactor;

    this.canvas = new fabric.Canvas('canvas', {
      width: this.viewportMeta.width,
      height: this.viewportMeta.height,
    });
    this.canvas.freeDrawingBrush.decimate = PIXEL_LIMIT; // Avoid too many points drawn

    this.group = new fabric.Group();
    this.canvas.skipOffscreen = false;
    this.canvas.selection = false;
    // use zoom to make sure that we always display the objects in the right position, regardless of instance.width
    this.canvas.setZoom(this.viewportMeta.factor);
    fabric.Object.prototype.borderColor = 'blue';
    fabric.Object.prototype.padding = 10;
    fabric.Object.prototype.selectable = false;
    // this.canvas.observe('object:scaling', (e) => {
    //   e.target.resizeToScale();
    // });
    const hammerElement = document.getElementById('hammer');

    this.hammer = new Hammer(hammerElement, {domEvents: true, inputClass: null});
    this.hammer.get('pinch').set({enable: true, threshold: 0, pointƒers: 2});
    this.hammer.get('pan').set({threshold: 10, enable: true});

    if (this.markings) {
      let data = typeof this.markings === 'string' ? JSON.parse(this.markings) : this.markings;
      if (typeof data === 'string') {
        // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
        data = JSON.parse(data);
      }
      this.viewportMeta.version = data.version + 1;
      this.viewportMeta.user = data.user;
      data.fabricData.backgroundImage = null;
      this.canvas.loadFromJSON(data.fabricData, this.canvas.renderAll.bind(this.canvas));
    }
    if (img) {
      this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));
    } else {
      this.canvas.setBackgroundColor('white');
    }

    if (this.planMarkers?.length) {
      await this.addPlanMarkers();
    }

    this.canvas.renderAll();

    this.modeMethods = this.initializeModes(this);

    this.viewportMeta.modesVars.strokewidth = this.size_to_factor(this.viewportMeta.modesVars.swmin + this.viewportMeta.modesVars.swmax) / 4;
    if (this.signature) {
      this.viewportMeta.modesVars.color = this.SIGNATURE_COLOR;
      this.viewportMeta.modesVars.strokewidth = this.SIGNATURE_STROKE_WIDTH;
    }

    const penOnly = (e) => {
      this.viewportMeta.movePositionMode = false;
      if (!this.viewportMeta.penOnly) {
        return;
      }
      const touch = e.changedTouches.item(0);
      this.viewportMeta.radius = {x: touch.radiusX, y: touch.radiusY};
      if (touch.radiusX > 1 || touch.radiusY > 1) {
        e.preventDefault();
        e.stopPropagation();
        this.viewportMeta.movePositionMode = true;
        return false;
      }
    };
    hammerElement.addEventListener('touchstart', (event) => {
      if (!(event.touches.length > 1)) {
        penOnly(event);
      }
    });
    this.initializeZoom();
    this.switchMode(DrawMode.FREE_DRAW);
    const clonedGroup = _.cloneDeep(this.group);
    this.viewportMeta.historyPointer = 0;
    this.history = [clonedGroup];
    this.setLastJSON();
    this.handleResize();

    this.viewportMeta.state = this.STATES.SUCCESS;
    setTimeout(() => {
      this.canvas.renderAll();
      this.updateCanvasState();
    });
  }

  initializeModes(instance) {
    return {
      line: {
        initObjects: (ev) => {
          const coords = instance.getCorrCoords(instance, ev.e);
          const lineStart = [coords.x, coords.y];
          const line = new fabric.Line(lineStart.concat(lineStart), {
            stroke: instance.viewportMeta.modesVars.color,
            strokeWidth: instance.viewportMeta.modesVars.strokewidth,
          });

          instance.viewportMeta.lastDrawnLine = line;
          return [line];
        },
        updateObjects: (objects, ev) => {
          const coords = instance.getCorrCoords(instance, ev.e);
          const [line] = objects;
          if (coords) {
            line.set('x2', coords.x);
            line.set('y2', coords.y);
          }
          return !(line.x1 === line.x2 && line.y1 === line.y2);
        },

        finalize: () => {
          instance.canvas.remove(this.viewportMeta.lastDrawnLine);
          instance.viewportMeta.lastDrawnLine.shapeType = 'line';
          instance.canvas.add(instance.viewportMeta.lastDrawnLine);
          instance.canvas.renderAll();
          instance.updateCanvasState();
        },
      },

      arrow: {
        initObjects: (ev) => {
          const coords = instance.getCorrCoords(instance, ev.e);
          const lineStart = [coords.x, coords.y];
          const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          const line = new fabric.Line(lineStart.concat(lineStart), {
            stroke: instance.viewportMeta.modesVars.color,
            strokeWidth: instance.viewportMeta.modesVars.strokewidth,
          });
          const triangle = new fabric.Triangle({
            fill: instance.viewportMeta.modesVars.color,
            height: instance.viewportMeta.modesVars.strokewidth * 2,
            width: instance.viewportMeta.modesVars.strokewidth * 2,
            originX: 'center',
            originY: 'center',
            selectable: false,
            top: line.y2 + instance.viewportMeta.modesVars.strokewidth / 2,
            left: line.x2 + instance.viewportMeta.modesVars.strokewidth / 2,
          });

          // Create unique id for line and triangle
          //  line.id = random
          // triangle.id = random
          return [line, triangle];
        },
        updateObjects: (objects, ev) => {
          const coords = instance.getCorrCoords(instance, ev.e);
          const [line, triangle] = objects;
          if (coords) {
            line.set('x2', coords.x);
            line.set('y2', coords.y);

            const dx = line.x2 - line.x1;
            const dy = line.y2 - line.y1;
            const angle = (Math.atan2(dy, dx) * 180) / Math.PI + 90;

            triangle.set('angle', angle);
            triangle.set('top', line.y2 + instance.viewportMeta.modesVars.strokewidth / 2);
            triangle.set('left', line.x2 + instance.viewportMeta.modesVars.strokewidth / 2);
          }
          instance.viewportMeta.lastArrowObjects = [line, triangle];
          return !(line.x1 === line.x2 && line.y1 === line.y2);
        },

        finalize: () => {
          const group: any = new fabric.Group(instance.viewportMeta.lastArrowObjects);
          group.shapeType = 'arrow';
          instance.canvas.add(group);
          instance.canvas.remove(instance.viewportMeta.lastArrowObjects[0]);
          instance.canvas.remove(instance.viewportMeta.lastArrowObjects[1]);
          instance.canvas.renderAll();
          instance.updateCanvasState();
        },
      },

      dualArrow: {
        initObjects: (ev) => {
          const coords = instance.getCorrCoords(instance, ev.e);
          const lineStart = [coords.x, coords.y];
          // const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
          const line = new fabric.Line(lineStart.concat(lineStart), {
            stroke: instance.viewportMeta.modesVars.color,
            strokeWidth: instance.viewportMeta.modesVars.strokewidth,
          });
          const triangleStart = new fabric.Triangle({
            fill: instance.viewportMeta.modesVars.color,
            height: instance.viewportMeta.modesVars.strokewidth * 2,
            width: instance.viewportMeta.modesVars.strokewidth * 2,
            originX: 'center',
            originY: 'center',
            selectable: false,
            top: line.y1 + instance.viewportMeta.modesVars.strokewidth / 2,
            left: line.x1 + instance.viewportMeta.modesVars.strokewidth / 2,
            angle: 90,
          });
          const triangleEnd = new fabric.Triangle({
            fill: instance.viewportMeta.modesVars.color,
            height: instance.viewportMeta.modesVars.strokewidth * 2,
            width: instance.viewportMeta.modesVars.strokewidth * 2,
            originX: 'center',
            originY: 'center',
            selectable: false,
            top: line.y2 + instance.viewportMeta.modesVars.strokewidth / 2,
            left: line.x2 + instance.viewportMeta.modesVars.strokewidth / 2,
          });

          // Create unique id for line and triangle
          // line.id = random
          // triangleStart.id = random
          // triangleEnd.id = random
          return [line, triangleStart, triangleEnd];
        },

        updateObjects: (objects, ev) => {
          const coords = instance.getCorrCoords(instance, ev.e);
          const [line, triangleStart, triangleEnd] = objects;
          if (coords) {
            line.set('x2', coords.x);
            line.set('y2', coords.y);
            const dx = line.x2 - line.x1;
            const dy = line.y2 - line.y1;
            const angle = (Math.atan2(dy, dx) * 180) / Math.PI + 90;
            const oppositeAngle = (angle + 180) % 360;

            triangleStart.set('angle', angle);
            triangleStart.set('top', line.y2 + instance.viewportMeta.modesVars.strokewidth / 2);
            triangleStart.set('left', line.x2 + instance.viewportMeta.modesVars.strokewidth / 2);

            triangleEnd.set('angle', oppositeAngle);
            triangleEnd.set('top', line.y1 + instance.viewportMeta.modesVars.strokewidth / 2);
            triangleEnd.set('left', line.x1 + instance.viewportMeta.modesVars.strokewidth / 2);
          }
          this.viewportMeta.lastArrowObjects = [line, triangleStart, triangleEnd];
          return !(line.x1 === line.x2 && line.y1 === line.y2);
        },

        finalize: () => {
          const group: any = new fabric.Group(this.viewportMeta.lastArrowObjects);
          group.shapeType = 'dualArrow';
          instance.canvas.add(group);
          instance.canvas.remove(this.viewportMeta.lastArrowObjects[0]);
          instance.canvas.remove(this.viewportMeta.lastArrowObjects[1]);
          instance.canvas.remove(this.viewportMeta.lastArrowObjects[2]);
          instance.canvas.renderAll();
          instance.updateCanvasState();
        },
      },

      erase: {
        initObjects: (ev) => {
          instance.canvas.freeDrawingBrush.color = 'white';
          instance.canvas.freeDrawingBrush.width = instance.viewportMeta.modesVars.strokewidth;
          const pointer = instance.canvas.getPointer(ev.e);
          instance.canvas.freeDrawingBrush.onMouseDown(pointer, {e: ev.e, pointer});
          return [];
        },
        updateObjects: (objects, ev) => {
          const pointer = instance.canvas.getPointer(ev.e);
          instance.canvas.freeDrawingBrush.onMouseMove(pointer, {e: ev.e, pointer});
          return true;
        },
        finalize: (ev) => {
          // this is partially taken from fabric source code
          const brush = instance.canvas.freeDrawingBrush;
          if (brush.decimate) {
            brush._points = brush.decimatePoints(brush._points, brush.decimate);
          }
          // @ts-ignore
          brush.oldEnd = undefined;
          const ctx = instance.canvas.contextTop;
          ctx.closePath();

          const pathData = brush.convertPointsToSVGPath(brush._points).join('');
          if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
            instance.canvas.renderAll();
            return;
          }

          const path = brush.createPath(pathData);
          path.globalCompositeOperation = 'destination-out';
          path.setCoords();
          instance.canvas.add(path);
          const intersectedObjects = [];
          instance.canvas.forEachObject((obj) => {
            // except path
            if (obj.globalCompositeOperation === 'destination-out') {
              return;
            }
            path.setCoords();
            if (path.intersectsWithObject(obj)) {
              intersectedObjects.push(obj);
            }
          });

          intersectedObjects.map((obj) => {
            path.clone((clonedPath) => {
              const group: IGroupOptions = new fabric.Group([obj, clonedPath], {});
              group.globalCompositeOperation = 'source-over';
              group.type = 'group';
              instance.canvas.add(group).renderAll();
              instance.canvas.remove(obj);
            });
          });
          instance.canvas.remove(path);
          instance.canvas.clearContext(instance.canvas.contextTop);
          brush._resetShadow();
          instance.canvas.renderAll();
          instance.updateCanvasState();
        },
      },

      mouse: {
        initObjects(ev) {},
      },

      move: {
        initObjects(ev) {},
      },

      freeDraw: {
        initObjects: (ev) => {
          instance.canvas.freeDrawingBrush.color = instance.viewportMeta.modesVars.color;
          instance.canvas.freeDrawingBrush.width = instance.viewportMeta.modesVars.strokewidth;
          const pointer = instance.canvas.getPointer(ev.e);
          instance.canvas.freeDrawingBrush.onMouseDown(pointer, {e: ev.e, pointer});
          return [];
        },
        updateObjects: (objects, ev) => {
          const pointer = instance.canvas.getPointer(ev.e);
          instance.canvas.freeDrawingBrush.onMouseMove(pointer, {e: ev.e, pointer});
          return true;
        },
        finalize: (ev) => {
          const pointer = instance.canvas.getPointer(ev.e);
          instance.canvas.freeDrawingBrush.onMouseUp({e: ev.e, pointer});
          instance.updateCanvasState();
        },
      },

      text: {
        async initObjects(ev) {
          const coords = instance.getCorrCoords(instance, ev.e);
          instance.viewportMeta.textToAdd = null;
          const inputElement = document.getElementById('inputTextDiv');

          inputElement.style.display = 'block';
          inputElement.getElementsByTagName('input')[0].focus();
          const promiseResult = await instance.waitUserInput();

          inputElement.style.display = 'none';
          if (!promiseResult) {
            return;
          }
          const text = new fabric.Text(instance.viewportMeta.textToAdd, {
            left: coords.x,
            top: coords.y,
            fontSize: instance.viewportMeta.modesVars.strokewidth * 2 + 20,
            fill: instance.viewportMeta.modesVars.color,
          });
          text.selectable = true;
          instance.canvas.add(text).setActiveObject(text);
          instance.canvas.renderAll();
          instance.updateCanvasState();
          instance.switchMode(DrawMode.MOUSE);
          return [text];
        },
        updateObjects: (objects, ev) => {
          return true;
        },
        finalize: (ev) => {
          return;
        },
      },
    };
  }

  getCorrCoords(instance, event) {
    const coords = this.getCoords(event);
    if (coords) {
      return {
        x: (instance.clamp(coords.x, 0, instance.viewportMeta.width) + instance.viewportMeta.scaleOffset.x) / instance.viewportMeta.factor / instance.viewportMeta.scale,
        y: (instance.clamp(coords.y, 0, instance.viewportMeta.height) + instance.viewportMeta.scaleOffset.y) / instance.viewportMeta.factor / instance.viewportMeta.scale,
      };
    }
  }

  getCoords(event) {
    const pos = document.getElementById('canvas').getBoundingClientRect();
    const scrollLeft = window.scrollX ? window.scrollX : document.body.scrollLeft;
    const scrollTop = window.scrollY ? window.scrollY : document.body.scrollTop;
    let x, y;
    if (event.clientX) {
      x = event.clientX;
      y = event.clientY;
    } else if (event.touches && event.touches.length > 0) {
      // touch
      x = event.touches[0].clientX;
      y = event.touches[0].clientY;
    } else {
      return;
    }
    return {x: x - pos.left + scrollLeft, y: y - pos.top + scrollTop};
  }

  clamp(num, min, max) {
    return Math.max(min, Math.min(num, max));
  }

  setUserZoom = (bool) => {
    document.querySelectorAll('head meta[name=viewport]').forEach((obj) => {
      if (obj.parentNode) {
        obj.parentNode.removeChild(obj);
      }
    });
    const elMeta = document.createElement('meta');
    elMeta.setAttribute('name', 'viewport');
    if (bool) {
      elMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, user-scalable=yes');
    } else {
      elMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
      document.head.appendChild(elMeta);
    }
  };

  size_to_factor(x) {
    return x / this.viewportMeta.factor;
  }

  handleResize() {
    this.viewportMeta.isSmallScreen = this.getWidth() < this.SMALL_UI_WIDTH;
  }

  getWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  setLastJSON() {
    if (!this.canvas) {
      return;
    }
    this.viewportMeta.lastJSON = JSON.stringify(this.canvas.toDatalessJSON(['id']));
  }

  hasCanvasChanged() {
    if (!this.canvas) {
      return;
    }
    const currentJSON = JSON.stringify(this.canvas.toDatalessJSON(['id']));
    return !(currentJSON === this.viewportMeta.lastJSON);
  }

  updateSaveButton() {
    if (!this.saveButton) {
      return;
    }
    if (this.hasCanvasChanged()) {
      this.saveButton.disabled = false;
    } else {
      this.saveButton.disabled = true;
    }
  }

  startSaveButtonCooldown() {
    // const cooldownTime = 1000 // ms
    // this.saveButtonDisabled = true
    // setTimeout(() => this.saveButtonDisabled = false, cooldownTime)
  }

  setGroupObjectsColor(group, color) {
    group.getObjects().map((item) => {
      item.set('color', color);
      item.set('stroke', color);
      if (item.type !== 'path') {
        item.set('fill', color);
      }
      if (item.hasOwnProperty('group')) {
        this.setGroupObjectsColor(item, color);
      }
    });
  }

  canvasClick() {
    this.viewportMeta.objectsSelected = !!this.canvas.getActiveObjects().length;
  }

  initializeZoom() {
    if (this.signature) {
      return;
    }
    const instance = this;
    disableEventHandlers(instance.hammer.element);
    let lastScale = instance.viewportMeta.scale;
    let x = 0,
      y = 0;
    let pinchCenter = null;
    const MIN_SCALE = 1,
      MAX_SCALE = 24;
    let czx, czy;

    function disableEventHandlers(elm) {
      const events = ['onclick', 'onmousedown', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'ondblclick', 'onfocus', 'onblur'];
      events.forEach((event) => {
        elm[event] = () => {
          return false;
        };
      });
    }

    function rawCenter(e) {
      const centerX = _.get(e, 'center.x', 0),
        centerY = _.get(e, 'center.y', 0);
      const pos = document.getElementById('canvas').getBoundingClientRect();
      return {x: centerX - pos.left, y: centerY - pos.top};
    }

    function zoomAroundAndPan(scaleBy, rawZoomX, rawZoomY, e) {
      const newRawZoomX = rawZoomX - e.deltaX * instance.viewportMeta.panSpeed;
      const newRawZoomY = rawZoomY - e.deltaY * instance.viewportMeta.panSpeed;
      zoomAround(scaleBy, newRawZoomX, newRawZoomY);
    }

    function zoomAround(scaleBy, rawZoomX, rawZoomY) {
      instance.viewportMeta.scale = restrictScale(lastScale * scaleBy);
      setZoom(instance.viewportMeta.scale);
      const newWidth = instance.clamp(instance.viewportMeta.width * instance.viewportMeta.scale, instance.viewportMeta.width, instance.viewportMeta.viewportWidth);
      const newHeight = instance.clamp(instance.viewportMeta.height * instance.viewportMeta.scale, instance.viewportMeta.height, instance.viewportMeta.viewportHeight);
      instance.canvas.setWidth(newWidth);
      instance.canvas.setHeight(newHeight);
      if (newWidth < instance.viewportMeta.viewportWidth && newHeight === instance.viewportMeta.viewportHeight) {
        translate(0, czy * instance.viewportMeta.scale - rawZoomY);
      } else if (newHeight < instance.viewportMeta.viewportHeight && newWidth === instance.viewportMeta.viewportWidth) {
        translate(czx * instance.viewportMeta.scale - rawZoomX, 0);
      } else if (newWidth < instance.viewportMeta.viewportWidth && newHeight < instance.viewportMeta.viewportHeight) {
        translate(0, 0);
      } else {
        translate(czx * instance.viewportMeta.scale - rawZoomX, czy * instance.viewportMeta.scale - rawZoomY);
      }
      if (instance.currentBackgroundImage) {
        const {dx, dy} = enclose(instance.canvas, instance.currentBackgroundImage);
        x -= dx;
        y -= dy;
      }
      instance.canvas.requestRenderAll();
    }

    const updateLastScale = () => {
      lastScale = instance.viewportMeta.scale;
    };
    const updateLastPos = () => {
      instance.viewportMeta.scaleOffset = {x, y};
    };
    const restrictScale = (scale) => {
      return instance.clamp(scale, MIN_SCALE, MAX_SCALE);
    };

    function translate(tx, ty) {
      x = instance.clamp(tx, 0, instance.viewportMeta.width * instance.viewportMeta.scale - instance.viewportMeta.width);
      y = instance.clamp(ty, 0, instance.viewportMeta.height * instance.viewportMeta.scale - instance.viewportMeta.height);
      absolutePan(x, y);
    }

    function setZoom(zoom) {
      instance.canvas.setZoom(instance.viewportMeta.factor * zoom);
    }

    function absolutePan(xPan, yPan) {
      instance.canvas.absolutePan(new fabric.Point(xPan, yPan));
    }

    function handleMouseWheel(e) {
      const c = instance.getCoords(e);
      czx = (c.x + x) / instance.viewportMeta.scale;
      czy = (c.y + y) / instance.viewportMeta.scale;

      zoomAround(e.deltaY < 0 ? ZOOMSPEED : 1 / ZOOMSPEED, c.x, c.y);
      updateLastScale();
      updateLastPos();
    }

    // takes care of pinch and pan
    instance.hammer.on('pinch', (e) => {
      // console.log("movePositionMode true")
      instance.viewportMeta.movePositionMode = true;
      if (instance.currentMode === 'freedraw') {
        // const lastObject = canvas.getObjects().pop()
        // canvas.renderAll()
        instance.canvas.isDrawingMode = false;
      }
      if (pinchCenter === null) {
        pinchCenter = rawCenter(e);
        czx = (pinchCenter.x + x) / instance.viewportMeta.scale;
        czy = (pinchCenter.y + y) / instance.viewportMeta.scale;
      }

      zoomAroundAndPan(e.scale, pinchCenter.x, pinchCenter.y, e);
    });

    instance.hammer.on('pinchend', () => {
      // console.log("movePositionMode false")
      instance.viewportMeta.movePositionMode = false;
      if (instance.currentMode === 'freedraw') {
        instance.canvas.isDrawingMode = true;
      }
      updateLastScale();
      updateLastPos();
      pinchCenter = null;
    });

    /*  hammer.on('doubletap', function(e){
      //console.log("doubleTap")
      instance.movePositionMode=true


      let c = rawCenter(e)

      czx = (c.x + x)/instance.scale
      czy = (c.y + y)/instance.scale
      zoomAround(4, c.x, c.y)
      updateLastScale()
      updateLastPos()

    })*/

    instance.hammer.on('pan', (event) => {
      if (instance.currentMode === DrawMode.MOVE) {
        instance.canvas.defaultCursor = 'grabbing';
        translate(instance.viewportMeta.scaleOffset.x - event.deltaX, instance.viewportMeta.scaleOffset.y - event.deltaY);
        if (instance.currentBackgroundImage) {
          const {dx, dy} = enclose(instance.canvas, instance.currentBackgroundImage);
          x -= dx;
          y -= dy;
        }
      }
    });

    instance.hammer.on('panend', () => {
      if (instance.currentMode === DrawMode.MOVE) {
        instance.canvas.defaultCursor = 'grab';
        updateLastPos();
      }
    });
    document.addEventListener('keyup', this.handleKeyPress);

    const hammerElement = document.getElementById('hammer');
    hammerElement.addEventListener('wheel', handleMouseWheel);
  }

  ngOnDestroy(): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    if (this.revokeObjectUrlOnDestroy && this.attachmentUrl) {
      URL.revokeObjectURL(this.attachmentUrl);
    }
    this.destroy$.next();
    this.destroy$.complete();
    document.removeEventListener('keyup', this.handleKeyPress);
    this.clearCanvas();
  }

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    this.attachmentUrl = this.navParams.data.attachmentUrl;
    this.markings = this.navParams.data.markings;
    this.planMarkers = this.navParams.data.planMarkers;
    this.onMarkingsChanged = this.navParams.data.onMarkingsChanged;
    this.loadedSvgMarkers = await loadMarkerImages();
    const ionModalElement = this.modal;
    if (this.signature) {
      ionModalElement.cssClass += ' signature';
    }
    if (this.isLargeScreen()) {
      this.fabActivated = true;
      fromEvent(this.elementRef.nativeElement.querySelectorAll('ion-fab-list'), 'click', {capture: true})
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: Event) => {
          event.stopPropagation();
          const target = event.target;
          let button;
          if (target instanceof SVGPathElement || target instanceof HTMLElement || target instanceof SVGSVGElement) {
            button = target.closest('ion-fab-button');
          } else {
            return;
          }
          if (!button) {
            return;
          }
          const action = button.dataset.clickAction;
          const argument = button.dataset.clickArgument;

          if (action === 'switchMode' && argument) {
            this.switchMode(argument);
          } else if (action === 'changeColor' && argument) {
            this.changeColor(argument);
          }
        });
    }
    await this.mounted();
  }

  handleKeyPress = (event) => {
    if (event.type !== 'keyup') {
      return;
    }
    switch (event.code) {
      case Shortcuts.LINE:
        this.switchMode(DrawMode.LINE);
        break;
      case Shortcuts.ARROW:
        if (event.shiftKey) {
          this.switchMode(DrawMode.DUAL_ARROW);
        } else {
          this.switchMode(DrawMode.ARROW);
        }
        break;
      case Shortcuts.TEXT:
        this.switchMode(DrawMode.TEXT);
        break;
      case Shortcuts.ERASE:
        if (this.canErase) {
          this.switchMode(DrawMode.ERASE);
        }
        break;
      case Shortcuts.FREE_DRAW:
        this.switchMode(DrawMode.FREE_DRAW);
        break;
      case Shortcuts.MOUSE:
        this.switchMode(DrawMode.MOUSE);
        break;
      case Shortcuts.MOVE:
        this.switchMode(DrawMode.MOVE);
        break;
      case Shortcuts.DELETE:
        this.deleteSelectedObjects();
        break;
    }
  };

  private async addPlanMarkers() {
    const markers = await addCanvasMarkers(this.canvas, this.loadedSvgMarkers, this.planMarkers, 1);
    // make sure, markers are not selectable.
    markers.forEach((marker) => {
      marker.evented = false;
      marker.selectable = false;
    });
  }
}
