import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PdfPlanFolderDataService} from 'src/app/services/data/pdf-plan-folder-data.service';
import {PdfPlanDataService} from 'src/app/services/data/pdf-plan-data.service';
import {of, Subject, Subscription} from 'rxjs';
import {IdType, PdfPlan, PdfPlanFolder, PdfPlanVersion, ProtocolEntry} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {ModalController} from '@ionic/angular';
import {PdfPlanMarkerComponent} from 'src/app/components/common/pdf-plan-marker/pdf-plan-marker.component';
import {SystemEventService} from '../../../services/event/system-event.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {PdfPlanVersionDataService} from '../../../services/data/pdf-plan-version-data.service';
import {SelectableRecentlyUsedItems} from 'src/app/model/selectable';
import {RecentlyUsedSelectableService} from 'src/app/services/common/recently-used-selectable.service';
import {LoggingService} from 'src/app/services/common/logging.service';

interface FolderFileTree {
  folder: PdfPlanFolder;
  files?: PdfPlanVersion[];
}

const LOG_SOURCE = 'PdfPlanTreeViewComponent';
const RECENTLY_USED_KEY = 'pdfPlans';

@Component({
  selector: 'app-pdf-plan-tree-view',
  templateUrl: './pdf-plan-tree-view.component.html',
  styleUrls: ['./pdf-plan-tree-view.component.scss'],
})
export class PdfPlanTreeViewComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;

  @Input() protocolEntry: ProtocolEntry;
  @Input() acrossProjects = false;

  public folderFilesTree: FolderFileTree[] = [];
  public filteredFolderFilesTree: FolderFileTree[] = [];
  public recentlyUsedPlanVersions: PdfPlanVersion[] = [];
  public searchText: string | undefined;

  private pdfFolderFileTreeSubscription: Subscription | undefined;
  private pdfPlanVersions: PdfPlanVersion[] = [];
  private recentlyUsedItems: SelectableRecentlyUsedItems | undefined;

  selectedVersion: PdfPlanVersion;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private pdfPlanFolderDataService: PdfPlanFolderDataService,
    private pdfPlanDataService: PdfPlanDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private protocolService: ProtocolService,
    private projectDataService: ProjectDataService,
    private systemEventService: SystemEventService,
    private recentlyUsedSelectableService: RecentlyUsedSelectableService,
    private loggingService: LoggingService
  ) {}

  async ngOnInit() {
    this.modal.canDismiss = true;
    this.recentlyUsedSelectableService
      .getRecentlyUsedItemsOfCurrentProject$(RECENTLY_USED_KEY)
      .pipe(takeUntil(this.destroy$))
      .subscribe((recentlyUsedItems) => {
        this.recentlyUsedItems = recentlyUsedItems;
      });
    this.pdfFolderFileTreeSubscription = combineLatestAsync([
      this.protocolService.getProjectByEntryId(this.protocolEntry?.id).pipe(switchMap((project) => (!project ? this.projectDataService.currentProjectObservable : of(project)))),
      this.acrossProjects ? this.pdfPlanFolderDataService.dataAcrossProjects$ : this.pdfPlanFolderDataService.data,
      this.acrossProjects ? this.pdfPlanDataService.dataAcrossProjects$ : this.pdfPlanDataService.data,
      this.acrossProjects ? this.pdfPlanVersionDataService.dataAcrossProjects$ : this.pdfPlanVersionDataService.data,
    ]).subscribe(([project, folders, pdfPlans, pdfPlanVersions]) => {
      this.folderFilesTree = [];
      const latestPdfPlanVersions = this.pdfPlanVersionDataService.filterLatest(pdfPlanVersions);
      const ascFolders = _.orderBy(
        folders.filter((folder) => folder.projectId === project?.id),
        ['name'],
        ['asc']
      );
      this.displayAllFiles(
        ascFolders,
        pdfPlans.filter((pdfPlan) => ascFolders.some((folder) => pdfPlan.folderId === folder.id)),
        latestPdfPlanVersions
      );
    });
  }

  ngOnDestroy() {
    if (this.pdfFolderFileTreeSubscription) {
      this.pdfFolderFileTreeSubscription.unsubscribe();
      this.pdfFolderFileTreeSubscription = undefined;
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  filterPlans() {
    if (_.isEmpty(this.searchText)) {
      this.filteredFolderFilesTree = this.folderFilesTree;
      return;
    }
    this.filteredFolderFilesTree = this.folderFilesTree.map((tree) => ({
      folder: tree.folder,
      files: tree.files.filter((file) => _.includes(file.name.toLowerCase(), this.searchText.toLowerCase())),
    }));
  }

  displayAllFiles(ascFolders: PdfPlanFolder[], pdfPlans: PdfPlan[], latestPdfPlanVersions: PdfPlanVersion[]) {
    try {
      this.pdfPlanVersions = [];
      ascFolders.forEach((folder: PdfPlanFolder) => {
        const folderfileTree: FolderFileTree = {folder};
        const files = this.getFilesByFolderId(pdfPlans, latestPdfPlanVersions, folder.id);
        this.pdfPlanVersions.push(...files);
        folderfileTree.files = files;
        this.folderFilesTree.push(folderfileTree);
      });
      this.recentlyUsedPlanVersions = !this.recentlyUsedItems
        ? []
        : [
            ...this.pdfPlanVersions
              .filter((planVersion) => this.recentlyUsedItems.some((recentlyUsedItem) => recentlyUsedItem.id === planVersion.id))
              .sort((a, b) => {
                const timestampA = this.recentlyUsedItems.find((item) => item.id === a.id)?.lastUsedTimestamp || 0;
                const timestampB = this.recentlyUsedItems.find((item) => item.id === b.id)?.lastUsedTimestamp || 0;
                return timestampB - timestampA;
              }),
          ];
      this.filteredFolderFilesTree = [...this.folderFilesTree];
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - displayAllFiles', error?.userMessage + '-' + error?.message);
    }
  }

  async openPdfPlanMarker(pdfPlanVersion: PdfPlanVersion) {
    await this.markRecentlyUsed(pdfPlanVersion.id);
    await this.modal.dismiss({pdfPlanVersion});
    if (!_.isEmpty(this.protocolEntry)) {
      const modal = await this.modalController.create({
        component: PdfPlanMarkerComponent,
        cssClass: 'full-screen-modal-xxl',
        componentProps: {
          pdfPlanVersion,
          selectedProtocolEntry: this.protocolEntry,
          acrossProjects: this.acrossProjects,
        },
      });
      await modal.present();
    }
  }

  private async markRecentlyUsed(idOrIds: IdType | Array<IdType>) {
    this.recentlyUsedItems = await this.recentlyUsedSelectableService.markRecentlyUsedForCurrentProject(RECENTLY_USED_KEY, idOrIds);
    this.loggingService.debug(LOG_SOURCE, `markRecentlyUsed - recentlyUsedKey="${RECENTLY_USED_KEY}", recentlyUsedItems=${this.recentlyUsedItems?.length}`);
  }

  removePrefixForIcon(iconText: string): string {
    return iconText.split('|')[1];
  }

  removeSuffixForIcon(iconText: string): string {
    return iconText.split('|')[0];
  }

  private getFilesByFolderId(pdfPlans: PdfPlan[], latestPdfPlanVersions: PdfPlanVersion[], pdfPlanFolderId): PdfPlanVersion[] {
    return _.orderBy(
      _.filter(pdfPlans, (pdfPlan: PdfPlan) => pdfPlan.folderId === pdfPlanFolderId && !!pdfPlan.active),
      ['name'],
      ['asc']
    ).map((pdfPlan) => latestPdfPlanVersions.find((pdfPlanVersion) => pdfPlanVersion.pdfPlanId === pdfPlan.id));
  }

  async dismissModal() {
    await this.modal.dismiss();
  }
}
