import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController, IonRouterOutlet, ModalController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProtocolEntriesComponent} from 'src/app/components/protocol/protocol-entries/protocol-entries.component';
import {ProtocolEntryCreateComponent} from 'src/app/components/protocol/protocol-entry-create/protocol-entry-create.component';
import {ProtocolListComponent} from 'src/app/components/protocol/protocol-list/protocol-list.component';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {ActiveProtocolEntry, ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {EntryCreationModeService} from 'src/app/services/entry/entry-creation-mode.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ProtocolNavigationService} from 'src/app/services/protocol-navigation.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {SelectedProtocolService} from 'src/app/services/protocol/selected-protocol.service';
import {UserflowService} from 'src/app/services/userflow/userflow.service';
import {getBaseProtocolsPagePath} from 'src/app/utils/router-utils';
import {IdType, LicenseType, Protocol, ProtocolEntry} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../utils/observable-to-promise';
import {UnitService} from '../../services/unit/unit.service';

@Component({
  selector: 'app-protocol-entries-page',
  templateUrl: './protocol-entries.page.html',
  styleUrls: ['./protocol-entries.page.scss'],
  providers: [ProtocolEntrySelectionService],
})
export class ProtocolEntriesPage implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('appProtocolEntries') appProtocolEntries: ProtocolEntriesComponent;

  @ViewChild(ProtocolListComponent, {
    static: false,
  })
  protocolList: ProtocolListComponent;

  public isMenuVisible: boolean;

  protected selectedPath = '';
  public protocolId: IdType;
  public protocol: Protocol | null;
  public currentProtocolEntry: Observable<ActiveProtocolEntry | null>;
  private selectedProtocolSubscription: Subscription | undefined;
  private selectedProtocolEntrySubscription: Subscription | undefined;
  private protocolDeletedSubscription?: Subscription;
  public entryCreationModeIcon$ = this.entryCreationModeService.entryCreationModeIcon$;

  constructor(
    private activatedRoute: ActivatedRoute,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolService: ProtocolService,
    private protocolDataService: ProtocolDataService,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private router: Router,
    private projectDataService: ProjectDataService,
    private userflowService: UserflowService,
    private selectedProtocolService: SelectedProtocolService,
    private featureEnabledService: FeatureEnabledService,
    private modalController: ModalController,
    private ionRouterOutlet: IonRouterOutlet,
    private protocolNavigationService: ProtocolNavigationService,
    private platform: Platform,
    private protocolEntrySelectionService: ProtocolEntrySelectionService<ProtocolEntry>,
    private entryCreationModeService: EntryCreationModeService,
    private unitService: UnitService
  ) {}

  ngOnInit() {
    this.selectedProtocolService
      .getCurrentProtocol()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.protocolEntrySelectionService.isSelectMode) {
          this.protocolEntrySelectionService.leaveSelectMode();
        }
      });
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.protocolId = params.protocolId;
      this.protocolDeletedSubscription?.unsubscribe();
      this.protocolDeletedSubscription = this.protocolNavigationService.watchProtocolDeleted(this.protocolId, this.ionRouterOutlet).subscribe(() => this.protocolDeletedFromServer(this.protocol));
      this.initProtocol(this.protocolId);
    });
    this.currentProtocolEntry = this.protocolEntryDataService.currentProtocolEntryObservable;
    this.subscribeActiveProtocolEntry();
  }

  private initProtocol(protocolId: IdType) {
    this.unsubscribeSelectedProtocol();
    this.selectedProtocolSubscription = combineLatest([this.protocolDataService.getByIdAcrossProjects(protocolId), this.projectDataService.currentProjectObservable]).subscribe(
      async ([protocol, currentProject]) => {
        if (_.isEmpty(protocol) && !_.isEmpty(this.protocol) && protocol?.projectId === currentProject.id) {
          await this.protocolDeletedFromServer(this.protocol);
        }
        this.protocol = protocol;
      }
    );
  }

  private async protocolDeletedFromServer(protocol: Protocol) {
    const protocolShortName = await this.protocolService.getProtocolShortName(protocol);
    const message = this.translateService.instant('sync.deletion.protocol', {protocolId: protocolShortName});
    const alert = await this.alertCtrl.create({
      message,
      buttons: [
        {
          text: this.translateService.instant('okay'),
          handler: async () => {
            await this.router.navigate([getBaseProtocolsPagePath(this.router.url)]);
          },
        },
      ],
    });
    alert.present();
  }

  ionViewWillEnter() {
    this.protocolList?.ionViewWillEnter();
  }

  ionViewWillLeave() {
    this.protocolList?.ionViewWillLeave();
  }

  ionViewDidLeave() {
    this.protocolList?.ionViewDidLeave();
  }

  ionViewDidEnter() {
    if (this.platform.is('desktop')) {
      this.appProtocolEntries?.searchbar?.setFocus();
    }
    this.protocolList?.ionViewDidEnter();
  }

  private subscribeActiveProtocolEntry() {
    this.unsubscribeSelectedProtocolEntrySubscription();
    this.selectedProtocolEntrySubscription = this.protocolEntryDataService.getCurrentProtocolEntry().subscribe(async (activeProtocolEntry) => {
      if (activeProtocolEntry && activeProtocolEntry?.protocolListShowActive && this.isMenuVisible) {
        if (activeProtocolEntry?.newProtocolEntry) {
          this.appProtocolEntries.textSearch = '';
        }
      }
    });
  }

  private unsubscribeSelectedProtocolEntrySubscription() {
    if (this.selectedProtocolEntrySubscription) {
      this.selectedProtocolEntrySubscription.unsubscribe();
      this.selectedProtocolEntrySubscription = undefined;
    }
  }

  splitPaneChange(event) {
    this.isMenuVisible = event.detail.visible;
    this.userflowService.updateUserFlow({
      splitScreen: this.isMenuVisible,
    });
  }

  private unsubscribeSelectedProtocol(): void {
    if (this.selectedProtocolSubscription) {
      this.selectedProtocolSubscription.unsubscribe();
      this.selectedProtocolSubscription = undefined;
    }
  }

  async addNewProtocolEntry() {
    if (!(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER]))) {
      return;
    }
    const defaultUnit = await observableToPromise(this.unitService.getUnitDefaultForProtocol$(this.protocolId));
    const modal = await this.modalController.create({
      component: ProtocolEntryCreateComponent,
      keyboardClose: false,
      backdropDismiss: false,
      componentProps: {
        protocolId: this.protocolId,
        expressView: true,
        startWithWorkflow: this.entryCreationModeService.entryCreationMode,
        defaultUnit,
      },
    });
    await modal.present();
  }

  async openButtonActions(event: Event) {
    if (await this.entryCreationModeService.openEntryCreationModeOptions(event)) {
      await this.addNewProtocolEntry();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSelectedProtocol();
    this.unsubscribeSelectedProtocolEntrySubscription();
    this.protocolDeletedSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
