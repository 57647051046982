import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, merge, Observable, of, Subscription, timer} from 'rxjs';
import {IdType, UserDeviceOfflineProject} from 'submodules/baumaster-v2-common';
import {AVAILABLE_PROJECT_EXPIRATION_IN_MS, DOWNLOADED_PROJECT_EXPIRATION_IN_MS, StorageKeyEnum} from '../../shared/constants';
import {StorageMutationOptions, StorageService} from '../storage.service';
import {UserDeviceOfflineProjectDataService} from '../data/user-device-offline-project.data.service';
import {PosthogService} from '../posthog/posthog.service';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {distinctUntilChanged, map, shareReplay, startWith, switchMap, tap, throttleTime} from 'rxjs/operators';
import _ from 'lodash';
import {LoggingService} from '../common/logging.service';
import {AuthenticationService} from '../auth/authentication.service';
import {DevModeService} from '../common/dev-mode.service';
import {ProjectIdWithOffline} from '../../model/project-with-offline';
import {ClientService} from '../client/client.service';

const LOG_SOURCE = 'ProjectAvailabilityExpirationService';
const STORAGE_KEY = StorageKeyEnum.PROJECT_AVAILABILITY_EXPIRATION;
const IGNORE_SUBSEQUENT_VALUES_IN_MS = 60 * 1000;
const MAX_32_BIT_INTEGER = 2_000_000_000;

export type ProjectAvailability = Record<IdType, string>;

@Injectable({
  providedIn: 'root',
})
export class ProjectAvailabilityExpirationService implements OnDestroy {
  private projectAvailabilitySubject = new BehaviorSubject<ProjectAvailability | undefined | null>(undefined);
  public readonly projectAvailability$: Observable<ProjectAvailability> = this.projectAvailabilitySubject
    .asObservable()
    .pipe(map((projectAvailability) => (projectAvailability ? projectAvailability : {})));
  private readonly nextCheckTimestampsSubject = new BehaviorSubject<number[] | undefined>(undefined);
  private readonly nextCheckTimestamp$: Observable<number | undefined> = this.nextCheckTimestampsSubject
    .pipe(
      throttleTime(IGNORE_SUBSEQUENT_VALUES_IN_MS, undefined, {leading: true, trailing: true}),
      switchMap((timestamps) => {
        if (!timestamps?.length) {
          this.loggingService.debug(LOG_SOURCE, `nextCheckTimestamp$.switchMap - !timestamps.length. return undefined`);
          return of(undefined);
        }
        const now = Date.now();
        const futureTimestamps = timestamps.filter((ts) => ts > now);
        if (!futureTimestamps.length) {
          this.loggingService.debug(LOG_SOURCE, `nextCheckTimestamp$.switchMap - !futureTimestamps.length. return 0`);
          return of(0); // there were active timestamps when given to the Subject but they expired by now. Still return a value but do not set a timer.
        }
        const nextFutureTimestamp = futureTimestamps[0];
        if (!_.isNumber(nextFutureTimestamp)) {
          this.loggingService.warn(LOG_SOURCE, `nextCheckTimestamp$.switchMap - nextFutureTimestamp "${nextFutureTimestamp}" is not a number. returning timer with ${IGNORE_SUBSEQUENT_VALUES_IN_MS}.`);
          return timer(IGNORE_SUBSEQUENT_VALUES_IN_MS);
        }
        // rx-js timer function can only work with integer. If it overflows to a negative value, we would have an infnite loop
        const dueTime = Math.min(nextFutureTimestamp - Date.now(), MAX_32_BIT_INTEGER);
        this.loggingService.debug(LOG_SOURCE, `nextCheckTimestamp$.switchMap - setting timer to ${nextFutureTimestamp} which is in ${dueTime} ms`);
        return timer(dueTime);
      }),
      tap(() => {
        const nextNotExpiredTimestamps = this.extractNextNotExpiredTimestamps(this.projectAvailability);
        if (nextNotExpiredTimestamps?.length) {
          this.loggingService.debug(LOG_SOURCE, `nextCheckTimestamp$.tap - setting nextNotExpiredTimestamps (length=${nextNotExpiredTimestamps?.length})`);
          this.nextCheckTimestampsSubject.next(nextNotExpiredTimestamps);
        }
      })
    )
    .pipe(shareReplay(1));
  private nextCheckTimestampButNotWaitTheFirstTime$ = merge(of(0), this.nextCheckTimestamp$);

  public userDeviceOfflineProjectsAcrossClients$: Observable<Array<UserDeviceOfflineProject>> = combineLatestAsync([
    this.clientService.clients$,
    this.userDeviceOfflineProjectDataService.storageInitializedObservable,
    this.userDeviceOfflineProjectDataService.dataByClientId$.pipe(startWith(new Map())),
  ]).pipe(
    map(([clients, userDeviceOfflineProjectInitialized, userDeviceOfflineProjects]) => {
      return _.uniq(_.compact(_.flatten(clients.map((client) => (userDeviceOfflineProjectInitialized.has(client.id) ? (userDeviceOfflineProjects.get(client.id) ?? []) : [])))));
    })
  );

  public readonly notExpiredProjectAvailability$ = combineLatestAsync([this.projectAvailability$, this.nextCheckTimestampButNotWaitTheFirstTime$])
    .pipe(map(([projectAvailability]) => this.cloneNotExpired(projectAvailability)))
    .pipe(distinctUntilChanged(_.isEqual))
    .pipe(shareReplay({refCount: true, bufferSize: 1}));

  public readonly notExpiredProjectIds$ = this.notExpiredProjectAvailability$
    .pipe(map((projectAvailability) => Object.keys(projectAvailability)))
    .pipe(distinctUntilChanged((a, b) => _.xor(a, b).length === 0))
    .pipe(shareReplay({refCount: true, bufferSize: 1}));

  public readonly availableProjectIds$ = combineLatestAsync([this.projectAvailability$, this.userDeviceOfflineProjectsAcrossClients$, this.nextCheckTimestampButNotWaitTheFirstTime$])
    .pipe(map(([projectAvailability, userDeviceOfflineProjects]) => this.determineAvailableProjectIds(projectAvailability, userDeviceOfflineProjects)))
    .pipe(distinctUntilChanged((a, b) => _.xor(a, b).length === 0))
    .pipe(shareReplay({refCount: true, bufferSize: 1}));

  public readonly projectIdsWithOffline$: Observable<ProjectIdWithOffline[]> = combineLatestAsync([
    this.projectAvailability$,
    this.userDeviceOfflineProjectsAcrossClients$,
    this.nextCheckTimestampButNotWaitTheFirstTime$,
  ])
    .pipe(map(([projectAvailability, userDeviceOfflineProjects]) => this.toProjectIdWithOffline(projectAvailability, userDeviceOfflineProjects)))
    .pipe(distinctUntilChanged((a, b) => _.xor(a, b).length === 0))
    .pipe(shareReplay({refCount: true, bufferSize: 1}));

  public readonly projectIdsWithOfflineAcrossClients$: Observable<ProjectIdWithOffline[]> = combineLatestAsync([
    this.projectAvailability$,
    this.userDeviceOfflineProjectsAcrossClients$,
    this.nextCheckTimestampButNotWaitTheFirstTime$,
  ])
    .pipe(map(([projectAvailability, userDeviceOfflineProjects]) => this.toProjectIdWithOffline(projectAvailability, userDeviceOfflineProjects)))
    .pipe(distinctUntilChanged((a, b) => _.xor(a, b).length === 0))
    .pipe(shareReplay({refCount: true, bufferSize: 1}));

  private userDeviceOfflineProjectSubscription: Subscription | undefined;
  private authenticationSubscription: Subscription | undefined;
  private userDeviceOfflineProjects: Array<UserDeviceOfflineProject> | undefined;

  private set projectAvailability(projectAvailability: ProjectAvailability | null) {
    this.projectAvailabilitySubject.next(projectAvailability);
    if (projectAvailability) {
      this.loggingService.debug(LOG_SOURCE, 'projectAvailability - calling nextCheckTimestampsSubject.next');
      this.nextCheckTimestampsSubject.next(this.extractNextNotExpiredTimestamps(projectAvailability));
    }
  }

  public get projectAvailability(): ProjectAvailability {
    return this.projectAvailabilitySubject.value ? this.projectAvailabilitySubject.value : {};
  }

  constructor(
    private storage: StorageService,
    private userDeviceOfflineProjectDataService: UserDeviceOfflineProjectDataService,
    private clientService: ClientService,
    private posthogService: PosthogService,
    private loggingService: LoggingService,
    protected authenticationService: AuthenticationService,
    private devModeService: DevModeService
  ) {
    this.initFromStorage();
    this.userDeviceOfflineProjectSubscription = this.userDeviceOfflineProjectsAcrossClients$.subscribe((userDeviceOfflineProjects) => {
      this.userDeviceOfflineProjects = userDeviceOfflineProjects;
    });
    this.authenticationSubscription = this.authenticationService.data.pipe(distinctUntilChanged(_.isEqual)).subscribe(async (auth) => {
      if (!auth) {
        await this.clearStorage();
      }
    });
  }

  ngOnDestroy(): void {
    this.userDeviceOfflineProjectSubscription?.unsubscribe();
    this.userDeviceOfflineProjectSubscription = undefined;
    this.authenticationSubscription?.unsubscribe();
    this.authenticationSubscription = undefined;
  }

  private async initFromStorage() {
    const projectAvailability: ProjectAvailability = await this.storage.get(STORAGE_KEY);
    if (!projectAvailability) {
      this.loggingService.warn(LOG_SOURCE, `Storage ${STORAGE_KEY} not yet initialized.`);
      this.projectAvailability = null;
      return;
    }
    this.projectAvailability = projectAvailability;
  }

  private async clearStorage() {
    await this.storage.remove(STORAGE_KEY);
    this.projectAvailability = {};
  }

  private async updateProjectExpiration(projectAvailability: ProjectAvailability, mutationOptions?: Partial<StorageMutationOptions>) {
    this.projectAvailability = projectAvailability;
    await this.storage.set(STORAGE_KEY, projectAvailability, mutationOptions);
  }

  /**
   * See storeProjectExpirationDate for documentation.
   *
   * This method handles the migration case which makes sure that all active projects are initialized with an active expiry date.
   * This method can be removed in later versions and replaced by calling storeProjectExpirationDate directly.
   *
   * @param projectOrProjectIds
   * @param allActiveProjectIds list of all active projectIds. Used in case the storage is not yet initialized to set an initial expiry date.
   * @param newExpirationDateOrDates
   * @param overrideLaterExpirationDate
   */
  public async storeProjectExpirationDateAndInit(
    projectOrProjectIds: IdType | IdType[],
    allActiveProjectIds: IdType[],
    newExpirationDateOrDates?: Date | null | Array<Date | null | undefined>,
    overrideLaterExpirationDate = false,
    mutationOptions?: Partial<StorageMutationOptions>
  ): Promise<ProjectAvailability> {
    const isInitialized = this.isInitialized();
    this.loggingService.debug(LOG_SOURCE, `storeProjectExpirationDateAndInit called (isInitialized=${isInitialized}).`);
    if (!isInitialized) {
      const projectIds: IdType[] = _.isArray(projectOrProjectIds) ? projectOrProjectIds : [projectOrProjectIds];
      const allActiveProjectIdsNotInProjectIds = allActiveProjectIds.filter((p) => !projectIds.includes(p));
      if (allActiveProjectIdsNotInProjectIds.length) {
        this.loggingService.warn(LOG_SOURCE, `storeProjectExpirationDateAndInit - Storage not initialized. Initializing projects: ${allActiveProjectIdsNotInProjectIds}`);
        await this.storeProjectExpirationDate(allActiveProjectIdsNotInProjectIds);
        this.loggingService.info(LOG_SOURCE, `storeProjectExpirationDateAndInit - Storage successfully initialized. Initializing projects: ${allActiveProjectIdsNotInProjectIds}`);
      }
    }
    return await this.storeProjectExpirationDate(projectOrProjectIds, newExpirationDateOrDates, overrideLaterExpirationDate, mutationOptions);
  }

  /**
   * Stores projectexpiration date of the given project(s).
   *
   * @param projectOrProjectIds projectId or array of projectIds
   * @param newExpirationDateOrDates the nextExpirationDate to be used or array of dates. If undefined (standard case) then this method calculates the expiration date based on
   * userDeviceOfflineProjects.
   * If null, the expiration date of the project(s) will be removed which is pretty much the same as a date it the past.
   * If value is provided, the value is being used. The value is only updated if this value is newer than the stored value or overrideLaterExpirationDate is set.
   * If the value is not an array but projectOrProjectIds was an array, the same value of newExpirationDateOrDates is used for all projectIds.
   * If the value is an array ist must match the length of projectOrProjectIds.
   * @param overrideLaterExpirationDate (default false). If set to true, it overrides the value in the store even though it is newer.
   */
  public async storeProjectExpirationDate(
    projectOrProjectIds: IdType | IdType[],
    newExpirationDateOrDates?: Date | null | Array<Date | null | undefined>,
    overrideLaterExpirationDate = false,
    mutationOptions?: Partial<StorageMutationOptions>
  ): Promise<ProjectAvailability> {
    if (!this.userDeviceOfflineProjects) {
      this.userDeviceOfflineProjects = await observableToPromise(this.userDeviceOfflineProjectsAcrossClients$);
    }
    const projectIds: IdType[] = _.isArray(projectOrProjectIds) ? projectOrProjectIds : [projectOrProjectIds];
    let newExpirationDates: Array<Date | null | undefined> | undefined;
    if (newExpirationDateOrDates !== undefined) {
      if (_.isArray(newExpirationDateOrDates)) {
        newExpirationDates = newExpirationDateOrDates;
      } else {
        newExpirationDates = Array(projectIds.length).fill(newExpirationDateOrDates);
      }
      if (projectIds.length !== newExpirationDates.length) {
        throw new Error(`storeProjectExpirationDate - Number of projectIds (${projectIds.length}) does not match number of newExpirationDates (${newExpirationDates.length}).`);
      }
    }
    const projectExpirationMap = this.projectAvailability;
    for (let projectIdIndex = 0; projectIdIndex < projectIds.length; projectIdIndex++) {
      const projectId = projectIds[projectIdIndex];
      let newExpirationDate = newExpirationDates ? newExpirationDates[projectIdIndex] : undefined;
      let posthogProjectState: 'downloaded' | 'available' | undefined;
      if (this.userDeviceOfflineProjects.some((offlineProject) => offlineProject.projectId === projectId)) {
        if (newExpirationDate === undefined) {
          const expireInMs = this.devModeService.enabled ? this.devModeService.settings.downloadedProjectExpirationInMs : DOWNLOADED_PROJECT_EXPIRATION_IN_MS;
          newExpirationDate = new Date(Date.now() + expireInMs);
        }
        posthogProjectState = 'downloaded';
      } else {
        if (newExpirationDate === undefined) {
          const expireInMs = this.devModeService.enabled ? this.devModeService.settings.availableProjectExpirationInMs : AVAILABLE_PROJECT_EXPIRATION_IN_MS;
          newExpirationDate = new Date(Date.now() + expireInMs);
        }
        posthogProjectState = 'available';
      }

      const oldExpirationDate = projectExpirationMap[projectId];
      if (oldExpirationDate && newExpirationDate) {
        if (!overrideLaterExpirationDate && new Date(oldExpirationDate).getTime() > newExpirationDate.getTime()) {
          this.loggingService.info(LOG_SOURCE, `Do not override later newExpirationDate ${oldExpirationDate} of project ${projectId} to a earlier value of ${newExpirationDate}`);
          continue;
        }
        if (!overrideLaterExpirationDate) {
          this.sendPosthogEvent(new Date(oldExpirationDate), newExpirationDate, posthogProjectState);
        }
      }
      if (newExpirationDate === null) {
        this.loggingService.info(LOG_SOURCE, `Removing expirationDate for project ${projectId}`);
        delete projectExpirationMap[projectId];
      } else {
        projectExpirationMap[projectId] = newExpirationDate.toISOString();
        this.loggingService.debug(LOG_SOURCE, `Expiration date for project ${projectId} set to ${projectExpirationMap[projectId]}`);
      }
    }
    await this.updateProjectExpiration(projectExpirationMap, mutationOptions);
    return projectExpirationMap;
  }

  private sendPosthogEvent(oldExpirationDate: Date, newExpirationDate: Date, projectState: 'downloaded' | 'available') {
    const differenceInDays = Math.round((newExpirationDate.getTime() - oldExpirationDate.getTime()) / (1000 * 3600 * 24));
    if (differenceInDays > 0) {
      this.posthogService.captureEvent('[Projects] Days since last usage', {daysSinceLastUsed: differenceInDays, projectState});
    }
  }

  private filterOnlyNotExpired(projectAvailability: ProjectAvailability): ProjectAvailability {
    const newProjectAvailability: ProjectAvailability = {};
    const now = Date.now();
    for (const projectId of Object.keys(projectAvailability)) {
      const expireString = projectAvailability[projectId];
      if (new Date(expireString).getTime() >= now) {
        newProjectAvailability[projectId] = expireString;
      }
    }
    return newProjectAvailability;
  }

  private extractNotExpiredProjectIds(projectAvailability: ProjectAvailability): Array<IdType> {
    return Object.keys(this.filterOnlyNotExpired(projectAvailability));
  }

  public isProjectAvailable(projectId: IdType): boolean | undefined {
    if (!this.projectAvailability) {
      return undefined;
    }
    return this.projectAvailability[projectId] !== undefined && new Date(this.projectAvailability[projectId]).getTime() >= Date.now();
  }

  private extractNextNotExpiredTimestamps(projectAvailability: ProjectAvailability): number[] | undefined {
    const datesAsStringValues = Object.values(projectAvailability);
    if (!datesAsStringValues.length) {
      return undefined;
    }
    const timestampValues = _.orderBy(datesAsStringValues.map((dateAsString) => new Date(dateAsString).getTime()));
    const now = Date.now();
    return timestampValues.filter((timestamp) => timestamp >= now);
  }

  private cloneNotExpired(projectAvailability: ProjectAvailability): ProjectAvailability {
    const cloned: ProjectAvailability = {};
    for (const projectId of Object.keys(projectAvailability)) {
      const timestampAsString = projectAvailability[projectId];
      const date = new Date(timestampAsString);
      if (date.getTime() >= Date.now()) {
        cloned[projectId] = timestampAsString;
      }
    }
    return cloned;
  }

  private determineAvailableProjectIds(projectAvailability: ProjectAvailability, userDeviceOfflineProjects: Array<UserDeviceOfflineProject>): Array<IdType> {
    const activeProjectIds = this.extractNotExpiredProjectIds(projectAvailability);
    const offlineAvailableProjectIds = userDeviceOfflineProjects.map((userDeviceOfflineProject) => userDeviceOfflineProject.projectId);
    return _.compact([...activeProjectIds, ...offlineAvailableProjectIds]);
  }

  private toProjectIdWithOffline(projectAvailability: ProjectAvailability, userDeviceOfflineProjects: Array<UserDeviceOfflineProject>): Array<ProjectIdWithOffline> {
    const activeProjectIds = this.extractNotExpiredProjectIds(projectAvailability);
    const offlineAvailableProjectIds = userDeviceOfflineProjects.map((userDeviceOfflineProject) => userDeviceOfflineProject.projectId);
    const activeOrOfflineAvailableProjectIds = _.compact([...activeProjectIds, ...offlineAvailableProjectIds]);
    const activeProjectIdSet = new Set(activeProjectIds);
    const offlineAvailableProjectIdSet = new Set(offlineAvailableProjectIds);
    return activeOrOfflineAvailableProjectIds.map((projectId) => {
      return {
        id: projectId,
        isOfflineAvailable: offlineAvailableProjectIdSet.has(projectId),
        isAvailable: activeProjectIdSet.has(projectId),
      };
    });
  }

  private isInitialized(): boolean {
    return this.projectAvailabilitySubject.value !== null;
  }
}
