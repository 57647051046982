import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AbstractEntryListService} from 'src/app/services/entry/abstract-entry-list.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {EntryFilterComponent} from '../entry-filter/entry-filter.component';

@Component({
  selector: 'app-entry-filter-modal',
  templateUrl: './entry-filter-modal.component.html',
  styleUrls: ['./entry-filter-modal.component.scss'],
  standalone: true,
  imports: [UiModule, IonicModule, TranslateModule, EntryFilterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryFilterModalComponent {
  private readonly modal: HTMLIonModalElement;

  constructor(private entryListService: AbstractEntryListService) {}

  close() {
    this.modal.dismiss();
  }

  reset() {
    this.entryListService.resetFilters();
    this.modal.dismiss();
  }
}
