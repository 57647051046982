import {ChangeDetectorRef, Directive, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {EditTagsModalComponent} from 'src/app/components/tags/edit-tags-modal/edit-tags-modal.component';
import {TagListComponent} from 'src/app/components/tags/tag-list/tag-list.component';
import {Nullish} from 'src/app/model/nullish';
import {TagBase} from 'submodules/baumaster-v2-common';

@Directive({
  selector: 'app-tag-list[appEditTagsModal]',
  exportAs: 'appEditTagsModal',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditTagsModalDirective),
      multi: true,
    },
  ],
})
export class EditTagsModalDirective implements ControlValueAccessor, OnInit {
  value: Nullish<TagBase[]>;

  @Input()
  clearLabel?: string;
  @Input()
  applyLabel?: string;

  private propagateOnChange = (_: any) => {};
  private propagateOnTouched = () => {};

  constructor(
    private modalController: ModalController,
    private tagListComponent: TagListComponent,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateTagListComponent();
  }

  private updateTagListComponent() {
    this.tagListComponent.tags = this.value ?? [];
    this.cdRef.detectChanges();
  }

  writeValue(obj: any): void {
    if (obj === undefined || obj === null) {
      return this.updateModel(obj);
    }
    if (!Array.isArray(obj)) {
      return;
    }
    this.updateModel(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  updateModel(value: Nullish<TagBase[]>) {
    this.value = value;
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
    this.updateTagListComponent();
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: EditTagsModalComponent,
      componentProps: {
        tags: this.value,
        clearLabel: this.clearLabel,
        applyLabel: this.applyLabel,
      },
      cssClass: 'omg-modal omg-boundary',
    });

    await modal.present();

    const {data} = await modal.onWillDismiss();

    if (data && Array.isArray(data)) {
      this.updateModel(data);
    }
  }
}
