import {CommonModule} from '@angular/common';
import {Component, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {AutodeskBaseViewerComponent, BimMarkerInViewerDirective} from 'src/app/components/autodesk/autodesk-base-viewer/autodesk-base-viewer.component';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {PhotoService} from 'src/app/services/photo/photo.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {UserService} from 'src/app/services/user/user.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {fetchWithTimeout} from 'src/app/utils/fetch-utils';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {vector3TupleToBimMarkerPosition} from 'src/app/utils/three-js-utils';
import {BimMarker, IdType, LicenseType, ProtocolEntry, User} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {PipesModule} from '../../../pipes/pipes.module';
import {convertErrorToMessage} from '../../../shared/errors';
import {AutodeskDocumentLoadingStateComponent} from '../autodesk-document-loading-state/autodesk-document-loading-state.component';
import {BimMarkerChanges, BimMarkerCreation, BimViewerComponentData, BimViewerComponentRole} from '../../../model/bim-plan-with-deletable';
import _ from 'lodash';

const LOG_SOURCE = 'AutodeskViewer';

interface AutodeskTool {
  getNames(): [string, ...string[]]; // At least one name must be returned
  getPriority?(): number;
  activate(toolName: string, viewerApi: Autodesk.Viewing.Viewer3D): void;
  deactivate(toolName: string, viewerApi: Autodesk.Viewing.Viewer3D): void;
  getCursor?(): string;
  /**
   * @returns true, if refresh is required
   */
  update?(highResTimestamp: number): boolean;
  handleResize?(): void;

  // Other methods are available, as event handlers. Tools may "consume" events by returning true
  // from their event handling methods, or they may allow events to be passed down to the next tool
  // on the stack by returning false from the handling methods
}

class AutodeskCursorTool implements AutodeskTool {
  constructor(private cursor: string) {}

  getNames(): [string, ...string[]] {
    return ['cursor-override'];
  }

  getPriority(): number {
    return 999;
  }

  getCursor(): string {
    return this.cursor;
  }

  activate(_toolName: string, _viewerApi: Autodesk.Viewing.Viewer3D): void {
    // noop
  }

  deactivate(_toolName: string, _viewerApi: Autodesk.Viewing.Viewer3D): void {
    // noop
  }
}

@Directive({selector: '[appBimMarkerPreview]', standalone: true})
export class BimMarkerPreviewDirective {}

@Component({
  selector: 'app-autodesk-viewer',
  templateUrl: './autodesk-viewer.component.html',
  styleUrls: ['../../../components/autodesk/autodesk-base-viewer/autodesk-base-viewer.component.scss', './autodesk-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FontAwesomeModule,
    UiModule,
    TranslateModule,
    BimMarkerInViewerDirective,
    BimMarkerPreviewDirective,
    AutodeskDocumentLoadingStateComponent,
    PipesModule,
  ],
})
export class AutodeskViewerComponent extends AutodeskBaseViewerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(BimMarkerPreviewDirective, {read: ElementRef}) markerPreview: ElementRef<HTMLElement>;

  public createMarkerEnabled = false;
  public selectedMarkerPosition: BimMarker | BimMarkerCreation | undefined;
  public isFeatureEnabled$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.PROFESSIONAL]);
  loading = false;

  @Input() modal: HTMLIonModalElement;
  @Input() selectedProtocolEntry: ProtocolEntry | undefined;
  @Input() acrossProjects = false;
  @Input() readonly = false;

  @Output() protocolEntryMarkerClick = new EventEmitter<IdType>();
  @Output() markerChanges = new EventEmitter<BimMarkerChanges>();

  mergedMarkers: Array<BimMarker | BimMarkerCreation> | undefined;
  markersToDelete = new Array<BimMarker | BimMarkerCreation>();
  markersToInsert = new Array<BimMarker | BimMarkerCreation>();

  private currentUser: User | undefined;

  private cursorTool = new AutodeskCursorTool('url("/assets/images/marker.png") 16 32, auto');

  constructor(
    private featureEnabledService: FeatureEnabledService,
    private photoService: PhotoService,
    private alertService: AlertService,
    private userService: UserService
  ) {
    super();
  }

  /**
   * @override
   */
  ngOnInit() {
    this.emitClickEvent = true;
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit...');
    this.userService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => (this.currentUser = user));
    this.viewerLoaded.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.viewer.toolController.registerTool(this.cursorTool);
    });
    this.modal.canDismiss = async (data?: any, role?: string) => {
      if (this.hasPendingChanges()) {
        return await this.confirmationBeforeLeave();
      } else {
        return true;
      }
    };
  }

  /**
   * @override
   */
  async ngOnChanges(changes: SimpleChanges) {
    await super.ngOnChanges(changes);
    if (changes.markers) {
      this.updatedMergedMarkers();
      this.selectedMarkerPosition = null;
      if (this.mergedMarkers) {
        await this.drawMarkers();
        this.createMarkerEnabled = false;
        this.restoreCursor();
      }
    }
  }

  /**
   * @override
   */
  protected shouldCheckCameraChangedEvent(event: any): boolean {
    return this.createMarkerEnabled;
  }

  /**
   * @override
   */
  protected updateMarkersCallback = async () => {
    if (this.mergedMarkers || this.selectedMarkerPosition) {
      await this.updateMarkers(...this.getUpdateMarkersArgs());
    }
  };

  /**
   * @override
   */
  protected async handleClick(event: MouseEvent): Promise<void> {
    await this.createMarkerPreviewFromClick(event);
  }

  /**
   * @override
   */
  public geometryLoadedCallback = () => {
    if (this.mergedMarkers?.length === 1 && this.selectedProtocolEntry) {
      this.goToMarkerView(this.mergedMarkers[0]);
    }
    super.geometryLoadedCallbackFn();
  };

  /**
   * @override
   */
  protected async drawMarkers() {
    await super.drawMarkers(this.mergedMarkers ?? []);
  }

  /**
   * @override
   */
  protected findMarkerContainer(marker: BimMarker): HTMLElement | undefined {
    if (this.selectedMarkerPosition) {
      return this.markerPreview?.nativeElement;
    } else {
      return super.findMarkerContainer(marker);
    }
  }

  /**
   * @override
   */
  public goToMarkerView(marker: BimMarker) {
    super.goToMarkerView(marker);
    if (!this.selectedProtocolEntry && marker.protocolEntryId) {
      this.protocolEntryMarkerClick.emit(marker.protocolEntryId);
    }
  }

  private overrideCursor() {
    const activated = this.viewer?.toolController.activateTool(this.cursorTool.getNames()[0]);
    this.loggingService.debug(LOG_SOURCE, `overrideCursor - ${activated}`);
  }

  private restoreCursor() {
    const deactivated = this.viewer?.toolController.deactivateTool(this.cursorTool.getNames()[0]);
    this.loggingService.debug(LOG_SOURCE, `restoreCursor - ${deactivated}`);
  }

  async ionViewDidEnter() {
    await this.initializeComponent();
  }

  ionViewWillLeave() {
    this.destroyComponent();
  }

  private async confirmationBeforeLeave(): Promise<boolean> {
    return await this.alertService.confirm({header: 'protocolCreation.data_loss_header', message: 'protocolCreation.data_loss_message'});
  }

  async dismissModal(role: BimViewerComponentRole, mergedMarkers?: Array<BimMarker | BimMarkerCreation>) {
    const data: BimViewerComponentData = {
      selectedBimVersion: this.selectedBimVersion,
      markerChanges: await this.createBimMarkerChanges(),
      bimMarkers: mergedMarkers,
    };
    return this.modal.dismiss(data, role);
  }

  public async setMarkerMode() {
    if (!(await this.featureEnabledService.isFeatureEnabled(true, false, [LicenseType.PROFESSIONAL]))) {
      return this.toastService.infoWithMessageAndHeader(`toast.licenseDisabled.header`, `toast.licenseDisabled.message`);
    }
    this.createMarkerEnabled = true;
    this.overrideCursor();
  }

  public async emitMarkerChanges(): Promise<boolean> {
    const bimMarkerChanges = await this.createBimMarkerChanges();
    if (!bimMarkerChanges) {
      return false;
    }
    this.markerChanges.emit(bimMarkerChanges);
    return true;
  }

  private async createBimMarkerChanges(): Promise<BimMarkerChanges | undefined> {
    if (!this.createMarkerEnabled || !this.selectedMarkerPosition) {
      if (this.markersToDelete.length) {
        return {delete: this.markersToDelete};
      }
      return undefined;
    }
    try {
      this.selectedMarkerPosition.camera = await this.getCameraState();
      const markerCreation = await this.updateBimMarkerToCreation(this.selectedMarkerPosition);
      return {insert: markerCreation, delete: this.markersToDelete};
    } catch (error) {
      const errorMessage = convertErrorToMessage(error);
      this.loggingService.error(LOG_SOURCE, errorMessage);
      await this.toastService.error(this.translateService.instant('bimPlanMarker.errors.error_creating_marker'));
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - submitMarker', error?.userMessage + '-' + error?.message);
      throw error;
    }
  }

  public async deleteMarkersFn() {
    try {
      await this.deleteAllMarkersFromBimViewer();
      this.deleteMarkersFromArrays();
    } catch (error) {
      const errorMessage = `deleteMarkersFn failed - ${convertErrorToMessage(error)}`;
      this.loggingService.error(LOG_SOURCE, errorMessage);
      await this.toastService.error(this.translateService.instant('bimPlanMarker.errors.error_deleting_marker'));
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - deleteMarker', error?.userMessage + '-' + error?.message);
    }
  }

  private deleteMarkersFromArrays() {
    for (const marker of this.mergedMarkers) {
      const indexInserted = this.markersToInsert.findIndex((markerToInsert) => markerToInsert.id === marker.id);
      if (indexInserted >= 0) {
        this.markersToInsert.splice(indexInserted, 1);
        continue;
      }
      const isOriginalMarker = this.markers.some((originalMarker) => originalMarker.id === marker.id);
      if (isOriginalMarker) {
        this.markersToDelete.push(marker);
        continue;
      }
      this.loggingService.warn(LOG_SOURCE, `deleteMarkersFromArrays - marker ${marker.id} not found in inserted nor original markers.`);
    }
    this.updatedMergedMarkers();
  }

  public async deleteAllMarkersFromBimViewer() {
    this.viewer.disableSelection(false);
    if (!(await this.featureEnabledService.isFeatureEnabled(true, false, [LicenseType.PROFESSIONAL]))) {
      this.toastService.infoWithMessageAndHeader(`toast.licenseDisabled.header`, `toast.licenseDisabled.message`);
      return;
    }
    if (!this.mergedMarkers?.length) {
      return;
    }
    this.markerButtons.forEach((item) => (item.nativeElement.style.display = 'none'));
    if (this.viewer.overlays.hasScene('markerScene')) {
      if (this.cylinderMesh && this.sphereMesh) {
        this.clearScene();
        this.viewer.overlays.removeMesh(this.cylinderMesh, 'markerScene');
        this.viewer.overlays.removeMesh(this.sphereMesh, 'markerScene');
        this.viewer.overlays.removeScene('markerScene');
      }
    }
    this.createMarkerEnabled = false;
    this.restoreCursor();
    this.selectedMarkerPosition = null;
  }

  public async createMarkerPreviewFromClick(event: any) {
    if (!this.createMarkerEnabled) {
      return;
    }
    const intersectionEvent = await this.getIntersection(event);
    if (!intersectionEvent) {
      return;
    }

    const {dbId, point, objectBounds, objectCenter, objectIds, camera} = intersectionEvent;
    const user = this.currentUser ?? (await observableToPromise(this.userService.currentUser$));
    if (!user) {
      throw new Error('User is not set in AutodeskViewerComponent');
    }
    if (!this.selectedMarkerPosition) {
      this.selectedMarkerPosition = {
        id: v4(),
        viewerId: dbId,
        createdById: user.id,
        createdAt: new Date().toISOString(),
        changedAt: new Date().toISOString(),
        ...vector3TupleToBimMarkerPosition(point),
        objectBounds,
        objectCenter,
        objectIds,
        camera,
        bimVersionId: this.selectedBimVersion.id,
        protocolEntryId: this.selectedProtocolEntry?.id,
      };
      this.markersToInsert.push(this.selectedMarkerPosition);
    } else {
      this.selectedMarkerPosition = {
        ...this.selectedMarkerPosition,
        viewerId: dbId,
        changedAt: new Date().toISOString(),
        ...vector3TupleToBimMarkerPosition(point),
        objectBounds,
        objectCenter,
        objectIds,
        camera,
      };
      const indexOfMarkersToInsert = this.markersToInsert.findIndex((marker) => marker.id === this.selectedMarkerPosition.id);
      if (indexOfMarkersToInsert >= 0) {
        this.markersToInsert[indexOfMarkersToInsert] = this.selectedMarkerPosition;
      } else {
        this.loggingService.warn(LOG_SOURCE, `createMarkerPreviewFromClick called for marker ${this.selectedMarkerPosition?.id} but was not found in markersToInsert.`);
      }
    }
    await this.drawMarker(this.selectedMarkerPosition);
    await this.updateBimMarkerToCreation(this.selectedMarkerPosition);

    this.updatedMergedMarkers();
  }

  private async updateBimMarkerToCreation(bimMarkerOrCreation: BimMarker | BimMarkerCreation): Promise<BimMarkerCreation> {
    const blobUrl = await this.getScreenshotBlobUrl(bimMarkerOrCreation);
    const blob = await fetchWithTimeout(blobUrl).then((r) => r.blob());
    const attachment = this.photoService.createAttachment('marker.png');
    _.set(bimMarkerOrCreation, 'blob', blob);
    _.set(bimMarkerOrCreation, 'attachment', attachment);
    return bimMarkerOrCreation as BimMarkerCreation;
  }

  private getUpdateMarkersArgs(): Parameters<AutodeskBaseViewerComponent['updateMarkers']> {
    if (this.selectedMarkerPosition) {
      if (!this.markerPreview) {
        return [[]];
      }
      return [[this.selectedMarkerPosition], this.markerPreview.nativeElement];
    }
    return [this.mergedMarkers ? this.mergedMarkers.slice() : []];
  }

  async cancel() {
    await this.dismissModal('cancel');
  }

  private async save(): Promise<Array<BimMarker | BimMarkerCreation> | undefined> {
    if (this.readonly) {
      this.loggingService.warn(LOG_SOURCE, 'save called but readonly is set.');
      return;
    }
    try {
      this.loading = true;
      this.updatedMergedMarkers();
      const mergedMarkers = this.mergedMarkers;
      await this.emitMarkerChanges();
      this.selectedMarkerPosition = null;
      this.resetMarkerArrays();
      return mergedMarkers;
    } finally {
      this.loading = false;
    }
  }

  hasPendingChanges(): boolean {
    return Boolean(this.markersToDelete.length || this.markersToInsert.length);
  }

  async saveAndClose() {
    const mergedMarkers = await this.save();
    await this.dismissModal('save', mergedMarkers);
  }

  private resetMarkerArrays() {
    this.markersToInsert = [];
    this.markersToDelete = [];
    this.updatedMergedMarkers();
  }

  private updatedMergedMarkers() {
    if (!this.markers && !this.markersToInsert.length && !this.markersToDelete.length) {
      this.mergedMarkers = [];
      return;
    }
    let mergedMarkers = this.markers ? [...this.markers] : [];
    if (this.markersToInsert.length) {
      mergedMarkers = mergedMarkers.concat(this.markersToInsert);
    }
    if (this.markersToDelete.length) {
      for (const markerToDelete of this.markersToDelete) {
        const index = mergedMarkers.findIndex((marker) => marker.id === markerToDelete.id);
        if (index === -1) {
          this.loggingService.warn(LOG_SOURCE, `updatedMergedMarkers - unable to find deletedMarker ${markerToDelete.id}`);
          continue;
        }
        mergedMarkers.splice(index, 1);
      }
    }
    this.mergedMarkers = mergedMarkers;
    this.loggingService.debug(
      LOG_SOURCE,
      `updatedMergedMarkers - mergedMarkers=${mergedMarkers?.length}, markersToInsert=${this.markersToInsert?.length}, markersToDelete=${this.markersToDelete?.length}`
    );
  }
}
