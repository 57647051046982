import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthenticateRes, IdType, User, UserInvite, UserInviteConfirmReq, UserInviteReq, UserInviteRes, UserLicense} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {observableToPromise} from '../../utils/async-utils';
import {UserInviteDataService} from '../data/user-invite-data.service';
import {v4 as uuid4} from 'uuid';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';

const LOG_SOURCE = 'UserInviteService';

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {
  public static readonly USER_INVITE_INVITE_URL = environment.serverUrl + 'userInvite/invite';
  public static readonly USER_INVITE_CONFIRM_URL = environment.serverUrl + 'userInvite/confirmInvite';
  public static readonly USER_BASE_URL = environment.serverUrl + 'user';
  private readonly httpOptions = {
    headers: {
      Accept: 'application/json; version=6',
    },
  };

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private userInviteDataService: UserInviteDataService,
    private syncService: SyncService
  ) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called');
  }

  public async sendUserInvite(userInvite: UserInvite, clientId: IdType): Promise<UserInviteRes> {
    const payload: UserInviteReq = {
      userInvite,
    };
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
    const result = await observableToPromise(this.http.post<UserInviteRes>(UserInviteService.USER_INVITE_INVITE_URL + '/' + clientId, payload));
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    return result;
  }

  public async confirmUserInvite(userInviteLink: string, password: string): Promise<AuthenticateRes> {
    const payload: UserInviteConfirmReq = {
      password,
      userInviteLink,
    };
    return await observableToPromise(this.http.post<AuthenticateRes>(UserInviteService.USER_INVITE_CONFIRM_URL, payload, this.httpOptions));
  }

  public async cancelInvite(userInvite: UserInvite) {
    await this.userInviteDataService.delete(userInvite);
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
  }

  public async resendInvite(userInvite: UserInvite, clientId: IdType) {
    const newUserInvite = {...userInvite, ...{id: uuid4(), changedAt: new Date().toISOString()}};
    await this.sendUserInvite(newUserInvite, clientId);
  }

  async updateUserLicense(userId: IdType, userLicense: UserLicense, clientId: IdType) {
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
    await observableToPromise(this.http.post<void>(`${UserInviteService.USER_BASE_URL}/updateLicense/${clientId}/${userId}`, userLicense, this.httpOptions));
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
  }

  async deactivateUser(user: User, clientId: IdType) {
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
    await observableToPromise(this.http.post<void>(`${UserInviteService.USER_BASE_URL}/deactivate/${clientId}/${user.id}`, undefined, this.httpOptions));
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
  }

  async activateUser(userId: IdType, userLicense: UserLicense, clientId: IdType) {
    await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
    await observableToPromise(this.http.post<void>(`${UserInviteService.USER_BASE_URL}/activate/${clientId}/${userId}`, userLicense, this.httpOptions));
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
  }
}
