import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, ModalController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {RxIf} from '@rx-angular/template/if';
import {map} from 'rxjs';
import {TranslatedNotificationEventWithGroup} from 'src/app/model/notifications';
import {NotificationManagerService} from 'src/app/services/notifications/notification-manager.service';
import {NotificationsCenterProjectMode, NotificationsCenterService} from 'src/app/services/notifications/notifications-center.service';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {NotificationConfigModalComponent} from '../../project-settings/notification-config-modal/notification-config-modal.component';
import {NotificationCardComponent} from '../notification-card/notification-card.component';
import {NotificationListComponent} from '../notification-list/notification-list.component';
import {NotificationsToolbarComponent} from '../notifications-toolbar/notifications-toolbar.component';

@Component({
  selector: 'app-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, UiModule, TranslateModule, FontAwesomeModule, NotificationCardComponent, NotificationsToolbarComponent, NotificationListComponent, RxIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsCenterComponent {
  protected isOpen$ = this.notificationsCenterService.isCenterOpen$;

  protected data$ = combineLatestAsync([
    this.notificationsCenterService.notifications$,
    this.notificationsCenterService.unseenCountByMode$,
    this.notificationsCenterService.projectMode$,
    this.notificationsCenterService.showSeen$,
  ]).pipe(map(([notifications, unseenCountByMode, projectMode, showSeen]) => ({notifications, unseenCountByMode, projectMode, showSeen})));

  constructor(
    private notificationsCenterService: NotificationsCenterService,
    private notificationsService: NotificationsService,
    private modalController: ModalController,
    private notificationManagerService: NotificationManagerService,
    private posthogService: PosthogService
  ) {}

  protected handleClosed() {
    this.notificationsCenterService.closeCenter();
  }

  protected handleProjectModeChange(mode: NotificationsCenterProjectMode) {
    if (mode === 'allProjects') {
      this.posthogService.captureEvent('[Notification] Show all projects clicked', {});
    }
    this.notificationsCenterService.projectMode = mode;
  }

  protected handleStatusClick(notification: TranslatedNotificationEventWithGroup) {
    this.notificationsService.setSeenAt([notification.recipientId], !notification.seenAt);
  }

  protected handleShowSeenClick(showSeen: boolean) {
    this.posthogService.captureEvent(showSeen ? '[Notification] Show seen and unseen clicked' : '[Notification] Show only unseen clicked', {});
    this.notificationsCenterService.showSeen = showSeen;
  }

  protected handleMarkAllAsSeen(notifications: TranslatedNotificationEventWithGroup[]) {
    this.posthogService.captureEvent('[Notification] Mark all as seen clicked', {});
    this.notificationsService.setSeenAt(
      notifications.map((v) => v.recipientId),
      true
    );
  }

  protected async handleNotificationClick(notification: TranslatedNotificationEventWithGroup) {
    if (!notification.notificationEventType.startsWith('ProtocolEntry')) {
      this.notificationsCenterService.closeCenter();
    }
    await this.notificationManagerService.handleNotificationClick(
      {
        id: notification.id,
        bimVersionId: notification.bimVersionId,
        notificationEventType: notification.notificationEventType,
        pdfPlanVersionId: notification.pdfPlanVersionId,
        projectId: notification.projectId,
        protocolEntryId: notification.protocolEntryId,
        createdAt: notification.createdAt,
      },
      {
        skipSyncModalIfProjectPresent: true,
        skipCloseModal: true,
        openEntryModalInsteadOfNavigation: true,
        skipChangingProject: notification.notificationEventType.startsWith('ProtocolEntry'),
      }
    );
    if (!notification.seenAt) {
      this.notificationsService.setSeenAt([notification.recipientId], true);
    }
  }

  protected async handleSettingsClick() {
    const modal = await this.modalController.create({
      component: NotificationConfigModalComponent,
      componentProps: {},
      cssClass: 'omg-modal omg-boundary pdf-workflow-modal',
    });

    await modal.present();
    this.notificationsCenterService.closeCenter();
  }
}
