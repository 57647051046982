import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {AppToken, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';

const REST_ENDPOINT_URI = 'api/data/appTokens/';

@Injectable({
  providedIn: 'root',
})
export class AppTokenDataService extends AbstractClientAwareDataService<AppToken> implements OnDestroy {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(StorageKeyEnum.APP_TOKEN, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, integrityResolverService);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.isClientAdmin;
  }

  private dec2hex(dec: number): string {
    return dec.toString(16).padStart(2, '0');
  }
  public createRandomToken(size = 20): string {
    const uint8Array = new Uint8Array(Math.floor(size));
    const randomArray = crypto.getRandomValues(uint8Array);
    return Array.from(randomArray, this.dec2hex).join('');
  }
}
