import _ from 'lodash';
import {PdfProtocolSendReq} from '../../../../requestResponse';
import {PdfPreview} from '../../../../models';
import {GlobalSearchPdfProtocolGenerateData, PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {AbstractProjectContent} from '../abstractProject.content';
import {Column, Content, TableCell} from 'pdfmake/interfaces';
import {SvgIcons} from '../../pdfSvgIcons';
import {PdfHelperFunctions} from '../../../common-report-utils';

export class ProjectContent extends AbstractProjectContent {
  private globalSearchData: GlobalSearchPdfProtocolGenerateData;

  constructor(config: PdfProtocolSendReq, data: GlobalSearchPdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    const pdfData = {
      attachmentClients: data.attachmentClients,
      client: data.client,
      participants: data.participants,
      lookup: data.lookup,
    } as PdfProtocolGenerateData;
    super(data.language, config, pdfData, pdfHelperFunctions, pdfPreview);
    this.globalSearchData = data;
  }

  writeLetterhead(content: Content[]) {
    if (this.config.pdfProtocolSetting && !_.isEmpty(this.config.pdfProtocolSetting.letterheadText)) {
      content.push({
        text: this.config.pdfProtocolSetting.letterheadText ? this.config.pdfProtocolSetting.letterheadText : '',
        style: ['font12', 'letterHead'],
      });
    }
  }

  writeProject(content: Content[]) {
    this.writeProjectPhoto(content);
    this.writeCompanyInfo(content);
    if (this.config.pdfProtocolSetting?.showLetterhead) {
      this.writeLetterhead(content);
    }
    this.writeProjectTitle(content);
    this.writeNextMeeting(content);
    this.writeParticipants(content);
    if (!_.isEmpty(this.config.pdfProtocolSetting?.pdfStartText)) {
      content.push({text: '', style: ['marginTop25']});
    }
    this.writeStartText(content);
  }

  protected writeParticipants(content: Content[]) {
    this.writeParticipantsHeader(content);
    if (this.config.pdfProtocolSetting?.compactContactList) {
      this.writeCompactParticipantList(content, this.config.pdfProtocolSetting?.showParticipantCol);
    } else {
      this.writeParticipantList(content, this.config.pdfProtocolSetting?.showParticipantCol);
    }
  }

  private writeProjectTitle(content: Content[]) {
    content.push({
      style: ['marginTop20'],
      columns: [
        {
          text: this.globalSearchData.pdfTitle,
          style: ['textBold', 'font15', 'marginBottom10', 'fontColorGray'],
        },
      ],
    });
    this.writeCustomText(content);
    this.writeFilter(content);
  }

  private writeCustomText(content: Content[]) {
    const additionTextColumn: Column[] = [];

    if (!_.isEmpty(this.config.pdfProtocolSetting?.additionalText)) {
      additionTextColumn.push({
        columns: [
          {
            svg: this.changeSvgIconFill(SvgIcons.paper, this.getProtocolColor()),
            fit: [12, 12],
          },
        ],
        width: 'auto',
      });

      additionTextColumn.push({
        text: `${this.i18n?.get('addtional_text')}`,
        width: '*',
        style: ['font10', 'marginLeft10', 'protocolFontColor'],
      });
    }

    additionTextColumn.push({
      text: `${this.i18n?.get('sent_on')}`,
      width: '*',
      style: ['alignRight', 'font8'],
    });

    additionTextColumn.push({
      text: `${this.getDateValueNotNull(new Date())}`,
      width: 'auto',
      style: ['alignRight', 'font8', 'textBold', 'marginLeft2'],
    });

    content.push({
      columns: [
        {
          style: 'marginTop5',
          columns: additionTextColumn,
        },
      ],
    });
    this.writeLine(content);

    content.push({
      text: `${this.config.pdfProtocolSetting?.additionalText}`,
      style: ['font9', 'marginTop10Bottom30'],
    });
  }

  private writeFilter(content: Content[]) {
    const filters = this.globalSearchData?.searchFilter;
    if (!filters) {
      return;
    }

    const tableCell: TableCell[][] = [];
    const filterHeaderColumn: Column[] = [];
    this.writeFilterHeader(filterHeaderColumn);

    tableCell.push([
      {
        fillColor: '#EEEEEE',
        columns: filterHeaderColumn,
        style: ['marginTop5'],
        border: [false, false, false, true],
      },
    ]);

    const filterColumns: Column[] = [];
    for (const filter of filters) {
      const columnValue: Column[] = [];
      for (const value of filter.values) {
        columnValue.push({
          text: value,
          style: ['font9', 'marginLeft10'],
          width: 'auto',
        });
      }

      filterColumns.push({
        columns: [
          {
            text: filter.key,
            style: ['textBold', 'font9'],
            width: 'auto',
          },
          {columns: columnValue},
        ],
        style: ['marginTop10'],
      });
    }

    tableCell.push([
      {
        fillColor: '#EEEEEE',
        columns: [filterColumns],
        style: ['marginBottom10'],
        border: [false, false, false, false],
      },
    ]);

    content.push({
      style: ['marginTop10Bottom30'],
      table: {
        widths: [507],
        body: tableCell,
      },
      layout: {
        hLineWidth(i, node) {
          return 0.5;
        },
        vLineWidth(i, node) {
          return 0.5;
        },
        hLineColor(i, node) {
          return '#B3B3B3';
        },
        vLineColor(i, node) {
          return '#B3B3B3';
        },
      },
    });
  }

  private writeFilterHeader(column: Column[]) {
    column.push({
      columns: [
        {
          style: 'marginTop5',
          columns: [
            {
              columns: [
                {
                  svg: this.changeSvgIconFill(SvgIcons.paper, this.getProtocolColor()),
                  fit: [12, 12],
                },
              ],
              width: 'auto',
            },
            {
              text: `${this.i18n?.get('applied_filter')}`,
              width: '*',
              style: ['font10', 'marginLeft10', 'protocolFontColor'],
            },
          ],
        },
      ],
    });
  }
}
