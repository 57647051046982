import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IdType, ProtocolEntryLocation, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {mapObjectsWithDeletedSuffix, mapObjectWithDeletedSuffix} from 'src/app/utils/async-utils';
import {TranslateService} from '@ngx-translate/core';
import {Nullish} from 'src/app/model/nullish';
import _ from 'lodash';
import {VERSION_INTRODUCED_DEFAULT} from './abstract-data.service';

const REST_ENDPOINT_URI = 'api/data/protocolEntryLocation/';

@Injectable({
  providedIn: 'root',
})
export class ProtocolEntryLocationDataService extends AbstractClientAwareDataService<ProtocolEntryLocation> {
  public readonly dataActive$ = this.dataForOwnClient$.pipe(
    map((protocolEntryLocations) => protocolEntryLocations?.filter((protocolEntryLocation) => protocolEntryLocation.isActive || protocolEntryLocation.isActive === undefined))
  );

  public readonly dataActiveAcrossClients$ = this.dataAcrossClients$.pipe(
    map((protocolEntryLocations) => protocolEntryLocations?.filter((protocolEntryLocation) => protocolEntryLocation.isActive || protocolEntryLocation.isActive === undefined))
  );

  public readonly dataWithDeletedSuffix = this.data.pipe(mapObjectsWithDeletedSuffix(this.translateService, 'location', (v) => v.location));

  public readonly dataGroupedByIdWithDeletedSuffix = this.dataWithDeletedSuffix.pipe(map((locations) => (locations === null ? {} : _.keyBy(locations, 'id'))));

  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private translateService: TranslateService
  ) {
    super(
      StorageKeyEnum.PROTOCOL_ENTRY_LOCATION,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      clientService,
      loggingService,
      integrityResolverService,
      VERSION_INTRODUCED_DEFAULT,
      [(item) => item?.location?.toLowerCase()]
    );
  }

  public getByProtocolEntryLocationId(protocolEntryLocationId: IdType): Observable<ProtocolEntryLocation | null> {
    return this.data.pipe(map((protocolEntries) => protocolEntries.find((protocolEntryLocation) => protocolEntryLocation.id === protocolEntryLocationId)));
  }

  getByIdAcrossClientsWithDeletedSuffix(id: IdType): Observable<Nullish<ProtocolEntryLocation>> {
    return this.getByIdAcrossClients(id).pipe(mapObjectWithDeletedSuffix(this.translateService, 'location', (v) => v.location));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
