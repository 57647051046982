import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {BimPlanWithDeletable, BimVersionWithAttachment} from 'src/app/model/bim-plan-with-deletable';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {BimPlanDataService} from '../data/bim-plan-data.service';
import {BimVersionDataService} from '../data/bim-version-data.service';
import _ from 'lodash';
import {BimMarkerDataService} from '../data/bim-marker-data.service';
import {ContactService} from '../contact/contact.service';
import {getPersonInitials} from 'src/app/utils/text-utils';
import {assertSameProject} from 'src/app/utils/data-assert-utils';
import {BimVersion, IdType, BimMarker} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {ProjectDataService} from '../data/project-data.service';
import {ClientService} from '../client/client.service';

const LOG_SOURCE = 'BimPlanService';

export const bimVersionOrderByLastIndexAndStatusFinal = <T extends BimVersion>(lastFinalElement: BimVersion | undefined) => {
  return (bimVersion: T): number => {
    const isLastFinal = bimVersion.id === lastFinalElement?.id;
    if (bimVersion.status !== 'final') {
      return 2; // draft
    }
    return isLastFinal ? 3 : 1;
  };
};

@Injectable({
  providedIn: 'root',
})
export class BimPlanService {
  constructor(
    private bimPlanDataService: BimPlanDataService,
    private bimVersionDataService: BimVersionDataService,
    private bimMarkerDataService: BimMarkerDataService,
    private contactService: ContactService,
    private loggingService: LoggingService,
    private projectDataService: ProjectDataService,
    private clientService: ClientService
  ) {}

  getBimPlans$(searchQuery$: Observable<string | undefined> = of(undefined), limitTo2FinalVersions = true, hideDraftForConnectedProject = true): Observable<BimPlanWithDeletable[]> {
    return combineLatestAsync([
      this.clientService.ownClient$,
      this.projectDataService.currentProjectObservable,
      this.bimPlanDataService.data,
      this.bimVersionDataService.dataByPlanId$,
      this.bimMarkerDataService.dataByBimVersionId$,
      this.contactService.addressByUserId$,
    ]).pipe(
      map(([ownClient, currentProject, bimPlans, bimVersionsByPlanId, bimMarkersByVersionId, addressByUserId]) => {
        const bimPlansUnsorted = bimPlans.map<BimPlanWithDeletable>((plan) => {
          let bimVersionsForPlan = bimVersionsByPlanId[plan.id] ?? [];
          if (limitTo2FinalVersions && bimVersionsForPlan.length > 2) {
            const hasDraft = bimVersionsForPlan.some((v) => v.status !== 'final');
            const indexFilter = hasDraft ? bimVersionsForPlan.length - 3 : bimVersionsForPlan.length - 2;
            bimVersionsForPlan = bimVersionsForPlan.filter((value, index) => index >= indexFilter);
          }
          const isConnectedProject = currentProject && ownClient && currentProject.clientId !== ownClient.id;
          if (hideDraftForConnectedProject && isConnectedProject) {
            bimVersionsForPlan = bimVersionsForPlan.filter((value) => value.status === 'final');
          }
          const bimVersions: BimVersionWithAttachment[] = bimVersionsForPlan.map((version, index, arr) => {
            const bimMarkers = _.orderBy(bimMarkersByVersionId[version.id] ?? [], ['createdAt'], ['asc']);
            const lastMarker = bimMarkers.length ? bimMarkers[bimMarkers.length - 1] : undefined;
            const address = addressByUserId[version.createdById];
            const lastMarkerAddress = lastMarker ? addressByUserId[lastMarker.createdById] : undefined;
            const isLatestVersion = arr.length - 1 === index;
            const indexLatestFinalVersion = _.findLastIndex(arr, (bimVersion) => bimVersion.status === 'final');
            const isLatestFinalVersion = index === indexLatestFinalVersion;
            return {
              ...version,
              deletable: isLatestVersion && (version.status !== 'final' || !bimMarkers.length),
              isLatestVersion,
              isLatestFinalVersion,
              bimMarkers,
              createdByInitials: address ? getPersonInitials(address) : undefined,
              createdByName: address ? `${address.firstName} ${address.lastName}` : undefined,
              lastMarkerAt: lastMarker?.createdAt,
              lastMarkerByInitials: lastMarkerAddress ? getPersonInitials(lastMarkerAddress) : undefined,
              lastMarkerByName: lastMarkerAddress ? `${lastMarkerAddress.firstName} ${lastMarkerAddress.lastName}` : undefined,
            };
          });
          const latestBimVersion = _.last(bimVersions);
          const latestFinalBimVersion = _.last(bimVersions.filter((bv) => bv.status === 'final'));
          const bimVersionsOrderedByFinal = this.orderByLastIndexAndStatusFinal(bimVersions);
          const latestBimVersionByDisplayOrder = _.head(bimVersionsOrderedByFinal);
          return {
            ...plan,
            bimVersions: bimVersionsOrderedByFinal,
            latestBimVersion,
            latestBimVersionByDisplayOrder,
            latestFinalBimVersion,
            deletable: !bimVersions.length || bimVersions.every((version) => version.deletable),
            latestBimVersionDeletable: !!latestBimVersion?.deletable,
            hasDraftVersion: bimVersions.some((bv) => bv.status !== 'final'),
          };
        });
        return _.orderBy(bimPlansUnsorted, ['latestBimVersionByDisplayOrder.name', 'latestBimVersionByDisplayOrder.createdAt']);
      }),
      switchMap((bimPlans) =>
        searchQuery$.pipe(
          map((query) => (query?.trim() ? bimPlans.filter((plan) => plan.bimVersions.some((version) => version.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))) : bimPlans))
        )
      )
    );
  }

  private orderByLastIndexAndStatusFinal<T extends BimVersion>(values: Array<T>): Array<T> {
    const lastFinal = _.findLast(values, (v) => v.status === 'final');
    return _.orderBy(values, [bimVersionOrderByLastIndexAndStatusFinal(lastFinal), 'number'], ['desc', 'desc']);
  }

  async saveBimPlans(bimPlans: BimPlanWithDeletable[]): Promise<void> {
    if (bimPlans.length === 0) {
      return;
    }
    const bimVersions = bimPlans.filter((plan) => plan.latestBimVersion).map((plan) => plan.latestBimVersion);
    if (bimVersions.length === 0) {
      return;
    }
    const projectId = assertSameProject(bimPlans, 'bimVersions');
    await this.bimVersionDataService.update(bimVersions, projectId);
  }

  private getLatestFinalBimVersions$(acrossProjects = false): Observable<Array<BimVersion>> {
    return acrossProjects ? this.bimVersionDataService.dataLatestFinalAcrossProjects$ : this.bimVersionDataService.dataLatestFinal$;
  }

  private getLatestFinalBimVersionIds$(acrossProjects = false): Observable<Array<IdType>> {
    return this.getLatestFinalBimVersions$(acrossProjects).pipe(map((bimVersions) => bimVersions.map((bimVersion) => bimVersion.id)));
  }

  private getBimMarkers$(acrossProjects = false): Observable<Array<BimMarker>> {
    return acrossProjects ? this.bimMarkerDataService.dataAcrossProjects$ : this.bimMarkerDataService.data;
  }

  getLatestMarkers$(protocolEntryIds: Array<IdType>, acrossProjects = false): Observable<Array<BimMarker>> {
    return combineLatestAsync([this.getLatestFinalBimVersionIds$(acrossProjects), this.getBimMarkers$(acrossProjects)]).pipe(
      map(([bimVersionIds, bimMarkers]) => bimMarkers.filter((bimMarker) => protocolEntryIds.includes(bimMarker.protocolEntryId) && bimVersionIds.includes(bimMarker.bimVersionId)))
    );
  }

  getLatestMarkersWithVersion$(protocolEntryId: IdType, acrossProjects = false): Observable<{bimMarkers: Array<BimMarker>; bimVersion?: BimVersion}> {
    return combineLatestAsync([this.getLatestFinalBimVersions$(acrossProjects), this.bimMarkerDataService.getByEntryId$(protocolEntryId, acrossProjects)]).pipe(
      map(([bimVersions, bimMarkers]) => {
        const protocolEntryBimMarkers = bimMarkers.filter((bimMarker) => bimVersions.some((bv) => bv.id === bimMarker.bimVersionId));
        const bimVersionIds = _.uniq(protocolEntryBimMarkers.map((v) => v.bimVersionId));
        const protocolEntryBimVersions = bimVersions.filter((bv) => bimVersionIds.includes(bv.id));
        if (protocolEntryBimVersions.length > 1) {
          this.loggingService.warn(LOG_SOURCE, `getLatestMarkersWithVersion$ - found markers of ${protocolEntryBimVersions?.length} bimVersions for protocolEntry ${protocolEntryId}`);
        }
        return {bimMarkers: protocolEntryBimMarkers, bimVersion: protocolEntryBimVersions[0]};
      })
    );
  }
}
