import {Injectable} from '@angular/core';
import {NameableDropdown, NameableDropdownItem, User} from 'submodules/baumaster-v2-common';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {ProjectDataService} from './project-data.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {AbstractProjectAwareMappingDataService} from './abstract-project-aware-mapping-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import _ from 'lodash';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/nameableDropdownItems';

@Injectable({
  providedIn: 'root',
})
export class NameableDropdownItemDataService extends AbstractProjectAwareMappingDataService<NameableDropdownItem> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.NAMEABLE_DROPDOWN_ITEM,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  getByNameableDropdownAcrossProjects(nameableDropdown: NameableDropdown): Observable<NameableDropdownItem[]> {
    return this.dataByProjectId$.pipe(
      map((allNameableDropdownItemsMap) =>
        _.flatten(Array.from(allNameableDropdownItemsMap.values())).filter((nameableDropdownItem: NameableDropdownItem) => nameableDropdownItem.nameabledropdownId === nameableDropdown.id)
      )
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
