import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PhotoService} from '../../../services/photo/photo.service';
import {AlertController, IonInput, LoadingController, ModalController, NavParams, Platform, ViewDidEnter} from '@ionic/angular';
import {AttachmentBlob} from '../../../model/attachments';
import {v4 as uuidv4, v4 as uuid4} from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDataService} from '../../../services/data/project-data.service';
import _ from 'lodash';
import {Attachment, AttachmentChat, IdType, MIME_TYPE_EXTENSION_WHITELIST, Project, ProtocolEntry, ProtocolEntryChat} from 'submodules/baumaster-v2-common';
import {observableToPromise} from '../../../utils/async-utils';
import {AuthenticationService} from '../../../services/auth/authentication.service';
import {ProtocolEntryChatDataService} from '../../../services/data/protocol-entry-chat-data.service';
import {ErrorWithUserMessage} from '../../../shared/errors';
import {IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES, LIMIT_ATTACHMENTS_NUMBER_COMMENT} from '../../../shared/constants';
import {AttachmentChatDataService} from '../../../services/data/attachment-chat-data.service';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {LoggingService} from '../../../services/common/logging.service';
import {convertFileViaBinaryStringToFile, extractCommonAttachmentProperties, isImage, isImageBlob, isQuotaExceededError} from '../../../utils/attachment-utils';
import {SystemEventService} from '../../../services/event/system-event.service';
import {AttachmentService} from '../../../services/attachment/attachment.service';
import {ProjectService} from '../../../services/project/project.service';
import {SketchComponent} from '../../common/sketch/sketch.component';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {Nullish} from '../../../model/nullish';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {Router} from '@angular/router';

const LOG_SOURCE = 'ProtocolEntryChatCreateComponent';

@Component({
  selector: 'app-protocol-entry-chat-create',
  templateUrl: './protocol-entry-chat-create.component.html',
  styleUrls: ['./protocol-entry-chat-create.component.scss'],
  providers: [PageDidEnterLifecycleService],
})
export class ProtocolEntryChatCreateComponent implements OnInit, OnDestroy, ViewDidEnter {
  private readonly convertForExternalSourcesNeeded = this.platform.is('android');
  private protocolEntry: ProtocolEntry;
  private selectedProject: Project;
  private authenticatedUserId: IdType | undefined;
  private modal: HTMLIonModalElement;
  public allAttachments = new Array<AttachmentBlob | AttachmentChat>();
  public savedAttachments = new Array<AttachmentChat>();
  public newlyUploadedAttachments = new Array<AttachmentBlob>();
  public editMode: boolean;
  private authenticationSubscription: Subscription;
  private projectSubscription?: Subscription;
  private newlyUploadedAttachmentSubject = new BehaviorSubject<AttachmentBlob[] | null>(null);
  private newlyUploadedAttachmentsObservable = this.newlyUploadedAttachmentSubject.asObservable();
  private allAttachmentSubject = new BehaviorSubject<Array<AttachmentBlob | AttachmentChat | null>>(null);
  public allAttachmentsObservable = this.allAttachmentSubject.asObservable();
  private selectedEntryChat: ProtocolEntryChat;

  public takingPhotosSupported: boolean;
  public readonly acceptedMimeTypesForUpload: string;
  public message: string;
  public loading: boolean | undefined;
  private newlyUploadedAttachmentSubscription: Subscription;
  viewDidEnter = false;

  private photoAmount = 0;
  private fileSystemAmount = 0;
  private attachmentsProjectRoomAmount = 0;
  private drawingsAmount = 0;
  private photoSeriesUsed = false;

  public acrossProjects = true;

  @ViewChild('messageInput', {static: false}) messageInput: IonInput;
  @ViewChild('fileUpload') private fileUploadElem: ElementRef<HTMLInputElement>;

  constructor(
    private modalController: ModalController,
    private toastService: ToastService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private photoService: PhotoService,
    public platform: Platform,
    private navParams: NavParams,
    private projectDataService: ProjectDataService,
    private protocolEntryChatDataService: ProtocolEntryChatDataService,
    private attachmentChatDataService: AttachmentChatDataService,
    private authenticationService: AuthenticationService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private loadingController: LoadingController,
    private attachmentService: AttachmentService,
    private projectService: ProjectService,
    private protocolService: ProtocolService,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService,
    private posthogService: PosthogService,
    private networkStatusService: NetworkStatusService,
    private router: Router
  ) {
    this.acceptedMimeTypesForUpload = MIME_TYPE_EXTENSION_WHITELIST.join(',');
  }

  async ngOnInit() {
    this.takingPhotosSupported = this.photoService.isCameraSupported();
    this.selectedProject = await this.projectDataService.getCurrentProject();
    this.authenticationSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => (this.authenticatedUserId = authenticatedUserId));
    this.protocolEntry = this.navParams.data.protocolEntry;
    this.projectSubscription = (this.acrossProjects ? this.protocolService.getProjectByEntryId(this.protocolEntry.id) : this.projectDataService.currentProjectObservable).subscribe(
      (project) => (this.selectedProject = project)
    );
    this.selectedEntryChat = this.navParams.data.chat;
    this.editMode = !!this.navParams.data.editMode;
    this.newlyUploadedAttachmentSubject.next([]);
    if (this.editMode) {
      this.message = this.navParams.data.chat.message;
      this.newlyUploadedAttachmentSubscription = combineLatest([
        this.acrossProjects
          ? this.attachmentChatDataService.getByProtocolEntryAndChatAcrossProjects(this.protocolEntry.id, this.selectedEntryChat.id)
          : this.attachmentChatDataService.getByProtocolEntryAndChat(this.protocolEntry.id, this.selectedEntryChat.id),
        this.newlyUploadedAttachmentsObservable,
      ]).subscribe(([savedAttachments, newAttachments]) => {
        this.allAttachments = savedAttachments;
        this.allAttachments = this.allAttachments.concat(newAttachments);
        this.allAttachmentSubject.next(this.allAttachments);
      });
    } else {
      this.newlyUploadedAttachmentSubscription = this.newlyUploadedAttachmentsObservable.subscribe((newAttachments) => {
        this.allAttachments = newAttachments;
        this.allAttachmentSubject.next(this.allAttachments);
      });
    }
    this.setCanDismiss();
  }

  async ionViewDidEnter() {
    this.viewDidEnter = true;
    this.pageDidEnterLifecycleService.pageDidEnter();
    setTimeout(() => this.messageInput.setFocus(), 50);
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'save') {
      return true;
    }

    if (!this.hasChanges()) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss',
        },
      ],
    });

    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  async takePicture() {
    await this.photoService.getAttachmentFromCamera(
      'protocol entry chat create',
      this.allAttachments?.length,
      LIMIT_ATTACHMENTS_NUMBER_COMMENT,
      async (attachmentBlob) => this.addAttachmentBlob(attachmentBlob),
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
    this.photoAmount++;
  }

  async takePictures() {
    const newlyUploadedBefore = this.newlyUploadedAttachments;
    await this.photoService.getAttachmentsFromCamera(
      'protocol entry chat create',
      this.allAttachments?.length,
      LIMIT_ATTACHMENTS_NUMBER_COMMENT,
      async (attachmentBlob) => this.addAttachmentBlob(attachmentBlob),
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
    this.photoSeriesUsed = true;
    this.photoAmount += this.newlyUploadedAttachments?.length - newlyUploadedBefore?.length ?? 0;
  }

  async uploadFileEvent(event: any) {
    const files: FileList = event.target.files;
    if (files?.length > LIMIT_ATTACHMENTS_NUMBER_COMMENT - this.allAttachments?.length) {
      await this.showAlertForFileUpload();
      return;
    }
    let compressingLoading: HTMLIonLoadingElement | undefined;
    try {
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i);
        if (this.convertForExternalSourcesNeeded) {
          file = await convertFileViaBinaryStringToFile(file);
        }
        const filename = file.name;
        if (!(await this.photoService.ensureContentIsJpegOrPng(file, 'entryChat'))) {
          break;
        }
        let newAttachment: AttachmentBlob;
        if (file.size > IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES && isImageBlob(file)) {
          if (!compressingLoading) {
            compressingLoading = await this.loadingController.create({
              message: this.translateService.instant('imageCompressionInProgress'),
            });
            await compressingLoading.present();
          }
          newAttachment = await this.addAttachment(await this.photoService.scaleImage(file), filename);
        } else {
          newAttachment = await this.addAttachment(file, filename);
        }
        this.fileSystemAmount++;

        if (i === 0 && files.length === 1 && isImage(newAttachment)) {
          await this.photoService.showToastMessageImageTakenEditMarkings(newAttachment, this.onTakenPhotoMarkingsChanged);
        }
      }
    } finally {
      if (compressingLoading) {
        await compressingLoading.dismiss();
      }
    }
    // reset the file upload, otherwise you are not able to upload the same file again
    event.target.value = '';
  }

  private async showAlertForFileUpload() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.header'),
      message: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.message_comment', {limit: LIMIT_ATTACHMENTS_NUMBER_COMMENT}),
      buttons: [this.translateService.instant('okay')],
    });
    await alert.present();
  }

  public async onMarkingsChanged(attachment: AttachmentBlob | AttachmentChat, markings: string) {
    if (this.editMode && !_.find(this.newlyUploadedAttachments, {id: attachment.id})) {
      const onMarkingsChanged = this.navParams.data.markingsChanged;
      if (onMarkingsChanged) {
        await onMarkingsChanged(attachment, markings);
      }
    } else {
      attachment.markings = markings;
    }
  }

  async createNewProtocolEntryChat(): Promise<ProtocolEntryChat> {
    return {
      id: uuidv4(),
      number: await this.getNextChatNumber(),
      message: this.message,
      protocolEntryId: this.protocolEntry.id,
      projectId: this.selectedProject.id,
      createdAt: new Date(),
      createdById: this.authenticatedUserId ?? '',
      changedAt: new Date(),
    };
  }

  public onAttachmentDeleted(attachment: AttachmentBlob) {
    if (this.editMode && !_.find(this.newlyUploadedAttachments, {id: attachment.id})) {
      const attachmentDeleted = this.navParams.data.attachmentDeleted;
      if (attachmentDeleted) {
        attachmentDeleted(attachment);
      }
    } else {
      const index = this.newlyUploadedAttachments.findIndex((obj) => obj.id === attachment.id);
      if (this.newlyUploadedAttachments.length === 1) {
        this.newlyUploadedAttachments.splice(0);
      } else {
        this.newlyUploadedAttachments.splice(index, 1);
      }
      this.newlyUploadedAttachmentSubject.next(this.newlyUploadedAttachments);
    }
  }

  async saveDismiss() {
    if (!this.isFormValid() || !this.hasChanges()) {
      return;
    }
    try {
      let messageChanged = false;
      this.loading = true;
      let protocolEntryChat: ProtocolEntryChat;
      if (this.editMode) {
        messageChanged = this.selectedEntryChat?.message !== this.message;
        this.selectedEntryChat.message = this.message;
        protocolEntryChat = this.selectedEntryChat;
        await this.protocolEntryChatDataService.update(protocolEntryChat, this.selectedProject.id);
      } else {
        protocolEntryChat = await this.createNewProtocolEntryChat();
        await this.protocolEntryChatDataService.insert(protocolEntryChat, this.selectedProject.id);
      }

      if (this.newlyUploadedAttachments.length > 0) {
        const attachments = await this.createAttachments(protocolEntryChat);
        const blobs = this.newlyUploadedAttachments.map((attachment: AttachmentBlob) => attachment.blob);
        await this.attachmentChatDataService.insert(attachments, this.selectedProject.id, {}, blobs);
      }

      this.message = null;
      await this.modal.dismiss(undefined, 'save');
      await this.toastService.savingSuccess();
      try {
        const isNetworkConnected = this.networkStatusService.onlineOrUnknown;
        if (this.editMode) {
          this.posthogService.captureEvent(this.router.url.includes('tasks') ? '[Tasks][Task] Comment Edit' : '[Protocols][Entry] Comment Edit', {
            text: messageChanged,
            photos: this.photoAmount,
            photoSeriesUsed: this.photoSeriesUsed,
            attachmentsFilesystem: this.fileSystemAmount,
            attachmentsProjectRoom: this.attachmentsProjectRoomAmount,
            drawings: this.drawingsAmount,
            editedOffline: !isNetworkConnected,
          });
        } else {
          this.posthogService.captureEvent(this.router.url.includes('tasks') ? '[Tasks][Task] Comment Added' : '[Protocols][Entry] Comment Added', {
            photos: this.photoAmount,
            photoSeriesUsed: this.photoSeriesUsed,
            attachmentsFilesystem: this.fileSystemAmount,
            attachmentsProjectRoom: this.attachmentsProjectRoomAmount,
            drawings: this.drawingsAmount,
            editedOffline: !isNetworkConnected,
          });
        }
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
      }
    } catch (error) {
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage;
      } else {
        message = error.message;
      }
      this.loggingService.error(LOG_SOURCE, `Error inserting protocolEntryChat. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - saveDismiss', error?.userMessage + '-' + error?.message);
      if (isQuotaExceededError(message)) {
        await this.attachmentService.showToastQuotaExceeded();
      } else {
        await this.toastService.errorWithMessageAndHeader('error_saving_message', message);
      }
    } finally {
      this.loading = false;
    }
  }

  hasChanges(): boolean {
    if (this.editMode) {
      return this.navParams.data.chat.message !== this.message || this.newlyUploadedAttachments.length > 0;
    }
    return !_.isEmpty(this.message) || this.newlyUploadedAttachments.length > 0;
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
    this.newlyUploadedAttachmentSubscription.unsubscribe();
    this.projectSubscription?.unsubscribe();
  }

  isFormValid(): boolean {
    return !_.isEmpty(this.message);
  }

  dismissModal() {
    return this.modal.dismiss();
  }

  private async getNextChatNumber(): Promise<number> {
    const protocolEntryChats = await observableToPromise(
      this.acrossProjects ? this.protocolEntryChatDataService.getByProtocolEntryAcrossProjects(this.protocolEntry.id) : this.protocolEntryChatDataService.getByProtocolEntry(this.protocolEntry.id)
    );
    const sortedNumbers: Array<number> = _.sortBy(protocolEntryChats.map((protocolEntryChat) => protocolEntryChat.number));
    return sortedNumbers.length === 0 ? 1 : sortedNumbers[sortedNumbers.length - 1] + 1;
  }

  private addAttachment = async (blob: Blob, fileName: string, markings?: string | null): Promise<AttachmentBlob> => {
    const attachment = this.photoService.createAttachmentBlob(blob, fileName, markings);
    return this.addAttachmentBlob(attachment);
  };

  private onTakenPhotoMarkingsChanged = async (takenImageAttachment: AttachmentBlob, markings: Nullish<string>) => {
    const index = this.newlyUploadedAttachments.findIndex((attachment) => attachment.id === takenImageAttachment.id);
    if (index >= 0) {
      takenImageAttachment.markings = markings;
      this.newlyUploadedAttachments[index] = takenImageAttachment;
      this.newlyUploadedAttachmentSubject.next(this.newlyUploadedAttachments);
    }
  };

  private addAttachmentBlob(attachment: AttachmentBlob) {
    this.newlyUploadedAttachments.push(attachment);
    this.newlyUploadedAttachmentSubject.next(this.newlyUploadedAttachments);
    return attachment;
  }

  private async createAttachments(protocolEntryChat: ProtocolEntryChat): Promise<Array<AttachmentChat>> {
    if (!this.newlyUploadedAttachments.length) {
      return;
    }
    const attachments = new Array<AttachmentChat>();
    for (const attachmentBlob of this.newlyUploadedAttachments) {
      const attachment: AttachmentChat = {
        id: attachmentBlob.id,
        hash: attachmentBlob.hash,
        projectId: this.selectedProject.id,
        protocolEntryId: this.protocolEntry.id,
        chatId: protocolEntryChat.id,
        mimeType: attachmentBlob.mimeType,
        markings: attachmentBlob.markings,
        fileName: attachmentBlob.fileName,
        fileExt: attachmentBlob.fileExt,
        changedAt: attachmentBlob.changedAt,
        createdAt: attachmentBlob.createdAt,
        createdById: attachmentBlob.createdById,
        ...(await extractCommonAttachmentProperties(attachmentBlob.blob)),
      };
      attachments.push(attachment);
    }
    return attachments;
  }

  async projectRoomAttachmentsSelector() {
    if (LIMIT_ATTACHMENTS_NUMBER_COMMENT - this.allAttachments?.length <= 0) {
      await this.showAlertForFileUpload();
      return;
    }

    const project = await observableToPromise(this.projectService.getById(this.selectedProject.id));
    if (await this.attachmentService.showAlertIfOnlineOnlyProjectAndNoNetwork(project)) {
      return;
    }

    const selectedAttachments = await this.attachmentService.showProjectRoomAttachmentsSelector(LIMIT_ATTACHMENTS_NUMBER_COMMENT - this.allAttachments?.length, project?.id);
    if (selectedAttachments?.length) {
      this.attachmentsProjectRoomAmount += selectedAttachments.length;
      await this.copyAndAddAttachments(selectedAttachments);
    }
  }

  async openSketchingTool() {
    if (LIMIT_ATTACHMENTS_NUMBER_COMMENT - this.allAttachments?.length === 0) {
      await this.showAlertForFileUpload();
      return;
    }
    const blob = this.photoService.createEmptyImage();
    const modal = await this.modalController.create({
      component: SketchComponent,
      backdropDismiss: false,
      cssClass: 'full-screen-sketch',
      componentProps: {
        attachmentUrl: URL.createObjectURL(blob),
        onMarkingsChanged: async (markings: Nullish<string>) => {
          const newAttachment = await this.addAttachment(blob, uuid4() + '.jpg');
          if (newAttachment) {
            this.drawingsAmount++;
            await this.onTakenPhotoMarkingsChanged(newAttachment, markings);
          }
        },
      },
    });
    await modal.present();
  }

  private async copyAndAddAttachments(attachmentsToCopy: Array<Attachment>) {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('attachment.addingInProgress'),
    });
    try {
      await loading.present();
      const blobs = new Array<{blob: Blob; fileName: string; markings?: string | null}>();
      for (const attachmentToCopy of attachmentsToCopy) {
        if (!attachmentToCopy.filePath) {
          throw new Error(`Unable to copy attachment with id ${attachmentToCopy.id} as we do not have the attachment information.`);
        }
        const blob = await this.photoService.downloadAttachment(attachmentToCopy, 'image');
        if (!blob) {
          throw new Error(`Unable to copy attachment with id ${attachmentToCopy.id} as we do not have the blob.`);
        }
        blobs.push({blob, fileName: attachmentToCopy.fileName, markings: attachmentToCopy.markings});
      }

      for (const blobFilename of blobs) {
        await this.addAttachment(blobFilename.blob, blobFilename.fileName, blobFilename.markings);
      }
    } finally {
      await loading.dismiss();
    }
  }
}
