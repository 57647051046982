import {Directive, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {LicenseType} from 'submodules/baumaster-v2-common';
import {FeatureEnabledService} from '../services/feature/feature-enabled.service';

@Directive({
  selector: '[appFeatureEnabled]',
  exportAs: 'appFeatureEnabled',
})
export class FeatureEnabledDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private featureEnabledSubject = new BehaviorSubject<boolean>(true);
  private showSubject = new BehaviorSubject<boolean | null>(null);
  private forLicensesSubject = new BehaviorSubject<LicenseType[] | null>(null);
  private forConnectedSubject = new BehaviorSubject<boolean | null>(null);
  private forAdminSubject = new BehaviorSubject<boolean | null>(null);

  show$ = this.showSubject.asObservable();

  get show(): boolean | null {
    return this.showSubject.getValue();
  }

  @Input()
  set appFeatureEnabled(appFeatureEnabled: boolean) {
    this.featureEnabledSubject.next(appFeatureEnabled);
  }

  @Input()
  set appFeatureEnabledForConnected(forConnected: boolean | null) {
    this.forConnectedSubject.next(forConnected);
  }

  @Input()
  set appFeatureEnabledForLicenses(forLicenses: LicenseType[] | null) {
    this.forLicensesSubject.next(forLicenses);
  }

  @Input()
  set appFeatureEnabledForAdmin(forAdmin: boolean | null) {
    this.forAdminSubject.next(forAdmin);
  }

  private get viewContainer(): ViewContainerRef | undefined {
    if (this._viewContainer && this.templateRef) {
      return this._viewContainer;
    }

    return undefined;
  }

  constructor(
    @Optional() private templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private featureEnabledService: FeatureEnabledService
  ) {}

  ngOnInit() {
    this.show$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((show) => {
      if (show) {
        this.updateView();
      } else {
        this.clearView();
      }
    });

    combineLatest([this.featureEnabledSubject, this.forConnectedSubject, this.forLicensesSubject, this.forAdminSubject])
      .pipe(
        switchMap(([featureEnabled, forConnected, forLicenses, forAdmin]) => this.featureEnabledService.isFeatureEnabled$(featureEnabled, forConnected, forLicenses, forAdmin)),
        takeUntil(this.destroy$)
      )
      .subscribe((show) => {
        this.showSubject.next(show);
      });
  }

  private updateView() {
    this.viewContainer?.createEmbeddedView(this.templateRef);
  }

  private clearView() {
    this.viewContainer?.clear();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
