import {Directive} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {PdfPlanHolderSelectionService} from 'src/app/services/project-room/pdf-plan-holder-selection.service';
import {TagService} from 'src/app/services/tags/tag.service';

@Directive({
  selector: '[appUseEditPlansData]',
  exportAs: 'appUseEditPlansData',
})
export class UseEditPlansDataDirective {
  private selectedPdfPlanHolders$ = this.pdfPlanHolderSelectionService.selected$;

  editPlansData$ = this.selectedPdfPlanHolders$.pipe(
    switchMap((pdfPlans) =>
      this.tagService
        .getTagsForObjects$(
          pdfPlans.map(({latestPdfPlanVersion}) => latestPdfPlanVersion?.id).filter((id) => id),
          'pdfPlanVersions'
        )
        .pipe(
          map((tagsById) => ({
            tagsById,
            pdfPlans,
          }))
        )
    ),
    take(1)
  );

  constructor(
    private pdfPlanHolderSelectionService: PdfPlanHolderSelectionService,
    private tagService: TagService
  ) {}
}
